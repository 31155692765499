import { REHYDRATE } from 'redux-persist/es/constants';
import { getIn } from 'timm';

import {
  PUSH_APNS_TOKEN_SET,
  PUSH_FCM_TOKEN_ADD_SUCCESS,
  PUSH_PRIMER_SHOWN,
  PUSH_SET_STATUS,
  PUSH_SHOW_PRIMER,
} from './types';

const initialState = {
  shouldRequestPushPermissions: false,
  numberOfTimesPushPrimerHasBeenShown: 0,
  pushEnabled: false,
  fcmToken: '',
  apnsToken: '',
  hasRequestedPermissions: false,
};

export default function pushReducer(state = initialState, action) {
  switch (action.type) {
    case PUSH_SHOW_PRIMER: {
      return {
        ...state,
        shouldRequestPushPermissions: action.payload,
        hasRequestedPermissions:
          state.hasRequestedPermissions || !action.payload,
      };
    }

    case PUSH_PRIMER_SHOWN: {
      return {
        ...state,
        numberOfTimesPushPrimerHasBeenShown:
          state.numberOfTimesPushPrimerHasBeenShown + 1,
      };
    }

    case PUSH_FCM_TOKEN_ADD_SUCCESS: {
      return {
        ...state,
        fcmToken: getIn(action, ['payload', 'token']),
      };
    }

    case PUSH_APNS_TOKEN_SET: {
      return {
        ...state,
        apnsToken: action.payload,
      };
    }

    case PUSH_SET_STATUS: {
      return {
        ...state,
        pushEnabled: action.payload,
      };
    }

    case REHYDRATE: {
      const rehydratedState = getIn(action, ['payload', 'push']);

      if (action.payload && action.payload.push) {
        return {
          ...state,
          ...rehydratedState,
        };
      }

      return state;
    }

    default:
      return state;
  }
}
