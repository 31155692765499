import { z } from 'zod';

export const HostDataSchema = z.object({
  marketingUserId: z.string().optional(),
  companyId: z.number(),
  /** E.g. `'Europe/Stockholm'` */
  timeZone: z.string(),
  independentCareAge: z.number(),
  adultAge: z.number(),
  /** E.g. `'local.md'` */
  authCookieDomain: z.string(),
  /** E.g. `'mindoktor'` */
  defaultSite: z.string(),
  /** E.g. `'wss://rtm.dev.local.md'` */
  messagingServerHost: z.string(),
  analyticsEnabled: z.boolean(),
  /** @deprecated Use its own endpoint */
  featureFlags: z.unknown().optional(),
});

export const HostSchema = z.object({
  data: HostDataSchema,
});
