import MuiAvatar from '@material-ui/core/Avatar';
import { _t } from '@mindoktor/patient-legacy/i18n';
import React from 'react';

import colors from '../../../../common/colors';
import { Body1, Body3 } from '../../../../common/components/typography';
import constants from '../../../../common/constants';
import { MessageSendStatus } from '../../../../state/cases/constants';
import { getDisplayTime } from '../utils';
import Img from '@mindoktor/pulse/src/utils/images/Img.web';
import { getImageSrc } from '@mindoktor/pulse/src/utils/images/getImageSrc';

const styles = {
  imgAvatar: {
    width: constants.thumbDoctor,
    height: constants.thumbDoctor,
    marginRight: constants.spacing16,
    fontWeight: 500,
  },

  body: {
    flex: 1,
  },

  failed: {
    fontWeight: 500,
    color: colors.error_text,
  },

  header: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: constants.spacing16,
  },

  icon: {
    display: 'block',
    width: constants.thumbDoctor,
    height: constants.thumbDoctor,
    marginRight: constants.spacing16,
  },

  meta: {
    marginLeft: constants.spacing16,
    color: colors.grey_500,
  },

  title: {
    fontWeight: 500,
  },
};

export class Header extends React.Component {
  static defaultProps = {
    bold: true,
  };

  render() {
    const { icon, image, initials, title, timestamp, signature, status, bold } =
      this.props;

    const sending = status === MessageSendStatus.SENDING;
    const failed = status === MessageSendStatus.FAILED;

    return (
      <div style={styles.header}>
        {icon && (
          <div>
            <Img src={icon} style={styles.icon} />
          </div>
        )}

        {/* The Material UI Avatar component will display the image if provided,
        with the initials as the fallback. */}
        {(image || initials) && (
          <MuiAvatar
            src={image ? getImageSrc(image) : undefined}
            style={styles.imgAvatar}
          >
            {initials}
          </MuiAvatar>
        )}

        <div style={styles.body}>
          <Body1
            style={{
              ...(bold ? styles.title : {}),
              paddingTop: signature ? 0 : 10,
            }}
          >
            {title}
          </Body1>

          {signature && <Body3>{signature}</Body3>}
        </div>

        <Body3
          style={{ ...styles.meta, ...(failed ? styles.failed : undefined) }}
        >
          {sending
            ? _t('case.header.sending')
            : failed
            ? _t('case.header.failed')
            : getDisplayTime(timestamp)}
        </Body3>
      </div>
    );
  }
}

export default Header;
