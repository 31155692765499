import { REHYDRATE } from 'redux-persist/es/constants';
import {
  addFirst,
  getIn,
  merge,
  omit,
  removeAt,
  replaceAt,
  set,
  setIn,
  updateIn,
} from 'timm';

import {
  BROWSER_VIDEO_WARNING_DISMISSED,
  HIDE_COACH_NOTIFICATION,
  REGISTER_COACH_NOTIFICATIONS,
  SET_COACH_NOTIFICATION_POSITION,
  SHOW_COACH_NOTIFICATION,
  TOAST_NOTIFICATION_DISMISS,
  TOAST_NOTIFICATION_SHOW,
  UNREGISTER_COACH_NOTIFICATIONS,
} from './types';

export const initialState = {
  items: [],
  counter: 0,
  coachItems: {},
  videoWarningDismissed: false,
};

export default function notificationsReducer(state = initialState, action) {
  const { type, payload = {} } = action;

  switch (type) {
    case TOAST_NOTIFICATION_SHOW:
      const item = payload;
      if (!item) {
        return state;
      }

      const duplicate = state.items.find(
        (i) => i.message === item.message && i.type === item.type
      );

      // if item already created, just update its ping counter
      if (duplicate) {
        const id = duplicate.id;
        if (id === undefined) {
          console.warn('Notification id is missing');
          return state;
        }

        const index = state.items.findIndex((item) => item.id === id);
        if (index === -1) {
          console.warn('Notification index to delete not found');
          return state;
        }

        const pings = (state.items[index].pings || 0) + 1;

        return {
          ...state,
          items: replaceAt(state.items, index, {
            ...state.items[index],
            ...item,
            pings,
          }),
        };
      }

      // if item not already created, generate and id
      item.id = state.counter;

      return {
        ...state,
        items: addFirst(state.items, {
          ...item,
          pings: 0,
        }),
        counter: state.counter + 1,
      };

    case TOAST_NOTIFICATION_DISMISS:
      const id = payload && payload.id;

      const index = state.items.findIndex((item) => item.id === id);
      if (index === -1) {
        return state;
      }
      return {
        ...state,
        items: removeAt(state.items, index),
      };

    case REGISTER_COACH_NOTIFICATIONS: {
      const { contextName, coachNotifications } = payload;
      const itemsObject = coachNotifications.reduce(
        (items, item) => set(items, item.name, item),
        {}
      );

      if (getIn(state, ['coachItems', contextName])) {
        console.warn(
          `A coachItem context with the name ${contextName} was already registered.`
        );
      }

      return setIn(state, ['coachItems', contextName], itemsObject);
    }

    case UNREGISTER_COACH_NOTIFICATIONS: {
      const { contextName } = payload;
      return updateIn(state, ['coachItems'], (items) =>
        omit(items, contextName)
      );
    }

    case SET_COACH_NOTIFICATION_POSITION: {
      const { contextName, name, top, left } = payload;

      if (!getIn(state, ['coachItems', contextName, name])) return state;

      return updateIn(state, ['coachItems', contextName, name], (item) =>
        merge(item, { top, left })
      );
    }

    case SHOW_COACH_NOTIFICATION: {
      const { contextName, name } = payload;
      return setIn(state, ['coachItems', contextName, name, 'visible'], true);
    }

    case HIDE_COACH_NOTIFICATION: {
      const { contextName, name } = payload;
      return setIn(state, ['coachItems', contextName, name, 'visible'], false);
    }

    case BROWSER_VIDEO_WARNING_DISMISSED: {
      return {
        ...state,
        videoWarningDismissed: true,
      };
    }

    case REHYDRATE: {
      const { notifications = {} } = payload;
      return {
        ...state,
        videoWarningDismissed: notifications.videoWarningDismissed,
      };
    }

    default:
      return state;
  }
}
