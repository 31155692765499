import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import React from 'react';

import { getDisplayError } from '../../../state/inquisitions/utils';
import Markdown from '../components/Markdown';
import Binary from '../input/Binary';
import Choice from '../input/Choice';
import Info from '../input/Info';
import MultipleChoice from '../input/MultipleChoice';
import Number from '../input/Number';
import Range from '../input/Range';
import Tertiary from '../input/Tertiary';
import Text from '../input/Text';
import Upload from '../input/Upload';

class Question extends React.Component {
  // <{
  //   question: QuestionType,
  //   value: *,
  //   update: (*) => *,
  //   done: (*) => *,
  //   classes: { [string]: string },
  // }>
  render() {
    const { question, value, update, done, classes } = this.props;

    const {
      type,
      label,
      description,
      options,
      min,
      max,
      unit,
      optional,
      mimeTypes,
    } = question;

    const inputProps = {
      value,
      error: getDisplayError(question, value),
      options,
      optional,
      min,
      max,
      unit,
      update,
      done,
      mimeTypes,
    };

    const InputComponent = {
      binary: Binary,
      choice: Choice,
      info: Info,
      multipleChoice: MultipleChoice,
      number: Number,
      range: Range,
      tertiary: Tertiary,
      text: Text,
      upload: Upload,
    }[type];

    return (
      <>
        <div className={classes.header}>
          {label !== undefined && label !== '' && (
            <Typography variant="h2">{label.trim()}</Typography>
          )}
          {description !== undefined && description !== '' && (
            <Markdown className={classes.description}>
              {description.trim()}
            </Markdown>
          )}
        </div>

        {InputComponent && <InputComponent {...inputProps} />}
      </>
    );
  }
}

export default withStyles(({ spacing, breakpoints: { down } }) => ({
  header: {
    paddingBottom: spacing(3),
    marginRight: spacing(10),
    [down('xs')]: {
      marginRight: spacing(5),
    },
  },

  description: {
    marginTop: spacing(),
    '&:first-child': {
      marginTop: 0,
    },
  },
}))(Question);
