import React, { Component } from 'react';

import IconButton from '../../components/button/icon';
import { MdClose } from '../../components/docly-md-icons';

const colorByType = {
  error: 'yellow',
  success: 'green',
  warning: 'blue',
};

export default class Close extends Component {
  render() {
    const { type, onClick } = this.props;

    return (
      <IconButton
        iconComponent={MdClose}
        color={colorByType[type] || 'black'}
        size={40}
        onClick={onClick}
      />
    );
  }
}
