import Base64 from 'base-64';

import {
  LOGIN,
  logout as logoutAction,
} from '@mindoktor/patient-legacy/src/state/api/actions';

import { removeToken } from '@mindoktor/patient-app/api/token';

import { useLegacyDispatch, useLegacySelector } from './legacyState';

const extractSession = (token: string) => {
  // rudimentary check for JWT validity
  // see: http://git.io/xPBn for JWT format
  const parts = token.split('.');
  if (parts.length !== 3) {
    return {};
  }
  return JSON.parse(Base64.decode(parts[1]));
};

export const useLegacyAuth = () => {
  const dispatch = useLegacyDispatch();
  const isLoggedIn = useLegacySelector((state) => state.api?.loggedIn);
  const authToken = useLegacySelector((state) => state.api?.token);

  const dispatchLogin = () => {
    dispatch({
      type: LOGIN,
      payload: {
        token: authToken,

        // If there's no token we assume the iat timestamp is now, for idle timeout purposes.
        session:
          authToken !== null
            ? extractSession(authToken ?? '')
            : { iat: Math.floor(Date.now() / 1000) },
      },
    });
  };

  const dispatchLogout = async (initiatedByUser = false) => {
    // Temporary hack to be sure of removing the token
    void removeToken();
    dispatch(logoutAction({ initiatedByUser }));
    await removeToken();
  };

  return {
    /** In legacy it means the user is authenticated, registered and accepted privacy/tos. */
    isLoggedIn: isLoggedIn ?? false,
    dispatchLogin,
    dispatchLogout,
    // Temporary fix, should be implemented properly.
    token: authToken,
  };
};
