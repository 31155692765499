import React, { ComponentProps } from 'react';

import { SxProps, Theme } from '@mui/material';
import MUILink from '@mui/material/Link';

import {
  PaletteColorDefinitionComplete,
  PaletteCompleteColor,
} from '../../../styles/colors';
import { weights } from '../../../styles/fonts';

type MUILinkProps = ComponentProps<typeof MUILink>;

interface Props {
  variant?: MUILinkProps['variant'];
  color?: PaletteCompleteColor | MUILinkProps['color'];
  children?: React.ReactNode;
  onClick?: MUILinkProps['onClick'];
  noUnderline?: boolean;
  sx?: SxProps<Theme>;
}

const defaults: Omit<Required<Props>, 'onClick'> & {
  fontWeight: number;
  colorVariant: keyof PaletteColorDefinitionComplete;
  pressedColorVariant: keyof PaletteColorDefinitionComplete;
} = {
  variant: 'inherit',
  color: 'hero',
  colorVariant: 'main',
  pressedColorVariant: 'light',
  children: null,
  noUnderline: false,
  fontWeight: weights.medium,
  sx: null,
};

const TextLink: React.FC<Props> = ({
  children,
  color,
  onClick,
  variant,
  noUnderline,
  sx,
}) => {
  return (
    <MUILink
      variant={variant ?? defaults.variant}
      fontWeight={defaults.fontWeight}
      underline={noUnderline ?? defaults.noUnderline ? 'none' : 'always'}
      color={color}
      onClick={onClick}
      sx={[...(Array.isArray(sx) ? sx : [sx])]}
    >
      {children}
    </MUILink>
  );
};

export type TextLinkProps = Props;
export default TextLink;
