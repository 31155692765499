import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

import { SvgIconComponent as Icon } from '../../../icons/Injection.svg';

const InjectionIcon: React.FC<SvgIconProps> = ({ sx, ...otherProps }) => (
  <SvgIcon
    inheritViewBox
    component={Icon}
    sx={[
      {
        '&.MuiSvgIcon-root': {
          width: '72px',
          height: '72px',
        },
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    {...otherProps}
  />
);

export default InjectionIcon;
