import { isQuestion } from '../formulary/formulary';
import { getFormularyState } from '../formulary/selectors';
import { getHealthProfileFormularyState } from '../healthprofile/selectors';
import { getGuideByEntrywayId } from './selectors';

const getVisibleQuestionsInTree = (node, visibility) => {
  const questions = [];

  if (visibility[node.index]) {
    if (isQuestion(node)) questions.push(node);

    if (node.children) {
      node.children.forEach((child) =>
        questions.push(...getVisibleQuestionsInTree(child, visibility))
      );
    }
  }

  return questions;
};

// type Progress = {
//   index?: string,
//   position?: number,
//   total?: number,
//   progress?: number,
//   savedAt?: number,
//   remainingHealthProfileQuestions?: number,
// };

const progressCache = {};

// Returns the progress for a guide & health profile as an object that contains
// the current position, total number of questions, progress as a number between 0 and 1
// and when the draft was last saved at.
export const getProgress = (
  { guides, formulary, healthProfile },
  formularyKey
) => {
  const formularyData = getFormularyState({ formulary }, formularyKey);

  if (!formularyData) return {};

  const { entrywayId, childUuid } = formularyData;

  const guide = getGuideByEntrywayId({ guides }, entrywayId);

  if (!guide) return {};

  const healthProfileData = getHealthProfileFormularyState(
    { formulary, healthProfile },
    childUuid
  );

  if (!healthProfileData) return {};

  const index = `${formularyKey}-${formularyData.current.index}-${healthProfileData.current.index}`;

  const cachedProgress = progressCache[formularyKey];

  if (cachedProgress && cachedProgress.index === index) {
    return cachedProgress;
  }

  let { remainingHealthProfileQuestions } = cachedProgress || {};

  const healthProfileErrorCount = Object.keys(healthProfileData.errors).length;

  // Save remaining health profile questions unless already cached.
  if (remainingHealthProfileQuestions === undefined) {
    remainingHealthProfileQuestions = healthProfileErrorCount;
  }

  const formularyQuestions = getVisibleQuestionsInTree(
    formularyData.formulary,
    formularyData.visibility
  );

  const total = formularyQuestions.length + remainingHealthProfileQuestions;

  let position = 1;

  // Set position at the current guide question index.
  for (
    ;
    position < formularyQuestions.length + 1 &&
    formularyQuestions[position - 1].index !== formularyData.current.index;
    position++
  );

  // If at the end of the guide questions, add answered health profile questions to the position.
  if (!formularyData.next) {
    position += remainingHealthProfileQuestions - healthProfileErrorCount;
  }

  const progress = (progressCache[formularyKey] = {
    index,

    position,
    total,
    progress: position / (total + 1),

    savedAt: formularyData.savedAt,

    remainingHealthProfileQuestions,
  });

  return progress;
};
