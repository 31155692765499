import { _t } from '@mindoktor/patient-legacy/i18n';
import React from 'react';

import { Title1 } from '../../../common/components/typography';
import { URL_SUPPORT } from '../../../state/api';
import Button from '../../components/button/button';
import Markdown from '../../components/markdown';
import Modal from '../../components/modal';
import { hideModal } from '../../routing';

import { routes } from '@mindoktor/patient-app/routing/constants/webRoutes';

const styles = {
  title: {
    marginBottom: 10,
  },

  button: {
    marginBottom: 10,
  },
};

const CaseLimitModal = () => (
  <Modal
    body={
      <div>
        <Title1 style={styles.title}>{_t('caseLimitInfo.title')}</Title1>

        <Markdown source={_t('caseLimitInfo.body')} />
      </div>
    }
    footer={
      <div>
        <Button
          color="black"
          style={styles.button}
          onClick={() => (window.location.href = `#${routes.CASES}`)}
        >
          {_t('caseLimitInfo.cases')}
        </Button>

        <Button
          type="flat"
          color="black"
          style={styles.button}
          onClick={() => (window.location.href = URL_SUPPORT)}
        >
          {_t('caseLimitInfo.support')}
        </Button>

        <Button type="flat" color="black" onClick={hideModal}>
          {_t('caseLimitInfo.cancel')}
        </Button>
      </div>
    }
  />
);

export default CaseLimitModal;
