import { LOGIN, LOGOUT } from '../api/actions';
import { isCompleted, withAnswers, withAnswersAndPosition } from './formulary';
import { findByIndex } from './navigation';
import {
  FORMULARY_ADD,
  FORMULARY_CLEAR,
  FORMULARY_LOAD,
  FORMULARY_LOAD_SUCCESS,
  FORMULARY_NEXT,
  FORMULARY_POST,
  FORMULARY_POST_ERROR,
  FORMULARY_POST_SUCCESS,
  FORMULARY_PREVIOUS,
  FORMULARY_SAVED,
  FORMULARY_SUBMIT,
  FORMULARY_UPDATE,
  QUESTIONNAIRE_UPDATE,
} from './types';

const initialState = /** @type {import('./types').FormularyState} */ ({
  byKey: {},
  isLoading: false,
});

function createState(state, formularyKey, formularyData) {
  const newState = {
    byKey: { ...state.byKey },
    isLoading: false,
  };

  newState.byKey[formularyKey] = formularyData || null;
  return newState;
}

const getFormularyData = (formulary, formularyKey) => {
  const data = formulary.byKey[formularyKey];
  if (!data) return null;
  if (data.type === 'formulary') return data;
  throw new Error('Expected formulary, not ' + data.type);
};

function onLoad(state) {
  return {
    ...state,
    isLoading: true,
  };
}

function onLoadSuccess(
  state,
  {
    payload: {
      formularyKey,
      entrywayId,
      revisitId,
      childUuid,
      formulary,
      answers,
      index,
      dynamicCode,
      skipDrafts,
      skipAnswered,
      skipOptionalQuestions,
    },
  }
) {
  return createState(state, formularyKey, {
    type: 'formulary',
    formulary,
    ...withAnswersAndPosition(
      formulary,
      index ? findByIndex(formulary, index) : null,
      answers,
      undefined,
      { skipAnswered, skipOptionalQuestions }
    ),
    entrywayId,
    childUuid,
    dynamicCode,
    skipDrafts,
    skipAnswered,
    revisitId,
    initialIndex: index,
  });
}

function onFormularyAdd(state, { payload }) {
  switch (payload.type) {
    case 'questionnaire':
      const { type, formulary, answers, key } = payload;
      return createState(state, key, {
        type,
        formulary,
        ...withAnswers(formulary, answers),
        submittedAnswers: answers,
        hasUnsubmittedAnswers: false,
        isFinished: false,
      });

    default:
      throw new Error(
        FORMULARY_ADD +
          ' is not implemented for formularies of type ' +
          payload.type
      );
  }
}

const getQuestionnaireState = (formulary, formularyKey) => {
  const data = formulary.byKey[formularyKey];
  if (!data) return null;
  if (data.type === 'questionnaire') return data;
  throw new Error('Expected questionnaire, not ' + data.type);
};

const onSubmit = (state, { payload: { formularyKey } }) => {
  const formularyState = getQuestionnaireState(state, formularyKey);
  if (!formularyState) return state;
  const { formulary, type, answers } = formularyState;

  const wAnswers = withAnswers(formulary, answers);

  switch (type) {
    case 'questionnaire':
      return createState(state, formularyKey, {
        type: 'questionnaire',
        formulary,
        ...wAnswers,
        submittedAnswers: answers,
        hasUnsubmittedAnswers: false,
        isFinished: isCompleted(wAnswers, answers),
      });
    default:
      throw new Error('not implemented for formularies of type ' + type);
  }
};

function onUpdate(state, { payload: { formularyKey, answers, index, type } }) {
  switch (type) {
    case 'questionnaire': {
      const formularyState = getQuestionnaireState(state, formularyKey);
      if (!formularyState) return state;

      const wAnswers = withAnswers(
        formularyState.formulary,
        {
          ...(formularyState.answers || {}),
          ...(answers || {}),
        },
        formularyState.initialVisibility
      );

      return createState(state, formularyKey, {
        type: 'questionnaire',
        ...formularyState,
        ...wAnswers,
        hasUnsubmittedAnswers: true,
        isFinished: false,
      });
    }

    case 'formulary': {
      const formularyState = getFormularyData(state, formularyKey);

      if (!formularyState) return state;

      const {
        formulary,
        current,
        skipAnswered,
        initialVisibility,
        skipOptionalQuestions,
      } = formularyState;

      return createState(state, formularyKey, {
        type: 'formulary',
        ...formularyState,
        ...withAnswersAndPosition(
          formulary,
          index ? findByIndex(formulary, index) : current,
          {
            ...(formularyState.answers || {}),
            ...(answers || {}),
          },
          initialVisibility,
          { skipAnswered, skipOptionalQuestions }
        ),
      });
    }
    default:
      throw new Error(
        FORMULARY_UPDATE + ' is not implemented for formularies of type ' + type
      );
  }
}

function onPrevious(state, { payload: { formularyKey } }) {
  const formularyState = getFormularyData(state, formularyKey);

  if (!formularyState) return state;

  const { skipAnswered, skipOptionalQuestions } = formularyState;

  return createState(state, formularyKey, {
    type: 'formulary',
    ...formularyState,
    ...withAnswersAndPosition(
      formularyState.formulary,
      formularyState.current,
      formularyState.answers,
      formularyState.initialVisibility,
      { previous: true, skipAnswered, skipOptionalQuestions }
    ),
  });
}

function onNext(state, { payload: { formularyKey } }) {
  const formularyData = getFormularyData(state, formularyKey);

  if (!formularyData) return state;

  const { skipAnswered, skipOptionalQuestions } = formularyData;

  return createState(state, formularyKey, {
    type: 'formulary',
    ...formularyData,
    ...withAnswersAndPosition(
      formularyData.formulary,
      formularyData.current,
      formularyData.answers,
      formularyData.initialVisibility,
      { next: true, skipAnswered, skipOptionalQuestions }
    ),
  });
}

function onClear(state, { payload: { formularyKey } }) {
  return createState(state, formularyKey, undefined);
}

function onPosting(state, { payload: { formularyKey } }) {
  return createState(state, formularyKey, {
    type: 'formulary',
    ...getFormularyData(state, formularyKey),
    posting: true,
    postingError: false,
  });
}

function onPosted(state, { payload: { formularyKey } }) {
  return createState(state, formularyKey, {
    type: 'formulary',
    ...getFormularyData(state, formularyKey),
    posting: false,
    postingError: false,
  });
}

function onPostedError(state, { payload: { formularyKey } }) {
  return createState(state, formularyKey, {
    type: 'formulary',
    ...getFormularyData(state, formularyKey),
    posting: false,
    postingError: true,
  });
}

function onSaved(state, { payload: { formularyKey, savedAt } }) {
  return createState(state, formularyKey, {
    type: 'formulary',
    ...getFormularyData(state, formularyKey),
    savedAt,
  });
}

export default function formularyReducer(state = initialState, action) {
  const { type } = action;

  switch (type) {
    case LOGIN:
    case LOGOUT:
      return initialState;

    case FORMULARY_LOAD:
      return onLoad(state);
    case FORMULARY_LOAD_SUCCESS:
      return onLoadSuccess(state, action);
    case FORMULARY_UPDATE:
      return onUpdate(state, action);
    case FORMULARY_PREVIOUS:
      return onPrevious(state, action);
    case FORMULARY_NEXT:
      return onNext(state, action);
    case FORMULARY_CLEAR:
      return onClear(state, action);
    case FORMULARY_POST:
      return onPosting(state, action);
    case FORMULARY_POST_SUCCESS:
      return onPosted(state, action);
    case FORMULARY_POST_ERROR:
      return onPostedError(state, action);
    case FORMULARY_SAVED:
      return onSaved(state, action);
    case QUESTIONNAIRE_UPDATE:
      return onUpdate(state, action);
    case FORMULARY_SUBMIT:
      return onSubmit(state, action);
    case FORMULARY_ADD:
      return onFormularyAdd(state, action);

    default:
      return state;
  }
}
