import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import React from 'react';

export default withStyles(({ spacing, breakpoints: { down, up } }) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: spacing(2),
    [down('xs')]: {
      marginBottom: spacing(6),
      marginTop: -spacing(),
      marginLeft: -spacing(1.5),
      width: `calc(100% + ${spacing(1.5)}px)`,
    },
    [up('sm')]: {
      width: `calc(100% - ${spacing(4)}px)`,
      marginLeft: spacing(2),
      marginRight: spacing(2),
    },
  },
}))(({ children, classes, className }) => (
  <div className={classNames(classes.root, className)}>{children}</div>
));
