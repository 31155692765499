import { getIn } from 'timm';

import { LOGOUT } from '../api/actions';
import { FEATUREFLAGS_REQUEST_SUCCESS } from '../featureflags/types';
import * as actions from './actions';

const initialState = {
  // assume online, so always check for explicit false
  ready: false,
  online: null,
  state: 'active',
  os: '',
  version: '',
  keyboardHeight: 0,
  systemMessage: '',
  navigationAtFirstLevel: true,
  deviceId: '',
  sessionId: '',
  userAgent: '',
  killSwitch: {
    isAllowed: true,
    hasUpdate: false,
  },
  referral: {
    uri: null,
  },
  pushEnabled: false,
  hasLaunched: false,
  safeAreaColor: '#fff',
  seenBefore: null,
};

const appReducer = (state = initialState, action) => {
  const payload = action.payload || {};

  switch (action.type) {
    case actions.APP_START: {
      const { payload = {} } = action;

      return {
        ...state,
        os: payload.os,
        version: payload.version,
        safeAreaColor: payload.safeAreaColor || state.safeAreaColor,
      };
    }

    case actions.APP_READY: {
      return {
        ...state,
        ready: true,
      };
    }

    case actions.APP_ONLINE:
      return { ...state, online: action.payload.online };

    case actions.APP_STATE_CHANGE:
      return { ...state, state: action.payload.state };

    case actions.TOGGLE_KEYBOARD:
      return {
        ...state,
        keyboardHeight: action.payload.height,
        keyboardVisible: action.payload.visible,
      };

    case actions.RECEIVE_SYSTEM_MESSAGE:
      return {
        ...state,
        systemMessage: action.payload.systemMessage,
      };

    case FEATUREFLAGS_REQUEST_SUCCESS:
      return {
        ...state,
        // must be, since we just received a response from the server
        online: true,
      };

    case actions.APP_VERSION_STATUS_RECEIVED:
      return {
        ...state,
        // must be, since we just received a response from the server
        online: true,
        killSwitch: action.payload,
      };

    case actions.FIRST_LEVEL_NAVIGATION:
      return {
        ...state,
        navigationAtFirstLevel: action.payload.navigationAtFirstLevel,
      };

    case actions.INIT:
      return {
        ...state,
        ...action.payload,
      };

    case actions.APP_REFERRAL:
      return {
        ...state,
        referral: {
          uri: action.payload.uri,
          ...action.payload.params,
        },
      };

    case actions.APP_REFERRAL_RESET:
      return {
        ...state,
        referral: {
          uri: null,
        },
      };

    case LOGOUT:
      return {
        ...state,
      };

    case actions.APP_LAUNCHED: {
      return {
        ...state,
        hasLaunched: true,
      };
    }

    case actions.APP_REGISTER_ADVERTISING_ID_REQUEST: {
      return {
        ...state,
        advertisingId: action.payload,
      };
    }

    case actions.SET_SAFEAREA_COLOR: {
      return {
        ...state,
        safeAreaColor:
          getIn(action, ['payload', 'color']) || state.safeAreaColor,
      };
    }

    case actions.APP_REGISTER_ADVERTISING_ID_REQUEST_SUCCESS: {
      const { seenBefore } = payload;

      return {
        ...state,
        seenBefore,
      };
    }

    default:
      return state;
  }
};

export default appReducer;
