import React, { Component } from 'react';

import angryUnchecked from '../../../../images/survey/ic_survey_smiley_angry.svg';
import angryChecked from '../../../../images/survey/ic_survey_smiley_angry_chosen.svg';
import gladUnchecked from '../../../../images/survey/ic_survey_smiley_glad.svg';
import gladChecked from '../../../../images/survey/ic_survey_smiley_glad_chosen.svg';
import happyUnchecked from '../../../../images/survey/ic_survey_smiley_happy.svg';
import happyChecked from '../../../../images/survey/ic_survey_smiley_happy_chosen.svg';
import sadUnchecked from '../../../../images/survey/ic_survey_smiley_sad.svg';
import sadChecked from '../../../../images/survey/ic_survey_smiley_sad_chosen.svg';
import { ratingValues } from '../../../state/formulary/constants';

const icons = {
  happy: {
    checked: happyChecked,
    unchecked: happyUnchecked,
  },

  glad: {
    checked: gladChecked,
    unchecked: gladUnchecked,
  },

  sad: {
    checked: sadChecked,
    unchecked: sadUnchecked,
  },

  angry: {
    checked: angryChecked,
    unchecked: angryUnchecked,
  },
};

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: 400,
    margin: '0 auto',
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
  },

  item: {
    display: 'block',
    width: 50,
    height: 50,
    margin: 0,
    padding: 0,
    cursor: 'pointer',
  },
};

// type ItemProps = {
//   icon: string,
//   checked: boolean,
//   select: any => any,
// };

class Item extends Component {
  select = () => {
    const { icon, select } = this.props;

    select(icon);
  };

  render() {
    const { icon, checked } = this.props;

    return (
      <img
        src={icons[icon][checked ? 'checked' : 'unchecked']}
        style={styles.item}
        onClick={this.select}
      />
    );
  }
}

// type Props = {
//   answer?: any,
//   error?: string,
//   update: (value?: any) => any,
//   next: () => any,
// };

export default class Rating extends Component {
  select = async (icon) => {
    const { update, next } = this.props;

    await update(ratingValues[icon]);

    next();
  };

  render() {
    const { answer } = this.props;

    return (
      <div style={styles.root}>
        {Object.keys(ratingValues).map((icon) => (
          <Item
            key={icon}
            icon={icon}
            checked={answer === ratingValues[icon]}
            select={this.select}
          />
        ))}
      </div>
    );
  }
}
