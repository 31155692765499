import React from 'react';
import { _t } from '@mindoktor/patient-legacy/i18n';
import Screen from '../../components/screen';
import MenuBar from '../../menu/bar';
import VerifyEmail from '../../contact_info_verification/components/VerifyEmail';
import * as Boxed from '../../components/boxedscreen';
import { useNavigation, routes } from '@mindoktor/patient-app/routing/web';

const VerifyEmailScreen = () => {
  const navigation = useNavigation();

  const onComplete = () => {
    navigation.navigate(routes.ACCOUNT_CONTACT_VERIFY_EMAIL_SUCCESS);
  };

  return (
    <Screen
      header={<MenuBar />}
      body={
        <VerifyEmail
          onComplete={onComplete}
          title={_t('contactinfo.verification.emailupdate.title')}
          variant={Boxed.ScreenVariant.Screen}
          emailChangeInfo={false}
        />
      }
    />
  );
};

export default VerifyEmailScreen;
