import throttle from 'lodash-es/throttle';

import { refreshToken } from './actions';
import { isLoggedIn } from './selectors';

const REFRESH_THROTTLE_TIME = 1 * 60 * 1000;

// Refreshes the token when certain actions are dispatched.
export default ({ refreshTokenOn }) =>
  ({ dispatch, getState }) => {
    const throttledRefreshToken = throttle(
      () => isLoggedIn(getState()) && dispatch(refreshToken()),
      REFRESH_THROTTLE_TIME,
      {
        leading: true,
        trailing: false,
      }
    );

    return (next) => (action) => {
      const { type } = action;

      const nextAction = next(action);

      if (isLoggedIn(getState()) && refreshTokenOn.includes(type)) {
        throttledRefreshToken();
      }

      return nextAction;
    };
  };
