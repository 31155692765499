import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import { refreshToken } from '../../state/api/actions';
import videoLoader from './loader';
import Videochat from './videochat';

import { useUserActivity } from '@mindoktor/patient-app/user/hooks/useUserActivity';

/**
 * @typedef {object} OwnProps
 * @prop {string} token
 */

/** @typedef {import('react-redux').ConnectedProps<typeof connector>} PropsFromRedux */
/** @typedef {PropsFromRedux & OwnProps} Props */

/** @type {React.FC<Props>} */
export const VideochatScreen = ({
  token,
  updatePresence,
  OT,
  userId,
  apiKey,
  sessionId,
  doctorName,
  doctorSignature,
}) => {
  const [localSrc, setLocalSrc] = useState(undefined);
  const [remoteSrc, setRemoteSrc] = useState(undefined);
  const [connected, setConnected] = useState(false);
  const [voiceCallOnly, setVoiceCallOnly] = useState(false);
  const [muted, setMuted] = useState(false);
  const [error, setError] = useState(undefined);

  const sessionRef = useRef(undefined);
  const publisherRef = useRef(undefined);
  const subscriberRef = useRef(undefined);

  const activityContext = useUserActivity();

  const validateStream = (stream) => {
    if (!stream || !stream.connection) return false;

    const connectionData = stream.connection.data;

    if (!connectionData) return false;

    const parsedData = JSON.parse(decodeURIComponent(connectionData));

    return parsedData.userId !== userId;
  };

  const streamCreated = (event) => {
    if (!sessionRef.current) return;

    if (!validateStream(event.stream)) return;

    if (subscriberRef.current) {
      sessionRef.current.unsubscribe(subscriberRef.current);
    }

    const newSubscriber = sessionRef.current.subscribe(
      event.stream,
      undefined,
      {
        audioVolume: 100,
        insertDefaultUI: false,
      },
      (error) => {
        console.error('subscriber.subscribe', error);

        error && setError(error);
      }
    );

    newSubscriber.on('videoElementCreated', (event) => {
      setRemoteSrc(event.element.srcObject);
    });

    newSubscriber.on('destroyed', (_event) => {
      setRemoteSrc(undefined);
    });

    subscriberRef.current = newSubscriber;
  };

  const videoElementCreated = (event) => {
    event.element.onloadeddata = () => {
      setLocalSrc(event.element.srcObject);
    };
  };

  const streamDestroyed = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    sessionRef.current = OT.initSession(apiKey, sessionId);

    const publisher = OT.initPublisher(
      undefined,
      {
        publishAudio: !muted,
        publishVideo: !voiceCallOnly,
        insertDefaultUI: false,
      },
      (error) => {
        console.error('publisher.init', error);
        error && setError(error);
      }
    );

    publisherRef.current = publisher;

    sessionRef.current.on('streamCreated', streamCreated);
    publisherRef.current.on('videoElementCreated', videoElementCreated);
    publisherRef.current.on('streamDestroyed', streamDestroyed);

    const presenceUpdater = setInterval(() => {
      if (connected && updatePresence) {
        updatePresence();
      }
    }, 30000);

    return () => {
      if (sessionRef.current) {
        sessionRef.current.disconnect();
        sessionRef.current.off('streamCreated', streamCreated);
      }

      if (publisherRef.current) {
        publisherRef.current.off('videoElementCreated', videoElementCreated);
        publisherRef.current.off('streamDestroyed', streamDestroyed);
        publisherRef.current.destroy();
      }

      clearInterval(presenceUpdater);
    };
  }, []);

  const connect = () => {
    if (connected) return;

    updatePresence && updatePresence();

    sessionRef.current &&
      sessionRef.current.connect(token, (error) => {
        console.error('session.connect', error);

        if (error) {
          setError(error);
          return;
        }

        sessionRef.current.publish(publisherRef.current, (error) => {
          console.error('session.publish', error);

          error && setError(error);
        });
      });

    setConnected(true);
    activityContext.setIsVideocallActive(true);
  };

  const disconnect = () => {
    if (!!sessionRef.current) {
      console.error('disconnect', sessionRef.current);
      sessionRef.current.unpublish(publisherRef.current);
      sessionRef.current.unsubscribe(subscriberRef.current);
      sessionRef.current.disconnect();
    }

    subscriberRef.current = undefined;
    setRemoteSrc(undefined);
    setConnected(false);
    activityContext.setIsVideocallActive(false);
    setError(undefined);
  };

  const onMutedChange = (muted) => {
    publisherRef.current && publisherRef.current.publishAudio(!muted);
    setMuted(muted);
  };

  const mute = () => onMutedChange(true);
  const unmute = () => onMutedChange(false);

  const onVoiceCallOnlyChange = (voiceCallOnly) => {
    publisherRef.current && publisherRef.current.publishVideo(!voiceCallOnly);
    setVoiceCallOnly(voiceCallOnly);
  };

  const disableVideo = () => onVoiceCallOnlyChange(true);
  const enableVideo = () => onVoiceCallOnlyChange(false);

  return (
    <div>
      <Videochat
        localSrc={localSrc}
        remoteSrc={remoteSrc}
        connected={connected}
        muted={muted}
        error={error}
        onMute={mute}
        onUnmute={unmute}
        onConnect={connect}
        onDisconnect={disconnect}
        name={doctorName}
        signature={doctorSignature}
        disabled={false}
        voiceCallOnly={voiceCallOnly}
        onDisableVideo={disableVideo}
        onEnableVideo={enableVideo}
      />
    </div>
  );
};

const connector = connect(
  // NOTE that the linter says "state" is never read, but without it, things start crashing on
  // line 129, where OT is undefined.
  (state, props) => {
    const {
      OT,
      userId,
      apiKey,
      sessionId,
      doctorName,
      doctorSignature,
      caseId,
    } = props;

    return {
      OT,
      userId,
      apiKey,
      sessionId,
      doctorName,
      doctorSignature,
      caseId,
    };
  },
  { updatePresence: refreshToken }
);

export default videoLoader(connector(VideochatScreen));
