import React from 'react';

const BackgroundColor = ({ color }) => (
  <style>
    {`
      body {
        background-color: ${color};
      }
    `}
  </style>
);

export default BackgroundColor;
