import { _t } from '@mindoktor/patient-legacy/i18n';
import React from 'react';

import arrowIconSource from '../../../../images/generated/arrow_listitem@4x.png';
import logoSource from '../../../../images/logo/heart_alone@2x.png';
import colors from '../../../common/colors';
import {
  Body1,
  Body2,
  fontFamilies,
  Title2,
} from '../../../common/components/typography';
import { VARDGUIDEN_URL } from '../../../common/constants/links';
import spacing from '../../../common/spacing';
import { track } from '../../../state/tracking/actions';
import Markdown from '../../components/markdown';
import Modal from '../../components/modal';
import { hideModal } from '../../routing';
import Img from '@mindoktor/pulse/src/utils/images/Img.web';

const styles = {
  content: {
    padding: 0,
  },
  body: {
    marginBottom: spacing.t,
  },
  itemText: {
    fontFamily: fontFamilies.graphikBold,
    fontWeight: 500,
    color: colors.red_500,
    flexGrow: '1',
  },
  arrow: {
    marginTop: 4,
    height: spacing.xs,
    opacity: 0.33,
  },
  icon: {
    height: spacing.s,
    marginRight: spacing.xt,
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    textDecoration: 'none',
    padding: `${spacing.t}px 0`,
    marginBottom: spacing.t,
  },
  borderedItem: {
    borderBottom: `solid 1px ${colors.grey_200}`,
  },
  lastItem: {
    marginBottom: spacing.l,
  },
  logoWrapper: {
    flex: 1,
    paddingBottom: 20,
  },
  logo: {
    display: 'block',
    width: 35,
    height: 27,
  },
};

const Item = ({ children, track, href, iconSource, style }) => (
  <a
    onClick={() => track('YoungParentModal.LinkClicked', { route: href })}
    href={href}
    style={{ ...styles.item, ...style }}
  >
    <Img style={styles.icon} src={iconSource} />
    <Body1 style={styles.itemText}>{children}</Body1>
    <Img src={arrowIconSource} style={styles.arrow} />
  </a>
);

const Logo = () => (
  <div style={styles.logoWrapper}>
    <Img src={logoSource} style={styles.logo} />
  </div>
);

const YoungParentModal = () => {
  return (
    <Modal
      confirmButton={{
        text: _t('common.close'),
        onConfirm: () => {
          track('YoungParentModal.Closed');
          hideModal();
        },
      }}
      contentStyle={styles.content}
      body={
        <>
          <Logo key="logo" />
          <Title2 mb="s">{_t('seekphysicalcare.title')}</Title2>
          <Markdown style={styles.body} source={_t('seekphysicalcare.body')} />
          <Item track={track} href={VARDGUIDEN_URL} style={styles.borderedItem}>
            {_t('seekphysicalcare.findclinic.title')}
            <Body2>{_t('seekphysicalcare.findclinic.description')}</Body2>
          </Item>
        </>
      }
    />
  );
};

export default YoungParentModal;
