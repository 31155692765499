import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { _t } from '@mindoktor/patient-legacy/i18n';

import Modal from '../components/modal';
import Button from '../components/button/button';
import HiddenIdentityComponent, {
  HiddenIdentityTextVariant,
} from './HiddenIdentityComponent';
import { hideModal } from '../routing';

const padding = 0.5; // rem
const useStyles = makeStyles({
  component: {
    padding: padding + 'rem',
    width: `calc(100% - ${padding * 2}rem)`,
    maxWidth: 760,
    margin: '0 auto',
  },
  button: {
    margin: '0 auto',
    maxWidth: '16rem',
    marginBottom: padding + 'rem',
  },
});

interface Props {
  textVariant?: keyof typeof HiddenIdentityTextVariant;
}

const HiddenIdentityModal: React.FC<Props> = ({ textVariant }) => {
  const classes = useStyles();
  return (
    <Modal
      body={
        <HiddenIdentityComponent
          className={classes.component}
          textVariant={textVariant}
        />
      }
      footer={
        <Button
          className={classes.button}
          color="black"
          hero={false}
          onClick={hideModal}
        >
          {_t('common.close')}
        </Button>
      }
    />
  );
};

export { HiddenIdentityTextVariant };
export default HiddenIdentityModal;
