const shallowMerge = (styles) => {
  const a = Object.values(styles);
  const f = (acc, val) => {
    if (typeof val === 'number') {
      console.warn(
        'Looks like a native stylesheet (styleSheet.create) has been supplied instead of a style object.'
      );
      return acc;
    }
    // support nested arrays
    if (Array.isArray(val)) {
      return { ...acc, ...shallowMerge(val) };
    }
    return { ...acc, ...val };
  };
  return a.reduce(f, {}) || {};
};

// append px on all numbers
const pixelate = (style) => {
  const keys = Object.keys(style);
  if (!keys) {
    return style;
  }

  const exceptions = ['fontWeight', 'opacity'];

  const newStyle = keys.reduce((acc, key) => {
    // don't replace on exceptions
    if (exceptions.find((item) => key === item)) {
      return { ...acc, [key]: style[key] };
    }

    if (typeof acc[key] === 'number') {
      return { ...acc, [key]: style[key] + 'px' };
    }

    return { ...acc, [key]: style[key] };
  }, style);
  return newStyle;
};

export const nativeToWeb = (styles) => {
  if (styles === undefined) return {};

  if (typeof styles === 'number') {
    console.warn(
      'Looks like a native stylesheet (styleSheet.create) has been supplied instead of a style object.'
    );
    return {};
  }

  if (Array.isArray(styles)) {
    return pixelate(shallowMerge(styles));
  }
  return pixelate(styles);
};
