import { z } from 'zod';

export const CaseSchema = z.object({
  id: z.number().positive(),
  conversationId: z.number(),
  displayTitle: z.string().min(1),
  uuid: z.string().optional(),
  isPatientSameAsUser: z.boolean(),
  entrywayId: z.number().positive(),
  patientUuid: z.string().min(1),
  created: z.string().min(1),
  guideName: z.string().optional(),
  doctorName: z.string().optional(),
  doctorSignature: z.string().optional(),
  status: z.number(),
  updated: z.string(),
});

export const CasesSchema = z.object({
  data: z.array(CaseSchema),
});
