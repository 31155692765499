import React, { useState } from 'react';

import { useUserActivityAcrossTabs } from '../hooks/useUserActivityAcrossTabs';
import { useUserBrowserActivity } from '../hooks/useUserBrowserActivity';

import UserActivityContext from './UserActivityContext';

interface Props {
  children?: React.ReactNode;
}

/**
 * Provides activity of the user based on some events.
 *
 * The user is considered active if:
 * - the user is active in the browser UI (e.g. by clicks mouse moving etc)
 * - the user has opened another tab and is likewise active there
 * - the user is engaged in a video call (connected)
 */
const UserActivityProvider: React.FC<Props> = ({ children }) => {
  const [isVideocallActive, setIsVideocallActive] = useState(false);

  const { isActive: isActivelyBrowsing } = useUserBrowserActivity();
  const { anyOtherTabActive } = useUserActivityAcrossTabs(
    isActivelyBrowsing || isVideocallActive
  );

  return (
    <UserActivityContext.Provider
      value={{
        isUserActive: isActivelyBrowsing || isVideocallActive,
        anyOtherTabActive,
        setIsVideocallActive,
        resetInactivityTimeout: () => console.log('Unused for web!'),
      }}
    >
      {children}
    </UserActivityContext.Provider>
  );
};

export default UserActivityProvider;
