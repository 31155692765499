import { routes } from '../../state/routing';
import { navigate } from '../routing';
import EditScreen from './edit/screen';
import QuestionsScreen from './questions/screen';

const navigation = {
  [routes.HEALTH_PROFILE]: ({ props: { childUuid, step } = {} }) =>
    navigate(QuestionsScreen, { childUuid, step }),

  [routes.HEALTH_PROFILE_EDIT]: ({ props: { index, childUuid } = {} }) =>
    navigate(EditScreen, { index, childUuid }),
};

export default navigation;
