import React from 'react';

import Button from '@mui/material/Button/Button';
import Typography from '@mui/material/Typography';

import TextLink from '@mindoktor/pulse/src/components/TextLink/web';

import { useResumeCase } from '@mindoktor/patient-app/adapters/hooks/useResumeCase';
import { useCaseById } from '@mindoktor/patient-app/cases/hooks/useCaseById';
import { useTranslation } from '@mindoktor/patient-app/localization/hooks/useTranslation';
import { routes, useNavigation } from '@mindoktor/patient-app/routing/web';

import { ThankYouMessage } from '../../../api/models/conversation';
import SystemCardLayout from '../../layout/SystemCardLayout/SystemCardLayout';

import CaseDoneIcon from './CaseDoneIcon/CaseDoneIcon';
import {
  GuideQuestionsRouteParams,
  ThankYouCardProps,
  ThankYouMessageComplete,
} from './types';

const ThankYouCard: React.FC<ThankYouCardProps> = ({ message }) => {
  const { isChildCase, caseId, entrywayId, revisitId, patientUUID } =
    message.payload;
  const t = useTranslation('messaging', 'thankYouMessage');
  const navigation = useNavigation();
  const isRevisitPossible = entrywayId > 0;

  return (
    <SystemCardLayout icon={CaseDoneIcon} title={t('title')} message={message}>
      <Typography variant="body1" whiteSpace="pre-wrap">
        {t('bodyPart1')}{' '}
        <TextLink
          onClick={() =>
            navigation.navigate<{ caseId: number }>(routes.JOURNAL_NOTE, {
              caseId,
            })
          }
          sx={{
            textDecorationThickness: '2px',
            textUnderlineOffset: '4px',
          }}
        >
          {t('yourJournal', { context: isChildCase && 'child' })}
        </TextLink>
        .
      </Typography>
      {isRevisitPossible && (
        <>
          <Typography variant="body1" whiteSpace="pre-wrap" gutterBottom>
            {t('bodyPart2')}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              navigation.navigate<
                Pick<GuideQuestionsRouteParams, 'entrywayId'>,
                Omit<GuideQuestionsRouteParams, 'entrywayId'>
              >(
                routes.GUIDES_QUESTIONS,
                {
                  entrywayId,
                },
                {
                  childUuid: isChildCase ? patientUUID : undefined,
                  revisitId,
                }
              )
            }
          >
            {t('beginARevisit')}
          </Button>
        </>
      )}
    </SystemCardLayout>
  );
};

// Adapts the ThankYou message until we move the revisit logic to the BE
const AdaptedThankYouContent: React.FC<{
  message: ThankYouMessage;
}> = ({ message }) => {
  const case_ = useCaseById(message.payload.caseId);
  const resumeCaseData = useResumeCase(case_);
  const fullMessage: ThankYouMessageComplete = {
    ...message,
    payload: {
      ...message.payload,
      entrywayId: resumeCaseData?.entrywayId ?? 0,
      patientUUID: resumeCaseData?.patientUUID ?? '',
      revisitId: resumeCaseData?.revisitId ?? 0,
    },
  };
  return <ThankYouCard message={fullMessage} />;
};

export default AdaptedThankYouContent;
