import { _t } from '@mindoktor/patient-legacy/i18n';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import colors from '../../../../common/colors';
import { openRoute, routes } from '../../../../state/routing';
import { profileIdentityProtection } from '../../../../state/profile/selectors';

import { isHelpCenterEnabled } from '../../../../state/helpCenter/selectors';
import { getSupportTicketsNotifications } from '../../../../state/userNotifications/selectors';
import { markSupportTicketNotificationsAsDone } from '../../../../state/userNotifications/actions';
import fetchHelpCenterSupportUrl, {
  allowedPage,
} from '../../../../api/helpCenter/fetchHelpCenterSupportUrl';

import Card from '../../components/card';
import CardHeader from '../../components/card_header';

import iconSupportColor from '../../../../../images/icon/icon_support_color@4x.png';

import type { SupportTicket } from '../../../../api/helpCenter/types';
import { BaseStateType } from '../../../../state/types';
import { apiHeadersSelector } from '../../../../api/api';

const styles = {
  active: {
    borderTop: `2px solid ${colors.grey_900}`,
    backgroundColor: colors.red_100,
  },
  finished: {
    backgroundColor: '#e0e0e0', // Make inactive cards gray
    opacity: 0.5,
  },
};

const connector = connect(
  (state: BaseStateType) => ({
    helpCenterEnabled: isHelpCenterEnabled(state),
    hasIdentityProtection: profileIdentityProtection(state),
    supportTicketNotifications: getSupportTicketsNotifications(state),
    apiHeaders: apiHeadersSelector(state),
  }),
  { markSupportTicketNotificationsAsDone, openRoute }
);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  isResumable?: boolean;
  style?: React.CSSProperties;
  supportTicketList: SupportTicket[];
}

/**
 * Card to display tickets from support.
 */
const SupportCard: React.FC<Props> = ({
  hasIdentityProtection,
  helpCenterEnabled,
  isResumable = true,
  markSupportTicketNotificationsAsDone,
  openRoute,
  style,
  supportTicketList,
  supportTicketNotifications,
  apiHeaders,
}) => {
  const openSupportLink = async (page: allowedPage = '', ticketId = 0) => {
    const [helpCenterLink, err] = await fetchHelpCenterSupportUrl(
      apiHeaders,
      page,
      ticketId
    );
    if (err) {
      console.error(err);
      return;
    }
    if (!helpCenterLink) {
      console.error('Found empty help center link: could not open page');
      return;
    }
    markSupportTicketNotificationsAsDone();
    window.open(helpCenterLink, '_self');
  };

  const showHiddenIdentityPage = () => {
    openRoute(routes.IDENTITY_PROTECTION_SUPPORT);
  };

  const handleSupportCenterClick = async (
    page: allowedPage = '',
    ticketId = 0
  ) => {
    if (hasIdentityProtection) {
      showHiddenIdentityPage();
      return;
    }
    await openSupportLink(page, ticketId);
  };

  if (!helpCenterEnabled) {
    return null;
  }

  if (!supportTicketList?.length) {
    return null;
  }

  return (
    <Card
      style={{
        ...(isResumable ? styles.active : styles.finished),
        ...style,
      }}
      onClick={async () =>
        // If there are multiple tickets open the list, otherwise open the specific ticket page
        supportTicketList.length > 1
          ? handleSupportCenterClick('requests')
          : handleSupportCenterClick('request', supportTicketList[0].id)
      }
      data-test-id="support-card"
    >
      <CardHeader
        icon={iconSupportColor}
        title={_t('caseCard.supportHeaderCard')}
        intro={
          supportTicketNotifications.length > 0
            ? _t('caseCard.supportNewMessage')
            : _t('caseCard.supportReadMessages', {
                amount: supportTicketList.length,
              })
        }
      />
    </Card>
  );
};

export default connector(SupportCard);
