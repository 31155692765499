const TOKEN_KEY_NAME = 'userAuthToken';

/**
 * We are no longer storing the token, but we want to keep this around
 * during the transition to ensure of removing the token and not breaking anything.
 */
const getFakeWebTokenStore = () => ({
  getToken: async () => {
    return '';
  },
  setToken: async (/** @type {string} */ newToken) => {
    return newToken;
  },
  removeToken: async () => {
    window.localStorage.removeItem(TOKEN_KEY_NAME);
  },
});

export default getFakeWebTokenStore;
