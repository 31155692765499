import { getIn, set } from 'timm';

import { postApi } from '../api/actions';
import { getCase } from '../cases/selectors';
import { FORMULARY_SUBMIT } from '../formulary/types';
import { mapAnswers } from '../formulary/utils';
import { getCurrentLocale } from '../intl/selectors';
import {
  SURVEY2_ADD,
  SURVEY2_ANSWER,
  SURVEY2_ANSWER_FAILURE,
  SURVEY2_ANSWER_SUCCESS,
  SURVEY2_END,
  SURVEY2_END_FAILURE,
} from './actionTypes';
import { surveyNames } from './constants';
import { getQuestionnaireData, getQuestionnaireKey } from './selectors';

export const addSurvey = (
  /** @type {import('./types').AddSurveyPayload} */ payload
) => ({
  type: SURVEY2_ADD,
  payload,
});

const answerBody = (state, caseId, surveyName, isCancelled) => {
  const data = getQuestionnaireData(state, caseId, surveyName);

  if (!data) {
    throw new Error(
      `There is no formularyData for survey ${surveyName} of case ${caseId}`
    );
  }

  const {
    formulary: { id, version },
    answers,
    isFinished,
  } = data;

  return {
    formulary: {
      id,
      version,
      language: getCurrentLocale(state),
      answers: mapAnswers(answers),
    },

    isFinished,
    isCancelled: isCancelled || false,
  };
};

// use this action to submit follow-up survey answers
// since the follow-up survey is using a custom end-point we can not use answerSurvey
export const answerFollowupSurvey = (uuid) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SURVEY2_ANSWER,
      payload: {
        caseId: surveyNames.FOLLOWUP_EVALUATION,
        surveyName: surveyNames.FOLLOWUP_EVALUATION,
      },
    });

    dispatch({
      type: FORMULARY_SUBMIT,
      payload: { formularyKey: surveyNames.FOLLOWUP_EVALUATION },
    });

    const { error } = await dispatch(
      postApi(
        '/api/v1/survey/followup',
        set(
          answerBody(
            getState(),
            surveyNames.FOLLOWUP_EVALUATION,
            surveyNames.FOLLOWUP_EVALUATION
          ),
          'uuid',
          uuid
        )
      )
    );

    if (error) {
      dispatch({ type: SURVEY2_ANSWER_FAILURE, payload: error });
      return;
    }

    dispatch({
      type: SURVEY2_ANSWER_SUCCESS,
      payload: {
        caseId: surveyNames.FOLLOWUP_EVALUATION,
        surveyName: surveyNames.FOLLOWUP_EVALUATION,
      },
    });
  } catch (err) {
    console.error(err);
  }
};

export const answerSurvey =
  (caseId, surveyName) => async (dispatch, getState) => {
    try {
      dispatch({ type: SURVEY2_ANSWER, payload: { caseId, surveyName } });

      const formularyKey = getIn(getState(), [
        'survey2',
        'ongoing',
        caseId,
        surveyName,
      ]);

      dispatch({
        type: FORMULARY_SUBMIT,
        payload: { formularyKey },
      });

      const { error } = await dispatch(
        postApi(
          `/api/v1/cases/${caseId}/${surveyName}`,
          answerBody(getState(), caseId, surveyName)
        )
      );

      if (error) {
        dispatch({ type: SURVEY2_ANSWER_FAILURE, payload: error });
        return;
      }

      const questionnaireData = getQuestionnaireData(
        getState(),
        caseId,
        surveyName
      );
      const answer = getIn(questionnaireData, [
        'answers',
        'survey.perceivedFriction',
      ]);

      const entrywayId = getIn(getCase(getState(), caseId), ['entrywayId']);

      dispatch({
        type: SURVEY2_ANSWER_SUCCESS,
        payload: { caseId, surveyName },
        meta: {
          track: {
            event: 'Survey.Answered',
            receiver: ['product'],
            properties: {
              answer,
              entrywayId,
              name: surveyName,
            },
          },
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

export const endSurvey = (caseId, surveyName) => (dispatch, getState) =>
  dispatch({
    type: SURVEY2_END,
    payload: {
      caseId,
      surveyName,
      formularyKey: getQuestionnaireKey(getState(), caseId, surveyName),
    },
  });

export const cancelSurvey =
  (caseId, surveyName) => async (dispatch, getState) => {
    const result = dispatch(
      postApi(
        `/api/v1/cases/${caseId}/${surveyName}`,
        answerBody(getState(), caseId, surveyName, true)
      )
    );
    const { error } = result;

    if (error) {
      dispatch({ type: SURVEY2_END_FAILURE, payload: error });
      return;
    }

    dispatch({
      type: SURVEY2_END,
      payload: {
        caseId,
        surveyName,
        formularyKey: getQuestionnaireKey(getState(), caseId, surveyName),
      },
    });
  };
