import React, { Component } from 'react';

import { colorOptions } from './button';

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: '50%',
  },
};

export default class IconButton extends Component {
  state = {
    hover: false,
  };

  onMouseEnter = () => this.setState({ hover: true });

  onMouseLeave = () => this.setState({ hover: false });

  render() {
    const { color, size, disabled, style, onClick, iconComponent } = this.props;

    const { hover } = this.state;

    const { backgroundColor, highlightColor, contentColor } =
      colorOptions[color] || colorOptions.transparent;

    const icon = iconComponent({
      style: {
        width: size * (3 / 5),
        height: size * (3 / 5),
      },
    });

    return (
      <div
        onClick={onClick}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        style={{
          ...styles.root,
          backgroundColor: hover ? highlightColor : backgroundColor,
          color: contentColor,
          width: size,
          height: size,
          opacity: disabled ? 0.33 : 1,
          ...style,
        }}
      >
        {icon}
      </div>
    );
  }
}
