import {
  ApiResponse,
  requestHandler,
} from '@mindoktor/patient-app/api/requestHandler';

import { JournalResponse } from './models/journal';
import { JournalResponseSchema } from './schema/journal';

const BASE_URL = '/api/v1/cases';

const getJournalEntries = async (
  caseId: number
): Promise<ApiResponse<JournalResponse>> => {
  return await requestHandler.get(
    `${BASE_URL}/${caseId}/journal/latest-entries`,
    {
      validationSchema: JournalResponseSchema,
    }
  );
};

export const journalApi = {
  getJournalEntries,
};
