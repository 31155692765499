import type { Dispatch } from 'redux';
import { CommonStateType } from '../types';
import {
  getSupportTicketsNotifications,
  getUserNotifications,
} from './selectors';
import fetchUserNotificationsAPI from '../../api/userNotifications/fetchUserNotifications';
import postMarkUserNotificationsAsDone from '../../api/userNotifications/postMarkUserNotificationsAsDone';
import type { UserNotification } from '../../api/userNotifications/types';
import { apiHeadersSelector } from '../../api/api';

export enum actionTypes {
  ACTION_SET_USER_NOTIFICATIONS = 'ACTION_SET_USER_NOTIFICATIONS',
}

export type Action<T = unknown> = {
  type: keyof typeof actionTypes;
  payload: T;
};

export const fetchUserNotifications =
  () =>
  async (
    dispatch: Dispatch<Action<UserNotification[]>>,
    getState: () => CommonStateType
  ) => {
    const apiHeaders = apiHeadersSelector(getState());
    const [notifications, err] = await fetchUserNotificationsAPI(apiHeaders);
    if (err) {
      console.error('Could not fetch user notifications correctly', err);
    }
    dispatch({
      type: actionTypes.ACTION_SET_USER_NOTIFICATIONS,
      payload: notifications,
    });
  };

export const markUserNotificationsAsDone =
  (doneNotifications: UserNotification[]) =>
  async (
    dispatch: Dispatch<Action<UserNotification[]>>,
    getState: () => CommonStateType
  ) => {
    const apiHeaders = apiHeadersSelector(getState());
    const err = await postMarkUserNotificationsAsDone(
      apiHeaders,
      doneNotifications
    );
    if (err) {
      console.error('Could not post done user notifications correctly', err);
    }
    const state = getState();
    const currentNotifications = getUserNotifications(state);
    const unreadNotifications = currentNotifications.filter(
      (n) => !doneNotifications.includes(n)
    );
    dispatch({
      type: actionTypes.ACTION_SET_USER_NOTIFICATIONS,
      payload: unreadNotifications,
    });
  };

// We mark all support notifications as done, as the behaviour we have for reading
// them leads directly to our help center (ATTOW Zendesk) and it exposes no API to know
// what has been read and what not.
export const markSupportTicketNotificationsAsDone =
  () =>
  async (
    dispatch: (
      action: ReturnType<typeof markUserNotificationsAsDone>
    ) => Promise<void>,
    getState: () => CommonStateType
  ) => {
    const state = getState();
    const supportTicketsNotifications = getSupportTicketsNotifications(state);
    dispatch(markUserNotificationsAsDone(supportTicketsNotifications));
  };
