import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import config from '@mindoktor/env/Config';
import { _t } from '@mindoktor/patient-legacy/i18n';
import {
  MdChildCare,
  MdContactInfo,
  MdCreditCard,
  MdDeleteAccount,
  MdHealthProfile,
  MdPowerSettingsNew,
  MdSecurity,
  MdVaccinationBook,
} from '../components/docly-md-icons';
import { useAuthentication } from '@mindoktor/patient-app/auth/hooks/useAuthentication';
import { useFeatureFlag } from '@mindoktor/patient-app/utils/featureFlags';
import {
  getChildren,
  getPatientNameAndBirthDate,
  profileIdentityProtection,
} from '../../state/profile/selectors';

import {
  formatRoute,
  openRoute,
  routes as legacyRoutes,
} from '../../state/routing';

import { goBack } from '../routing';
import {
  getSiteHowItWorksUrl,
  getSiteLandingUrl,
  getSiteOptions,
  getSiteSupportUrl,
} from '../utils/site';

import Screen from '../components/screen';
import { Body1 } from '../../common/components/typography';
import Avatar from '../components/user/avatar';
import FullFooter from '../components/footer/full';
import Logo from '../components/logo';

import Item from './item';
import UserItem from './user_item';

import fetchHelpCenterSupportUrl from '../../api/helpCenter/fetchHelpCenterSupportUrl';
import cancelIconSource from '../../../images/generated/ic_close@4x.png';
import { Connection } from '../../state/profile/types_definitions';
import { CommonStateType } from '../../state/types';
import { useAuthorization } from '@mindoktor/patient-app/user';
import { useNavigation } from '@mindoktor/patient-app/routing/hooks/useNavigation/web';
import { routes } from '@mindoktor/patient-app/routing/constants/webRoutes';
import { apiHeadersSelector } from '../../api/api';
import { useAppSelector } from '../../state/hooks';
import Img from '@mindoktor/pulse/src/utils/images/Img.web';

const styles = {
  body: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },

  logo: {
    paddingTop: 15,
    paddingBottom: 5,
    paddingLeft: 20,
    paddingRight: 20,
  },

  icon: {
    width: '24px',
    marginRight: '10px',
  },

  items: {
    padding: 10,
  },

  item: {
    padding: 10,
  },

  user: {
    paddingTop: 10,
    paddingBottom: 10,
    borderTop: '1px solid #f0f0f0',
  },

  avatarAndName: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },

  avatar: {
    marginRight: 10,
  },

  name: {
    fontWeight: 'bold',
  },

  userItems: {
    paddingTop: 10,
    paddingBottom: 10,
  },

  userItem: {
    paddingLeft: 30,
  },

  childItems: {
    borderTop: '1px solid #f0f0f0',
    paddingTop: 10,
    paddingBottom: 10,
  },

  childItem: {
    paddingLeft: 34,
  },

  footer: {
    flex: 1,
  },

  cancel: {
    position: 'fixed',
    top: 10,
    right: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 40,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    borderRadius: '50%',
    cursor: 'pointer',
  },

  cancelIcon: {
    display: 'block',
    width: 22,
    height: 22,
  },
} as const;

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

const ResponsiveMenuScreen: React.FC<Props> = (props) => {
  const authorization = useAuthorization();
  const authentication = useAuthentication();
  const helpCenterFlag = useFeatureFlag('zendesk-help-center');
  const apiHeaders = useAppSelector(apiHeadersSelector);

  const logout = async () => {
    await authentication.logout(true);
  };

  const openSupportLink = async () => {
    if (helpCenterFlag.isEnabled && authorization.isAuthorized) {
      const [helpCenterLink, _err] = await fetchHelpCenterSupportUrl(
        apiHeaders,
        'new_request'
      );
      // Send to the old contact link if we can't get the HelpCenter link
      window.open(helpCenterLink || getSiteSupportUrl(), '_self');
      return;
    }
    window.open(getSiteSupportUrl());
  };

  const showHiddenIdentityPage = () => {
    props.openRoute(legacyRoutes.IDENTITY_PROTECTION_SUPPORT);
  };

  const handleSupportCenterClick = async () => {
    if (props.hasIdentityProtection) {
      showHiddenIdentityPage();
      return;
    }
    await openSupportLink();
  };

  const { initials, fullName, children } = props;

  const navigation = useNavigation();

  return (
    <Screen
      body={
        <div style={styles.body}>
          <Logo
            height={30}
            style={styles.logo}
            onClick={() => ((window as Window).location = getSiteLandingUrl())}
          />

          <div style={styles.items}>
            <Item
              style={styles.item}
              href={`#${formatRoute(routes.GUIDES, { entrywayId: 'adult' })}`}
            >
              {_t('menu.newCase')}
            </Item>

            {authorization.isAuthorized && (
              <Item style={styles.item} href={`#${formatRoute(routes.CASES)}`}>
                {_t('menu.myCases')}
              </Item>
            )}

            {!authorization.isAuthorized && (
              <Item
                style={styles.item}
                href={`#${formatRoute(routes.LOGIN, {
                  state: formatRoute(routes.CASES),
                })}`}
              >
                {_t('common.login')}
              </Item>
            )}

            <Item style={styles.item} onClick={handleSupportCenterClick}>
              {_t('menu.support')}
            </Item>

            <Item style={styles.item} href={getSiteHowItWorksUrl()}>
              {_t('menu.howItWorks')}
            </Item>
          </div>

          {authorization.isAuthorized && (
            <div style={styles.user}>
              <div style={styles.avatarAndName}>
                <Avatar initials={initials} size={34} style={styles.avatar} />
                <Body1 style={styles.name}>{fullName}</Body1>
              </div>

              <div style={styles.userItems}>
                <UserItem
                  iconComponent={MdHealthProfile}
                  text={_t('menu.healthProfile')}
                  style={styles.userItem}
                  onClick={() =>
                    ((window as Window).location = `#${formatRoute(
                      legacyRoutes.HEALTH_PROFILE
                    )}`)
                  }
                />
                <UserItem
                  iconComponent={MdVaccinationBook}
                  text={_t('menu.vaccinationBook')}
                  style={styles.userItem}
                  onClick={() =>
                    ((window as Window).location = `#${formatRoute(
                      legacyRoutes.VACCINATION_BOOK
                    )}`)
                  }
                />

                <UserItem
                  iconComponent={MdContactInfo}
                  text={_t('menu.account')}
                  style={styles.userItem}
                  onClick={() => navigation.navigate(routes.ACCOUNT_CONTACT)}
                />

                {config.ShowFreePass && (
                  <UserItem
                    iconComponent={MdCreditCard}
                    text={_t('menu.freePass')}
                    style={styles.userItem}
                    onClick={() =>
                      ((window as Window).location = `#${formatRoute(
                        legacyRoutes.PAYMENT_FREE_PASS
                      )}`)
                    }
                  />
                )}

                {getSiteOptions().showPrivacySettings && (
                  <UserItem
                    iconComponent={MdSecurity}
                    text={_t('menu.integrity')}
                    style={styles.userItem}
                    onClick={() =>
                      ((window as Window).location = `#${formatRoute(
                        legacyRoutes.LOGIN_PRIVACY_SETTINGS
                      )}`)
                    }
                  />
                )}

                <UserItem
                  iconComponent={MdDeleteAccount}
                  text={_t('menu.deleteMyAccount')}
                  style={styles.userItem}
                  onClick={() =>
                    ((window as Window).location = `#${formatRoute(
                      routes.DELETE_ACCOUNT
                    )}`)
                  }
                />

                <UserItem
                  iconComponent={MdPowerSettingsNew}
                  text={_t('menu.logout')}
                  style={styles.userItem}
                  onClick={logout}
                />
              </div>

              {!!children && !!children.length && (
                <div style={styles.childItems}>
                  {children.map((child) => (
                    <UserItem
                      key={child.uuid}
                      iconComponent={MdChildCare}
                      text={_t('menu.healthProfileChild', {
                        name: child.firstName,
                      })}
                      small={true}
                      style={styles.childItem}
                      onClick={() =>
                        ((window as Window).location = `#${formatRoute(
                          legacyRoutes.HEALTH_PROFILE,
                          { childUuid: child.uuid }
                        )}`)
                      }
                    />
                  ))}
                </div>
              )}
            </div>
          )}

          <FullFooter style={styles.footer} />

          <div style={styles.cancel} onClick={goBack}>
            <Img src={cancelIconSource} style={styles.cancelIcon} />
          </div>
        </div>
      }
    />
  );
};

// we have an extra named export here to be able to mock the test without going
// thru mocking the redux store. In the future we might want to introduce a more
// elegant way of dealing with this since this means clients can import this
// component without going thru `connect` HOC (named import) which might
// result in some unwanted state for the component.
export const ResponsiveMenuScreenTest = ResponsiveMenuScreen;

const connector = connect(
  (state: CommonStateType) => {
    const { initials, fullName } = getPatientNameAndBirthDate(state);

    return {
      initials,
      fullName,
      children: getChildren(state) as Connection[],
      hasIdentityProtection: profileIdentityProtection(state),
    };
  },
  { openRoute }
);

export default connector(ResponsiveMenuScreen);
