import { makeStyles } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';

import colors from '../../../common/colors';
import constants from '../../../common/constants';
import { FreePassState } from '../../../state/freepass/types';
import {
  PaymentMethodType,
  paymentMethodTypes,
} from '../../../state/payment/types';
import { isValidFreePass } from '../../../state/freepass/utils';
import FreePassInfo from './freepass_info';
import InvoiceInfo from './invoice_info';

interface Props {
  freePass?: FreePassState;
  onPaymentMethodChange: (type: any) => any;
  onFreePassChange: (data: any) => any;
  allowFreePass: boolean;
}

const PaymentSelector: FC<Props> = (props) => {
  const { freePass, onPaymentMethodChange, onFreePassChange, allowFreePass } =
    props;
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodType>();

  const classes = useStyles(props);

  useEffect(() => {
    if (allowFreePass && isValidFreePass(freePass)) {
      setPaymentMethod(paymentMethodTypes.FREEPASS);
      onFreePassChange({
        issuer: freePass?.issuer ?? '',
        number: freePass?.number ?? '',
        expires: freePass?.expires ?? '',
        isValid: !freePass?.hasError,
        isChanged: false,
      });
    } else {
      // default to invoice
      setPaymentMethod(paymentMethodTypes.INVOICE);
    }
  }, [freePass]);

  useEffect(() => {
    paymentMethod !== null && onPaymentMethodChange(paymentMethod);
  }, [paymentMethod]);

  return (
    <div className={classes.root}>
      {paymentMethod === paymentMethodTypes.INVOICE && <InvoiceInfo />}

      {!!freePass && paymentMethod === paymentMethodTypes.FREEPASS && (
        <FreePassInfo freePass={freePass} />
      )}
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.red_100,
    color: colors.grey_900,
    padding: constants.spacingExtraSmall,
    borderRadius: 4,
    marginBottom: constants.spacingLarge,
  },
});

export default PaymentSelector;
