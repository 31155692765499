import React from 'react';

import { Stack, Typography } from '@mui/material';

import { colors } from '@mindoktor/pulse/src/styles/colors';

import { useTranslation } from '@mindoktor/patient-app/localization/hooks/useTranslation';

import SkandiaIcon from './SkandiaIcon';

/** Simple util function to check if user comes from a skandia environment. */
export const isSkandiaSite = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const siteParam = urlParams.get('site');

  return siteParam === 'skandia';
};

const SkandiaAttribution = () => {
  const t = useTranslation('common');

  return (
    <Stack
      direction={'row'}
      justifyContent="flex-end"
      alignItems={'center'}
      spacing={'0.75rem'}
      paddingX={'1.5rem'}
      paddingY={'0.45rem'}
      sx={{ backgroundColor: colors.skandia_color }}
    >
      <Typography variant="body2" sx={{ color: colors.white }}>
        {t('attributionPrefix')}
      </Typography>
      <SkandiaIcon
        // The somewhat ugly marginTop nugde is because of how the icon is made
        // (the minus value) and how sx works in a stack (!important).
        sx={{ marginTop: '-2px!important', width: '5rem', height: '1rem' }}
      />
    </Stack>
  );
};

export default SkandiaAttribution;
