import { _t } from '@mindoktor/patient-legacy/i18n';
import { MdPhoto } from '../../../components/docly-md-icons';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import colors from '../../../../common/colors';
import { Body3 } from '../../../../common/components/typography';
import { constructUrl } from '../../../../state/api';
import { HeaderType } from '../../../../state/cases/constants';
import { getCaseData } from '../../../../state/cases/selectors';
import { getPatientNameAndBirthDate } from '../../../../state/profile/selectors';
import AttachmentOverlay from '../../../components/attachment_overlay';
import ImageModal from '../../../components/image_modal';
import { showModal } from '../../../routing';
import { getFileData } from '../../../utils/files';
import { getSite } from '../../../utils/site';
import Message from '../../components/message';
import Header from '../components/header';

const styles = {
  failed: {
    fontWeight: 500,
    color: colors.error_text,
  },

  imageWrapper: {
    maxWidth: 400,
  },

  image: {
    display: 'block',
    maxWidth: '100%',
    maxHeight: 200,
    cursor: 'pointer',
  },

  loading: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 400,
    height: 200,
    backgroundColor: '#eee',
  },

  error: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 400,
    height: 200,
    backgroundColor: `${colors.yellow_500}50`,
    color: colors.error_text,
  },

  photoIcon: {
    display: 'block',
    width: 44,
    height: 44,
    margin: 8,
    color: 'black',
    opacity: 0.33,
  },
};

export class Image extends Component {
  state = {
    loaded: false,
    error: false,

    src: undefined,
    largeSrc: undefined,
  };

  getHeaderProps = (config) => {
    const { fromPatient } = this.props;

    const status = fromPatient ? HeaderType.patient : HeaderType.doctor;

    return config[status];
  };

  getConfig = () => {
    const {
      doctorImage,
      doctorName,
      doctorSignature,
      patientInitials,
      patientName,
    } = this.props;

    return {
      [HeaderType.patient]: {
        initials: patientInitials,
        title: patientName,
      },

      [HeaderType.doctor]: {
        image: doctorImage,
        title: doctorName,
        signature: doctorSignature,
      },
    };
  };

  readFileData = async () => {
    const { attachmentId, path } = this.props;

    if (path) {
      try {
        const src = await getFileData(path);

        if (src) {
          this.setState({ src, largeSrc: src });
          return;
        }
      } catch (e) {
        // ignore
      }
    }

    this.setState({
      src: constructUrl({
        url: `/api/v1/files/${attachmentId}`,
        params: { thumb: 'true', site: getSite() },
      }),
      largeSrc: constructUrl({
        url: `/api/v1/files/${attachmentId}`,
        params: { site: getSite() },
      }),
    });
  };

  onLoad = () => this.setState({ loaded: true });

  onError = () => this.setState({ error: true });

  componentDidMount() {
    this.readFileData();
  }

  showImageModal = () => {
    const { largeSrc } = this.state;

    showModal(ImageModal, { src: largeSrc });
  };

  render() {
    const { timestamp, status, style, attachmentReferenceNumber } = this.props;
    const { loaded, error, src } = this.state;

    const { initials, title, image, signature } = this.getHeaderProps(
      this.getConfig()
    );

    return (
      <Message style={style}>
        <Header
          image={image}
          initials={initials}
          title={title}
          signature={signature}
          timestamp={timestamp}
          status={status}
        />

        <AttachmentOverlay referenceNumber={attachmentReferenceNumber}>
          {!!src && (
            <div style={styles.imageWrapper}>
              <img
                src={src}
                style={{ ...styles.image, display: loaded ? 'block' : 'none' }}
                onLoad={this.onLoad}
                onError={this.onError}
                onClick={this.showImageModal}
              />
            </div>
          )}

          {!loaded && !error && (
            <div style={styles.loading}>
              <MdPhoto style={styles.photoIcon} />
            </div>
          )}

          {!loaded && error && (
            <Body3 style={styles.error}>
              <MdPhoto style={styles.photoIcon} />
              {_t('imageItem.failedToLoad')}
            </Body3>
          )}
        </AttachmentOverlay>
      </Message>
    );
  }
}

export default connect((state, props) => {
  const { caseId } = props;
  const { initials: patientInitials, fullName: patientName } =
    getPatientNameAndBirthDate(state);
  const { doctorImage, doctorName, doctorSignature } =
    getCaseData(state, caseId) || {};

  return {
    patientInitials,
    patientName,
    doctorImage,
    doctorName,
    doctorSignature,
  };
})(Image);
