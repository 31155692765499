import React from 'react';

import colors from '../../common/colors';
import { Body3 } from '../../common/components/typography';

const styles = {
  root: {
    padding: '15px 20px',
    backgroundColor: colors.yellow_500,
    textAlign: 'center',
  },
};

const Warning = ({ text = '' }) => <Body3 style={styles.root}>{text}</Body3>;

export default Warning;
