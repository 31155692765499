import { RefObject, useEffect, useState } from 'react';

/**
 * Checks if an element is within the vertically-scrollable parent visible content boundaries.
 * @param y - Vertical position of the element relatively to its parent.
 * @param height - Height of the element.
 * @param parentHeight - Height of the parent.
 * @param scrollOffsetY - Offset from top, where top is zero.
 * @param fullFromTop - Decides if an element needs to visible in its entirety from the top to be considered visible.
 * @param fullFromBottom - Decides if an element needs to visible in its entirety from the bottom to be considered visible.
 * @returns If the element is visible or not within the parent's content boundaries.
 *
 * The last two parameters decide what it means for an element to be visible:
 * - `false`, `false` - The element is visible if it's at least partially visible.
 * - `false`, `true` (default) - The element is visible if its bottom is visible.
 * - `true`, `false` - The element is visible if its top is visible.
 * - `true`, `true` - The element is visible if it's entirely visible.
 */
export const isVScrolledElementVisible = (
  y: number,
  height: number,
  parentHeight: number,
  scrollOffsetY: number,
  fullFromTop: boolean = false,
  fullFromBottom: boolean = true
): boolean => {
  const isLowerThanTop = scrollOffsetY <= y + (fullFromTop ? 0 : height);
  const isHigherThanBottom =
    scrollOffsetY >= y - parentHeight + (fullFromBottom ? height : 0);
  const isVisible = isLowerThanTop && isHigherThanBottom;

  return isVisible;
};

/** Hook to check if an element is visible in the vertically-scrollable parent container. */
export const useIsVisible = <T extends HTMLDivElement | null>(
  elementRef: RefObject<T>,
  parentElementRef: RefObject<T>
) => {
  const [scrollOffsetY, setScrollOffsetY] = useState(-Infinity);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (scrollOffsetY > -Infinity) {
      if (elementRef.current == null) {
        return;
      }

      if (parentElementRef.current == null) {
        console.warn(
          'Ref to container not available. Did you pass the it to your component?'
        );
        return;
      }

      if (elementRef.current != null) {
        return checkVisibilityWeb(elementRef.current, parentElementRef.current);
      }
    }
  }, [scrollOffsetY]);

  const checkVisibilityWeb = (
    element: HTMLDivElement,
    container: HTMLDivElement
  ) => {
    const elementRect = element.getBoundingClientRect();
    const containerRect = container.getBoundingClientRect();
    const relativeY = elementRect.y - containerRect.y + scrollOffsetY; // scroll independent y position w.r.t. its container
    const elementIsVisible = isVScrolledElementVisible(
      relativeY,
      elementRect.height,
      containerRect.height,
      scrollOffsetY
    );

    if (isVisible !== elementIsVisible) {
      setIsVisible(elementIsVisible);
    }
  };

  return { isVisible, setScrollOffsetY };
};
