import config from '@mindoktor/env/Config';
import { _t } from '@mindoktor/patient-legacy/i18n';
import { getIn } from 'timm';

import { MESSAGE_POST, MESSAGES_RECEIVED } from '../cases/actionTypes';
import { addFormulary, clearFormulary } from '../formulary/actions';
import { getQuestionnaireData } from '../formulary/selectors';
import { showNotification } from '../notifications/actions';
import { addSurvey, endSurvey } from './actions';
import {
  SURVEY2_ANSWER_SUCCESS,
  SURVEY2_END,
  SURVEY2_SHOW_MEDICAL_COMPLAINTS_INFO,
} from './actionTypes';
import { surveyNames } from './constants';
import consultationChecker from './consultationChecker';
import onboardingChecker from './onboardingChecker';

// type Options = {
//   cancelSurveyCheckers: {
//     [surveyName: string]: CancelSurveyChecker,
//   },
// };

export const middlewareFactory =
  ({ cancelSurveyCheckers: checkers }) =>
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    // The switch case is dependent on the new state
    const result = next(action);

    const notify = (formularyKey) => {
      const state = getState();
      const questionnaireData = getQuestionnaireData(state, formularyKey);

      if (!questionnaireData || !questionnaireData.answers) return;

      if (Object.keys(questionnaireData.answers).length) {
        dispatch(
          showNotification({
            message: _t('survey.thankYouNotification'),
            type: 'success',
          })
        );
      }
    };

    const checkAndEnd = (caseId) => (surveyName) => {
      const checker = checkers[surveyName];
      if (checker && checker({ caseId, surveyName, state: getState() })) {
        dispatch(endSurvey(caseId, surveyName));
      }
    };

    switch (action.type) {
      case MESSAGES_RECEIVED: {
        const { payload: { survey, caseId } = {} } = action;
        const state = getState();

        if (survey) {
          const { formulary, answers, formularyKey, surveyName, caseId } =
            survey;

          if (!getIn(state, ['formulary', 'byKey', formularyKey])) {
            dispatch(
              addFormulary({
                type: 'questionnaire',
                formulary,
                answers,
                key: formularyKey,
              })
            );

            dispatch(addSurvey({ caseId, surveyName, formularyKey }));
          }
        }

        if (
          state.survey2 &&
          state.survey2.ongoing &&
          state.survey2.ongoing[caseId]
        ) {
          Object.keys(state.survey2.ongoing[caseId]).forEach(
            checkAndEnd(caseId)
          );
        }

        break;
      }

      case SURVEY2_ANSWER_SUCCESS: {
        const { payload: { caseId, surveyName } = {} } = action;
        checkAndEnd(caseId)(surveyName);
        break;
      }

      case MESSAGE_POST:
        // eslint-disable-next-line no-case-declarations
        const { payload: { caseId } = {} } = action;
        if (!caseId) break;
        getState().survey2.ongoing[caseId] &&
          Object.keys(getState().survey2.ongoing[caseId]).forEach(
            checkAndEnd(caseId)
          );
        break;

      case SURVEY2_END: {
        const { payload: { formularyKey, surveyName } = {} } = action;
        notify(formularyKey);

        if (
          surveyName === surveyNames.CONSULTATION_EVALUATION &&
          config.RequireMedicalComplaintsInfo
        ) {
          dispatch({ type: SURVEY2_SHOW_MEDICAL_COMPLAINTS_INFO });
        }

        dispatch(clearFormulary({ formularyKey }));
        break;
      }
    }

    return result;
  };

const options = {
  cancelSurveyCheckers: {
    [surveyNames.ONBOARDING_EVALUATION]: onboardingChecker,
    [surveyNames.CONSULTATION_EVALUATION]: consultationChecker,
  },
};

export default middlewareFactory(options);
