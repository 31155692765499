import React, { FC } from 'react';
import { _t } from '@mindoktor/patient-legacy/i18n';

import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { FreePassEditData, FreePassState } from '../../../state/freepass/types';
import regions from '../../../state/freepass/regions';
import { useFreePassEdit } from '../../../state/freepass/useFreePassEdit';

import Link from '../../components/link';
import colors from '../../../common/colors';
import BottomSheet from '@mindoktor/pulse/src/components/BottomSheet/BottomSheet';
import BottomSheetContent from '@mindoktor/pulse/src/components/BottomSheetContent/web';
import BottomSheetHeader from '@mindoktor/pulse/src/components/BottomSheetHeader/web';
import BottomSheetActions from '@mindoktor/pulse/src/components/BottomSheetActions/web';

const regionOptions = regions.map((region) => ({
  label: region.short,
  value: region.region,
}));

interface Props {
  submitText?: string;
  freePass: FreePassState;
  isOpen: boolean;
  onClose: () => void;
  submitCallback?: (freepass: FreePassEditData) => void;
  immediateValidation?: boolean;
}

const EditFreePassModal: FC<Props> = ({
  freePass,
  isOpen,
  onClose,
  submitCallback,
  submitText = _t('common.save'),
  immediateValidation = false,
}) => {
  const {
    currentFreePass,
    isValid,
    submitted,
    errors,
    handleChange,
    handleSubmit,
  } = useFreePassEdit(freePass, onClose, submitCallback, immediateValidation);

  return (
    <BottomSheet isOpen={isOpen} onClose={onClose}>
      <BottomSheetHeader
        ariaLabelForClose={_t('common.close')}
        onClose={onClose}
      >
        <Typography variant="h2">
          {_t('payment.cart.leadGenericAddFreePass')}
        </Typography>
      </BottomSheetHeader>
      <BottomSheetContent>
        <Typography variant="body1" gutterBottom>
          {_t('payment.paymentSelection.freecardInfoModal.bodyPart1')}
        </Typography>
        <Typography variant="body1" mb="1.5rem">
          {_t('payment.paymentSelection.freecardEditModal.bodyPart2')}
        </Typography>

        <FormControl error={submitted && !!errors?.issuer}>
          <InputLabel htmlFor="issuer-select-filled">
            {_t('freePass.issuedByCounty')}
          </InputLabel>
          <Select
            id="issuer-select-filled"
            native
            defaultValue={currentFreePass?.issuer ?? '-'}
            onChange={(event) => handleChange('issuer', event.target.value)}
          >
            <option aria-label={_t('common.notSelected')} value="-">
              {_t('common.notSelected')}
            </option>
            {regionOptions.map((option, i) => (
              <option key={i} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
          {submitted && errors?.issuer && (
            <FormHelperText>{errors.issuer}</FormHelperText>
          )}
        </FormControl>

        <TextField
          label={_t('freePass.number.label')}
          value={currentFreePass?.number}
          onChange={(event) => handleChange('number', event.target.value)}
          error={submitted && !!errors?.number}
          helperText={submitted && errors?.number}
        />

        <TextField
          label={_t('freePass.expires.label')}
          value={currentFreePass?.expires}
          onChange={(event) => handleChange('expires', event.target.value)}
          error={submitted && !!errors?.expires}
          helperText={submitted && errors?.expires}
        />

        {/* @ts-ignore "...cannot be used as a JSX component" */}
        <Link
          href={'http://www.1177.se/Regler-och-rattigheter/Hogkostnadsskydd/'}
          target="_blank"
        >
          <Typography variant="body1" fontWeight="medium" color="hero">
            {_t('freePass.1177link')}
          </Typography>
        </Link>
        <Typography variant="body2" color={colors.grey_700} mb={'2rem'}>
          {_t('freePass.1177link.description')}
        </Typography>
      </BottomSheetContent>
      <BottomSheetActions>
        <Button
          onClick={handleSubmit}
          disabled={submitted && !isValid}
          color="primary"
          aria-label={submitText}
        >
          {submitText}
        </Button>
      </BottomSheetActions>
    </BottomSheet>
  );
};

export default EditFreePassModal;
