import React, { PropsWithChildren } from 'react';

import { Box, Stack, Typography } from '@mui/material';

import { colors } from '@mindoktor/pulse/src/styles/colors';
import { rem } from '@mindoktor/pulse/src/styles/conversions';
import { fonts, weights } from '@mindoktor/pulse/src/styles/fonts';

import { useTranslation } from '@mindoktor/patient-app/localization/hooks/useTranslation';

import { ImageAttachmentOverlayProps } from './types';

const ImageAttachmentOverlay: React.FC<
  PropsWithChildren<ImageAttachmentOverlayProps>
> = ({ fileReferenceNumber, children }) => {
  const t = useTranslation('messaging', 'attachment');
  return (
    <Box position="relative" height="100%">
      {children}
      {fileReferenceNumber != null && (
        <Stack
          display="flex"
          alignItems="center"
          justifyContent="center"
          position="absolute"
          top={rem(9 / 16)}
          left={rem(9 / 16)}
          sx={{ backgroundColor: colors.red[500] }}
          borderRadius={rem(0.25)}
          paddingX={rem(0.5)}
        >
          <Typography
            fontSize={fonts.body2.size}
            fontWeight={weights.semibold}
            textAlign="center"
            color={colors.white}
          >
            {t('fileReferenceNumberImage', { fileReferenceNumber })}
          </Typography>
        </Stack>
      )}
    </Box>
  );
};

export default ImageAttachmentOverlay;
