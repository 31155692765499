import { useMutation, UseMutationResult } from '@tanstack/react-query';

import { ResponseError } from '@mindoktor/patient-app/api/responseError';

import { filesApi } from '../api/files';
import { PostFileRequest, PostFileResponse } from '../api/models/files';

type FileMutationResult = UseMutationResult<
  PostFileResponse | undefined,
  ResponseError,
  PostFileRequest
>;

export const useFileMutator = (): FileMutationResult => {
  return useMutation(
    async (file) => {
      const response = await filesApi.postFile(file);
      if (!response.ok) {
        throw new ResponseError(response);
      }
      return response.data;
    },
    {
      retry: false,
    }
  );
};
