import { _t } from '@mindoktor/patient-legacy/i18n';
import React from 'react';

import certico from '../../../../images/company/logo_certico.png';
import lf from '../../../../images/company/logo_lf.png';
import skandia from '../../../../images/company/logo_skandia.svg';
import th from '../../../../images/company/logo_th.png';
import wh from '../../../../images/company/logo_wh.svg';
import { Body3 } from '../../../common/components/typography';
import { getSiteOptions } from '../../utils/site';
import Img from '@mindoktor/pulse/src/utils/images/Img.web';

const styles = {
  inner: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: 'calc(100% - 40px)',
    maxWidth: 960,
    height: 50,
    margin: '0 auto',
    paddingLeft: 20,
    paddingRight: 20,
  },

  text: {
    color: 'white',
  },

  logo: {
    display: 'block',
    height: 26,
    marginLeft: 10,
  },
};

const Attribution = () => {
  const { key, color, showCompanyAttribution } = getSiteOptions();

  const logoSource = {
    lf,
    skandia,
    wh,
    th,
    certico,
  }[key];

  const companyName = {
    ea: 'Euro Accident',
  }[key];

  return showCompanyAttribution ? (
    <div style={{ backgroundColor: color }}>
      <div style={styles.inner}>
        <Body3 style={styles.text}>
          {_t('company.attribution.prefix')}
          {companyName && ' ' + companyName}
        </Body3>
        {!!logoSource && (
          <Img
            src={logoSource}
            style={{
              ...styles.logo,
              ...{
                skandia: {
                  height: 16,
                },
                wh: {
                  height: 20,
                },
                th: {
                  height: 14,
                },
              }[key],
            }}
          />
        )}
      </div>
    </div>
  ) : null;
};

export default Attribution;
