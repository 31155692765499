import { _t } from '@mindoktor/patient-legacy/i18n';
import React, { Component } from 'react';

import logoSource from '../../../../images/logo/heart_alone@2x.png';
import colors from '../../../common/colors';
import { Body3 } from '../../../common/components/typography';
import { getSiteCookiesUrl, getSiteTermsUrl } from '../../utils/site';
import Link from './link';
import Img from '@mindoktor/pulse/src/utils/images/Img.web';

const styles = {
  root: {
    backgroundColor: colors.grey_100,
  },

  inner: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: 960,
    margin: '0 auto',
    padding: 20,
  },

  left: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  logo: {
    display: 'block',
    width: 35,
    height: 27,
    marginRight: 10,
  },

  copyright: {
    color: '#646464',
  },

  right: {
    display: 'flex',
    flexDirection: 'row',
  },

  link: {
    marginLeft: 15,
  },
};

export default class SimpleFooter extends Component {
  render() {
    const { style } = this.props;

    return (
      <div style={{ ...styles.root, ...style }}>
        <div style={styles.inner}>
          <div style={styles.left}>
            <Img src={logoSource} style={styles.logo} />
            <Body3 style={styles.copyright}>
              © {new Date().getFullYear()} Docly Healthcare AB
            </Body3>
          </div>

          <div style={styles.right}>
            <Link href={getSiteTermsUrl()} style={styles.link}>
              {_t('footer.service.terms')}
            </Link>

            <Link href={getSiteCookiesUrl()} style={styles.link}>
              {_t('footer.service.cookies')}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
