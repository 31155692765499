import withStyles from '@material-ui/core/styles/withStyles';
import { _t } from '@mindoktor/patient-legacy/i18n';
import React from 'react';

import Next from '../components/Next';

class Info extends React.Component {
  render() {
    const { done, classes } = this.props;

    return (
      <div className={classes.root}>
        <Next
          label={_t('formularyNavigation.next')}
          onClick={() => done('yes')}
        />
      </div>
    );
  }
}

export default withStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
}))(Info);
