import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  Body1,
  Body3,
  Title1,
  Title2,
} from '../../../common/components/typography';
import { requestToBeForgotten } from '../../../state/profile/actions';
import Button from '../../components/button/button';
import TruncatedFooter from '../../components/footer/truncated';
import Option from '../../components/input/option';
import Link from '../../components/link';
import MediaQuery from '../../components/media_query';
import Modal from '../../components/modal';
import Screen from '../../components/screen';
import MenuBar from '../../menu/bar';
import { hideModal, showModal } from '../../routing';
import { getSiteSupportUrl } from '../../utils/site';
import { withRouting, routes } from '@mindoktor/patient-app/routing/web';

const styles = {
  wrapper: {
    width: '100%',
    maxWidth: 800,
    margin: '0 auto',
    paddingTop: 20,
    paddingBottom: 20,
  },

  body: {
    maxWidth: 600,
    paddingLeft: 20,
    paddingRight: 20,
  },

  title: {
    marginBottom: 10,
  },

  section: {
    marginBottom: 20,
  },

  option: {
    marginLeft: -20,
    marginBottom: 20,
  },
};

const ConfirmModal = ({ submit }) => (
  <Modal
    body={
      <div>
        <Title1 style={styles.title}>Rätten att bli glömd</Title1>

        <Body1 style={styles.section}>
          Om du åberopar rätten att bli glömd finns ingen möjlighet att
          återskapa uppgifterna.
        </Body1>

        <Body1>Är du säker på att du vill åberopa rätten att bli glömd?</Body1>
      </div>
    }
    footer={
      <div>
        <Button style={styles.section} onClick={submit}>
          Ja
        </Button>

        <Button type="ghost" color="black" onClick={hideModal}>
          Nej
        </Button>
      </div>
    }
  />
);

class ForgetConfirmScreen extends Component {
  state = {
    confirmed: false,
    submitting: false,
  };

  onConfirmedChange = () => this.setState({ confirmed: !this.state.confirmed });

  showConfirmModal = () => showModal(ConfirmModal, { submit: this.submit });

  submit = async () => {
    const {
      requestToBeForgotten,
      routing: { navigation },
    } = this.props;

    hideModal();

    this.setState({ submitting: true });

    const { error } = await requestToBeForgotten();

    if (error) {
      this.setState({ submitting: false });
    } else {
      navigation.navigate(routes.ACCOUNT_FORGET_SUCCESS);
    }
  };

  render() {
    const { confirmed, submitting } = this.state;
    return (
      <Screen
        header={<MenuBar />}
        body={
          <div style={styles.wrapper}>
            <div style={styles.body}>
              <Title1 style={styles.title}>Rätten att bli glömd</Title1>

              <Body1 style={styles.section}>
                Din personliga integritet är mycket viktig för oss. För att du
                ska kunna få en patientsäker behandling behöver vi viss
                information om dig. Du har dock alltid möjlighet att åberopa den
                så kallade rätten att bli glömd.
              </Body1>

              <Title2 style={styles.title}>
                Vad innebär rätten att bli glömd?
              </Title2>

              <Body1 style={styles.section}>
                Rätten att bli glömd innebär att du kan begära att Min Doktor
                raderar de personuppgifter om dig, som inte är knutna till din
                journal.
              </Body1>

              <Body1 style={styles.section}>
                Journaluppgifterna, inklusive kontaktuppgifter, är vi i egenskap
                av vårdgivare skyldiga att behålla i tio år med hänvisning till
                patientsäkerhetslagen och patientdatalagen. Anledningen till
                detta är din egen säkerhet, då journaluppgifterna kan komma att
                behövas om du blir sjuk vid ett senare tillfälle.
              </Body1>

              <Body1 style={styles.section}>
                Om du har barn knutna till ditt konto hos Min Doktor sparas
                heller ingen information om dina barn utöver journaluppgifterna.
              </Body1>

              <Body1 style={styles.section}>
                Att åberopa rätten att bli glömd påverkar inte dina framtida
                möjligheter att använda Min Doktor.
              </Body1>

              <Title2 style={styles.title}>Support</Title2>

              <Body1 style={styles.section}>
                Har du funderingar kring hur vi hanterar och skyddar dina
                uppgifter eller om annat som rör din integritet? Eller vill du
                begära ut en kopia av din journal? Välkommen att kontakta vår{' '}
                <Link href={getSiteSupportUrl()}>support</Link>. Vi finns till
                för att hjälpa dig och för att du ska känna dig trygg hos Min
                Doktor.
              </Body1>

              <Title2 style={styles.title}>
                Vad händer när jag åberopat rätten att bli glömd?
              </Title2>

              <Body1 style={styles.section}>
                Inom 30 dagar raderar vi dina personuppgifter. Du får, för din
                egen säkerhet, ett brev till din folkbokföringsadress som
                bekräftar att uppgifterna raderats.
              </Body1>

              <Body3 style={styles.section}>
                Observera att om du har skyddade personuppgifter kan vi inte se
                din folkbokföringsadress. Vi följer Skatteverkets process för
                utskick till personer med skyddade personuppgifter.
              </Body3>

              <div style={styles.option}>
                <Option
                  label="Jag åberopar rätten att bli glömd."
                  checked={confirmed}
                  multiple={true}
                  lastItem={true}
                  onChange={this.onConfirmedChange}
                />
              </div>

              <Button
                disabled={submitting || !confirmed}
                onClick={this.showConfirmModal}
              >
                Skicka
              </Button>
            </div>
          </div>
        }
        footer={
          <MediaQuery minWidth={801}>
            <TruncatedFooter />
          </MediaQuery>
        }
        showScrollGradient={true}
      />
    );
  }
}

export default withRouting(
  connect(undefined, { requestToBeForgotten })(ForgetConfirmScreen)
);
