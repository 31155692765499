// @ flow weak
import isEqual from 'lodash-es/isEqual';
import { addFirst, merge, mergeIn, omit, setIn } from 'timm';

import { LOGOUT } from '../api/actions';
import {
  CASE_ANSWERS_REQUEST,
  CASE_ANSWERS_REQUEST_ERROR,
  CASE_ANSWERS_REQUEST_SUCCESS,
  CASE_CANCEL_REQUEST_SUCCESS,
  CASE_VIDEOCALL_ACCEPT,
  CASE_VIDEOCALL_RESCHEDULE,
  CASE_VIEW,
  CASES_RECEIVED,
  CASES_REQUEST,
  CASES_REQUEST_ERROR,
  MESSAGE_INCOMING,
  MESSAGE_POST,
  MESSAGE_POST_ERROR,
  MESSAGE_POST_SUCCESS,
  MESSAGES_RECEIVED,
  MESSAGES_REQUEST,
  MESSAGES_REQUEST_ERROR,
  UNREAD_MESSAGES,
} from './actionTypes';
import { CaseStatuses, MessageSendStatus, VideocallStatus } from './constants';

/** @type {import('./types').CasesState} */
export const initialState = {
  casesById: {},
  allCaseIds: [],
  caseIds: [],
  closedCaseIds: [],
  openCaseIds: [],
  messagesById: {},
  isLoading: false,
  hasLoaded: false,
  unread: {
    messageCount: 0,
    messageCases: [],
  },
  answers: { isLoading: true },
};

export default function caseReducer(state = initialState, action) {
  const { caseId, cId } = action.payload || {};

  switch (action.type) {
    // set loading flag for now
    case CASES_REQUEST:
    case MESSAGES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case CASES_REQUEST_ERROR:
    case MESSAGES_REQUEST_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case UNREAD_MESSAGES:
      const { messageCount, messageCases } = action.payload;
      return {
        ...state,
        unread: {
          messageCount,
          messageCases: [...messageCases],
        },
      };
    case CASES_RECEIVED: {
      const { casesById } = action.payload;

      const allCases = Object.keys(casesById).map(
        (caseId) => casesById[caseId]
      );

      const messagesById =
        Object.keys(action.payload.messagesById).length === 0
          ? state.messagesById
          : { ...state.messagesById, ...action.payload.messagesById };

      return {
        ...state,
        isLoading: false,
        hasLoaded: true,
        casesById: action.payload.casesById,
        allCaseIds: Object.keys(action.payload.casesById),
        caseIds: allCases
          .filter((caseData) => !caseData.revisitParentCaseId)
          .map((caseData) => caseData.id),

        openCaseIds: allCases
          .filter(
            ({ status }) =>
              status === CaseStatuses.InProgress ||
              status === CaseStatuses.Pending ||
              status === CaseStatuses.AutomationEvaluating
          )
          .map(({ id }) => id),

        closedCaseIds: allCases
          .filter(
            ({ status }) =>
              status === CaseStatuses.Finished ||
              status === CaseStatuses.ClosedByDoctor
          )
          .map(({ id }) => id),
        messagesById,
      };
    }

    case MESSAGES_RECEIVED: {
      // received messages might also include changes to case data
      const { messageIds, messagesById, ...props } = action.payload;
      return {
        ...state,
        isLoading: false,
        casesById: isEqual(state.casesById[caseId].messages, messageIds)
          ? state.casesById
          : mergeIn(state.casesById, [caseId], {
              messages: messageIds,
              ...props,
            }),
        messagesById: merge(state.messagesById, messagesById),
      };
    }

    case CASE_VIEW: {
      const newState = { ...state };
      newState.casesById[
        action.payload.caseId
      ].unreadMessageCountFromCaregiver = 0;

      return newState;
    }

    case MESSAGE_POST: {
      return {
        ...state,
        casesById: state.messagesById[cId]
          ? state.casesById
          : setIn(
              state.casesById,
              [[caseId], 'messages'],
              addFirst(state.casesById[caseId].messages, cId)
            ),
        messagesById: setIn(state.messagesById, [[cId]], {
          ...action.payload,
          sendStatus: MessageSendStatus.SENDING,
        }),
      };
    }

    case MESSAGE_POST_SUCCESS:
      return mergeIn(state, ['messagesById', [cId]], {
        // image: adds attachmentId to
        // text post: adds id + timestamp
        ...action.payload,
        sendStatus: MessageSendStatus.DONE,
      });

    case MESSAGE_POST_ERROR:
      return setIn(
        state,
        ['messagesById', [cId], 'sendStatus'],
        MessageSendStatus.FAILED
      );

    case MESSAGE_INCOMING: {
      const newState = { ...state };
      newState.casesById[action.payload.caseId]
        .unreadMessageCountFromCaregiver++;

      return newState;
    }

    case CASE_VIDEOCALL_ACCEPT: {
      if (!cId) return state;
      return setIn(
        state,
        ['messagesById', [cId], 'status'],
        VideocallStatus.ACCEPTED
      );
    }

    case CASE_VIDEOCALL_RESCHEDULE: {
      if (!cId) return state;
      return setIn(
        state,
        ['messagesById', [cId], 'status'],
        VideocallStatus.REJECTED
      );
    }

    case CASE_CANCEL_REQUEST_SUCCESS: {
      if (!caseId) return state;

      return {
        ...state,
        casesById: omit(state.casesById, caseId),
        allCaseIds: state.allCaseIds.filter((id) => id !== caseId),
        caseIds: state.caseIds.filter((id) => id !== caseId),
        openCaseIds: state.openCaseIds.filter((id) => id !== caseId),
      };
    }

    case CASE_ANSWERS_REQUEST: {
      return {
        ...state,
        answers: { isLoading: true },
      };
    }
    case CASE_ANSWERS_REQUEST_ERROR: {
      const { error } = action;
      return {
        ...state,
        answers: { error },
      };
    }
    case CASE_ANSWERS_REQUEST_SUCCESS: {
      const { caseAnswers } = action.payload;

      return {
        ...state,
        answers: {
          caseAnswers,
          isLoading: false,
        },
      };
    }

    case LOGOUT:
      return initialState;

    default:
      return state;
  }
}
