import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

import { SvgIconComponent as Icon } from '../../../icons/DownloadAppQRCode.svg';

/**
 * QR Code content: https://mdlink.se/vaccinationbook-web
 */
const DownloadAppQRCodeIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon inheritViewBox component={Icon} {...props} />
);

export default DownloadAppQRCodeIcon;
