import { API_URL, DEFAULT_HEADERS } from '../api';
import { generateRandomId } from '../utils/random';

export const fetchHostConfig = async (site) => {
  const response = await fetch(
    `${API_URL}/api/v1/host?site=${site}&request_id=${generateRandomId()}`,
    {
      mode: 'cors',
      credentials: 'include',
      headers: DEFAULT_HEADERS,
    }
  );

  if (response && response.status === 200) {
    const json = await response.json();

    const host = json.data;

    return { host };
  }

  return { error: true };
};
