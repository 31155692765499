import './styles.css';

import React from 'react';

const Checkbox = ({ onChange, checked, label, labelStyle }) => (
  <label className="material-checkbox">
    <input type="checkbox" onChange={onChange} checked={checked} />
    <span style={labelStyle || {}}>{label}</span>
  </label>
);

export default Checkbox;
