import { _t } from '@mindoktor/patient-legacy/i18n';

import { DEFAULT_RANGE_MAX_VALUE } from '../../state/formulary/transforms';

const getFirstTextFromElement = (element) => {
  if (!element) return '';

  if (element.data) {
    return element.data.replace(/\s+/g, ' ');
  }

  if (element.childNodes) {
    for (let i = 0; i < element.childNodes.length; i++) {
      const text = getFirstTextFromElement(element.childNodes[i]);

      if (text) return text;
    }
  }

  return '';
};

const getFirstTextFromHtml = (html) => {
  if (!html) return '';

  const sanitizedHtml = ('<div>' + (html || '') + '</div>')
    .trim()
    .replace(/>\s+</g, '><')
    .replace(/<!--(.*?)-->/g, '');

  const root = new DOMParser().parseFromString(sanitizedHtml, 'text/html');

  if (!root.body) return '';

  const text = getFirstTextFromElement(root.body);

  return text;
};

export const getDisplayLabel = (node) => {
  if (!node) return '';

  return (
    node.label ||
    getFirstTextFromHtml(node.prologue) ||
    getFirstTextFromHtml(node.description) ||
    ''
  ).trim();
};

export const getDisplayAnswer = (node, value) => {
  const { type, options = [], unit, max, validations = {} } = node;

  if (type === 'info') return _t('common.back');

  if (value === undefined) {
    if (type === 'multipleChoice' && !validations.required) {
      return _t('formulary.displayValue.unansweredMultipleChoice');
    }

    return _t('formulary.displayValue.unanswered');
  }

  switch (type) {
    case 'binary':
      return value === 'yes'
        ? _t('formulary.displayValue.yes')
        : _t('formulary.displayValue.no');

    case 'choice':
    case 'duration':
      return Array.from(options)
        .filter((o) => o.value === value)
        .map((o) => o.label)
        .join('\n');

    case 'confirm':
      return _t('formulary.displayValue.yes');

    case 'medication':
      return value === 'other'
        ? _t('formulary.displayValue.other')
        : // $FlowFixMe
          value.label + (value.description ? ` (${value.description})` : '');

    case 'multipleChoice':
      return Array.from(options)
        .filter((o) => value && value.indexOf(o.value) > -1)
        .map((o) => o.label)
        .join(', ');

    case 'number':
      return value + ' ' + (unit || '');

    case 'range':
      return (
        // $FlowFixMe
        Math.round(value) +
        '/' +
        ((max && max.value) || DEFAULT_RANGE_MAX_VALUE)
      );

    case 'tertiary':
      return value === 'yes'
        ? _t('formulary.displayValue.yes')
        : value === 'no'
        ? _t('formulary.displayValue.no')
        : _t('formulary.displayValue.unknown');

    case 'text':
      return String(value);

    case 'upload':
      return value.length === 1
        ? _t('formulary.display.uploadedOne')
        : _t('formulary.display.uploadedMultiple', { count: value.length });

    case 'multipleSearch':
      // $FlowFixMe
      return value
        .map((o) => {
          return o && `${o.Label} ${o.Description}`;
        })
        .join('\n');

    default:
      return String(value);
  }
};

export const getDisplayError = (node, error) => {
  const { messages, unit } = node;

  let message;

  if (error) {
    message = messages && messages[error];

    if (!message) {
      switch (error) {
        case 'required':
          message = _t('formulary.error.required');
          break;

        case 'min':
          message = _t('formulary.error.min');
          break;

        case 'max':
          message = _t('formulary.error.max');
          break;

        case 'pattern':
          message = _t('formulary.error.pattern');
          break;

        case 'condition':
          message = _t('formulary.error.condition');
          break;

        default:
          message = '';
          break;
      }

      message += (unit ? ` (${unit})` : '') + '.';
    }
  }

  return message;
};
