import React, { Component } from 'react';
import { connect } from 'react-redux';

import colors from '../../common/colors';
import { Body1, Title2 } from '../../common/components/typography';
import { questionShown, updateFormulary } from '../../state/formulary/actions';
import { getFormularyState } from '../../state/formulary/selectors';
import LimitedHtml from '../components/html/limited';
import Link from '../components/link';
import { showModal } from '../routing';
import HtmlModal from './components/html_modal';
import Input from './input';

const styles = {
  inner: {
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },

  label: {
    paddingBottom: 10,
  },

  prologue: {
    paddingBottom: 10,
  },

  description: {
    paddingBottom: 10,
  },

  modal: {
    paddingBottom: 10,
  },

  contextDescription: {
    marginBottom: '24px',
    paddingBottom: '12px',
    borderBottom: `1px solid ${colors.grey_500}`,
  },
};

// type Props = {
//   formularyKey: string,

//   node: QuestionType,

//   answer?: any,
//   error?: string,
//   options?: Option[],
//   contextDescription?: string,

//   next: () => any,

//   updateFormulary: typeof updateFormulary,

//   style?: any,
// };

export class Question extends Component {
  static defaultProps = {
    next: () => {},
  };

  showModal = () => {
    const {
      node: { modal },
    } = this.props;

    showModal(HtmlModal, {
      html: modal && modal.content,
    });
  };

  update = async (value) => {
    const {
      formularyKey,
      node: { id },
      updateFormulary,
    } = this.props;

    await updateFormulary({ formularyKey, answers: { [id]: value } });
  };

  componentDidMount() {
    const { formularyKey, questionShown } = this.props;
    questionShown({ formularyKey });
  }

  render() {
    const {
      node,
      answer,
      error,
      options,
      next,
      style,
      contextDescription,
      entrywayId,
    } = this.props;

    return (
      <div style={style}>
        <div style={styles.inner}>
          {!!node.prologue && (
            <LimitedHtml html={node.prologue} style={styles.prologue} />
          )}

          {!!contextDescription && (
            <LimitedHtml
              html={contextDescription}
              style={styles.contextDescription}
            />
          )}

          {node.type !== 'confirm' && !!node.label && (
            <Title2
              style={{
                ...styles.label,
              }}
            >
              {node.label}
            </Title2>
          )}

          {!!node.description && (
            <LimitedHtml html={node.description} style={styles.description} />
          )}

          {!!node.modal && (
            <div style={styles.modal}>
              <Link textComponent={Body1} onClick={this.showModal}>
                {node.modal.link}
              </Link>
            </div>
          )}
        </div>

        <Input
          node={node}
          answer={answer}
          error={error}
          options={options}
          update={this.update}
          next={next}
          entrywayId={entrywayId}
        />
      </div>
    );
  }
}

export default connect(
  (state, props) => {
    const { formularyKey, node } = props;

    if (!formularyKey) return {};

    const { answers, errors, visibility, contextDescriptions, entrywayId } =
      getFormularyState(state, formularyKey) || {};

    return {
      answer: answers && answers[node.id],
      error: errors && errors[node.id],
      contextDescription: contextDescriptions && contextDescriptions[node.id],
      options:
        node.options && !!node.options.find((o) => !!o.condition)
          ? node.options.filter((o) => visibility[o.index])
          : node.options,
      entrywayId,
    };
  },
  { updateFormulary, questionShown }
)(Question);
