import { _t } from '@mindoktor/patient-legacy/i18n';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from '@mindoktor/patient-legacy/src/common/compose';

import Config from '@mindoktor/env/Config';
import colors from '../../../common/colors';
import { Body1, Body3, Title1 } from '../../../common/components/typography';
import { getIndependentCareAgeFromHost } from '../../../state/api/selectors';
import { getGuideByEntrywayId } from '../../../state/guides/selectors';
import { addChild } from '../../../state/profile/actions';
import { openRoute, routes as legacyRoutes } from '../../../state/routing';
import { evalSsn } from '../../../state/utils/evaluation';
import {
  SSN_TOO_OLD,
  SSN_VALID,
  validateSsn,
} from '../../../state/utils/validation';
import BoxedScreen from '../../components/boxed_screen';
import Button from '../../components/button/button';
import TextInput from '../../components/input/text';
import GuideBar from '../../guides/components/bar';
import { getGuideImageByEntrywayId } from '../../guides/utils/images';
import { getChildrenTrackingDataFromProps } from '../getTrackingDataFromProps';
import { withTrackPageView } from '@mindoktor/patient-app/tracking/HOC/withTrackPageView';
import { withRouting } from '@mindoktor/patient-app/routing/HOC/withRouting.web';
import { routes } from '@mindoktor/patient-app/routing/constants/webRoutes';

const styles = {
  wrapper: {
    maxWidth: 800,
    paddingTop: 20,
    paddingBottom: 20,
  },

  body: {
    maxWidth: 600,
    paddingLeft: 20,
    paddingRight: 20,
  },

  title: {
    fontSize: 26,
    lineHeight: '32px',
    marginBottom: 10,
  },

  lead: {
    marginBottom: 20,
  },

  section: {
    display: 'block',
    marginBottom: 20,
  },

  label: {
    fontWeight: 500,
    marginBottom: 5,
  },

  message: {
    marginTop: 4,
    fontWeight: 500,
  },

  footer: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
  },

  buttons: {
    maxWidth: 400,
    marginLeft: 'auto',
  },

  dropdown: {
    marginBottom: 10,
  },
};

// type Props = {
//   entrywayId: number,

//   iconSource: any,
//   title?: string,
//   dynamicCode?: string,

//   addChild: typeof addChild,

//   openRoute: typeof openRoute,
// };

// type State = {
//   ssn: string,
//   ssnDirty: boolean,
//   ssnValid: boolean,
//   ssnMessage: string,

//   firstName: string,
//   lastName: string,
//   dateOfBirth: string,
//   dateOfBirthDirty: boolean,
//   dateOfBirthValid: boolean,
//   dateOfBirthMessage: string,

//   sex: string,
//   gpPractice: string,

//   confirmed: boolean,

//   submitting: boolean,
// };

export class AddScreen extends Component {
  state = {
    ssn: '',
    ssnDirty: false,
    ssnValid: false,
    ssnMessage: '',

    submitting: false,
  };
  componentDidMount() {
    const { trackPageView } = this.props;
    trackPageView(getChildrenTrackingDataFromProps(this.props));
  }

  openGuides = () => {
    const {
      routing: {
        navigation: { navigate },
      },
    } = this.props;

    navigate(routes.GUIDES, { entrywayId: 'child' });
  };

  onSsnChange = (ssn) => {
    const validationResult = validateSsn(
      ssn,
      { maxAge: this.props.independentCareAge - 1 },
      Config.SSNType
    );

    let ssnValid = false;
    let ssnMessage;
    switch (validationResult) {
      case SSN_VALID:
        {
          ssnValid = true;
          const { gender, years, months } = evalSsn(ssn);
          const genderText =
            gender === 'male' ? _t('addChild.male') : _t('addChild.female');

          ssnMessage = _t('addChild.ssnValid', {
            gender: genderText,
            years,
            months,
          });

          // Use a special text for children under a month.
          if (years === 0 && months === 0) {
            ssnMessage = _t('addChild.ssnUnderOneMonth', {
              gender: genderText,
            });
          }
        }
        break;
      case SSN_TOO_OLD:
        ssnMessage = _t('addChild.ssnTooOldError');
        break;
      default:
        ssnMessage = _t('addChild.ssnError');
        break;
    }
    this.setState({ ssn, ssnValid, ssnMessage });
  };

  onSsnBlur = () => {
    const { ssn, ssnDirty } = this.state;
    this.setState({ ssnDirty: ssnDirty || !!ssn });
  };

  submit = async () => {
    const {
      addChild,
      openRoute,
      entrywayId,
      dynamicCode,
      preferredCaregiverId,
      routing: {
        navigation: { navigate },
      },
    } = this.props;
    const { ssn } = this.state;

    this.setState({ submitting: true });

    let addChildArg, evalFunc, evalArg;

    addChildArg = {
      ssn,
    };
    evalFunc = evalSsn;
    evalArg = ssn;

    const { error, child } = await addChild(addChildArg);
    this.setState({ submitting: false });

    if (!error) {
      const { years, months } = evalFunc(evalArg);

      if (years === 0 && months < 6) {
        // Legacy navigation
        return openRoute(legacyRoutes.CHILDREN_UNDER_SIX_MONTHS);
      }

      // Child added from within a started guide.
      if (entrywayId) {
        return navigate(
          routes.GUIDES_PRESTART,
          {
            entrywayId,
          },
          {
            childUuid: child.uuid,
            dynamicCode,
            preferredCaregiverId,
          }
        );
      }

      // Child added from guidelist.
      return navigate(
        routes.GUIDES,
        {
          entrywayId: 'child',
        },
        {
          preferredCaregiverId,
        }
      );
    }

    return error;
  };

  getSubmitDisabled = (state) => {
    const { ssnValid, submitting } = state;

    return !ssnValid || submitting;
  };

  getSsnLabel = () => {
    return _t('addChild.ssnLabel');
  };

  getSsnPlaceholder = () => {
    return _t('addChild.ssnPlaceholder');
  };

  render() {
    const { iconSource, title } = this.props;
    const { ssnDirty, ssnValid, ssnMessage } = this.state;

    const requiredFields = ['ssn'];

    return (
      <BoxedScreen
        header={
          <GuideBar
            title={title}
            iconSource={iconSource}
            onCancel={this.openGuides}
          />
        }
        body={
          <div style={styles.wrapper}>
            <div style={styles.body}>
              <Title1 style={styles.title}>{_t('addChild.title')}</Title1>

              <Body1 style={styles.lead}>{_t('addChild.intro')}</Body1>

              {requiredFields.includes('ssn') && (
                <label style={styles.section}>
                  <Body1 style={styles.label}>{this.getSsnLabel()}</Body1>

                  <TextInput
                    placeholder={this.getSsnPlaceholder()}
                    autoFocus={true}
                    onChange={this.onSsnChange}
                    onBlur={this.onSsnBlur}
                    error={ssnDirty && !ssnValid}
                  />

                  {ssnMessage && ssnMessage.length > 0 && ssnDirty && (
                    <Body3
                      style={{
                        ...styles.message,
                        color: ssnValid ? colors.green_500 : colors.error_text,
                      }}
                    >
                      {ssnMessage}
                    </Body3>
                  )}
                </label>
              )}
            </div>
          </div>
        }
        footer={
          <div>
            <div style={styles.footer}>
              <div style={styles.buttons}>
                <Button
                  hero={true}
                  disabled={this.getSubmitDisabled(this.state)}
                  onClick={this.submit}
                >
                  {_t('addChild.submit')}
                </Button>
              </div>
            </div>
          </div>
        }
        showScrollGradient={true}
      />
    );
  }
}

const enhance = compose(
  withTrackPageView,
  withRouting,
  connect(
    (state, props) => {
      const {
        routing: { queryParams },
      } = props;

      const entrywayId = queryParams.get('entrywayId');
      const dynamicCode = queryParams.get('dynamicCode');
      const guide = getGuideByEntrywayId(state, entrywayId);

      const { displayTitle: title } = guide || {};

      return {
        iconSource: entrywayId
          ? getGuideImageByEntrywayId(entrywayId)
          : undefined,
        title,
        independentCareAge: getIndependentCareAgeFromHost(state),
        entrywayId,
        dynamicCode,
      };
    },
    { addChild, openRoute }
  )
);

export default enhance(AddScreen);
