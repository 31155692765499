export const FEATUREFLAGS_REQUEST = 'FEATUREFLAGS_REQUEST';
export const FEATUREFLAGS_REQUEST_SUCCESS = 'FEATUREFLAGS_REQUEST_SUCCESS';
export const FEATUREFLAGS_REQUEST_ERROR = 'FEATUREFLAGS_REQUEST_ERROR';
export const FEATUREFLAGS_UPDATE_FLAG_ENABLED =
  'FEATUREFLAGS_UPDATE_FLAG_ENABLED';

// export type Flag = {
//   key: string,
//   enabled: boolean,
//   enabledInApi?: boolean,
//   description: string,
// };

// /**
//  * Feature flag state
//  */
// export type FeatureFlagsState = {
//   +flags: Flag[],
//   +fetched: boolean,
//   +pending: boolean,
//   persistedFlags?: {
//     [key: string]: boolean,
//   },
// };

// export type FeatureFlagsAction =
//   | RequestAction
//   | RequestSuccessAction
//   | RequestErrorAction
//   | UpdateFlagEnabledAction;

// export type RequestAction = {
//   type: typeof FEATUREFLAGS_REQUEST,
//   payload?: null,
// };

// export type RequestSuccessAction = {
//   type: typeof FEATUREFLAGS_REQUEST_SUCCESS,
//   payload: { flags: Flag[] },
// };

// export type RequestErrorAction = {
//   type: typeof FEATUREFLAGS_REQUEST_ERROR,
//   payload: any,
// };

// export type UpdateFlagEnabledAction = {
//   type: typeof FEATUREFLAGS_UPDATE_FLAG_ENABLED,
//   payload: { key: string, value: boolean },
// };
