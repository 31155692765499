export const VACCINATION_FETCH = 'VACCINATION_FETCH';
export const VACCINATION_FETCH_SUCCESS = 'VACCINATION_FETCH_SUCCESS';
export const VACCINATION_FETCH_CHILD_SUCCESS =
  'VACCINATION_FETCH_CHILD_SUCCESS';
export const VACCINATION_FETCH_ERROR = 'VACCINATION_FETCH_ERROR';
export const VACCINATION_ADD = 'VACCINATION_ADD';
export const VACCINATION_ADD_SUCCESS = 'VACCINATION_ADD_SUCCESS';
export const VACCINATION_ADD_ERROR = 'VACCINATION_ADD_ERROR';
export const VACCINATION_DELETE = 'VACCINATION_DELETE';
export const VACCINATION_DETAILS_MODAL = 'VACCINATION_DETAILS_MODAL';
export const VACCINATION_APOTEKET_MODAL = 'VACCINATION_APOTEKET_MODAL';
// User ordination actions
export const VACCINATION_PRODUCT_SELECTED = 'VACCINATION_PRODUCT_SELECTED';
export const VACCINATION_TAKEN_SELECTED = 'VACCINATION_TAKEN_SELECTED';
export const VACCINATION_DOSAGE_SELECTED = 'VACCINATION_DOSAGE_SELECTED';
export const VACCINATION_REMINDER_SELECTED = 'VACCINATION_REMINDER_SELECTED';
export const VACCINATION_RESET_CURRENTANSWERS =
  'VACCINATION_RESET_CURRENTANSWERS';

// export type VaccinationState = {
//   vaccinationCards: Array<DividerCard | VaccinationCard>,
//   vaccinationCardsChild: Object,
//   query: string,
//   answers?: UserOrdinationAnswers,
// };

// export type DividerCard = {
//   divider: boolean,
//   type: string,
// };

// export type VaccinationCard = {
//   isNew: boolean,
//   needsRefill: boolean,
//   type: string,
//   label: string,
//   ordinations: Array<Ordination | RemodeledUserOrdination>,
//   prescriptions: Prescription[],
//   allActivities: Array<Ordination | RemodeledUserOrdination | Prescription>,
// };

// Userordinations remodeled to fit the old flat ordination setup.
// export type RemodeledUserOrdination = {
//   id: number,
//   type: string,
//   userAdded: boolean,
//   label: string,
//   created: string,
//   product: string,
//   dosage: string,
//   reminder: string,
// };

// export type UserOrdinationProduct = {|
//   key?: string,
//   nplId?: string,
//   atcCode?: string,
//   label?: string,
//   type?: Array<string>,
//   productType?: string,
//   productName?: string,
// |};

// export type UserOrdinationAnswers = {|
//   takenDate?: string,
//   dosage?: string,
//   reminder?: string,
//   ...UserOrdinationProduct,
// |};

// export type UserOrdination = {
//   id: number,
//   created: string,
//   answers: UserOrdinationAnswers,
// };

// export type Prescription = {
//   product: string,
//   date: string,
//   isNarcotic: boolean,
//   packageSize: string,
//   limit: number,
//   dosage: string,
//   productName: string,
//   form: string,
//   strength: string,
//   atcCode: string,
//   isPrescription: boolean,
// };

// export type VaccinationFetchAction = {
//   type: typeof VACCINATION_FETCH,
// } & Action;

// export type VaccinationFetchSuccessAction = {
//   type: typeof VACCINATION_FETCH_SUCCESS,
// } & Action;

// export type VaccinationFetchErrorAction = {
//   type: typeof VACCINATION_FETCH_ERROR,
// } & Action;

// export type VaccinationAddAction = {
//   type: typeof VACCINATION_ADD,
// } & Action;

// export type VaccinationDeleteAction = {
//   type: typeof VACCINATION_DELETE,
// } & Action;

// export type VaccinationAddSuccessAction = {
//   type: typeof VACCINATION_ADD_SUCCESS,
// } & Action;

// export type VaccinationAddErrorAction = {
//   type: typeof VACCINATION_ADD_ERROR,
// } & Action;

// export type VaccinationProductSelectedAction = {
//   type: typeof VACCINATION_PRODUCT_SELECTED,
// } & Action;

// export type VaccinationDosageSelectedAction = {
//   type: typeof VACCINATION_DOSAGE_SELECTED,
// } & Action;

// export type VaccinationTakenSelectedAction = {
//   type: typeof VACCINATION_TAKEN_SELECTED,
// } & Action;

// export type VaccinationReminderSelectedAction = {
//   type: typeof VACCINATION_REMINDER_SELECTED,
// } & Action;

// export type VaccinationResetCurrentAnswersAction = {
//   type: typeof VACCINATION_RESET_CURRENTANSWERS,
// } & Action;
