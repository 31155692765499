export const INQUISITIONS_DRAFT_UPDATE = 'INQUISITIONS_DRAFT_UPDATE';
export const INQUISITIONS_DRAFT_REMOVE = 'INQUISITIONS_DRAFT_REMOVE';

// export type Limit = {
//   value: number,
//   label?: string,
// };

// export type Option = {
//   value: string | number,
//   label: string,
// };

// export type Question = {
//   id: string,
//   ref: string,
//   type: string,
//   label?: string,
//   description?: string,
//   min?: Limit,
//   max?: Limit,
//   unit?: string,
//   options?: Option[],
//   optional?: boolean,
// };

// export type Answer = Question & {
//   value: *,
// };

// export type Abort = {
//   label?: string,
//   description?: string,
// };

// export type Ask = Question => Promise<*>;

// export type Result = {
//   answers: Answer[],
//   abort?: Abort,
// };

// export type Patient = {
//   firstName: string,
//   lastName: string,
//   birthDate: string,
//   gender: 'male' | 'female' | 'unknown', // unknown for user surveys, where we don't require a login, and therefore do not know what gender the user is during the survey
//   age: number,
//   domain: string,
// };

// export type Inquisition = ({ ask: Ask, patient: Patient }) => Promise<Result>;

// export type Draft = {
//   visited?: string[],
//   values?: { [string]: * },
//   syncKey?: *,
//   options?: *, // { entrywayId: number, ... }
// };

// export type InquisitionsState = {
//   draftById: { [string]: Draft },
// };
