import {
  ApiResponse,
  requestHandler,
} from '@mindoktor/patient-app/api/requestHandler';

import { HostResponse } from './models/host';
import { HostSchema } from './schema/host';

const get = async (): Promise<ApiResponse<HostResponse>> => {
  return await requestHandler.get('/api/v1/host', {
    validationSchema: HostSchema,
  });
};

export const hostApi = {
  get,
};
