import {
  WORKOUT_DETAILS_FETCH,
  WORKOUT_DETAILS_FETCH_ERROR,
  WORKOUT_DETAILS_FETCH_SUCCESS,
  WORKOUT_DISMISS,
  WORKOUT_SHOW,
} from './types';

export const initialState = {
  selectedWorkout: null,
  workoutsById: {},
  allWorkoutIds: [],
  isLoading: true,
};

export default function workoutReducer(state = initialState, action) {
  const { type, payload = {} } = action;

  switch (type) {
    case WORKOUT_SHOW:
      return {
        ...state,
        selectedWorkout: payload.cId,
      };

    case WORKOUT_DISMISS:
      return initialState;

    case WORKOUT_DETAILS_FETCH:
      return {
        ...state,
        isLoading: true,
      };
    case WORKOUT_DETAILS_FETCH_ERROR:
      return state;
    case WORKOUT_DETAILS_FETCH_SUCCESS:
      return {
        ...state,
        workoutsById: payload.workoutsById,
        allWorkoutIds: payload.allWorkoutIds,
        isLoading: false,
      };

    default:
      return state;
  }
}
