import { useContext } from 'react';

import {
  AuthenticationContext,
  AuthenticationContextType,
} from '../context/AuthenticationContext';

export const useAuthentication = (): AuthenticationContextType => {
  const authenticationContext = useContext(AuthenticationContext);
  if (authenticationContext == null) {
    throw new Error(
      'AuthenticationContext is not initialized. Did you try calling it out of its provider?'
    );
  }
  return authenticationContext;
};
