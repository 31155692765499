export const REQUEST_GUIDES = 'REQUEST_GUIDES';
export const RECEIVE_GUIDES = 'RECEIVE_GUIDES';
export const RECEIVE_CATEGORIES = 'RECEIVERECEIVE_CATEGORIES_GUIDES';
export const CHANGE_GUIDECATEGORY = 'CHANGE_GUIDECATEGORY';
export const VIEW_GUIDE = 'VIEW_GUIDE';
export const START_GUIDE = 'START_GUIDE';
export const CLEAR_GUIDE_FLAG = 'CLEAR_GUIDE_FLAG';

export const GUIDE_NATIVE_START_ERROR = 'GUIDE_NATIVE_START_ERROR';

export const GUIDES_STEP = 'GUIDES_STEP';
export const GUIDES_CANCEL = 'GUIDES_CANCEL';

export const GUIDES_LOAD_ERROR = 'GUIDES_LOAD_ERROR';

export const GUIDES_SEARCH_SUBMIT = 'GUIDES_SEARCH_SUBMIT';
export const GUIDES_SEARCH_CLEAR = 'GUIDES_SEARCH_CLEAR';
export const GUIDES_SEARCH_CANCEL = 'GUIDES_SEARCH_CANCEL';
export const GUIDES_SEARCH_DONE = 'GUIDES_SEARCH_DONE';
