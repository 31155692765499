import React, { useEffect } from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';

import LegacyModalsRouter from '@mindoktor/patient-app/adapters/components/LegacyModalsRouter/web';
import TrackPlainPageView from '@mindoktor/patient-app/tracking/components/TrackPlainPageView';
import { useAuthorization } from '@mindoktor/patient-app/user';

import { useRouteElements } from '../../../hooks/useRouteElements/web';
import ProtectedRoutesHandler from '../../ProtectedRoutesHandler/web/ProtectedRoutesHandler';

const handleBadHash = () => {
  if (!window.location.href.includes('#')) {
    // This URL has no hash
    return;
  }

  const hashRouteWithoutHashSign = window.location.hash.slice(1);
  /** E.g. guides.mindoktor.se#guides */
  const isHashWithoutLeadingSlash = !hashRouteWithoutHashSign.startsWith('/');

  if (isHashWithoutLeadingSlash) {
    window.location.hash = `/${hashRouteWithoutHashSign}`;
  }
};

const Router: React.FC = () => {
  const routeElements = useRouteElements();
  const authorization = useAuthorization();

  useEffect(() => {
    window.addEventListener('hashchange', handleBadHash);
    return () => {
      window.removeEventListener('hashchange', handleBadHash);
    };
  }, []);

  if (routeElements.isLoading || authorization.isLoading) {
    return null;
  }

  return (
    <HashRouter>
      <LegacyModalsRouter />
      <Routes>
        {routeElements.publicRoutesList
          .filter((routeObjEl) => routeObjEl.isEnabled)
          .map(({ path, manualPageViewTracking, element: Element }) => (
            <Route
              path={path}
              key={path}
              element={
                <TrackPlainPageView
                  path={path}
                  enabled={manualPageViewTracking !== true}
                >
                  <Element />
                </TrackPlainPageView>
              }
            />
          ))}

        <Route element={<ProtectedRoutesHandler />}>
          {routeElements.protectedRoutesList
            .filter((routeObjEl) => routeObjEl.isEnabled)
            .map(({ path, manualPageViewTracking, element: Element }) => (
              <Route
                path={path}
                key={path}
                element={
                  <TrackPlainPageView
                    path={path}
                    enabled={manualPageViewTracking !== true}
                  >
                    <Element />
                  </TrackPlainPageView>
                }
              />
            ))}
        </Route>
      </Routes>
    </HashRouter>
  );
};

export default Router;
