import React from 'react';

import Stack from '@mui/material/Stack/Stack';

import MDLogoFullIcon from '@mindoktor/pulse/src/icons/MDLogoFullIcon/MDLogoFullIcon';
import { colors } from '@mindoktor/pulse/src/styles/colors';
import { useBreakpointStyle } from '@mindoktor/pulse/src/utils/mediaQueries/useBreakpointStyle';

import Config from '@mindoktor/env/Config';

import { openExternalMinDoktorURL } from '@mindoktor/patient-app/utils/externalRouting/web';

import Menu from './Menu';

interface Props {
  showMenuBarActions?: boolean;
}

const Header: React.FC<Props> = ({ showMenuBarActions = true }) => {
  const { matches } = useBreakpointStyle();
  return (
    <Stack
      direction="row"
      height="3.75rem"
      justifyContent="center"
      paddingX={{ md: 2, xs: 1 }}
      sx={{ backgroundColor: colors.black }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        maxWidth={1200}
        width="100vw"
      >
        <MDLogoFullIcon
          fontSize={matches.md ? 2.25 : 1.95}
          onClick={() =>
            openExternalMinDoktorURL(Config.Urls?.web?.domain ?? '')
          }
        />
        {showMenuBarActions && <Menu />}
      </Stack>
    </Stack>
  );
};

export default Header;
