import React, { Component } from 'react';
import { hideModal } from '../routing';
import MediaQuery from './media_query';
import spacing from '../../common/spacing';
import Button from './button/button';
import colors from '../../common/colors';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 600,
    width: `calc(100% - ${spacing.xs * 2}px)`,
    maxHeight: '90%',
    backgroundColor: 'white',
    margin: spacing.t,
    borderRadius: '8px',
  },

  body: {
    flex: 1,
    overflowY: 'auto',
    padding: spacing.s,
  },

  bodyLarge: {
    padding: spacing.m,
  },

  footer: {
    paddingBottom: spacing.s,
    paddingLeft: spacing.s,
    paddingRight: spacing.s,
  },

  footerLarge: {
    paddingBottom: spacing.m,
    paddingLeft: spacing.m,
    paddingRight: spacing.m,
  },

  closeButton: {
    margin: `0 auto ${spacing.s}px auto`,
    width: 242,
    backgroundColor: colors.grey_900,
    border: `2px solid ${colors.grey_900}`,
  },
};

export default class Modal extends Component {
  stopPropagation = (event) => event && event.stopPropagation();

  render() {
    const {
      body,
      footer,
      style,
      contentStyle,
      onBackgroundClick = hideModal,
      confirmButton,
    } = this.props;

    return (
      <div style={{ ...styles.root, ...style }} onClick={onBackgroundClick}>
        <div
          style={{ ...styles.content, ...contentStyle }}
          onClick={this.stopPropagation}
        >
          {!!body && (
            <MediaQuery maxWidth={800}>
              <div style={styles.body}>{body}</div>
            </MediaQuery>
          )}

          {!!body && (
            <MediaQuery minWidth={801}>
              <div style={{ ...styles.body, ...styles.bodyLarge }}>{body}</div>
            </MediaQuery>
          )}

          {!!footer && (
            <MediaQuery maxWidth={800}>
              <div style={styles.footer}>{footer}</div>
            </MediaQuery>
          )}

          {!!footer && (
            <MediaQuery minWidth={801}>
              <div style={{ ...styles.footer, ...styles.footerLarge }}>
                {footer}
              </div>
            </MediaQuery>
          )}

          {confirmButton && (
            <Button
              style={styles.closeButton}
              onClick={confirmButton.onConfirm || hideModal}
            >
              {confirmButton.text}
            </Button>
          )}
        </div>
      </div>
    );
  }
}
