import { useEffect, useRef, useState } from 'react';
import { SafeArea, SafeAreaInsets } from 'capacitor-plugin-safe-area';

type ISafeArea = SafeAreaInsets['insets'];
type SafeAreaEventListener = Awaited<ReturnType<typeof SafeArea.addListener>>;

const defaultInsets: ISafeArea = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
};

export const useSafeAreaInsets = () => {
  const [insets, setInsets] = useState<ISafeArea>(defaultInsets);
  const eventListenerRef = useRef<SafeAreaEventListener | null>(null);

  useEffect(() => {
    const _inner = async () => {
      const safeAreaData = await SafeArea.getSafeAreaInsets();
      setInsets(safeAreaData.insets);
    };
    void _inner();
  }, []);

  useEffect(() => {
    // On mount
    const _inner = async () => {
      eventListenerRef.current = await SafeArea.addListener(
        'safeAreaChanged',
        (safeAreaData) => setInsets(safeAreaData.insets)
      );
    };
    void _inner();

    // On unmount
    return () => {
      void eventListenerRef.current?.remove();
    };
  }, []);

  return { insets };
};
