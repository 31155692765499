import Config from '@mindoktor/env/Config';
// import messages from '@mindoktor/patient-legacy/i18n/messages';
import { REHYDRATE } from 'redux-persist/es/constants';

import * as actions from './actions';

const locales = Config.Locales || [];
const initialState = {
  currentLocale: Config.DefaultLocale,
  defaultLocale: Config.DefaultLocale,
  initialNow: '',
  locales,
  messages: {},
};

const intlReducer = (state = initialState, action) => {
  const { payload = {} } = action;
  switch (action.type) {
    case actions.SET_CURRENT_LOCALE: {
      const { locale } = payload;

      return {
        ...state,
        currentLocale: locales.includes(locale)
          ? locale
          : initialState.currentLocale,
      };
    }

    case REHYDRATE:
      const { intl: { currentLocale } = {} } = payload;

      return {
        ...state,
        currentLocale: locales.includes(currentLocale)
          ? currentLocale
          : initialState.currentLocale,
      };

    default:
      return state;
  }
};

export default intlReducer;
