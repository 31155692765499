import { getApi } from '../api/actions';

export const MEDICATIONS_FETCHED = 'MEDICATIONS_FETCHED' as const;

export function fetchMedications(filter?: string) {
  const params = filter ? '?filter=' + filter : '';
  const url = `/api/v1/medications${params}`;

  return async (
    dispatch: <T = unknown>(
      data: T
    ) => Promise<
      T extends (...args: unknown[]) => unknown ? ReturnType<T> : void
    >
  ) => {
    const { json, error } = await dispatch(getApi(url));

    if (error) return;

    dispatch({
      type: MEDICATIONS_FETCHED,
      payload: {
        filter,
        data: json,
      },
    });
  };
}
