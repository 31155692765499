import { CommonStateType } from '../types';
import UrlPattern from 'url-pattern';

import legacyRoutes from './routes';
import { routes } from '@mindoktor/patient-app/routing/constants/webRoutes';

type QueryParamList = {
  [key: string]: any;
};

export const getQueryParams = (uri = '') => {
  const params: QueryParamList = {};
  const re = /[?&]?([^=]+)=([^&]*)/g;

  let tokens;

  while ((tokens = re.exec(uri.split('+').join(' ')))) {
    params[tokens[1]] = tokens[2];
  }

  return params;
};

export const getQueryParam = (uri = '', name: string) =>
  getQueryParams(uri)[name || ''];

export const parseRoute = (uri: string) => {
  const parts = uri.split('?', 2);
  const uriWithoutQuery = parts[0];
  const queryParams = getQueryParams(parts.length > 1 ? parts[1] : '');

  // Until we migrate all routes we need to check the legacy routes as well
  const routePaths = [...Object.values(routes), ...Object.values(legacyRoutes)];

  for (const path of routePaths) {
    /**
     * UrlPattern checks if a url string matches a given route paths.
     * If matching, it also return parameters specified in the pattern as a key-value map.
     * @example
     * new UrlPattern('/guides/:id').match('/guides/44')
     * // { params: {id: '44'}, path: '/guides/:id' }
     *
     * @see https://github.com/snd/url-pattern/tree/master#url-pattern
     */
    const pathParams = new UrlPattern(path).match(uriWithoutQuery);

    if (pathParams) {
      const params = {
        ...queryParams,
        ...pathParams,
      };

      const decodedParams: QueryParamList = {};

      Object.keys(params).forEach(
        (key) => (decodedParams[key] = decodeURIComponent(params[key]))
      );

      return {
        path,
        params: decodedParams,
      };
    }
  }

  return { path: '', params: undefined };
};

export const formatRoute = (path: string, params?: QueryParamList) => {
  const encodedParams: QueryParamList = {};
  if (params) {
    Object.keys(params)
      .filter((key) => params[key] !== undefined && params[key] !== null)
      .forEach(
        (key) => (encodedParams[key] = encodeURIComponent(String(params[key])))
      );
  }

  const uri = new UrlPattern(path).stringify(encodedParams);

  const query = Object.keys(encodedParams)
    .filter((key) => !path.includes(`:${key}`))
    .map((key) => `${key}=${encodedParams[key]}`)
    .join('&');

  return uri + (query ? '?' + query : '');
};

export const formatRouteWithHash = (path: string, params: QueryParamList) =>
  '#' + formatRoute(path, params);

export const getRouteParam = <T = Record<string, string | undefined>>(
  state: CommonStateType,
  prop: keyof T
) => state.routing.props[prop];
