import { _t } from '@mindoktor/patient-legacy/i18n';
import React, { Component } from 'react';

import Choice from './choice';

// type Props = {
//   answer?: any,
//   error?: string,
//   update: (value?: any) => any,
//   next: () => any,
// };

export default class Binary extends Component {
  options = [
    {
      index: '0',
      label: _t('formulary.question.binary.yes'),
      value: 'yes',
    },
    { index: '1', label: _t('formulary.question.binary.no'), value: 'no' },
  ];

  render() {
    const { answer, error, update, next } = this.props;

    return (
      <Choice
        options={this.options}
        answer={answer}
        error={error}
        update={update}
        next={next}
      />
    );
  }
}
