import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import React from 'react';

export default withStyles(({ spacing, breakpoints: { up } }) => ({
  root: {
    width: '100%',
    [up('sm')]: {
      width: `calc(100% - ${spacing(20)}px)`,
      padding: spacing(10),
      backgroundColor: 'white',
      boxShadow:
        '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
      borderRadius: 4,
    },
  },
}))(({ children, classes, className }) => (
  <div className={classNames(classes.root, className)}>{children}</div>
));
