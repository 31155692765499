import React from 'react';

import ContactScreen from '@mindoktor/patient-legacy/src/web/account/contact/screen';
import VerifyEmailScreen from '@mindoktor/patient-legacy/src/web/account/contact/verify_email';
import ForgetConfirmScreen from '@mindoktor/patient-legacy/src/web/account/forget/confirm_screen';
import ForgetSuccessScreen from '@mindoktor/patient-legacy/src/web/account/forget/success_screen';
import AnswersScreen from '@mindoktor/patient-legacy/src/web/cases/answers/screen';
import CasesListScreen from '@mindoktor/patient-legacy/src/web/cases/list/screen';
import ReferralsLocationScreen from '@mindoktor/patient-legacy/src/web/cases/referrals/location/screen';
import DeveloperScreen from '@mindoktor/patient-legacy/src/web/developer/screen';
import AbortScreen from '@mindoktor/patient-legacy/src/web/guides/abort/screen';
import BloodPressureScreen from '@mindoktor/patient-legacy/src/web/guides/bloodpressure/screen';
import DynamicCodeScreen from '@mindoktor/patient-legacy/src/web/guides/dynamiccode/screen';
import EditScreen from '@mindoktor/patient-legacy/src/web/guides/edit/screen';
import PrestartScreen from '@mindoktor/patient-legacy/src/web/guides/prestart/screen';
import QuestionsScreen from '@mindoktor/patient-legacy/src/web/guides/questions/screen';
import SurveyCompletedScreen from '@mindoktor/patient-legacy/src/web/survey/followup/SurveyCompletedScreen';
import SurveyScreen from '@mindoktor/patient-legacy/src/web/survey/followup/SurveyScreen';

import LegacyRouter from '@mindoktor/patient-app/adapters/components/LegacyRouter/web';
import { legacyNavigationObject } from '@mindoktor/patient-app/adapters/routes/legacyNavigationObject.web';
import {
  LoginScreen,
  SkandiaTestScreen,
} from '@mindoktor/patient-app/auth/web';
import JournalNoteScreen from '@mindoktor/patient-app/journal/components/JournalNoteScreen/web';
import MessagingScreen from '@mindoktor/patient-app/messaging/components/MessagingScreen/MessagingScreen';
import { MESSAGING_FEATURE_FLAG } from '@mindoktor/patient-app/messaging/constants';
import { pageViewRoutesWithExtraData } from '@mindoktor/patient-app/tracking/pageViewRoutesWithExtraData';
import DeleteAccountScreen from '@mindoktor/patient-app/user/components/DeleteAccount/DeleteAccountScreen/web';
import {
  RegistrationScreen,
  TosAndPrivacyScreen,
} from '@mindoktor/patient-app/user/web';
import { useFeatureFlag } from '@mindoktor/patient-app/utils/featureFlags';

import GuidesScreen from '../../../../guides/components/GuidesScreen/web/GuidesScreen';
import RedirectToDefaultRoute from '../../../components/RedirectToDefaultRoute/web';
import { routes } from '../../../constants/webRoutes';

interface RouteObjectElement {
  path: string;
  element: React.FC | React.ComponentClass;
  isEnabled: boolean;
  /** If true, its element needs to track page-view events manually */
  manualPageViewTracking?: boolean;
}

const legacyRouteElements: RouteObjectElement[] = Object.keys(
  legacyNavigationObject
).map((key) => ({
  path: key,
  element: LegacyRouter,
  isEnabled: true,
  manualPageViewTracking: (pageViewRoutesWithExtraData as Set<string>).has(key),
}));

export const useRouteElements = () => {
  const nextGenChatFlag = useFeatureFlag(MESSAGING_FEATURE_FLAG);

  /**
   * List of public routes
   *
   * These are routes that are completely public and without any
   * sort of protection.
   */
  const publicRoutesList: RouteObjectElement[] = [
    {
      path: routes.SKANDIA_TEST_LOGIN,
      element: SkandiaTestScreen,
      // This page is required on staging, but it
      // is empty if one tries to reach it from prod.
      isEnabled: true,
    },
    {
      path: routes.FOLLOWUP_SURVEY,
      element: SurveyScreen,
      isEnabled: true,
    },
    {
      path: routes.FOLLOWUP_SURVEY_COMPLETED,
      element: SurveyCompletedScreen,
      isEnabled: true,
    },
  ];

  /** List of routes that require authentication */
  const protectedRoutesList: RouteObjectElement[] = [
    {
      path: routes.DELETE_ACCOUNT,
      element: DeleteAccountScreen,
      isEnabled: true,
    },
    {
      path: routes.ACCOUNT_CONTACT,
      element: ContactScreen,
      isEnabled: true,
    },
    {
      path: routes.ACCOUNT_CONTACT_VERIFY_EMAIL,
      element: VerifyEmailScreen,
      isEnabled: true,
    },
    {
      path: routes.ACCOUNT_CONTACT_VERIFY_EMAIL_SUCCESS,
      element: ContactScreen,
      isEnabled: true,
    },
    {
      path: routes.ACCOUNT_FORGET,
      element: ForgetConfirmScreen,
      isEnabled: true,
    },
    {
      path: routes.ACCOUNT_FORGET_SUCCESS,
      element: ForgetSuccessScreen,
      isEnabled: true,
    },
    {
      path: routes.DEVELOPER_TOOLS,
      element: DeveloperScreen,
      isEnabled: true,
    },
    {
      path: routes.CASES,
      element: CasesListScreen,
      isEnabled: true,
    },
    {
      path: routes.CONVERSATION,
      element: MessagingScreen,
      isEnabled: nextGenChatFlag.isEnabled,
    },
    {
      path: routes.CASE_ANSWERS,
      element: AnswersScreen,
      isEnabled: true,
    },
    {
      path: routes.CASE_REFERRALS_LOCATION,
      element: ReferralsLocationScreen,
      isEnabled: true,
    },
    {
      path: routes.JOURNAL_NOTE,
      element: JournalNoteScreen,
      isEnabled: nextGenChatFlag.isEnabled,
    },
    {
      path: routes.LOGIN,
      element: LoginScreen,
      isEnabled: true,
      manualPageViewTracking: true,
    },
    {
      path: routes.REGISTER,
      element: RegistrationScreen,
      isEnabled: true,
    },
    {
      path: routes.TOS_PRIVACY,
      element: TosAndPrivacyScreen,
      isEnabled: true,
    },
    {
      path: routes.GUIDES,
      element: GuidesScreen,
      isEnabled: true,
    },
    {
      path: routes.GUIDES_INTRO,
      element: PrestartScreen,
      isEnabled: true,
    },
    {
      path: routes.GUIDES_PRESTART,
      element: PrestartScreen,
      isEnabled: true,
    },
    {
      path: routes.GUIDES_QUESTIONS,
      element: QuestionsScreen,
      isEnabled: true,
    },
    {
      path: routes.GUIDES_ABORT,
      element: AbortScreen,
      isEnabled: true,
    },
    {
      path: routes.GUIDES_BLOOD_PRESSURE,
      element: BloodPressureScreen,
      isEnabled: true,
    },
    {
      path: routes.GUIDES_DYNAMIC_CODE,
      element: DynamicCodeScreen,
      isEnabled: true,
    },
    {
      path: routes.GUIDES_QUESTIONS_EDIT,
      element: EditScreen,
      isEnabled: true,
    },
    {
      path: routes.GUIDES_BANNER,
      element: GuidesScreen,
      isEnabled: true,
    },

    // Let the legacy router handle the legacy routes
    ...legacyRouteElements,
    // Wildcard route to handle nonexistent routes e.g. if the users tamper with the URL themselves.
    {
      path: '*',
      element: RedirectToDefaultRoute,
      isEnabled: true,
    },
  ];

  return {
    isLoading: nextGenChatFlag.isLoading,
    protectedRoutesList,
    publicRoutesList,
  };
};
