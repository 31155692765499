import { omit, setIn, updateIn } from 'timm';

import { SURVEY2_ADD, SURVEY2_END, SURVEY2_END_FAILURE } from './actionTypes';
import { Survey2State } from './types';
import { FormularyKey } from '../formulary/types';

const initialState: Survey2State = {
  ongoing: {},
};

interface Action {
  type: string;
  payload?: {
    caseId?: number;
    surveyName?: string;
    error?: unknown;
    formularyKey: FormularyKey;
    survey?: {
      caseId?: number;
      surveyName?: string;
      formularyKey: FormularyKey;
    };
  };
}

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case SURVEY2_END: {
      const { caseId, surveyName } = action.payload || {};

      if (!caseId || !surveyName) {
        console.error('Both caseId and surveyName must be in the payload');
        return state;
      }

      return updateIn(state, ['ongoing', caseId], (keys) =>
        omit(keys, surveyName)
      );
    }

    case SURVEY2_END_FAILURE: {
      const { error } = action.payload || {};

      console.error('Something went wrong when cancelling the survey.', error);

      return state;
    }

    case SURVEY2_ADD: {
      const survey = action.payload;
      // debugger;

      if (!survey) return state;

      const { caseId, surveyName, formularyKey } = survey;

      // @ts-expect-error The behavior of this is complex and didn't feel touching it
      // See: https://github.com/guigrpa/timm?tab=readme-ov-file#setin
      return setIn(state, ['ongoing', caseId, surveyName], formularyKey);
    }

    default:
      return state;
  }
};
