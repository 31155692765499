import './button.css';

import { _t } from '@mindoktor/patient-legacy/i18n';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Body1, Title2 } from '../../../common/components/typography';
import { isLoggedIn } from '../../../state/api/selectors';
import { openRoute, routes as legacyRoutes } from '../../../state/routing';
import { routes } from '@mindoktor/patient-app/routing/constants/webRoutes';
import Button from '../../components/button/button';

const styles = {
  root: {
    borderBottom: '2px solid #e6e6e6',
  },

  title: {
    paddingBottom: 10,
  },

  lead: {
    paddingBottom: 19,
  },
};

// type Props = {
//   isLoggedIn: boolean,
//   style: typeof undefined | Object,
//   openRoute: typeof openRoute,
//   fromPage?: string,
// };
export class Warning extends Component {
  openAddChild = () => {
    const { openRoute, fromPage } = this.props;
    const props = { from: fromPage };

    if (this.props.isLoggedIn) {
      openRoute(legacyRoutes.CHILDREN_ADD, props);
    } else {
      openRoute(routes.LOGIN, props, { state: legacyRoutes.CHILDREN_ADD });
    }
  };

  render() {
    const { style } = this.props;

    return (
      <div style={{ ...styles.root, ...style }}>
        <div>
          <Title2 style={styles.title}>
            {_t('guides.children.warning.title')}
          </Title2>

          <Body1 style={styles.lead}>
            {_t('guides.children.warning.body')}
          </Body1>

          <Button
            className={'add-child-button'}
            onClick={() => this.openAddChild()}
          >
            {_t('addChild.title')}
          </Button>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    isLoggedIn: isLoggedIn(state),
  }),
  { openRoute }
)(Warning);
