import Slider from '@material-ui/core/Slider';
import withStyles from '@material-ui/core/styles/withStyles';
import React from 'react';

import Next from '../components/Next';

class Range extends React.Component {
  // <{
  //   value?: *,
  //   error?: string,
  //   min?: Limit,
  //   max?: Limit,
  //   update: (?*) => *,
  //   done: (?*) => *,
  //   classes: { [string]: string },
  // }>
  onChange = (event, value) => {
    const { update } = this.props;

    update(value);
  };

  render() {
    const { value, error, min, max, done, classes } = this.props;

    const minValue = min !== undefined ? min.value : undefined;
    const minLabel = min !== undefined ? min.label : undefined;
    const maxValue = max !== undefined ? max.value : undefined;
    const maxLabel = max !== undefined ? max.label : undefined;

    return (
      <div className={classes.root}>
        <Slider
          type="number"
          defaultValue={value}
          min={minValue}
          max={maxValue}
          step={0.01}
          marks={[
            {
              value: minValue !== undefined ? minValue : 0,
              label: minLabel,
            },
            {
              value: maxValue !== undefined ? maxValue : 10,
              label: maxLabel,
            },
          ]}
          onChange={this.onChange}
          className={classes.slider}
          classes={{ thumb: classes.thumb, mark: classes.mark }}
        />

        <div className={classes.buttons}>
          <Next disabled={!!error} onClick={() => done()} />
        </div>
      </div>
    );
  }
}

export default withStyles(({ spacing, breakpoints: { down } }) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContet: 'center',
  },

  slider: {},

  thumb: {
    height: 30,
    width: 30,
    marginTop: -15,
    marginLeft: -15,
  },

  mark: {
    [down('sm')]: {},
  },

  buttons: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    marginTop: spacing(3),
  },
}))(Range);
