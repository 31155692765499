import { z } from 'zod';

import { BankIDCollectSchema, BankIDStartSchema } from '../schema/bankid';

export interface BankIDCollectRequest {
  orderRef: string;
}

export type BankIDStartResponse = z.infer<typeof BankIDStartSchema>;
export type BankIDStart = BankIDStartResponse;

export type BankIDCollectResponse = z.infer<typeof BankIDCollectSchema>;
export type BankIDCollect = BankIDCollectResponse;

export const BankIDLoginModes = {
  QRCODE: 'qrcode',
  DEVICE: 'device',
} as const;

export type BankIDLoginMode =
  (typeof BankIDLoginModes)[keyof typeof BankIDLoginModes];
