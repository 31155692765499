import type { Openings } from '../../common/types';
import formatShortDay from './formatShortDay';

/**
 * Formats opening hours for display purposes.
 *
 * @example formatOpeningHours(openings) // 'mån-fre: 08:00-17:00'
 */
const formatOpeningHours = (openings: Openings) =>
  `${formatShortDay(openings.days.from)}-${formatShortDay(
    openings.days.to
  )}: ` + openings.times.map((t) => `${t.from}-${t.to}`).join(', ');

export default formatOpeningHours;
