import { useCallback } from 'react';
import { useTranslation as useI18NextTranslation } from 'react-i18next';

export const useFormatNumber = () => {
  const { i18n } = useI18NextTranslation();
  const formatNumber = useCallback(
    (value: number): string => {
      switch (i18n.language) {
        case 'en':
          return `${value}`;
        default:
          return `${value}`.replace('.', ',');
      }
    },
    [i18n.language]
  );

  return formatNumber;
};
