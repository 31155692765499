import React, { CSSProperties } from 'react';
import QRCode from 'react-qr-code';

import { CircularProgress, Stack, Typography } from '@mui/material';

import TextLink from '@mindoktor/pulse/src/components/TextLink/web';
import { colors } from '@mindoktor/pulse/src/styles/colors';
import { circularProgressLarge } from '@mindoktor/pulse/src/styles/sizes';

import { useTranslation } from '@mindoktor/patient-app/localization/hooks/useTranslation';
import { isMobile } from '@mindoktor/patient-app/utils/device/web';

import BankIDSignIcon from '../../BankIDSignIcon/web';
import classes from '../../LoginMode.styles';

const QR_CODE_SIZE_PX = 156;

interface Props {
  qrCode?: string;
  signing: boolean;
  complete?: boolean;
  onClose: () => void;
  onThisDeviceClick: () => void;
}

const LoginModeQRCode: React.FC<Props> = ({
  qrCode,
  signing,
  complete = true,
  onClose,
  onThisDeviceClick,
}) => {
  const t = useTranslation('auth');

  return (
    <Stack spacing={'1rem'} sx={classes.root}>
      <Stack sx={classes.imageWrapper}>
        {qrCode != null && (
          <QRCode
            size={QR_CODE_SIZE_PX}
            style={classes.image as CSSProperties}
            value={qrCode}
            viewBox={`0 0 ${QR_CODE_SIZE_PX} ${QR_CODE_SIZE_PX}`}
          />
        )}

        {signing && <BankIDSignIcon sx={classes.image} />}

        {(complete || (qrCode == null && !signing)) && (
          <CircularProgress
            color="primary"
            size={circularProgressLarge}
            sx={{ alignSelf: 'center' }}
          />
        )}
      </Stack>

      {!(complete || signing) && (
        <Stack
          spacing={isMobile ? 0 : '0.5rem'}
          paddingBottom={isMobile ? 0 : '1rem'}
        >
          <Stack direction={'row'}>
            <Typography
              variant="body1"
              color={colors.gray[900]}
              width={'1rem'}
              fontSize={14}
            >
              {'1.'}
            </Typography>
            <Typography variant="body1" color={colors.gray[900]} fontSize={14}>
              {t('bankid.qrcodeInfo1')}
            </Typography>
          </Stack>
          <Stack direction={'row'}>
            <Typography
              variant="body1"
              color={colors.gray[900]}
              width={'1rem'}
              fontSize={14}
            >
              {'2.'}
            </Typography>
            <Typography variant="body1" color={colors.gray[900]} fontSize={14}>
              {t('bankid.qrcodeInfo2')}
            </Typography>
          </Stack>
          <Stack direction={'row'}>
            <Typography
              variant="body1"
              color={colors.gray[900]}
              width={'1rem'}
              fontSize={14}
            >
              {'3.'}
            </Typography>
            <Typography variant="body1" color={colors.gray[900]} fontSize={14}>
              {t('bankid.qrcodeInfo3')}
            </Typography>
          </Stack>
        </Stack>
      )}

      {signing && (
        <Stack sx={classes.text}>
          <Typography variant="body2" color={colors.gray[900]} fontSize={14}>
            {t('bankid.signingInfo')}
          </Typography>
        </Stack>
      )}

      {complete && (
        <Stack sx={classes.text}>
          <Typography variant="body2" color={colors.gray[900]} fontSize={14}>
            {t('bankid.loggingIn')}
          </Typography>
        </Stack>
      )}

      <Stack spacing={'1.5rem'} flex={1} justifyContent={'flex-end'}>
        {!isMobile && !(complete || signing) && (
          <TextLink
            noUnderline
            onClick={onThisDeviceClick}
            sx={{ alignSelf: 'center' }}
          >
            {t('bankid.thisDevice')}
          </TextLink>
        )}
        <TextLink noUnderline onClick={onClose} sx={{ alignSelf: 'center' }}>
          {!(complete || signing) ? t('common.back') : t('common.cancel')}
        </TextLink>
      </Stack>
    </Stack>
  );
};

export default LoginModeQRCode;
