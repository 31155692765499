import { _t } from '@mindoktor/patient-legacy/i18n';
import React from 'react';

import Modal from '../../Modal';
import View from '../../primitives/View';
import InstructionCardCarousel from './InstructionCardCarousel';

export const PictureInstructionsModal = ({ cards, onClose }) => {
  return (
    <Modal
      confirmButton={{
        onConfirm: onClose,
        text: _t('pictureInstructions.close'),
      }}
    >
      <View mb="t">
        <InstructionCardCarousel cards={cards} />
      </View>
    </Modal>
  );
};

export default PictureInstructionsModal;
