import { _t } from '@mindoktor/patient-legacy/i18n';
import React, { Component, useEffect } from 'react';

import config from '@mindoktor/env/Config';
import colors from '../../../common/colors';
import {
  getAdultAgeFromHost,
  getIndependentCareAgeFromHost,
} from '../../../state/api/selectors';
import { isUpperAdolescent } from '../../../state/cases/utils';
import { viewGuide } from '../../../state/guides/actions';
import {
  getGuidesByCategory,
  hasChildGuides,
} from '../../../state/guides/selectors';
import { contactInformation } from '../../../state/profile/selectors';
import { getInvitations } from '../../../state/profile/actions';
import ChildNewsletterCard from '../../children/components/newsletter_card';
import ChildWarning from '../../children/components/warning';
import CompanyAttribution from '../../components/company/attribution';
import CompanyGuidesPlug from '../../components/company/guides_plug';
import FullFooter from '../../components/footer/full';
import MediaQuery from '../../components/media_query';
import Screen from '../../components/screen';
import Warning from '../../components/warning';
import YoungParentModal from '../modals/YoungParentModal';
import LocalePicker from '../../intl/components/locale_picker';
import MenuBar from '../../menu/bar';
import { showModal } from '../../routing';
import List from './list';
import Tabs from './tabs';

import { useAppDispatch, useAppSelector } from '../../../state/hooks';
import { useParams } from '@mindoktor/patient-app/routing/hooks/useParams/useParams';
import { useNavigation } from '@mindoktor/patient-app/routing/hooks/useNavigation/web';
import { routes } from '@mindoktor/patient-app/routing/constants/webRoutes';
import { useTrackPageView } from '@mindoktor/patient-app/tracking/hooks/useTrackPageView';
import { useMarketingTracking } from '@mindoktor/patient-app/tracking/hooks/useMarketingTracking';
import { TIMEOUT_USER_STAYED_GUIDE_PICKER_LONG_MS } from '@mindoktor/patient-app/tracking/constants';
import { MarketingTrackingActions } from '@mindoktor/patient-app/tracking/api/models/marketingTracking';

const styles = {
  body: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },

  tabs: {
    marginBottom: 40,
  },

  child: {
    marginBottom: 40,
  },

  list: {
    marginBottom: 40,
  },

  narrow: {
    margin: '0 auto',
    paddingLeft: 20,
    paddingRight: 20,
    maxWidth: 600,
  },

  news: {
    marginBottom: 40,
  },

  footer: {
    flex: 1,
  },

  locale: {
    backgroundColor: colors.grey_100,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: 20,
  },
};

// type Props = {
//   category: 'adult' | 'child' | 'prescription',

//   guides: any[],
//   showChildGuides: boolean,

//   openRoute: typeof openRoute,
// };

export class ListScreen extends Component {
  openGuide = (entrywayId) => {
    const {
      navigation: { navigate },
      viewGuide,
    } = this.props;

    viewGuide(
      entrywayId,
      {
        currentLocation: 'GuidePicker',
      },
      { doTrackPressed: true }
    );
    return navigate(routes.GUIDES, {
      entrywayId,
    });
  };

  openCategory = (category) => {
    const {
      birthDate,
      navigation: { navigate },
      independentCareAge,
      adultAge,
    } = this.props;

    if (category === 'child' && birthDate) {
      const upperAdolescent = isUpperAdolescent(
        birthDate,
        independentCareAge,
        adultAge
      );
      if (upperAdolescent) {
        return showModal(YoungParentModal);
      }
    }

    return navigate(routes.GUIDES, { entrywayId: category });
  };

  componentDidMount() {
    const { getInvitations } = this.props;
    getInvitations();
  }

  render() {
    const { category, guides, showChildGuides } = this.props;

    return (
      <Screen
        header={
          <MediaQuery minWidth={801}>
            <MenuBar />
          </MediaQuery>
        }
        body={
          <div style={styles.body}>
            <MediaQuery maxWidth={800}>
              <MenuBar />
            </MediaQuery>
            <CompanyAttribution />
            {((!!guides && !!guides.length) ||
              !config.HideGuideCategoryChild) && (
              <Tabs
                category={category}
                showChildGuides={showChildGuides}
                onTabClick={this.openCategory}
                style={styles.tabs}
              />
            )}

            {category === 'child' && (
              <div style={styles.narrow}>
                <ChildWarning style={styles.child} />
              </div>
            )}
            <List
              guides={guides}
              onGuideClick={this.openGuide}
              style={styles.list}
            />
            {category === 'child' && config.ShowChildNewsletterCard && (
              <div style={styles.narrow}>
                <ChildNewsletterCard style={styles.news} />
              </div>
            )}
            <CompanyGuidesPlug />
            {!!guides && !!guides.length && (
              <Warning
                text={_t('guides.emergencyNoticeMessage')}
                fromPage={'guidesScreen'}
              />
            )}
            {!!guides && !!guides.length && (
              <FullFooter style={styles.footer} />
            )}
            {config.ShowLocalePicker && (
              <div style={styles.locale}>
                <LocalePicker />
              </div>
            )}
          </div>
        }
      />
    );
  }
}

const ListScreenWrapper = () => {
  const { entrywayId: guideListCategory } = useParams();
  const navigation = useNavigation();
  const dispatch = useAppDispatch();
  const marketingTracking = useMarketingTracking();

  useEffect(() => {
    marketingTracking.track(MarketingTrackingActions.GuidePickerViewed, {
      label: guideListCategory,
    });
  }, [guideListCategory]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      marketingTracking.track(MarketingTrackingActions.GuidePickerUserStayed, {
        label: guideListCategory,
      });
    }, TIMEOUT_USER_STAYED_GUIDE_PICKER_LONG_MS);
    return () => {
      clearTimeout(timeout);
    };
  }, [guideListCategory]);

  useTrackPageView({ entrywayId: guideListCategory });

  const props = {
    ...useAppSelector((state) => ({
      guides: getGuidesByCategory(state, guideListCategory),
      showChildGuides: hasChildGuides(state) || !config.HideGuideCategoryChild,
      birthDate: contactInformation(state).birthDate,
      independentCareAge: getIndependentCareAgeFromHost(state),
      adultAge: getAdultAgeFromHost(state),
    })),
    navigation,
    category: guideListCategory,
    getInvitations: () => dispatch(getInvitations()),
    viewGuide: (...args) => dispatch(viewGuide(...args)),
  };

  return <ListScreen {...props} />;
};

export default ListScreenWrapper;
