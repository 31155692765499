import evaluator from './evaluators';
import { hasKey, isPredefined, isQuestion, toQuestion } from './formulary';
import Values from './values';

export const toFormularyKey = (attributes) => {
  const copy = {};
  Object.keys(attributes)
    .sort()
    .forEach((k) => (copy[k] = attributes[k] || undefined));
  const value = Values.hash(copy);
  return value;
};

export function mapAnswers(answers) {
  const out = {};

  Object.keys(answers).forEach((key) => {
    Values.set(key, out, answers[key]);
  });

  return out;
}

export function flattenAnswers(node, map, out) {
  const o = out || {};

  if (isQuestion(node)) {
    const value = node.id && Values.get(node.id, map);
    if (value !== undefined && node.id) o[node.id] = value;
  }

  if (node && node.children) {
    node.children.forEach((c) => flattenAnswers(c, map, o));
  }

  return o;
}

export const getNonPredefinedAnswers = (answers) =>
  Object.keys(answers).filter((answer) => answer.indexOf('predefined') !== 0);

export const onInitialIndex = (formularyState) => {
  if (
    !formularyState ||
    !formularyState.current ||
    !formularyState.current.index ||
    !formularyState.initialIndex
  ) {
    return false;
  }
  return formularyState.current.index === formularyState.initialIndex;
};

const questions = (data, node, collected) => {
  const { visibility, submittedAnswers } = data;

  const show = (node) => {
    if (!node) return false;
    if (!node.condition) return true;
    return (
      visibility[node.index] &&
      evaluator({ answers: submittedAnswers })(node.condition)
    );
  };

  if (isQuestion(node) && !isPredefined(node) && show(toQuestion(node))) {
    const submitted = hasKey(String(node.id), data.submittedAnswers);
    collected.push({
      ...toQuestion(node),
      submitted,
    });
    return collected;
  }

  for (const child of (node && node.children) || []) {
    questions(data, child, collected);
  }
  return collected;
};

/**
 * Used to get an array with the currently visible questions
 */
export const questionnaireQuestions = (data) => {
  if (!data) return [];
  if (data.type === 'formulary') {
    console.warn('This function only handles questionnaires');
    return [];
  }
  return questions(data, data.root, []);
};

const last = (a) => a[a.length - 1];

export const lastVisibleQuestion = (data, node) => {
  return last(questions(data, node, []));
};
