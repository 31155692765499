import { useContext } from 'react';

import {
  MarketingTrackingContext,
  MarketingTrackingContextType,
} from '../context/MarketingTrackingContext';

export const useMarketingTracking = (): MarketingTrackingContextType => {
  const authenticationContext = useContext(MarketingTrackingContext);
  if (authenticationContext == null) {
    throw new Error(
      'MarketingTrackingContext is not initialized. Did you try calling it out of its provider?'
    );
  }
  return authenticationContext;
};
