import { useEffect, useState } from 'react';

import { useHostApi } from '@mindoktor/patient-app/host/hooks/useHostApi';

import { useMarketingTrackingApi } from './useMarketingTrackingApi';

export const useMarketingTrackingInitialization = () => {
  const marketingTrackingApi = useMarketingTrackingApi();
  const host = useHostApi();
  const [isInitialized, setIsInitialized] = useState(false);
  const [isInitializing, setIsInitializing] = useState(false);

  useEffect(() => {
    // No need to run anymore
    if (isInitialized) {
      return;
    }

    const timeout = setInterval(() => {
      const isInitializedApi = marketingTrackingApi.isInitialized();
      if (isInitializedApi !== isInitialized) {
        setIsInitialized(isInitializedApi);
        setIsInitializing(false);
      }
    }, 500);

    return () => {
      clearInterval(timeout);
    };
  }, [isInitialized]);

  useEffect(() => {
    // No need to run anymore
    if (isInitialized || isInitializing) {
      return;
    }

    // Cannot run yet
    if (host.isLoading) {
      return;
    }

    marketingTrackingApi.initialize(host.data?.analyticsEnabled === true);
    setIsInitializing(true);
  }, [host, isInitialized]);

  return { isInitialized };
};
