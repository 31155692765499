import { _t } from '@mindoktor/patient-legacy/i18n';
import React from 'react';
import { connect } from 'react-redux';

import { Body1 } from '../../../../common/components/typography';
import constants from '../../../../common/constants';
import { constructUrl } from '../../../../state/api';
import { AttachmentContext } from '../../../../state/cases/constants';
import { getCaseData } from '../../../../state/cases/selectors';
import Link from '../../../components/link';
import { getSite } from '../../../utils/site';
import Message from '../../components/message';
import Header from '../components/header';

const styles = {
  body: {
    paddingBottom: constants.spacing16,
  },
};

export class DoctorFile extends React.Component {
  getConfig = () => {
    return {
      [AttachmentContext.prescription]: {
        body: _t('fileItem.description.prescription'),
        linkText: _t('fileItem.link.prescription'),
      },

      [AttachmentContext.writeup]: {
        body: _t('fileItem.description.writeup'),
        linkText: _t('fileItem.link.writeup'),
      },

      [AttachmentContext.generic]: {
        linkText: _t('fileItem.link.generic'),
      },
    };
  };

  getFileProps = (config, { attachmentContext }) => {
    return config[attachmentContext];
  };

  render() {
    const {
      attachmentContext,
      attachmentId,
      attachmentName,
      attachmentOriginalName,
      doctorImage,
      doctorName,
      doctorSignature,
      timestamp,
      style,
    } = this.props;

    const { body, linkText } = this.getFileProps(this.getConfig(), {
      attachmentContext,
    });

    const displayName = attachmentOriginalName
      ? attachmentOriginalName
      : attachmentName;

    return (
      <Message style={style}>
        <Header
          image={doctorImage}
          title={doctorName}
          signature={doctorSignature}
          timestamp={timestamp}
        />

        <Body1 style={styles.body}>{body}</Body1>
        <Link
          href={constructUrl({
            url: `/api/v1/files/${attachmentId}`,
            params: { site: getSite() },
          })}
        >
          {linkText + ': ' + displayName}
        </Link>
      </Message>
    );
  }
}

export default connect((state, props) => {
  const { caseId } = props;
  const { doctorImage, doctorName, doctorSignature } =
    getCaseData(state, caseId) || {};

  return {
    doctorImage,
    doctorName,
    doctorSignature,
  };
})(DoctorFile);
