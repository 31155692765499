import { PayloadAction } from '@reduxjs/toolkit';

import { ROUTING_OPENED } from './actions';

export type RouterReducerState = {
  route: string | null;
  path: string | null;
  props: any;
};

export const initialState: RouterReducerState = {
  route: null,
  path: null,
  props: {},
};

export default function routerReducer(
  state = initialState,
  action: PayloadAction<Partial<RouterReducerState>>
) {
  const { type, payload } = action;

  switch (type) {
    case ROUTING_OPENED:
      const { route, path, props } = payload;

      return {
        ...state,
        route,
        path,
        props,
      };

    default:
      return state;
  }
}
