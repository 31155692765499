import { _t } from '@mindoktor/patient-legacy/i18n';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from '@mindoktor/patient-legacy/src/common/compose';

import { Title1 } from '../../../common/components/typography';
import {
  getAdultAgeFromHost,
  getIndependentCareAgeFromHost,
} from '../../../state/api/selectors';
import { fetchCasesOverviewList } from '../../../state/cases/actions';
import {
  getClosedCasesAndTicketsByYear,
  getIsLoading,
} from '../../../state/cases/selectors';
import { isUpperAdolescent } from '../../../state/cases/utils';
import { loadDrafts } from '../../../state/drafts/actions';
import { hasFeatureFlag } from '../../../state/featureflags/selectors';
import { fetchSupportTickets } from '../../../state/helpCenter/actions';
import { getInvitations } from '../../../state/profile/actions';
import {
  contactInformation,
  getOpenCaseListItems,
} from '../../../state/profile/selectors';
import { withRouting } from '@mindoktor/patient-app/routing/HOC/withRouting.web';
import { fetchUserNotifications } from '../../../state/userNotifications/actions';
import Button from '../../components/button/button';
import CompanyAttribution from '../../components/company/attribution';
import FullFooter from '../../components/footer/full';
import MediaQuery from '../../components/media_query';
import Screen from '../../components/screen';
import YoungParentModal from '../../guides/modals/YoungParentModal';
import MenuBar from '../../menu/bar';
import { showModal } from '../../routing';
import Cases from './cases';
import { RESPONSIVE_SCREEN_WIDTH_BREAKPOINT } from '@mindoktor/patient-app/utils/device/web';
import { apiHeadersSelector } from '../../../api/api';

import { routes } from '@mindoktor/patient-app/routing/constants/webRoutes';

const styles = {
  body: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },

  wrapper: {
    width: '100%',
    maxWidth: 800,
    paddingTop: 24,
    paddingBottom: 24,
    margin: '0 auto',
  },

  inner: {
    maxWidth: 600,
  },

  title: {
    marginBottom: 16,
    marginLeft: 24,
    marginRight: 24,
    fontWeight: 500,
  },

  button: {
    marginBottom: 24,
    marginLeft: 24,
    marginRight: 24,
  },

  footer: {
    flex: 1,
  },
  item: {
    marginBottom: 24,
    marginLeft: 8,
    marginRight: 8,
  },
};

export class ListScreen extends Component {
  componentDidMount() {
    const {
      fetchCasesOverviewList,
      fetchSupportTickets,
      fetchUserNotifications,
      loadDrafts,
      getInvitations,
      birthDate,
      independentCareAge,
      adultAge,
      apiHeaders,
      routing: { queryParams },
    } = this.props;
    fetchCasesOverviewList();
    fetchSupportTickets();
    fetchUserNotifications(apiHeaders);

    loadDrafts();
    getInvitations();

    const forChildren = queryParams.get('forChildren');

    if (
      forChildren &&
      isUpperAdolescent(birthDate, independentCareAge, adultAge)
    ) {
      showModal(YoungParentModal);
    }
  }

  render() {
    const {
      loading = true,
      caseItems,
      closedCasesByYear,
      resumeClosedCases,
      routing: {
        navigation: { navigate },
      },
    } = this.props;

    return (
      <Screen
        header={
          <MediaQuery minWidth={RESPONSIVE_SCREEN_WIDTH_BREAKPOINT + 1}>
            <MenuBar />
          </MediaQuery>
        }
        body={
          <div style={styles.body}>
            <MediaQuery maxWidth={RESPONSIVE_SCREEN_WIDTH_BREAKPOINT}>
              <MenuBar />
            </MediaQuery>

            <CompanyAttribution />

            <div style={styles.wrapper}>
              <div style={styles.inner}>
                <Title1 style={styles.title}>{_t('caseList.header')}</Title1>
                {!caseItems.length && !loading && (
                  <Button
                    hero={true}
                    onClick={() =>
                      navigate(routes.GUIDES, { entrywayId: 'adult' })
                    }
                    style={styles.button}
                  >
                    {_t('caseList.noCasesButton')}
                  </Button>
                )}
                <Cases
                  caseItems={caseItems}
                  closedCasesByYear={closedCasesByYear}
                  resumeClosedCases={resumeClosedCases}
                />
              </div>
            </div>

            {(!loading || !!caseItems.length || !!closedCasesByYear.length) && (
              <FullFooter style={styles.footer} />
            )}
          </div>
        }
      />
    );
  }
}

const enhance = compose(
  withRouting,
  connect(
    (state) => ({
      loading: !!getIsLoading(state),

      caseItems: getOpenCaseListItems(state),
      closedCasesByYear: getClosedCasesAndTicketsByYear(state),
      resumeClosedCases: hasFeatureFlag(state, 'resume-closed-cases'),
      birthDate: contactInformation(state).birthDate,
      independentCareAge: getIndependentCareAgeFromHost(state),
      adultAge: getAdultAgeFromHost(state),
      apiHeaders: apiHeadersSelector(state),
    }),
    {
      fetchCasesOverviewList,
      fetchSupportTickets,
      fetchUserNotifications,
      loadDrafts,
      getInvitations,
    }
  )
);

export default enhance(ListScreen);
