import { _t } from '@mindoktor/patient-legacy/i18n';
import React, { Component, Fragment } from 'react';

import { Body1, Title1 } from '../../../common/components/typography';
import Button from '../../components/button/button';
import Modal from '../../components/modal';

export default class CannotCancelCaseModal extends Component {
  render() {
    return (
      <Modal
        body={
          <Fragment>
            <Title1 mb="xt">{_t('case.abort.taken')}</Title1>
            <Body1 mb="t">{_t('case.abort.support')}</Body1>
          </Fragment>
        }
        footer={
          <Fragment>
            <Button type="ghost" color="black" onClick={this.props.onCancel}>
              {_t('case.abort.close')}
            </Button>
          </Fragment>
        }
      />
    );
  }
}
