import Config from '@mindoktor/env/Config';

import {
  STATUS_NO_CONTENT as NO_CONTENT,
  STATUS_NOT_FOUND as NOT_FOUND,
} from '../state/api/actions';
import { constructUrl, DEFAULT_HEADERS } from '../state/api';

import uuid from '../common/uuid';
import {
  getDeviceId,
  getSessionId,
  getUserAgent,
} from '../state/app/selectors';
import { getToken } from '../state/api/selectors';
import { platform } from '@mindoktor/patient-app/utils/compatibility/platform/platform';
import { createSelector } from 'reselect';

export interface ApiHeaders {
  authorization: string | undefined;
  userAgent: string;
  deviceId: string;
  sessionId: string;
}

interface Headers extends globalThis.Headers {
  Authorization?: string;
  [key: string]: unknown;
}

interface Request extends RequestInit {
  headers?: Headers;
  method?: 'GET' | 'POST';
  url: string;
}

export interface ApiResponse extends Response {
  data?: { [key: string]: unknown };
  message?: string;
}

/** Statuses for which we ignore the body of a request */
const ignoreBodyStatuses = [NOT_FOUND, NO_CONTENT];

export const apiHeadersSelector = createSelector(
  [getToken, getUserAgent, getDeviceId, getSessionId],
  (
    accessToken: string | undefined | null,
    userAgent: string | undefined | null,
    deviceId: string | undefined | null,
    sessionId: string | undefined | null
  ) => {
    const authorization = accessToken ? `Bearer ${accessToken}` : undefined;
    return {
      authorization,
      userAgent: userAgent ?? '',
      deviceId: deviceId ?? '',
      sessionId: sessionId ?? '',
    };
  }
);

export const ajaxApi = async (
  apiHeaders: ApiHeaders,
  request: Request,
  body: { [key: string]: unknown } | null = null
): Promise<[ApiResponse | null, Error | null]> => {
  // Headers Setup
  const headers = {
    ...DEFAULT_HEADERS,
    'User-Agent': apiHeaders.userAgent,
    'X-MD-Device-ID': apiHeaders.deviceId,
    'X-MD-Session-ID': apiHeaders.sessionId,
    ...request.headers,
  } as Headers;

  if (apiHeaders.authorization && !platform.isWeb) {
    headers.Authorization = apiHeaders.authorization;
  }

  // Compose request URL
  const constructedUrl = constructUrl({
    url: request.url,
    params: {
      site: Config.DefaultSite,
      requestId: encodeURIComponent(uuid.generate()),
    },
  });

  // Fetch resource
  let response: ApiResponse | null = null;
  try {
    response = await fetch(constructedUrl, {
      method: request.method || 'GET',
      credentials: request.credentials || 'include',
      headers,
      body: body ? JSON.stringify(body) : undefined,
    });
    const jsonResponse = await parseJson(response);
    return [jsonResponse, null];
  } catch (err) {
    if (response) {
      console.warn(response.status, response.message, request.url);
    } else {
      console.error(`Could not fetch ${request.url}.`);
    }
    return [response, err as Error];
  }
};

export const parseJson = async (response: ApiResponse) => {
  // We save the response text for logging
  const text = await response?.text();

  if (!text || ignoreBodyStatuses.includes(response.status)) return null;

  let json;

  // When we can't parse JSON into an object - we want to inspect what response is failing to parse
  try {
    json = JSON.parse(text);
  } catch (err) {
    console.error({
      err: err,
      status: response.status,
      responseBody: text,
    });
  }

  return json;
};
