import { _t } from '@mindoktor/patient-legacy/i18n';
import dayjs from 'dayjs';
import { MdPhoto } from '../../../components/docly-md-icons';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import colors from '../../../../common/colors';
import { Body3 } from '../../../../common/components/typography';
import { constructUrl } from '../../../../state/api';
import { getPatientNameAndBirthDate } from '../../../../state/profile/selectors';
import AttachmentOverlay from '../../../components/attachment_overlay';
import { getFileData } from '../../../utils/files';
import { getSite } from '../../../utils/site';
import Message from '../../components/message';
import Header from '../components/header';

const styles = {
  videoWrapper: {
    maxWidth: 400,
  },

  video: {
    display: 'block',
    maxWidth: '100%',
    maxHeight: 200,
    backgroundColor: '#eee',
  },

  loading: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 400,
    height: 200,
    backgroundColor: '#eee',
  },

  error: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 400,
    height: 200,
    backgroundColor: `${colors.yellow_500}50`,
    color: colors.error_text,
  },

  photoIcon: {
    display: 'block',
    width: 44,
    height: 44,
    margin: 8,
    color: 'black',
    opacity: 0.33,
  },
};

// type Props = {
//   caseId: number,
//   doctorImage?: string,
//   doctorName?: string,
//   doctorSignature?: string,
//   patientInitials?: string,
//   patientName?: string,
//   timestamp: number,
//   attachmentId: number,
//   fromPatient: boolean,
//   path?: string,
//   status?: number,
//   style?: any,
// };

export class Video extends Component {
  state = {
    loaded: false,
    error: false,

    src: undefined,
    poster: undefined,
    type: undefined,
  };

  readFileData = async () => {
    const { attachmentId, path } = this.props;

    if (path) {
      try {
        const src = await getFileData(path);

        if (src) {
          const match = /^data:([^;]*)/i.exec(src);

          this.setState({ src, type: match && match[1] });

          return;
        }
      } catch (e) {
        // ignore
      }
    }

    this.setState({
      src: constructUrl({
        url: `/api/v1/files/${attachmentId}`,
        params: { site: getSite() },
      }),
      poster: constructUrl({
        url: `/api/v1/files/${attachmentId}`,
        params: { site: getSite(), thumb: 'true' },
      }),
    });
  };

  onLoad = () => this.setState({ loaded: true });

  onError = () => this.setState({ error: true });

  componentDidMount() {
    this.readFileData();
  }

  render() {
    const {
      initials,
      name,
      timestamp,
      status,
      style,
      attachmentReferenceNumber,
    } = this.props;
    const { loaded, error, src, poster, type } = this.state;

    const processing = error && dayjs().diff(timestamp, 'minute') < 10;

    return (
      <Message style={style}>
        <Header
          initials={initials}
          title={name}
          timestamp={timestamp}
          status={status}
        />

        <AttachmentOverlay referenceNumber={attachmentReferenceNumber}>
          {!!src && (
            <div style={styles.videoWrapper}>
              <video
                style={{ ...styles.video, display: loaded ? 'block' : 'none' }}
                poster={poster}
                controls
                onLoadedMetadata={this.onLoad}
                onError={this.onError}
              >
                <source src={src} type={type || 'video/mp4'} />
              </video>
            </div>
          )}

          {((!loaded && !error) || (processing && error)) && (
            <div style={styles.loading}>
              <MdPhoto style={styles.photoIcon} />

              {processing && <Body3>{_t('videoItem.processing')}</Body3>}
            </div>
          )}

          {!loaded && error && !processing && (
            <Body3 style={styles.error}>
              <MdPhoto style={styles.photoIcon} />
              {_t('videoItem.faieldToLoad')}
            </Body3>
          )}
        </AttachmentOverlay>
      </Message>
    );
  }
}

export default connect((state) => {
  const { initials, fullName: name } = getPatientNameAndBirthDate(state);

  return {
    initials,
    name,
  };
})(Video);
