import { INFORMED_CONSENT_FIRST, INFORMED_CONSENT_SECOND } from './types';

export const setInformedConsent =
  (entrywayId, type, value) => async (dispatch) => {
    if (type === 'first') {
      dispatch({
        type: INFORMED_CONSENT_FIRST,
        payload: { entrywayId, value },
      });
    }

    if (type === 'second') {
      dispatch({
        type: INFORMED_CONSENT_SECOND,
        payload: { entrywayId, value },
      });
    }
  };
