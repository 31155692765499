import { create, DEFAULT_HEADERS, PROBLEM_CODE } from 'apisauce';
import { v4 as uuidv4 } from 'uuid';
import { Schema } from 'zod';

import Config from '@mindoktor/env/Config';

import { platform } from '@mindoktor/patient-app/utils/compatibility/platform/platform';

import { validate } from './middleware/validation/validator';
import { getDeviceId, USER_AGENT } from './device';
import { getSessionId } from './session';

interface Request {
  get: <TData>(
    url: string,
    config: RequestConfig,
    params?: Record<string, string>
  ) => Promise<ApiResponse<TData>>;
  post: <TResponseData, TRequestData = unknown>(
    url: string,
    data?: TRequestData,
    config?: RequestConfig
  ) => Promise<ApiResponse<TResponseData>>;
  setGlobalConfig: (config: RequestConfig) => void;
  getApiConfig: () => {
    baseUrl: string;
    headers: Record<string, string>;
  };
}

interface LegacyApiError {
  msg: string;
}

interface ApiError {
  error: {
    code: string;
    message: string;
  };
}

type ApiErrorComposite = LegacyApiError | ApiError;

export interface ApiErrorResponse {
  ok: false;
  data?: ApiErrorComposite;
  status?: number;
  problem?: PROBLEM_CODE;
}

export interface ApiOkResponse<TData> {
  ok: true;
  data?: TData;
}

export type ApiResponse<TData> = ApiErrorResponse | ApiOkResponse<TData>;

export interface RequestConfig {
  validationSchema: Schema;
  deviceId?: string;
  sessionId?: string;
  headers?: Record<string, string>;
}

type UserAgentHeader = { 'User-Agent': string } | {};

// The custom user agent string MUST be set for the NAPP since certain features relies on it.
// Even if it is technically allowed to set it for web, Chrome and Safari throws an error when
// doing so.
const userAgent: UserAgentHeader = platform.isNative
  ? { 'User-Agent': USER_AGENT }
  : {};

/**
 * Creates an instance of our API.
 *
 * NOTE: Do not change these headers unless you know what you are doing.
 */
const api = create({
  baseURL: Config.Urls?.base?.domain,
  headers: {
    ...DEFAULT_HEADERS,
    'X-Requested-With': 'XMLHttpRequest',
    'X-MD-Device-ID': getDeviceId(),
    'X-MD-Session-ID': getSessionId(),
    ...userAgent,
  },
  timeout: 60000,
  withCredentials: true,
});

let globalConfig: RequestConfig | undefined;

const setGlobalConfig = (config: RequestConfig) => {
  globalConfig = config;
};

const get = async <TData>(
  urlString: string,
  config: RequestConfig,
  params?: Record<string, string>
): Promise<ApiResponse<TData>> => {
  const url = new URL(urlString, api.getBaseURL());
  appendQueryParams(url);
  const headers = await getHeaders(config);
  const response = await api.get<TData, ApiErrorComposite>(url.href, params, {
    headers,
  });
  validate(response, config.validationSchema);
  return response;
};

const post = async <TData>(
  urlString: string,
  data?: unknown,
  config?: RequestConfig
): Promise<ApiResponse<TData>> => {
  const url = new URL(urlString, api.getBaseURL());
  appendQueryParams(url);
  const headers = await getHeaders(config);
  return await api.post<TData, ApiErrorComposite>(url.href, data, { headers });
};

const getHeaders = async (
  config?: RequestConfig
): Promise<Record<string, string>> => {
  let headers: Record<string, string> = {};
  let combinedConfig: RequestConfig | undefined;
  if (globalConfig !== undefined) {
    combinedConfig = { ...globalConfig, ...config };
  } else {
    combinedConfig = config;
  }
  if (combinedConfig !== undefined) {
    if (combinedConfig.deviceId !== undefined) {
      headers['X-MD-Device-ID'] = combinedConfig.deviceId;
    }
    if (combinedConfig.sessionId !== undefined) {
      headers['X-MD-Session-ID'] = combinedConfig.sessionId;
    }
    if (combinedConfig.headers !== undefined) {
      headers = { ...headers, ...combinedConfig.headers };
    }
  }

  return headers;
};

const appendQueryParams = (
  url: URL,
  params: Record<string, string> = {}
): void => {
  let site = Config.DefaultSite;
  // Get site information (required by Skandia), applicable for web only.
  if (platform.isWeb) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    site = urlParams.get('site') ?? Config.DefaultSite;
  }
  url.searchParams.append('site', site);
  url.searchParams.append('requestId', uuidv4());
  Object.entries(params).forEach((entry) => {
    url.searchParams.append(entry[0], entry[1]);
  });
};

const getApiConfig = () => ({
  baseUrl: api.getBaseURL(),
  headers: api.headers,
});

export const requestHandler: Request = {
  get,
  post,
  setGlobalConfig,
  getApiConfig,
};
