import React from 'react';
import { _t } from '@mindoktor/patient-legacy/i18n';
import dayjs from 'dayjs';

import colors from '../../../../common/colors';
import { Body1 } from '../../../../common/components/typography';
import Link from '../../../components/link';
import Message from '../../components/message';

const styles = {
  header: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 24,
  },
  updated: {
    marginTop: 16,
  },

  icon: {
    display: 'block',
    width: 44,
    height: 44,
    marginRight: 16,
  },
  errorMsg: {
    color: colors.red_500,
    fontWeight: 500,
    marginBottom: 20,
  },
  linkWrapper: {
    display: 'inline',
    marginLeft: 60,
  },
};

const Answers = ({
  caseId,
  iconSource,
  style,
  hasTechnicalError,
  hasPayment,
  hasHistory,
  updated,
  firstName,
}) => {
  return (
    <Message style={style}>
      <div style={styles.header}>
        <div>
          <img src={iconSource} style={styles.icon} />
        </div>

        <div>
          <Body1>
            {_t('welcome.user.text', { name: firstName }) +
              _t('guides.done.queue') +
              ' ' +
              _t('formularyAnswersMessageItem.body')}
          </Body1>

          {hasHistory && !!updated && (
            <Body1 style={styles.updated}>
              {_t('formularyAnswersMessageItem.hasHistory', {
                date: dayjs(updated).format('ll'),
              })}
            </Body1>
          )}
        </div>
      </div>
      {!hasPayment && hasTechnicalError && (
        <Body1 style={styles.errorMsg}>
          {_t('errorMessage.technicalError.contactSupport')}
        </Body1>
      )}
      <Body1 style={styles.linkWrapper}>
        <Link href={`#/cases/${caseId}/answers`}>
          {_t('formularyAnswersMessageItem.button')} ›
        </Link>
      </Body1>
    </Message>
  );
};

export default Answers;
