// type State = {
//   resolve?: (*) => *,
//   reject?: (*) => *,
//   question?: Question,
//   result?: Result,
// };

// export type Inquisitor = {
//   getQuestion: () => ?Question,
//   getResult: () => ?Result,

//   start: Draft => Promise<Draft>,
//   push: (*) => Promise<void>,
// };

const inquisitors = {};

export const deleteInquisitor = (id) => {
  delete inquisitors[id];
};
export const setInquisitor = (id, inquisitor) => (inquisitors[id] = inquisitor);
export const getInquisitor = (id) => inquisitors[id];

export const createInquisitor = (inquisition, patient) => {
  const { getState, getStatePromise, replaceState } = createState();

  return {
    getQuestion: () => getState().question,
    getResult: () => getState().result,

    start: async ({ visited = [], values = {} } = {}) => {
      const { reject } = getState();
      if (reject) reject();

      let forward = true;
      let index = 0;

      const statePromise = getStatePromise();

      inquisition({
        ask: (question) =>
          new Promise((resolve, reject) => {
            if (forward && visited[index] === question.ref) {
              index++;
              resolve(values[question.ref]);
            } else {
              replaceState({ resolve, reject, question });
            }
          }),
        patient,
      }).then((result) => result && replaceState({ result }));

      await statePromise;

      forward = false;

      return { visited: visited.slice(0, index), values };
    },

    push: async (value) => {
      const { resolve } = getState();

      if (!resolve) throw new Error('no resolve');

      const statePromise = getStatePromise();

      resolve(value);

      await statePromise;
    },
  };
};

const createState = () => {
  let _state = {};
  let _stateResolve;
  let _statePromise;
  return {
    getState: () => _state,
    getStatePromise: () =>
      (_statePromise =
        _statePromise || new Promise((resolve) => (_stateResolve = resolve))),
    replaceState: (newState) => {
      _state = newState;
      if (_stateResolve) _stateResolve();
      _statePromise = _stateResolve = undefined;
    },
  };
};
