import { DoclyIntlProvider } from '@mindoktor/patient-legacy/i18n';
import polyfillLocales from '@mindoktor/patient-legacy/i18n/polyfillLocales';
import React from 'react';
import { connect } from 'react-redux';
polyfillLocales(window);

const Provider = ({ currentLocale, defaultLocale, children }) => {
  return (
    <DoclyIntlProvider
      defaultLocale={defaultLocale}
      locale={currentLocale}
      // textComponent={'span'}
    >
      {children}
    </DoclyIntlProvider>
  );
};

export default connect((state) => ({
  currentLocale: state.intl.currentLocale,
  defaultLocale: state.intl.defaulLocale,
}))(Provider);
