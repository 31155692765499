import { Keys, Values } from '@mindoktor/patient-app/utils/types/objects';

/**
 * See more at: https://docs.google.com/spreadsheets/d/170mz1ynJaBwrLV-kXgZkgyXdlyKHtsNHLVDq1ZRr6x4/edit?usp=sharing
 */
export const MarketingTrackingActions = {
  LoginStarted: 'Login.Started',
  LoginCompleted: 'Login.Completed',
  RegistrationStarted: 'Registration.Started',
  RegistrationCompleted: 'Registration.Completed',
  GuidePickerViewed: 'GuidePicker.Viewed',
  GuidePickerUserStayed: 'GuidePicker.UserStayed',
  GuideIntroAViewed: 'GuideIntroA.Viewed',
  GuideIntroBViewed: 'GuideIntroB.Viewed',
  GuideAborted: 'Guide.Aborted',
  ContactInfoVerificationViewed: 'Confirmation.Viewed',
} as const;

export type MarketingTrackingActionName = Keys<typeof MarketingTrackingActions>;
export type MarketingTrackingAction = Values<typeof MarketingTrackingActions>;

export const marketingTrackingCategories = {
  System: 'System',
} as const;

export type MarketingTrackingCategory =
  keyof typeof marketingTrackingCategories;

export interface SendEventAdditionalProperties {
  value?: number;
  step?: number;
  orderId?: string;
  products?: SendEventAdditionalPropertiesProducts[];
  gclid?: string;
  paymentMethod: string;
}

interface SendEventAdditionalPropertiesProducts {
  name?: string;
}

export interface TrackOptions {
  category?: MarketingTrackingCategory;
  label?: string;
  additionalProperties?: SendEventAdditionalProperties;
}

export interface MarketingAPI {
  initialize: (isEnabled: boolean) => void;
  isInitialized: () => boolean;
  identify: (userMarketingId: string) => void;
  track: (
    action: MarketingTrackingAction,
    options?: TrackOptions
  ) => Promise<void>;
  cleanupSession: () => void;
}
