import { _t } from '@mindoktor/patient-legacy/i18n';
import React, { useEffect, useRef, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import spacing from '../../../common/spacing';
import { postApi } from '../../../state/api/actions';
import { hasFeatureFlag } from '../../../state/featureflags/selectors';
import { addFormulary } from '../../../state/formulary/actions';
import { showNotification } from '../../../state/notifications/actions';
import { addSurvey } from '../../../state/survey2/actions';
import { surveyNames } from '../../../state/survey2/constants';
import {
  getQuestionnaireData,
  getQuestionsAndAnswers,
} from '../../../state/survey2/selectors';
import BoxedScreen from '../../components/boxed_screen';
import Question from './Question';
import { CommonStateType } from '../../../state/types';
import type {
  Question as QuestionType,
  QuestionnaireData,
} from '../../../state/formulary/types';
import { useQueryParams } from '@mindoktor/patient-app/routing/hooks/useQueryParams/web';
import { useNavigationWithLegacyFallback } from '@mindoktor/patient-app/routing/hooks/useNavigationWithLegacyFallback/web';
import { routes } from '@mindoktor/patient-app/routing/constants/webRoutes';

const styles = {
  body: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    flex: 1,
    paddingBottom: spacing.m,
  },
  content: {
    width: '100%',
    maxWidth: 600,
    marginLeft: 'auto',
    paddingTop: 20,
  },
} as const;

interface CurrentQuestionProps {
  questionsAndAnswers: Array<QuestionType>;
  questionnaireData: QuestionnaireData;
  uuid: string;
}

// Todo: make it a selector
const CurrentQuestion: React.FC<CurrentQuestionProps> = ({
  questionsAndAnswers,
  questionnaireData: { submittedAnswers /* , hasUnsubmittedAnswers */ },
  uuid,
}) => {
  const lastQuestion = questionsAndAnswers.find(
    ({ id }) => !submittedAnswers[id]
  );
  if (!lastQuestion) return null;

  return (
    <Question
      formularyKey={surveyNames.FOLLOWUP_EVALUATION}
      node={lastQuestion}
      uuid={uuid}
    />
  );
};

/** According to mindoktor/pkg/company/links.go */
interface SurveyQueryParams {
  uuid: string;
  score: string;
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
}

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

const SurveyScreen: React.FC<Props> = (props) => {
  const isFinished = props.questionnaireData?.isFinished;
  const wasFinished = useRef(isFinished === true);
  const [uuid, setUuid] = useState<string | null>(null);

  const navigationWithLegacyFallback = useNavigationWithLegacyFallback();

  const openSurveyCompleted = () => {
    navigationWithLegacyFallback.navigate(routes.FOLLOWUP_SURVEY_COMPLETED);
  };

  const queryParams = useQueryParams();

  const postNPS = async () => {
    const {
      postApi,
      showNotification,
      addFormulary,
      addSurvey,
      hasSurveyFollowupStep2,
    } = props;

    const queryParamsList = new Array(...queryParams.entries());
    const query = Object.fromEntries(
      queryParamsList.map(([k, v]) => {
        if (k === 'score') {
          return [k, Number(v)];
        }

        return [k, v];
      })
    ) as SurveyQueryParams & { score?: number };

    const { json, error } = await postApi('/api/v1/net-promoter-score', query);

    if (error) {
      showNotification({ type: 'error', message: _t('followup.posterror') });
      return;
    }

    if (!hasSurveyFollowupStep2) {
      openSurveyCompleted();
      return;
    }

    const { formulary } = json;
    setUuid(query.uuid as string);

    addFormulary({
      type: 'questionnaire',
      formulary,
      answers: {
        'survey.nps': query.score,
      },
      key: surveyNames.FOLLOWUP_EVALUATION,
    });

    addSurvey({
      // @ts-expect-error a proper caseId and formularyKey is not needed
      // for the follow-up survey since it is displayed in its own context.
      // We might keep an eye on this though. Maybe we should reload the page when
      // the survey is finished or aborted.
      caseId: surveyNames.FOLLOWUP_EVALUATION,
      surveyName: surveyNames.FOLLOWUP_EVALUATION,
      formularyKey: surveyNames.FOLLOWUP_EVALUATION,
    });
  };

  useEffect(() => {
    if (!wasFinished && isFinished) {
      openSurveyCompleted();
    }

    wasFinished.current = isFinished === true;
  }, [isFinished]);

  useEffect(() => {
    postNPS();
  }, []);

  const { questionsAndAnswers, questionnaireData } = props;

  if (!questionnaireData || questionnaireData?.isFinished) {
    return null;
  }

  return uuid && questionsAndAnswers && questionsAndAnswers.length ? (
    <BoxedScreen
      body={
        <div style={styles.body}>
          <div style={styles.content}>
            <CurrentQuestion
              uuid={uuid}
              questionsAndAnswers={questionsAndAnswers}
              questionnaireData={questionnaireData}
            />
          </div>
        </div>
      }
    />
  ) : (
    <div
      style={{
        display: 'flex',
        width: '100vw',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      ...
    </div>
  );
};

const mapStateToProps = (state: CommonStateType) => ({
  questionnaireData: getQuestionnaireData(
    state,
    /** @ts-expect-error See explanation above */
    surveyNames.FOLLOWUP_EVALUATION,
    surveyNames.FOLLOWUP_EVALUATION
  ),
  questionsAndAnswers: getQuestionsAndAnswers(
    state,
    /** @ts-expect-error See explanation above */
    surveyNames.FOLLOWUP_EVALUATION,
    surveyNames.FOLLOWUP_EVALUATION
  ),
  hasSurveyFollowupStep2: hasFeatureFlag(state, 'survey-followup-step2'),
});

const connector = connect(mapStateToProps, {
  postApi,
  showNotification,
  addFormulary,
  addSurvey,
});

const ConnectedSurveyScreen = connector(SurveyScreen);
export default ConnectedSurveyScreen;
