export const RECEIVE_FREE_PASS = 'RECEIVE_FREE_PASS';
export const FREE_PASS_ERROR = 'FREE_PASS_ERROR';

export type FreePassStatusType =
  | 'noCard'
  | 'preliminary'
  | 'final'
  | 'belowLimit'
  | undefined;

export const FREEPASS_EFRIKORT_SERVICENAME = 'eFrikort';

export const freePassStatuses = {
  NO_CARD: 'noCard',
  PRELIMINARY: 'preliminary',
  FINAL: 'final',
  BELOW_LIMIT: 'belowLimit',
} as const;

// export type FreePassAction = {
//   type: typeof RECEIVE_FREE_PASS,
//   payload: {
//     number: string,
//     issuer: string,
//     expires: string,
//   },
// };

// export type FreePassErrorAction = {
//   type: typeof FREE_PASS_ERROR,
//   payload: {
//     hasError: boolean,
//     errorMessage: string,
//   },
// };

// export type Action = FreePassAction | FreePassErrorAction;

export interface FreePassState {
  number?: string;
  issuer?: string;
  expires?: string;
  hasError: boolean;
  errorMessage?: string;
  origin?: string;
  status?: FreePassStatusType;
  amountToLimit?: number;
}

export interface FreePassEditData {
  number: string;
  issuer: string;
  expires: string;
  isValid: boolean;
  isChanged: boolean;
}
