// mirrored from BE (pkg/users/sex/const.go)
export const Sex = {
  unknown: 0,
  male: 1,
  female: 2,
};

export function getInitials(firstName, lastName) {
  const initials = `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();
  return initials;
}

export function getAge(_birthDate) {
  // Fix to support birthDate format YYYYMMDD
  const birthDate = !_birthDate.includes('-')
    ? birthDateToDate(_birthDate)
    : _birthDate;

  const [year, month, date] = birthDate.split('-');
  const now = new Date();

  const yearsDiff = now.getFullYear() - parseInt(year, 10);
  const monthsDiff = now.getMonth() + 1 - parseInt(month, 10);
  const dateDiff = now.getDate() - parseInt(date, 10);

  let monthsElapsed = yearsDiff * 12 + monthsDiff;
  if (dateDiff < 0) {
    monthsElapsed--;
  }

  return {
    years: Math.floor(monthsElapsed / 12),
    yearsFloat: monthsElapsed / 12,
    months: monthsElapsed % 12,
  };
}

export function birthDateToDate(b) {
  return `${b.substr(0, 4)}-${b.substr(4, 2)}-${b.substr(6, 2)}`;
}
