// Constants and enums from BE/models/casereferral.go

import { Keys, Values } from '@mindoktor/patient-app/utils/types/objects';

export const caseReferralStatus = {
  New: 'New',
  Approved: 'Approved',
  Sent: 'Sent',
  Unsigned: 'Unsigned',
  Signed: 'Signed',
  Canceled: 'Canceled',
  MarkedDone: 'MarkedDone',
  HasResults: 'HasResults',
};

export const caseReferralType = {
  None: 'None',
  LabTest: 'LabTest',
  Specialist: 'Specialist',
  XRay: 'XRay',
  SelfTest: 'SelfTest',
  ClinicalPhysiology: 'ClinicalPhysiology',
  Cytology: 'Cytology',
  Freetext: 'Freetext',
  OtherEstablishment: 'OtherEstablishment',
  ContactTracing: 'ContactTracing',
  UrgentReferral: 'UrgentReferral',
  GenericInternational: 'GenericInternationalReferral',
};

/**
 * Based on BE/models/casereferral.go
 *
 * GuideAborted indicates that the guide was aborted due to answers from the patient.
 * Pending indicates that the case has not yet been assigned to a doctor.
 * InProgress indicates that the guide is beeing handled by a doctor.
 * Finished indicates that the case has been handled and finished.
 * CanceledByPatient indicates that the guide for this case was canceled by the patient.
 * ClosedByDoctor indicates that the case was closed by a doctor.
 * PaymentTimeout indicates that the case was automatically moved to this status by the db cleanup job after a certain period of time.
 * PaymentError indicates that a payment error occured
 */
export const CaseStatuses = {
  GuideAborted: 0,
  Pending: 1,
  InProgress: 2,
  /** a.k.a. CLOSED */
  Finished: 3,
  CanceledByPatient: 4,
  ClosedByDoctor: 5,
  PaymentTimeout: 6,
  PaymentError: 7,
  AutomationEvaluating: 9,
} as const;

export type CaseStatusKey = Keys<typeof CaseStatuses>;
export type CaseStatus = Values<typeof CaseStatuses>;

// export type CaseStatusType = $Values<typeof CaseStatus>;

export const MessageType = {
  TEXT: 0,
  IMAGE: 1,
  VIDEOCALL: 2,
  REFERRAL: 3,
  PRESCRIPTION: 4,
  SURVEY: 7,
  VIDEO: 8,
  CERTIFICATE: 9,
  FORMULARY_ANSWERS: 11,
  WORKOUT: 12,
  INVITATION_SCHEDULED: 13,
  VIDEOCHAT_ACTIVE: 14,
  FILE: 15,
  SHARED_REFERRAL_RESULTS: 16,
  QUESTIONNAIRE: 17,
  NEW_MESSAGES_DIVIDER: 18,
  CLOSED_CASE: 19,
  // 20 used to be LIVANDA, which is now deprecated.
  RETAKE_IMAGE: 21,
  RETAKE_VIDEO: 22,
  VIDEOCALL_INVITATION: 23,
  SCHEDULED_APPOINTMENT: 24,
  RESUME_CLOSED_CASE: 25,
  APH_LINK: 26,
  VACCINATION_TBE: 27,
  LISTING_LINK: 28,
  PAYMENTINFO_INVOICE: 29,
  PAYMENTINFO_FREE: 30,
  WRONG_ATTACHMENT: 31,
  FLU_SEASON_LINK: 32,
};

// Link to MD listing page. TODO: use correct link
export const MD_LISTING_LINK = 'https://www.mindoktor.se/listning/';

export const MessageSendStatus = {
  DONE: 0,
  SENDING: 1,
  FAILED: 2,
};

export const FileUploadStatus = {
  FAILED: -1,
  UPLOADING: 0,
  UPLOAD_STARTING: 1,
  UPLOADED_FILE: 2,
  ATTACHED_TO_CASE: 3,
};

export const VideocallStatus = {
  CHOICE: 0,
  ACCEPTED: 1,
  REJECTED: 2,
  // local statuses, replaced by flags in server data
  PENDING: 3,
  ISINPAST: 4,
};

export const Treater = {
  doctor: 'doctor',
  generic: 'generic',
  midwife: 'midwife',
  physiotherapist: 'physiotherapist',
  psychologist: 'psychologist',
};

export type AllowedTreater = Keys<typeof Treater>;

export const ContactType = {
  CHAT: 'chat',
  PHONE: 'phone',
  VIDEO: 'video',
};

export const PrescriptionType = {
  UNKNOWN: 0,
  ERECEPT: 1,
  FREETEXT: 2,
};

export const AttachmentContext = {
  prescription: 'prescription',
  writeup: 'writeUp',
  formulary: 'formulary',
  generic: 'generic',
};

export const HeaderType = {
  doctor: 'doctor',
  patient: 'patient',
};

// CertificateType is the equivalent of the BE consts for certificates:
// https://github.com/mindoktor/mindoktor/blob/1706334cb7786c5ad2a0c4db3bc2e4953f5c0084/pkg/certificate/const.go#L16
export const CertificateType = {
  MD: 1, // Min Doktor
  FK17: 2, // Försäkringskassan day 1 to 7
  FK8: 3, // Försäkringskassan day 8 and above
  DoclyFr: 4, // Docly branded certificates for French companies
  Vab: 5, // Försäkringskassan VAB (for children younger than 12 years, for day 8+)
  Vab1216: 6, // Försäkringskassan VAB (for children 12-16 years old)
  Covid19: 7, // Covid 19
  TravelCovid19: 8, // Travel certificate Covid 19
};

export const CertificateStatus = {
  Draft: 1,
  Sent: 2,
  Cancelled: 3,
  Removed: 4,
};

export const DeclineType = {
  retake: 'retake',
  invalidate: 'invalidate',
};
