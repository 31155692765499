import {
  ApiResponse,
  requestHandler,
} from '@mindoktor/patient-app/api/requestHandler';
import { FeatureFlagsResponse } from '@mindoktor/patient-app/utils/featureFlags/api/models/featureFlags';
import { FeatureFlagsSchema } from '@mindoktor/patient-app/utils/featureFlags/api/schema/featureFlags';

export const getFeatureFlags = async (): Promise<
  ApiResponse<FeatureFlagsResponse>
> => {
  return await requestHandler.get('/api/v1/featureflags', {
    validationSchema: FeatureFlagsSchema,
  });
};

export const api = {
  getFeatureFlags,
};
