import MuiAccordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import config from '@mindoktor/env/Config';
import { _t } from '@mindoktor/patient-legacy/i18n';
import React, { useEffect, useState } from 'react';

import billogramIconSource from '../../../../images/billogram/billogram_icon_purple.svg';
import colors from '../../../common/colors';
import { Body1, SubHead } from '../../../common/components/typography';
// import type {
//   FreePassState,
//   FreePassEditData,
// } from '../../../state/freepass/types';
import { isValidEFrikort } from '../../../state/freepass/utils';
import { freePassStatuses } from '../../../state/freepass/types';
import { paymentMethodTypes } from '../../../state/payment/types';
import OptionInput from '../../components/input/option';
import FreePassForm from './payment_selection_freepass';

const Accordion = withStyles({
  root: {
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: `1px solid ${colors.grey_300}`,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'transparent',
    marginBottom: -1,
    minHeight: 60,
    '&$expanded': {
      minHeight: 60,
    },
  },
  content: {
    margin: 0,
    '&$expanded': {
      margin: 0,
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const useStyles = makeStyles({
  root: {
    padding: 16,
    paddingTop: 24,
    marginBottom: 20,
    marginLeft: -16,
    marginRight: -16,
  },
  title: {
    marginBottom: 10,
  },
  options: {
    marginLeft: -20,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  accordionDetails: {
    padding: 0,
    marginBottom: 12,
  },
  infoBox: {
    width: '100%',
    padding: '16px 12px',
  },
  billogramIcon: {
    marginRight: -34,
    width: 34,
    height: 32,
  },
});

// type Props = {
//   freePass?: FreePassState,
//   onPaymentMethodChange: (type: PaymentMethodType) => any,
//   onFreePassChange: (data: FreePassEditData) => any,
//   allowFreePass: boolean,
//   classes?: any,
// };

const PaymentSelectorOld = (props) => {
  const { freePass, onPaymentMethodChange, onFreePassChange, allowFreePass } =
    props;
  const [paymentMethod, setPaymentMethod] = useState();
  /* <
    PaymentMethodType | typeof undefined
  >
  */

  const classes = useStyles(props);

  useEffect(() => {
    if (!(allowFreePass && config.ShowFreePass)) {
      setPaymentMethod(paymentMethodTypes.INVOICE);
    } else if (freePass?.status === freePassStatuses.FINAL) {
      setPaymentMethod(paymentMethodTypes.FREEPASS);
    }
  }, []);

  useEffect(() => {
    !!paymentMethod && onPaymentMethodChange(paymentMethod);
  }, [paymentMethod]);

  return (
    <Paper variant="outlined" className={classes.root}>
      <SubHead className={classes.title}>
        {_t('payment.paymentSelection.title.old')}
      </SubHead>
      {allowFreePass && config.ShowFreePass && (
        <Accordion
          classes={{ root: classes.accordionRoot }}
          expanded={paymentMethod === paymentMethodTypes.FREEPASS}
        >
          <AccordionSummary aria-controls="freepass" id="freepass-header">
            <div className={classes.options}>
              <OptionInput
                label={
                  isValidEFrikort(freePass)
                    ? _t('payment.paymentSelection.efrikort')
                    : _t('payment.paymentSelection.freepass')
                }
                checked={paymentMethod === paymentMethodTypes.FREEPASS}
                multiple={false}
                lastItem={true}
                onChange={() => setPaymentMethod(paymentMethodTypes.FREEPASS)}
              />
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <FreePassForm onChange={onFreePassChange} freePass={freePass} />
          </AccordionDetails>
        </Accordion>
      )}
      <Accordion
        classes={{ root: classes.accordionRoot }}
        expanded={paymentMethod === paymentMethodTypes.INVOICE}
      >
        <AccordionSummary aria-controls="invoice" id="invoice-header">
          <div className={classes.options}>
            <OptionInput
              className={classes.options}
              label={_t('payment.paymentSelection.invoice')}
              checked={paymentMethod === paymentMethodTypes.INVOICE}
              multiple={false}
              lastItem={true}
              onChange={() => setPaymentMethod(paymentMethodTypes.INVOICE)}
            />
            <img
              alt="billogram"
              src={billogramIconSource}
              className={classes.billogramIcon}
            />
          </div>
        </AccordionSummary>
        <AccordionDetails classes={{ root: classes.accordionDetails }}>
          <Paper variant="outlined" elevation={0} className={classes.infoBox}>
            <Body1 className={classes.body}>
              {_t('payment.paymentSelection.invoice.billogram.body')}
            </Body1>
          </Paper>
        </AccordionDetails>
      </Accordion>{' '}
    </Paper>
  );
};

export default PaymentSelectorOld;
