import React, { FC } from 'react';
import { routes } from '@mindoktor/patient-app/routing/web';
import { _t } from '@mindoktor/patient-legacy/i18n';
import * as Boxed from '../../components/boxedscreen';
import Markdown from '../../components/markdown';
import { useEmailVerification } from '../../../api/contactInfoVerification/useEmailVerification';

export interface Verification {
  type: 'email';
  info: string;
}

interface Props {
  onComplete: () => void;
  title?: string;
  variant?: Boxed.ScreenVariant;
  emailChangeInfo?: boolean;
}

const VerifyEmail: FC<Props> = ({
  emailChangeInfo = true,
  onComplete,
  title,
  variant = Boxed.ScreenVariant.Screen,
}) => {
  const {
    handleChangeTextInput,
    handleContinueButton,
    handleSendAgainButton,
    code,
    isDisabled,
    isLoading,
    verification,
  } = useEmailVerification({ onComplete });

  if (isLoading) return null;

  const email = verification.info;

  const lineBreak = '\n\n';
  return (
    <>
      <Boxed.Body variant={variant}>
        <Boxed.Heart />
        <Boxed.Title>
          {title ?? _t('guides.contactinfo.verification.entercode.title')}
        </Boxed.Title>
        <Boxed.Lead>
          <Markdown
            source={_t('contactinfo.verification.entercode.instruction', {
              email,
            })}
          />
        </Boxed.Lead>
        <Boxed.FooterButtons>
          <Boxed.Input
            value={code}
            onChange={handleChangeTextInput}
            placeholder={_t('contactinfo.verification.entercode.placeholder')}
          />
          <Boxed.Section>
            <Markdown
              source={
                _t('guides.contactinfo.verification.entercode.nomailinfo') +
                lineBreak +
                emailChangeInfo
                  ? _t(
                      'guides.contactinfo.verification.entercode.changemailinfo',
                      {
                        linkname: _t('menu.account'),
                        link: '/#' + routes.ACCOUNT_CONTACT,
                      }
                    )
                  : ''
              }
            />
          </Boxed.Section>
          <Boxed.Button onPress={handleContinueButton} disabled={isDisabled}>
            {_t('guides.continue')}
          </Boxed.Button>
          <Boxed.Button
            onPress={handleSendAgainButton}
            type="outline"
            secondary
          >
            {_t('guides.contactinfo.verification.entercode.sendagain')}
          </Boxed.Button>
        </Boxed.FooterButtons>
      </Boxed.Body>
    </>
  );
};

export default VerifyEmail;
