import React from 'react';

import { SxProps, Theme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Stack, { StackProps } from '@mui/material/Stack';

import CloseIcon from '../../../icons/CloseIcon/CloseIcon';

interface Props {
  children?: React.ReactNode;
  onClose?: () => void;
  ariaLabelForClose?: string;
  spacing?: StackProps['spacing'];
  sx?: SxProps<Theme>;
  icon?: React.ReactNode;
}

const BottomSheetHeader: React.FC<Props> = ({
  children,
  onClose,
  sx,
  ariaLabelForClose,
  spacing,
  icon,
}) => (
  <Stack
    sx={[
      {
        paddingBottom: '0.5rem',

        position: 'relative',
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
  >
    <Stack
      paddingTop={onClose != null ? '4rem' : '2rem'}
      paddingX="2rem"
      spacing={spacing}
    >
      {icon != null && (
        <Stack marginBottom={'1rem'} marginTop={'-1rem'}>
          {icon}
        </Stack>
      )}
      {children}
    </Stack>
    {onClose != null && (
      <IconButton
        aria-label={ariaLabelForClose}
        onClick={onClose}
        sx={{
          margin: '0.2rem',
          width: '4rem',
          height: '4rem',

          position: 'absolute',
          top: 0,
          right: 0,
        }}
      >
        <CloseIcon fontSize={1} />
      </IconButton>
    )}
  </Stack>
);

export default BottomSheetHeader;
