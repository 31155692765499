import base from './base';

const AppStoreAppId = '1104213750';

const Config = {
  ...base,

  ConfigName: 'se-test',
  IsDev: true,
  ShowVersionName: true,

  Urls: {
    base: {
      domain: 'https://api.se.doclytest.io',
    },
    web: {
      domain: 'https://se.doclytest.io',
    },
    kiosk: {
      domain: 'https://kiosk.se.doclytest.io',
    },
    guides: {
      domain: 'https://guides.se.doclytest.io',
    },
    inquisitor: {
      domain: 'https://develop-inquisitor.now.sh',
    },
  },

  Locales: ['sv', 'en'],
  DefaultLocale: 'sv',
  ShowLocalePicker: true,

  AppStoreAppId,
  GooglePlayAppId: 'se.mindoktor.android',

  CampaignTopic: '/topics/campaign-webview-dev',

  Tracking: true,

  SegmentIoKeys: {
    android: 'nUIqyuvef7O7LX38A77idthWc5IzzFQN',
    ios: 'nUIqyuvef7O7LX38A77idthWc5IzzFQN',
  },
};

export default Config;
