import { _t } from '@mindoktor/patient-legacy/i18n';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import arrowListItem from '../../../../../images/generated/arrow_listitem@4x.png';
import iconDoctorCert from '../../../../../images/icon/icon_doctor_cert@4x.png';
import iconLabReport from '../../../../../images/icon/icon_lab_report@4x.png';
import colors from '../../../../common/colors';
import { Intro } from '../../../../common/components/typography';
import constants from '../../../../common/constants';
import {
  fetchSharedResults,
  setIds,
} from '../../../../state/referrals/actions';
import { getSharedResults } from '../../../../state/referrals/selectors';
import { routes } from '../../../../state/routing';
import { openRoute } from '../../../../state/routing/actions';
import BackBar from '../../../components/bar/back';
import Screen from '../../../components/screen';
import Img from '@mindoktor/pulse/src/utils/images/Img.web';

const styles = {
  wrapper: {
    flex: 1,
    width: '100vw',
    display: 'flex',
    backgroundColor: colors.grey_300,
    justifyContent: 'center',
  },
  scroll: { flex: 1, backgroundColor: colors.white, maxWidth: 800 },
  batch: {
    backgroundColor: colors.white,
    marginTop: constants.spacing8,
    marginBottom: constants.spacing8,
    paddingTop: constants.spacing16,
    paddingBottom: constants.spacing16,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: constants.spacing16,
    paddingRight: constants.spacing16,
  },
  iconHeader: {
    width: constants.thumbDoctor,
  },
  headerText: {
    fontWeight: 500,
    marginLeft: constants.spacing12,
  },
  file: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomColor: colors.grey_300,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    paddingTop: constants.spacing16,
    paddingBottom: constants.spacing16,
    marginLeft: constants.screenPaddingHorizontal,
    marginRight: constants.screenPaddingHorizontal,
    textDecoration: 'none',
  },
  fileText: {
    flex: 1,
    marginLeft: constants.spacing12,
    marginRight: constants.spacing8,
  },
  iconFile: {
    width: constants.thumbExtraSmall,
  },
  rightArrowImage: {
    width: 10,
    paddingLeft: constants.spacing8,
    filter: 'brightness(6) grayscale(1)',
  },
};

// type Props = {
//   setIds: typeof setIds,
//   fetchSharedResults: typeof fetchSharedResults,
//   referralId: number,
//   caseId: number,
//   sharedBatches: ?(ReferralResultsBatch[]),
// };

const File = ({ caseId, file: { batchId, referralId, fileUrl } }) => (
  <a style={styles.file} href={fileUrl}>
    <Img src={iconDoctorCert} style={styles.iconFile} />
    <Intro style={styles.fileText}>
      {caseId + '–' + referralId + '–' + batchId}
    </Intro>
    <Img style={styles.rightArrowImage} src={arrowListItem} />
  </a>
);

const ReferralResults = ({ sharedBatches, caseId }) => (
  <div style={styles.batch}>
    <div style={styles.header}>
      <Img src={iconLabReport} style={styles.iconHeader} />
      <Intro style={styles.headerText}>
        {_t('referralResults.cardHeader')}
      </Intro>
    </div>
    {sharedBatches.map((item) => (
      <File caseId={caseId} file={item} />
    ))}
  </div>
);

export class ReferralResultsScreen extends Component {
  componentDidMount() {
    const {
      props: { fetchSharedResults, referralId, caseId, setIds },
    } = this;
    setIds(caseId, referralId);
    fetchSharedResults();
  }

  render() {
    const { sharedBatches, caseId, openRoute } = this.props;

    if (!sharedBatches) return null;

    return (
      <Screen
        header={
          <BackBar
            dark
            maxWidth={1000}
            title={_t('referralResults.title')}
            onBack={() => openRoute(routes.CASE_MESSAGES, { id: caseId })}
          />
        }
        body={
          <div style={styles.wrapper}>
            <div style={styles.scroll}>
              <ReferralResults sharedBatches={sharedBatches} caseId={caseId} />
            </div>
          </div>
        }
      />
    );
  }
}

export default connect(
  (state, { caseId, referralId }) => ({
    sharedBatches: getSharedResults(state),
    referralId,
    caseId,
  }),
  {
    setIds,
    fetchSharedResults,
    openRoute,
  }
)(ReferralResultsScreen);
