import { _t } from '@mindoktor/patient-legacy/i18n';
import dayjs from 'dayjs';
import React from 'react';
import colors from '../../../../common/colors';
import { Body1, Title2 } from '../../../../common/components/typography';
import { Treater } from '../../../../state/cases/constants';
import {
  getTreaterByEntrywayId,
  isContactTracingGuide,
} from '../../../../state/guides/guidesUtils';
import { InvitationType } from '../../../../state/profile/types';
import { formatRoute } from '../../../../state/routing';
import { GuideStartOrigin } from '../../../../state/tracking/constants';
import Button from '../../../components/button/button';
import { showModal } from '../../../routing';
import Card from '../../components/card';
import InternalReferralModal from '../../modals/internal_referral_modal';
import RevisitInformationModal from '../../modals/revisit_information_modal';
import { routes } from '@mindoktor/patient-app/routing/constants/webRoutes';

const styles = {
  card: {
    borderTop: `2px solid ${colors.grey_900}`,
  },

  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: 16,
  },

  icon: {
    display: 'block',
    width: 56,
    height: 56,
    marginRight: 16,
  },

  lead: {
    marginBottom: 16,
  },

  howItWorksButton: {
    marginBottom: 8,
  },

  handlingDoctor: {
    marginTop: 24,
  },

  topMargin: {
    marginTop: 16,
  },
};

const getHeaderText = (type) => {
  return type === InvitationType.internalReferral
    ? _t('guides.internalReferral.title')
    : _t('guides.revisitCard.title');
};

const getInfoText = (entrywayId, type, opens, closes) => {
  const dateRevisitOpensShort = dayjs(opens).format('D/M');
  const dateRevisitClosesShort = dayjs(closes).format('D/M');
  const dateRevisitClosesLong = dayjs(closes).format('L');

  switch (type) {
    case InvitationType.patientRevisit:
      return _t('guides.revisitCard.patientRevisit', {
        dateRevisitClosesLong,
      });
    case InvitationType.internalReferral:
      if (isContactTracingGuide(entrywayId)) {
        return _t('guides.invitationCard.internalReferral.contactTracing');
      }
      switch (getTreaterByEntrywayId(entrywayId)) {
        case Treater.physiotherapist:
          return _t('guides.invitationCard.internalReferral.physiotherapist', {
            dateRevisitOpensShort,
            dateRevisitClosesShort,
          });
        case Treater.psychologist:
          return _t('guides.invitationCard.internalReferral.psychologist', {
            dateRevisitOpensShort,
            dateRevisitClosesShort,
          });
        default:
          return _t('guides.invitationCard.internalReferral.generic', {
            dateRevisitOpensShort,
            dateRevisitClosesShort,
          });
      }
    default:
      return _t('guides.revisitCard.caregiverRevisit', {
        dateRevisitOpensShort,
        dateRevisitClosesShort,
      });
  }
};

const getButtonText = (entrywayId, type) => {
  if (type === InvitationType.internalReferral) {
    return isContactTracingGuide(entrywayId)
      ? _t('guides.revisitCard.howItWorks.contactTracing')
      : _t('guides.revisitCard.howItWorks');
  }
  return _t('guides.revisitCard.howItWorks');
};

const openInformationModal = (entrywayId, type) => {
  showModal(
    type === InvitationType.internalReferral
      ? InternalReferralModal
      : RevisitInformationModal,
    { entrywayId }
  );
};

// type Props = {
//   entrywayId: number,
//   childUuid?: string,
//   revisitId?: number,

//   type: string,
//   isOpen: boolean,
//   iconSource: any,
//   title: string,

//   opens: string,
//   closes: string,

//   style?: any,

//   isCaregiverAssigned: boolean,
//   doctorName?: string,
//   doctorSignature?: string,
// };

const InvitationCard = ({
  entrywayId,
  childUuid,
  revisitId,
  type,
  iconSource,
  title,
  opens,
  closes,
  isOpen,
  style,
  doctorName,
  doctorSignature,
  isCaregiverAssigned,
  caseOwnerCanRepresentPatient,
}) => {
  let handlingDoctor = '';

  if (!!doctorName && !!doctorSignature) {
    handlingDoctor = doctorName + ', ' + doctorSignature;
  }

  const isInternalReferral = type === InvitationType.internalReferral;

  return (
    <Card style={{ ...styles.card, ...style }}>
      <div style={styles.header}>
        <img src={iconSource} style={styles.icon} />
        <div>
          <Title2>
            {getHeaderText(type)} - {title}
          </Title2>
        </div>
      </div>

      <Body1 style={styles.lead}>
        <div>{getInfoText(entrywayId, type, opens, closes)}</div>

        {!caseOwnerCanRepresentPatient && (
          <div style={styles.topMargin}>
            {isInternalReferral
              ? _t(
                  'guides.invitationCard.internalReferral.caseOwnerCanNotRepresentPatient'
                )
              : _t('guides.revisitCard.caseOwnerCanNotRepresentPatient')}
          </div>
        )}

        {!!isCaregiverAssigned && !!handlingDoctor && (
          <div style={styles.handlingDoctor}>{handlingDoctor}</div>
        )}
      </Body1>

      <Button
        type="flat"
        color="black"
        style={styles.howItWorksButton}
        onClick={() => openInformationModal(entrywayId, type)}
      >
        {getButtonText(entrywayId, type)}
      </Button>

      {isOpen && caseOwnerCanRepresentPatient && (
        <Button
          color="red"
          href={
            isContactTracingGuide(entrywayId)
              ? `#${formatRoute(routes.GUIDES, {
                  entrywayId,
                  childUuid,
                  revisitId,
                  origin: GuideStartOrigin.INVITATION_CARD,
                })}`
              : `#${formatRoute(routes.GUIDES_QUESTIONS, {
                  entrywayId,
                  childUuid,
                  revisitId,
                  origin: GuideStartOrigin.INVITATION_CARD,
                })}`
          }
        >
          {isContactTracingGuide(entrywayId)
            ? _t('guides.revisitCard.howItWorks.toContactTracing')
            : _t('guides.revisitCard.continue')}
        </Button>
      )}
    </Card>
  );
};

export default InvitationCard;
