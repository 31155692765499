import { EXPERIMENT_REQUEST_SUCCESS } from './types';

export const initialState = {
  experiments: {},
};

export default function experimentsReducer(state = initialState, action) {
  switch (action.type) {
    // Below here are the actions for v2 of the experiment store
    case EXPERIMENT_REQUEST_SUCCESS: {
      const { name, variant } = action.payload || {};

      return {
        ...state,
        experiments: {
          ...state.experiments,
          [name]: {
            ...state.experiments[name],
            variant,
          },
        },
      };
    }

    default:
      return state;
  }
}
