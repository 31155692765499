/**
 * Composes single-argument functions from right to left. The rightmost
 * function can take multiple arguments as it provides the signature for
 * the resulting composite function.
 **/
type Func = (...args: any[]) => any;

const compose = (...funcs: Func[]): Func => {
  return funcs.reduce(
    (a, b) =>
      (...args) =>
        a(b(...args)),
    (arg) => arg
  );
};

export default compose;
