export const SHOW_RENEWAL_MODAL = 'APOTEKET_SHOW_RENEWAL_MODAL';
export const HIDE_RENEWAL_MODAL = 'APOTEKET_HIDE_RENEWAL_MODAL';
export const OPEN_RENEWAL_GUIDE = 'APOTEKET_OPEN_RENEWAL_GUIDE';

export const APOTEKET_BLOOD_PRESSURE_LINK =
  'https://www.apotekhjartat.se/vara-tjanster/blodtryckmatning/blodtrycksmatning-hos-oss/?utm_source=mindoktor&utm_medium=link&utm_campaign=bplocate';

export const APOTEKET_ERECEPT_LINK = 'https://mdlink.se/apotekhjartaterecept';

// Link attow used by APHLink feature
export const APOTEKET_EGENVARD_LINK =
  'https://www.apotekhjartat.se/?utm_source=mindoktor&utm_medium=link&utm_campaign=recept&utm_content=egenvard';
