import React, { Component } from 'react';

import backIconSource from '../../../../images/generated/arrow_listitem@4x.png';
import whiteBackIconSource from '../../../../images/icon/ic_back_white@3x.png';
import colors from '../../../common/colors';
import { Body1, Body3, SubHead } from '../../../common/components/typography';
import { goBack } from '../../routing';
import MediaQuery from '../media_query';
import Img from '@mindoktor/pulse/src/utils/images/Img.web';

const styles = {
  bar: {
    borderBottom: '2px solid #EDEDED',
    backgroundColor: colors.white,
  },

  darkBar: {
    borderBottom: 'none',
    backgroundColor: colors.black,
    color: colors.white,
  },

  wrapper: {
    height: 58,
    paddingLeft: 20,
    paddingRight: 20,
    margin: '0 auto',
  },

  wrapperWide: {
    height: 68,
    paddingLeft: 40,
    paddingRight: 40,
  },

  inner: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
    margin: '0 auto',
  },

  back: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minWidth: 40,
    height: 40,
    marginRight: 10,
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    fontWeight: 500,
  },

  backIcon: {
    display: 'block',
    width: 16 * (18 / 26),
    height: 18,
    padding: 0,
    margin: 0,
    marginRight: 10,
    transform: 'rotate(180deg)',
  },

  backIconDark: {
    transform: 'none',
  },

  middle: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'hidden',
  },

  title: {
    textAlign: 'center',
    whiteSpace: 'nowrap',
    fontWeight: 500,
  },

  subtitle: {
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },

  right: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    width: 40,
    height: 40,
    marginLeft: 10,
    cursor: 'pointer',
  },

  rightIcon: {
    width: 40,
    height: 40,
  },
};

// type BackProps = {
//   text?: string,
//   onClick?: () => any,
//   dark?: boolean,
// };

class Back extends Component {
  render() {
    const { text, onClick, dark } = this.props;

    return (
      <Body1
        style={{
          ...styles.back,
          color: colors.off_black,
        }}
        onPress={onClick}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        <Img
          src={dark ? whiteBackIconSource : backIconSource}
          style={{ ...styles.backIcon, ...(dark && styles.backIconDark) }}
        />
        {text}
      </Body1>
    );
  }
}

// type Props = {
//   title?: string,
//   subtitle?: string,
//   backText?: string,
//   maxWidth?: number,
//   rightIconSource?: any,
//   onBack?: () => any,
//   dark?: boolean,
//   style?: any,
// };

export default class Bar extends Component {
  render() {
    const {
      title,
      subtitle,
      backText,
      maxWidth,
      rightIconSource,
      onBack,
      dark,
      style,
    } = this.props;

    const innerComponent = (
      <div style={styles.inner}>
        <Back text={backText} dark={dark} onClick={onBack || goBack} />

        <div style={styles.middle}>
          <MediaQuery maxWidth={800}>
            <Body1 color="inherit" style={styles.title}>
              {title}
            </Body1>
          </MediaQuery>

          <MediaQuery minWidth={801}>
            <SubHead color="inherit" style={styles.title}>
              {title}
            </SubHead>
          </MediaQuery>

          <Body3 color="inherit" style={styles.subtitle}>
            {subtitle}
          </Body3>
        </div>

        <div style={styles.right}>
          {!!rightIconSource && (
            <Img src={rightIconSource} style={styles.rightIcon} />
          )}
        </div>
      </div>
    );

    return (
      <div style={{ ...styles.bar, ...(dark && styles.darkBar), ...style }}>
        <MediaQuery maxWidth={800}>
          <div
            style={{
              ...styles.wrapper,
              maxWidth: maxWidth ? maxWidth - 40 : undefined,
            }}
          >
            {innerComponent}
          </div>
        </MediaQuery>

        <MediaQuery minWidth={801}>
          <div
            style={{
              ...styles.wrapper,
              ...styles.wrapperWide,
              maxWidth: maxWidth ? maxWidth - 80 : undefined,
            }}
          >
            {innerComponent}
          </div>
        </MediaQuery>
      </div>
    );
  }
}
