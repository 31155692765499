import React from 'react';

import { palette } from '../../styles/colors';
import { rem } from '../../styles/conversions';
import { fonts, FontVariant } from '../../styles/fonts';
import { PulseIconProps } from '../../utils/icons/types';

import ChevronIcon from './ChevronIcon';

const iconAlignmentOffsetRatio = 0.013;
const remValue = rem(1, null);

interface AlignedIconProps extends PulseIconProps {
  variant?: FontVariant;
  isPressed?: boolean;
}

const TextAlignedChevronIcon: React.FC<AlignedIconProps> = ({
  variant,
  isPressed,
  ...iconProps
}) => {
  const { fontSize, color, marginTop, ...remainingIconProps } = iconProps;

  const alignedColor =
    color ?? palette.hero[isPressed === true ? 'light' : 'main'];
  const alignedFontSize = fontSize ?? fonts[variant ?? 'body1'].size / remValue;
  const alignedMarginTop =
    (marginTop ?? 0) + alignedFontSize * iconAlignmentOffsetRatio;

  return (
    <ChevronIcon
      color={alignedColor}
      fontSize={alignedFontSize}
      marginTop={alignedMarginTop}
      {...remainingIconProps}
    />
  );
};

export default TextAlignedChevronIcon;
