import { z } from 'zod';

import { UserStatusStatuses } from '../models/userStatus';

export const UserStatusSchema = z.object({
  status: z.nativeEnum(UserStatusStatuses),
  contactDetailsComplete: z.boolean(),
  tosAccepted: z.boolean(),
  privacyAccepted: z.boolean(),
});
