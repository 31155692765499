import React, { Component } from 'react';

import colors from '../../../common/colors';
import { Intro } from '../../../common/components/typography';

const styles = {
  inner: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
  },

  text: {
    flex: 1,
    textAlign: 'right',
    color: colors.off_black,
    marginBottom: 0,
    paddingRight: 10,
    fontWeight: 500,
  },

  circle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 50,
    height: 50,
    borderRadius: '50%',
  },

  image: {
    display: 'block',
    width: 22 * (52 / 88),
    height: 22,
    marginLeft: 4,
  },
};

// type Props = {
//   text?: string,
//   disabled?: boolean,
//   onClick?: () => any,
//   style?: any,
// };

import continueSource from '../../../../images/temp/continue.png';
import Img from '@mindoktor/pulse/src/utils/images/Img.web';

export default class ContinueButton extends Component {
  state = {
    hover: false,
  };

  onMouseEnter = () => this.setState({ hover: true });

  onMouseLeave = () => this.setState({ hover: false });

  render() {
    const { text, disabled, onClick, style } = this.props;
    const { hover } = this.state;

    return (
      <div
        style={style}
        onClick={!disabled ? onClick : undefined}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        data-test-id="continue-button"
      >
        <div style={styles.inner}>
          {!!text && <Intro style={styles.text}>{text}</Intro>}

          <div
            style={{
              ...styles.circle,
              opacity: disabled ? 0.15 : 1,
              backgroundColor:
                hover && !disabled ? colors.red_400 : colors.red_500,
            }}
          >
            <Img src={continueSource} style={styles.image} />
          </div>
        </div>
      </div>
    );
  }
}
