import isFunction from 'lodash-es/isFunction';

export const createQueue = (rootName) => {
  let delayed = [];

  const push = (functionName, ...args) => {
    let parent;
    let fn = window[rootName];

    functionName.split('.').forEach((part) => {
      parent = fn;
      fn = fn && fn[part];
    });

    if (isFunction(fn)) {
      fn.call(parent, ...args);
    } else {
      delayed.push(() => push(functionName, ...args));
    }
  };

  const flush = () => {
    const copy = [...delayed];

    delayed = [];

    copy.forEach((f) => f());
  };

  return {
    push,
    flush,
  };
};
