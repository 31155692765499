import React, { Component } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { getFadeDuration } from '../../utils/device';

const duration = getFadeDuration();

const style = `
  .fade-enter {
    opacity: 0.01;
  }

  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity ${duration}ms ease-in;
  }

  .fade-exit {
    opacity: 1;
  }

  .fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: opacity ${duration}ms ease-in;
  }
`;

export default class Router extends Component {
  state = {
    children: this.props.children,
    transitionKey: this.props.transitionKey,
    skipTransition: this.props.skipTransition,
  };

  componentDidUpdate(prevProps) {
    const { transitionKey: previousTransitionKey } = prevProps;
    const { transitionKey, afterTransition, children, skipTransition } =
      this.props;

    if (transitionKey !== previousTransitionKey) {
      this.setState({ children: undefined });

      if (skipTransition) {
        this.setState({ children, transitionKey, skipTransition });
        afterTransition && afterTransition();
        return;
      }

      this.timer = setTimeout(() => {
        this.setState({ children, transitionKey });

        afterTransition && afterTransition();
      }, duration + 50);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  render() {
    const { children, transitionKey, skipTransition } = this.state;

    if (skipTransition) {
      return children || null;
    }

    return (
      <div>
        <TransitionGroup>
          {children ? (
            <CSSTransition
              key={transitionKey}
              classNames="fade"
              timeout={{ enter: duration, exit: duration }}
              component="div"
            >
              <div>{children}</div>
            </CSSTransition>
          ) : null}
        </TransitionGroup>

        <style>{style}</style>
      </div>
    );
  }
}
