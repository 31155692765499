import { _t } from '@mindoktor/patient-legacy/i18n';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  nextQuestion,
  previousQuestion,
} from '../../../state/formulary/actions';
import { getFormularyState } from '../../../state/formulary/selectors';
import { saveHealthProfile } from '../../../state/healthprofile/actions';
import { getPatientNameAndBirthDate } from '../../../state/profile/selectors';
import BackBar from '../../components/bar/back';
import FadeTransition from '../../components/transition/fade';
import FormularyAnswer from '../../formulary/answer';
import FormularyFooter from '../../formulary/footer';
import FormularyQuestion from '../../formulary/question';
import GuideQuestionView from '../../guides/components/question_view';
import { goHome } from '../../routing';
import SummaryContent from '../summary/content';
import SummaryFooter from '../summary/footer';
import healthProfileLoader from '../utils/loader';

// type Props = {
//   healthProfileKey?: string,
//   childUuid?: string,

//   childName?: string,

//   hasErrors?: boolean,

//   previousNode?: Question,
//   currentNode?: Question,
//   nextNode?: Question,

//   nextQuestion: typeof nextQuestion,
//   previousQuestion: typeof previousQuestion,

//   saveHealthProfile: typeof saveHealthProfile,
// };

// type State = {
//   step: 'questions' | 'summary',
// };

// TODO: is this used any more?
export class QuestionsScreen extends Component {
  state = {
    step: this.props.hasErrors ? 'questions' : 'summary',
  };

  next = () => {
    const {
      healthProfileKey,
      childUuid,
      hasErrors,
      nextNode,
      nextQuestion,
      saveHealthProfile,
    } = this.props;

    const { step } = this.state;

    if (step === 'summary') {
      goHome();
    } else if (nextNode) {
      nextQuestion({ formularyKey: healthProfileKey });
    } else {
      if (!hasErrors) {
        saveHealthProfile({ childUuid });
      }

      this.setState({ step: 'summary' });
    }
  };

  previous = () => {
    const { healthProfileKey, previousQuestion } = this.props;

    previousQuestion({ formularyKey: healthProfileKey });
  };

  getQuestionPaneProps = (currentNode, previousNode) => {
    const { healthProfileKey } = this.props;

    return currentNode
      ? {
          key: currentNode.index,

          previous: previousNode ? (
            <FormularyAnswer
              formularyKey={healthProfileKey}
              node={previousNode}
              dimLabel={true}
              onClick={this.previous}
            />
          ) : undefined,
          content: (
            <FormularyQuestion
              formularyKey={healthProfileKey}
              node={currentNode}
              next={this.next}
            />
          ),
          footer: (
            <FormularyFooter
              formularyKey={healthProfileKey}
              node={currentNode}
              next={this.next}
            />
          ),
        }
      : undefined;
  };

  getSummaryPaneProps = () => {
    const { childUuid } = this.props;

    return {
      key: 'summary',

      previous: undefined,
      content: <SummaryContent childUuid={childUuid} showTitle={false} />,
      footer: <SummaryFooter childUuid={childUuid} next={this.next} />,
    };
  };

  render() {
    const { previousNode, currentNode, childName } = this.props;

    const { step } = this.state;

    let current;

    switch (step) {
      case 'questions':
        current = this.getQuestionPaneProps(currentNode, previousNode);

        break;

      case 'summary':
        current = this.getSummaryPaneProps();

        break;
    }

    const { key, previous, content, footer } = current || {};

    return (
      <FadeTransition transitionKey={key} afterTransition={window.scroll}>
        <GuideQuestionView
          header={
            <BackBar
              title={_t('guides.healthProfileSummary')}
              subtitle={childName}
              onBack={goHome}
            />
          }
          previous={previous}
          content={content}
          footer={footer}
        />
      </FadeTransition>
    );
  }
}

export default healthProfileLoader(
  connect(
    (state, props) => {
      const { healthProfileKey, childUuid } = props;

      const {
        errors,

        previous: previousNode,
        current: currentNode,
        next: nextNode,
      } = getFormularyState(state, healthProfileKey) || {};

      const { fullName: childName } = childUuid
        ? getPatientNameAndBirthDate(state, childUuid)
        : {};

      return {
        childName,

        hasErrors: !!errors && !!Object.keys(errors).length,

        previousNode,
        currentNode,
        nextNode,
      };
    },
    {
      nextQuestion,
      previousQuestion,

      saveHealthProfile,
    }
  )(QuestionsScreen)
);
