// Pulse colors
// See more at https://www.figma.com/file/4wwZU7RnupTgWvmKndcA9T/Pulse---Foundation?node-id=624%3A239

import aphColors from '@mindoktor/patient-app/apotekhjartat/aphColors';

export const colors = {
  red: {
    /** Item disabled */
    100: '#FFF2F2',

    /** Patient background cards */
    200: '#FFCECB',

    /** Hover color for links, primary CTA’s */
    400: '#BF0B0E',

    /** Logo, links, primary CTA’s */
    500: '#E52427',

    /** Pictogram highlight, Links on “black” background */
    V500: '#FF383B',
  } as const,

  orange: {
    /** Error, warning */
    500: '#F77700',
  } as const,

  yellow: {
    /** Error, warning */
    500: '#FFD700',
    /** Error, warning, light - Input backgrounds */
    L500: '#FFF7CC',
  } as const,

  green: {
    /** Success, start video */
    500: '#1D9E6F',
  } as const,

  blue: {
    /** Coach marks */
    500: '#0E73C0',
  } as const,

  gray: {
    /** Background */
    100: '#F2F2F3',

    /** Disabled */
    200: '#E1E1E2',

    /** Avatar, background */
    300: '#D0D0D2',

    /** Textfield hints */
    400: '#BDBDBF',

    /** */
    500: '#A1A1A6',

    /** Textfield labels */
    600: '#8A8A8F',

    /** Secondary text */
    700: '#7D7D82',

    /** Button active, hover */
    V900: '#21566D',

    /** Button, Notifications */
    900: '#283D46',

    /** Box shadow color */
    S900: 'rgba(0,0,0,0.15)',
  } as const,

  /**
   * Primary text, background.
   * A.K.A. Docly Black 900
   **/
  black: '#00151E' as const,
  /** Background */
  white: '#ffffff' as const,

  /** Skandia specific color. Used for e.g. banner */
  skandia_color: '#1a8166' as const,

  transparent: 'rgba(0,0,0,1)' as const,
} as const;

/**
 *  Based upon (but not limited to) button colors definitions.
 *  https://www.figma.com/file/d3cTtObHACyTcIdjyBxZmbpG/Component-%E2%80%93-Button?node-id=4200%3A669
 */
export const palette = {
  hero: {
    main: colors.red[500],
    light: colors.red.V500,
    dark: colors.red[400],
    disabled: colors.gray[300],
    contrastText: colors.white,
  },
  primary: {
    main: colors.gray[900],
    light: colors.gray.V900,
    dark: colors.black,
    disabled: colors.gray[300],
    contrastText: colors.white,
  },
  secondary: {
    main: colors.white,
    light: colors.white,
    dark: colors.gray[200],
    disabled: colors.white,
    contrastText: colors.black,
  },
  transparent: {
    main: colors.transparent,
  },
  success: {
    main: colors.green[500],
  },
  info: {
    main: colors.blue[500],
  },
  warning: {
    main: colors.yellow[500],
  },
  error: {
    main: colors.orange[500],
  },
  aph: {
    main: aphColors.linkColor,
    light: aphColors.bright,
    dark: aphColors.darkGreen,
    disabled: colors.gray[300],
    contrastText: colors.white,
  },
};

interface PaletteColorDefinition {
  main: string;
}

export interface PaletteColorDefinitionComplete extends PaletteColorDefinition {
  light: string;
  dark: string;
  contrastText: string;
  disabled: string;
}

type PaletteType = typeof palette;
export type PaletteColor = keyof PaletteType;

/** Color with main, light, dark, contrastText and disabled. */
export type PaletteCompleteColor = {
  [k in keyof PaletteType]: PaletteType[k] extends PaletteColorDefinitionComplete
    ? k
    : never;
}[keyof PaletteType];
