import { Route } from '../../../web';
import { getRedirectRoute } from '../../getRedirectRoute/web';

export const getRouteWithRedirect = <TRoute extends string | Route>(
  route: TRoute
): TRoute => {
  const redirect = getRedirectRoute();
  if (redirect == null) {
    return route;
  }

  // If the route already has query params, just append it
  const symbol = route.includes('?') ? '&' : '?';
  return `${route}${symbol}state=${encodeURIComponent(redirect)}` as TRoute;
};
