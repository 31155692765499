/**
 * Base routes.
 *
 * Check also the web and native routes for a complete overview of the
 * available routes.
 */
const routes = {
  CASES: '/cases',
  CONVERSATION: '/cases/:caseId/conversation/:conversationId',
  JOURNAL_NOTE: '/cases/:caseId/journal/note',
  CASE_ANSWERS: '/cases/:caseId/answers',
  CASE_REFERRALS_LOCATION: '/cases/:caseId/referrals/:referralId/location',

  LOGIN: '/auth/login',
  TOS_PRIVACY: '/user/tosprivacy',
  REGISTER: '/user/register',

  DELETE_ACCOUNT: '/user/delete-account',
  ACCOUNT_CONTACT: '/account/contact',
  ACCOUNT_CONTACT_VERIFY_EMAIL: '/account/contact/verify',
  ACCOUNT_CONTACT_VERIFY_EMAIL_SUCCESS: '/account/contact/verify/success',
  ACCOUNT_FORGET: '/account/forget',
  ACCOUNT_FORGET_SUCCESS: '/account/forget/success',
  DEVELOPER_TOOLS: '/developer',

  GUIDES: '/guides/:entrywayId',
  GUIDES_INTRO: '/guides/:entrywayId/intro',
  GUIDES_PRESTART: '/guides/:entrywayId/prestart',
  GUIDES_QUESTIONS: '/guides/:entrywayId/questions',
  GUIDES_ABORT: '/guides/:entrywayId/abort',
  GUIDES_BLOOD_PRESSURE: '/guides/:entrywayId/bloodpressure',
  GUIDES_DYNAMIC_CODE: '/guides/:entrywayId/dynamiccode',
  GUIDES_QUESTIONS_EDIT: '/guides/:entrywayId/questions/edit',
  GUIDES_BANNER: '/guides/:entrywayId/banner',
} as const;

export default routes;
