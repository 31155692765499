import React from 'react';

import { Box, IconButton, Stack } from '@mui/material';

import { colors } from '@mindoktor/pulse/src/styles/colors';
import { rem } from '@mindoktor/pulse/src/styles/conversions';

import { useTranslation } from '../../../../localization/hooks/useTranslation';
import CloseIcon from '../CloseIcon/CloseIcon';

import { MediaPreviewProps } from './types';

const MediaPreview: React.FC<MediaPreviewProps> = ({
  mediaAsset,
  onRemove,
  isDisabled = false,
}) => {
  const t = useTranslation('messaging');

  return (
    <Stack
      position="relative"
      width={rem(5.75)}
      height={rem(5.75)}
      direction="row"
      alignItems="flex-end"
    >
      <Box
        style={{
          backgroundColor: colors.black,
          width: rem(5),
          height: rem(5),
        }}
        borderRadius={rem(0.25)}
      >
        <img
          src={mediaAsset.uri}
          style={{
            objectFit: 'cover',
            width: rem(5),
            height: rem(5),
            borderRadius: rem(0.25),
            opacity: isDisabled ? 0.8 : 1,
          }}
          alt={t('mediaPicker.previewPhoto')}
        />
      </Box>
      <Box
        position="absolute"
        right={0}
        top={0}
        sx={{
          backgroundColor: colors.white,
          borderRadius: rem(10),
          padding: rem(0.15),
        }}
      >
        <IconButton
          onClick={onRemove}
          disabled={isDisabled}
          color="hero"
          sx={{
            width: rem(1.25),
            height: rem(1.25),
          }}
        >
          <CloseIcon fontSize={0.625} />
        </IconButton>
      </Box>
    </Stack>
  );
};

export default MediaPreview;
