// type Options = {
//   type: string,
//   regex: Regex,
//   flagGroup: number,
//   innardsGroup: number,
//   start: string,
//   end?: string,
//   defaultFlag?: string,
//   before?: string,
// };

/**
 * Create a new block type plugin for react-markdown in an easier
 * fashion
 *
 * Utilises the following parser:
 *     https://github.com/remarkjs/remark/tree/master/packages/remark-parse
 *
 * Note: Block means an element that's standalone, create an inline plugin
 *       if you want it to be within the text
 */
export default class BlockPlugin {
  subvalue = '';
  flag = '';
  innards = '';

  constructor(options) {
    // Config
    this.options = options;
  }

  /**
   * Tokenize the value passed in and return a modal node if found
   *
   * @param {Function} eat
   * @param {string} value
   * @param {boolean} silent
   */
  /* eslint consistent-return: 0 */
  tokenizer(eat, value, silent) {
    const { start, regex, flagGroup, innardsGroup } = this.options;

    // Find the block
    let block = regex.exec(value);

    // Only do something if the block is found
    if (block) {
      // If silent is true, only test if the block is found
      if (silent) {
        return true;
      }

      // Set options to return as part of the node
      this.flag = block[flagGroup] || '';
      this.innards = block[innardsGroup];

      // Get the subvalue string
      this.subvalue = value.slice(0, block.index + block[0].length);

      // If it doesn't start with the start characters then return so
      // the rest of the string gets parsed!
      if (!this.subvalue.startsWith(start)) {
        return;
      }

      return this.getNode(eat);
    }
  }

  /**
   * Return the node from the subvalue text for the block
   *
   * @param {Function} eat
   */
  getNode(eat) {
    const { type, defaultFlag } = this.options;

    // Eat the value including the modal
    let eaten = eat(this.subvalue);

    // Create the node element to render via the renderer
    let node = eaten({
      type,
      flag: this.flag || defaultFlag || '',
      innards: this.innards,
    });

    // Return the node!
    return node;
  }

  /**
   * Attach this tokenizer to the markdown parser
   *
   * @param {Function} parser
   */
  attachTo(parser) {
    const { type, before } = this.options;

    // Some defaults - are they needed?
    this.tokenizer.onlyAtStart = false;
    this.tokenizer.notInBlock = false;
    this.tokenizer.notInList = false;
    this.tokenizer.notInLink = false;

    // Add the modal block type to the parser
    parser.blockTokenizers.modal = this.tokenizer.bind(this);
    parser.blockMethods.splice(
      parser.blockMethods.indexOf(before || 'paragraph'),
      0,
      type
    );
  }
}
