import { _t } from '@mindoktor/patient-legacy/i18n';

import { Browsers, Platforms } from '../../web/utils/device';
import { SERVER_MAINTENANCE_RESPONSE } from '../api/actions';
import { APP_ONLINE, RECEIVE_SYSTEM_MESSAGE } from '../app/actions';
import { MESSAGE_POST_ERROR } from '../cases/actionTypes';
import { FILES_POST_ERROR } from '../files/types';
import { FORMULARY_POST_ERROR } from '../formulary/types';
import { FREE_PASS_ERROR } from '../freepass/types';
import { isFreePassValidationError } from '../freepass/utils';
import {
  GUIDE_NATIVE_START_ERROR,
  GUIDES_LOAD_ERROR,
} from '../guides/actionTypes';
import { dismissNotification, showNotification } from './actions';
import { getIdByMessageType, isMinDoktorCustomer } from './selectors';
import {
  PROFILE_CONNECTIONS_ERROR,
  PROFILE_SAVE_ERROR,
  PROFILE_UPDATE_FROM_SPAR_ERROR,
  PROFILE_UPDATE_FROM_SPAR_RATE_LIMITED,
  PROFILE_UPDATE_FROM_SPAR_SUCCESS,
  PROFILE_SAVE_SUCCESS,
} from '../profile/actions';
import {
  REFERRAL_LAB_CHOOSE_ERROR,
  REFERRAL_LAB_DONE_ERROR,
  REFERRAL_SELFTEST_CONFIRM_ERROR,
  REFERRALLABS_REQUEST_ERROR,
} from '../referrals/actionTypes';
import {
  BROWSER_VIDEO_WARNING,
  BROWSER_VIDEO_WARNING_DISMISSED,
} from './types';

import { showSnackbar } from '../../state/snackbar/actions';
import { SnackbarType } from '../../state/snackbar/types';

/**
 * Notifications middleware is injected into the Redux state machine and every action passes through
 * it before hitting the reducers.
 *
 * You can listen for specific actions to perform notification actions based on them.
 *
 * No matter the actions taken, notifications middleware should always return `next(action)`
 */
export default ({ getState, dispatch }) => {
  return (next) => (action) => {
    switch (action.type) {
      // Scheduled Maintenance notifications.
      case RECEIVE_SYSTEM_MESSAGE: {
        const message = action.payload && action.payload.systemMessage;
        dispatch(
          showNotification({ message, type: 'warning', shouldTimeout: false })
        );
        return next(action);
      }

      // Network on/off
      case APP_ONLINE: {
        const online = action.payload && action.payload.online;
        const message = 'errorMessage.offline';
        const type = 'error';
        const state = getState();

        // Warn about offline
        if (!online) {
          dispatch(showNotification({ message, type, shouldTimeout: false }));
        } else {
          // Remove warning if still present when going online
          const id = getIdByMessageType(state, { message, type });
          if (id !== undefined) {
            dispatch(dismissNotification(id));
          }
        }
        return next(action);
      }

      // API call getting an 503 response (under maintenance) will trigger this notification
      case SERVER_MAINTENANCE_RESPONSE: {
        dispatch(
          showNotification({
            message: 'errorMessage.maintenance',
            type: 'error',
          })
        );
        return next(action);
      }

      case FREE_PASS_ERROR: {
        if (
          action.payload &&
          action.payload.errorMessage &&
          !isFreePassValidationError(action.payload.errorMessage)
        ) {
          // If something else that a validation error happens when saving
          // a freepass, we show the usual anonymous tech error message.
          dispatch(
            showNotification({
              message: 'errorMessage.technicalError.contactSupport',
              type: 'error',
            })
          );
        }
        return next(action);
      }

      case GUIDE_NATIVE_START_ERROR: {
        if (
          action.payload &&
          action.payload.error &&
          action.payload.error === 'childGuideAge'
        ) {
          dispatch(
            showNotification({
              message: 'errorMessage.childGuideAge',
              type: 'error',
            })
          );
        } else {
          dispatch(
            showNotification({
              message: 'errorMessage.technicalError',
              type: 'error',
            })
          );
        }
        return next(action);
      }

      case PROFILE_CONNECTIONS_ERROR: {
        const { message } = action.payload || {};

        dispatch(
          showNotification({
            message:
              {
                LNGErrUnder18AlreadyRegisteredToYou:
                  'errorMessage.addChildAlreadyRegisteredToYou',
                LNGErrUnder18AlreadyRegisteredToOther:
                  'errorMessage.addChildAlreadyRegisteredToOther',
                LNGErrUnder18SparNameMismatch:
                  'errorMessage.addChildSparNameMismatch',
                LNGErrUnder18SparIDPMismatch:
                  'errorMessage.addChildSparIDPMismatch',
                LNGErrCannotAddChildContactSupport:
                  'errorMessage.cannotAddChildContactSupport',
                LNGErrNotLegalGuardianOfChild:
                  'errorMessage.notLegalGuardianOfChild',
                LNGNotFound: 'errorMessage.ssnNotFoundInOfficialRecords',
              }[message] || 'errorMessage.addChildDefault',
            type: 'error',
            // These texts can be lengthy, so we show them a bit longer than usual.
            timeout: 20000,
          })
        );

        return next(action);
      }

      case GUIDES_LOAD_ERROR: {
        dispatch(
          showNotification({
            message: 'errorMessage.guideLoadError',
            type: 'error',
          })
        );

        return next(action);
      }

      case FORMULARY_POST_ERROR: {
        const { message } = action.payload || {};

        dispatch(
          showNotification({
            message:
              {
                LNGErrParallelCases: 'errorMessage.parallelCases',
                LNGErrNotOfficeHours: 'errorMessage.notOfficeHours',
                LNGErrNotLegalGuardianOfChild:
                  'errorMessage.notLegalGuardianOfChild',
                LNGErrUnder18SparIDPMismatch:
                  'errorMessage.addChildSparIDPMismatch',
              }[message] || 'errorMessage.formularyError',
            type: 'error',
            // These texts can be lengthy, so we show them a bit longer than usual.
            timeout: 20000,
          })
        );

        return next(action);
      }

      case FILES_POST_ERROR: {
        const { message } = action.payload || {};

        dispatch(
          showNotification({
            message:
              {
                LNGCollisionSSN: 'errorMessage.collisionSSN',
              }[message] || 'errorMessage.postFile',
            type: 'error',
          })
        );

        return next(action);
      }

      case MESSAGE_POST_ERROR: {
        const { message } = action.payload || {};

        dispatch(
          showNotification({
            message:
              message === 'LNGErrCaseClosed'
                ? 'errorMessage.caseClosed'
                : 'errorMessage.technicalError',
            type: 'error',
          })
        );

        return next(action);
      }

      case PROFILE_UPDATE_FROM_SPAR_SUCCESS: {
        dispatch(
          showNotification({
            message: 'sparNameUpdate.success',
            type: 'info',
          })
        );

        return next(action);
      }
      case PROFILE_UPDATE_FROM_SPAR_ERROR: {
        dispatch(
          showNotification({
            message: 'sparNameUpdate.error.generic',
            type: 'error',
          })
        );

        return next(action);
      }
      case PROFILE_UPDATE_FROM_SPAR_RATE_LIMITED: {
        dispatch(
          showNotification({
            message: 'sparNameUpdate.error.rateLimited',
            type: 'error',
          })
        );

        return next(action);
      }
      // General technical error (typically failed api calls, could be more detailed maybe)
      case REFERRALLABS_REQUEST_ERROR:
      case REFERRAL_LAB_CHOOSE_ERROR:
      case REFERRAL_LAB_DONE_ERROR:
      case REFERRAL_SELFTEST_CONFIRM_ERROR: {
        dispatch(
          showNotification({
            message: 'errorMessage.technicalError',
            type: 'error',
          })
        );

        return next(action);
      }

      // Using a browser that is not officially supported for video calls.
      case BROWSER_VIDEO_WARNING: {
        const state = getState();
        const { platform, browser, iosVersion, isBrowserSupported, caseId } =
          action.payload || {};
        const shouldUseApp = isMinDoktorCustomer(state, caseId);
        if (!state.notifications.videoWarningDismissed && isBrowserSupported) {
          let message;
          switch (platform) {
            case Platforms.DESKTOP:
              if (browser !== Browsers.CHROME) {
                message = _t('device.browserVideoNotSupported', {
                  linkToBrowser:
                    '[Google Chrome](https://www.google.com/chrome/)',
                });
              }
              break;
            case Platforms.ANDROID:
              if (shouldUseApp) {
                message = _t('device.mobileBrowserUseApp', {
                  hrefToApp:
                    'https://play.google.com/store/apps/details?id=se.mindoktor.android',
                });
              } else if (browser !== Browsers.FIREFOX) {
                // Advise Firefox
                message = _t('device.browserVideoNotSupported', {
                  linkToBrowser:
                    '[Mozilla Firefox](https://play.google.com/store/apps/details?id=org.mozilla.firefox)',
                });
              }
              break;
            case Platforms.IOS:
              if (shouldUseApp) {
                message = _t('device.mobileBrowserUseApp', {
                  hrefToApp:
                    'https://itunes.apple.com/se/app/min-doktor-l%C3%A4kare-i-mobilen/id1104213750?mt=8',
                });
              } else if (iosVersion < 11) {
                // Advise computer
                message = _t('device.useComputer');
              } else if (browser !== Browsers.SAFARI) {
                // Advise Safari
                message = _t('device.browserVideoNotSupported', {
                  linkToBrowser: 'Safari',
                });
              }
              break;
          }
          if (message) {
            dispatch(
              showNotification({
                message,
                type: 'error',
                shouldTimeout: false,
                onClose: () => {
                  dispatch({ type: BROWSER_VIDEO_WARNING_DISMISSED });
                },
              })
            );
          }
        }
        return next(action);
      }

      case PROFILE_SAVE_SUCCESS: {
        const { profile } = action.payload || {};
        const { profile: currentProfile } = getState();
        // Success message(s) based on what has changed.
        if (profile.phone !== currentProfile.phone) {
          dispatch(
            showSnackbar({
              type: SnackbarType.SUCCESS,
              text: _t('contactinfo.update.phone.saved'),
            })
          );
        }
        if (profile.email !== currentProfile.email) {
          dispatch(
            showSnackbar({
              type: SnackbarType.SUCCESS,
              text: _t('contactinfo.verification.emailupdate.saved'),
            })
          );
        }
        return next(action);
      }

      case PROFILE_SAVE_ERROR: {
        const { message } = action.payload || {};
        const messages = {
          LNGPhoneCollision: 'errorMessage.phoneCollision',
          LNGInvalidPhoneFormat: 'errorMessage.invalidPhoneFormat',
          LNGInvalidPostalCodeFormat: 'errorMessage.invalidPostalCode',
          LNGInvalidPostalCodeRegion: 'errorMessage.invalidPostalCodeRegion',
          LNGEmailCollision: 'errorMessage.emailCollision',
        };
        const msg = messages[message]
          ? _t(messages[message])
          : _t('errorMessage.accountSettings');

        dispatch(
          showSnackbar({
            type: SnackbarType.WARNING,
            text: msg,
          })
        );

        return next(action);
      }
    }
    // Makes sure we always return next(action)
    return next(action);
  };
};
