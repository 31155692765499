import React, { useRef, useState } from 'react';

import { Tab, Tabs, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';

import ScrollViewWithScrollDownButton from '@mindoktor/pulse/src/components/ScrollViewWithScrollDownButton/web';
import { colors } from '@mindoktor/pulse/src/styles/colors';

import { useTranslation } from '@mindoktor/patient-app/localization/hooks/useTranslation';

import { useAuthConfigApi } from '../../../hooks/useAuthConfigApi';
import { LoginMethod, LoginMethods } from '../../../types';
import BankIDLogin from '../../BankIDLogin/web/BankIDLogin';
import EmailLogin from '../../EmailLogin/web';

const LoginCardContentDesktop = () => {
  const [loginMethod, setLoginMethod] = useState<LoginMethod>(
    LoginMethods.bankid
  );

  const t = useTranslation('auth');
  const contentRef = useRef(null);
  const authConfig = useAuthConfigApi();
  const isEmailAuthEnabled = authConfig.data?.isEmailEnabled ?? false;

  return (
    <Stack
      position="relative"
      spacing="1.5rem"
      minWidth="100%"
      minHeight="100%"
    >
      <Typography variant="h2">{t(`common.login`)}</Typography>
      <Typography variant="subtitle1" sx={{ color: colors.gray[700] }}>
        {t(`${loginMethod}.usage`)}
      </Typography>
      <ScrollViewWithScrollDownButton
        contentRef={contentRef}
        padding="1rem"
        borderRadius="5px"
        flexGrow={1}
        sx={{ backgroundColor: colors.gray[100] }}
      >
        <Stack ref={contentRef} flexGrow={1} width="100%">
          {isEmailAuthEnabled ? (
            <>
              <Tabs
                value={loginMethod}
                onChange={(_, newTab) => setLoginMethod(newTab)}
                aria-label="login tabs"
                indicatorColor="primary"
                textColor="primary"
                centered
                sx={{ paddingBottom: '2rem' }}
              >
                <Tab
                  label={t('common.bankid')}
                  value={LoginMethods.bankid}
                  sx={{ textTransform: 'none' }}
                />
                <Tab
                  label={t('common.email')}
                  value={LoginMethods.email}
                  sx={{ textTransform: 'none' }}
                />
              </Tabs>
              {loginMethod === LoginMethods.bankid && <BankIDLogin />}
              {loginMethod === LoginMethods.email && <EmailLogin />}
            </>
          ) : (
            <Stack paddingTop={'1rem'} flex={1}>
              <BankIDLogin />
            </Stack>
          )}
        </Stack>
      </ScrollViewWithScrollDownButton>
    </Stack>
  );
};

export default LoginCardContentDesktop;
