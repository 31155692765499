import { useEffect } from 'react';

import {
  DataTrackingEventData,
  DataTrackingEvents,
} from '../api/models/dataTracking';

import { useDataTracking } from './useDataTracking';

export const useTrackPageView = (data: DataTrackingEventData = {}) => {
  const dataTracking = useDataTracking();
  useEffect(() => {
    dataTracking.track(DataTrackingEvents.PageViewed, data);
  }, []);
};
