import dayjs from 'dayjs';

import { Sex } from '../profile/profileUtils';
import { getDomainPublicInfo, getDomains } from '../api/selectors';
import { contactInformation, getChild } from '../profile/selectors';
import { getInquisitor } from './inquisitor';

export const getQuestion = (state, id) => {
  const inquisitor = getInquisitor(id);

  return inquisitor && inquisitor.getQuestion();
};

export const getResult = (state, id) => {
  const inquisitor = getInquisitor(id);

  return inquisitor && inquisitor.getResult();
};

export const getValue = (state, id) => {
  const { values = {} } = getDraft(state, id) || {};
  const question = getQuestion(state, id);

  return question ? values[question.ref] : undefined;
};

export const isFirst = (state, id) => {
  const { visited = [] } = getDraft(state, id) || {};

  return !visited.length;
};

export const getDraft = ({ inquisitions }, id) =>
  inquisitions.draftById && inquisitions.draftById[id];

export const getPatient = ({ api, profile }) => {
  const domain = getDomainPublicInfo({ api });
  const patient = contactInformation({ profile });

  if (!patient) return undefined;

  const { firstName, lastName, isFemale, birthDate } = patient;

  if (!birthDate) return undefined;

  const date = dayjs(birthDate.slice(0, 8));
  const age = dayjs().diff(date, 'year');

  return {
    firstName,
    lastName,
    birthDate,
    gender: isFemale ? 'female' : 'male',
    age,
    domain: domain ? domain.slug : '',
  };
};

export const getChildPatient = ({ profile, api }, childUuid) => {
  const child = getChild({ profile }, childUuid);
  const domains = getDomains({ api });

  if (!child || !domains) {
    return undefined;
  }

  const {
    firstName,
    lastName,
    connectedSex,
    birthDate,
    connectedDomainId: domainId,
  } = child;

  const isFemale = connectedSex === Sex.female;

  const date = dayjs(birthDate);
  const age = dayjs().diff(date, 'year');
  const domain = domains.find((d) => d.id === domainId);

  return {
    firstName,
    lastName,
    birthDate: dayjs(birthDate).format('YYYYMMDD'),
    gender: isFemale ? 'female' : 'male',
    age,
    domain: domain ? domain.slug : '',
  };
};
