export const SET_DEEP_LINK = 'SET_DEEP_LINK';

export const setDeepLink =
  (link?: { name: string; params: any }) => async (dispatch: any) =>
    dispatch({
      type: SET_DEEP_LINK,
      payload: {
        link: link || {},
      },
    });
