import '../../common/dateConfig';

import dayjs from 'dayjs';

import type { SupportTicket, SupportTicketStatus } from './types';

/**
 * Checks if the status is any that we can consider as "closed".
 */
export const isTicketClosed = (ticketStatus: SupportTicketStatus) =>
  ['solved', 'closed'].includes(ticketStatus);

/**
 * Returns the latest ticket timestamp or an empty string if none is found.
 */
export const getLatestTimestampFromSupportTicketList = (
  supportTicketList: SupportTicket[]
) =>
  supportTicketList.reduce((accTimestamp, { updated: ticketTimestamp }) => {
    if (accTimestamp === '') {
      return ticketTimestamp;
    }

    if (dayjs(ticketTimestamp).utc().isAfter(dayjs(accTimestamp))) {
      return ticketTimestamp;
    }

    return accTimestamp;
  }, '');
