import React, { Component } from 'react';
import Textarea from 'react-textarea-autosize';

// import throttle from 'lodash-es/throttle';

import colors from '../../../common/colors';

import { fonts } from '../../../common/components/typography';

const styles = {
  input: {
    display: 'block',
    width: '100%',
    fontFamily: fonts.body,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '24px',
    padding: 0,
    paddingTop: 6,
    paddingBottom: 6,
    margin: 0,
    border: 'none',
    background: 'none',
    outline: 'none',
    resize: 'none',
    borderBottom: `1px solid ${colors.off_black}`,
    borderRadius: 0,
  },

  focused: {
    paddingBottom: 5,
    borderBottom: `2px solid ${colors.off_black}`,
  },

  disabled: {
    borderBottom: `1px solid ${colors.grey_300}`,
    color: colors.grey_400,
  },

  error: {
    paddingBottom: 5,
    backgroundColor: `${colors.yellow_500}50`,
    borderBottomColor: colors.yellow_500,
    borderWidth: 2,
  },
};

// type Props = {
//   type?: 'text' | 'number' | 'date',
//   autoComplete?: string,

//   initialValue?: string | number,
//   value?: string | number,

//   placeholder?: string,
//   maxLength?: number,
//   autoFocus?: boolean,
//   multiline?: boolean,
//   disabled?: boolean,
//   error?: boolean,

//   onChange?: Function,
//   onFocus?: Function,
//   onBlur?: Function,

//   style?: any,
// };

// type State = {
//   initialValue?: string,
//   focused: boolean,
// };

export default class TextInput extends Component {
  state = {
    initialValue: this.props.initialValue,
    focused: false,
  };

  focus = () => {
    const { inputComponent } = this;
    if (!inputComponent || !inputComponent.focus) return;

    if (inputComponent.getAttribute) {
      const savedTabIndex = inputComponent.getAttribute('tabindex');
      inputComponent.setAttribute('tabindex', '-1');
      inputComponent.focus();
      inputComponent.setAttribute('tabindex', savedTabIndex);
    } else {
      inputComponent.focus();
    }
  };

  blur = () => {
    const { inputComponent } = this;
    if (!inputComponent || !inputComponent.blur) return;
    inputComponent.blur();
  };

  onFocus = (e) => {
    const { onFocus } = this.props;

    this.setState({ focused: true });

    onFocus && onFocus(e);
  };

  onBlur = (e) => {
    const { onBlur } = this.props;

    this.setState({ focused: false });

    onBlur && onBlur(e);
  };

  onChangeText = ({ target: { value } }) => {
    const { onChange } = this.props;

    onChange && onChange(value);
  };

  /*
  onChangeTextThrottled = throttle((text) => {
    const { onChange } = this.props;

    onChange && onChange(text);
  }, 200);
  */

  componentDidMount() {
    const {
      props: { autoFocus },
    } = this;
    if (autoFocus) setTimeout(() => this.focus());
  }

  componentWillUnmount() {
    // this.onChangeTextThrottled.cancel();
  }

  render() {
    const {
      props: {
        error,
        type = 'text',
        autoComplete,
        placeholder,
        maxLength,
        autoFocus,
        multiline,
        disabled,
        style,
        value,
      },
      state: { focused, initialValue },
    } = this;

    return type === 'text' && multiline ? (
      <Textarea
        defaultValue={
          !isNaN(initialValue) ? String(initialValue) : initialValue
        }
        value={value}
        placeholder={placeholder}
        maxLength={maxLength}
        autoFocus={autoFocus}
        disabled={disabled}
        style={{
          ...styles.input,
          ...(focused ? styles.focused : undefined),
          ...style,
          ...(error ? styles.error : undefined),
          ...(disabled ? styles.disabled : undefined),

          // Hack!
          // The proper way would be to calculate this
          // when the application is loaded and on screen resize,
          // and then either pass the value through the context
          // or via redux. It should also be based on something
          // else than the height of the viewport that takes into
          // account what other elements there are.
          // But the context api has changed (could not use React.createContext yet)
          // and the layout of the guides will probably change too.
          // ... therefore this hack for now.
          maxHeight: window.innerHeight * 0.5,
        }}
        onChange={this.onChangeText}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        ref={(ref) => (this.inputComponent = ref)}
        data-test-id="input-text"
      />
    ) : (
      <input
        type={type}
        autoComplete={autoComplete}
        defaultValue={
          !isNaN(initialValue) ? String(initialValue) : initialValue
        }
        value={value}
        placeholder={placeholder}
        maxLength={maxLength}
        autoFocus={autoFocus}
        disabled={disabled}
        style={{
          ...styles.input,
          ...(focused ? styles.focused : undefined),
          height: 26,
          ...style,
          ...(error ? styles.error : undefined),
          ...(disabled ? styles.disabled : undefined),
        }}
        onChange={this.onChangeText}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        ref={(ref) => {
          this.inputComponent = ref;
        }}
      />
    );
  }
}
