import { _t } from '@mindoktor/patient-legacy/i18n';
import React from 'react';

import { Title1 } from '../../../common/components/typography';
import Button from '../../components/button/button';
import Markdown from '../../components/markdown';
import Modal from '../../components/modal';
import { hideModal } from '../../routing';

const styles = {
  title: {
    marginBottom: 10,
  },

  intro: {
    marginBottom: 10,
  },

  screenshot: {
    padding: 0,
    margin: 0,
    marginBottom: 10,
  },
};

const FreePassInfoModal = () => {
  return (
    <Modal
      body={
        <div>
          <Title1 style={styles.title}>{_t('faq.freePass.id.title')}</Title1>
          <Markdown source={_t('faq.freePass.id.text')} style={styles.intro} />
        </div>
      }
      footer={
        <Button type="ghost" color="black" onClick={hideModal}>
          {_t('common.close')}
        </Button>
      }
    />
  );
};

export default FreePassInfoModal;
