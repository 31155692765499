import { routes as legacyRoutes } from '@mindoktor/patient-legacy/src/state/routing';

export { legacyRoutes };

export const legacyRoutesPaths = new Set(
  Object.values(legacyRoutes as Record<string, string>)
);

export type LegacyRouteKeys = keyof typeof legacyRoutes;
export type LegacyRoute = (typeof legacyRoutes)[LegacyRouteKeys];

export const isLegacyRoute = (route: string): route is LegacyRoute =>
  legacyRoutesPaths.has(route);
