const styles = {
  heart: {
    width: 38,
    height: 30,
    marginBottom: 12,
  },

  wrapper: {
    style: {
      paddingTop: 20,
      paddingBottom: 20,
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
    },
    guideflow: {
      alignItems: 'flex-end',
    },
    screen: {
      alignItems: 'flex-start',
    },
  },

  body: {
    style: {
      maxWidth: 600,
      paddingLeft: 20,
      paddingRight: 20,
      flex: 1,
    },
    guideflow: {
      alignItems: 'flex-end',
    },
    screen: {
      alignItems: 'flex-end',
      margin: '0 auto',
      padding: 24,
    },
  },

  title: {
    fontSize: 36,
    lineHeight: '42px',
    marginBottom: 20,
  },

  lead: {
    marginBottom: 20,
  },

  section: {
    display: 'block',
    marginBottom: 20,
  },

  label: {
    fontWeight: 500,
    marginBottom: 5,
  },

  message: {
    marginTop: 4,
    fontWeight: 500,
  },

  footer: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },

  buttons: {
    marginLeft: 'auto',
    maxWidth: 600,
    flex: 1,
  },

  dropdown: {
    marginBottom: 10,
  },
  footerButton: {
    marginBottom: 12,
  },

  input: {
    height: 56,
    marginBottom: 12,
  },
};

export default styles;
