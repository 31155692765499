import { _t } from '@mindoktor/patient-legacy/i18n';
import React from 'react';

import iconSource from '../../../../../images/icon/icon_complaints@3x.png';
import colors from '../../../../common/colors';
import { Body1, Body3 } from '../../../../common/components/typography';
import { formatRoute, routes } from '../../../../state/routing';
import Button from '../../../components/button/button';
import Message from '../../components/message';
import { getDisplayTime } from '../utils';
import Img from '@mindoktor/pulse/src/utils/images/Img.web';

const styles = {
  header: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 16,
  },

  icon: {
    display: 'block',
    width: 44,
    height: 44,
    marginRight: 16,
    borderRadius: '50%',
  },

  middle: {
    flex: 1,
  },

  title: {
    fontWeight: 500,
  },

  timestamp: {
    marginLeft: 16,
    color: colors.grey_500,
  },

  lead: {
    paddingBottom: 16,
  },
};

const Workout = ({
  caseId,
  workoutId,
  title,
  timestamp,
  numberOfExercises,
  style,
}) => (
  <Message style={style}>
    <div style={styles.header}>
      <div>
        <Img src={iconSource} style={styles.icon} />
      </div>

      <div style={styles.middle}>
        <Body1 style={styles.title}>{_t('workout.program')}</Body1>
        <Body1>{title}</Body1>
      </div>

      <Body3 style={styles.timestamp}>{getDisplayTime(timestamp)}</Body3>
    </div>

    <Body1 style={styles.lead}>
      {_t('workout.numberOfExercises') + ': ' + numberOfExercises}
    </Body1>

    <Button
      color="black"
      href={`#${formatRoute(routes.CASE_WORKOUT, {
        id: caseId,
        workoutId,
      })}`}
    >
      {_t('workout.showExercises')}
    </Button>
  </Message>
);

export default Workout;
