import { WeekDays, Openings } from '../../common/types';

/** Provides MD support center opening hours */
const getOpeningHours = (): Openings[] => [
  {
    days: {
      from: WeekDays.MONDAY,
      to: WeekDays.FRIDAY,
    },
    times: [
      {
        from: '08:00',
        to: '17:00',
      },
    ],
  },
];

export default getOpeningHours;
