import React, { Component } from 'react';

import colors from '../../../common/colors';
import { Body1, SubHead } from '../../../common/components/typography';

const styles = {
  item: {
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    padding: 20,
  },

  itemHover: {
    backgroundColor: `${colors.red_500}0B`,
  },

  icon: {
    width: 60,
    height: 60,
  },

  text: {
    flex: 1,
    paddingTop: 1,
    paddingBottom: 1,
    paddingLeft: 20,
  },

  title: {
    paddingBottom: 2,
  },

  titleHover: {
    color: colors.red_500,
  },

  lead: {
    opacity: 0.66,
    fontWeight: 400,
  },
};

// type Props = {
//   iconSource: any,
//   title: string,
//   lead: string,

//   onClick: () => any,

//   style?: any,
// };

export default class Item extends Component {
  state = {
    hover: false,
  };

  onMouseEnter = () => this.setState({ hover: true });

  onMouseLeave = () => this.setState({ hover: false });

  render() {
    const { iconSource, title, lead, onClick, style } = this.props;
    const { hover } = this.state;

    return (
      <div
        style={{
          ...styles.item,
          ...(hover ? styles.itemHover : undefined),
          ...style,
        }}
        onClick={onClick}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        <img src={iconSource} style={styles.icon} />

        <div style={styles.text}>
          <SubHead
            style={{
              ...styles.title,
              ...(hover ? styles.titleHover : undefined),
            }}
          >
            {title}
          </SubHead>

          <Body1 style={styles.lead}>{lead}</Body1>
        </div>
      </div>
    );
  }
}
