import { _t } from '@mindoktor/patient-legacy/i18n';
import React, { PureComponent } from 'react';

import closeupPicture from '../../../../../images/guides/picture_instructions/closeup.png';
import closeupAnimation from '../../../../../images/guides/picture_instructions/closeup_animation.gif';
import distancePicture from '../../../../../images/guides/picture_instructions/distance.png';
import distanceAnimation from '../../../../../images/guides/picture_instructions/distance_animation.gif';
import lightingPicture from '../../../../../images/guides/picture_instructions/lighting.png';
import lightingAnimation from '../../../../../images/guides/picture_instructions/lighting_animation.gif';
import selfiePicture from '../../../../../images/guides/picture_instructions/selfie.png';
import selfieAnimation from '../../../../../images/guides/picture_instructions/selfie_animation.gif';
import throatPicture from '../../../../../images/guides/picture_instructions/throat.png';
import throatAnimation from '../../../../../images/guides/picture_instructions/throat_animation.gif';
import gotoImage from '../../../../../images/icon/ic_goto.png';
import { trackOnPress } from '../../../../state/tracking/press';
import colors from '../../../colors';
import Image from '../../primitives/Image';
import View from '../../primitives/View';
import Body2 from '../../typography';
import PictureInstructionsModal from './PictureInstructionsModal';

const ViewWithTracking = trackOnPress(View);

const buildCards = (cardMap, instructions) => {
  let cards = instructions || ['distance', 'selfie', 'closeup', 'lighting'];

  return cards.map((cardName) => cardMap[cardName]);
};

export class PictureInstructions extends PureComponent {
  cardMap = {
    distance: {
      title: _t('pictureInstructions.distance.title'),
      info: _t('pictureInstructions.distance.info'),
      animation: distanceAnimation,
      picture: distancePicture,
    },
    throat: {
      title: _t('pictureInstructions.throat.title'),
      info: _t('pictureInstructions.throat.info'),
      animation: throatAnimation,
      picture: throatPicture,
    },
    selfie: {
      title: _t('pictureInstructions.selfie.title'),
      info: _t('pictureInstructions.selfie.info'),
      animation: selfieAnimation,
      picture: selfiePicture,
    },
    closeup: {
      title: _t('pictureInstructions.closeup.title'),
      info: _t('pictureInstructions.closeup.info'),
      animation: closeupAnimation,
      picture: closeupPicture,
    },
    lighting: {
      title: _t('pictureInstructions.lighting.title'),
      info: _t('pictureInstructions.lighting.info'),
      animation: lightingAnimation,
      picture: lightingPicture,
    },
  };

  state = {
    cardIndex: 0,
    cards: buildCards(this.cardMap, this.props.instructions),
    intervalId: 0,
  };

  getNextCardIndex = () => {
    return this.state.cardIndex === this.state.cards.length - 1
      ? 0
      : this.state.cardIndex + 1;
  };

  componentDidMount() {
    if (this.state.cards.length > 1) {
      const intervalId = setInterval(
        () =>
          this.setState({
            cardIndex: this.getNextCardIndex(),
          }),
        4000
      );

      this.setState({ intervalId });
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  onPress = () => {
    this.props.showModal(
      PictureInstructionsModal,
      { onClose: this.props.hideModal, cards: this.state.cards },
      { transparent: true, animated: false }
    );
  };

  render() {
    const { cards, cardIndex } = this.state;

    return (
      <ViewWithTracking
        trackingEvent="ContextualHelp.Pressed"
        trackingProperties={{ context: 'pictureInstructions' }}
        mb="s"
        style={styles.clickableView}
        onPress={this.onPress}
      >
        <View style={styles.outline} mr="xt">
          <Image
            style={[styles.clickableImage, { opacity: 1 }]}
            resizeMode={'contain'}
            source={cards[cardIndex].picture}
          />
        </View>
        <Body2 mr="xt" style={styles.clickableText}>
          {_t('pictureInstructions.info')}
        </Body2>
        <Image style={styles.clickableGoTo} source={gotoImage} />
      </ViewWithTracking>
    );
  }
}

const styles = {
  clickableView: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    height: 52,
  },
  clickableText: {
    flex: 1,
    alignSelf: 'center',
    color: colors.blue_500,
  },
  clickableImage: {
    height: 52,
    width: 68.5,
    borderRadius: 5,
  },
  outline: {
    // react native needs extra width to fit border
    height: 54,
    width: 70.5,
    borderColor: '#0B47A1',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 5,
    backgroundColor: '#e7effc',
  },
  clickableGoTo: { alignSelf: 'center' },
};

export default PictureInstructions;
