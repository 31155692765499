import React, { useState } from 'react';

import { Button, Stack, TextField, Typography } from '@mui/material';

import ContainerLink from '@mindoktor/pulse/src/components/ContainerLink/web';
import TextAlignedChevronIcon from '@mindoktor/pulse/src/icons/ChevronIcon/TextAlignedChevronIcon';
import { colors } from '@mindoktor/pulse/src/styles/colors';

import { useTranslation } from '@mindoktor/patient-app/localization/hooks/useTranslation';
import { useRedirectionNavigation } from '@mindoktor/patient-app/routing/hooks/useRedirectionNavigation';
import {
  DataTrackingEvents,
  DataTrackingJourneyEvents,
} from '@mindoktor/patient-app/tracking/api/models/dataTracking';
import { MarketingTrackingActions } from '@mindoktor/patient-app/tracking/api/models/marketingTracking';
import { useDataTracking } from '@mindoktor/patient-app/tracking/hooks/useDataTracking';
import { useMarketingTracking } from '@mindoktor/patient-app/tracking/hooks/useMarketingTracking';
import { isMobile } from '@mindoktor/patient-app/utils/device/web';
import { WEB_URL } from '@mindoktor/patient-app/utils/externalRouting/constants';
import { openExternalMinDoktorURL } from '@mindoktor/patient-app/utils/externalRouting/web';

import { UserStatusStatuses } from '../../../api/models/userStatus';
import { useFinalizeRegistrationMutator } from '../../../hooks/useFinalizeRegistrationMutator';
import {
  RegistrationErrorKey,
  useRegistrationForm,
} from '../../../hooks/useRegistrationForm';
import { useUserDetailsApi } from '../../../hooks/useUserDetailsApi';
import { useUserErrorNotifications } from '../../../hooks/useUserErrorNotification';
import { useUserStatusApi } from '../../../hooks/useUserStatusApi';

const DATA_SAFETY_LINK = `${WEB_URL}/faq#patient-and-data-safety` as const;

const ProtectedIdentity: React.FC = () => {
  const t = useTranslation('user', 'registration');

  return (
    <Stack spacing={'1rem'}>
      <Typography variant="body1" color={colors.gray[900]}>
        {t('protectedIdentity.info')}
      </Typography>
      <ContainerLink
        onClick={() => openExternalMinDoktorURL(DATA_SAFETY_LINK)}
        endIcon={<TextAlignedChevronIcon variant="body1" />}
      >
        {t('protectedIdentity.linkText')}
      </ContainerLink>
    </Stack>
  );
};

const RegistrationForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  /** We don't want to validate until the user has tried submitting */
  const [hasTriedSubmitting, setHasTriedSubmitting] = useState(false);

  const t = useTranslation(['user', 'common']);
  const redirectionNavigation = useRedirectionNavigation();
  const marketingTracking = useMarketingTracking();

  const dataTracking = useDataTracking();

  const { data: userStatus } = useUserStatusApi(true);
  const { data: userDetails } = useUserDetailsApi();

  const registrationMutator = useFinalizeRegistrationMutator();

  const userError = useUserErrorNotifications();

  // NOTE: For people with identity protection there will be an api call for BE
  // to finalize the registration process so the submission happens for them too
  // but with hidden, non editable form values.
  const onSubmit = async () => {
    if (!hasTriedSubmitting) {
      setHasTriedSubmitting(true);
    }
    if (!isValid) {
      return;
    }
    setIsSubmitting(true);

    try {
      await registrationMutator.mutateAsync(values);
      onSuccess();
    } catch (error) {
      userError.show(error);
    }
    setIsSubmitting(false);
  };

  const {
    values,
    isValid,
    onChange,
    isFieldInvalid,
    fieldErrorMessage,
    onEnterKey,
    inputRefs,
  } = useRegistrationForm(onSubmit);
  const { postalCodeRef, cityRef, emailRef, phoneRef } = inputRefs;

  const onSuccess = () => {
    if (userStatus.status === UserStatusStatuses.UserStatusPending) {
      void marketingTracking.track(
        MarketingTrackingActions.RegistrationCompleted
      );
      void dataTracking.track(DataTrackingEvents.PageViewed, {
        journeyEvent: DataTrackingJourneyEvents.RegistrationCompleted,
      });
    } else {
      void dataTracking.track(DataTrackingEvents.PageViewed, {
        journeyEvent: DataTrackingJourneyEvents.UserDetailsUpdated,
      });
    }

    redirectionNavigation.redirect();
  };

  const disabled = (hasTriedSubmitting && !isValid) || isSubmitting;

  const showAddressFields =
    userDetails?.address === '' ||
    userDetails?.postalCode === '' ||
    userDetails?.city === '';

  const showFieldError = (key: RegistrationErrorKey) =>
    hasTriedSubmitting && isFieldInvalid(key);

  const isRequired =
    userDetails?.identityProtection != null
      ? !userDetails.identityProtection
      : true;

  return (
    <Stack flexGrow={1} spacing={'1rem'}>
      {userDetails?.identityProtection === true ? (
        <ProtectedIdentity />
      ) : (
        <>
          <Typography variant="body1" color={colors.gray[900]}>
            {t('user:registration.info')}
          </Typography>
          <form>
            <Stack spacing="2rem" marginBottom={'1rem'}>
              {/* For users that aren't registered in SPAR we need their address info */}
              {showAddressFields && (
                <>
                  <TextField
                    id="user-address"
                    label={t('user:registration.address')}
                    sx={{ marginBottom: 0 }}
                    variant="filled"
                    type="text"
                    value={values.address}
                    onChange={(e) => onChange('address', e.target.value)}
                    error={showFieldError('address')}
                    helperText={
                      hasTriedSubmitting && fieldErrorMessage('address')
                    }
                    required={isRequired}
                    autoComplete="street-address"
                    onKeyDown={(e) => onEnterKey('address', e)}
                  />
                  <TextField
                    id="user-postal-code"
                    inputRef={postalCodeRef}
                    label={t('user:registration.postalCode')}
                    sx={{ marginBottom: 0 }}
                    variant="filled"
                    type="number"
                    value={values.postalCode}
                    onChange={(e) => onChange('postalCode', e.target.value)}
                    error={showFieldError('postalCode')}
                    helperText={
                      hasTriedSubmitting && fieldErrorMessage('postalCode')
                    }
                    required={isRequired}
                    autoComplete="postal-code"
                    onKeyDown={(e) => onEnterKey('postalCode', e)}
                  />
                  <TextField
                    id="user-city"
                    inputRef={cityRef}
                    label={t('user:registration.city')}
                    sx={{ marginBottom: 0 }}
                    variant="filled"
                    type="text"
                    value={values.city}
                    onChange={(e) => onChange('city', e.target.value)}
                    error={showFieldError('city')}
                    helperText={hasTriedSubmitting && fieldErrorMessage('city')}
                    required={isRequired}
                    autoComplete="address-level2"
                    onKeyDown={(e) => onEnterKey('city', e)}
                  />
                </>
              )}

              <TextField
                id="user-email"
                inputRef={emailRef}
                label={t('user:registration.email')}
                sx={{ marginBottom: 0 }}
                variant="filled"
                type="email"
                value={values.email}
                onChange={(e) => onChange('email', e.target.value)}
                error={showFieldError('email')}
                helperText={hasTriedSubmitting && fieldErrorMessage('email')}
                required={isRequired}
                autoComplete="email"
                onKeyDown={(e) => onEnterKey('email', e)}
              />
              <TextField
                id="user-phone"
                inputRef={phoneRef}
                label={t('user:registration.phone')}
                sx={{ marginBottom: 0 }}
                variant="filled"
                type="tel"
                value={values.telephone}
                onChange={(e) => onChange('telephone', e.target.value)}
                error={showFieldError('telephone')}
                helperText={
                  hasTriedSubmitting && fieldErrorMessage('telephone')
                }
                required={isRequired}
                autoComplete="tel"
                onKeyDown={(e) => onEnterKey('telephone', e)}
              />
            </Stack>
          </form>
        </>
      )}

      {/* On small screens we want the rest aligned here at the bottom, while on large screens we
          prefer some space between the lower section and the form fields, but only so much. Hence
          the max height here.
          The result of this is that for low content screens (attow email/phone and IDP) the
          buttons will hang a bit above the lower edge, while long forms (non-SPAR users) will
          have the rest of the screen estate and scroll if needed. */}
      <Stack flex={1} maxHeight={isMobile ? 'unset' : '4rem'} />

      <Stack spacing="1rem" alignItems={'center'}>
        {userDetails?.identityProtection === false && (
          <Typography variant="body2" color={colors.gray[900]}>
            {t('user:registration.disclaimer')}
          </Typography>
        )}

        {/* Only show a stepper ("Step 2 of 2") for new users */}
        {userStatus?.status === UserStatusStatuses.UserStatusPending && (
          <Typography
            variant="body2"
            color={colors.gray[900]}
            sx={{ alignSelf: 'center' }}
          >
            {t('user:registration.stepper')}
          </Typography>
        )}

        <Button
          color="hero"
          onClick={() => {
            void onSubmit();
          }}
          variant="contained"
          disabled={disabled}
          sx={{ alignSelf: 'center', width: isMobile ? '100%' : '70%' }}
        >
          {userDetails?.identityProtection === true
            ? t('common:continue')
            : t('common:save')}
        </Button>
      </Stack>
    </Stack>
  );
};

export default RegistrationForm;
