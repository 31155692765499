/**
 * This module is dependent on browser specific stuff and is not used by the native app.
 * If it will be used by the app, we will have to rewrite this module and inject
 * functionality that is platform specific, like how to retrieve the messaging token.
 */

import { Centrifuge } from 'centrifuge';

import { LOGIN, UPDATE_TOKEN } from '../api/actions';
import { getToken as getAccessToken } from '../api/selectors';
import { connect } from './actions';
import {
  MESSAGING_CONNECT,
  MESSAGING_ENABLED,
  MESSAGING_STATUS_CHANGE,
  MESSAGING_SUBSCRIBE,
  MESSAGING_UNSUBSCRIBE,
} from './actionTypes';
import { getHost, getIsConnected, getToken } from './selectors';

const middleware = ({ dispatch, getState }) => {
  let centrifuge;
  const subscriptions = {};

  return (next) => (action) => {
    next(action);

    switch (action.type) {
      // The user is connected to the messaging server when logged in
      // When the app is reloaded for a logged in user, no token is yet
      // received from the backend when LOGIN is dispatched,
      // therefore we need to wait for an UPDATE_TOKEN action before
      // we can connect to the messaging server.
      case LOGIN:
      case UPDATE_TOKEN: {
        if (getAccessToken(getState()) && !getIsConnected(getState())) {
          setTimeout(async () => {
            const token = getToken(getState());
            if (token) {
              const host = getHost(getState());
              if (!host) {
                console.error('No host in messaging token');
                return;
              }
              dispatch(connect(host));
            }
          });
        }
        break;
      }
      case MESSAGING_SUBSCRIBE: {
        if (!centrifuge) break;

        const { payload: { channel, onPublish } = {} } = action;

        const token = getToken(getState());
        if (!token) break;

        centrifuge.setToken(token);
        let sub = centrifuge.getSubscription(channel);
        if (!sub) {
          sub = centrifuge.newSubscription(channel);
        }

        subscriptions[channel] = sub;

        if (onPublish) {
          sub.on('publication', (message) => {
            // Extra check, just in case onPublish has been reset since we created the subscription.
            if (onPublish) {
              onPublish(message);
            }
          });
        }

        sub.subscribe();

        break;
      }

      case MESSAGING_UNSUBSCRIBE: {
        const { payload: { channel } = {} } = action;
        const sub = subscriptions[channel];
        if (!sub) break;
        sub.unsubscribe();
        sub.removeAllListeners();
        centrifuge.removeSubscription(sub);

        delete subscriptions[channel];
        break;
      }
      case MESSAGING_CONNECT: {
        const token = getToken(getState());
        if (!token) break;

        if (!centrifuge) {
          const host = getHost(getState());
          if (!host) break;

          dispatch({ type: MESSAGING_ENABLED });

          centrifuge = new Centrifuge(host + '/connection/websocket', {
            debug: true,
          });
          centrifuge.on('disconnected', ({ reason, reconnect }) => {
            if (reason !== 'connection closed') {
              console.warn('messaging server was disconnected: ' + reason);
            }

            return dispatch({
              type: MESSAGING_STATUS_CHANGE,
              payload: {
                change: 'disconnected',
                reason,
                reconnect,
              },
            });
          });

          centrifuge.on('connected', ({ reason, reconnect }) => {
            dispatch({
              type: MESSAGING_STATUS_CHANGE,
              payload: {
                change: 'connected',
                reason,
                reconnect,
              },
            });
          });
        }

        centrifuge.setToken(token);

        // We need to put the connect call on the event queue if the connect
        // and disconnect handlers should be in place when connecting
        setTimeout(() => centrifuge.connect());
        break;
      }
      default:
        break;
    }
  };
};

export default middleware;
