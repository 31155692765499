import { _t } from '@mindoktor/patient-legacy/i18n';
import { MdKeyboardArrowDown } from './docly-md-icons';
import React, { Component } from 'react';

import gradientSource from '../../../images/components/fade_neutral_white.png';
import colors from '../../common/colors';
import { getIosHeightOffset, isIos } from '../utils/device';
import { Body3 } from '../../common/components/typography';
import Img from '@mindoktor/pulse/src/utils/images/Img.web';

const styles = {
  screen: {
    backgroundColor: 'white',
  },

  header: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
  },

  body: {
    display: 'flex',
    flexDirection: 'column',
  },

  footer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'white',
  },

  staticFooter: {
    backgroundColor: 'white',
  },

  overlay: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    pointerEvents: 'none',
  },

  gradient: {
    display: 'block',
    height: 70,
    width: '100%',
    padding: 0,
    margin: 0,
  },

  solid: {
    width: '100%',
    backgroundColor: 'white',
  },

  staticFooterSolid: {
    width: '100%',
    backgroundColor: 'rgba(190, 190, 190, 0.7)',
    height: 50,
    borderTopWidth: 1,
    borderTopColor: colors.grey_500,
    borderTopStyle: 'solid',
  },

  indicator: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  indicatorBody: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    // height: 30,
    paddingLeft: 10,
    paddingRight: 20,
    paddingTop: 8,
    paddingBottom: 8,
    marginBottom: 20,
    borderRadius: 30,
    backgroundColor: colors.grey_900,
    color: colors.white,
    cursor: 'pointer',
    pointerEvents: 'all',
    fontWeight: 500,
  },

  indicatorHover: {
    color: colors.grey_500,
  },

  indicatorIcon: {
    height: 24,
    width: 24,
    marginRight: 2,
  },
};

class ScrollIndicator extends Component {
  state = {
    hover: false,
  };

  onMouseEnter = () => this.setState({ hover: true });

  onMouseLeave = () => this.setState({ hover: false });

  onClick = () =>
    window.scrollTo(0, document.body && document.body.scrollHeight);

  render() {
    const { hover } = this.state;

    return (
      <div style={styles.indicator}>
        <Body3
          style={{
            ...styles.indicatorBody,
            ...(hover ? styles.indicatorHover : undefined),
          }}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          onClick={this.onClick}
        >
          <MdKeyboardArrowDown style={styles.indicatorIcon} />
          {_t('pane.scrollIndicator')}
        </Body3>
      </div>
    );
  }
}

// type Props = {
//   header?: any,
//   body?: any,
//   footer?: any,
//   staticFooter?: boolean,
//   showScrollGradient?: boolean,
//   showScrollIndicator?: boolean,
//   onBodyResize?: (height: number) => any,
//   style?: any,
//   scrollOverlayStyle?: any,
// };

const isScrolledToBottom = () => {
  return (
    window.innerHeight + window.pageYOffset + 10 >=
    (document.body ? document.body.scrollHeight : 0)
  );
};

const getElementHeight = (element) =>
  element ? element.getBoundingClientRect().height : 0;

const initialWindowHeight = window.innerHeight;

export default class Screen extends Component {
  state = {
    windowHeight: initialWindowHeight,
    headerHeight: 0,
    footerHeight: 0,
    scrolledToBottom: true,
    updated: false,
  };

  headerComponent;
  footerComponent;

  timer;

  onResize = () => this.updateState();

  onScroll = () => this.setState({ scrolledToBottom: isScrolledToBottom() });

  onHeaderRef = (component) => (this.headerComponent = component);

  onFooterRef = (component) => (this.footerComponent = component);

  updateState = () => {
    const { onBodyResize } = this.props;
    const {
      windowHeight: previousWindowHeight,
      headerHeight: previousHeaderHeight,
      footerHeight: previousFooterHeight,
      updated,
    } = this.state;

    const windowHeight = window.innerHeight;
    const headerHeight = getElementHeight(this.headerComponent);
    const footerHeight = getElementHeight(this.footerComponent);

    const changed =
      windowHeight !== previousWindowHeight ||
      headerHeight !== previousHeaderHeight ||
      footerHeight !== previousFooterHeight;

    this.setState({
      windowHeight,
      headerHeight,
      footerHeight,
      // Because the measuring of heights alter the height of the body element we may erronously
      // think we're not scrolled to the bottom until the next update cycle. This can mean that the
      // scroll indicator quickly flashes on the screen. Which we avoid by saying we're scrolled to
      // the bottom until the height measurements stabilize.
      scrolledToBottom: isScrolledToBottom() || changed,
      updated: true,
    });

    onBodyResize &&
      (!updated || changed) &&
      onBodyResize(windowHeight - headerHeight - footerHeight);
  };

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
    window.addEventListener('scroll', this.onScroll);

    this.timer = setInterval(this.updateState, 500);

    this.updateState();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
    window.removeEventListener('scroll', this.onScroll);

    clearInterval(this.timer);
  }

  render() {
    const {
      header,
      body,
      footer,
      staticFooter,
      showScrollGradient,
      showScrollIndicator,
      style,
      scrollOverlayStyle,
    } = this.props;
    const { windowHeight, headerHeight, footerHeight, scrolledToBottom } =
      this.state;

    const dynamicFooterHeight = staticFooter ? 0 : footerHeight;

    return (
      <div style={{ ...styles.screen, ...style }}>
        {!!body && (
          <div
            style={{
              ...styles.body,
              paddingTop: headerHeight,
              paddingBottom: dynamicFooterHeight,

              // Using a dynamic window height in iOS screws up the virtual keyboard positioning.
              // So for now we're doing a little bit of extra magic.
              minHeight: isIos
                ? `calc(100vh - ${
                    getIosHeightOffset() + headerHeight + footerHeight
                  }px)`
                : windowHeight - headerHeight - footerHeight,
            }}
          >
            {body}
          </div>
        )}

        {!!header && (
          <div ref={this.onHeaderRef} style={styles.header}>
            {header}
          </div>
        )}

        {!!footer && (
          <div
            ref={this.onFooterRef}
            style={staticFooter ? styles.staticFooter : styles.footer}
          >
            {footer}
          </div>
        )}

        {!scrolledToBottom && showScrollGradient && !staticFooter && (
          <div
            style={{
              ...scrollOverlayStyle,
              ...styles.overlay,
              bottom: dynamicFooterHeight,
            }}
          >
            <Img src={gradientSource} style={styles.gradient} />

            <div
              style={{
                ...styles.solid,
                height: showScrollIndicator ? 30 : 10,
              }}
            />
          </div>
        )}

        {!scrolledToBottom && staticFooter && (
          <div
            style={{
              ...styles.staticFooterSolid,
              ...styles.overlay,
            }}
          />
        )}

        {!scrolledToBottom && showScrollIndicator && (
          <div
            style={{
              ...scrollOverlayStyle,
              ...styles.overlay,
              bottom: staticFooter ? 10 : footerHeight,
            }}
          >
            <ScrollIndicator />
          </div>
        )}
      </div>
    );
  }
}
