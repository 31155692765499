import {
  MarketingTrackingAction,
  MarketingTrackingActions,
  MarketingTrackingCategory,
  SendEventAdditionalProperties,
} from './marketingTracking';

export interface GrowthAnalyticsApi {
  init?: (isEnabled: boolean) => void;
  isInitialized: () => boolean;
  sendFunnelEvent: (
    category: MarketingTrackingCategory,
    action: MarketingTrackingAction,
    label: string,
    additionalProperties?: SendEventAdditionalProperties
  ) => void;
  sendGenericEvent: (
    category: MarketingTrackingCategory,
    action: MarketingTrackingAction,
    label: string,
    additionalProperties?: SendEventAdditionalProperties
  ) => void;
  sendPageView: () => void;
  setVerbose: (verbose: boolean) => void;
  userLogin: (userMarketingId: string) => void;
  userLogout: () => void;
}

export const marketingTrackingLabels = {
  Login: 'Login',
  Registration: 'Registration',
} as const;

export type MarketingTrackingLabel = keyof typeof marketingTrackingLabels;

export const marketingTrackingActionLabelMapping = {
  [MarketingTrackingActions.LoginStarted]: marketingTrackingLabels.Login,
  [MarketingTrackingActions.LoginCompleted]: marketingTrackingLabels.Login,
  [MarketingTrackingActions.RegistrationStarted]:
    marketingTrackingLabels.Registration,
  [MarketingTrackingActions.RegistrationCompleted]:
    marketingTrackingLabels.Registration,
};
