import find from 'lodash-es/find';
import isArray from 'lodash-es/isArray';
import isString from 'lodash-es/isString';
import keys from 'lodash-es/keys';

const defaultValidators = {
  required: (value, required) => {
    return !required || value !== undefined;
  },

  min: (value, min) => {
    return value >= min;
  },

  max: (value, max) => {
    return value <= max;
  },

  condition: (value, condition, evaluator) => {
    return !!evaluator(condition);
  },

  pattern: (value, pattern) => {
    return isString(value) && !!value.match(pattern);
  },
};

const typeValidators = {
  text: {
    required: (value) => {
      return isString(value) && value.trim().length > 0;
    },

    min: (value, min) => {
      return isString(value) && value.trim().length >= min;
    },

    max: (value, max) => {
      return !isString(value) || value.trim().length <= max;
    },
  },

  upload: {
    min: (value, min) => {
      if (min === 0) return true;
      if (!value) return false;

      return value.length >= min;
    },

    max: (value, max) => {
      if (!value) return true;

      return value.length <= max;
    },
  },

  choice: {
    required: (value, required, evaluator, properties) => {
      return (
        !required ||
        find(properties.options, (option) => {
          return option.show && option.value === value;
        })
      );
    },
  },

  multipleChoice: {
    required: (value, required, evaluator, properties) => {
      return (
        !required ||
        find(value, (curVal) => {
          // For each element in the answer array, go through each option
          // on the question in order to find a match that has show == true.
          return find(properties.options, (option) => {
            return option.show && option.value === curVal;
          });
        })
      );
    },
  },

  multipleSearch: {
    required: (value) => {
      return isArray(value) && value.length > 0;
    },

    min: (value, min) => {
      return isArray(value) && value.length >= min;
    },

    max: (value, max) => {
      return isArray(value) && value.length <= max;
    },
  },
};

export default function Validators(properties, evaluator) {
  return (value) => {
    return find(keys(defaultValidators), (name) => {
      let validator;
      const option = properties.validations && properties.validations[name];
      if (option !== false && option !== undefined && option !== null) {
        validator =
          (typeValidators[properties.type] &&
            typeValidators[properties.type][name]) ||
          defaultValidators[name];
        return !validator(value, option, evaluator, properties);
      }
      return undefined;
    });
  };
}
