import { routes } from '../../state/routing';
import { navigate } from '../routing';
import AddScreen from './add/screen';
import ListScreen from './list/screen';
import UnderSixMonthsScreen from './undersixmonths/screen';

export default {
  [routes.CHILDREN]: ({
    props: {
      entrywayId,
      revisitId,
      childUuid,
      dynamicCode,
      preferredCaregiverId,
    } = {},
  }) =>
    navigate(ListScreen, {
      entrywayId: entrywayId && parseInt(entrywayId, 10),
      revisitId: revisitId ? parseInt(revisitId, 10) : undefined,
      dynamicCode,
      childUuid,
      preferredCaregiverId,
    }),

  [routes.CHILDREN_ADD]: ({
    props: { entrywayId, dynamicCode, preferredCaregiverId } = {},
  }) =>
    navigate(AddScreen, {
      entrywayId: entrywayId && parseInt(entrywayId, 10),
      dynamicCode,
      preferredCaregiverId,
    }),

  [routes.CHILDREN_UNDER_SIX_MONTHS]: () => navigate(UnderSixMonthsScreen),
};
