import React, { Component } from 'react';
import { connect } from 'react-redux';
import { _t } from '@mindoktor/patient-legacy/i18n';

import { fetchCases, paymentDone } from '../../../state/cases/actions';
import { createCase } from '../../../state/formulary/actions';
import { getFormularyState } from '../../../state/formulary/selectors';
import { getGuideByEntrywayId } from '../../../state/guides/selectors';
import { submitFreePass } from '../../../state/freepass/actions';
import {
  profileIdentityProtection,
  isContactInfoVerified,
  contactInformation,
} from '../../../state/profile/selectors';
// import type { FreePassEditData } from '../../../state/freepass/types';
import {
  // type PaymentMethodType,
  paymentMethodTypes,
} from '../../../state/payment/types';

import { openRoute, routes } from '../../../state/routing';
import { getExternalIdFromHost } from '../../utils/host';
import { selectContactInfoVerification } from '../../../state/profile/actions';
import Button from '../../components/button/button';
import JournalConsent from '../components/journal_consent';

const styles = {
  root: {
    marginBottom: 20,
    marginLeft: 20,
    marginRight: 20,
  },

  consent: {
    marginBottom: 20,
  },

  companyDetails: {
    marginTop: 20,
  },
};

// type Props = {
//   formularyKey: string,

//   dynamicCode?: string,

//   requireJournalConsent?: boolean,

//   email?: string,

//   fetchCases: typeof fetchCases,
//   createCase: typeof createCase,
//   openRoute: typeof openRoute,

//   paymentMethod: PaymentMethodType,
//   freePassData: FreePassEditData,
//   submitFreePass: typeof submitFreePass,
// };

export class CartFooter extends Component {
  state = { journalConsent: false, submitting: false };

  submit = async () => {
    const {
      formularyKey,
      dynamicCode,
      requireJournalConsent,
      fetchCases,
      createCase,
      openRoute,
      submitFreePass,
      paymentMethod,
      freePassData,
      isVerified,
      hasIdentityProtection,
      paymentDone,
    } = this.props;

    const { journalConsent } = this.state;

    this.setState({ submitting: true });

    // If the user chose freepass and it has changed we need to save the
    // new freepass data before posting the case.
    if (
      paymentMethod === paymentMethodTypes.FREEPASS &&
      freePassData.isChanged
    ) {
      const ok = await submitFreePass(freePassData);
      if (!ok) {
        // error notification is handled in middleware FREE_PASS_ERROR
        this.setState({ submitting: false });
        return;
      }
    }

    const { caseId, error } = await createCase({
      formularyKey,
      dynamicCode,
      journalConsent: requireJournalConsent ? journalConsent : undefined,
      externalId: getExternalIdFromHost(),
    });

    const nextRouteArgs =
      isVerified || hasIdentityProtection
        ? [
            routes.CONTACT_INFO_VERIFICATION_GUIDE_COMPLETED_ALREADY_VERIFIED,
            { caseId, paymentMethod },
          ]
        : [
            routes.CONTACT_INFO_VERIFICATION_GUIDE_CONFIRMATION,
            { caseId, paymentMethod },
          ];

    if (!error) {
      await paymentDone(caseId, paymentMethod);
      // Fetch cases to repopulate the state with the newly created case
      await fetchCases();
      // and then move on to the confirmation screen
      openRoute(...nextRouteArgs);
    } else {
      this.setState({ submitting: false });

      // handle error
    }
  };

  onJournalConsentChange = () =>
    this.setState({ journalConsent: !this.state.journalConsent });

  isCaseSubmittable = () => {
    const { paymentMethod, requireJournalConsent, freePassData } = this.props;
    const { journalConsent, submitting } = this.state;

    let ret =
      !submitting &&
      (journalConsent || !requireJournalConsent) &&
      !!paymentMethod;

    if (paymentMethod === paymentMethodTypes.FREEPASS) {
      return ret && freePassData.isValid;
    }

    return ret;
  };

  getSubmitButtonCopy = () => {
    const { paymentMethod } = this.props;
    const { submitting } = this.state;

    let submitButtonCopy =
      paymentMethod === paymentMethodTypes.FREE
        ? _t('payment.cart.submit')
        : _t('payment.cart.complete');

    // After the button is clicked we show
    // different text till the "submit" is completed.
    submitButtonCopy = submitting
      ? _t('payment.cart.submitting')
      : submitButtonCopy;

    return submitButtonCopy;
  };

  componentDidMount() {
    const { selectContactInfoVerification, email } = this.props;
    selectContactInfoVerification(email);
  }

  render() {
    const { requireJournalConsent } = this.props;

    const { journalConsent } = this.state;

    const submittable = this.isCaseSubmittable();

    return (
      <div style={styles.root}>
        {!!requireJournalConsent && (
          <JournalConsent
            consent={journalConsent}
            onChange={this.onJournalConsentChange}
            style={styles.consent}
          />
        )}

        <Button
          hero={true}
          onClick={submittable ? this.submit : undefined}
          style={{ opacity: !submittable ? 0.33 : 1 }}
        >
          {this.getSubmitButtonCopy()}
        </Button>
      </div>
    );
  }
}

export default connect(
  (state, props) => {
    const { formularyKey } = props;

    const { entrywayId, dynamicCode } =
      getFormularyState(state, formularyKey) || {};

    const { requireJournalConsent } =
      getGuideByEntrywayId(state, entrywayId) || {};

    const hasIdentityProtection = profileIdentityProtection(state);
    const contactInfo = contactInformation(state);
    const isVerified = isContactInfoVerified(state, contactInfo.email);
    const { email } = contactInfo;

    return {
      requireJournalConsent,
      dynamicCode,
      email,
      isVerified,
      hasIdentityProtection,
    };
  },
  {
    fetchCases,
    createCase,
    openRoute,
    submitFreePass,
    selectContactInfoVerification,
    paymentDone,
  }
)(CartFooter);
