import Config from '@mindoktor/env/Config';
import debounce from 'lodash-es/debounce';

import { getApi, postApi } from '../api/actions';
import { getAppOsAndVersion } from './selectors';

export const APP_ONLINE = 'APP_ONLINE';
export const APP_START = 'APP_START';
export const APP_STARTED = 'APP_STARTED';
export const APP_STOP = 'APP_STOP';
export const APP_ERROR = 'APP_ERROR';
export const APP_STORAGE_LOADED = 'APP_STORAGE_LOADED';
export const APP_REFERRAL = 'APP_REFERRAL';
export const APP_REFERRAL_RESET = 'APP_REFERRAL_RESET';
export const TOGGLE_KEYBOARD = 'TOGGLE_KEYBOARD';
export const RECEIVE_SYSTEM_MESSAGE = 'RECEIVE_SYSTEM_MESSAGE';
export const FIRST_LEVEL_NAVIGATION = 'FIRST_LEVEL_NAVIGATION';
export const INIT = 'INIT';
export const APP_OPEN_URL = 'APP_OPEN_URL';
export const APP_PLAY_VIDEO = 'APP_PLAY_VIDEO';
export const APP_VERSION_STATUS_RECEIVED = 'APP_VERSION_STATUS_RECEIVED';
export const APP_FETCH_CONSENTS = 'APP_FETCH_CONSENTS';
export const APP_TYPING = 'APP_TYPING';
export const APP_LAUNCHED = 'APP_LAUNCHED';
export const APP_LAUNCHED_FIRST_TIME = 'APP_LAUNCHED_FIRST_TIME';
export const APP_STATE_CHANGE = 'APP_STATE_CHANGE';
export const APP_LAUNCH_NOTIFICATION = 'APP_LAUNCH_NOTIFICATION';
export const APP_WHITE_BLACK_LIST_SHOWN = 'APP_WHITE_BLACK_LIST_SHOWN';
export const APP_READY = 'APP_READY';
export const APP_ADD_ENTRANCE_HIGHLIGHT_REQUEST =
  'APP_ADD_ENTRANCE_HIGHLIGHT_REQUEST';
export const APP_ADD_ENTRANCE_HIGHLIGHT_SUCCESS =
  'APP_ADD_ENTRANCE_HIGHLIGHT_SUCCESS';
export const APP_ADD_ENTRANCE_HIGHLIGHT_ERROR =
  'APP_ADD_ENTRANCE_HIGHLIGHT_ERROR';
export const SET_SAFEAREA_COLOR = 'SET_SAFEAREA_COLOR';
export const APP_REGISTER_ADVERTISING_ID_REQUEST =
  'APP_REGISTER_ADVERTISING_ID_REQUEST';
export const APP_REGISTER_ADVERTISING_ID_REQUEST_ERROR =
  'APP_REGISTER_ADVERTISING_ID_REQUEST_ERROR';
export const APP_REGISTER_ADVERTISING_ID_REQUEST_SUCCESS =
  'APP_REGISTER_ADVERTISING_ID_REQUEST_SUCCESS';
export const APP_REGISTER_ADVERTISING_ID_MISSING =
  'APP_REGISTER_ADVERTISING_ID_MISSING';
export const APP_EXTERNAL_LINK_PRESSED = 'APP_EXTERNAL_LINK_PRESSED';

// debounce online status report, to avoid initial null-false-then-true leap
const reportOnlineStatus = debounce((dispatch, getState, online) => {
  // only dispatch if online status has actually changed
  if (online !== getState().app.online) {
    dispatch({ type: APP_ONLINE, payload: { online } });
  }
}, 400);

export const appOnline = (online) => {
  return (dispatch, getState) => {
    reportOnlineStatus(dispatch, getState, online);
  };
};

export const appToggleKeyboard = (height) => {
  return {
    type: TOGGLE_KEYBOARD,
    payload: {
      height,
      visible: height > 0,
    },
  };
};

// Function that sets a system message if there is any
// e.g. 'Service will be unavailable between 22-34 due to server maintenance'
export const fetchSystemMessage = () => async (dispatch) => {
  const { json, error } = await dispatch(getApi('/api/v1/appalerts'));
  if (!error && json) {
    dispatch({
      type: RECEIVE_SYSTEM_MESSAGE,
      payload: {
        systemMessage: json,
      },
    });
  }
};

export const checkKillSwitch = () => async (dispatch, getState) => {
  if (!Config.KillSwitchEnabled) {
    return dispatch({
      type: APP_VERSION_STATUS_RECEIVED,
      payload: {
        enabled: false,
        isAllowed: true,
        hasUpdate: false,
      },
    });
  }

  const { os, version } = getAppOsAndVersion(getState());
  const url = `/api/v1/appversionstatus/mindoktor/${os}/${version}`;

  const { json, error } = await dispatch(getApi(url));

  if (!error && json) {
    return dispatch({
      type: APP_VERSION_STATUS_RECEIVED,
      payload: json,
    });
  }

  return null;
};

export const navigationOnFirstLevel = (payload) => {
  return {
    type: FIRST_LEVEL_NAVIGATION,
    payload: {
      navigationAtFirstLevel: payload.atFirstLevel,
    },
  };
};

export function setReferralInfo(uri = '', params = {}) {
  return (dispatch) => {
    dispatch({
      type: APP_REFERRAL,
      payload: {
        uri,
        params,
      },
    });
  };
}

export function clearReferralInfo() {
  return {
    type: APP_REFERRAL_RESET,
  };
}

// Called on componentDidMount aka only at the client (browser or native).
export const appStart = (os, version) => {
  return {
    type: APP_START,
    payload: { os, version },
  };
};

export const appReady = () => ({
  type: APP_READY,
});

export const appStop = () => {
  return {
    type: APP_STOP,
  };
};

export function initApp(obj) {
  return {
    type: INIT,
    payload: obj,
  };
}

export const openUrl = (uri, meta) => {
  return {
    type: APP_OPEN_URL,
    payload: {
      uri,
    },
    meta,
  };
};

export const playVideo = (source, meta) => ({
  type: APP_PLAY_VIDEO,
  payload: { source },
  meta,
});

export const triggerTyping = () => ({ type: APP_TYPING });

export const changeAppState = (state) => ({
  type: APP_STATE_CHANGE,
  payload: { state, timestamp: Date.now() },
});

export const handleLaunchNotification = (notification) => ({
  type: APP_LAUNCH_NOTIFICATION,
  payload: { notification },
});

export const addContextualHint = (payload) => async (dispatch) => {
  dispatch({
    type: APP_ADD_ENTRANCE_HIGHLIGHT_REQUEST,
  });

  const { error } = await dispatch(postApi('/api/v1/contextualhints', payload));

  if (error) {
    dispatch({
      type: APP_ADD_ENTRANCE_HIGHLIGHT_ERROR,
      payload: error,
    });
  }

  dispatch({
    type: APP_ADD_ENTRANCE_HIGHLIGHT_SUCCESS,
  });
};

export const setSafeAreaColor = (color) => ({
  type: SET_SAFEAREA_COLOR,
  payload: {
    color,
  },
});

export const registerAdvertisingId = (token) => async (dispatch, getState) => {
  if (!token) {
    dispatch({
      type: APP_REGISTER_ADVERTISING_ID_MISSING,
    });

    return;
  }

  dispatch({
    type: APP_REGISTER_ADVERTISING_ID_REQUEST,
    payload: token,
  });

  const state = getState();
  const { os, version: appVersion } = getAppOsAndVersion(state);

  const { json, error } = await dispatch(
    postApi('/api/v1/registeradvertisingid', {
      os,
      appVersion,
      token,
    })
  );

  if (error) {
    dispatch({
      type: APP_REGISTER_ADVERTISING_ID_REQUEST_ERROR,
      error,
    });

    return;
  }

  dispatch({
    type: APP_REGISTER_ADVERTISING_ID_REQUEST_SUCCESS,
    payload: json,
  });
};

export const formularySearch = (query, source) => (dispatch) =>
  dispatch(
    postApi('/api/v1/formularysearch', {
      query,
      source,
    })
  );

// externalLinkPressed triggers an action with the url as payload and a default
// meta object with tracking info that can be replaced if so desired.
export const externalLinkPressed = ({ href, trackData }) => ({
  type: APP_EXTERNAL_LINK_PRESSED,
  payload: { href },
  meta: {
    track: {
      event: 'ExternalLinkPressed',
      receiver: ['product'],
      properties: {
        href,
      },
      ...trackData,
    },
  },
});
