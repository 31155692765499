// import type { Action } from '../types';

export const FAQ_FETCH = 'FAQ_FETCH';
export const FAQ_FETCH_SUCCESS = 'FAQ_FETCH_SUCCESS';
export const FAQ_FETCH_ERROR = 'FAQ_FETCH_ERROR';

// export type FAQState = {
//   categories: Array<Object>,
//   lastUpdated: number,
// };

// export type FAQFetchAction = {
//   type: 'FAQ_FETCH',
// } & Action;

// export type FAQFetchSuccessAction = {
//   type: 'FAQ_FETCH_SUCCESS',
// } & Action;

// export type FAQFetchErrorAction = {
//   type: 'FAQ_FETCH_ERROR',
// } & Action;
