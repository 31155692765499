import clone from 'lodash-es/clone';
import filter from 'lodash-es/filter';
import find from 'lodash-es/find';
import isArray from 'lodash-es/isArray';
import isObject from 'lodash-es/isObject';
import isString from 'lodash-es/isString';

export const DEFAULT_RANGE_MIN_VALUE = 0;
export const DEFAULT_RANGE_MAX_VALUE = 10;

const transforms = {
  binary: (value) => {
    return !value || value === 'no' ? 'no' : 'yes';
  },

  choice: (value, properties) => {
    if (find(properties.options, { value })) return value;
    return undefined;
  },

  confirm: (value) => {
    if (value) return 'yes';
    return undefined;
  },

  medication: (value) => {
    if (isObject(value) || value === 'other') return value;
    if (isString(value)) return JSON.parse(value);
    return undefined;
  },

  multipleChoice: (value, properties) => {
    const a = filter(isArray(value) ? value : [value], (v) => {
      return find(properties.options, { value: v });
    });

    if (a.length) return a;
    return undefined;
  },

  number: (value) => {
    let value2;

    if (typeof value === 'string') {
      if (!value) return undefined;

      // Because in Sweden, ',' is used before float decimals instead of
      // '.', but the function isNaN expects '.'.
      value2 = value.replace(',', '.');
      if (!isNaN(value2)) return Number(value2);
    } else if (typeof value === 'number' && !isNaN(value)) {
      return value;
    }

    return undefined;
  },

  range: (value, properties) => {
    let number;
    if (isNaN(value)) return undefined;
    number = Number(value);
    if (
      number <
      ((properties.min && properties.min.value) || DEFAULT_RANGE_MIN_VALUE)
    ) {
      return undefined;
    }
    if (
      number >
      ((properties.max && properties.max.value) || DEFAULT_RANGE_MAX_VALUE)
    ) {
      return undefined;
    }
    return number;
  },

  rating: (value) => {
    let number;
    if (isNaN(value)) return undefined;
    number = Number(value);
    if (number < 1 || number > 5) return undefined;
    return number;
  },

  tertiary: (value) => {
    if (['yes', 'no', 'unknown'].indexOf(value) > -1) return value;
    return undefined;
  },

  text: (value) => {
    const string = '' + value;
    if (string) return string;
    return undefined;
  },

  upload: (value) => {
    const a = (isArray(value) ? clone(value) : [value]).filter((a) => !!a);
    if (a.length) return a;
    return undefined;
  },

  multipleSearch: (value) => {
    const valueArray = isArray(value) ? clone(value) : [value];

    const a = valueArray.map((v) => {
      if (typeof v === 'string') {
        try {
          const o = JSON.parse(v);
          if (o && typeof o === 'object') {
            return o;
          }
        } catch (e) {
          return v;
        }
      }
      return v;
    });

    return a;
  },
};

export default function Transforms(properties) {
  const type = properties.type;

  // Takes a value, checks that it conforms to all the rules for the given node type. If not,
  // it returns undefined.
  return (value) => {
    if (value === undefined || value === null) return undefined;
    if (!transforms[type]) return value;
    return transforms[type](value, properties);
  };
}
