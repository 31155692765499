import { REHYDRATE } from 'redux-persist/es/constants';

import { toFormularyKey } from '../formulary/utils';
import { DRAFTS_LOAD_SUCCESS } from './types';

const initialState = {
  draftsByEntrywayId: {},
  draftCount: 0,
  draftById: {},
  draftIds: [],
  hasLoaded: false,
};

const createId = ({ entrywayId, formularyId, formularyVersion, patientUUID }) =>
  toFormularyKey({
    entrywayId,
    formularyId,
    formularyVersion,
    patientUUID,
  });

export default function draftReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case DRAFTS_LOAD_SUCCESS: {
      const {
        payload: { drafts },
      } = action;
      const cleanedDrafts = drafts
        .filter((draft) => draft.isCompanySameAsProfileCompany)
        .map((draft) => ({
          ...draft,
          childUuid: draft.patientUUID,
          id: createId(draft),
        }));

      const draftById = cleanedDrafts.reduce((acc, draft) => {
        acc[draft.id] = draft;
        return acc;
      }, {});

      const draftsByEntrywayId = cleanedDrafts.reduce((acc, draft) => {
        acc[draft.entrywayId] = acc[draft.entrywayId] || [];
        acc[draft.entrywayId].push(draft.id);
        return acc;
      }, {});

      const draftCount = cleanedDrafts.length || 0;

      return {
        ...state,
        draftById,
        draftsByEntrywayId,
        draftCount,
        draftIds: cleanedDrafts.map((draft) => draft.id),
        hasLoaded: true,
      };
    }

    case REHYDRATE:
      const draftCount =
        (payload.drafts && payload.drafts.draftCount) ||
        initialState.draftCount;
      return {
        ...state,
        draftCount,
      };

    default:
      return state;
  }
}
