export const AuthErrorCodes = {
  ACCESS_DENIED: 'err.auth.access_denied',
  BANKID_CANCELLED: 'err.auth.bankid_cancelled',
  BANKID_CERTIFICATE_ERROR: 'err.auth.bankid_certificate',
  BANKID_EXPIRED_TRANSACTION: 'err.auth.bankid_transaction_expired',
  BANKID_FAILURE_UNKNOWN: 'err.auth.bankid_unknown',
  BANKID_INTERNAL_ERROR: 'err.auth.bankid_internal_error',
  BANKID_SESSION_NOT_FOUND: 'err.auth.bankid_session_not_found',
  BANKID_USER_CANCEL: 'err.auth.bankid_user_cancelled',
  BANKID_USER_DECLINED_CALL: 'err.auth.bankid_user_declined_call',
  BANKID_START_FAILED: 'err.auth.bankid_start',
  EMAIL_LOGIN_FAILED: 'err.auth.email_login_failed',
  USER_AGE_LIMIT: 'err.auth.bankid_age_limit',
  RESOURCE_NOT_FOUND: 'err.request.resource_not_found',
  INVALID_REQUEST: 'err.request.invalid',
} as const;

type AuthErrorCodeKeys = keyof typeof AuthErrorCodes;
export type AuthErrorCode = (typeof AuthErrorCodes)[AuthErrorCodeKeys];

export interface AuthResponseError {
  error: {
    code: AuthErrorCode;
    message: string;
  };
}
