import { ajaxApi, ApiHeaders } from '../api';

const API_ENDPOINT_USER_NOTIFICATIONS_GET = '/api/v1/usernotifications/';
import type { UserNotification } from './types';

/**
 * Fetches user notifications.
 */
const fetchUserNotifications = async (
  apiHeaders: ApiHeaders
): Promise<[UserNotification[], Error | null]> => {
  const [jsonResponse, err] = await ajaxApi(
    apiHeaders,
    {
      method: 'GET',
      url: API_ENDPOINT_USER_NOTIFICATIONS_GET,
    },
    null
  );
  if (err) {
    return [[], err];
  }
  const notifications = (jsonResponse?.data?.notifications ??
    []) as UserNotification[];
  return [notifications, err];
};

export default fetchUserNotifications;
