import React from 'react';

import Box from '@mui/material/Box';
import Stack, { StackProps } from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { colors } from '@mindoktor/pulse/src/styles/colors';

import { useTranslation } from '@mindoktor/patient-app/localization/hooks/useTranslation';

interface Props {
  paddingBottom?: StackProps['paddingBottom'];
}

const LastReadMarker: React.FC<Props> = ({ paddingBottom }) => {
  const t = useTranslation('messaging');
  const Line = () => (
    <Box
      sx={{
        backgroundColor: colors.gray.V900,
        height: '1px',
        flexGrow: 1,
      }}
    />
  );

  return (
    <Stack
      direction="row"
      spacing="1rem"
      alignItems="center"
      paddingBottom={paddingBottom}
    >
      <Line />
      <Typography variant="body2" fontWeight="medium" color={colors.gray.V900}>
        {t('unreadMessages')}
      </Typography>
      <Line />
    </Stack>
  );
};

export default LastReadMarker;
