import React, { Component } from 'react';
import { connect } from 'react-redux';

import colors from '../../common/colors';
import { Body1, Body3 } from '../../common/components/typography';
import { getFormularyState } from '../../state/formulary/selectors';
import LimitedHtml from '../components/html/limited';
import UploadFiles from '../components/upload/files';
import UndoLink from './components/undo_link';
import { getDisplayAnswer, getDisplayError, getDisplayLabel } from './utils';

const styles = {
  root: {
    paddingLeft: 20,
    paddingRight: 20,
  },

  label: {
    marginBottom: 20,
  },

  labelDim: {
    color: '#707070',
  },

  error: {
    marginTop: 5,
    marginBottom: 0,
    color: colors.error_text,
    textAlign: 'right',
  },

  undo: {
    marginLeft: '25%',
  },
};

// type Props = {
//   formularyKey?: string,

//   node?: Question,

//   answer?: any,
//   contextDescription?: string,
//   error?: string,

//   truncate?: boolean,
//   thumbnails?: boolean,
//   dimLabel?: boolean,
//   solid?: boolean,

//   onClick?: Function,

//   style?: any,
// };

// type State = {
//   label: string,
//   node: ?Question,
// };

export class Answer extends Component {
  static defaultProps = {
    truncate: true,
  };

  state = {
    label: getDisplayLabel(this.props.node),
    node: this.props.node,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.node && !prevState.node) {
      return {
        ...prevState,
        label: getDisplayLabel(nextProps.node),
        node: nextProps.node,
      };
    }

    return null;
  }

  render() {
    const {
      caseId,
      node,
      answer,
      error,
      truncate,
      thumbnails,
      dimLabel,
      solid,
      onClick,
      style,
      contextDescription,
    } = this.props;
    const { label } = this.state;

    const { type } = node;

    const displayAnswer = getDisplayAnswer(node, answer);

    const showAnswerAsThumbnails = type === 'upload' && answer && thumbnails;

    return (
      <div style={{ ...styles.root, ...style }}>
        <LimitedHtml html={contextDescription} />
        <Body1
          style={{
            ...styles.label,
            ...(dimLabel ? styles.labelDim : undefined),
          }}
        >
          {label && label.length > 100 && truncate
            ? label.slice(0, 97) + '...'
            : label}
        </Body1>

        <UndoLink
          text={
            showAnswerAsThumbnails
              ? undefined
              : displayAnswer && displayAnswer.length > 100 && truncate
              ? displayAnswer.slice(0, 97) + '...'
              : displayAnswer
          }
          error={!!error}
          solid={solid}
          onClick={onClick}
          style={styles.undo}
        >
          {showAnswerAsThumbnails && (
            <UploadFiles caseId={caseId} files={answer} />
          )}
        </UndoLink>

        {!!error && (
          <Body3 style={styles.error}>{getDisplayError(node, error)}</Body3>
        )}
      </div>
    );
  }
}

export default connect((state, props) => {
  const { formularyKey, node } = props;

  if (!formularyKey) return {};

  const { answers, errors } = getFormularyState(state, formularyKey) || {};

  return {
    answer: answers && answers[node.id],
    error: errors && errors[node.id],
  };
})(Answer);
