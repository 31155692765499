import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import createMuiTheme from '@material-ui/core/styles/createMuiStrictModeTheme';
import React from 'react';

import colors from '../../common/colors';
import { isIos, isPhone } from '../utils/device';
import spacingUnit from './spacing';
import typography from './typography';

const breakpointValues = {
  xs: 0,
  sm: 600,
  md: 880,
  lg: 1160,
  xl: 1441,
};
const { spacing, breakpoints } = createMuiTheme({
  breakpoints: { values: breakpointValues },
  spacing: spacingUnit,
});
const theme = createMuiTheme({
  breakpoint: { values: breakpointValues },
  palette: {
    primary: { main: colors.red_500, light: colors.red_500 },
    secondary: { main: colors.off_black },
    error: { main: colors.yellow_500 },
    textPrimary: { main: colors.off_black },
    textSecondary: { main: colors.grey_700 },
    background: { default: colors.grey_100 },
    action: { disabledBackground: colors.white_semi_transparent },
  },
  typography: {
    ...typography.body1,
    useNextVariants: true,
    h1: typography.h1,
    h2: typography.h2,
    h3: typography.h3,
    h4: typography.h3,
    h5: typography.h3,
    h6: typography.h3,
    subtitle1: typography.body1,
    subtitle2: typography.body2,
    body1: typography.body1,
    body2: typography.body2,
    caption: typography.body2,
    button: {
      ...typography.button,
      textTransform: 'inherit',
    },
    overline: typography.body2,
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
      },
    },
    MuiDialog: {
      paper: {
        marginLeft: spacing(3),
        marginRight: spacing(3),
        boxShadow: '0px 2px 60px rgba(0, 0, 0, 0.4)',
        borderRadius: 8,
      },
      paperFullWidth: {
        width: `calc(100% - ${spacing(6)}px)`,
      },
    },
    MuiDialogTitle: {
      root: {
        padding: `0px ${spacing(4)}px`,
        '&:first-child': { paddingTop: spacing(4) },
        '&:last-child': { paddingBottom: spacing(4) },
      },
    },
    MuiDialogContent: {
      root: {
        padding: `${spacing(2)}px ${spacing(4)}px 0px ${spacing(4)}px`,
        '&:first-child': { paddingTop: spacing(4) },
        '&:last-child': { paddingBottom: spacing(4) },
      },
    },
    MuiDialogActions: {
      root: {
        padding: `${spacing(3)}px ${spacing(4)}px 0px ${spacing(4)}px`,
        '&:first-child': { paddingTop: spacing(4) },
        '&:last-child': { paddingBottom: spacing(4) },
        display: 'flex',
        flexDirection: 'row',
        [breakpoints.down('xs')]: {
          flexDirection: 'column',
        },
      },
      spacing: {
        '& > *': {
          width: '100%',
          flex: 1,
        },
        '& > * + *': {
          marginLeft: spacing(2),
          [breakpoints.down('xs')]: {
            marginTop: spacing(2),
            marginLeft: 0,
          },
        },
      },
    },
    MuiButton: {
      root: {
        borderRadius: 99,
        boxShadow: 'none',
        minHeight: 40,
        '&:hover': isPhone
          ? {}
          : {
              backgroundColor: colors.grey_100,
            },
      },
      sizeLarge: {
        minHeight: 50,
      },
      label: {
        textTransform: 'none',
      },
      outlined: {
        border: `1px solid ${colors.grey_500}`,
        padding: '8px 16px',
        backgroundColor: 'white',
      },
      outlinedPrimary: {
        borderColor: colors.red_500,
      },
      contained: {
        // no shadow for default buttons
        boxShadow: 'none',
      },
      containedSecondary: {
        backgroundColor: colors.grey_900,
      },
    },
    MuiFab: {
      root: {
        height: 56,
        width: 56,
        boxShadow: 'none',
      },
      sizeSmall: {
        height: 40,
        width: 40,
      },
    },
    MuiFormControlLabel: {
      label: typography.body1,
    },
    MuiFormLabel: {
      root: typography.body1,
    },
    MuiInputBase: {
      input: {
        ...typography.body1,

        // Anything smaller than 16px and iOS will zoom into the input field.
        ...(isIos
          ? {
              fontSize: '16px',
              lineHeight: '24px',
            }
          : undefined),
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: colors.grey_100,
        '&.Mui-focused': {
          backgroundColor: colors.grey_100,
        },
      },
    },
    MuiMenuItem: {
      root: typography.body1,
    },
    MuiTab: {
      textColorPrimary: {
        color: colors.off_black,
      },
      label: {
        ...typography.body1,
        fontWeight: 500,
        textTransform: 'none',
      },
    },
    MuiFormHelperText: {
      root: {
        color: colors.orange_500,
      },
    },
  },
  spacing: spacingUnit,
});

const ThemeProvider = ({ children }) => (
  <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
);

export default ThemeProvider;
