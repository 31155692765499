import { set } from 'timm';

import { API_URL } from '../api';
import { getSite, getToken } from '../api/selectors';
import { formatRoute } from '../routing/utils';
import { labOrgGroups } from './constants';
import { dist } from './utils';

export const getLabs = (state) => state.referrals.labs;
export const getRegion = (state) => state.referrals.region;
export const getLoading = (state) => state.referrals.loading;
export const getPosting = (state) => state.referrals.posting;
export const getFindingUserPosition = (state) =>
  state.referrals.findingUserPosition;

export const getPreviewedLab = (state) =>
  (state.referrals.previewedLab &&
    state.referrals.labs &&
    state.referrals.labs[String(state.referrals.previewedLab)]) ||
  undefined;

export const getReferralDocumentUrl = (state, caseId, messageId) => {
  const token = getToken(state);
  const site = getSite(state);
  const url = `${API_URL}/api/v1/cases/${caseId}/referrals?id=${messageId}&request=file&access_token=${token}&site=${site}`;
  return url;
};

export const getReferralId = ({
  referrals: {
    results: { referralId },
  },
}) => referralId;

export const getSharedResults = (state) => {
  const {
    referrals: {
      results: { shared, caseId, referralId },
    },
  } = state;

  if (!caseId || !referralId) return null;

  if (!shared) return shared;

  return shared.map((batch) =>
    set(
      batch,
      'fileUrl',
      API_URL +
        formatRoute('/api/v1/cases/(:caseId)/referrals', {
          caseId,
          id: referralId,
          request: 'resultfile',
          fileId: batch.fileId,
          access_token: getToken(state),
          site: getSite(state),
        })
    )
  );
};

export const getLabsByLabOrgGroup = (state, labOrgGroup) => {
  const labs = state.referrals.labs;
  if (!labs) return undefined;

  // $FlowFixMe
  return Object.keys(labs)
    .map((key) => labs[key]) // https://github.com/facebook/flow/issues/2221#issuecomment-366519862
    .filter((lab) => lab.labOrgGroup === labOrgGroup);
};

export const getNearestMDK = (state, maxMeters = 50000) => {
  const region = getRegion(state);
  const labs = getLabsByLabOrgGroup(state, labOrgGroups.MDK);
  if (!region || !labs || labs.length === 0) {
    return undefined;
  }

  const nearestLab = labs.sort((a, b) => {
    const distA = dist(region.latitude, region.longitude, a.lat, a.lng);
    const distB = dist(region.latitude, region.longitude, b.lat, b.lng);
    return distA - distB;
  })[0];

  const shortestDistance = dist(
    region.latitude,
    region.longitude,
    nearestLab.lat,
    nearestLab.lng
  );
  return shortestDistance <= maxMeters ? nearestLab : undefined;
};
