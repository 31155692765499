import React, { Component } from 'react';

import backIconSource from '../../../../images/icon/ic_back_white@3x.png';
import colors from '../../../common/colors';
import { Body1, Body3 } from '../../../common/components/typography';
import Img from '@mindoktor/pulse/src/utils/images/Img.web';

const styles = {
  bar: {
    backgroundColor: colors.grey_700,
  },

  inner: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 60,
    margin: '0 auto',
    paddingLeft: 20,
    paddingRight: 20,
  },

  back: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 40,
    height: 40,
    marginRight: 10,
    cursor: 'pointer',
  },

  backIcon: {
    display: 'block',
    width: 16 * (18 / 26),
    height: 18,
    padding: 0,
    margin: 0,
    marginRight: 10,
  },

  middle: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'hidden',
  },

  title: {
    textAlign: 'center',
    whiteSpace: 'nowrap',
    fontWeight: 500,
    color: 'white',
  },

  subtitle: {
    textAlign: 'center',
    whiteSpace: 'nowrap',
    color: 'white',
  },

  right: {
    width: 40,
    height: 40,
    marginLeft: 10,
  },
};

export default class Bar extends Component {
  render() {
    const { title, subtitle, onBack } = this.props;

    return (
      <div style={styles.bar}>
        <div style={styles.inner}>
          <div style={styles.back} onClick={onBack}>
            <Img src={backIconSource} style={styles.backIcon} />
          </div>

          <div style={styles.middle}>
            <Body1 style={styles.title}>{title}</Body1>

            <Body3 style={styles.subtitle}>{subtitle}</Body3>
          </div>

          <div style={styles.right} />
        </div>
      </div>
    );
  }
}
