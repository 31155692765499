import React from 'react';

import Snackbar from '@mindoktor/pulse/src/components/Snackbar';
import SnackbarList from '@mindoktor/pulse/src/components/SnackbarList';

import { NotificationWithId } from '../types';

interface Props {
  notifications: NotificationWithId[];
  onCloseNotification: (id: number) => void;
}

const NotificationList: React.FC<Props> = ({
  notifications,
  onCloseNotification,
}) => {
  return (
    <SnackbarList open={notifications.length > 0}>
      {notifications.map((notification) => (
        <Snackbar
          key={notification.id}
          type={notification.type}
          text={notification.text}
          onClose={() => onCloseNotification(notification.id)}
        />
      ))}
    </SnackbarList>
  );
};

export default NotificationList;
