import { _t } from '@mindoktor/patient-legacy/i18n';
import React from 'react';

import Text from './text';

// type Props = {
//   answer?: any,
//   error?: string,
//   errorMessage?: string,
//   hint?: string,
//   unit?: string,
//   update: (value?: any) => any,
//   next: () => any,
// };

const NumberComponent = ({
  answer,
  error,
  errorMessage,
  hint,
  unit,
  update,
  next,
}) => (
  <Text
    answer={answer}
    error={error}
    errorMessage={errorMessage}
    hint={hint || (unit && _t('formulary.question.unitsIn', { unit }))}
    single={true}
    numeric={true}
    update={update}
    next={next}
  />
);
export default NumberComponent;
