import { getIn } from 'timm';

// Temporary State type, will refine it in a global types.js file
// type State = {
//   messages: MessagesState,
// };

export const getMarkNewForCase = (state, caseId) =>
  getIn(state, ['messages', 'markNewSince', caseId]);

export const getActiveMessagesScreenSince = (state) =>
  getIn(state, ['messages', 'activeCaseIdSince']);
