import { _t } from '@mindoktor/patient-legacy/i18n';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { fetchFreePass, submitFreePass } from '../../../state/freepass/actions';
import regions from '../../../state/freepass/regions';
import { createCase } from '../../../state/formulary/actions';
import { fetchCases } from '../../../state/cases/actions';

import { goBack, showModal } from '../../routing';

import { Body1 } from '../../../common/components/typography';
import Button from '../../components/button/button';
import Markdown from '../../components/markdown';
import BoxedScreen from '../../components/boxed_screen';
import BackBar from '../../components/bar/back';
import DropdownInput from '../../components/input/dropdown';
import TextInput from '../../components/input/text';
import Link from '../../components/link';
import HelpIcon from '@material-ui/icons/Help';

import FreePassInfoModal from '../components/freepass_info_modal';

import ErrorMessage from '../components/error_message';
import { getFormularyState } from '../../../state/formulary/selectors';
import { openRoute, routes } from '../../../state/routing';

const regionOptions = regions.map((region) => ({
  label: region.short,
  value: region.region,
}));

const styles = {
  body: {
    padding: 20,
  },

  bodyInner: {
    maxWidth: 600,
  },

  section: {
    marginBottom: 20,
  },

  label: {
    fontWeight: 500,
    marginBottom: 10,
  },

  error: {
    marginTop: 5,
  },

  dropdown: {
    marginBottom: 10,
  },

  footer: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 20,
  },

  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    maxWidth: 820,
    marginLeft: 'auto',
  },

  removeButton: {
    flex: 0.5,
    marginLeft: 10,
    marginRight: 10,
  },

  submitButton: {
    flex: 0.5,
    marginLeft: 10,
    marginRight: 10,
  },

  infoText: {
    marginBottom: 24,
  },

  infoLink: {
    marginBottom: 32,
  },
  infoLinkIcon: {
    marginLeft: 10,
    marginBottom: -8,
    width: 32,
    height: 32,
  },
};

// type Props = {
//   number?: string,
//   issuer?: string,
//   expires?: string,
//   hasError?: boolean,
//   errorMessage?: string,
//   formularyKey?: string,
//   submitImmediately?: boolean,

//   fetchFreePass: typeof fetchFreePass,
//   submitFreePass: typeof submitFreePass,
//   createCase: typeof createCase,
//   fetchCases: typeof fetchCases,
// };

// type State = {
//   loaded?: boolean,
//   submitting?: boolean,
//   number?: string,
//   issuer?: string,
//   expires?: string,
//   hasError?: boolean,
//   errorMessage?: string,
//   showEFreePassInfo: boolean,
// };

export class FreePassScreen extends Component {
  state = {
    loaded: false,
    submitting: false,
    number: undefined,
    issuer: undefined,
    expires: undefined,
    hasError: undefined,
    errorMessage: undefined,
    showEFreePassInfo: false,
  };

  load = async () => {
    const { fetchFreePass } = this.props;

    await fetchFreePass();

    const { number, issuer, expires } = this.props;

    this.setState({
      loaded: true,
      number,
      issuer,
      expires,
    });
  };

  remove = async () => {
    const { submitFreePass } = this.props;

    this.setState({ submitting: true });

    const ok = await submitFreePass({});

    if (ok) {
      goBack();
    } else {
      this.setState({ submitting: false });
    }
  };

  submit = async () => {
    const {
      fetchCases,
      submitFreePass,
      formularyKey,
      dynamicCode,
      openRoute,
      createCase,
      submitImmediately,
    } = this.props;
    const { number, issuer, expires } = this.state;

    if (!number && !issuer && !expires) {
      return;
    }

    this.setState({ submitting: true });

    const ok = await submitFreePass({
      number,
      issuer,
      expires,
    });

    if (ok) {
      if (submitImmediately) {
        const { caseId, error } = await createCase({
          formularyKey,
          dynamicCode,
        });

        if (!error) {
          // Fetch cases to repopulate the state with the newly created case
          await fetchCases();
          openRoute(routes.CONTACT_INFO_VERIFICATION_GUIDE_CONFIRMATION, {
            caseId,
          });
        } else {
          this.setState({ submitting: false });
        }
      } else {
        goBack();
      }
    } else {
      const { hasError, errorMessage } = this.props;

      this.setState((state) => ({
        ...state,
        submitting: false,
        hasError,
        errorMessage,
      }));
    }
  };

  clearError = () =>
    this.setState((state) => ({
      ...state,
      hasError: false,
      errorMessage: undefined,
    }));

  onIssuerChange = (issuer) => {
    this.setState((state) => ({ ...state, issuer }));
    this.clearError();
  };

  onNumberChange = (number) => this.setState((state) => ({ ...state, number }));

  onExpiresChange = (expires) =>
    this.setState((state) => ({ ...state, expires }));

  componentDidMount() {
    this.load();
  }

  showFreePassInfoModal = () => showModal(FreePassInfoModal);

  render() {
    const { number: existingNumber, submitImmediately, editable } = this.props;

    const {
      loaded,
      submitting,
      number,
      issuer,
      expires,
      hasError,
      errorMessage,
    } = this.state;

    if (!loaded) return null;

    const filledOut = !!(number && issuer && expires);

    return (
      <BoxedScreen
        header={<BackBar title={_t('previewPayment.freePass')} />}
        body={
          <div style={styles.body}>
            <div style={styles.bodyInner}>
              <div style={styles.section}>
                <Markdown
                  style={styles.infoText}
                  source={_t('freePass.infoText')}
                />

                <div style={styles.infoLink}>
                  <Link onClick={this.showFreePassInfoModal}>
                    {_t('faq.freePass.id.title')}
                    <HelpIcon style={styles.infoLinkIcon} />
                  </Link>
                </div>

                {hasError && (
                  <ErrorMessage
                    message={errorMessage}
                    trigger={'unknown'}
                    includeUnknowns={true}
                    style={styles.error}
                  />
                )}
              </div>

              <div style={styles.section}>
                <Body1 style={styles.label}>
                  {_t('freePass.issuedByCounty')}
                </Body1>

                <DropdownInput
                  value={issuer}
                  onChange={this.onIssuerChange}
                  multiple={false}
                  options={regionOptions}
                  style={styles.dropdown}
                  disabled={!editable}
                />

                <ErrorMessage
                  message={errorMessage}
                  trigger={'LNGFreePassInvalidIssuer'}
                  style={styles.error}
                />
              </div>

              <div style={styles.section}>
                <Body1 style={styles.label}>{_t('freePass.freePassId')}</Body1>

                <TextInput
                  placeholder={_t('freePass.freePassIdPlaceholder')}
                  single={true}
                  initialValue={number}
                  onChange={this.onNumberChange}
                  onFocus={this.clearError}
                  error={
                    !!errorMessage &&
                    errorMessage === 'LNGFreePassInvalidNumber'
                  }
                  disabled={!editable}
                />
                <ErrorMessage
                  message={errorMessage}
                  trigger={'LNGFreePassInvalidNumber'}
                  style={styles.error}
                />
              </div>

              <div style={styles.section}>
                <Body1 style={styles.label}>{_t('freePass.validity')}</Body1>

                <TextInput
                  placeholder={_t('freePass.validityPlaceholder')}
                  single={true}
                  initialValue={expires}
                  onChange={this.onExpiresChange}
                  onFocus={this.clearError}
                  error={
                    !!errorMessage &&
                    errorMessage ===
                      ('LNGFreePassInvalidExpires' || 'LNGFreePassExpired')
                  }
                  disabled={!editable}
                />
                <ErrorMessage
                  message={errorMessage}
                  trigger={'LNGFreePassInvalidExpires'}
                  style={styles.error}
                />
                <ErrorMessage
                  message={errorMessage}
                  trigger={'LNGFreePassExpired'}
                  style={styles.error}
                />
              </div>
            </div>
          </div>
        }
        footer={
          <div style={styles.footer}>
            <div style={styles.buttons}>
              {existingNumber && !submitting && (
                <Button
                  type="ghost"
                  color="black"
                  hero={true}
                  onClick={this.remove}
                  style={styles.removeButton}
                  disabled={!editable}
                >
                  {_t('common.delete')}
                </Button>
              )}

              <Button
                hero={true}
                disabled={submitting || !filledOut || !editable}
                onClick={this.submit}
                style={styles.submitButton}
              >
                {submitImmediately
                  ? _t('payment.cart.submit')
                  : _t('freePass.ok')}
              </Button>
            </div>
          </div>
        }
        showScrollGradient={true}
      />
    );
  }
}

function validIssuer(issuer) {
  if (!issuer) return false;
  return regions.filter((region) => region.region === issuer).length === 1;
}

export default connect(
  (state, props) => {
    const { formularyKey } = props;

    const { dynamicCode } = getFormularyState(state, formularyKey) || {};

    return {
      number: state.freePass.number,
      issuer: validIssuer(state.freePass.issuer)
        ? state.freePass.issuer
        : undefined,
      expires: state.freePass.expires,
      editable: state.freePass.editable,
      hasError: state.freePass.hasError,
      errorMessage: state.freePass.errorMessage,
      dynamicCode,
    };
  },
  { fetchFreePass, submitFreePass, createCase, fetchCases, openRoute }
)(FreePassScreen);
