import config from '@mindoktor/env/Config';
import { _t } from '@mindoktor/patient-legacy/i18n';
import React, { Component } from 'react';

import logoSource from '../../../../images/logo/heart_alone@2x.png';
import colors from '../../../common/colors';
import { Body3 } from '../../../common/components/typography';
import { getSiteOptions } from '../../utils/site';
import SimpleFooter from './simple';
import Img from '@mindoktor/pulse/src/utils/images/Img.web';

const styles = {
  root: {
    backgroundColor: colors.grey_100,
  },

  inner: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 960,
    margin: '0 auto',
    padding: 20,
  },

  logo: {
    display: 'block',
    width: 35,
    height: 27,
  },

  text: {
    marginLeft: 15,
    opacity: 0.5,
  },
};

export default class TruncatedFooter extends Component {
  render() {
    const { style } = this.props;

    return config.ShowSimpleFooter || getSiteOptions().showSimpleFooter ? (
      <SimpleFooter style={style} />
    ) : (
      <div style={{ ...styles.root, ...style }}>
        <div style={styles.inner}>
          <div>
            <Img src={logoSource} style={styles.logo} />
          </div>

          <div>
            <Body3 style={styles.text}>
              {_t('info.wetterhalsanFooter')} © {new Date().getFullYear()} MD
              International AB
            </Body3>
          </div>
        </div>
      </div>
    );
  }
}
