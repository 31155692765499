// This part of the state is what is considered contact information in the app
// export type ContactInformation = {|
//   address: string,
//   city: string,
//   email: string,
//   firstName: string,
//   lastName: string,
//   phone: string,
//   postalcode: string,
//   birthDate?: string,
//   isFemale?: boolean,
//   identityProtection?: boolean,
//   addressEditAllowed?: boolean,
// |};

// export type ContactInfoVerification = {
// info is like email or phone (attow) we keep a list of them
// because there could be multiple emails that are verified for the user
// at a given time. This is because of the contact info update flow, the flow
// requires to change the email with a verified email only. So if their
// email is already verified we don't wanna lose that verified info which means
// we are required to keep both old and new info in store.
// verifications: {
//   [info: string]: {
//     info: string,
//     isVerified: boolean,
//   },
// },
// this is used to send user back to where they were before verification flow
// redirectFunc: Function,
// the current info being verified
//   infoBeingVerified: {
//     info: string,
//     type: ContactInfoVerificationType,
//   },
//   sendInfoVerificationError: Error,
//   verifyInfoError: Error,
//   fetchInfoVerificationError: Error,
// };

// export type ContactInfoVerificationType = 'email' | 'phone';
// export type ContactInfoVerificationMethod = 'link' | 'code';

// export type FrenchDoctor = {
//   rppsNumber: string,
//   buildingName: string,
//   city: string,
//   country: string,
//   firstName: string,
//   lastName: string,
//   postalCode: string,
//   streetName: string,
//   streetNumber: string,
//   title: string,
//   connected?: boolean,
// };

// All the keys that are sent from the backend when the profile is fetched
// Using `+` to make properties read-only. Read more at
// https://flow.org/en/docs/frameworks/redux/#toc-typing-redux-state-immutability
// export type Profile = {
//   ...ContactInformation,
//   lat: number,
//   lng: number,
//   prefLang: string,
//   status: number,
//   timeZone: string,
//   userMarketingId: string,
// };

interface GuideParams {
  entrywayId: number;
  guideType: 'adult' | 'children';
}

export interface Invitation {
  id: number;
  revisitParentCaseId: number;
  status: string;
  opens: string; // UTC
  closes: string; // UTC,
  created: string; // UTC
  patientUUID: string;
  typeName: string;
  guideParams: GuideParams;
  isOpen: boolean;
  revisitParentEntrywayId: number;
  targetEntrywayId?: number;
  targetEntrywayName?: string;
  sourceCaseId: number;
  revisitChainHasOpenCase: boolean;
  doctorName: string;
  doctorSignature: string;
  isCaregiverAssigned: boolean;
  caseOwnerCanRepresentPatient: boolean;
}

export const InvitationType = {
  caregiverRevisit: 'caregiverRevisit', // A revisit invitation to the patient created by the caregiver. When accepted by the patient, a new case of the same type is created (new case id appended to the origin case)
  patientRevisit: 'patientRevisit', // A revisit invitation created by the system when a case is closed. When triggered by the patient a new case of the same type is created (new case id appended to the origin case)
  internalReferral: 'internalReferral', // An invitation to do another guide. Created by the doctor. Creates a new case when accepted. New does not get appended to the origin case.
};

export const InvitationStatus = {
  RevisitStatusActive: 'active',
  RevisitStatusConsumed: 'consumed',
  RevisitStatusExpired: 'expired',
  RevisitStatusCanceledByCareGiver: 'canceledByCaregiver',
  RevisitStatusCanceledByCaseOwner: 'canceledByCaseOwner',
};

// export type CardType = 'generic-id' | 'selfie';

// export type Ticket = {
//   id: number,
//   title: string,
//   conversationId: number,
//   assigneeId: number,
//   userId: number,
//   status: 'open' | 'closed',
//   numberOfUnreadMessages: number,
//   created: string,
// };

// The entire profile state
// export type ProfileState = Profile & {
//   invitationsById: {
//     [id: number]: Invitation,
//   },
//   invitationsByParentCaseId: {
//     [id: number]: number[],
//   },
//   connections?: Connection[], // undefined = not loaded yet
//   freePass: Object,
//   freePassError?: boolean,
//   pending: boolean,
//   loaded: boolean,
//   validation: {
//     errors: {
//       [key: string]: string,
//     },
//     valid: boolean,
//     pending: boolean,
//   },
//   tickets?: { [id: number]: Ticket },
//   contactInfoVerification: ContactInfoVerification,
// };

// type ConnectionType = 1;

export const connectionTypes = {
  child: 1,
};

// export type Connection = {
//   uuid?: string,
//   birthDate?: string,
//   firstName: string,
//   lastName: string,
//   connectedSSN?: string,
//   connectedSex?: string,
//   connectionType: ConnectionType,
//   verifiedIdentity?: boolean,
//   connectedDomainId?: number,
//   identityProtection?: boolean,
// };

export const CaseListItemTypes = {
  case: 'case',
  invitation: 'invitation',
  draft: 'draft',
  support: 'support',
};

// export type CaseListItem = {
//   type: $Keys<typeof CaseListItemTypes>,
//   payload: any,
//   timestamp: string,
// };

export const SSNTypePersonnummer = 1;

// export type SSNType =
//   | typeof SSNTypePersonnummer
