import React, { useState, useEffect } from 'react';

import { useAppDispatch } from '../../state/hooks';
import { makeStyles } from '@material-ui/core/styles';

import Screen from '../components/screen';
import Bar from '../menu/bar';
import VerifyByLinkResult from './verify_by_link_result';
import spacing from '../../common/spacing';
import { verifyContactInfoByLink } from '../../state/profile/actions';

const useStyles = makeStyles(() => ({
  component: {
    width: '100%',
    maxWidth: 800,
    paddingTop: spacing.s,
    paddingBottom: spacing.s,
    margin: '0 auto',
  },
}));

interface Props {
  type: string; // <-- This is not used, shall we remove it? Also from navigation?
  token: string;
}

const VerifyByLinkScreen: React.FC<Props> = ({ token }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [error, setError] = useState(false);
  const [info, setInfo] = useState('');
  const [firstName, setFirstName] = useState('');
  const [verified, setVerified] = useState(false);

  // on mount
  useEffect(() => {
    const verify = async () => {
      let storedToken;
      try {
        storedToken = JSON.parse(localStorage.getItem('token') ?? '');
      } catch (err) {
        console.error('JSON.parse error:', err);
      }
      const isTokenValid = storedToken
        ? new Date(storedToken?.expiryDate).getTime() > new Date().getTime()
        : false;

      if (storedToken && isTokenValid) {
        setVerified(true);
      } else {
        localStorage.removeItem('token');
        const { json, error: errorFromResp } = await dispatch(
          verifyContactInfoByLink(token)
        );
        if (errorFromResp) {
          setError(true);
          return;
        }
        if (json) {
          setInfo(json.infoBeingVerified);
          setFirstName(json.firstName);
          const now = new Date();
          const expiryDate = now.setHours(now.getHours() + 1);
          localStorage.setItem('token', JSON.stringify({ token, expiryDate }));
        } else {
          setError(true);
        }
      }
    };
    verify();
  }, []);

  return (
    <Screen
      header={<Bar />}
      body={
        <div className={classes.component}>
          <VerifyByLinkResult
            successful={!error}
            firstName={firstName}
            info={info}
            verified={verified}
          />
        </div>
      }
    />
  );
};

export default VerifyByLinkScreen;
