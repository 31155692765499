import React, { ComponentProps, useCallback, useEffect } from 'react';

import ListView from '@mindoktor/pulse/src/components/ListView/web/ListView';
import { scrollViewStyle } from '@mindoktor/pulse/src/components/ScrollView/web';
import { rem } from '@mindoktor/pulse/src/styles/conversions';

import { useTranslation } from '@mindoktor/patient-app/localization/hooks/useTranslation';
import { useNotifications } from '@mindoktor/patient-app/notifications/hooks/useNotifications';

import { Message } from '../../api/models/conversation';
import MessageReadProvider from '../../contexts/MessageReadProvider';
import { useConversation } from '../../hooks/useConversation';
import { useConversationId } from '../../hooks/useConversationId';
import { useConversationSubscriber } from '../../hooks/useConversationSubscriber';
import LastReadMarker from '../LastReadMarker/LastReadMarker.web';
import MessageCardFactory from '../MessageCardFactory';

import { ConversationViewProps } from './types';

const spacing = '1rem';
const ConversationView: React.FC<ConversationViewProps> = ({
  conversationId,
}) => {
  const hookConversationId = useConversationId();
  const resolvedConversationId =
    hookConversationId !== null ? hookConversationId : conversationId;
  const {
    isError,
    messages,
    firstUnreadMessageId,
    scrollTargetKey,
    scrollPositionOption,
    setIsScrollBottom,
    markMessageAsRead,
  } = useConversation(resolvedConversationId);

  const t = useTranslation('messaging');
  const notifications = useNotifications();
  useEffect(() => {
    if (!isError) {
      return;
    }

    notifications.pushWarning(t('errors.getConversation'));
  }, [isError]);

  useConversationSubscriber(resolvedConversationId);

  const renderItem = useCallback(
    (message: Message) => (
      <>
        {message.id === firstUnreadMessageId && (
          <LastReadMarker paddingBottom={spacing} />
        )}
        <MessageCardFactory message={message} />
      </>
    ),
    [firstUnreadMessageId]
  );

  const onVisibilityChange = useCallback(
    (message: Message, isVisible: boolean) => {
      return isVisible && markMessageAsRead(message);
    },
    [markMessageAsRead]
  );

  const onScroll = useCallback(({ isBottom }: { isBottom: boolean }) => {
    setIsScrollBottom(isBottom);
  }, []);

  const onLand = useCallback(({ isBottom }: { isBottom: boolean }) => {
    setIsScrollBottom(isBottom);
  }, []);

  const keyExtractorFunction = useCallback(
    (message: Message) => message.id,
    []
  );

  return (
    <ListView
      spacing={spacing}
      height="100%"
      paddingX="0.75rem"
      paddingY="1rem"
      sx={scrollViewStyle()}
      items={messages}
      keyExtractor={keyExtractorFunction}
      scrollToItemTarget={scrollTargetKey}
      renderItem={renderItem}
      onVisibilityChange={onVisibilityChange}
      onScroll={onScroll}
      onLand={onLand}
      positionOffsetOnScroll={rem(0.5, null)}
      bottomDetectionPadding={rem(2, null)}
      scrollPositionOption={scrollPositionOption}
    />
  );
};

const ConversationViewWithMessageReadProvider: React.FC<
  ComponentProps<typeof ConversationView>
> = (props) => {
  const hookConversationId = useConversationId();
  const resolvedConversationId =
    hookConversationId !== null ? hookConversationId : props.conversationId;
  return (
    <MessageReadProvider conversationId={resolvedConversationId}>
      <ConversationView {...props} />
    </MessageReadProvider>
  );
};

export default ConversationViewWithMessageReadProvider;
