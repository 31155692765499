import { z } from 'zod';

export const QuestionAnswerSchema = z.object({
  question: z.string(),
  answer: z.string().optional(),
  valueList: z.array(z.string()).optional(),
});

export const JournalEntrySchema = z.object({
  questionsAnswers: z.array(QuestionAnswerSchema),
  signature: z.string(),
  signedAt: z.string().datetime(),
});

export const JournalSchema = z.object({
  displayName: z.string().optional(),
  /* Journal entries as a list of question and answers since the journal is a formulary. */
  journalEntries: z.array(JournalEntrySchema).optional(),
});

export const JournalResponseSchema = z.object({
  data: JournalSchema,
});
