// type Position = {
//   coords: { latitude: number, longitude: number },
// };

// Haversine formula from
// http://stackoverflow.com/questions/27928/calculate-distance-between-two-latitude-longitude-points-haversine-formula
export function dist(latitude1, longitude1, latitude2, longitude2) {
  const lat1 = parseFloat(latitude1);
  const lon1 = parseFloat(longitude1);
  const lat2 = parseFloat(latitude2);
  const lon2 = parseFloat(longitude2);

  const R = 6371000; // Radius of the earth in m
  const dLat = (lat2 - lat1) * (Math.PI / 180);
  const dLon = (lon2 - lon1) * (Math.PI / 180);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * (Math.PI / 180)) *
      Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in m
  return d;
}

export function getRegionWithLabs(userPosition, state) {
  const labs = state.referrals.labs;
  const nbrOfLabs = 3;

  if (!userPosition) {
    return { error: 'no position supplied to getRegionWithLabs' };
  }
  if (!labs) {
    return { error: 'no labs found in state when calling getRegionWithLabs' };
  }

  const { latitude, longitude } = userPosition.coords;
  const labIDs = Object.keys(labs);

  labIDs.sort((a, b) => {
    const distA = dist(latitude, longitude, labs[a].lat, labs[a].lng);
    const distB = dist(latitude, longitude, labs[b].lat, labs[b].lng);
    if (distA < distB) return -1;
    if (distA > distB) return 1;
    return 0;
  });

  const labIndex =
    nbrOfLabs - 1 < labIDs.length ? nbrOfLabs - 1 : labIDs.length;
  const furtherstLab = labs[labIDs[labIndex]];
  const padding = 0.1;
  const latitudeDelta = furtherstLab
    ? Math.abs(latitude - furtherstLab.lat) * (2 + padding)
    : 0;
  const longitudeDelta = furtherstLab
    ? Math.abs(longitude - furtherstLab.lng) * (2 + padding)
    : 0;

  return {
    region: {
      latitude,
      longitude,
      latitudeDelta,
      longitudeDelta,
    },
  };
}

export function decorateLabs(labs) {
  const decoratedLabs = {};

  Object.keys(labs).forEach((labId) => {
    const lab = labs[labId];
    decoratedLabs[labId] = {
      ...lab,
      coordinate: {
        latitude: lab.lat,
        longitude: lab.lng,
      },
    };
  });
  return decoratedLabs;
}
