import { _t } from '@mindoktor/patient-legacy/i18n';
import { MdClose, MdCloudDone } from '../docly-md-icons';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import colors from '../../../common/colors';
import { Body1 } from '../../../common/components/typography';
import { getSite, getToken } from '../../../state/api/selectors';
import { getCaseFileReferenceNumber } from '../../../state/cases/selectors';
import { getCachedFileUri } from '../../../state/files/selectors';
import { getFileUri } from '../../../state/files/utils';
import { showModal } from '../../routing';
import { getFileData } from '../../utils/files';
import AttachmentOverlay from '../attachment_overlay';
import IconButton from '../button/icon';
import ImageModal from '../image_modal';

const styles = {
  item: {
    marginBottom: 20,
    textAlign: 'center',
  },

  lastItem: {
    marginBottom: 0,
  },

  wrapper: {
    position: 'relative',
    maxWidth: '100%',
  },

  image: {
    display: 'block',
    padding: 0,
    margin: 0,
    maxWidth: '100%',
    borderRadius: 3,
    cursor: 'pointer',
  },

  video: {
    display: 'block',
    padding: 0,
    margin: 0,
    maxWidth: '100%',
    borderRadius: 3,
    backgroundColor: '#eee',
  },

  file: {
    backgroundColor: `${colors.green_500}20`,
    borderRadius: 3,
  },

  fileContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 200,
  },

  icon: {
    color: colors.green_500,
    width: 80,
    height: 80,
  },

  working: {
    marginBottom: 0,
    color: colors.green_500,
  },

  remove: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
};

// type ItemProps = {
//   id: number,
//   imageUri: string,
//   largeImageUri: string,
//   videoUri?: string,
//   videoType?: string,
//   onRemove: (id: number) => any,
//   style?: any,
// };

class Item extends Component {
  state = {
    imageUri: this.props.imageUri,
    imageLoaded: false,
    imageError: false,

    videoUri: this.props.videoUri,
    videoData: undefined,
    videoType: undefined,
    videoLoaded: false,
    videoError: !this.props.videoUri,
  };

  remove = () => {
    const { id, onRemove } = this.props;

    onRemove(id);
  };

  onImageLoaded = () => this.setState({ imageLoaded: true });

  onImageError = async () => {
    const { videoUri } = this.state;

    let videoData;
    let videoType;

    if (videoUri) {
      try {
        videoData = await getFileData(videoUri);
      } catch (e) {
        // ignore
      }
    }

    if (videoData) {
      const match = /^data:([^;]*)/i.exec(videoData);

      videoType = match && match[1];
    }

    this.setState({
      imageError: true,
      videoData,
      videoType,
      videoError: !videoData,
    });
  };

  onVideoLoaded = () => this.setState({ videoLoaded: true });

  onVideoError = () => this.setState({ videoError: true });

  showImageModal = () => {
    const { largeImageUri } = this.props;

    showModal(ImageModal, { src: largeImageUri });
  };

  render() {
    const { fileReferenceNumber, onRemove, style } = this.props;
    const {
      imageUri,
      imageLoaded,
      imageError,
      videoData,
      videoType,
      videoLoaded,
      videoError,
    } = this.state;

    let fileView;

    if (!imageError) {
      fileView = (
        <img
          src={imageUri}
          style={styles.image}
          onLoad={this.onImageLoaded}
          onError={this.onImageError}
          onClick={this.showImageModal}
        />
      );
    } else if (!videoError) {
      fileView = (
        <video
          style={styles.video}
          onLoadedMetadata={this.onVideoLoaded}
          onError={this.onVideoError}
          controls
          playsInline={true}
          preload="auto"
        >
          <source src={videoData} type={videoType} />
        </video>
      );
    } else {
      fileView = (
        <div style={styles.file}>
          <div style={styles.fileContent}>
            <MdCloudDone style={styles.icon} />

            <Body1 style={styles.working}>
              {_t('formulary.upload.working')}
            </Body1>
          </div>
        </div>
      );
    }

    return (
      <div style={style}>
        <div
          style={{
            ...styles.wrapper,
            minHeight: imageLoaded || videoLoaded ? 0 : 200,
            display: imageLoaded || videoLoaded ? 'inline-block' : 'block',
          }}
        >
          <AttachmentOverlay referenceNumber={fileReferenceNumber}>
            {fileView}
          </AttachmentOverlay>

          {onRemove &&
            (imageLoaded || videoLoaded || (imageError && videoError)) && (
              <IconButton
                iconComponent={MdClose}
                color="white"
                size={50}
                onClick={this.remove}
                style={styles.remove}
              />
            )}
        </div>
      </div>
    );
  }
}

// type Props = {
//   files?: number[],
//   onRemove?: (fileId: number) => any,

//   getImageUri: (id: number) => string,
//   getLargeImageUri: (id: number) => string,
//   getVideoUri: (id: number) => ?string,

//   style?: any,
// };

export class Files extends Component {
  remove = (id) => {
    const { onRemove } = this.props;

    onRemove && onRemove(id);
  };

  render() {
    const {
      files,
      onRemove,
      getImageUri,
      getLargeImageUri,
      getVideoUri,
      getFileReferenceNumber,
      style,
    } = this.props;

    return (
      <div style={style}>
        {!!files &&
          files.map((id, i) => (
            <Item
              key={id}
              id={id}
              fileReferenceNumber={getFileReferenceNumber(id)}
              imageUri={getImageUri(id)}
              largeImageUri={getLargeImageUri(id)}
              videoUri={getVideoUri(id)}
              onRemove={onRemove && this.remove}
              style={{
                ...styles.item,
                ...(i === files.length - 1 ? styles.lastItem : undefined),
              }}
            />
          ))}
      </div>
    );
  }
}

export default connect((state, { caseId }) => {
  return {
    getImageUri: (id) => getFileUri(id, true, getToken(state), getSite(state)),
    getLargeImageUri: (id) =>
      getFileUri(id, false, getToken(state), getSite(state)),
    getVideoUri: (id) => getCachedFileUri(state, id),
    getFileReferenceNumber: (id) =>
      caseId ? getCaseFileReferenceNumber(state, caseId, id) : null,
  };
})(Files);
