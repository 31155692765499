import {
  nextQuestion,
  previousQuestion,
} from '../../common/inquisitions/utils';
import Config from '@mindoktor/env/Config';
import { postApi } from '../api/actions';
import { getInquisitor } from '../inquisitions/inquisitor';
import { getDraft, getQuestion } from './selectors';
import { USER_SURVEY_UPDATE } from './types';

export const updateSurvey = (id, draft) => (dispatch) => {
  dispatch({
    type: USER_SURVEY_UPDATE,
    payload: { id, draft },
  });
};

export const answerQuestion = (id, value, options) => (dispatch, getState) => {
  const question = getQuestion(getState(), id);

  if (!question) throw new Error('no question');

  const { visited = [], values = {} } = getDraft(getState(), id) || {};

  dispatch(
    updateSurvey(id, {
      visited,
      values: { ...values, [question.ref]: value },
      options,
    })
  );
};

export const goToPreviousQuestion = (id) => async (dispatch, getState) => {
  const previous = await previousQuestion(
    getDraft(getState(), id) || {},
    getInquisitor(id)
  );

  dispatch(updateSurvey(id, previous));
};

export const goToNextQuestion = (id) => async (dispatch, getState) => {
  const next = await nextQuestion(
    getDraft(getState(), id) || {},
    getInquisitor(id)
  );

  dispatch(updateSurvey(id, next));
};

export const fetchInquisitionJson = async (id) => {
  let response;
  let json;
  try {
    response = await fetch(
      `${Config.Urls.inquisitor.domain}/static/guides/${id}.json`,
      {
        mode: 'cors',
        credentials: 'omit',
      }
    );
    json = await response.json();
  } catch (e) {
    return { json: undefined, error: true };
  }

  return { json, error: response.status >= 400 };
};

export const postSurvey = (id, marketingUserId, survey) => async (dispatch) => {
  return await dispatch(
    postApi('/api/v1/usersurvey', {
      id,
      marketingUserId,
      answers: survey.answers,
    })
  );
};
