import React, { CSSProperties, ReactNode } from 'react';
import { _t } from '@mindoktor/patient-legacy/i18n';
import { connect, ConnectedProps } from 'react-redux';
import Modal from '../components/modal';
import Markdown from '../components/markdown';
import {
  Body1,
  fontFamilies,
  Title2,
} from '../../common/components/typography';
import colors from '../../common/colors';
import spacing from '../../common/spacing';
import { getSiteMedicalComplaints } from '../utils/site';
import { track } from '../../state/tracking/actions';
import { isHelpCenterEnabled } from '../../state/helpCenter/selectors';
import { profileIdentityProtection } from '../../state/profile/selectors';
import type { BaseStateType } from '../../state/types';
import { openRoute, routes } from '../../state/routing';
import { hideModal } from '../routing';
import fetchHelpCenterSupportUrl from '../../api/helpCenter/fetchHelpCenterSupportUrl';

import arrowIconSource from '../../../images/generated/arrow_listitem@4x.png';
import complaintsIconSource from '../../../images/icon/icon_care.svg';
import supportIconSource from '../../../images/icon/icon_support_black.svg';
import { apiHeadersSelector } from '../../api/api';
import { useAppSelector } from '../../state/hooks';
import Img from '@mindoktor/pulse/src/utils/images/Img.web';
import { CompatImgSrc } from '@mindoktor/pulse/src/utils/images/types';

const styles = {
  content: {
    padding: 0,
  },
  body: {
    marginBottom: spacing.t,
  },
  itemText: {
    fontFamily: fontFamilies.graphikBold,
    fontWeight: 500,
    color: colors.red_500,
    flexGrow: '1',
  },
  arrow: {
    marginTop: 4,
    height: spacing.xs,
    opacity: 0.33,
  },
  icon: {
    height: spacing.s,
    marginRight: spacing.xt,
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    textDecoration: 'none',
    padding: `${spacing.t}px 0`,
    marginBottom: spacing.t,
    cursor: 'pointer',
  } as CSSProperties,
  borderedItem: {
    borderBottom: `solid 1px ${colors.grey_200}`,
  },
  lastItem: {
    marginBottom: spacing.l,
  },
};

const connector = connect(
  (state: BaseStateType) => ({
    helpCenterEnabled: isHelpCenterEnabled(state),
    hasIdentityProtection: profileIdentityProtection(state),
  }),
  { track, openRoute }
);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface ItemProps {
  track: PropsFromRedux['track'];
  children: ReactNode;
  href?: string;
  onClick?: React.ComponentProps<'a'>['onClick'];
  iconSource: CompatImgSrc;
  style: React.CSSProperties;
}
const Item: React.FC<ItemProps> = ({
  children,
  track,
  href,
  iconSource,
  onClick,
  style,
}) => (
  <a
    onClick={(e) => {
      if (onClick) {
        onClick(e);
      }
      track('MedicalComplaintsModal.LinkClicked', { route: href });
    }}
    href={href}
    style={{ ...styles.item, ...style }}
  >
    <Img style={styles.icon} src={iconSource} />
    <Body1 style={styles.itemText}>{children}</Body1>
    <Img src={arrowIconSource} style={styles.arrow} />
  </a>
);

type Props = PropsFromRedux;
const MedicalComplaintsModal: React.FC<Props> = ({
  track,
  openRoute,
  hasIdentityProtection,
}) => {
  const apiHeaders = useAppSelector(apiHeadersSelector);

  const openSupportLink = async () => {
    const [helpCenterLink, err] = await fetchHelpCenterSupportUrl(
      apiHeaders,
      'new_request'
    );
    if (err) {
      console.error(err);
      return;
    }
    if (!helpCenterLink) {
      console.error('Found empty help center link: could not open page');
      return;
    }
    window.open(helpCenterLink, '_self');
  };

  const showHiddenIdentityPage = () => {
    openRoute(routes.IDENTITY_PROTECTION_SUPPORT);
  };

  const handleSupportCenterClick = async () => {
    if (hasIdentityProtection) {
      showHiddenIdentityPage();
      return;
    }
    await openSupportLink();
  };

  return (
    <Modal
      confirmButton={{
        text: _t('common.close'),
        onConfirm: () => {
          track('MedicalComplaintsModal.Closed');
          hideModal();
        },
      }}
      contentStyle={styles.content}
      body={
        <>
          <Title2 mb="s">{_t('medicalcomplaints.title')}</Title2>
          <Markdown style={styles.body} source={_t('medicalcomplaints.body')} />
          <Item
            track={track}
            href={getSiteMedicalComplaints()}
            iconSource={complaintsIconSource}
            style={styles.borderedItem}
          >
            {_t('medicalcomplaints.complain')}
          </Item>
          <Markdown source={_t('medicalcomplaints.forothercases')} />
          <Item
            track={track}
            onClick={handleSupportCenterClick}
            iconSource={supportIconSource}
            style={styles.lastItem}
          >
            {_t('medicalcomplaints.contactsupport')}
          </Item>
        </>
      }
    />
  );
};

export default connector(MedicalComplaintsModal);
