import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

import { SvgIconComponent as Icon } from '../../../icons/AppStoreLogo.svg';

const AppStoreLogoIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon inheritViewBox component={Icon} {...props} />
);

export default AppStoreLogoIcon;
