import { _t } from '@mindoktor/patient-legacy/i18n';
import isArray from 'lodash-es/isArray';
import React, { Component } from 'react';

import colors from '../../../common/colors';
import { Body1 } from '../../../common/components/typography';
import { hideModal, showModal } from '../../routing';
import Button from '../button/button';
import Link from '../link';
import Modal from '../modal';
import Option from './option';

const styles = {
  body: {
    marginLeft: -20,
  },

  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 8,
    paddingBottom: 8,
    borderBottom: '1px solid black',
    cursor: 'pointer',
  },

  disabled: {
    borderBottom: `1px solid ${colors.grey_300}`,
  },

  confirm: {
    marginBottom: 10,
  },

  value: {
    paddingRight: 40,
  },
};

// type OptionItem = {
//   label?: string,
//   value?: any,
// };

// type ListProps = {
//   options: OptionItem[],
//   multiple?: boolean,
//   onChange: (value?: any) => any,
// };

export class ListModal extends Component {
  state = {
    value: this.props.value,
  };

  onChange = (value) => {
    const { multiple } = this.props;
    const { value: previousValue } = this.state;

    if (multiple) {
      if (
        previousValue &&
        isArray(previousValue) &&
        previousValue.includes(value)
      ) {
        this.setState({ value: previousValue.filter((v) => v !== value) });
      } else {
        this.setState({
          value: [
            ...(previousValue && isArray(previousValue) ? previousValue : []),
            value,
          ],
        });
      }
    } else {
      this.setState({ value });
    }
  };

  confirm = () => {
    const { onChange } = this.props;
    const { value } = this.state;

    onChange(value);

    hideModal();
  };

  render() {
    const { options, multiple } = this.props;
    const { value } = this.state;

    return (
      <Modal
        body={
          <div style={styles.body}>
            {options.map((option, i) => (
              <Option
                key={i}
                label={option.label}
                checked={
                  multiple && isArray(value)
                    ? value.includes(option.value)
                    : option.value === value
                }
                multiple={multiple}
                onChange={() => this.onChange(option.value)}
                lastItem={i === options.length - 1}
              />
            ))}
          </div>
        }
        footer={
          <div>
            <Button color="black" onClick={this.confirm} style={styles.confirm}>
              {_t('common.done')}
            </Button>

            <Button color="black" type="flat" onClick={hideModal}>
              {_t('common.close')}
            </Button>
          </div>
        }
      />
    );
  }
}

// type Props = {
//   value?: any,
//   options: OptionItem[],
//   multiple?: boolean,
//   style?: any,
//   onChange?: (value?: any) => any,
// };

const getDisplayValue = (value, options) =>
  value
    ? (isArray(value) ? value : [value])
        .map((v) => (options.find((o) => o.value === v) || { label: v }).label)
        .join(', ')
    : _t('common.notSelected');

export default class Dropdown extends Component {
  showModal = () => {
    const { value, multiple, options, disabled } = this.props;

    if (disabled) return;

    showModal(ListModal, {
      multiple,
      options,
      value,
      onChange: this.onChange,
    });
  };

  onChange = (value) => {
    const { onChange } = this.props;

    onChange && onChange(value);
  };

  render() {
    const { options, value, style, disabled } = this.props;

    return (
      <div
        style={{
          ...styles.root,
          ...style,
          ...(disabled ? styles.disabled : undefined),
        }}
        onClick={this.showModal}
      >
        <Body1
          color={disabled || !value ? colors.grey_400 : colors.off_black}
          style={styles.value}
        >
          {getDisplayValue(value, options)}
        </Body1>
        {!disabled && (
          <Link onClick={this.showModal}>{_t('common.change')}</Link>
        )}
      </div>
    );
  }
}
