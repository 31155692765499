import React from 'react';

import Button from '@mui/material/Button/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import NavigationHeader from '@mindoktor/pulse/src/components/NavigationHeader/web';

import LegacyScreen from '@mindoktor/patient-app/adapters/components/LegacyScreen/web';
import BaseLayout from '@mindoktor/patient-app/layout/components/BaseLayout/web';
import { useTranslation } from '@mindoktor/patient-app/localization/hooks/useTranslation';
import { RESPONSIVE_SCREEN_WIDTH_BREAKPOINT } from '@mindoktor/patient-app/utils/device/constants';
import { useIsSmallScreen } from '@mindoktor/patient-app/utils/device/web';

const MD_CONTACT_EMAIL = 'privacy@mindoktor.se' as const;

const DeleteAccountInfo: React.FC = () => {
  const t = useTranslation('user', 'deleteAccount');
  return (
    <Stack spacing="1rem">
      <Typography variant="body1">
        {t('paragraph1')}{' '}
        <Typography variant="body1" component="span" fontWeight="medium">
          {MD_CONTACT_EMAIL}
        </Typography>
        .
      </Typography>
      <Typography variant="body1">{t('paragraph2')}</Typography>
      <Typography variant="body1">{t('paragraph3')}</Typography>
    </Stack>
  );
};

const ContactUs: React.FC = () => {
  const t = useTranslation('user', 'deleteAccount');
  return (
    <Stack
      direction="column"
      justifyContent="flex-end"
      alignItems="center"
      flexGrow="1"
    >
      <Button
        variant="contained"
        color="primary"
        onClick={() =>
          (window as Window).open(
            `mailto:${MD_CONTACT_EMAIL}?subject=${t('subject')}`,
            '_blank',
            'noopener'
          )
        }
      >
        {t('contactUs')}
      </Button>
    </Stack>
  );
};

const DeleteAccountScreen: React.FC = () => {
  const t = useTranslation('user', 'deleteAccount');
  const isSmallScreen = useIsSmallScreen();
  return (
    <LegacyScreen>
      <BaseLayout useFillHeight={true}>
        <Stack
          alignItems="center"
          marginTop="1rem"
          marginBottom={isSmallScreen ? '2rem' : '4rem'}
          marginX="1.5rem"
          flexGrow="1"
        >
          <Stack
            spacing={isSmallScreen ? '1rem' : '2rem'}
            maxWidth={RESPONSIVE_SCREEN_WIDTH_BREAKPOINT}
            flexGrow="1"
          >
            <NavigationHeader />
            <Typography variant="h2">{t('title')}</Typography>
            <Stack flex="1" spacing={isSmallScreen ? '0' : '4rem'}>
              <DeleteAccountInfo />
              <ContactUs />
            </Stack>
          </Stack>
        </Stack>
      </BaseLayout>
    </LegacyScreen>
  );
};
export default DeleteAccountScreen;
