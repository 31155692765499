import { formatRouteWithHash } from '../../state/routing';
import {
  DEFAULT_ROUTE,
  routes,
} from '@mindoktor/patient-app/routing/constants/webRoutes';

export const context =
  // : {
  // cachedRoute, // ?: { Component: any, props?: Object, options?: Object },
  // navigate, //?: (any, ?Object, ?Object) => any,
  // showModal, //?: (any, ?Object, options?: ShowModalOptions) => any,
  // hideModal, //?: () => any,
  // }
  {
    cachedRoute: undefined,
    navigate: undefined,
    showModal: undefined,
    hideModal: undefined,
  };

export const navigate = (Component, props, options) => {
  if (context.navigate) {
    context.navigate(Component, props, options);
  } else {
    context.cachedRoute = { Component, props, options };
  }
};

export const goHome = () => {
  window.location.href = formatRouteWithHash(DEFAULT_ROUTE);
};

export const goToAdultGuides = () => (window.location.href = '#/guides/adult');
export const goToChildGuides = () => (window.location.href = '#/guides/child');

export const goBack = () => window.history.back();

export const navigateToLogin = (route) => {
  return (window.location.href = formatRouteWithHash(routes.LOGIN, {
    state: route !== '' || window.location.hash.slice(1),
  }));
};

export const showModal = (Component, props, options) => {
  context.showModal && context.showModal(Component, props, options);
};

export const hideModal = () => context.hideModal && context.hideModal();
