import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';

import { ResponseError } from '@mindoktor/patient-app/api/responseError';

import { marketingConsentApi } from '../api/marketingConsent';
import {
  MarketingConsentPostRequest,
  MarketingConsentResponse,
} from '../api/models/marketingConsent';

import { MARKETING_CONSENT_QUERY_KEY } from './useMarketingConsentApi';

type MarketingConsentMutation = UseMutationResult<
  MarketingConsentResponse['data'] | null,
  ResponseError,
  MarketingConsentPostRequest
>;

export const useMarketingConsentMutator = (): MarketingConsentMutation => {
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: MarketingConsentPostRequest) => {
      const response = await marketingConsentApi.postMarketingConsent(payload);
      if (!response.ok) {
        throw new ResponseError(response);
      }
      return response.data?.data ?? null;
    },
    {
      onSuccess: async (data) => {
        queryClient.setQueryData<MarketingConsentResponse['data'] | null>(
          [MARKETING_CONSENT_QUERY_KEY],
          data
        );
      },
    }
  );
};
