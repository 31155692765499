import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import React from 'react';

import colors from '../../common/colors';
import Loader from '../../common/inquisitions/Loader';
import Screen from '../components/screen';
import BackgroundColor from './components/BackgroundColor';
import Loading from './components/Loading';

// export type LoadedScreenProps = LoadedProps & {
//   width: string,
// };

const withLoaderScreen = () => (Component) =>
  withWidth()((props) => (
    <Screen
      body={
        <>
          <Loader
            {...props}
            LoadedComponent={Component}
            LoadingComponent={Loading}
          />
          {isWidthUp('sm', props.width) && (
            <BackgroundColor color={colors.grey_100} />
          )}
        </>
      }
      style={{ backgroundColor: 'transparent' }}
    />
  ));

export default withLoaderScreen;
