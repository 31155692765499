import { useEffect } from 'react';
import { _t } from '@mindoktor/patient-legacy/i18n';
import { getConversationId } from '../../state/cases/selectors';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { openChatRoute } from '../utils/openChatRoute';
import { useNavigation } from '@mindoktor/patient-app/routing/web';
import { useTrackPageView } from '@mindoktor/patient-app/tracking/hooks/useTrackPageView';
import { MarketingTrackingActions } from '@mindoktor/patient-app/tracking/api/models/marketingTracking';
import { useMarketingTracking } from '@mindoktor/patient-app/tracking/hooks/useMarketingTracking';
import { fetchCases } from '../../state/cases/actions';
import { getRouteParam } from '../../state/routing/utils';
import { Keys } from '@mindoktor/patient-app/utils/types/objects';

/*
 This function was previously used as welcome screen after a
 a customer had posted a case. This screen is no longer used
 but is kept here as stepping stone to be able to still trigger 
 all events needed, in the same order as previously, and still track what happens.   
*/

const routeParams = {
  caseId: 'caseId',
  paymentMethod: 'paymentMethod',
} as const;

type RouteParams = Record<Keys<typeof routeParams>, string | undefined>;

// Props are correct but ESLint still complains
// eslint-disable-next-line react/prop-types
const DoneAlreadyVerifiedScreen: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigation = useNavigation();
  const marketingTracking = useMarketingTracking();
  const { caseId, paymentMethod } = useAppSelector((state) => {
    const caseId = getRouteParam<RouteParams>(state, routeParams.caseId);
    const paymentMethod = getRouteParam<RouteParams>(
      state,
      routeParams.paymentMethod
    );

    return {
      caseId,
      paymentMethod,
    };
  });
  useTrackPageView({ caseId });

  useEffect(() => {
    dispatch(fetchCases);
  }, []);

  useEffect(() => {
    if (!caseId) {
      console.warn('missing caseId');
      return;
    }

    if (!paymentMethod) {
      console.warn('missing payment method');
      return;
    }

    marketingTracking.track(
      MarketingTrackingActions.ContactInfoVerificationViewed,
      {
        label: 'Confirmation',
        additionalProperties: {
          paymentMethod,
        },
      }
    );
  }, [!!paymentMethod]);

  const conversationId = useAppSelector((state) => {
    return getConversationId(state, caseId);
  });

  useEffect(() => {
    openChatRoute(dispatch, navigation, caseId, conversationId);
  }, []);

  return null;
};

export default DoneAlreadyVerifiedScreen;
