// Based on https://www.figma.com/file/bDdD3XZuBgv8zx3aFfyzB1/Rebranding-exploration?node-id=2217%3A3222
// Note this currently mobile only and not responsive

const mobile = {
  none: 0,
  xxt: 4,
  xt: 8,
  t: 12,
  xs: 16,
  s: 24,
  m: 32,
  l: 48,
  xl: 64,
  xxl: 64,
};
const spacing = mobile;

const getSpacing = (key, value) =>
  value && spacing.hasOwnProperty(value) ? { [key]: spacing[value] } : {};

export const getSpacingStyle = (props) => {
  const { mt, mb, ml, mr } = props;

  return {
    ...getSpacing('marginTop', mt),
    ...getSpacing('marginBottom', mb),
    ...getSpacing('marginLeft', ml),
    ...getSpacing('marginRight', mr),
  };
};

export default spacing;
