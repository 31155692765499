import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from '@mindoktor/patient-legacy/src/common/compose';

import {
  loadFormularyByKey,
  nextQuestion,
  updateFormulary,
} from '../../../state/formulary/actions';
import { getFormularyState } from '../../../state/formulary/selectors';
import { lastVisibleQuestion } from '../../../state/formulary/utils';
import BackBar from '../../components/bar/back';
import FadeTransition from '../../components/transition/fade';
import FormularyFooter from '../../formulary/footer';
import FormularyQuestion from '../../formulary/question';
import QuestionView from '../components/question_view';
import { goBack } from '../../routing';
import { withTrackPageView } from '@mindoktor/patient-app/tracking/HOC/withTrackPageView';
import { getQuestionsTrackingDataFromProps } from '../getTrackingDataFromProps';

import { withRouting } from '@mindoktor/patient-app/routing/HOC/withRouting.web';

// type Props = {
//   childUuid?: string,
//   index?: string,

//   formularyKey?: string,

//   currentNode?: Question,
//   nextNode?: Question,
//   hasErrors?: boolean,

//   formulary?: Formulary,
//   answers?: Object,
//   visibility?: { [string]: boolean },
//   errors?: { [string]: string },

//   nextQuestion: typeof nextQuestion,
//   loadFormularyByKey: typeof loadFormularyByKey,
//   updateFormulary: typeof updateFormulary,
// };

export class EditScreen extends Component {
  state = {
    loaded: false,
    submitting: false,
  };

  load = async () => {
    const { childUuid, index, formularyKey, loadFormularyByKey } = this.props;

    const { error } = await loadFormularyByKey({
      childUuid,
      index,
      formularyKey,
      skipOptionalQuestions: true,
    });

    this.setState({ loaded: !error });
  };

  next = async () => {
    const {
      formularyKey,
      nextNode,
      hasErrors,
      updateFormulary,
      nextQuestion,
      answers,
      visibility,
      formulary,
      childUuid,
      index,
      loadFormularyByKey,
    } = this.props;

    const { submitting } = this.state;

    if (submitting) return;

    if (nextNode) {
      nextQuestion({ formularyKey: formularyKey });
    } else {
      this.setState({ submitting: true });

      // load (or actually set) formulary with skipOptions to false
      await loadFormularyByKey({
        childUuid,
        index,
        formularyKey,
        skipOptionalQuestions: false,
      });

      // While editing the questions we have changed the formulary state index.
      // Now is the time to reset it to the last visible question so that when pressing
      // "back to form" (Frågeformulär) we go back to the last question.
      const last = lastVisibleQuestion(
        {
          submittedAnswers: answers,
          visibility,
        },
        formulary
      );

      if (!hasErrors) {
        await updateFormulary({ formularyKey, answers, index: last.index });
      }

      this.setState({ submitting: false });

      goBack();
    }
  };

  componentDidMount() {
    const { trackPageView } = this.props;
    trackPageView(getQuestionsTrackingDataFromProps(this.props));
    this.load();
  }

  render() {
    const { formularyKey, currentNode } = this.props;
    const { loaded } = this.state;

    if (!loaded) return null;

    return (
      <FadeTransition
        transitionKey={currentNode.index}
        afterTransition={window.scroll}
      >
        <QuestionView
          header={<BackBar />}
          content={
            <FormularyQuestion
              formularyKey={formularyKey}
              node={currentNode}
              next={this.next}
            />
          }
          footer={
            <FormularyFooter
              formularyKey={formularyKey}
              node={currentNode}
              next={this.next}
            />
          }
        />
      </FadeTransition>
    );
  }
}

const enhance = compose(
  withTrackPageView,
  withRouting,
  connect(
    (state, props) => {
      const {
        routing: { queryParams },
      } = props;

      const formularyKey = queryParams.get('formularyKey');
      const index = queryParams.get('index');

      const {
        formulary,
        errors,
        visibility,
        answers,
        current: currentNode,
        next: nextNode,
      } = getFormularyState(state, formularyKey) || {};

      return {
        formulary,
        errors,
        visibility,
        answers,
        currentNode,
        nextNode,
        hasErrors: !!errors && !!Object.keys(errors).length,
        formularyKey,
        index,
      };
    },
    { nextQuestion, loadFormularyByKey, updateFormulary }
  )
);

export default enhance(EditScreen);
