// @ts-check

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';

import colors from '../../common/colors';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    position: 'relative',
  },

  referenceNumber: {
    position: 'absolute',
    top: spacing(0.5),
    left: spacing(0.5),
    backgroundColor: colors.red_500,
    borderRadius: 3,
    pointerEvents: 'none',
    color: colors.white,
    width: 32,
    height: 32,
    lineHeight: '32px',
    textAlign: 'center',
    fontSize: 16,
  },
}));

/**
 * @param {object} props
 * @param {JSX.Element} props.children
 * @param {number} props.referenceNumber
 */
const AttachmentOverlay = ({ referenceNumber, children }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {children}
      {referenceNumber && (
        <Typography variant="caption" className={classes.referenceNumber}>
          {referenceNumber}
        </Typography>
      )}
    </div>
  );
};

export default AttachmentOverlay;
