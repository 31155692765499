import { _t } from '@mindoktor/patient-legacy/i18n';
import React from 'react';

import iconLabReport from '../../../../../images/icon/icon_lab_report.svg';
import constants from '../../../../common/constants';
import routes from '../../../../state/routing/routes';
import { formatRoute } from '../../../../state/routing/utils';
import Button from '../../../components/button/button';
import Message from '../../components/message';
import Header from '../components/header';

const styles = {
  root: {
    marginBottom: constants.spacingCaseMessageItem,
  },
  buttons: {
    marginTop: constants.spacing12,
  },
};

const SharedReferralResultsItem = ({
  style,
  referralId,
  caseId,
  timestamp,
}) => (
  <Message style={{ ...styles.root, ...style }}>
    <Header
      title={_t('sharedReferralResultsItem.header')}
      icon={iconLabReport}
      timestamp={timestamp}
    />

    <div style={styles.buttons}>
      <Button
        color="black"
        href={
          '#' +
          formatRoute(routes.CASE_REFERRAL_RESULTS, {
            referralId,
            caseId,
          })
        }
      >
        {_t('sharedReferralResultsItem.button')}
      </Button>
    </div>
  </Message>
);

export default SharedReferralResultsItem;
