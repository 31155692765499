import React from 'react';
import { Transition } from 'react-spring';

// type Props = {
//   children: Object => React.Node,
//   from?: number,
//   to?: number,
//   visible: boolean,
//   native?: boolean,
// };

export default class FadeTransition extends React.Component {
  render() {
    const {
      props: { children, from = 0, to = 1, visible, native },
    } = this;

    return (
      <Transition
        native={native}
        from={{ opacity: from }}
        enter={{ opacity: to }}
        leave={{ opacity: from }}
      >
        {visible ? (style) => children(style) : null}
      </Transition>
    );
  }
}
