import { ActionTypes, SnackbarAction, SnackbarState } from './types';

export const initialState = null;

export default (
  state: SnackbarState = initialState,
  action: SnackbarAction
) => {
  switch (action.type) {
    case ActionTypes.SHOW_SNACKBAR: {
      return action.payload;
    }
    case ActionTypes.HIDE_SNACKBAR: {
      return null;
    }
    default:
      return state;
  }
};
