import Config from '@mindoktor/env/Config';
import dayjs /* , { type Dayjs } */ from 'dayjs';

import { LocaleSvSE } from '../intl/types';
import { SSNTypePersonnummer } from '../profile/types';

/**
 * Helper function to mark a function parameter as required.
 *
 * @description Used as a default param, which gets called if no value is provided.
 * If a required param is omitted, the function will be invoked and display an errors
 * to the developer. It will also return a given default value.
 *
 * Thanks to how React Native handles console.errors the error message will not be
 * displayed when in production.
 *
 * @example
 * const fn = (requiredParam: string = required('fn', 'requiredParam', null)) => {}
 *
 */
const required = (fn = 'a function', paramName = '', r) => {
  console.error(
    `You called ${fn} without one of its required params, ${paramName}`
  );
  return r;
};

/**
 * Helper function to warn the developer of a missing argument.
 *
 * @description Used as a deault param, which gets called if no values is provided to
 * to a function. If a parameter is omitted, the function will be invoked and display
 * a warning to the develop. It will also return a given default value.
 *
 * @example
 * const fn = (param = warnIfMissing('fn', 'param', false));
 */
const warnIfMissing = (fn, param, r) => {
  try {
    // Throw an error to collect the stack trace. This will be used to give context to the user.
    throw new Error();
  } catch (e) {
    console.warn(
      `You called ${fn} without ${param} parameter. This might cause unexpected behavior. The stack trace is included below.`
    );
    console.warn(e);
  }

  return r;
};

export const validateString = (value) => typeof value === 'string';
export const validateNumber = (value) => typeof value === 'number';

/**
 * Validation of phone numbers.
 *
 * Phone numbers can only consist of numbers, spaces and hyphens and must be
 * at least 8 chars.
 */
export const validatePhone = (value) => {
  return /^\+?[0-9\s-]{8,}$/g.test(value);
};

/**
 * Validation of personnummer (Swedish "Social Security Number").
 *
 * We require the year to be in the format 19?? or 20??. The dash (- or +)
 * is optional.
 */
// type SsnOptions = { now?: Dayjs, maxAge?: number, minAge?: number };

const passesLuhnCheck = (value) => {
  if (value.length < 2) {
    return false;
  }
  const checkStr = value.slice(2).replace(/[+-]/, '');
  const sum = checkStr
    .split('')
    .map(Number)
    .map((nr, i) => (i % 2 ? nr : nr * 2))
    .map((nr) => (nr > 9 ? nr - 9 : nr))
    .reduce((acc, nr) => acc + nr);

  return sum % 10 === 0;
};

const isValidDate = (now /* Dayjs */ = dayjs(), value) => {
  // note that we need strict parsing (the true arg) for this
  const date = dayjs(value.slice(0, 8), 'YYYYMMDD', true);
  if (!date.isValid()) return false;
  if (now.isBefore(date)) return false;
  return true;
};

const ageIsInValidRange = ({ now = dayjs(), maxAge, minAge }, value) => {
  const date = dayjs(value.slice(0, 8));
  const age = now.diff(date, 'year');

  if (maxAge && maxAge < age) return SSN_TOO_OLD;
  if (minAge && minAge > age) return SSN_TOO_YOUNG;
  return SSN_VALID;
};

export const isValidChildUser = (birthDate) => {
  const now = dayjs();
  const date = dayjs(birthDate);

  if (now.diff(date, 'year') <= Config.ChildMaxAge) {
    return true;
  }

  return false;
};

export const SSN_VALID = 'SSN_VALID';
export const SSN_INVALID = 'SSN_INVALID';
export const SSN_TOO_YOUNG = 'SSN_TOO_YOUNG';
export const SSN_TOO_OLD = 'SSN_TOO_OLD';

export const validateSsn = (value, options = {}, SsnType) => {
  const { now } = options;
  switch (SsnType) {
    case SSNTypePersonnummer:
    // fallthrough
    case undefined:
      // assume swedish by default
      const ssnRegEx = /^(?:19|20)\d{6}[+-]?\d{4}$/;

      const isValidSsn =
        ssnRegEx.test(value) &&
        passesLuhnCheck(value) &&
        isValidDate(now, value);
      if (!isValidSsn) {
        return SSN_INVALID;
      }
      return ageIsInValidRange(options, value);
    default:
      return SSN_VALID;
  }
};

// This uses the html 5 spec regex mentioned here:
// https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address. We use the same regex for
// all email validation. It is used in our backend and clinic/patient/kiosk frontends. DO NOT CHANGE
// IT. If it is good enough for html 5 browsers, it should be good enough for us.
export const validateEmail = (value) => {
  const regExp =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  return regExp.test(value);
};

/**
 * Validate if the given field is a zip code / postal code.
 *
 * Zip codes must have 5 numbers and an optional space between the
 * 3rd and 4th number.
 *
 * Valid formats: 12345, 123 45
 */
export const validateZip = (value, locale) => {
  switch (locale) {
    case LocaleSvSE:
    // fallthrough
    case undefined:
      // assume swedish by default
      return /([0-9]{3}\s?([0-9]{2}))/.test(value);
    default:
      return true;
  }
};

export const requiredField = (value) => {
  // If value is neither string nor number, it is not valid
  if (!validateString(value) && !validateNumber(value)) {
    return false;
  }

  // 0 is also a valid value
  return !!value || value === 0;
};

export const minLength = (
  value,
  test = warnIfMissing('minLenght', 'test', 0)
) => (typeof value === 'string' ? value.length >= test : false);

export const maxLength = (
  value,
  test = warnIfMissing('maxLenght', 'test', 999)
) => (typeof value === 'string' ? value.length <= test : false);

const validators = {
  text: validateString,
  number: validateNumber,
  phone: validatePhone,
  email: validateEmail,
  zip: validateZip,
  min: minLength,
  max: maxLength,
  ssn: validateSsn,
  required: requiredField,
  noop: (...args) => false, // eslint-disable-line no-unused-vars
};

// type ComplexValidation =
//   | { type: 'min' | 'max', value: number }
//   | { type: 'ssn', value: SsnOptions };

// type PrimitiveValidation =
//   | 'text'
//   | 'number'
//   | 'phone'
//   | 'email'
//   | 'zip'
//   | 'required'
//   | 'noop';

// export type ValidationTypes = PrimitiveValidation | ComplexValidation;

/**
 * Validation for form elements.
 *
 * @description To validate an element, call this function with the current value and the expected validation pattern.
 *
 * For simple validations the pattern can be string identifying it (eg 'text' or 'phone'). If the validation needs more
 * argments (eg minLength) the pattern should be an object with a `type` property defining the pattern (eg `min`) and a
 * `value` property specifying the needed argument(s) (eg `5` for `min`).
 *
 * The returned value will be either `true` (if the value conforms to the pattern) or `false` (if it does not).
 *
 * @example
 *
 * - `const valid = validate(target.value, 'text')`
 * - `const valid = validate(target.value, 'required')`
 * - `const valid = validate(target.value, {type: 'min', value: target.value })`
 * - `const valid = validate(target.value, {type: 'ssn', value: {minAge: 2, maxAge: 18 } })
 *
 */
export const validate = function (
  value = required('validate', 'value', 'noop'),
  type = required('validate', 'type', 'noop'),
  options
) {
  if (type === 'zip' && options?.locale) {
    return validateZip(value, options?.locale);
  }

  if (typeof type === 'string') {
    return validators[type](value);
  }

  if (type.type === 'ssn') {
    return validators[type.type](value, type.value);
  }

  return validators[type.type](value, type.value);
};

export default validate;
