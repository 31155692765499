import React, { useState } from 'react';

import { useTheme } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import colors from '../../../common/colors';
import { Body1 } from '../../../common/components/typography';

const styles = {
  root: {},

  link: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    width: 'calc(100% - 30px)',
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 16,
    paddingRight: 16,
    backgroundColor: 'white',
    borderRadius: 25,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.grey_300,
  },

  linkMobile: {
    paddingTop: 7,
    paddingBottom: 7,
  },

  linkSolid: {
    backgroundColor: colors.grey_200,
    borderColor: colors.grey_200,
  },

  linkWithError: {
    backgroundColor: `${colors.yellow_500}50`,
    borderColor: `${colors.yellow_500}50`,
  },

  linkHover: {
    backgroundColor: colors.grey_300,
    borderColor: colors.grey_300,
  },

  textWrapper: {
    flex: 1,
    overflow: 'hidden',
  },

  icon: {
    display: 'block',
    width: 21,
    height: 18,
    marginTop: 2.5,
    marginBottom: 3.5,
    marginLeft: 20,
  },
};

import arrowImageSource from '../../../../images/temp/redo.png';
import Img from '@mindoktor/pulse/src/utils/images/Img.web';

const UndoLink = (props) => {
  const { text, error, solid, children, onClick, style } = props;
  const [hover, setHover] = useState(false);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  const onMouseEnter = () => setHover(true);
  const onMouseLeave = () => setHover(false);

  return (
    <div style={{ ...styles.root, ...style }}>
      <div
        style={{
          ...styles.link,
          ...(solid && styles.linkSolid),
          ...(error && styles.linkWithError),
          ...(hover && !error && styles.linkHover),
          ...(mobile && styles.linkMobile),
          cursor: onClick ? 'pointer' : 'default',
        }}
        onClick={onClick}
        onMouseEnter={onClick && onMouseEnter}
        onMouseLeave={onClick && onMouseLeave}
      >
        <div style={styles.textWrapper}>
          {!!text && <Body1>{text}</Body1>}

          {children}
        </div>

        {!!onClick && <Img src={arrowImageSource} style={styles.icon} />}
      </div>
    </div>
  );
};

export default UndoLink;
