import withStyles from '@material-ui/core/styles/withStyles';
import { isWidthDown } from '@material-ui/core/withWidth';
import { _t } from '@mindoktor/patient-legacy/i18n';
import React from 'react';
import compose from '@mindoktor/patient-legacy/src/common/compose';

import Body from './components/Body';
import Close from './components/Close';
import FadeTransition from './components/FadeTransition';
import Fixed from './components/Fixed';
import Frame from './components/Frame';
import Header from './components/Header';
import Logo from './components/Logo';
import PreviousLink from './components/PreviousLink';
import ScrollIndicator from './components/ScrollIndicator';
import CancelModal from './modals/CancelModal';
import Question from './question/Question';
import withLoaderScreen from './withLoaderScreen';

import { withRouting } from '@mindoktor/patient-app/routing/HOC/withRouting.web';
import { routes } from '@mindoktor/patient-app/routing/constants/webRoutes';
import { routes as legacyRoutes } from '../../state/routing';

class InquisitionScreen extends React.Component {
  state = {
    cancelModal: false,
  };

  transitionRef = React.createRef();
  transition = async () =>
    this.transitionRef.current && this.transitionRef.current.transition();

  openCancelModal = () => this.setState({ cancelModal: true });
  closeCancelModal = () => this.setState({ cancelModal: false });

  cancel = () => {
    const {
      routing: {
        navigation: { navigate },
      },
    } = this.props;

    navigate(routes.GUIDES, { entrywayId: 'adult' });
  };

  update = (value) => {
    const { setDraftValue } = this.props;

    setDraftValue(value);
  };

  previous = async () => {
    const {
      query,
      first,
      popDraft,
      routing: {
        navigation: { navigate },
      },
    } = this.props;

    if (first) {
      navigate(routes.GUIDES_PRESTART, query);
    } else {
      await this.transition();

      popDraft();
    }
  };

  done = async (value) => {
    const { pushDraft } = this.props;

    if (value !== undefined) {
      this.update(value);
    }

    await this.transition();

    pushDraft();
  };

  redirect = ({ abort }) => {
    const { query, openRoute } = this.props;

    openRoute(
      abort
        ? legacyRoutes.INQUISITION_ABORT
        : legacyRoutes.INQUISITION_COMPLETE,
      query,
      { replace: true }
    );
  };

  UNSAFE_componentWillReceiveProps({ result }) {
    const { result: prevResult } = this.props;

    if (!prevResult && result) this.redirect(result);
  }

  componentDidMount() {
    const { result } = this.props;

    if (result) this.redirect(result);
  }

  render() {
    const { question, value, first, width, classes } = this.props;
    const { cancelModal } = this.state;

    if (!question) return null;

    const frame = () => (
      <Frame>
        <Logo className={classes.logo} />

        <Question
          key={question.ref}
          value={value}
          question={question}
          update={this.update}
          done={this.done}
        />

        <ScrollIndicator />
      </Frame>
    );

    const previousLink = () => (
      <PreviousLink
        label={first ? _t('common.back') : _t('guides.previousQuestion')}
        onClick={this.previous}
      />
    );

    return (
      <>
        <Body>
          {isWidthDown('xs', width) ? (
            <>
              <Header>{previousLink()}</Header>

              <FadeTransition ref={this.transitionRef}>
                {frame()}
              </FadeTransition>

              <Fixed horizontal="right" vertical="top" spacing>
                <Close
                  onClick={this.openCancelModal}
                  className={classes.cancelFixed}
                />
              </Fixed>
            </>
          ) : (
            <FadeTransition ref={this.transitionRef}>
              <Header>
                {previousLink()}

                <Close onClick={this.openCancelModal} />
              </Header>

              {frame()}
            </FadeTransition>
          )}
        </Body>

        <CancelModal
          open={cancelModal}
          close={this.closeCancelModal}
          cancel={this.cancel}
        />
      </>
    );
  }
}

const enhance = compose(
  withRouting,
  withLoaderScreen(),
  withStyles(({ spacing }) => ({
    cancelFixed: {
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
    },

    logo: {
      marginBottom: spacing(3),
    },
  }))
);

export default enhance(InquisitionScreen);
