import { _t } from '@mindoktor/patient-legacy/i18n';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { fetchMedications } from '../../../state/medications/actions';
import Choice from './choice';

// type Props = {
//   medications?: { label: string, description: string }[],
//   answer?: any,
//   error?: string,
//   filter?: string,
//   other?: boolean,
//   update: (value?: any) => any,
//   next: () => any,
//   fetchMedications: typeof fetchMedications,
// };

// type State = {
//   options?: Option[],
// };

export class Medication extends Component {
  state = {};

  update = async (value) => {
    const { medications, update } = this.props;

    await update(
      value === 'other'
        ? value
        : medications && medications.find((m) => JSON.stringify(m) === value)
    );
  };

  componentDidUpdate(prevProps) {
    const { medications: previousMedications } = prevProps;
    const { medications, other } = this.props;

    if (previousMedications !== medications) {
      let options;

      if (medications) {
        options = medications.map((m, i) => ({
          index: String(i),
          label: m.label + (m.description ? ' (' + m.description + ')' : ''),
          value: JSON.stringify(m),
        }));

        if (other) {
          options.push({
            index: String(options.length),
            label: _t('formulary.question.medication.other'),
            value: 'other',
          });
        }
      }

      this.setState({ options });
    }
  }

  componentDidMount() {
    const { filter, fetchMedications } = this.props;

    fetchMedications(filter);
  }

  render() {
    const { answer, error, next } = this.props;
    const { options } = this.state;

    if (!options) return null;

    return (
      <Choice
        options={options}
        answer={
          answer !== undefined && answer !== 'other'
            ? JSON.stringify(answer)
            : answer
        }
        error={error}
        update={this.update}
        next={next}
      />
    );
  }
}

export default connect(
  (state, props) => ({ medications: state.medications[props.filter || ''] }),
  {
    fetchMedications,
  }
)(Medication);
