import { routes } from '../../state/routing';
import { navigate } from '../routing';
import CompletionScreen from './CompletionScreen';
import IntroScreen from './IntroScreen';
import SurveyScreen from './SurveyScreen';

const handlers = {};

handlers[routes.USER_SURVEY_INTRO] = ({ props }) => {
  navigate(IntroScreen, props);
};

handlers[routes.USER_SURVEY] = ({ props }) => {
  navigate(SurveyScreen, props);
};

handlers[routes.USER_SURVEY_COMPLETE] = ({ props }) => {
  navigate(CompletionScreen, props);
};

export default handlers;
