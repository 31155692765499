import { z } from 'zod';

export const FeatureFlagSchema = z.object({
  key: z.string(),
  enabled: z.boolean(),
});

export const FeatureFlagsSchema = z.object({
  data: z.array(FeatureFlagSchema),
});
