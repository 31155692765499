import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { ResponseError } from '@mindoktor/patient-app/api/responseError';

import { journalApi } from '../api/journal';
import { Journal } from '../api/models/journal';

type journalResult = UseQueryResult<Journal | null, ResponseError>;

export const JOURNAL_QUERY_KEY = 'Journal';

export const useJournalApi = (caseId: number | undefined): journalResult => {
  return useQuery<Journal | null, ResponseError>(
    [caseId, JOURNAL_QUERY_KEY],
    async () => {
      if (caseId == null) {
        return null;
      }

      const response = await journalApi.getJournalEntries(caseId);

      if (!response.ok) {
        throw new ResponseError(response);
      }

      if (response.data?.data == null) {
        return null;
      }

      return response.data.data;
    }
  );
};
