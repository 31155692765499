import React from 'react';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { colors } from '@mindoktor/pulse/src/styles/colors';

import BaseLayout from '@mindoktor/patient-app/layout/components/BaseLayout/web';
import { useTranslation } from '@mindoktor/patient-app/localization/hooks/useTranslation';
import { openExternalMinDoktorURL } from '@mindoktor/patient-app/utils/externalRouting/web';

import { downloadAppLink } from '../../../constants';
import InfoLink from '../../InfoLink/web';
import InjectionIcon from '../../InjectionIcon/web/InjectionIcon';
import NavigationHeaderMobile from '../../NavigationHeaderMobile/web';

const VaccinationBookMobile: React.FC = () => {
  const t = useTranslation('vaccinationBook', 'downloadPage');
  return (
    <BaseLayout>
      <Stack marginTop="1rem" marginX="1.5rem">
        <Stack spacing="1rem" marginBottom="3rem">
          <NavigationHeaderMobile />
          <InjectionIcon />
          <Typography variant="h2">{t('title')}</Typography>
          <Typography variant="body1">{t('paragraph1')}</Typography>
          <Typography variant="body1">{t('paragraph2')}</Typography>
          <Typography variant="body1">{t('paragraph3')}</Typography>
          <InfoLink />
        </Stack>
        <Stack spacing="1rem" marginX="0.1rem">
          <Button onClick={() => openExternalMinDoktorURL(downloadAppLink)}>
            {t('continueButton')}
          </Button>
          <Typography
            variant="body2"
            textAlign="center"
            color={colors.gray[700]}
          >
            {t('continueButtonDisclaimer')}
          </Typography>
        </Stack>
      </Stack>
    </BaseLayout>
  );
};

export default VaccinationBookMobile;
