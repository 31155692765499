import React from 'react';

import WebButton from '../../../web/components/button/button';

const Button = ({ onPress, children, type, style, disabled }) => {
  return (
    <WebButton
      onClick={onPress}
      children={children}
      type={type}
      style={style}
      disabled={disabled}
    />
  );
};

export default Button;
