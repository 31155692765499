export const identity = (route: Route) => {
  return route;
};

export const routes = {
  ROOT: 'root',

  LOGIN_PRIVACY_SETTINGS: '/login/privacy/settings',

  PAYMENT_ROOT: 'payment',

  DEV_DEVICE_INFO: '/account/deviceinfo',
  HOME: 'home',
  MEETTHEDOCTORS: '/meetthedoctors',
  FAQ: '/faq',
  FAQ_CATEGORY: '/faq-category',
  COMPLAINTS: '/complaints',
  CASES: 'cases',
  VIDEOCHAT: '/videochat/:caseId',
  CASE_MESSAGES: 'case/messages',
  CONVERSATION: '/cases/:caseId/conversation/:conversationId',
  CASE_ANSWERS: '/cases/:caseId/answers',
  CASE_SCHEDULER: 'case/scheduler',
  CASE_REFERRAL_RESULTS: 'case/:caseId/referrals/:referralId/results',
  DEV_PERMISSIONS: 'dev/permissions',
  APH_IN_STOCK_PHARMACIES: 'case/prescriptions/in-stock',
  MDKLINIKEN: 'mdkliniken',
  MDKLINIKEN_LOCATIONS: 'mdkliniken/locations',
  MDKLINIKEN_LOCATION_CLINIC: 'mdkliniken/location/clinic',
  MDKLINIKEN_LOCATION_PRESENCE: 'mdkliniken/location/presence',
  MDKLINIKEN_KIOSK_WEBVIEW: 'mdkliniken/kiosk',

  SUPPORT_TICKET_WEBVIEW: 'supportticket/webview',

  ACCOUNT: 'account',
  ACCOUNT_CONTACT: '/account/contact',
  ACCOUNT_DELETE: '/account/delete',
  ACCOUNT_CONTACT_VERIFY_EMAIL_SUCCESS: '/account/contact/verify/success',
  ACCOUNT_CONTACT_VERIFY_EMAIL: '/account/contact/verify',
  ACCOUNT_NOTIFICATIONS: '/account/notifications',
  GUIDE_CATEGORIES: 'guide/categories',
  GUIDE_LIST: 'guide/list',
  GUIDE_INFO: 'guide/info',
  GUIDE_CHILD_SELECT: 'guide/child/select',
  GUIDE_CHILD_ADD: 'guide/child/add',
  GUIDE_CHILD_INFANT: 'guide/child/infant',
  GUIDE_PRESTART: 'guide/prestart',
  GUIDE_BLOODPRESSURE: 'guide/bloodpressure',
  // GUIDE_QUESTIONS include formulary questions, healthprofile, and cart
  GUIDE_QUESTIONS: 'guide/questions',
  GUIDE_QUESTIONS_EDIT: 'guide/questions/edit',
  GUIDE_ABORT: 'guide/abort',
  GUIDE_FREEPASS: 'guide/freepass',

  INQUISITION: 'inquisition',
  INQUISITION_ABORT: 'inquisition/abort',
  INQUISITION_COMPLETE: 'inquisition/complete',

  HEALTHPROFILE: 'healthprofile',
  HEALTHPROFILE_EDIT: 'healthprofile/edit',

  PAYMENT_CONFIRMATION: 'payment/confirmation',

  VACCINATION_BOOK: 'vaccinationbook',
  VACCINATION_ADD_PRODUCT: 'vaccinationbook/add-product',
  VACCINATION_ADD_TAKENDATE: 'vaccinationbook/add-takendate',
  VACCINATION_ADD_DOSAGE: 'vaccinationbook/add-dosage',
  VACCINATION_ADD_REMINDER: 'vaccinationbook/add-reminder',
  VACCINATION_ADD_OWN_PRODUCT: 'vaccinationbook/add-vaccine',

  // Tab navigation
  HOME_TAB: 'HomeTab',
  CASES_TAB: 'CasesTab',
  ACCOUNT_TAB: 'AccountTab',

  USER_SURVEY_INTRO: 'user-survey',
  USER_SURVEY: 'user-survey/survey',
  USER_SURVEY_COMPLETE: 'user-survey/complete',

  CONTACT_INFO_VERIFICATION_VERIFY: 'contactinfo/verify',
  CONTACT_INFO_VERIFICATION_SUCCESS: 'contactinfo/success',

  GUIDE_CONFIRMATION_ALREADY_VERIFIED: 'guides/verification/confirmation',
  GUIDE_CONFIRMATION_UNVERIFIED: 'guides/verification/unverified',
  GUIDE_VERIFICATION: 'guides/verification/verify',
  GUIDE_VERIFICATION_DONE: 'guides/verification/done',

  JOURNAL: '/journal',
  JOURNAL_NOTE: '/cases/:caseId/journal/note',
} as const;

export type RouteName = keyof typeof routes;
export type Route = (typeof routes)[RouteName];
