// Why does this file exist?
// because all the icons in the `react-icons/md` module got imported
// resulting in 85 KB gzip for a dosen svg icons.
// Expect these to be replaced by either @material-ui/icons or custom icons.

import { GenIcon, IconBaseProps } from 'react-icons/lib';

// These are the ones we use, in alphabetical order:
// MdArrowDropDown
// MdArrowDropUp
// MdBuild
// MdCallEnd
// MdChildCare
// MdClose
// MdCloudDone
// MdCreditCard
// MdKeyboardArrowDown
// MdMenu
// MdMic
// MdMicOff
// MdMood
// MdPayment
// MdPhoto
// MdPowerSettingsNew
// MdSecurity
// MdVideocam
// MdVideocamOff

export const MdArrowDropDown = function (props: IconBaseProps) {
  return GenIcon({
    tag: 'svg',
    attr: { viewBox: '0 0 24 24' },
    child: [{ tag: 'path', attr: { d: 'M7 10l5 5 5-5z' }, child: [] }],
  })(props);
};
MdArrowDropDown.displayName = 'MdArrowDropDown';

export const MdArrowDropUp = function (props: IconBaseProps) {
  return GenIcon({
    tag: 'svg',
    attr: { viewBox: '0 0 24 24' },
    child: [{ tag: 'path', attr: { d: 'M7 14l5-5 5 5z' }, child: [] }],
  })(props);
};
MdArrowDropUp.displayName = 'MdArrowDropUp';

export const MdBuild = function (props: IconBaseProps) {
  return GenIcon({
    tag: 'svg',
    attr: { viewBox: '0 0 24 24' },
    child: [
      {
        tag: 'path',
        attr: {
          d: 'M22.7 19l-9.1-9.1c.9-2.3.4-5-1.5-6.9-2-2-5-2.4-7.4-1.3L9 6 6 9 1.6 4.7C.4 7.1.9 10.1 2.9 12.1c1.9 1.9 4.6 2.4 6.9 1.5l9.1 9.1c.4.4 1 .4 1.4 0l2.3-2.3c.5-.4.5-1.1.1-1.4z',
        },
        child: [],
      },
    ],
  })(props);
};
MdBuild.displayName = 'MdBuild';

export const MdCallEnd = function (props: IconBaseProps) {
  return GenIcon({
    tag: 'svg',
    attr: { viewBox: '0 0 24 24' },
    child: [
      {
        tag: 'path',
        attr: {
          d: 'M12 9c-1.6 0-3.15.25-4.6.72v3.1c0 .39-.23.74-.56.9-.98.49-1.87 1.12-2.66 1.85-.18.18-.43.28-.7.28-.28 0-.53-.11-.71-.29L.29 13.08c-.18-.17-.29-.42-.29-.7 0-.28.11-.53.29-.71C3.34 8.78 7.46 7 12 7s8.66 1.78 11.71 4.67c.18.18.29.43.29.71 0 .28-.11.53-.29.71l-2.48 2.48c-.18.18-.43.29-.71.29-.27 0-.52-.11-.7-.28-.79-.74-1.69-1.36-2.67-1.85-.33-.16-.56-.5-.56-.9v-3.1C15.15 9.25 13.6 9 12 9z',
        },
        child: [],
      },
    ],
  })(props);
};
MdCallEnd.displayName = 'MdCallEnd';

export const MdChildCare = function (props: IconBaseProps) {
  return GenIcon({
    tag: 'svg',
    attr: { viewBox: '0 0 24 24' },
    child: [
      { tag: 'circle', attr: { cx: '14.5', cy: '10.5', r: '1.25' }, child: [] },
      { tag: 'circle', attr: { cx: '9.5', cy: '10.5', r: '1.25' }, child: [] },
      {
        tag: 'path',
        attr: {
          d: 'M22.94 12.66c.04-.21.06-.43.06-.66s-.02-.45-.06-.66c-.25-1.51-1.36-2.74-2.81-3.17-.53-1.12-1.28-2.1-2.19-2.91C16.36 3.85 14.28 3 12 3s-4.36.85-5.94 2.26c-.92.81-1.67 1.8-2.19 2.91-1.45.43-2.56 1.65-2.81 3.17-.04.21-.06.43-.06.66s.02.45.06.66c.25 1.51 1.36 2.74 2.81 3.17.52 1.11 1.27 2.09 2.17 2.89C7.62 20.14 9.71 21 12 21s4.38-.86 5.97-2.28c.9-.8 1.65-1.79 2.17-2.89 1.44-.43 2.55-1.65 2.8-3.17zM19 14c-.1 0-.19-.02-.29-.03-.2.67-.49 1.29-.86 1.86C16.6 17.74 14.45 19 12 19s-4.6-1.26-5.85-3.17c-.37-.57-.66-1.19-.86-1.86-.1.01-.19.03-.29.03-1.1 0-2-.9-2-2s.9-2 2-2c.1 0 .19.02.29.03.2-.67.49-1.29.86-1.86C7.4 6.26 9.55 5 12 5s4.6 1.26 5.85 3.17c.37.57.66 1.19.86 1.86.1-.01.19-.03.29-.03 1.1 0 2 .9 2 2s-.9 2-2 2zM7.5 14c.76 1.77 2.49 3 4.5 3s3.74-1.23 4.5-3h-9z',
        },

        child: [],
      },
    ],
  })(props);
};
MdChildCare.displayName = 'MdChildCare';

export const MdClose = function (props: IconBaseProps) {
  return GenIcon({
    tag: 'svg',
    attr: { viewBox: '0 0 24 24' },
    child: [
      {
        tag: 'path',
        attr: {
          d: 'M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z',
        },
        child: [],
      },
    ],
  })(props);
};
MdClose.displayName = 'MdClose';

export const MdCloudDone = function (props: IconBaseProps) {
  return GenIcon({
    tag: 'svg',
    attr: { viewBox: '0 0 24 24' },
    child: [
      {
        tag: 'path',
        attr: {
          d: 'M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM10 17l-3.5-3.5 1.41-1.41L10 14.17 15.18 9l1.41 1.41L10 17z',
        },
        child: [],
      },
    ],
  })(props);
};
MdCloudDone.displayName = 'MdCloudDone';

export const MdCreditCard = function (props: IconBaseProps) {
  return GenIcon({
    tag: 'svg',
    attr: { viewBox: '0 0 24 24' },
    child: [
      {
        tag: 'path',
        attr: {
          d: 'M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4v-6h16v6zm0-10H4V6h16v2z',
        },
        child: [],
      },
    ],
  })(props);
};
MdCreditCard.displayName = 'MdCreditCard';

export const MdKeyboardArrowDown = function (props: IconBaseProps) {
  return GenIcon({
    tag: 'svg',
    attr: { viewBox: '0 0 24 24' },
    child: [
      {
        tag: 'path',
        attr: { d: 'M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z' },
        child: [],
      },
    ],
  })(props);
};
MdKeyboardArrowDown.displayName = 'MdKeyboardArrowDown';

export const MdMenu = function (props: IconBaseProps) {
  return GenIcon({
    tag: 'svg',
    attr: { viewBox: '0 0 24 24' },
    child: [
      {
        tag: 'path',
        attr: { d: 'M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z' },
        child: [],
      },
    ],
  })(props);
};
MdMenu.displayName = 'MdMenu';

export const MdMic = function (props: IconBaseProps) {
  return GenIcon({
    tag: 'svg',
    attr: { viewBox: '0 0 24 24' },
    child: [
      {
        tag: 'path',
        attr: {
          d: 'M12 14c1.66 0 2.99-1.34 2.99-3L15 5c0-1.66-1.34-3-3-3S9 3.34 9 5v6c0 1.66 1.34 3 3 3zm5.3-3c0 3-2.54 5.1-5.3 5.1S6.7 14 6.7 11H5c0 3.41 2.72 6.23 6 6.72V21h2v-3.28c3.28-.48 6-3.3 6-6.72h-1.7z',
        },
        child: [],
      },
    ],
  })(props);
};
MdMic.displayName = 'MdMic';

export const MdMicOff = function (props: IconBaseProps) {
  return GenIcon({
    tag: 'svg',
    attr: { viewBox: '0 0 24 24' },
    child: [
      {
        tag: 'path',
        attr: {
          d: 'M19 11h-1.7c0 .74-.16 1.43-.43 2.05l1.23 1.23c.56-.98.9-2.09.9-3.28zm-4.02.17c0-.06.02-.11.02-.17V5c0-1.66-1.34-3-3-3S9 3.34 9 5v.18l5.98 5.99zM4.27 3L3 4.27l6.01 6.01V11c0 1.66 1.33 3 2.99 3 .22 0 .44-.03.65-.08l1.66 1.66c-.71.33-1.5.52-2.31.52-2.76 0-5.3-2.1-5.3-5.1H5c0 3.41 2.72 6.23 6 6.72V21h2v-3.28c.91-.13 1.77-.45 2.54-.9L19.73 21 21 19.73 4.27 3z',
        },
        child: [],
      },
    ],
  })(props);
};
MdMicOff.displayName = 'MdMicOff';

export const MdPayment = function (props: IconBaseProps) {
  return GenIcon({
    tag: 'svg',
    attr: { viewBox: '0 0 24 24' },
    child: [
      {
        tag: 'path',
        attr: {
          d: 'M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4v-6h16v6zm0-10H4V6h16v2z',
        },
        child: [],
      },
    ],
  })(props);
};
MdPayment.displayName = 'MdPayment';

export const MdPhoto = function (props: IconBaseProps) {
  return GenIcon({
    tag: 'svg',
    attr: { viewBox: '0 0 24 24' },
    child: [
      {
        tag: 'path',
        attr: {
          d: 'M21 19V5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zM8.5 13.5l2.5 3.01L14.5 12l4.5 6H5l3.5-4.5z',
        },
        child: [],
      },
    ],
  })(props);
};
MdPhoto.displayName = 'MdPhoto';

export const MdPowerSettingsNew = function (props: IconBaseProps) {
  return GenIcon({
    tag: 'svg',
    attr: { viewBox: '0 0 24 24' },
    child: [
      {
        tag: 'path',
        attr: {
          d: 'M13 3h-2v10h2V3zm4.83 2.17l-1.42 1.42C17.99 7.86 19 9.81 19 12c0 3.87-3.13 7-7 7s-7-3.13-7-7c0-2.19 1.01-4.14 2.58-5.42L6.17 5.17C4.23 6.82 3 9.26 3 12c0 4.97 4.03 9 9 9s9-4.03 9-9c0-2.74-1.23-5.18-3.17-6.83z',
        },
        child: [],
      },
    ],
  })(props);
};
MdPowerSettingsNew.displayName = 'MdPowerSettingsNew';

export const MdSecurity = function (props: IconBaseProps) {
  return GenIcon({
    tag: 'svg',
    attr: { viewBox: '0 0 24 24' },
    child: [
      {
        tag: 'path',
        attr: {
          d: 'M12 1L3 5v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V5l-9-4zm0 10.99h7c-.53 4.12-3.28 7.79-7 8.94V12H5V6.3l7-3.11v8.8z',
        },
        child: [],
      },
    ],
  })(props);
};
MdSecurity.displayName = 'MdSecurity';

export const MdVideocam = function (props: IconBaseProps) {
  return GenIcon({
    tag: 'svg',
    attr: { viewBox: '0 0 24 24' },
    child: [
      {
        tag: 'path',
        attr: {
          d: 'M17 10.5V7c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 4v-11l-4 4z',
        },
        child: [],
      },
    ],
  })(props);
};
MdVideocam.displayName = 'MdVideocam';

export const MdVideocamOff = function (props: IconBaseProps) {
  return GenIcon({
    tag: 'svg',
    attr: { viewBox: '0 0 24 24' },
    child: [
      {
        tag: 'path',
        attr: {
          d: 'M21 6.5l-4 4V7c0-.55-.45-1-1-1H9.82L21 17.18V6.5zM3.27 2L2 3.27 4.73 6H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.21 0 .39-.08.54-.18L19.73 21 21 19.73 3.27 2z',
        },
        child: [],
      },
    ],
  })(props);
};
MdVideocamOff.displayName = 'MdVideocamOff';

export const MdDirectMessaging = function (props: IconBaseProps) {
  return GenIcon({
    tag: 'svg',
    attr: { viewBox: '0 0 24 24' },
    child: [
      {
        tag: 'path',
        attr: {
          d: 'M19.5 12a7.5 7.5 0 1 0-7.5 7.5h7.5V12zM12 3a9 9 0 0 1 9 9v9h-9a9 9 0 1 1 0-18zm3.75 8.25h-7.5v-.75h7.5v.75zm0 2.25h-7.5v-.75h7.5v.75z',
        },
        child: [],
      },
    ],
  })(props);
};
MdDirectMessaging.displayName = 'MdDirectMessaging';

export const MdHealthProfile = function (props: IconBaseProps) {
  return GenIcon({
    tag: 'svg',
    attr: { viewBox: '0 0 24 24' },
    child: [
      {
        tag: 'path',
        // @ts-expect-error This prop should not have any effect
        fillRule: 'evenodd',
        attr: {
          d: 'M17.586 2h-.002a4.09 4.09 0 0 0-2.263.511 3.67 3.67 0 0 0-1.662 1.45L12 6.42l-1.658-2.46c-.442-.686-.893-1.08-1.662-1.45a4.09 4.09 0 0 0-2.263-.511h-.003c-2.847.053-4.792 2.532-4.35 5.047.432 2.446 2.63 4.644 5.305 6.384A27.86 27.86 0 0 0 12 15.855c.962-.392 2.774-1.204 4.635-2.426 2.676-1.742 4.874-3.94 5.302-6.38.44-2.52-1.505-4.995-4.35-5.048zm6.32 5.394C22.745 14.02 12.555 17.798 12 18 11.445 17.798 1.263 14.027.094 7.395-.566 3.633 2.34.077 6.377.001 7.494-.02 8.598.23 9.583.727a6.55 6.55 0 0 1 1.024.642A6.28 6.28 0 0 1 12 2.844a6.28 6.28 0 0 1 1.394-1.475c.318-.243.66-.458 1.024-.642.985-.498 2.09-.748 3.205-.725 4.037.076 6.942 3.63 6.283 7.393z',
        },
        child: [],
      },
    ],
  })(props);
};
MdHealthProfile.displayName = 'MdHealthProfile';

export const MdContactInfo = function (props: IconBaseProps) {
  return GenIcon({
    tag: 'svg',
    attr: { viewBox: '0 0 24 24' },
    child: [
      {
        tag: 'g',
        // @ts-expect-error This prop should not have any effect
        id: 'A',
        attr: {
          transform: 'scale(0.9 0.9)',
        },
        child: [
          {
            tag: 'path',
            attr: {
              d: 'M20 2H6a2 2 0 0 0-2 2h2v2H0V4h2a4 4 0 0 1 4-4h14a4 4 0 0 1 4 4v18a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4h2a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2z',
            },
            child: [],
          },
          {
            tag: 'path',
            attr: {
              d: 'M2 7h2v2h2v2H0V9h2V7z',
            },
            child: [],
          },
          {
            tag: 'path',
            attr: {
              d: 'M4 12H2v2H0v2h6v-2H4v-2z',
            },
            child: [],
          },
          {
            tag: 'path',
            attr: {
              d: 'M2 17h2v2h2v2H0v-2h2v-2z',
            },
            child: [],
          },
          {
            tag: 'g',
            attr: {
              transform: 'translate(7 7)',
            },
            child: [
              {
                tag: 'path',
                attr: {
                  d: 'M6.5 13c2.317 0 4.303-.95 5.296-2.42h-1.37c-.767.735-2.122 1.363-3.867 1.363-3.506 0-5.28-2.282-5.28-5.19 0-3.476 2.438-5.68 5.537-5.68 3.04 0 4.98 1.807 4.98 4.47 0 2.25-.812 3.14-1.64 3.14-.542 0-.737-.367-.662-1.01l.512-4.18H7.794l-.105.796c-.226-.505-.707-.92-1.64-.92-1.655 0-2.98 1.44-2.98 3.598 0 1.76.978 2.848 2.453 2.848.963 0 1.625-.475 2.016-1.286.21.812.903 1.256 2.212 1.256 1.6 0 3.25-1.164 3.25-4.21C13 2.22 10.352 0 6.786 0 3.235 0 0 2.68 0 6.737 0 10.198 2.438 13 6.5 13zm-.256-4.992c-.602 0-.918-.5-.918-1.133 0-1.026.48-1.73 1.16-1.73.632 0 .963.413.963 1.087 0 1.057-.542 1.776-1.204 1.776z',
                },
                child: [],
              },
            ],
          },
        ],
      },
    ],
  })(props);
};
MdContactInfo.displayName = 'MdContactInfo';

export const MdVaccinationBook = function (props: IconBaseProps) {
  return GenIcon({
    tag: 'svg',
    attr: { viewBox: '0 0 25 24' },
    child: [
      {
        tag: 'line',
        attr: {
          y1: '-1',
          x2: '2.54016',
          y2: '-1',
          transform:
            'matrix(0.731494 -0.681847 0.731494 0.681847 19.998 6.06152)',
          strokeWidth: '2',
        },
        child: [],
      },
      {
        tag: 'line',
        attr: {
          y1: '-1',
          x2: '7.62047',
          y2: '-1',
          transform:
            'matrix(-0.731494 -0.681847 0.731494 -0.681848 24.6426 5.19629)',
          strokeWidth: '2',
        },
        child: [],
      },
      {
        tag: 'rect',
        attr: {
          x: '-1.19209e-07',
          y: '-1.36369',
          width: '8.1603',
          height: '17.0512',
          rx: '2.40909',
          transform:
            'matrix(-0.731494 -0.681847 0.731494 -0.681848 9.84714 19.3559)',
          strokeWidth: '2',
          fill: 'none',
        },
        child: [],
      },
      {
        tag: 'line',
        attr: {
          y1: '-1',
          x2: '4.84971',
          y2: '-1',
          transform:
            'matrix(-0.731494 -0.681847 0.731494 -0.681848 14.4785 16.123)',
          strokeWidth: '2',
        },
        child: [],
      },
      {
        tag: 'line',
        attr: {
          y1: '-1',
          x2: '4.84971',
          y2: '-1',
          transform:
            'matrix(-0.731494 -0.681847 0.731494 -0.681848 18.1953 12.6592)',
          strokeWidth: '2',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          d: 'M2.41352 20.586L4.64471 18.5062L5.23005 19.0518L2.23108 21.8473L2.41352 20.586Z',
          strokeWidth: '1.13636',
        },
        child: [],
      },
    ],
  })(props);
};
MdVaccinationBook.displayName = 'MdVaccinationBook';

export const MdDeleteAccount = function (props: IconBaseProps) {
  return GenIcon({
    tag: 'svg',
    attr: { viewBox: '0 0 24 24', fill: 'none' },
    child: [
      {
        tag: 'path',
        attr: {
          d: 'M16.375 18.7915H5.875C5.04657 18.7915 4.375 18.1199 4.375 17.2915V3.7915H17.875V17.2915C17.875 18.1199 17.2034 18.7915 16.375 18.7915Z',
          fillRule: 'evenodd',
          strokeWidth: '2',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          d: 'M8.875 14.2915V8.2915',
          stroke: '#666666',
          fillRule: 'evenodd',
          strokeWidth: '2',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          d: 'M13.375 14.2915V8.2915',
          fillRule: 'evenodd',
          strokeWidth: '2',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          d: 'M1.375 3.7915H20.875',
          fillRule: 'evenodd',
          strokeWidth: '2',
        },
        child: [],
      },
      {
        tag: 'path',
        attr: {
          d: 'M13.375 0.791504H8.875C8.04657 0.791504 7.375 1.46308 7.375 2.2915V3.7915H14.875V2.2915C14.875 1.46308 14.2034 0.791504 13.375 0.791504Z',
          fillRule: 'evenodd',
          strokeWidth: '2',
        },
        child: [],
      },
    ],
  })(props);
};
MdDeleteAccount.displayName = 'MdDelete';
