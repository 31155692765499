import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from '@mindoktor/patient-legacy/src/common/compose';
import { _t } from '@mindoktor/patient-legacy/i18n';

import { getGuideByEntrywayId } from '../../../state/guides/selectors';

import {
  fetchUserConnections,
  checkLegalGuardianStatus,
} from '../../../state/profile/actions';
import {
  getChildren,
  contactInformation,
} from '../../../state/profile/selectors';

import { openRoute, routes as legacyRoutes } from '../../../state/routing';
import { isUpperAdolescent } from '../../../state/cases/utils';

import { evalSsn } from '../../../state/utils/evaluation';

import Button from '../../components/button/button';
import { Title1, Body1 } from '../../../common/components/typography';
import BoxedScreen from '../../components/boxed_screen';

import GuideBar from '../../guides/components/bar';

import { getGuideImageByEntrywayId } from '../../guides/utils/images';

import List from './list';

import {
  getIndependentCareAgeFromHost,
  getAdultAgeFromHost,
} from '../../../state/api/selectors';
import { getChildrenTrackingDataFromProps } from '../getTrackingDataFromProps';
import { withTrackPageView } from '@mindoktor/patient-app/tracking/HOC/withTrackPageView';

import { withRouting } from '@mindoktor/patient-app/routing/HOC/withRouting.web';
import { routes } from '@mindoktor/patient-app/routing/constants/webRoutes';

const styles = {
  wrapper: {
    maxWidth: 760,
    padding: 20,
  },

  body: {
    maxWidth: 600,
  },

  title: {
    maxWidth: 800,
    fontSize: 26,
    lineHeight: '32px',
    marginBottom: 10,
  },

  lead: {
    marginBottom: 20,
  },

  list: {
    marginBottom: 20,
  },

  footer: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
  },

  buttons: {
    maxWidth: 400,
    marginLeft: 'auto',
  },
};

// type Props = {
//   entrywayId: number,

//   iconSource: any,
//   title?: string,
//   dynamicCode?: string,

//   children: Connection[],

//   fetchUserConnections: typeof fetchUserConnections,

//   openRoute: typeof openRoute,
// };

export class ListScreen extends Component {
  state = {
    loadedChildren: false,
    upperAdolescent: true,
  };

  loadChildren = async () => {
    const { entrywayId, fetchUserConnections, openRoute, dynamicCode } =
      this.props;

    const { connections, error } = await fetchUserConnections();

    if (error) {
      return;
    }

    if (!connections || !connections.length) {
      // Legacy navigation
      openRoute(
        legacyRoutes.CHILDREN_ADD,
        { entrywayId, dynamicCode },
        { replace: true }
      );
      return;
    }

    this.setState({
      loadedChildren: true,
      childUuid: connections.length === 1 ? connections[0].uuid : undefined,
    });
  };

  openGuides = () => {
    const {
      routing: {
        navigation: { navigate },
      },
    } = this.props;

    navigate(routes.GUIDES, { entrywayId: 'child' });
  };

  openAddChild = () => {
    const { entrywayId, dynamicCode, openRoute, preferredCaregiverId } =
      this.props;

    openRoute(legacyRoutes.CHILDREN_ADD, {
      entrywayId,
      dynamicCode,
      preferredCaregiverId,
    });
  };

  openNext = async (childUuid) => {
    const {
      entrywayId,
      children,
      openRoute,
      revisitId,
      dynamicCode,
      preferredCaregiverId,
      checkLegalGuardianStatus,
      routing: {
        navigation: { navigate },
      },
    } = this.props;
    const child = children.find((c) => c.uuid === childUuid);

    if (!child) return;

    const isLegalGuardian = await checkLegalGuardianStatus(childUuid);

    if (!isLegalGuardian) {
      return;
    }

    const evalFunc = evalSsn;
    const evalArg = child.ssn;

    const { years, months } = evalFunc(evalArg);

    if (years === 0 && months < 6) {
      // Legacy navigation
      openRoute(legacyRoutes.CHILDREN_UNDER_SIX_MONTHS);
    } else {
      navigate(
        routes.GUIDES_PRESTART,
        {
          entrywayId,
        },
        {
          childUuid,
          revisitId,
          dynamicCode,
          preferredCaregiverId,
        }
      );
    }
  };

  childForRevisit = () => {
    const { children, childUuid } = this.props;
    return children.filter((c) => c.uuid === childUuid);
  };

  componentDidUpdate(prevProps) {
    const { birthDate } = this.props;
    if (birthDate && prevProps.birthDate !== birthDate) {
      this.evaluateRoute();
    }
  }

  componentDidMount() {
    const { trackPageView } = this.props;
    trackPageView(getChildrenTrackingDataFromProps(this.props));
    this.evaluateRoute();
  }

  evaluateRoute() {
    const {
      birthDate,
      routing: { navigation },
      independentCareAge,
      adultAge,
    } = this.props;
    if (birthDate) {
      const upperAdolescent = isUpperAdolescent(
        birthDate,
        independentCareAge,
        adultAge
      );

      if (upperAdolescent) {
        navigation.navigate(routes.CASES, { forChildren: true });
        return;
      }
      this.setState({ upperAdolescent });
      this.loadChildren();
    }
  }

  render() {
    const { iconSource, title, children, revisitId } = this.props;
    const { loadedChildren, upperAdolescent } = this.state;

    // If the case is a revisit, only the child connected to the case is showing.
    let childList =
      loadedChildren && revisitId ? this.childForRevisit() : children;

    if (upperAdolescent) return null;

    return (
      <BoxedScreen
        header={
          <GuideBar
            title={title}
            iconSource={iconSource}
            onCancel={this.openGuides}
          />
        }
        body={
          loadedChildren && (
            <div style={styles.wrapper}>
              <div style={styles.body}>
                <Title1 style={styles.title}>
                  {_t('guides.children.selectChild.header')}
                </Title1>

                <Body1 style={styles.lead}>
                  {_t('guides.children.selectChild.lead')}
                </Body1>

                <List
                  children={childList}
                  onChildSelect={this.openNext}
                  style={styles.list}
                />
              </div>
            </div>
          )
        }
        footer={
          <div>
            {loadedChildren && !revisitId && (
              <div style={styles.footer}>
                <div style={styles.buttons}>
                  <Button hero={true} onClick={this.openAddChild}>
                    {_t('guides.children.selectChild.addChild')}
                  </Button>
                </div>
              </div>
            )}
          </div>
        }
        showScrollGradient={true}
      />
    );
  }
}

const enhance = compose(
  withTrackPageView,
  withRouting,
  connect(
    (state, props) => {
      const {
        routing: { queryParams },
      } = props;

      const entrywayId = queryParams.get('entrywayId');
      const guide = getGuideByEntrywayId(state, entrywayId);

      const { displayTitle: title } = guide || {};

      let children = getChildren(state);

      return {
        iconSource: entrywayId
          ? getGuideImageByEntrywayId(entrywayId)
          : undefined,
        title,

        children,
        birthDate: contactInformation(state).birthDate,
        independentCareAge: getIndependentCareAgeFromHost(state),
        adultAge: getAdultAgeFromHost(state),
        entrywayId,
      };
    },
    {
      fetchUserConnections,
      openRoute,
      checkLegalGuardianStatus,
    }
  )
);

export default enhance(ListScreen);
