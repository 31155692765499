import { _t } from '@mindoktor/patient-legacy/i18n';
import React, { Component } from 'react';

import { Body1, SubHead } from '../../../common/components/typography';
import { caseReferralType } from '../../../state/cases/constants';
import Button from '../../components/button/button';
import Modal from '../../components/modal';
import { hideModal } from '../../routing';

const styles = {
  title: {
    paddingBottom: 8,
  },

  body: {
    paddingBottom: 16,
  },
};

const HowToLab = () => (
  <div>
    <SubHead style={styles.title}>
      {_t('referral.howToScreen.labHeader1')}
    </SubHead>

    <Body1 style={styles.body}>{_t('referral.howToScreen.labBody1')}</Body1>

    <SubHead style={styles.title}>
      {_t('referral.howToScreen.labHeader2')}
    </SubHead>

    <Body1 style={styles.body}>{_t('referral.howToScreen.labBody2')}</Body1>

    <SubHead style={styles.title}>
      {_t('referral.howToScreen.labHeader3')}
    </SubHead>

    <Body1>{_t('referral.howToScreen.labBody3')}</Body1>
  </div>
);

const HowToXray = () => (
  <div>
    <SubHead style={styles.title}>
      {_t('referral.howToScreen.xRayHeader1')}
    </SubHead>

    <Body1 style={styles.body}>{_t('referral.howToScreen.xRayBody1')}</Body1>

    <SubHead style={styles.title}>
      {_t('referral.howToScreen.xRayHeader2')}
    </SubHead>

    <Body1 style={styles.body}>{_t('referral.howToScreen.xRayBody2')}</Body1>

    <SubHead style={styles.title}>
      {_t('referral.howToScreen.xRayHeader3')}
    </SubHead>

    <Body1>{_t('referral.howToScreen.xRayBody3')}</Body1>
  </div>
);

const HowToSelfTest = () => (
  <div>
    <SubHead style={styles.title}>
      {_t('referral.howToScreen.selfTestHeader1')}
    </SubHead>

    <Body1 style={styles.body}>
      {_t('referral.howToScreen.selfTestBody1')}
    </Body1>

    <SubHead style={styles.title}>
      {_t('referral.howToScreen.selfTestHeader2')}
    </SubHead>

    <Body1 style={styles.body}>
      {_t('referral.howToScreen.selfTestBody2')}
    </Body1>

    <SubHead style={styles.title}>
      {_t('referral.howToScreen.selfTestHeader3')}
    </SubHead>

    <Body1>{_t('referral.howToScreen.selfTestBody3')}</Body1>
  </div>
);

const HowToSpecialist = () => (
  <div>
    <SubHead style={styles.title}>
      {_t('referral.howToScreen.specialistHeader')}
    </SubHead>

    <Body1>{_t('referral.howToScreen.specialistBody')}</Body1>
  </div>
);

const HowToClinicalPhysiology = () => (
  <div>
    <SubHead style={styles.title}>
      {_t('referral.howToScreen.clinicalPhysiologyTitle')}
    </SubHead>

    <Body1 style={styles.body}>
      {_t('referral.howToScreen.clinicalPhysiologyBody1')}
    </Body1>

    <Body1 style={styles.body}>
      {_t('referral.howToScreen.clinicalPhysiologyBody2')}
    </Body1>

    <Body1 style={styles.body}>
      {_t('referral.howToScreen.clinicalPhysiologyBody3')}
    </Body1>

    <Body1 style={styles.body}>
      {_t('referral.howToScreen.clinicalPhysiologyBody4')}
    </Body1>

    <Body1 style={styles.body}>
      {_t('referral.howToScreen.clinicalPhysiologyBody5')}
    </Body1>

    <Body1>{_t('referral.howToScreen.clinicalPhysiologyBody6')}</Body1>
  </div>
);

export default class ReferralHowToModal extends Component {
  renderBody() {
    const { referralType } = this.props;

    switch (referralType) {
      case caseReferralType.LabTest:
        return <HowToLab />;
      case caseReferralType.XRay:
        return <HowToXray />;
      case caseReferralType.SelfTest:
        return <HowToSelfTest />;
      case caseReferralType.Specialist:
        return <HowToSpecialist />;
      case caseReferralType.ClinicalPhysiology:
        return <HowToClinicalPhysiology />;
      default:
        console.warn('Missing referral howTo for type: ', referralType);
        return null;
    }
  }

  render() {
    return (
      <Modal
        body={this.renderBody()}
        footer={
          <Button type="ghost" color="black" onClick={hideModal}>
            {_t('common.close')}
          </Button>
        }
      />
    );
  }
}
