import { _t } from '@mindoktor/patient-legacy/i18n';
import React from 'react';

import { Body1, SubHead } from '../../../common/components/typography';
import OptionInput from '../../components/input/option';
import Link from '../../components/link';
import { showModal } from '../../routing';
import JournalConsentModal from './journal_consent_modal';

const styles = {
  title: {
    marginBottom: 10,
  },

  input: {
    marginLeft: -20,
  },
};

// type Props = {
//   consent?: boolean,
//   onChange?: () => any,
//   style?: any,
// };

const showJournalConsentModal = () => showModal(JournalConsentModal);

const JournalConsent = ({ consent, onChange, style }) => (
  <div style={style}>
    <SubHead style={styles.title}>{_t('payment.journalConsent.title')}</SubHead>

    <Body1>
      {_t('payment.journalConsent.lead')}{' '}
      <Link onClick={showJournalConsentModal}>
        {_t('payment.journalConsent.link')}
      </Link>
    </Body1>

    <div style={styles.input}>
      <OptionInput
        label={_t('payment.journalConsent.label')}
        checked={consent}
        multiple={true}
        lastItem={true}
        onChange={onChange}
      />
    </div>
  </div>
);

export default JournalConsent;
