import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { _t } from '@mindoktor/patient-legacy/i18n';

import HiddenIdentityComponent, {
  HiddenIdentityTextVariant,
} from './HiddenIdentityComponent';
import Screen from '../components/screen';
import TruncatedFooter from '../components/footer/truncated';
import MediaQuery from '../components/media_query';

import MenuBar from '../menu/bar';

const padding = 2.5; // rem
const useStyles = makeStyles({
  component: {
    padding: padding + 'rem',
    paddingBottom: '0.5rem',
    width: `calc(100% - ${padding * 2}rem)`,
    maxWidth: 760,
    margin: '0 auto',
  },
});

interface Props {
  textVariant?: keyof typeof HiddenIdentityTextVariant;
}

const SupportScreen: React.FC<Props> = ({ textVariant }) => {
  const classes = useStyles();
  return (
    <Screen
      header={<MenuBar />}
      body={
        <HiddenIdentityComponent
          className={classes.component}
          textVariant={textVariant}
        />
      }
      footer={
        <MediaQuery minWidth={801}>
          <TruncatedFooter />
        </MediaQuery>
      }
      showScrollGradient={true}
    />
  );
};

export default SupportScreen;
