import { useMutation, UseMutationResult } from '@tanstack/react-query';

import { useNotifications } from '@mindoktor/patient-app/adapters/hooks/useNotifications';
import { ResponseError } from '@mindoktor/patient-app/api/responseError';
import { useTranslation } from '@mindoktor/patient-app/localization/hooks/useTranslation';

import { policyApi } from '../api/policyApi';

type PrivacyMutation = UseMutationResult<void, ResponseError, void>;

export const useAcceptPrivacyMutator = (): PrivacyMutation => {
  const notifications = useNotifications();
  const t = useTranslation('error');
  return useMutation(
    async () => {
      const response = await policyApi.acceptPrivacy();
      if (!response.ok) {
        throw new ResponseError(response);
      }
      // This mutation normally should invalidate the user status query onSuccess,
      // but as it is used in parallell with the tos mutator we'd end up
      // invalidating twice and for that reason we do the invalidation in the
      // component that uses these queries.
    },
    {
      onError: async (_error) => {
        notifications.showWarning(t('unknown'));
      },
    }
  );
};
