import fetchHelpCenterSupportTickets from '../../api/helpCenter/fetchHelpCenterSupportTickets';
import type { SupportTicket } from '../../api/helpCenter/types';
import type { Dispatch } from 'redux';
import { CommonStateType } from '../types';
import { apiHeadersSelector } from '../../api/api';

export enum actionTypes {
  ACTION_SET_TICKETS = 'ACTION_SET_TICKETS',
}

export type Action<T = unknown> = {
  type: keyof typeof actionTypes;
  payload: T;
};

export const fetchSupportTickets =
  () =>
  async (
    dispatch: Dispatch<Action<SupportTicket[]>>,
    getState: () => CommonStateType
  ) => {
    const apiHeaders = apiHeadersSelector(getState());
    const [tickets, err] = await fetchHelpCenterSupportTickets(apiHeaders);
    if (err) {
      console.error('Could not fetch support tickets correctly', err);
    }
    dispatch({
      type: actionTypes.ACTION_SET_TICKETS,
      payload: tickets,
    });
  };
