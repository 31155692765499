import test from './test';

const Config = {
  ...test,
  ConfigName: 'se-docker',
  Urls: {
    base: {
      domain: 'https://api.victor.doclydev.io',
    },
    web: {
      domain: 'https://victor.doclydev.io',
    },
    kiosk: {
      domain: 'https://kiosk.se.doclytest.io',
    },
    guides: {
      domain: 'https://guides.victor.doclydev.io',
    },
    inquisitor: {
      domain: 'https://develop-inquisitor.now.sh',
    },
  },
};

export default Config;
