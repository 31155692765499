// @ts-ignore
import { _t } from '@mindoktor/patient-legacy/i18n';
import dayjs from 'dayjs';
import React, { Component } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import colors from '../../../common/colors';

import SpinnerScreen from '../../components/spinner/screen';

import { fetchCaseAnswers } from '../../../state/cases/actions';
import {
  getCase,
  getCaseAnswers,
  isCaseAnswersLoading,
} from '../../../state/cases/selectors';

import { withAnswers } from '../../../state/formulary/formulary';
import { flattenAnswers } from '../../../state/formulary/utils';

import { Body2, Title2 } from '../../../common/components/typography';
import BackBar from '../../components/bar/back';
import TruncatedFooter from '../../components/footer/truncated';
import HeartIcon from '../../components/icon/heart';
import MediaQuery from '../../components/media_query';
import Screen from '../../components/screen';

import FormularyAnswers from '../../formulary/answers';

import {
  RoutingProp,
  WithRoutingProps,
  withRouting,
} from '@mindoktor/patient-app/routing/HOC/withRouting.web';
import { FormularyWithAnswers } from '../../../state/formulary/types';
import { AppDispatch, CommonStateType } from '../../../state/types';
import InquisitionAnswers from '../../inquisitions/result/Answers';
import { openChatRoute } from '../../utils/openChatRoute';

const styles = {
  body: {
    width: '100%',
    maxWidth: 800,
    margin: '0 auto',
    paddingTop: 20,
    paddingBottom: 20,
  },

  inner: {
    maxWidth: 640,
  },

  divider: {
    height: 2,
    backgroundColor: '#EDEDED',
    marginTop: 10,
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 20,
  },

  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 20,
  },

  history: {
    marginTop: 10,
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 20,
    color: colors.grey_500,
  },

  inquisition: {
    paddingLeft: 20,
    paddingRight: 20,
  },
} as const;

interface OwnProps {
  conversationId?: number;
  routing: RoutingProp;
}

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = OwnProps & PropsFromRedux & WithRoutingProps;

type State = {
  guideResolved?: FormularyWithAnswers;
  healthProfileResolved?: FormularyWithAnswers;
};

export class AnswersScreen extends Component<Props, State> {
  state: State = {};

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (nextProps.guideFormulary && !prevState.guideResolved) {
      return {
        ...prevState,
        guideResolved:
          nextProps.guideFormulary &&
          withAnswers(
            nextProps.guideFormulary,
            flattenAnswers(
              nextProps.guideFormulary,
              nextProps.guideAnswers || {}
            )
          ),
        healthProfileResolved:
          nextProps.healthProfileFormulary &&
          withAnswers(
            nextProps.healthProfileFormulary,
            flattenAnswers(
              nextProps.healthProfileFormulary,
              nextProps.healthProfileAnswers || {}
            )
          ),
      };
    }

    return null;
  }

  componentDidMount() {
    const {
      fetchCaseAnswers,
      guideFormulary,
      guideAnswers,
      healthProfileFormulary,
      healthProfileAnswers,
    } = this.props;

    if (guideFormulary) {
      this.setState((state) => ({
        ...state,
        guideResolved:
          guideFormulary &&
          withAnswers(
            guideFormulary,
            flattenAnswers(guideFormulary, guideAnswers || {})
          ),
        healthProfileResolved:
          healthProfileFormulary &&
          withAnswers(
            healthProfileFormulary,
            flattenAnswers(healthProfileFormulary, healthProfileAnswers || {})
          ),
      }));
    }

    fetchCaseAnswers();
  }

  render() {
    const {
      isLoading,
      title,
      guideFormulary,
      guideContextDescriptions,
      healthProfileFormulary,
      inquisitionAnswers,
      hasHistory,
      updated,
      routing,
      dispatch,
    } = this.props;

    const caseIdParam = routing.params?.caseId;
    const conversationIdParam = routing.queryParams?.get('conversationId');

    const caseId = caseIdParam == undefined ? undefined : +caseIdParam;
    const conversationId =
      conversationIdParam === null ? conversationIdParam : +conversationIdParam;

    const { guideResolved, healthProfileResolved } = this.state;

    if (isLoading) {
      return <SpinnerScreen />;
    }

    return (
      <Screen
        header={
          <BackBar
            title={title}
            dark={true}
            maxWidth={1000}
            onBack={() =>
              openChatRoute(
                dispatch,
                routing.navigation,
                caseId,
                conversationId ?? undefined
              )
            }
          />
        }
        body={
          <div style={styles.body}>
            <div style={styles.inner}>
              {hasHistory && !!updated && (
                <Body2 style={styles.history}>
                  {_t('formularyAnswersMessageItem.hasHistory', {
                    date: dayjs(updated).format('ll'),
                  })}
                </Body2>
              )}

              {!!inquisitionAnswers && (
                <div style={styles.inquisition}>
                  <InquisitionAnswers answers={inquisitionAnswers} />
                </div>
              )}

              {guideResolved &&
                guideResolved.answers &&
                guideResolved.visibility &&
                guideResolved.errors && (
                  <FormularyAnswers
                    caseId={caseId}
                    formulary={guideFormulary}
                    answers={guideResolved.answers}
                    visibility={guideResolved.visibility}
                    errors={guideResolved.errors}
                    truncate={false}
                    thumbnails={true}
                    contextDescriptions={guideContextDescriptions}
                  />
                )}

              {healthProfileFormulary &&
                healthProfileResolved &&
                healthProfileResolved.answers &&
                healthProfileResolved.visibility &&
                healthProfileResolved.errors && (
                  <div>
                    <div style={styles.divider} />

                    <div style={styles.header}>
                      <Title2>{_t('cases.answers.healthProfile')}</Title2>

                      <HeartIcon />
                    </div>

                    <FormularyAnswers
                      caseId={caseId}
                      formulary={healthProfileFormulary}
                      answers={healthProfileResolved.answers}
                      visibility={healthProfileResolved.visibility}
                      errors={healthProfileResolved.errors}
                      truncate={false}
                      thumbnails={true}
                    />
                  </div>
                )}
            </div>
          </div>
        }
        footer={
          <MediaQuery minWidth={801}>
            <TruncatedFooter />
          </MediaQuery>
        }
        showScrollGradient={true}
      />
    );
  }
}

const connector = connect(
  (state: CommonStateType, props: OwnProps) => {
    const {
      title,
      guideFormulary,
      guideAnswers,
      guideContextDescriptions,
      healthProfileFormulary,
      healthProfileAnswers,
      inquisitionAnswers,
    } = getCaseAnswers(state) || {};

    const caseData = getCase(state, props.routing.params?.caseId);

    return {
      isLoading: isCaseAnswersLoading(state),
      title,

      guideFormulary,
      guideAnswers,
      guideContextDescriptions,

      healthProfileFormulary,
      healthProfileAnswers,

      inquisitionAnswers,

      hasHistory: caseData?.guideHasHistory,
      updated: caseData?.guideUpdated,
    };
  },
  (dispatch, props: OwnProps) => ({
    fetchCaseAnswers: () =>
      (dispatch as AppDispatch)(fetchCaseAnswers(props.routing.params?.caseId)),
    dispatch: dispatch as AppDispatch,
  })
);

const ConnectedAnswersScreenWithRouting = withRouting(connector(AnswersScreen));

export default ConnectedAnswersScreenWithRouting;
