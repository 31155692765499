import { _t } from '@mindoktor/patient-legacy/i18n';
import dayjs from 'dayjs';
import React, { Component } from 'react';

import cameraIcon from '../../../../../images/icon/ic_videochat_camera_black.png';
import { Body1 } from '../../../../common/components/typography';
import spacing from '../../../../common/spacing';
import { formatRoute, routes } from '../../../../state/routing';
import Button from '../../../components/button/button';
import Markdown from '../../../components/markdown';
import Message from '../../components/message';
import Img from '@mindoktor/pulse/src/utils/images/Img.web';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  body: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingLeft: spacing.xxt,
    paddingRight: spacing.xxt,
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: spacing.xs,
  },
  cameraIcon: {
    marginTop: spacing.xxt,
  },
  text: {
    whiteSpace: 'pre-wrap',
  },
  title: {
    fontWeight: 500,
  },
  startButton: {
    marginTop: spacing.xs,
  },
  date: {
    whiteSpace: 'nowrap',
  },
  // cancelButton: {
  //   marginLeft: 'auto',
  //   alignSelf: 'center',
  //   paddingLeft: spacing.s,
  //   paddingRight: spacing.s,
  // },
};

// type Props = {
//   appointment: Appointment,
//   appointmentId: string,
//   showCancelButton?: boolean,
//   showStartButton?: boolean,
//   caseId: string,
// };

class VideoAppointmentScheduled extends Component {
  render() {
    const { appointment, style, showStartButton, caseId } = this.props;
    return (
      <Message style={{ ...styles.root, ...style }}>
        <div style={{ ...styles.body }}>
          <Img src={cameraIcon} style={styles.cameraIcon} />
          <div style={styles.textWrapper}>
            <Body1 style={styles.title}>
              {dayjs(appointment.start).fromNow()}
              {' – '}
              <span style={{ ...styles.date }}>
                {dayjs(appointment.start).format('lll')}
              </span>
            </Body1>
            <Markdown
              source={_t('scheduler.appointmentscheduled.video')}
              style={styles.text}
            />
          </div>
        </div>

        {showStartButton && (
          <Button
            color="blue"
            style={styles.startButton}
            href={`#${formatRoute(routes.VIDEOCHAT, { caseId })}`}
          >
            {_t('scheduler.startVideoCall')}
          </Button>
        )}

        {/*
        Turn off for now, implement later

        {showCancelButton && (
          <Button color="blue" style={styles.cancelButton}>
            {_t('scheduler.cancel')}
          </Button>
        )} */}
      </Message>
    );
  }
}

export default VideoAppointmentScheduled;
