import { SnackbarValue, ActionTypes } from './types';

export const showSnackbar = (payload: SnackbarValue) => ({
  type: ActionTypes.SHOW_SNACKBAR,
  payload,
});

export const hideSnackbar = () => ({
  type: ActionTypes.HIDE_SNACKBAR,
});
