import { REHYDRATE } from 'redux-persist/es/constants';
import { getIn, omit } from 'timm';

import { LOGOUT } from '../api/actions';
import { FORMULARY_POST_SUCCESS } from '../formulary/types';
import {
  FETCH_CONTACT_INFO_VERIFICATION_ERROR,
  FETCH_CONTACT_INFO_VERIFICATION_REQUEST,
  FETCH_CONTACT_INFO_VERIFICATION_SUCCESS,
  PROFILE_CONNECTIONS_RECEIVED,
  PROFILE_INVITATION_REQUEST_SUCCESS,
  PROFILE_RECEIVED,
  PROFILE_REQUEST,
  PROFILE_SAVE,
  PROFILE_SAVE_ERROR,
  PROFILE_SAVE_SUCCESS,
  PROFILE_USERINFO_RECEIVED,
  PROFILE_VALIDATE,
  PROFILE_VALIDATE_ERROR,
  PROFILE_VALIDATE_SUCCESS,
  SEND_CONTACT_INFO_VERIFICATION_ERROR,
  SEND_CONTACT_INFO_VERIFICATION_REQUEST,
  SEND_CONTACT_INFO_VERIFICATION_SUCCESS,
  SET_CONTACT_INFO_VERIFICATION_BEING_VERIFIED,
  SET_CONTACT_INFO_VERIFICATION_REDIRECT_FUNC,
  TICKETS_REQUEST_SUCCESS,
  VERIFY_CONTACT_INFO_ERROR,
  VERIFY_CONTACT_INFO_REQUEST,
  VERIFY_CONTACT_INFO_SUCCESS,
} from './actions';

export const SSNTypePersonnummer = 1;

export const initialState = {
  address: '',
  city: '',
  invitationsByParentCaseId: {},
  /** @type {Record<number|string, import('./types_definitions').Invitation>} */
  invitationsById: {},
  email: '',
  firstName: '',
  lastName: '',
  lat: 0,
  long: 0,
  phone: '',
  postalcode: '',
  prefLang: '',
  status: 0,
  timeZone: '',
  freePass: {},
  freePassError: undefined,
  userMarketingId: '',
  pending: false,
  loaded: false,
  validation: {
    errors: {},
    valid: false,
  },
  identityProtection: undefined,
  userId: undefined,
  tickets: undefined,
  contactInfoVerification: {
    verifications: undefined,
    redirectFunc: undefined,
    infoBeingVerified: undefined,
  },
};

/**
 * @typedef {typeof initialState} InitialState
 */

/**
 * Adds missing properties that have no initialization
 * @typedef {InitialState & {age: number}} InitialStateAugmented
 */

/**
 * @param {InitialStateAugmented} state
 */
export default function profileReducer(state = initialState, action) {
  switch (action.type) {
    case PROFILE_REQUEST:
      return state;

    case PROFILE_RECEIVED:
      return {
        ...state,
        ...action.payload.profile,
        loaded: true,
      };

    case PROFILE_CONNECTIONS_RECEIVED:
      const connections = action.payload.connections;
      return {
        ...state,
        connections,
      };

    case PROFILE_USERINFO_RECEIVED:
      return {
        ...state,
        ...action.payload.userInfo,
      };

    case PROFILE_SAVE:
      return {
        ...state,
        pending: true,
      };

    case PROFILE_SAVE_SUCCESS:
      return {
        ...state,
        ...action.payload.profile,
        pending: false,
      };

    case PROFILE_SAVE_ERROR:
      return {
        ...state,
        message: action.payload.message,
        pending: false,
      };

    case PROFILE_VALIDATE:
      return {
        ...state,
        validation: {
          ...state.validation,
          pending: true,
        },
      };

    case PROFILE_VALIDATE_SUCCESS:
      return {
        ...state,
        validation: {
          errors: action.payload.valid
            ? {}
            : {
                ...state.validation.errors,
                ...action.payload.messages.reduce((acc, curr) => {
                  return {
                    ...acc,
                    [curr.key]: curr.message,
                  };
                }, {}),
              },
          valid: action.payload.valid,
          pending: false,
        },
      };

    // VALIDATE_ERROR occurs when the validation fails, not when there's a validation error.
    case PROFILE_VALIDATE_ERROR:
      return {
        ...state,
        validation: {
          ...state.validation,
          pending: false,
        },
      };

    case PROFILE_INVITATION_REQUEST_SUCCESS: {
      const invitations = getIn(action, ['payload', 'invitations']) || [];

      const invitationsById = invitations.reduce((accumulator, invitation) => {
        return {
          ...accumulator,
          [invitation.id]: invitation,
        };
      }, {});

      const invitationsByParentCaseId = invitations.reduce(
        (accumulator, invitation) => {
          const invitations =
            accumulator[
              invitation.revisitParentCaseId
                ? invitation.revisitParentCaseId
                : invitation.firstVisitCaseId
            ] || [];
          invitations.push(invitation.id);

          return {
            ...accumulator,
            [invitation.revisitParentCaseId
              ? invitation.revisitParentCaseId
              : invitation.firstVisitCaseId]: invitations,
          };
        },
        {}
      );

      return {
        ...state,
        invitationsById,
        invitationsByParentCaseId,
      };
    }

    // FORMULARY_POST_SUCCESS is dispatched when a new case has been submitted and saved
    case FORMULARY_POST_SUCCESS: {
      if (!action.payload.revisitId) {
        return state;
      }

      // If the new case was created from a revisit invitation we need
      // to remove the invitation from the state
      const usedRevisitInvitationFirstVisitId = Object.keys(
        state.invitationsByParentCaseId
      ).find((caseId) => {
        return (
          state.invitationsByParentCaseId[caseId].id ===
          action.payload.revisitId
        );
      });

      return {
        ...state,
        invitationsByParentCaseId: omit(
          state.invitationsByParentCaseId,
          usedRevisitInvitationFirstVisitId
        ),
      };
    }

    case LOGOUT:
      return {
        ...initialState,
      };

    case TICKETS_REQUEST_SUCCESS: {
      return {
        ...state,
        tickets: action.payload,
      };
    }

    case SEND_CONTACT_INFO_VERIFICATION_REQUEST: {
      return {
        ...state,
        contactInfoVerification: {
          ...state.contactInfoVerification,
          sendInfoVerificationError: null,
        },
      };
    }
    case SEND_CONTACT_INFO_VERIFICATION_SUCCESS: {
      return {
        ...state,
        contactInfoVerification: {
          ...state.contactInfoVerification,
          sendInfoVerificationError: null,
        },
      };
    }
    case SEND_CONTACT_INFO_VERIFICATION_ERROR: {
      return {
        ...state,
        contactInfoVerification: {
          ...state.contactInfoVerification,
          sendInfoVerificationError: action.error,
        },
      };
    }

    case VERIFY_CONTACT_INFO_REQUEST: {
      return {
        ...state,
        contactInfoVerification: {
          ...state.contactInfoVerification,
          verifyInfoError: null,
        },
      };
    }
    case VERIFY_CONTACT_INFO_SUCCESS: {
      return {
        ...state,
        contactInfoVerification: {
          ...state.contactInfoVerification,
          verifyInfoError: null,
        },
      };
    }
    case VERIFY_CONTACT_INFO_ERROR: {
      return {
        ...state,
        contactInfoVerification: {
          ...state.contactInfoVerification,
          verifyInfoError: action.error,
        },
      };
    }

    case FETCH_CONTACT_INFO_VERIFICATION_REQUEST: {
      return {
        ...state,
        contactInfoVerification: {
          ...state.contactInfoVerification,
          fetchInfoVerificationError: null,
        },
      };
    }
    case FETCH_CONTACT_INFO_VERIFICATION_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        contactInfoVerification: {
          ...state.contactInfoVerification,
          verifications: {
            ...state.contactInfoVerification.verifications,
            [payload.info]: payload,
          },
          fetchInfoVerificationError: null,
        },
      };
    }
    case FETCH_CONTACT_INFO_VERIFICATION_ERROR: {
      return {
        ...state,
        contactInfoVerification: {
          ...state.contactInfoVerification,
          fetchInfoVerificationError: action.error,
        },
      };
    }

    case SET_CONTACT_INFO_VERIFICATION_BEING_VERIFIED: {
      return {
        ...state,
        contactInfoVerification: {
          ...state.contactInfoVerification,
          infoBeingVerified: action.payload,
        },
      };
    }
    case SET_CONTACT_INFO_VERIFICATION_REDIRECT_FUNC: {
      return {
        ...state,
        contactInfoVerification: {
          ...state.contactInfoVerification,
          redirectFunc: action.payload,
        },
      };
    }

    case REHYDRATE:
      return {
        ...state,
        ...action.payload.profile,
      };

    default:
      return state;
  }
}
