import { createContext } from 'react';

const contextNotInitialized = () =>
  console.warn(
    'Context not initialized. Did you call this from within an EventReadProvider?'
  );

export interface MessageReadReceipt {
  messageId: number;
  /** Timestamp */
  messageCreated: string;
}

export interface IMessageReadContext {
  readMessageReceipts: MessageReadReceipt[];
  lastReadReceipt?: MessageReadReceipt | null;
  pushReadMessageReceipt: (e: MessageReadReceipt) => void;
  clearReadMessageReceipts: () => void;
}

const initialMessageReadContext: IMessageReadContext = {
  readMessageReceipts: [] as MessageReadReceipt[],
  lastReadReceipt: null,
  pushReadMessageReceipt: (e: MessageReadReceipt) => {
    contextNotInitialized();
  },
  clearReadMessageReceipts: () => {
    contextNotInitialized();
  },
};

/**
 * Context to hold read events before calling the last read API.
 */
export const MessageReadContext = createContext<IMessageReadContext>(
  initialMessageReadContext
);
