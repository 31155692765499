import { _t } from '@mindoktor/patient-legacy/i18n';
import React from 'react';
import { connect } from 'react-redux';

import Config from '@mindoktor/env/Config';
import { Body1, Title1 } from '../../../common/components/typography';
import { openUrl } from '../../../state/app/actions';
import BoxedScreen from '../../components/boxed_screen';
import Button from '../../components/button/button';
import GuideBar from '../../guides/components/bar';
import { goHome } from '../../routing';
import { useTrackPageView } from '@mindoktor/patient-app/tracking/hooks/useTrackPageView';
import { getChildrenTrackingDataFromProps } from '../getTrackingDataFromProps';

const styles = {
  wrapper: {
    maxWidth: 800,
    paddingTop: 20,
    paddingBottom: 20,
  },

  body: {
    maxWidth: 600,
    paddingLeft: 20,
    paddingRight: 20,
  },

  title: {
    marginBottom: 10,
  },

  paragraph: {
    marginBottom: 20,
  },
};

export const UnderSixMonthsScreen = (props) => {
  const call117 = () => props.openUrl('tel:1177');
  useTrackPageView(getChildrenTrackingDataFromProps(props));

  return (
    <BoxedScreen
      header={<GuideBar onCancel={goHome} />}
      body={
        <div style={styles.wrapper}>
          <div style={styles.body}>
            <Title1 style={styles.title}>
              {_t('underSixMonthsScreen.title')}
            </Title1>

            {[
              _t('underSixMonthsScreen.info1'),
              _t('underSixMonthsScreen.info2'),
              _t('underSixMonthsScreen.info3'),
            ].map((text, i) => (
              <Body1 key={i} style={styles.paragraph}>
                {text}
              </Body1>
            ))}
            {!Config.HideUnderSixMonthsCallButton && (
              <Button onClick={call117}>
                {_t('underSixMonthsScreen.call117')}
              </Button>
            )}
          </div>
        </div>
      }
      showScrollGradient={true}
    />
  );
};

export default connect(null, { openUrl })(UnderSixMonthsScreen);
