import { _t } from '@mindoktor/patient-legacy/i18n';
import React from 'react';

import colors from '../../../../common/colors';
import { Body3 } from '../../../../common/components/typography';

const styles = {
  text: {
    color: colors.red_500,
  },
};

const NewMessagesDivider = () => (
  <div>
    <Body3 mb="t" style={styles.text}>
      {_t('newMessagesDivider.text')}
    </Body3>
  </div>
);

export default NewMessagesDivider;
