import React from 'react';
import { Title2, Intro } from '../../../common/components/typography';
import { _t } from '@mindoktor/patient-legacy/i18n';

import spacing from '../../../common/spacing';
import arrowIconSource from '../../../../images/generated/arrow_listitem@4x.png';
import Img from '@mindoktor/pulse/src/utils/images/Img.web';
import type { CompatImgSrc } from '@mindoktor/pulse/src/utils/images/types';

const styles = {
  icon: {
    display: 'block',
    width: 56,
    height: 56,
    marginRight: spacing.xs,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: spacing.xs,
  },
  labels: {
    flex: 1,
  },
  arrow: {
    height: spacing.xs,
    marginTop: spacing.xxt,
    marginLeft: spacing.xs,
    opacity: 0.33,
  },
} as {
  [key: string]: { [key: string]: React.CSSProperties };
};

/**
 * Header of a case card.
 */
const CardHeader: React.FC<{
  title: string;
  intro: string;
  showArrow?: boolean;
  icon: CompatImgSrc;
}> = ({ icon, title, intro, showArrow = true }) => (
  <div style={styles.header}>
    <Img src={icon} style={styles.icon} />
    <div style={styles.labels}>
      <Title2>{title}</Title2>
      <Intro>{intro}</Intro>
    </div>
    {showArrow && <Img src={arrowIconSource} style={styles.arrow} />}
  </div>
);

export default CardHeader;
