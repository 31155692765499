export const PUSH_NOTIFICATION_FETCH = 'PUSH_NOTIFICATION_FETCH';
export const PUSH_NOTIFICATION_FETCH_SUCCESS =
  'PUSH_NOTIFICATION_FETCH_SUCCESS';
export const PUSH_NOTIFICATION_FETCH_ERROR = 'PUSH_NOTIFICATION_FETCH_ERROR';
export const PUSH_TOKEN_ADD = 'PUSH_TOKEN_ADD';
export const PUSH_FCM_TOKEN_ADD_SUCCESS = 'PUSH_FCM_TOKEN_ADD_SUCCESS';
export const PUSH_FCM_TOKEN_ADD_ERROR = 'PUSH_FCM_TOKEN_ADD_ERROR';
export const PUSH_SHOW_PRIMER = 'PUSH_SHOW_PRIMER';
export const PUSH_SET_STATUS = 'PUSH_SET_STATUS';
export const PUSH_PRIMER_SHOWN = 'PUSH_PRIMER_SHOWN';
export const PUSH_APNS_TOKEN_SET = 'PUSH_APNS_TOKEN_SET';

// export type PushState = {
//   shouldAskForPermissions: boolean,
//   numberOfTimesPushPrimerHasBeenShown: number,
//   pushEnabled: boolean,
//   fcmToken: string,
//   apnsToken: string,
//   hasRequestedPermissions: boolean,
// };
