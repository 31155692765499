import {
  HIDE_COACH_NOTIFICATION,
  REGISTER_COACH_NOTIFICATIONS,
  SET_COACH_NOTIFICATION_POSITION,
  SHOW_COACH_NOTIFICATION,
  TOAST_NOTIFICATION_DISMISS,
  TOAST_NOTIFICATION_SHOW,
  UNREGISTER_COACH_NOTIFICATIONS,
} from './types';

/**
 * @typedef {Object} ShowNotificationProps
 * @property {string} [type]
 * @property {string} [message]
 * @property {number} [timeout]
 * @property {boolean} [shouldTimeout]
 * @property {() => void} [onClose]
 */

export function showNotification(
  /** @type {ShowNotificationProps} */ {
    type = 'info',
    message = 'missing message',
    timeout = 10000,
    shouldTimeout = true,
    onClose,
  }
) {
  return {
    type: TOAST_NOTIFICATION_SHOW,
    payload: {
      type,
      message,
      timeout,
      shouldTimeout,
      onClose,
    },
  };
}

export function dismissNotification(id) {
  return async (dispatch) => {
    if (id === undefined) {
      console.warn('Notification id is missing');
    } else {
      dispatch({
        type: TOAST_NOTIFICATION_DISMISS,
        payload: { id },
      });
    }
  };
}

export function registerCoachNotifications(contextName, coachNotifications) {
  return {
    type: REGISTER_COACH_NOTIFICATIONS,
    payload: {
      contextName,
      coachNotifications,
    },
  };
}

export function unregisterCoachNotifications(contextName) {
  return {
    type: UNREGISTER_COACH_NOTIFICATIONS,
    payload: {
      contextName,
    },
  };
}

// This is supposed to be set as the value of onLayout
// on the component it should point at.
export function setCoachNotificationPosition(
  event,
  contextName,
  name,
  offset = {}
) {
  const { nativeEvent: { layout: { width = 0, x = 0, y = 0 } = {} } = {} } =
    event;

  const pointerWidth = 20;
  const top = y + (offset.y || 0);
  const left = x + (offset.x || 0) + width / 2 - pointerWidth / 2;

  return {
    type: SET_COACH_NOTIFICATION_POSITION,
    payload: {
      contextName,
      name,
      top,
      left,
    },
  };
}

export function hideCoachNotification(contextName, name) {
  return {
    type: HIDE_COACH_NOTIFICATION,
    payload: { contextName, name },
  };
}

export function showCoachNotification(contextName, name) {
  return {
    type: SHOW_COACH_NOTIFICATION,
    payload: { contextName, name },
  };
}
