import { localeMap } from './types';

export function getCurrentLocale({ intl }, short = false) {
  const { currentLocale, defaultLocale } = intl;

  if (short) {
    return currentLocale;
  }

  return localeMap[currentLocale] || localeMap[defaultLocale];
}
