import { _t } from '@mindoktor/patient-legacy/i18n';
import React from 'react';

import Button from '../../components/button/button';
import LimitedHtml from '../../components/html/limited';
import Modal from '../../components/modal';
import { hideModal } from '../../routing';

const HtmlModal = ({ html }) => (
  <Modal
    body={<LimitedHtml html={html} />}
    footer={
      <Button type="ghost" color="black" onClick={hideModal}>
        {_t('common.close')}
      </Button>
    }
  />
);

export default HtmlModal;
