import map from 'lodash-es/map';
import React from 'react';

import colors from '../../../common/colors';
import { fonts } from '../../../common/components/typography';

// type Props = {
//   html?: string,
//   style?: any,
// };

// type Node = {
//   tagName?: string,
//   innerText?: string,

//   data?: string,

//   href?: string,
//   src?: string,

//   width?: string | number,
//   height?: string | number,

//   loop?: any,
//   autoplay?: any,
//   muted?: any,

//   type?: string,

//   parentNode?: Node,
//   previousSibling?: Node,
//   nextSibling?: Node,

//   childNodes?: Node[],
//   dataset?: { color?: string },
// };

const colorMap = {
  highlight: colors.red_500,
  dim: colors.grey_500,
};

const Limited = ({ html, style }) => {
  const sanitizedHtml = ('<div>' + (html || '') + '</div>')
    .trim()
    .replace(/>\s+</g, '><')
    .replace(/<!--(.*?)-->/g, '');

  if (!sanitizedHtml) return null;

  const root = new DOMParser().parseFromString(sanitizedHtml, 'text/html');
  if (!root.body) return null;

  return <div style={style}>{renderChildNodes(root.body.childNodes)}</div>;
};

function renderNode(node, key) {
  const props = { key };

  switch (node.tagName ? node.tagName.toLowerCase() : undefined) {
    case 'li':
      return (
        <li
          {...props}
          style={{
            padding: 0,
            margin: 0,
            marginTop: node.previousSibling ? 6 : 0,
          }}
        >
          {renderChildNodes(node.childNodes)}
        </li>
      );

    case 'ul':
      return (
        <ul
          {...props}
          style={{
            padding: 0,
            margin: 0,
            marginTop: node.previousSibling ? 12 : 0,
            marginLeft: 20,
          }}
        >
          {renderChildNodes(node.childNodes)}
        </ul>
      );

    case 'p':
      return (
        <div
          {...props}
          style={{
            marginTop: node.previousSibling ? 12 : 0,
          }}
        >
          {renderChildNodes(node.childNodes)}
        </div>
      );

    case 'h1':
    case 'h2':
    case 'h3':
      return (
        <div
          {...props}
          style={{
            marginTop: node.previousSibling ? 12 : 0,
          }}
        >
          {renderChildNodes(node.childNodes)}
        </div>
      );

    case 'br':
      return <br {...props} />;

    case 'a':
      const { href } = node;

      return (
        <a {...props} href={href}>
          {renderChildNodes(node.childNodes)}
        </a>
      );

    case 'img': {
      const { width, height, src } = node;

      return (
        <img
          {...props}
          style={{
            width: width || 'auto',
            height: height || 'auto',
            maxWidth: '100%',
            margin: 0,
            padding: 0,
            display: 'block',
            borderRadius: 3,
          }}
          src={src}
        />
      );
    }

    case 'video': {
      const { width, loop, autoplay, muted } = node;

      const baseWidth = Math.min(600, window.innerWidth) - 40;

      return (
        <video
          {...props}
          style={{
            width: width || 'auto',
            height: baseWidth * (9 / 16),
            maxWidth: '100%',
            backgroundColor: '#eee',
            borderRadius: 3,
            margin: 0,
            padding: 0,
            display: 'block',
          }}
          loop={getBooleanAttributeValue(loop)}
          autoPlay={getBooleanAttributeValue(autoplay)}
          muted={getBooleanAttributeValue(muted)}
          controls={
            !getBooleanAttributeValue(autoplay) ||
            !getBooleanAttributeValue(loop)
          }
          playsInline={true}
          preload="auto"
          onLoadedData={(event) => (event.target.style.height = 'auto')}
        >
          {renderChildNodes(node.childNodes)}
        </video>
      );
    }

    case 'source': {
      const { src, type } = node;

      return <source {...props} src={src} type={type} />;
    }

    case null:
    case undefined:
      const text = (node.data || '')
        .replace(/\s+/g, ' ')
        .replace(
          /^\s+/,
          !node.previousSibling || isView(node.previousSibling) ? '' : ' '
        )
        .replace(
          /\s+$/,
          !node.nextSibling || isView(node.nextSibling) ? '' : ' '
        );

      if (text === '') return null;

      return (
        <span {...props} style={getTextStyles(node)}>
          {text}
        </span>
      );

    case 'div':
      // Not sure how this code is supposed to work, and can't fix it.
      // $FlowFixMe
      return props.className ? (
        <div {...props}>{renderChildNodes(node.childNodes)}</div>
      ) : (
        renderChildNodes(node.childNodes)
      );

    default:
      return renderChildNodes(node.childNodes);
  }
}

const isView = (node) =>
  ['li', 'ul', 'p', 'br', 'img', 'video', 'source', 'h1', 'h2', 'h3'].indexOf(
    node.tagName && node.tagName.toLowerCase()
  ) > -1;

const getBooleanAttributeValue = (value) =>
  value !== undefined && value !== null && value !== false;

function renderChildNodes(children) {
  return map(children || [], (c, i) => renderNode(c, i));
}

function getTextStyles(node) {
  let styles = {};
  let parent = node.parentNode || undefined;

  const colorDefinition = parent && parent.dataset && parent.dataset.color;
  const color = colorDefinition && colorMap[colorDefinition];
  while (parent) {
    const key = parent.tagName ? parent.tagName.toLowerCase() : 'fallback';
    styles = {
      ...textStyles[key],
      ...styles,
    };
    parent = parent.parentNode;
  }

  return {
    fontFamily: fonts.body,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '24px',
    ...styles,
    ...(color && { color }),
  };
}

const textStyles = {
  fallback: {},

  a: {
    color: colors.red_500,
    textDecorationLine: 'underline',
  },

  i: {},

  b: {
    fontFamily: fonts.body,
    fontWeigt: 'bold',
  },

  strong: {
    fontFamily: fonts.body,
    fontWeigt: 'bold',
  },

  em: {
    fontFamily: fonts.body,
    fontWeigt: 'bold',
  },

  h1: {
    fontFamily: fonts.header,
    fontWeigt: 'bold',
    fontSize: 24,
    lineHeight: '30px',
  },

  h2: {
    fontFamily: fonts.header,
    fontWeigt: 'bold',
    fontSize: 22,
    lineHeight: '28px',
  },

  h3: {
    fontFamily: fonts.header,
    fontWeigt: 'bold',
    fontSize: 20,
    lineHeight: '26px',
  },
};

export default Limited;
