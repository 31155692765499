import { _t } from '@mindoktor/patient-legacy/i18n';
import React from 'react';

import Markdown from '../../../web/components/markdown';

const styles = {
  text: {
    whiteSpace: 'pre-wrap',
  },
};

export default class ConsentMarkdown extends React.Component {
  render() {
    return (
      <Markdown
        source={_t('consent.informed.content')}
        style={{ ...this.props.style, ...styles.text }}
      />
    );
  }
}
