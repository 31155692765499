import React, { useRef } from 'react';

import { Fab } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import ScrollViewWithScrollDownButton from '@mindoktor/pulse/src/components/ScrollViewWithScrollDownButton/web';
import BackChevronIcon from '@mindoktor/pulse/src/icons/BackChevronIcon/BackChevronIcon';
import { colors } from '@mindoktor/pulse/src/styles/colors';

import { isMobile } from '@mindoktor/patient-app/utils/device/web';

import { useVisibleHeight } from '../../../hooks/useVisibleHeight/web/useVisibleHeight';
import SkandiaAttribution, {
  isSkandiaSite,
} from '../../company/SkandiaAttribution';
import Footer from '../../Footer/web/Footer';
import Header from '../../Header/web/Header';

interface Props {
  children: React.ReactNode;
  showFooter?: boolean;
  showMenuBarActions?: boolean;
  showBackButton?: boolean;
  onBack?: () => void;
}

/** Fixed full-page layout containing an element with an inner scrollable component. */
const FixedContentLayout: React.FC<Props> = ({
  children,
  showFooter = true,
  showMenuBarActions = true,
  showBackButton = true,
  onBack = () => window.history.back(),
}) => {
  const contentRef = useRef(null);

  const visibleHeight = useVisibleHeight();

  return (
    <Stack
      sx={{
        display: 'flex',
        // Mobile and desktop differs in how vertical screen estate is calculated.
        // A readup on the topic can be found here:
        // https://stackoverflow.com/questions/37112218/css3-100vh-not-constant-in-mobile-browser
        height: isMobile ? 'unset' : '100vh',
        minHeight: isMobile ? visibleHeight : 'unset',
      }}
    >
      <ScrollViewWithScrollDownButton contentRef={contentRef} flexGrow={1}>
        <Stack id="fixed-content-root" ref={contentRef} flexGrow={1}>
          <Box>
            <Header showMenuBarActions={showMenuBarActions} />
            {isSkandiaSite() && <SkandiaAttribution />}
            {showBackButton && (
              <Box sx={{ position: 'relative' }}>
                <Fab
                  sx={({ breakpoints: { up, down } }) => ({
                    position: 'absolute',
                    top: '1rem',
                    left: '1rem',
                    '&.MuiFab-root': {
                      filter: 'drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.25))',
                      boxShadow: 'none',
                    },
                    [up('md')]: {
                      top: '1.5rem',
                      left: '1.5rem',
                    },
                    zIndex: 1,
                  })}
                  color="secondary"
                  onClick={onBack}
                >
                  <BackChevronIcon color={colors.gray[900]} fontSize={2} />
                </Fab>
              </Box>
            )}
          </Box>
          <Stack
            id="fixed-content-content"
            sx={{
              padding: 0,
              flex: 1,
            }}
          >
            {children}
          </Stack>
          {showFooter && (
            <Box>
              <Footer />
            </Box>
          )}
        </Stack>
      </ScrollViewWithScrollDownButton>
    </Stack>
  );
};

export default FixedContentLayout;
