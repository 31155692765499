import { _t } from '@mindoktor/patient-legacy/i18n';
import dayjs from 'dayjs';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import colors from '../../../common/colors';
import { Body1 } from '../../../common/components/typography';
import { showNotification } from '../../../state/notifications/actions';
import { formatRoute, routes } from '../../../state/routing';
import {
  bookAppointment,
  fetchAvailableTimeslots,
} from '../../../state/scheduler/actions';
import BackBar from '../../components/bar/back';
import TruncatedFooter from '../../components/footer/truncated';
import MediaQuery from '../../components/media_query';
import Screen from '../../components/screen';
import { goBack } from '../../routing';
import AvailableDay from './available_day_list_item';

const styles = {
  body: {
    width: '100%',
    maxWidth: 800,
    margin: '0 auto',
    paddingTop: 20,
    paddingBottom: 20,
  },

  inner: {
    maxWidth: 640,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  divider: {
    height: 2,
    width: '95%',
    backgroundColor: colors.grey_200,
    marginTop: 10,
    marginBottom: 20,
  },
};

// type Props = {
//   caseId: number,
//   caregiverId: number,
//   fetchAvailableTimeslots: typeof fetchAvailableTimeslots,
//   bookAppointment: typeof bookAppointment,
// };

// type State = {
//   clickedDayIndex: number,
//   clickedTimeSlotIndex: number,
//   availableDays: AvailableDay[],
//   failedToFetchAvailableDays: boolean,
// };

export class Scheduler extends Component {
  state = {
    clickedDayIndex: -1,
    clickedTimeSlotIndex: -1,
    availableDays: [],
    failedToFetchAvailableDays: false,
  };

  async componentDidMount() {
    const { fetchAvailableTimeslots, caregiverId } = this.props;
    const from = dayjs();
    const to = dayjs().add(1, 'year');
    const response = await fetchAvailableTimeslots(
      caregiverId,
      from.format('YYYY-MM-DD'),
      to.format('YYYY-MM-DD')
    );
    if (response.error) {
      this.setState({
        failedToFetchAvailableDays: true,
      });
    } else {
      this.setState({
        availableDays: response.availableDays,
      });
    }
  }

  handleTimeSlotClicked(clickedDayIndex, clickedTimeslotIndex) {
    this.setState({
      clickedDayIndex: clickedDayIndex,
      clickedTimeSlotIndex: clickedTimeslotIndex,
    });
  }

  async handleTimeSlotBooked(start, end) {
    const { caseId, caregiverId, bookAppointment, showNotification } =
      this.props;
    const response = await bookAppointment(caseId, caregiverId, start, end);
    if (response.error) {
      showNotification({
        message: _t('scheduler.bookFailed'),
      });
    } else {
      goBack();
    }
  }

  goBack() {
    const { caseId } = this.props;
    window.location.href = `#${formatRoute(routes.CASE_MESSAGES, {
      id: caseId,
    })}`;
  }

  render() {
    const {
      clickedTimeSlotIndex,
      clickedDayIndex,
      availableDays,
      failedToFetchAvailableDays,
    } = this.state;
    return (
      <Screen
        header={
          <div>
            <BackBar
              title={_t('scheduler.chooseTime')}
              dark={true}
              maxWidth={1000}
              onBack={this.goBack.bind(this)}
            />
          </div>
        }
        body={
          <div
            style={styles.body}
            onClick={() => {
              this.setState({
                clickedTimeSlotIndex: -1,
              });
            }}
          >
            <div style={styles.inner}>
              {failedToFetchAvailableDays && (
                <Body1>{_t('scheduler.failedToFetchAvailableTimeSlots')}</Body1>
              )}
              {!failedToFetchAvailableDays && availableDays.length === 0 && (
                <Body1>{_t('scheduler.noAvailableTimeSlots')}</Body1>
              )}
              {availableDays.map((day, i) => (
                <div key={i} style={{ width: '100%' }}>
                  <AvailableDay
                    day={day.date}
                    timeSlots={day.timeslots}
                    disabled={clickedTimeSlotIndex !== -1}
                    index={i}
                    clickedTimeSlotIndex={
                      clickedDayIndex === i ? clickedTimeSlotIndex : -1
                    }
                    handleTimeSlotSelected={this.handleTimeSlotClicked.bind(
                      this
                    )}
                    handleTimeSlotBooked={this.handleTimeSlotBooked.bind(this)}
                  />
                  {i < availableDays.length - 1 && (
                    <div style={styles.divider} />
                  )}
                </div>
              ))}
            </div>
          </div>
        }
        footer={
          <MediaQuery minWidth={801}>
            <TruncatedFooter />
          </MediaQuery>
        }
        showScrollGradient={true}
      />
    );
  }
}

const mapDispatchToProps = {
  fetchAvailableTimeslots,
  bookAppointment,
  showNotification,
};

export default connect(null, mapDispatchToProps)(Scheduler);
