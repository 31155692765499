import React from 'react';
import { Transition } from 'react-spring';

// type Props = {
//   children: Object => React.Node,
//   from?: number,
//   to?: number,
//   stiffness?: number,
//   visible: boolean,
//   onRest?: (*) => *,
//   native?: boolean,
// };

export default class SlideTransition extends React.Component {
  render() {
    const {
      props: {
        children,
        from = 0,
        to = 0,
        // todo: check, is this not working in react-spring?
        stiffness = 170,
        visible,
        native,
      },
    } = this;

    return (
      <Transition
        native={native}
        from={{ transform: `translateY(${from}px)` }}
        enter={{ transform: `translateY(${to}px)` }}
        leave={{ transform: `translateY(${from}px)` }}
        stiffness={stiffness}
      >
        {visible ? (style) => children(style) : null}
      </Transition>
    );
  }
}
