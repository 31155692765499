import React from 'react';

import BoxedScreen from '../../components/boxed_screen';

const styles = {
  body: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
  },

  previous: {
    width: '100%',
    maxWidth: 400,
    marginRight: 'auto',
    paddingTop: 20,
    paddingBottom: 36,
  },

  content: {
    width: '100%',
    maxWidth: 600,
    marginLeft: 'auto',
    marginTop: 0,
    paddingTop: 0,
  },

  footer: {
    width: '100%',
    maxWidth: 600,
    marginLeft: 'auto',
  },
};

// type Props = {
//   header?: any,
//   previous?: any,
//   content?: any,
//   footer?: any,
// };

const BoxedScreenComponent = ({
  header,
  previous,
  content,
  footer,
  staticFooter = true,
}) => (
  <BoxedScreen
    header={header}
    body={
      <div style={styles.body}>
        {previous ? <div style={styles.previous}>{previous}</div> : <div />}

        <div style={styles.content}>{content}</div>
      </div>
    }
    footer={<div style={styles.footer}>{footer}</div>}
    staticFooter={staticFooter}
    showScrollGradient={true}
    showScrollIndicator={true}
  />
);

export default BoxedScreenComponent;
