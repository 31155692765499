import { _t } from '@mindoktor/patient-legacy/i18n';
import React from 'react';
import { connect } from 'react-redux';

import Button from '../../../common/components/Button';
import { Body1, SubHead } from '../../../common/components/typography';
import spacing from '../../../common/spacing';
import { postApi } from '../../../state/api/actions';
import Modal from '../../components/modal';
import { hideModal } from '../../routing';
import { getPlatform, Platforms } from '../../utils/device';

class HowToPrintModal extends React.Component {
  render() {
    const { viewDocument } = this.props;

    return (
      <Modal
        contentStyle={{ padding: spacing.t }}
        body={
          <div>
            <SubHead mb="t">{_t('howToPrint.printFromComputerHeader')}</SubHead>
            <Body1 mb="s">
              {_t('howToPrint.printFromComputerDescription')}
            </Body1>
            <SubHead mb="t">{_t('howToPrint.printFromMobileHeader')}</SubHead>
            <Body1 mb="s">
              {getPlatform() === Platforms.IOS
                ? _t('howToPrint.printFromMobileDescription')
                : _t('howToPrint.printFromMobileDescription.android')}
            </Body1>
          </div>
        }
        footer={
          <div>
            <Button
              type="solid"
              style={{ marginBottom: spacing.xt }}
              onPress={() => {
                hideModal();
                viewDocument();
              }}
            >
              {_t('howToPrint.openReferral')}
            </Button>
            <Button
              type="flat"
              onPress={() => {
                hideModal();
              }}
            >
              {_t('howToPrint.cancel')}
            </Button>
          </div>
        }
      />
    );
  }
}

export default connect((s) => s, { postApi })(HowToPrintModal);
