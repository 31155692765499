/**
 * Checks if `time a` < `time b`.
 * @param a Timestamp
 * @param b Timestamp
 * @returns True if `time a` < `time b`, false in any other case, including parse failure.
 */
export const isTimestampBefore = (a: string, b: string): boolean => {
  const aTime = new Date(a).getTime();
  const bTime = new Date(b).getTime();
  if (isNaN(aTime) || isNaN(bTime)) {
    console.warn(
      `isTimestampBefore failed to parse timestamps: a=${aTime}, b=${bTime}`
    );
    return false;
  }
  return aTime < bTime;
};
