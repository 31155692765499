import React, { useState } from 'react';

import { useTranslation } from '@mindoktor/patient-app/localization/hooks/useTranslation';

import { useFileMutator } from '../../../files/hooks/useFileMutator';
import { useNotifications } from '../../../notifications';
import { removeMediaAsset } from '../../functions/mediaPicker/mediaPicker';
import { MediaAsset } from '../../functions/mediaPicker/types';
import { useBottomSheet } from '../../hooks/useBottomSheet';
import { useConversationId } from '../../hooks/useConversationId';
import { useMessageMutator } from '../../hooks/useMessageMutator';

import MediaChooserSheetContent from './MediaChooserSheetContent/MediaChooserSheetContent';
import MessageComposer from './MessageComposer';

const MessageComposerContainer: React.FC = () => {
  const t = useTranslation('messaging');
  const notifications = useNotifications();
  const sheet = useBottomSheet();

  const conversationId = useConversationId();

  const [message, setMessage] = useState('');
  const [isSending, setIsSending] = useState(false);
  const messageMutator = useMessageMutator(conversationId);

  const [mediaAsset, setMediaAsset] = useState<MediaAsset>();
  const fileMutator = useFileMutator();

  const onSubmit = async (message: string) => {
    setIsSending(true);

    try {
      const attachments = [];
      if (mediaAsset != null) {
        const response = await fileMutator.mutateAsync(mediaAsset);
        if (response == null) {
          throw new Error('Failed to retrieve file id');
        }

        attachments.push({ fileId: response.data });
      }

      const content = message.trim();
      if (content.length === 0 && mediaAsset == null) {
        return;
      }

      const response = await messageMutator.mutateAsync({
        content,
        attachments,
      });
      if (response == null) {
        throw new Error('Failed to retrieve message response');
      }

      setMessage('');
      if (mediaAsset != null) {
        await onRemoveMediaAsset(mediaAsset);
      }
    } catch (error) {
      console.error(error);
      notifications.pushWarning(t('errors.mutateConversation'));
    } finally {
      setIsSending(false);
    }
  };

  const onOpenMediaChooser = () => {
    sheet.setContent(
      <MediaChooserSheetContent
        onClose={sheet.close}
        onChangeMediaAsset={onChangeMediaAsset}
        onError={onOpenMediaChooserError}
      />
    );
    sheet.open();
  };

  const onOpenMediaChooserError = (error: Error) => {
    notifications.pushError(t('mediaPicker.openError'));
    console.error(error);
  };

  const onChangeMediaAsset = (asset: MediaAsset) => {
    setMediaAsset(asset);
    sheet.close();
  };

  const onRemoveMediaAsset = async (asset: MediaAsset) => {
    await removeMediaAsset(asset);
    setMediaAsset(undefined);
  };

  return (
    <MessageComposer
      mediaAsset={mediaAsset}
      isSending={isSending}
      message={message}
      setMessage={setMessage}
      onSubmit={onSubmit}
      onRemoveMediaAsset={onRemoveMediaAsset}
      onOpenMediaChooser={onOpenMediaChooser}
    />
  );
};

export default MessageComposerContainer;
