import { ThemeOptions } from '@mui/material/styles';

const defaultColor = 'hero.main';
const isDefault = (color: unknown) => color == null || color === defaultColor;

const overrides: ThemeOptions['components'] = {
  MuiLink: {
    defaultProps: {
      // Specifying `main` should not be necessary
      // See issue: https://github.com/mui/material-ui/issues/29596
      color: defaultColor,
      fontWeight: 'medium',
    },
    styleOverrides: {
      root: ({ ownerState: { color }, theme: { palette } }) => ({
        cursor: 'pointer',
        textUnderlineOffset: '0.25em',
        textDecorationColor: isDefault(color)
          ? palette.hero.main
          : (color as string),

        '&:hover': {
          color: isDefault(color) ? palette.hero.light : color,
          textDecorationColor: isDefault(color) ? palette.hero.light : color,
        },
      }),
    },
  },
};

const linkOverrides = overrides?.MuiLink;
export default linkOverrides;
