import React from 'react';

import constants from '../../../constants';
import Image from '../../primitives/Image';
import View from '../../primitives/View';
import { Body1, Title2 } from '../../typography';

const PictureInstructionCard = ({ title, info, animation }) => (
  <View style={styles.instructionCard}>
    <View>
      <Title2 mb="s" style={styles.instructionCardTitle}>
        {title}
      </Title2>
      <Body1 mb="t" marginBottom={constants.spacingPixie}>
        {info}
      </Body1>
    </View>
    <Image source={animation} style={styles.instructionCardImage} />
  </View>
);

export default PictureInstructionCard;

const styles = {
  instructionCard: {
    flex: 1,
    justifyContent: 'space-between',
  },
  instructionCardImage: {
    width: '100%',
  },
  instructionCardTitle: { marginTop: constants.spacingPixie },
};
