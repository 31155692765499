import React from 'react';

import colors from '../../colors';
import Platform from '../Platform';
import TextTemplate from './TextTemplate';

const platformSwitch = (ios: any, android: any, def: any) => {
  switch (Platform.OS) {
    case 'ios':
      return ios;
    case 'android':
      return android;
    default:
      return def;
  }
};

export const webFamily =
  '"Graphik", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif';

export const fontFamilies = {
  graphikLight: platformSwitch(
    'GraphikLCApp-Light',
    'Graphik-Light',
    webFamily
  ),
  graphikRegular: platformSwitch(
    'GraphikLCApp-Regular',
    'Graphik-Regular',
    webFamily
  ),
  graphikMedium: platformSwitch(
    'GraphikLCApp-Medium',
    'Graphik-Medium',
    webFamily
  ),
  graphikSemibold: platformSwitch(
    'GraphikLCApp-Semibold',
    'Graphik-Semibold',
    webFamily
  ),
  graphikBold: platformSwitch('GraphikLCApp-Bold', 'Graphik-Bold', webFamily),
};

export const pulseFonts = {
  /* Header Fonts */
  header1: {
    fontFamily: fontFamilies.graphikBold,
    fontSize: 36,
    lineHeight: 36 * 1.1,
    color: colors.off_black,
    fontWeight: '800',
  },
  header2: {
    fontFamily: fontFamilies.graphikSemibold,
    fontSize: 26,
    lineHeight: 26 * 1.2,
    color: colors.off_black,
    fontWeight: '600',
  },
  header3: {
    fontFamily: fontFamilies.graphikMedium,
    fontSize: 18,
    lineHeight: 18 * 1.1,
    color: colors.off_black,
    fontWeight: '500',
  },
  body1: {
    fontFamily: fontFamilies.graphikRegular,
    fontSize: 16,
    lineHeight: 24,
    color: colors.off_black,
  },
  body1Strong: {
    fontFamily: fontFamilies.graphikMedium,
    fontSize: 16,
    lineHeight: 24,
    color: colors.off_black,
  },
  body2: {
    fontFamily: fontFamilies.graphikRegular,
    fontSize: 13,
    lineHeight: 20,
    color: colors.off_black,
  },
  body2Strong: {
    fontFamily: fontFamilies.graphikSemibold,
    fontSize: 13,
    lineHeight: 20,
    color: colors.off_black,
  },
  button: {
    fontFamily: fontFamilies.graphikMedium,
    fontSize: 18,
    lineHeight: 20,
    color: colors.white,
  },
};

export const fonts = {
  /* Header Fonts */
  display: {
    fontFamily: fontFamilies.graphikSemibold,
    fontSize: 36,
    lineHeight: 44,
    color: colors.off_black,
    fontWeight: '600',
  },
  title1: {
    fontFamily: fontFamilies.graphikSemibold,
    fontSize: 28,
    lineHeight: 36,
    color: colors.off_black,
    fontWeight: platformSwitch('600', '700', '600'),
  },
  title2: {
    fontFamily: fontFamilies.graphikMedium,
    fontSize: 22,
    lineHeight: 28,
    color: colors.off_black,
    fontWeight: '500',
  },
  subhead: {
    fontFamily: fontFamilies.graphikMedium,
    fontSize: 19,
    lineHeight: 26,
    color: colors.off_black,
    fontWeight: '500',
  },
  intro: {
    fontFamily: fontFamilies.graphikRegular,
    fontSize: 18,
    lineHeight: 26,
    color: colors.off_black,
  },
  introMedium: {
    fontFamily: fontFamilies.graphikMedium,
    fontSize: 18,
    lineHeight: 26,
    color: colors.off_black,
  },
  body1: {
    fontFamily: fontFamilies.graphikRegular,
    fontSize: 16,
    lineHeight: 24,
    color: colors.off_black,
  },
  body1Medium: {
    fontFamily: fontFamilies.graphikMedium,
    fontSize: 16,
    lineHeight: 24,
    color: colors.off_black,
  },
  body2: {
    fontFamily: fontFamilies.graphikRegular,
    fontSize: 14,
    lineHeight: 20,
    color: colors.off_black,
  },
  body2Medium: {
    fontFamily: fontFamilies.graphikMedium,
    fontSize: 14,
    lineHeight: 20,
    color: colors.off_black,
  },
  body3: {
    fontFamily: fontFamilies.graphikRegular,
    fontSize: 13,
    lineHeight: 20,
    color: colors.off_black,
  },
  body3Medium: {
    fontFamily: fontFamilies.graphikMedium,
    fontSize: 13,
    lineHeight: 20,
    color: colors.off_black,
  },
  body4: {
    fontFamily: fontFamilies.graphikRegular,
    fontSize: 11,
    lineHeight: 18,
    color: colors.off_black,
  },
  body5: {
    fontFamily: fontFamilies.graphikRegular,
    fontSize: 9,
    lineHeight: 13,
    color: colors.off_black,
    marginBottom: 12,
  },

  tab: {
    fontFamily: fontFamilies.graphikMedium,
    fontSize: 9,
    lineHeight: 16,
    color: colors.off_black,
    marginBottom: 2,
  },
  button: {
    fontFamily: fontFamilies.graphikMedium,
    fontSize: 18,
    lineHeight: 26,
    textAlign: 'center' as const,
    color: colors.off_black,
    includeFontPadding: false,
    textAlignVertical: 'center' as const,
  },
  textLink: {
    fontFamily: fontFamilies.graphikMedium,
    fontSize: 18,
    lineHeight: 26,
    color: colors.red_500,
    marginBottom: 12,
  },
  textLinkUnderline: {
    fontFamily: fontFamilies.graphikMedium,
    fontSize: 9,
    lineHeight: 16,
    color: colors.off_black,
    textDecoration: 'underline',
    marginBottom: 12,
  },

  // web legacy, proper typography components should be used, cleanup later
  body: webFamily,
  header: webFamily,
};

/***************************************************************************************************
 * HeaderTexts
 ***************************************************************************************************/

export const Display = (props: any) => (
  <TextTemplate {...props} font={fonts.display} />
);

export const Title1 = (props: any) => (
  <TextTemplate {...props} font={fonts.title1} />
);

export const Title2 = (props: any) => (
  <TextTemplate {...props} font={fonts.title2} />
);

export const Header1 = (props: any) => (
  <TextTemplate {...props} font={pulseFonts.header1} />
);

export const Header2 = (props: any) => (
  <TextTemplate {...props} font={pulseFonts.header2} />
);

export const Header3 = (props: any) => (
  <TextTemplate {...props} font={pulseFonts.header3} />
);

export const SubHead = (props: any) => (
  <TextTemplate {...props} font={fonts.subhead} />
);

/***************************************************************************************************
 * BodyTexts
 ***************************************************************************************************/
export const Intro = (props: any) => (
  <TextTemplate {...props} font={fonts.intro} />
);

export const IntroMedium = (props: any) => (
  <TextTemplate {...props} font={fonts.introMedium} />
);

export const Body1 = (props: any) => (
  <TextTemplate {...props} font={fonts.body1} />
);
export const Body1Medium = (props: any) => (
  <TextTemplate {...props} font={fonts.body1Medium} />
);
export const Body1Strong = (props: any) => (
  <TextTemplate {...props} font={pulseFonts.body1Strong} />
);

export const Body2 = (props: any) => (
  <TextTemplate {...props} font={fonts.body2} />
);
export const Body2Medium = (props: any) => (
  <TextTemplate {...props} font={fonts.body2Medium} />
);
export const Body2Strong = (props: any) => (
  <TextTemplate {...props} font={pulseFonts.body2Strong} />
);

export const Body3 = (props: any) => (
  <TextTemplate {...props} font={fonts.body3} />
);

export const Body4 = (props: any) => (
  <TextTemplate {...props} font={fonts.body4} />
);

export const Body5 = (props: any) => (
  <TextTemplate {...props} font={fonts.body5} />
);

export const TextLink = (props: any) => (
  <TextTemplate {...props} font={fonts.textLink} />
);

export default Body1;
