import isArray from 'lodash-es/isArray';
import React, { Component } from 'react';

import OptionInput from '../../components/input/option';

const styles = {
  root: {
    marginBottom: 20,
  },
};

// type ItemProps = {
//   option: Option,
//   checked: boolean,
//   select: any => any,
//   lastItem: boolean,
// };

class MultipleChoiceItem extends Component {
  select = () => {
    const { option, select } = this.props;

    select(option.value);
  };

  render() {
    const { option, checked, lastItem } = this.props;

    return (
      <OptionInput
        label={option.label}
        checked={checked}
        multiple={true}
        lastItem={lastItem}
        onChange={this.select}
      />
    );
  }
}

// type Props = {
//   options: Option[],
//   answer?: any,
//   update: (value?: any) => any,
// };

export default class MultipleChoice extends Component {
  select = (value) => {
    const { answer, update } = this.props;

    if (answer && isArray(answer) && answer.includes(value)) {
      update(answer.filter((v) => v !== value));
    } else {
      update([...(answer && isArray(answer) ? answer : []), value]);
    }
  };

  render() {
    const { options, answer } = this.props;

    return (
      <div style={styles.root}>
        {options.map((option, i) => (
          <MultipleChoiceItem
            key={option.index}
            option={option}
            checked={
              !!(answer && isArray(answer) && answer.includes(option.value))
            }
            select={this.select}
            lastItem={i === options.length - 1}
          />
        ))}
      </div>
    );
  }
}
