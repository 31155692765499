import { _t } from '@mindoktor/patient-legacy/i18n';
import React, { Component } from 'react';

import insuranceGuaranteedMarkerIconSource from '../../../../images/referrals/location_pin_insuranceok.svg';
import insuranceNotGuaranteedMarkerIconSource from '../../../../images/referrals/location_pin_noguarantee.svg';
import { Body1 } from '../../../common/components/typography';
import { caseReferralType } from '../../../state/cases/constants';
import Button from '../../components/button/button';
import Markdown from '../../components/markdown';
import Modal from '../../components/modal';
import { getSiteOptions } from '../../utils/site';

const styles = {
  levelExplainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 20,
  },

  levelIcon: {
    height: 30,
    marginRight: 10,
  },
};
export default class ReferralLocationInfoModal extends Component {
  render() {
    const { referralType, hideModal } = this.props;

    return (
      <Modal
        body={
          <div>
            <Markdown
              source={
                referralType === caseReferralType.XRay ||
                referralType === caseReferralType.ClinicalPhysiology
                  ? _t('referral.location.infoForXrayOrClinicalPhysiology')
                  : _t('referral.location.info')
              }
            />

            {getSiteOptions().showLabGuaranteeLevel &&
              referralType === caseReferralType.LabTest && (
                <div>
                  <Body1 style={styles.levelExplainer}>
                    <img
                      src={insuranceGuaranteedMarkerIconSource}
                      style={styles.levelIcon}
                    />
                    {_t('referral.location.guaranteed')}
                  </Body1>

                  <Body1 style={styles.levelExplainer}>
                    <img
                      src={insuranceNotGuaranteedMarkerIconSource}
                      style={styles.levelIcon}
                    />
                    {_t('referral.location.notGuaranteed')}
                  </Body1>
                </div>
              )}
          </div>
        }
        footer={
          <Button type="ghost" color="black" onClick={hideModal}>
            {_t('referral.location.continue')}
          </Button>
        }
      />
    );
  }
}
