import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';

import { useNotifications } from '@mindoktor/patient-app/adapters/hooks/useNotifications';
import { ResponseError } from '@mindoktor/patient-app/api/responseError';
import { useTranslation } from '@mindoktor/patient-app/localization/hooks/useTranslation';

import { MARKETING_CONSENT_QUERY_KEY } from '../../marketing-consents/hooks/useMarketingConsentApi';
import { RegistrationFormType } from '../api/models/registration';
import { userApi } from '../api/user';

import { userDetailsQueryKey } from './useUserDetailsApi';
import { userStatusQueryKey } from './useUserStatusApi';

type RegistrationMutation = UseMutationResult<
  void,
  ResponseError,
  RegistrationFormType
>;

export const useFinalizeRegistrationMutator = (): RegistrationMutation => {
  const notifications = useNotifications();
  const t = useTranslation('user', 'registration');

  const queryClient = useQueryClient();
  return useMutation(
    async (request: RegistrationFormType) => {
      const response = await userApi.postFinalizeRegistration(request);
      if (!response.ok) {
        throw new ResponseError(response);
      }
    },
    {
      onSuccess: async () => {
        notifications.showSuccess(t('success'));

        await queryClient.invalidateQueries([userDetailsQueryKey]);
        await queryClient.invalidateQueries([userStatusQueryKey]);
        await queryClient.invalidateQueries([MARKETING_CONSENT_QUERY_KEY]);
      },
    }
  );
};
