import { getFormularyState } from '../formulary/selectors';
import { toFormularyKey } from '../formulary/utils';

export const getHealthProfileFormularyKey = (state, childUuid) => {
  const reference = childUuid
    ? state.healthProfile.children && state.healthProfile.children[childUuid]
    : state.healthProfile;

  if (!reference) return undefined;

  const { formularyId, formularyVersion } = reference;

  return toFormularyKey({
    formularyId,
    formularyVersion,
    childUuid,
  });
};

export const getHealthProfileFormularyState = (state, childUuid) => {
  const formularyKey = getHealthProfileFormularyKey(state, childUuid);

  return formularyKey ? getFormularyState(state, formularyKey) : undefined;
};
