import { useEffect, useState } from 'react';

import {
  ScrollPositionOption,
  ScrollPositionOptionDict,
} from '@mindoktor/pulse/src/components/ListView/constants';

import { useUserProfile } from '../../adapters/hooks/useUserProfile';
import { isOwnMessage, Message } from '../api/models/conversation';
import { getFirstUnreadMessageId } from '../functions/getFirstUnreadMessageId';
import { getLandingScrollTarget } from '../functions/getLandingScrollTarget';

import { useConversationApi } from './useConversationApi';
import { useLastReadMessage } from './useLastReadMessage';
import { useMarkMessageAsRead } from './useMarkMessageAsRead';

/**
 * Manages a conversation with scrolling behavior and read message functionality.
 *
 * Note: Returns an empty list until all effects (e.g. last read message and where to scroll) are completed,
 * in order to keep the behavior correctly in sync.
 */
export const useConversation = (conversationId: number | null) => {
  const [hasPerformedInitialScrolling, setHasPerformedInitialScrolling] =
    useState(false);

  const [isScrollBottom, setIsScrollBottom] = useState(true);
  const [firstUnreadMessageId, setFirstUnreadMessageId] = useState<number>();
  const [scrollTargetKey, setScrollTargetKey] = useState<number>();
  const [scrollPositionOption, setScrollPositionOption] =
    useState<ScrollPositionOption>(ScrollPositionOptionDict.viewableAreaBottom);
  const [readyMessages, setReadyMessages] = useState<Message[]>([]);

  const conversation = useConversationApi(conversationId);
  const { isLoading: isLoadingLastReadMessage, id: lastReadMessageId } =
    useLastReadMessage(conversation);
  const markMessageAsRead = useMarkMessageAsRead(conversation);

  const userProfile = useUserProfile();

  const messages = conversation.data?.messages ?? [];

  // Handle behavior on initial landing
  useEffect(() => {
    if (
      hasPerformedInitialScrolling ||
      isLoadingLastReadMessage ||
      messages.length === readyMessages.length
    ) {
      return;
    }

    // Set first unread message id.
    const firstUnreadMessageId = getFirstUnreadMessageId(
      messages,
      lastReadMessageId
    );
    setFirstUnreadMessageId(firstUnreadMessageId ?? undefined);

    // Set scroll target and position.
    const { scrollTargetKey, scrollPositionOption } = getLandingScrollTarget(
      firstUnreadMessageId,
      messages
    );
    setScrollTargetKey(scrollTargetKey);
    setScrollPositionOption(scrollPositionOption);

    setReadyMessages(messages);

    setHasPerformedInitialScrolling(true);
  }, [messages, isLoadingLastReadMessage]);

  // Handle behavior when new messages arrive (sent or received)
  useEffect(() => {
    if (
      !hasPerformedInitialScrolling ||
      isLoadingLastReadMessage ||
      messages.length === readyMessages.length
    ) {
      return;
    }

    const lastMessage = messages[messages.length - 1];

    const isOwn = isOwnMessage(lastMessage.author, userProfile?.userId);

    // Handle scroll target and position.
    if (isScrollBottom || isOwn) {
      setScrollPositionOption(ScrollPositionOptionDict.viewableAreaBottom);
      setScrollTargetKey(lastMessage.id);
    }

    // Handle unread message id.
    if (isOwn) {
      // Always remove the unread marker if it's your own message.
      setFirstUnreadMessageId(undefined);
    } else if (!isScrollBottom && firstUnreadMessageId == null) {
      // If the user is not at the bottom and it's not your own, show the unread marker on the first unread message.
      const newFirstUnreadMessageId = getFirstUnreadMessageId(
        messages,
        lastReadMessageId
      );
      if (newFirstUnreadMessageId != null) {
        setFirstUnreadMessageId(newFirstUnreadMessageId);
      }
    }

    setReadyMessages(messages);
  }, [messages.length, isLoadingLastReadMessage]);

  return {
    isError: conversation.isError,
    messages: readyMessages,
    firstUnreadMessageId,
    scrollTargetKey,
    scrollPositionOption,
    setIsScrollBottom,
    markMessageAsRead,
  };
};
