// import { type AllowedLocales as AllowedLanguages } from '../../../configs/types';

// export type IntlState = {
//   +currentLocale: AllowedLanguages,
//   +defaultLocale: AllowedLanguages,
//   +initialNow: string,
//   +locales: AllowedLanguages[],
//   +messages?: Object,
// };

export const localeMap = {
  sv: 'sv-SE',
  en: 'en-GB',
};

export const countryMap = {
  GB: 'GB',
  GB: 'GB',
};

export const LocaleSvSE = 'sv-SE';
export const LocaleEnGB = 'en-GB';

// export type Locale = typeof LocaleSvSE | typeof LocaleEnGB;
