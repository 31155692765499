import { _t } from '@mindoktor/patient-legacy/i18n';
import React from 'react';
import { connect } from 'react-redux';

import retakePhotoIcon from '../../../../../images/icon/message_retakephoto.png';
import PictureInstructions from '../../../components/input/PictureInstructions';
import { API_URL_FILES } from '../../../../state/api';
import colors from '../../../colors';
import Button from '../../../components/Button';
import Image from '../../../components/primitives/Image';
import View from '../../../components/primitives/View';
import { Body1, Body3, Intro } from '../../../components/typography';
import { getDisplayTime } from '../../../utils';
import ItemTemplate from '../ItemTemplate';
import imageStyle from './imageStyle';

const style = {
  top: { display: 'flex', flexDirection: 'row' },
  icon: { width: 44, height: 44 },
  header: {
    paddingTop: 10,
    flex: 1,
  },
  time: {
    marginLeft: 16,
    color: colors.grey_500,
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
};

class RetakeItem extends React.Component {
  render() {
    const {
      attachmentId,
      attachmentUri,
      flipVertically,
      reasonDescription,
      freetext,
      timestamp,
      imageSource,
      site,
      getToken,
      uploadPhoto,
      uploadVideo,
      showModal,
      hideModal,
    } = this.props;

    const isImage = !!uploadPhoto;

    const uri = attachmentUri
      ? `file://${attachmentUri}`
      : `${API_URL_FILES}${attachmentId}?access_token=${getToken}&site=${site}`;

    return (
      <ItemTemplate flipVertically={flipVertically}>
        <View style={style.top}>
          <Image source={retakePhotoIcon} style={style.icon} mr="t" />

          <Intro fontWeight="500" style={style.header} mb="t">
            {isImage
              ? _t('case.retakePhoto.header')
              : _t('case.retakeVideo.header')}
          </Intro>
          <Body3 style={style.time}>{getDisplayTime(timestamp)}</Body3>
        </View>
        <Body1 mb="s">{reasonDescription}</Body1>

        {freetext && <Body1 mb="s">{freetext}</Body1>}
        <View style={style.imageContainer} mb="s">
          <Image
            source={imageSource || { uri: uri + '&thumb=true' }}
            style={imageStyle}
          />
        </View>
        {!!isImage && (
          <PictureInstructions showModal={showModal} hideModal={hideModal} />
        )}
        <Button type="solid" onPress={isImage ? uploadPhoto : uploadVideo}>
          {isImage
            ? _t('case.retakePhoto.addPhoto')
            : _t('case.retakeVideo.addVideo')}
        </Button>
      </ItemTemplate>
    );
  }
}
export default connect(undefined, {})(RetakeItem);
