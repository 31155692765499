import React from 'react';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import BackIcon from '@mindoktor/pulse/src/icons/BackIcon/BackIcon';

import { useCaseById } from '@mindoktor/patient-app/cases/hooks/useCaseById';
import { routes } from '@mindoktor/patient-app/routing/constants/webRoutes';
import { useNavigation } from '@mindoktor/patient-app/routing/hooks/useNavigation/web';

import { useCaseId } from '../../hooks/useCaseId';

const ConversationHeader: React.FC = () => {
  const caseId = useCaseId();
  const currentCase = useCaseById(caseId);

  const navigation = useNavigation();

  return (
    <Stack direction="row" paddingX="0.5rem" paddingTop="0.5rem">
      <IconButton
        color="transparent"
        size="large"
        onClick={() => navigation.navigate(routes.CASES)}
      >
        <BackIcon />
      </IconButton>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexGrow={1}
      >
        <Typography variant="body1" fontWeight={500}>
          {currentCase?.displayTitle}
        </Typography>
      </Box>
      {/* Placeholder for the icon button to come, to keep the elements aligned correctly */}
      <Box width="3rem" />
    </Stack>
  );
};

export default ConversationHeader;
