import React, { Component } from 'react';

import PictureInstructions from '../../../common/components/input/PictureInstructions';
import UploadFiles from '../../components/upload/files';
import { hideModal, showModal } from '../../routing';

const styles = {
  root: {
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 20,
  },
};

// type Props = {
//   answer?: any,
//   extras?: any,
//   update: (value?: any) => any,
// };

export default class Upload extends Component {
  remove = (id) => {
    const { answer, update } = this.props;

    update((answer || []).filter((v) => v !== id));
  };

  render() {
    const { answer, extras } = this.props;

    let instructions = extras && extras.instructions;

    return (
      <div style={styles.root}>
        <PictureInstructions
          instructions={instructions}
          showModal={showModal}
          hideModal={hideModal}
        />
        {answer && <UploadFiles files={answer} onRemove={this.remove} />}
      </div>
    );
  }
}
