import promiseRetry from 'promise-retry';

import { postApi } from '../api/actions';

/**
 * postCaseReopened posts a request for sending
 * a Pub/Sub-event with the subject "case.reopened" when
 * a patient reopens a case.
 * @param {*} caseId the id of the reopened case
 */
export const postCaseReopened = (caseId) => async (dispatch) => {
  promiseRetry(
    async function (retry, _) {
      const { error } = await dispatch(
        postApi('/api/v1/track-event', {
          caseId,
          eventName: 'case.reopened',
        })
      );
      if (error) {
        retry();
      }
    },
    { retries: 3 }
  );
};
