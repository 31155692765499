/** Extracts page.view tracking data for Questions and EditQuestions screens. */
export const getQuestionsTrackingDataFromProps = ({
  entrywayId,
  revisitId,
  childUuid,
  dynamicCode,
  origin,
  step,
  preferredCaregiverId,
}: Record<string, string | number | undefined>) => ({
  entrywayId,
  revisitId,
  childUuid,
  dynamicCode,
  origin,
  step,
  // this may be NaN because of some bad parseInt upstream in the app
  preferredCaregiverId:
    preferredCaregiverId?.toString() !== 'NaN'
      ? preferredCaregiverId
      : undefined,
});
