import React from 'react';
import { connect } from 'react-redux';
import scriptjs from 'scriptjs';

import { fetchVideochatCredentials } from '../../state/videochat/actions';
import { getVideochatCredentials } from '../../state/videochat/selectors';
import Spinner from '../components/spinner/screen';

// type LoaderProps = {
//   caseId: number | string,
//   doctorName: string,
//   doctorSignature: string,
//   doctorImageUrl: string,
//   apiKey?: string,
//   sessionId?: string,
//   token?: string,
//   precallSessionId?: string,
//   precallToken?: string,
//   userId?: number,
//   OT?: any,
//   fetchVideochatCredentials: (caseId: number | string) => void,
//   loadedVideochatCredentials?: boolean,
// };

class VideoLoader extends React.Component {
  mounted = false;
  state = { loadedScript: !!window.OT };

  load = async () => {
    const { caseId, loadedVideochatCredentials, fetchVideochatCredentials } =
      this.props;

    if (!loadedVideochatCredentials) {
      fetchVideochatCredentials(caseId);
    }
  };

  loadScript = async () => {
    const { loadedScript } = this.state;

    this.mounted = true;
    if (!loadedScript) {
      scriptjs(
        'https://static.opentok.com/v2.21.2/js/opentok.min.js',
        () => this.mounted && this.setState({ loadedScript: true })
      );
    }
  };

  loadstuff = async () => {
    await this.loadScript();

    this.load();
  };

  componentDidMount() {
    this.loadstuff();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    const { loadedVideochatCredentials, childComponent: ChildComponent } =
      this.props;
    const { loadedScript } = this.state;

    if (ChildComponent && loadedVideochatCredentials && loadedScript) {
      const componentProps = { ...this.props };
      componentProps.OT = window.OT;

      return <ChildComponent {...componentProps} />;
    }

    return <Spinner />;
  }
}

const ConnectedLoader = connect(
  (state, props) => {
    const { caseId, doctorName, doctorSignature, doctorImageUrl, OT } = props;

    const { apiKey, sessionId, token, userId } = getVideochatCredentials(
      state,
      caseId
    );

    return {
      loadedVideochatCredentials: !!apiKey,
      doctorName,
      doctorSignature,
      doctorImageUrl,
      apiKey,
      sessionId,
      token,
      userId,
      OT,
      caseId,
    };
  },
  { fetchVideochatCredentials }
)(VideoLoader);

export default (childComponent) => (props) =>
  <ConnectedLoader {...props} childComponent={childComponent} />;
