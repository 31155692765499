import { _t } from '@mindoktor/patient-legacy/i18n';
import React, { Component } from 'react';

import { Body1, Title2 } from '../../../common/components/typography';
import Button from '../../components/button/button';
import Modal from '../../components/modal';
import { hideModal } from '../../routing';

const styles = {
  title: {
    paddingBottom: 10,
  },
};

export default class RevisitInformationModal extends Component {
  render() {
    return (
      <Modal
        body={
          <div>
            <Title2 style={styles.title}>{_t('case.revisit.about')}</Title2>

            <Body1>{_t('guides.revisit.information')}</Body1>
          </div>
        }
        footer={
          <Button type="ghost" color="black" onClick={hideModal}>
            {_t('common.close')}
          </Button>
        }
      />
    );
  }
}
