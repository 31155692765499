import Config from '@mindoktor/env/Config';
import React from 'react';

import { Body1, Title1, Title2 } from '../../common/components/typography';
import TruncatedFooter from '../components/footer/truncated';
import MediaQuery from '../components/media_query';
import Screen from '../components/screen';
import MenuBar from '../menu/bar';
import {
  getDeviceId,
  getUniqueId,
  getSessionId,
} from '@mindoktor/patient-app/api';

const styles = {
  wrapper: {
    width: '100%',
    maxWidth: 800,
    margin: '0 auto',
    paddingTop: 20,
    paddingBottom: 20,
  },

  body: {
    maxWidth: 600,
    paddingLeft: 20,
    paddingRight: 20,
  },

  settings: {
    display: 'flex',
    flexDirection: 'column',
  },

  title: {
    paddingBottom: 24,
  },
};

const Section = ({ title, value }) => (
  <div style={{ marginBottom: 18 }}>
    <Title2 mb="t">{title}</Title2>
    <Body1>{value}</Body1>
  </div>
);

export const DeveloperTools = () => {
  if (!Config.IsDev) {
    return null;
  }

  return (
    <Screen
      header={<MenuBar />}
      body={
        <div style={styles.wrapper}>
          <div style={styles.body}>
            <Title1 style={styles.title}>Developer Tools</Title1>
            <Section title="Config setting" value={Config.ConfigName} />
            <Section title="Unique ID" value={getUniqueId()} />
            <Section title="Device ID" value={getDeviceId()} />
            <Section title="Session ID" value={getSessionId()} />
          </div>
        </div>
      }
      footer={
        <MediaQuery minWidth={801}>
          <TruncatedFooter />
        </MediaQuery>
      }
      showScrollGradient={true}
    />
  );
};

export default DeveloperTools;
