import aph_en from '@mindoktor/patient-legacy/apotekhjartat/localization/messages/en.json';
import aph_sv from '@mindoktor/patient-legacy/apotekhjartat/localization/messages/sv.json';

import en from './locales/en.json';
import sv from './locales/sv.json';

export default {
  sv: { ...sv, ...aph_sv },
  en: { ...en, ...aph_en },
};
