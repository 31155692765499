import { _t } from '@mindoktor/patient-legacy/i18n';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { constructUrl } from '../../../../state/api';
import { getPatientNameAndBirthDate } from '../../../../state/profile/selectors';
import Link from '../../../components/link';
import { getSite } from '../../../utils/site';
import Message from '../../components/message';
import Header from '../components/header';

export class PatientFile extends Component {
  render() {
    const {
      initials,
      name,
      timestamp,
      attachmentId,
      attachmentName,
      attachmentOriginalName,
      style,
    } = this.props;

    const displayName = attachmentOriginalName || attachmentName;

    return (
      <Message style={style}>
        <Header initials={initials} title={name} timestamp={timestamp} />

        <Link
          href={constructUrl({
            url: `/api/v1/files/${attachmentId}`,
            params: { site: getSite() },
          })}
        >
          {_t('fileItem.download', { name: displayName })}
        </Link>
      </Message>
    );
  }
}

export default connect((state) => {
  const { initials, fullName: name } = getPatientNameAndBirthDate(state);

  return {
    initials,
    name,
  };
})(PatientFile);
