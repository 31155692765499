import { getApi } from '../api/actions';
import {
  VIDEOCHAT_CREDENTIALS_REQUEST,
  VIDEOCHAT_CREDENTIALS_REQUEST_ERROR,
  VIDEOCHAT_CREDENTIALS_REQUEST_SUCCESS,
  VIDEOCHAT_PRESENCE_REQUEST,
  VIDEOCHAT_PRESENCE_REQUEST_ERROR,
  VIDEOCHAT_PRESENCE_REQUEST_SUCCESS,
} from './types';

export const fetchVideochatCredentials = (caseId) => async (dispatch) => {
  dispatch({
    type: VIDEOCHAT_CREDENTIALS_REQUEST,
    payload: { caseId },
  });

  const { json: credentials, error } = await dispatch(
    getApi(`/api/v1/videochat/credentials/case/${caseId}`)
  );

  if (error) {
    dispatch({
      type: VIDEOCHAT_CREDENTIALS_REQUEST_ERROR,
      payload: { caseId },
    });
  } else {
    dispatch({
      type: VIDEOCHAT_CREDENTIALS_REQUEST_SUCCESS,
      payload: {
        caseId,
        credentials,
      },
    });
  }

  return { error };
};

export const fetchVideochatPresence = () => async (dispatch) => {
  dispatch({
    type: VIDEOCHAT_PRESENCE_REQUEST,
  });

  const { json: sessions, error } = await dispatch(
    getApi('/api/v1/videochat/presence', { skipTokenUpdate: true })
  );

  if (error) {
    dispatch({
      type: VIDEOCHAT_PRESENCE_REQUEST_ERROR,
      payload: error,
    });

    return { error };
  }

  dispatch({
    type: VIDEOCHAT_PRESENCE_REQUEST_SUCCESS,
    payload: sessions,
  });

  return { sessions };
};
