import React from 'react';

import imageSource from '../../../../images/temp/child.png';
import Img from '@mindoktor/pulse/src/utils/images/Img.web';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
  },
};

const Child = ({ size = 40, style }) => (
  <div
    style={{
      ...styles.root,
      width: size,
      height: size,
      ...style,
    }}
  >
    <Img
      src={imageSource}
      style={{
        display: 'block',
        width: size,
        height: size,
      }}
    />
  </div>
);

export default Child;
