import AthleteFoot from '../../../../images/guides/AthleteFoot.svg';
import bloodpressure_measure from '../../../../images/guides/bloodpressure_measure.svg';
import bloodTestResult from '../../../../images/guides/bloodTestResult.svg';
import constipation from '../../../../images/guides/constipation.svg';
import COPD from '../../../../images/guides/COPD.svg';
import covid19 from '../../../../images/guides/covid-19.svg';
import dandruff from '../../../../images/guides/dandruff.svg';
import diarrhoea from '../../../../images/guides/diarrhoea.svg';
import dieticianAdvice from '../../../../images/guides/dieticianAdvice.svg';
import earwax from '../../../../images/guides/earwax.svg';
import Gout from '../../../../images/guides/Gout.svg';
import health_check from '../../../../images/guides/health_check.svg';
import heavy_periods from '../../../../images/guides/heavy_periods.svg';
import high_cholesterol from '../../../../images/guides/high_cholesterol.svg';
import hip_pain from '../../../../images/guides/hip_pain.svg';
import ill_adult_pregnancy from '../../../../images/guides/ill_adult_pregnancy.svg';
import ill_akne from '../../../../images/guides/ill_akne.svg';
import ill_allergi from '../../../../images/guides/ill_allergi.svg';
import ill_andningsbesvar from '../../../../images/guides/ill_andningsbesvar.svg';
import ill_asthma from '../../../../images/guides/ill_asthma.svg';
import ill_barn_akne from '../../../../images/guides/ill_barn_akne.svg';
import ill_barn_borrelia from '../../../../images/guides/ill_barn_borrelia.svg';
import ill_barn_diarre_och_krakningar from '../../../../images/guides/ill_barn_diarre_och_krakningar.svg';
import ill_barn_eksem from '../../../../images/guides/ill_barn_eksem.svg';
import ill_barn_forstoppning from '../../../../images/guides/ill_barn_forstoppning.svg';
import ill_barn_insektsbett from '../../../../images/guides/ill_barn_insektsbett.svg';
import ill_barn_klada_i_rumpan from '../../../../images/guides/ill_barn_klada_i_rumpan.svg';
import ill_barn_ogoninflammation from '../../../../images/guides/ill_barn_ogoninflammation.svg';
import ill_barn_ont_i_halsen from '../../../../images/guides/ill_barn_ont_i_halsen.svg';
import ill_barn_ovriga_besvar from '../../../../images/guides/ill_barn_ovriga_besvar.svg';
import ill_barn_pollen_palsdjur from '../../../../images/guides/ill_barn_pollen_palsdjur.svg';
import ill_barn_vattkoppor from '../../../../images/guides/ill_barn_vattkoppor.svg';
import ill_bihalebesvar from '../../../../images/guides/ill_bihalebesvar.svg';
import ill_borrelia from '../../../../images/guides/ill_borrelia.svg';
import ill_erektionsbesvar from '../../../../images/guides/ill_erektionsbesvar.svg';
import ill_fodelsemarke from '../../../../images/guides/ill_fodelsemarke.svg';
import ill_forskjuta_mens from '../../../../images/guides/ill_forskjuta_mens.svg';
import ill_fortidigutlosning from '../../../../images/guides/ill_fortidigutlosning.svg';
import ill_haravfall from '../../../../images/guides/ill_haravfall.svg';
import ill_herpes from '../../../../images/guides/ill_herpes.svg';
import ill_hosta from '../../../../images/guides/ill_hosta.svg';
import ill_hudbesvar from '../../../../images/guides/ill_hudbesvar.svg';
import ill_huvudvark from '../../../../images/guides/ill_huvudvark.svg';
import ill_illaluktande_flytningar from '../../../../images/guides/ill_illaluktande_flytningar.svg';
import ill_injuries from '../../../../images/guides/ill_injuries.svg';
import ill_insektsbett from '../../../../images/guides/ill_insektsbett.svg';
import ill_joint_pain from '../../../../images/guides/ill_joint_pain.svg';
import ill_klada_i_underlivet from '../../../../images/guides/ill_klada_i_underlivet.svg';
import ill_klamydia from '../../../../images/guides/ill_klamydia.svg';
import ill_klimakteriebesvar from '../../../../images/guides/ill_klimakteriebesvar.svg';
import ill_ledbesvar from '../../../../images/guides/ill_ledbesvar.svg';
import ill_magbesvar_nedre from '../../../../images/guides/ill_magbesvar_nedre.svg';
import ill_magbesvar_ovre from '../../../../images/guides/ill_magbesvar_ovre.svg';
import ill_midwifeConsult from '../../../../images/guides/ill_midwifeConsult.svg';
import ill_motionSickness from '../../../../images/guides/ill_motionSickness.svg';
import ill_muskelochledbesvar from '../../../../images/guides/ill_muskelochledbesvar.svg';
import ill_nagelsvamp from '../../../../images/guides/ill_nagelsvamp.svg';
import ill_nurseConsultation from '../../../../images/guides/ill_nurseConsultation.svg';
import ill_ogoninflammation from '../../../../images/guides/ill_ogoninflammation.svg';
import ill_ont_i_halsen from '../../../../images/guides/ill_ont_i_halsen.svg';
import ill_ovriga_besvar from '../../../../images/guides/ill_ovriga_besvar.svg';
import ill_physioConsult from '../../../../images/guides/ill_physioConsult.svg';
import fallbackImage from '../../../../images/guides/ill_placeholder.svg';
import ill_premens_besvar from '../../../../images/guides/ill_premens_besvar.svg';
import ill_preventivmedel from '../../../../images/guides/ill_preventivmedel.svg';
import ill_psychAdvice from '../../../../images/guides/ill_psychAdvice.svg';
import ill_receptfornyelse from '../../../../images/guides/ill_receptfornyelse.svg';
import ill_ryggont from '../../../../images/guides/ill_ryggont.svg';
import ill_somnsvarigheter from '../../../../images/guides/ill_somnsvarigheter.svg';
import ill_springmask from '../../../../images/guides/ill_springmask.svg';
import ill_stress_oro_depression from '../../../../images/guides/ill_stress_oro_depression.svg';
import ill_thyroid from '../../../../images/guides/ill_thyroid.svg';
import ill_urinvagsbesvar from '../../../../images/guides/ill_urinvagsbesvar.svg';
import ill_vartor_i_underlivet from '../../../../images/guides/ill_vartor_i_underlivet.svg';
import irritable_bowel_syndrome from '../../../../images/guides/irritable_bowel_syndrome.svg';
import lumps_and_bumps from '../../../../images/guides/lumps_and_bumps.svg';
import memory_loss from '../../../../images/guides/memory_loss.svg';
import neck_pain from '../../../../images/guides/neck_pain.svg';
import prostate_urinary_problems from '../../../../images/guides/prostate_urinary_problems.svg';
import psoriasis from '../../../../images/guides/psoriasis.svg';
import ringworm from '../../../../images/guides/ringworm.svg';
import sickNote from '../../../../images/guides/sickNote.svg';
import smoke_cessation from '../../../../images/guides/smoke_cessation.svg';
import tired_all_the_time from '../../../../images/guides/tired_all_the_time.svg';
import vaccination from '../../../../images/guides/vaccination.svg';
import varicose_veins from '../../../../images/guides/varicose_veins.svg';
import verrucas from '../../../../images/guides/verrucas.svg';
import wounds from '../../../../images/guides/wounds.svg';

const imagesByEntrywayId = {
  1: ill_ovriga_besvar,
  2: ill_urinvagsbesvar,
  3: ill_ont_i_halsen,
  4: ill_somnsvarigheter,
  5: ill_bihalebesvar,
  6: ill_magbesvar_ovre,
  7: ill_receptfornyelse,
  8: ill_hudbesvar,
  9: ill_fodelsemarke,
  10: ill_magbesvar_nedre,
  11: ill_huvudvark,
  12: ill_hosta,
  13: ill_ryggont,
  14: ill_erektionsbesvar,
  15: ill_ledbesvar,
  16: ill_ogoninflammation,
  17: ill_borrelia,
  18: ill_preventivmedel,
  19: ill_stress_oro_depression,
  20: ill_allergi, // allergy B2B
  21: ill_allergi, // allergy B2C
  22: ill_barn_eksem,
  23: ill_barn_forstoppning,
  24: ill_barn_ont_i_halsen,
  25: ill_barn_diarre_och_krakningar,
  26: undefined, // depricated mental health
  27: undefined, // general health
  28: undefined, // physio therapi
  29: ill_forskjuta_mens,
  30: undefined, // deprecated youth guide, never used.
  31: undefined, // deprecated youth guide, never used.
  32: undefined, // deprecated youth guide, never used.
  33: undefined, // deprecated youth guide, never used.
  34: undefined, // deprecated youth guide, never used.
  35: undefined, // deprecated youth guide, never used.
  36: undefined, // deprecated youth guide, never used.
  37: undefined, // deprecated youth guide, never used.
  38: undefined, // deprecated youth guide, never used.
  39: undefined, // deprecated youth guide, never used.
  40: undefined, // deprecated youth guide, never used.
  41: undefined, // deprecated youth guide, never used.
  42: ill_barn_vattkoppor,
  43: ill_barn_ogoninflammation,
  44: ill_akne,
  45: ill_klamydia,
  46: ill_muskelochledbesvar,
  47: ill_herpes,
  48: ill_receptfornyelse, // renewalApoteket
  49: ill_preventivmedel, // anticonceptionApoteket
  50: ill_barn_borrelia,
  51: ill_barn_akne,
  // 52: require('../../../../images/guides/ic_revisit.svg'),
  // 53: require('../../../../images/guides/ic_revisit.svg'), // revisit under 18
  54: ill_barn_klada_i_rumpan,
  55: ill_illaluktande_flytningar,
  56: ill_nagelsvamp,
  57: ill_barn_insektsbett,
  58: ill_klada_i_underlivet,
  59: ill_vartor_i_underlivet,
  60: ill_insektsbett,
  61: ill_premens_besvar,
  62: ill_klimakteriebesvar,
  63: ill_haravfall,
  64: ill_barn_ovriga_besvar,
  65: ill_fortidigutlosning,
  66: ill_muskelochledbesvar, // physio
  67: ill_muskelochledbesvar, // physioRevisitCaregiver
  68: undefined, // invitationFromPhysioToDoctor
  69: ill_muskelochledbesvar, // invitationFromDoctorToPhysio
  70: ill_muskelochledbesvar, // invitationFromDoctorToPhysioFull
  71: ill_muskelochledbesvar, // physioRevisitPatient
  // 72: contactTracing
  73: ill_thyroid, // thyroid
  // 74: mycoplasmaTrichomonas
  75: ill_urinvagsbesvar, // UTI B2B
  76: ill_thyroid, // thyroid B2B
  77: ill_huvudvark, // headache B2B
  78: ill_barn_pollen_palsdjur,
  79: ill_klimakteriebesvar, // B2B
  80: ill_stress_oro_depression, // B2B
  81: ill_somnsvarigheter, // B2B
  82: ill_ogoninflammation, // B2B
  83: ill_magbesvar_nedre, // B2B
  84: ill_magbesvar_ovre, // B2B
  87: ill_stress_oro_depression, // Revisit psychologist
  88: ill_receptfornyelse, // International
  89: ill_akne, // France
  90: ill_preventivmedel, // France
  91: ill_hudbesvar, // France
  92: ill_herpes, // France
  93: ill_insektsbett, // France
  94: ill_fodelsemarke, // France
  95: ill_ovriga_besvar, // France
  96: ill_receptfornyelse, // France
  97: ill_urinvagsbesvar, // France
  98: ill_hudbesvar, // Netherlands
  99: ill_urinvagsbesvar, // Netherlands
  100: ill_ont_i_halsen, // Netherlands
  101: ill_huvudvark, // Netherlands
  102: ill_somnsvarigheter, // Sleep phsycologist
  103: ill_adult_pregnancy,
  105: ill_ont_i_halsen,
  106: ill_bihalebesvar,
  107: ill_hosta,
  108: ill_urinvagsbesvar,
  109: ill_receptfornyelse,
  111: ill_bihalebesvar, // France
  112: ill_nagelsvamp, // France
  113: ill_ogoninflammation, // France
  114: ill_erektionsbesvar, // France
  115: ill_fortidigutlosning, // France
  116: ill_allergi, // France
  117: ill_vartor_i_underlivet, // France
  119: ill_bihalebesvar, // Netherlands
  120: ill_ogoninflammation, // Netherlands
  121: ill_herpes, // Netherlands
  122: ill_ryggont, // Netherlands
  123: ill_hosta, // Netherlands
  124: ill_allergi, // Netherlands
  125: ill_injuries, // Netherlands
  131: ill_asthma,
  133: ill_stress_oro_depression,
  134: ill_stress_oro_depression, // Revisit psychologist under 18
  136: ill_stress_oro_depression, // Internal referral psychologist
  137: ill_receptfornyelse, // Medication problems
  138: ill_ovriga_besvar, // UK
  139: ill_forskjuta_mens, // UK
  140: ill_preventivmedel, // EmergencyContraception UK
  141: ill_preventivmedel, // UK
  142: ill_andningsbesvar, // UK
  143: ill_allergi, // UK
  144: ill_huvudvark, // Migraine UK
  145: ill_hudbesvar, // Eczema UK
  146: ill_ogoninflammation, // ItchyEyes UK
  147: ill_klada_i_underlivet, // ItchyVagina UK
  148: ill_hudbesvar, // Rash UK
  149: ill_illaluktande_flytningar, // Vaginosis UK
  150: sickNote,
  151: ill_ovriga_besvar,
  152: COPD,
  153: smoke_cessation,
  154: bloodpressure_measure,
  155: high_cholesterol,
  156: ill_magbesvar_ovre,
  157: irritable_bowel_syndrome,
  158: constipation,
  159: diarrhoea,
  160: ill_thyroid,
  161: memory_loss,
  162: tired_all_the_time,
  163: ill_stress_oro_depression,
  164: ill_stress_oro_depression,
  165: ill_akne,
  166: psoriasis,
  167: lumps_and_bumps,
  168: wounds,
  169: ill_ryggont,
  170: hip_pain,
  171: ill_ledbesvar,
  172: heavy_periods,
  173: ill_klimakteriebesvar,
  174: prostate_urinary_problems,
  175: ill_nurseConsultation,
  176: ill_nurseConsultation,
  183: neck_pain, // neckPainUK
  184: ill_muskelochledbesvar, // upperLimbUK
  185: ill_ledbesvar, // lowerLimbUK
  188: ill_midwifeConsult,
  190: ill_nagelsvamp,
  191: ill_springmask,
  192: ill_springmask,
  197: lumps_and_bumps,
  198: lumps_and_bumps,
  199: verrucas,
  200: AthleteFoot,
  201: ringworm,
  202: dandruff,
  203: ill_akne,
  204: varicose_veins,
  205: earwax,
  206: Gout,
  209: ill_motionSickness,
  210: vaccination,
  212: ill_ogoninflammation,
  218: ill_physioConsult,
  219: ill_hudbesvar, // Rash children UK
  220: ill_joint_pain,
  222: ill_asthma,
  224: ill_ont_i_halsen, // Tonsillitis children UK
  225: ill_hosta, // Cough children UK
  226: ill_receptfornyelse, // Renewal children UK
  227: ill_psychAdvice,
  228: dieticianAdvice,
  229: dieticianAdvice,
  232: health_check,
  233: covid19,
  234: covid19,
  235: ill_ovriga_besvar,
  238: earwax,
  242: covid19,
  243: bloodTestResult,
  244: ill_asthma,
  255: ill_stress_oro_depression,
  256: ill_stress_oro_depression,
  257: ill_fodelsemarke,
  258: ill_hosta,
  259: ill_asthma,
};

export function getGuideImageByEntrywayId(id) {
  const img = imagesByEntrywayId[id];

  if (typeof img === 'object') {
    return img.default;
  }

  return img || fallbackImage;
}
