import React from 'react';

import Avatar, { AvatarProps } from '@mui/material/Avatar';

import { colors } from '@mindoktor/pulse/src/styles/colors';

import { getInitials } from './getInitials';

const avatarBgColor = colors.blue[500];
const avatarSize = '1.5rem';
const avatarFontSize = 10;
interface Props {
  firstName?: string;
  lastName?: string;
  src?: string;
  sx?: AvatarProps['sx'];
  icon?: React.ReactNode;
}

/**
 * Avatar to display the author of a Message.
 *
 * If no icon is provided, it falls back to using src.
 * If not even that is available, it displays the initials of the author.
 */
const MessageAvatar: React.FC<Props> = ({
  firstName,
  lastName,
  src,
  sx,
  icon,
}) => (
  <Avatar
    src={icon == null ? src : ''}
    sx={{
      width: avatarSize,
      height: avatarSize,
      fontSize: avatarFontSize,
      bgcolor: avatarBgColor,
      ...sx,
    }}
  >
    {icon == null && src == null ? getInitials(firstName, lastName) : icon}
  </Avatar>
);

export type MessageAvatarProps = Props;
export default MessageAvatar;
