// Libs
// Utils
import { _t } from '@mindoktor/patient-legacy/i18n';
import React from 'react';

import colors from '../../../common/colors';
import { Body1, Header3 } from '../../../common/components/typography';
import constants from '../../../common/constants';
import { MIN_DOKTOR_COMPANY_ID } from '../../../state/api/constants';
import {
  getTreaterByEntrywayId,
  hasPayment,
  hasTechnicalError,
} from '../../../state/guides/guidesUtils';
import {
  // type CaseListItem,
  CaseListItemTypes,
} from '../../../state/profile/types';
import { getGuideImageByEntrywayId } from '../../guides/utils/images';
// Components
import CaseCard from './cards/case_card';
import CaseClosedCard from './cards/case_closed_card';
import DraftCard from './cards/draft_card';
import InvitationCard from './cards/invitation_card';
import SupportCard from './cards/support_card';

const styles = {
  item: {
    marginBottom: constants.spacing24,
    marginLeft: constants.spacing8,
    marginRight: constants.spacing8,
  },
  title: {
    marginBottom: constants.spacing16,
    marginLeft: constants.spacing24,
    marginRight: constants.spacing24,
    fontWeight: 500,
  },
  year: {
    color: colors.grey_700,
    marginTop: constants.spacing24,
    marginRight: 0,
    marginBottom: constants.spacing16,
    marginLeft: constants.spacing24,
  },
};

// type Props = {
//   caseItems: CaseListItem[],
//   resumeClosedCases: boolean,
//   showSubHeaders: boolean,
// };

function Cases({
  caseItems,
  closedCasesByYear,
  resumeClosedCases,
  showSubHeaders = true,
}) {
  return (
    <div>
      {caseItems.map((item) => {
        const { type, payload = {}, timestamp } = item;

        switch (type) {
          case CaseListItemTypes.case: {
            const {
              id: caseId,
              conversationId,
              status,
              entrywayId,
              revisitParentEntrywayId,
              displayTitle: title,
              patientName,
              doctorImageUrl,
              doctorName,
              doctorSignature,
              companyID: companyId,
              companyName,
              domainTitle, // this attr doesn't exist in CaseWithoutMessages type which this payload is originated
              submitted,
              unreadMessageCountFromCaregiver,
              paymentStatus,
              paymentMethod,
              isCancelable,
            } = payload;
            return (
              <CaseCard
                key={'case-' + caseId}
                caseId={caseId}
                conversationId={conversationId}
                status={status}
                iconSource={getGuideImageByEntrywayId(
                  revisitParentEntrywayId || entrywayId
                )}
                title={title}
                patientName={patientName}
                doctorImageUrl={doctorImageUrl}
                doctorName={doctorName}
                doctorSignature={doctorSignature}
                companyName={
                  companyId !== MIN_DOKTOR_COMPANY_ID ? companyName : undefined
                }
                domainTitle={domainTitle}
                style={styles.item}
                hasTechnicalError={hasTechnicalError(submitted.time, status)}
                hasPayment={hasPayment(paymentStatus, paymentMethod)}
                paymentMethod={paymentMethod}
                caseIsTreatedBy={getTreaterByEntrywayId(entrywayId)}
                unread={unreadMessageCountFromCaregiver}
                isCancelable={isCancelable}
                entrywayId={entrywayId}
              />
            );
          }

          case CaseListItemTypes.draft: {
            const {
              entrywayId,
              revisitId,
              displayTitle: title,
              patientUUID: patientUuid,
              patientName,
              isPatientSameAsUser,
            } = payload;

            return (
              <DraftCard
                key={'draft-' + timestamp}
                entrywayId={entrywayId}
                childUuid={!isPatientSameAsUser ? patientUuid : undefined}
                revisitId={revisitId}
                iconSource={getGuideImageByEntrywayId(entrywayId)}
                title={title}
                patientName={patientName}
                style={styles.item}
              />
            );
          }

          case CaseListItemTypes.invitation: {
            const {
              typeName: type,
              targetEntrywayId,
              targetEntrywayName: title,
              id: revisitId,
              patientUUID: patientUuid,
              guideParams: { entrywayId, guideType },
              opens,
              closes,
              isOpen,
              doctorName,
              doctorSignature,
              isCaregiverAssigned,
              caseOwnerCanRepresentPatient,
            } = payload;

            return (
              <InvitationCard
                key={'invitation-' + revisitId}
                parentEntrywayId={entrywayId}
                entrywayId={entrywayId}
                childUuid={guideType === 'children' ? patientUuid : undefined}
                revisitId={revisitId}
                type={type}
                iconSource={getGuideImageByEntrywayId(targetEntrywayId)}
                title={title}
                opens={opens}
                closes={closes}
                isOpen={isOpen}
                style={styles.item}
                doctorName={doctorName}
                doctorSignature={doctorSignature}
                isCaregiverAssigned={isCaregiverAssigned}
                caseOwnerCanRepresentPatient={caseOwnerCanRepresentPatient}
              />
            );
          }

          case CaseListItemTypes.support: {
            return (
              <SupportCard
                style={styles.item}
                key={'support-card'} // There can be only one
                supportTicketList={payload}
              />
            );
          }
        }

        return null;
      })}
      {showSubHeaders && !!closedCasesByYear.length && (
        <Body1 style={styles.title}>{_t('caseList.previousCases')}</Body1>
      )}

      {closedCasesByYear.map((caseGroup, index) => (
        <div key={index}>
          <Header3 style={styles.year}>{caseGroup.year}</Header3>

          {caseGroup.cases.map(
            ({
              id: caseId,
              conversationId,
              entrywayId,
              revisitParentEntrywayId,
              resumeCaseInfo,
              displayTitle: title,
              patientName,
              status,
              doctorImageUrl,
              doctorName,
              doctorSignature,
              companyID: companyId,
              companyName,
              paymentMethod,
              payload,
              type,
            }) =>
              type === CaseListItemTypes.support ? (
                <SupportCard
                  style={styles.item}
                  key={'support-card'} // There can be only one
                  isClosed
                  isResumable={resumeClosedCases}
                  supportTicketList={payload}
                />
              ) : (
                // Other cases
                <div key={caseId}>
                  {resumeClosedCases && (
                    <CaseClosedCard
                      key={'closedcase-' + caseId}
                      caseId={caseId}
                      conversationId={conversationId}
                      title={title}
                      iconSource={getGuideImageByEntrywayId(
                        revisitParentEntrywayId || entrywayId
                      )}
                      patientName={patientName}
                      resumeCaseInfo={resumeCaseInfo}
                      style={styles.item}
                    />
                  )}
                  {!resumeClosedCases && (
                    <CaseCard
                      key={'closedcase-' + caseId}
                      caseId={caseId}
                      conversationId={conversationId}
                      status={status}
                      iconSource={getGuideImageByEntrywayId(entrywayId)}
                      title={title}
                      patientName={patientName}
                      doctorImageUrl={doctorImageUrl}
                      doctorName={doctorName}
                      doctorSignature={doctorSignature}
                      paymentMethod={paymentMethod}
                      companyName={
                        companyId !== MIN_DOKTOR_COMPANY_ID
                          ? companyName
                          : undefined
                      }
                      style={styles.item}
                    />
                  )}
                </div>
              )
          )}
        </div>
      ))}
    </div>
  );
}
export default Cases;
