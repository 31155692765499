import { showSnackbar } from '@mindoktor/patient-legacy/src/state/snackbar/actions';
import { SnackbarType } from '@mindoktor/patient-legacy/src/state/snackbar/types';

import { useLegacyDispatch } from './legacyState';

export const useNotifications = () => {
  const dispatch = useLegacyDispatch();

  return {
    showSuccess: (message: string) =>
      dispatch(
        showSnackbar({
          type: SnackbarType.SUCCESS,
          text: message,
        })
      ),
    showWarning: (message: string) =>
      dispatch(
        showSnackbar({
          type: SnackbarType.WARNING,
          text: message,
        })
      ),
  };
};
