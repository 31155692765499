import { getApi } from '../api/actions';
import {
  FEATUREFLAGS_REQUEST,
  FEATUREFLAGS_REQUEST_ERROR,
  FEATUREFLAGS_REQUEST_SUCCESS,
  FEATUREFLAGS_UPDATE_FLAG_ENABLED,
} from './types';

export const fetchFeatureFlags = () => async (dispatch) => {
  dispatch({
    type: FEATUREFLAGS_REQUEST,
  });

  const { json, error } = await dispatch(getApi('/api/v1/featureflags'));

  if (error) {
    dispatch({
      type: FEATUREFLAGS_REQUEST_ERROR,
      payload: error,
    });

    return { error };
  }

  const flags = json;

  dispatch({
    type: FEATUREFLAGS_REQUEST_SUCCESS,
    payload: { flags },
  });

  return { flags };
};

export const setFeatureFlags = (flags) => ({
  type: FEATUREFLAGS_REQUEST_SUCCESS,
  payload: { flags },
});

export const updateFlagEnabled = (key, value) => ({
  type: FEATUREFLAGS_UPDATE_FLAG_ENABLED,
  payload: { key, value },
});
