import React, { Component } from 'react';
import { connect } from 'react-redux';

import { nextQuestion } from '../../../state/formulary/actions';
import { getFormularyState } from '../../../state/formulary/selectors';
import {
  loadHealthProfile,
  saveHealthProfile,
} from '../../../state/healthprofile/actions';
import { getHealthProfileFormularyKey } from '../../../state/healthprofile/selectors';
import BackBar from '../../components/bar/back';
import FadeTransition from '../../components/transition/fade';
import FormularyFooter from '../../formulary/footer';
import FormularyQuestion from '../../formulary/question';
import QuestionView from '../../guides/components/question_view';
import { goBack } from '../../routing';

// type Props = {
//   childUuid?: string,
//   index?: string,

//   healthProfileKey?: string,

//   currentNode?: Question,
//   nextNode?: Question,

//   hasErrors?: boolean,

//   nextQuestion: typeof nextQuestion,
//   loadHealthProfile: typeof loadHealthProfile,
//   saveHealthProfile: typeof saveHealthProfile,
// };

export class EditScreen extends Component {
  state = {
    loaded: false,
    submitting: false,
  };

  load = async () => {
    const { childUuid, index, currentNode, loadHealthProfile } = this.props;

    const { error } = await loadHealthProfile({
      childUuid,
      initialIndex: index,
      skipOptionalQuestions: true,
      skipReload: !!currentNode,
    });

    this.setState({ loaded: !error });
  };

  next = async () => {
    const {
      childUuid,
      healthProfileKey,
      nextNode,
      hasErrors,
      nextQuestion,
      saveHealthProfile,
    } = this.props;

    const { submitting } = this.state;

    if (submitting) return;

    if (nextNode) {
      nextQuestion({ formularyKey: healthProfileKey });
    } else {
      this.setState({ submitting: true });

      if (!hasErrors) {
        await saveHealthProfile({ childUuid });
      }

      this.setState({ submitting: false });

      goBack();
    }
  };

  componentDidMount() {
    this.load();
  }

  render() {
    const { healthProfileKey, currentNode } = this.props;
    const { loaded } = this.state;

    if (!loaded) return null;

    return (
      <FadeTransition
        transitionKey={currentNode.index}
        afterTransition={window.scroll}
      >
        <QuestionView
          header={<BackBar />}
          content={
            <FormularyQuestion
              formularyKey={healthProfileKey}
              node={currentNode}
              next={this.next}
            />
          }
          footer={
            <FormularyFooter
              formularyKey={healthProfileKey}
              node={currentNode}
              next={this.next}
            />
          }
        />
      </FadeTransition>
    );
  }
}

export default connect(
  (state, props) => {
    const { childUuid } = props;

    const healthProfileKey = getHealthProfileFormularyKey(state, childUuid);

    const {
      current: currentNode,
      next: nextNode,
      errors,
    } = getFormularyState(state, healthProfileKey) || {};

    return {
      healthProfileKey,

      currentNode,
      nextNode,

      hasErrors: !!errors && !!Object.keys(errors).length,
    };
  },
  { nextQuestion, loadHealthProfile, saveHealthProfile }
)(EditScreen);
