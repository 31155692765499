import React, { useEffect, useReducer, useState } from 'react';

import { isTimestampBefore } from '../functions/isTimestampBefore';
import { useMessageReadMutator } from '../hooks/useMessageReadMutator';

import {
  IMessageReadContext,
  MessageReadContext,
  MessageReadReceipt,
} from './MessageReadContext';

const MUTATION_TIMEOUT_MS = 100 as const;

interface State {
  readMessageReceipts: MessageReadReceipt[];
}

type Action = { type: 'push'; payload: MessageReadReceipt } | { type: 'clear' };

const getLastReadReceipt = (
  receipts: MessageReadReceipt[]
): MessageReadReceipt => {
  return receipts.sort((a, b) =>
    isTimestampBefore(a.messageCreated, b.messageCreated) ? 1 : -1
  )[0];
};

const reducer = (state: State, action: Action) => {
  if (action.type === 'push') {
    return {
      ...state,
      readMessageReceipts: [...state.readMessageReceipts, action.payload],
    };
  }
  if (action.type === 'clear') {
    return {
      ...state,
      // Always keep track of last receipt to avoid sending duplicates
      readMessageReceipts: [getLastReadReceipt(state.readMessageReceipts)],
    };
  }
  throw Error('Unknown action.');
};

interface Props {
  children?: React.ReactNode;
  conversationId: number | null;
}

const MessageReadProvider: React.FC<Props> = ({ conversationId, children }) => {
  const { mutate } = useMessageReadMutator(conversationId);

  const [{ readMessageReceipts }, dispatch] = useReducer(reducer, {
    readMessageReceipts: [],
  });
  const [timeoutId, setTimeoutId] = useState(0);
  /**
   * We use this, together with keeping the last receipt always in the list,
   * to avoid sending duplicates.
   *
   * The receipt in the list is used for sorting, so we only check the last receipt,
   * and then with this we verify if we have already sent it.
   */
  const [lastReadReceipt, setLastReadReceipt] =
    useState<MessageReadReceipt | null>(null);

  const value: IMessageReadContext = {
    lastReadReceipt,
    readMessageReceipts,
    pushReadMessageReceipt: (e: MessageReadReceipt) =>
      dispatch({ type: 'push', payload: e }),
    clearReadMessageReceipts: () => dispatch({ type: 'clear' }),
  };

  useEffect(() => {
    if (timeoutId > 0) {
      clearTimeout(timeoutId);
    }

    if (readMessageReceipts.length === 0) {
      return;
    }

    const newTimeoutId = +setTimeout(() => {
      const newLastReceipt = getLastReadReceipt(readMessageReceipts);

      if (lastReadReceipt?.messageId === newLastReceipt.messageId) {
        return;
      }
      setLastReadReceipt(newLastReceipt);
      mutate({
        messageId: newLastReceipt.messageId,
        readTime: new Date().toISOString(),
      });
      value.clearReadMessageReceipts();
    }, MUTATION_TIMEOUT_MS);
    setTimeoutId(newTimeoutId);
  }, [readMessageReceipts]);

  return (
    <MessageReadContext.Provider value={value}>
      {children}
    </MessageReadContext.Provider>
  );
};

export default MessageReadProvider;
