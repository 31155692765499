import React from 'react';

import logoHeartAlone from '../../../images/logo/logo_alone_white_mouth.svg';
import logoMdBlackSource from '../../../images/logo/mindoktor_logo_black.svg';
import logoMdWhiteSource from '../../../images/logo/mindoktor_logo_white.svg';

const styles = {
  logo: {
    display: 'block',
    margin: 0,
    padding: 0,
    border: 'none',
    outline: 'none',
  },
};

const mdRatio = 606 / 129;

// type Props = {
//   type?: 'white' | 'black',
//   height?: number,
//   style?: any,
//   hasText?: boolean,
//   onClick?: () => any,
// };

const Logo = ({
  type = 'black',
  hasText = true,
  height = 30,
  style,
  onClick,
}) => {
  if (hasText) {
    return (
      <img
        src={type === 'black' ? logoMdBlackSource : logoMdWhiteSource}
        style={{
          ...styles.logo,
          height,
          width: height * mdRatio,
          cursor: onClick ? 'pointer' : 'default',
          ...style,
        }}
        onClick={onClick}
      />
    );
  }

  return (
    <img
      src={logoHeartAlone}
      style={{
        ...style,
        height,
        width: 'auto',
      }}
      onClick={onClick}
    />
  );
};

export default Logo;
