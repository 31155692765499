import React from 'react';

const styles = {
  message: {
    padding: 16,
    backgroundColor: 'white',
    whiteSpace: 'pre-line',
  },
};

const Message = ({ style, children }) => (
  <div style={{ ...styles.message, ...style }} data-test-id="message">
    {children}
  </div>
);

export default Message;
