import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { ResponseError } from '@mindoktor/patient-app/api/responseError';

import { experimentsApi } from '../api/experiments';
import { ExperimentsPostFetchVariantResponse } from '../api/models/experiments';

const EXPERIMENTS_QUERY_KEY = 'Experiments' as const;

export type ExperimentsPostFetchVariantResult = UseQueryResult<
  ExperimentsPostFetchVariantResponse['data'],
  ResponseError
>;

export const defaultExperimentData: ExperimentsPostFetchVariantResponse['data'] =
  {
    variant: 0,
  };

export const useExperimentApi = (
  experimentName: string,
  shouldFetchExperiment: boolean
): ExperimentsPostFetchVariantResult => {
  return useQuery<ExperimentsPostFetchVariantResponse['data'], ResponseError>(
    [EXPERIMENTS_QUERY_KEY, experimentName],
    async () => {
      const response = await experimentsApi.postFetchVariant({
        experimentName,
      });
      if (!response.ok) {
        return defaultExperimentData;
      }

      return response.data?.data ?? defaultExperimentData;
    },
    {
      enabled: shouldFetchExperiment,
      staleTime: Infinity, // never consider this data stale
      cacheTime: Infinity, // don't garbage collect this data
    }
  );
};
