import React from 'react';

const styles = {
  actionArea: {
    padding: 16,
  },
};

const ActionArea = ({ style, children }) => (
  <div style={{ ...styles.actionArea, ...style }}>{children}</div>
);

export default ActionArea;
