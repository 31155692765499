import { _t } from '@mindoktor/patient-legacy/i18n';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Body1 } from '../../../../common/components/typography';
import { resendMessage } from '../../../../state/cases/actions';
import { MessageSendStatus } from '../../../../state/cases/constants';
import { getPatientNameAndBirthDate } from '../../../../state/profile/selectors';
import Button from '../../../components/button/button';
import Message from '../../components/message';
import Header from '../components/header';

const styles = {
  resend: {
    marginTop: 10,
  },
  text: {
    overflowX: 'auto',
  },
};
export class PatientText extends Component {
  resend = () => {
    const { caseId, entrywayId, cId, resendMessage } = this.props;

    resendMessage(caseId, entrywayId, cId);
  };

  render() {
    const { initials, name, timestamp, status, text, style } = this.props;

    const failed = status === MessageSendStatus.FAILED;

    return (
      <Message style={style}>
        <Header
          initials={initials}
          timestamp={timestamp}
          title={name}
          status={status}
        />

        <Body1 style={styles.text}>{text}</Body1>

        {failed && (
          <Button
            type="ghost"
            color="black"
            onClick={this.resend}
            style={styles.resend}
          >
            {_t('patientText.tryAgain')}
          </Button>
        )}
      </Message>
    );
  }
}

export default connect(
  (state) => {
    const { initials, fullName: name } = getPatientNameAndBirthDate(state);

    return {
      initials,
      name,
    };
  },
  { resendMessage }
)(PatientText);
