import { _t } from '@mindoktor/patient-legacy/i18n';
import React from 'react';

import angryUnchecked from '../../../../images/survey/ic_survey_smiley_angry.svg';
import angryChecked from '../../../../images/survey/ic_survey_smiley_angry_chosen.svg';
import gladUnchecked from '../../../../images/survey/ic_survey_smiley_glad.svg';
import gladChecked from '../../../../images/survey/ic_survey_smiley_glad_chosen.svg';
import happyUnchecked from '../../../../images/survey/ic_survey_smiley_happy.svg';
import happyChecked from '../../../../images/survey/ic_survey_smiley_happy_chosen.svg';
import neutralUnchecked from '../../../../images/survey/ic_survey_smiley_neutral.svg';
import neutralChecked from '../../../../images/survey/ic_survey_smiley_neutral_chosen.svg';
import sadUnchecked from '../../../../images/survey/ic_survey_smiley_sad.svg';
import sadChecked from '../../../../images/survey/ic_survey_smiley_sad_chosen.svg';
import { Body5 } from '../../../common/components/typography';
import constants from '../../../common/constants';
import { surveyRatingValues as ratingValues } from '../../../common/survey/constants';

const icons = {
  happy: { checked: happyChecked, unchecked: happyUnchecked },
  glad: { checked: gladChecked, unchecked: gladUnchecked },
  neutral: { checked: neutralChecked, unchecked: neutralUnchecked },
  sad: { checked: sadChecked, unchecked: sadUnchecked },
  angry: { checked: angryChecked, unchecked: angryUnchecked },
};

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    maxWidth: 400,
    margin: '0 auto',
    marginBottom: constants.spacing24,
  },

  item: {
    display: 'flex',
    flexDirection: 'column',
    width: constants.thumbDoctor,
    cursor: 'pointer',
  },
  icon: {
    width: constants.thumbDoctor,
    height: constants.thumbDoctor,
    marginBottom: constants.spacing8,
  },
  text: {
    textAlign: 'center',
    fontWeight: '500',
    marginBottom: 0,
  },
};

// type ItemProps = {
//   icon: string,
//   checked: boolean,
//   disabled: boolean,
//   select: any => any,
// };

class Item extends React.Component {
  state = {
    pressed: false,
  };

  onMouseDown = () => this.setState({ pressed: true });

  onMouseUp = () => this.setState({ pressed: false });

  onMouseLeave = () => this.setState({ pressed: false });

  select = () => {
    const { icon, select } = this.props;
    select(icon);
  };

  render() {
    const { icon, checked, disabled } = this.props;
    const { pressed } = this.state;

    return (
      <img
        src={icons[icon][checked ? 'checked' : 'unchecked']}
        style={{ ...styles.icon, opacity: pressed && !disabled ? 0.7 : 1 }}
        onClick={this.select}
        onMouseDown={this.onMouseDown}
        onMouseUp={this.onMouseUp}
        onMouseLeave={this.onMouseLeave}
      />
    );
  }
}

// type Props = {
//   answer?: any,
//   update: (value?: any) => any,
//   disabled?: boolean,
//   style?: any,
// };

export default class Rating extends React.Component {
  iconTexts = {
    happy: _t('rating.happy'),
    glad: _t('rating.glad'),
    neutral: _t('rating.neutral'),
    sad: _t('rating.sad'),
    angry: _t('rating.angry'),
  };

  select = async (icon) => {
    if (!this.props.submitted) {
      const { update } = this.props;
      await update(ratingValues[icon]);
    }
  };

  render() {
    const { answer, style, disabled } = this.props;
    return (
      <div style={{ ...styles.root, ...style }}>
        {Object.keys(ratingValues).map((icon) => (
          <div
            key={icon}
            style={{
              ...styles.item,
              opacity: answer && !(answer === ratingValues[icon]) ? 0.5 : 1,
            }}
          >
            <Item
              icon={icon}
              checked={answer === ratingValues[icon]}
              disabled={!!disabled}
              select={this.select}
            />
            <Body5 style={styles.text}>{this.iconTexts[icon]}</Body5>
          </div>
        ))}
      </div>
    );
  }
}
