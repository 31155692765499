import withStyles from '@material-ui/core/styles/withStyles';
import React from 'react';

import Answer from './Answer';

class Answers extends React.Component {
  render() {
    const { answers = [], classes } = this.props;

    return answers.map((answer) => (
      <Answer key={answer.ref} answer={answer} className={classes.answer} />
    ));
  }
}

export default withStyles(({ spacing }) => ({
  answer: {
    marginBottom: spacing(3),
    '&:last-child': {
      marginBottom: 0,
    },
  },
}))(Answers);
