import find from 'lodash-es/find';

function findLeaf(node, reverse) {
  let a, child;

  if (!node || !node.children) return undefined;
  a = reverse ? node.children.slice().reverse() : node.children;
  child = find(a, { show: true });
  return findLeaf(child, reverse) || child;
}

function move(node, parents, reverse) {
  let p, n, i, leaf;
  if (!node) return undefined;

  leaf = findLeaf(node, reverse);
  if (leaf) return leaf;

  p = parents[node.index];
  n = node;

  while (p) {
    i = p.children.indexOf(n);
    if (p.show && (reverse ? i > 0 : i < p.children.length - 1)) break;
    n = p;
    p = parents[p.index];
  }

  if (p && p.show) {
    n = p.children[i + (reverse ? -1 : 1)];
    parents[n.index] = p;
    return n.show ? findLeaf(n, reverse) || n : move(n, parents, reverse);
  }

  return undefined;
}

function moveFromIndex(root, index, reverse) {
  if (!root) return undefined;

  const parents = {};
  const node = index ? findByIndex(root, index, parents) : root;

  return move(node, parents, reverse);
}

export function findByIndex(node, index, parents) {
  let match;
  let i;
  if (node.index === index) return node;
  if (node.children) {
    for (i = 0; i < node.children.length; i++) {
      const child = node.children[i];
      match = findByIndex(child, index, parents);
      if (parents) parents[child.index] = node;
      if (match) return match;
    }
  }
  return undefined;
}

export function previousQuestion(root, index) {
  return moveFromIndex(root, index, true);
}

function nextUnansweredQuestion(
  root,
  index,
  initialVisibility,
  { skipOptionalQuestions } = {}
) {
  const getNext = (index) => {
    const nextNode = moveFromIndex(root, index, false);

    // last node
    if (!nextNode) {
      return undefined;
    }

    const nextNeverShown = !initialVisibility[nextNode.index];

    // next unanswered
    if (
      !skipOptionalQuestions ||
      (nextNeverShown && (nextNode.error || !nextNode.value))
    ) {
      return nextNode;
    }

    return getNext(nextNode.index);
  };

  return getNext(index);
}

export function nextQuestion(root, index, initialVisibility, options = {}) {
  if (options.skipAnswered) {
    return nextUnansweredQuestion(
      root,
      index,
      initialVisibility || {},
      options
    );
  }
  return moveFromIndex(root, index, false);
}
