import { _t } from '@mindoktor/patient-legacy/i18n';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Body1, SubHead, Title1 } from '../../../common/components/typography';
import { constructUrl } from '../../../state/api';
import { formatRoute, routes } from '../../../state/routing';
import { fetchWorkoutDetails } from '../../../state/workout/actions';
import {
  getWorkoutDetailsByID,
  isWorkoutDetailsLoading,
} from '../../../state/workout/selectors';
import BackBar from '../../components/bar/back';
import Button from '../../components/button/button';
import { CardWithBody } from '../../components/card';
import TruncatedFooter from '../../components/footer/truncated';
import MediaQuery from '../../components/media_query';
import Screen from '../../components/screen';
import SpinnerScreen from '../../components/spinner/screen';
const styles = {
  body: {
    width: '100%',
    maxWidth: 760,
    margin: '0 auto',
    padding: 20,
  },

  inner: {
    maxWidth: 600,
  },

  workout: {
    marginBottom: 30,
  },

  exercise: {
    marginBottom: 30,
  },

  title: {
    paddingBottom: 10,
  },

  description: {
    paddingBottom: 20,
  },

  button: {
    marginBottom: 20,
  },
};

// type Props = {
//   caseId: number,
//   workoutId: number,
//   isLoading: boolean,
//   workout?: Workout,
//   fetchWorkoutDetails: Function,
// };

export class WorkoutScreen extends Component {
  openCase = () => {
    const { caseId } = this.props;

    window.location.href = `#${formatRoute(routes.CASE_MESSAGES, {
      id: caseId,
    })}`;
  };

  componentDidMount() {
    const { fetchWorkoutDetails, caseId } = this.props;

    fetchWorkoutDetails(caseId);
  }

  render() {
    const { caseId, isLoading, workout, workoutId } = this.props;

    if (isLoading) {
      return <SpinnerScreen />;
    }

    const { title, description, exercises, videoLink } = workout;

    return (
      <Screen
        header={
          <BackBar
            title={_t('workout.program')}
            dark={true}
            maxWidth={1000}
            onBack={this.openCase}
          />
        }
        body={
          <div style={styles.body}>
            <div style={styles.inner}>
              <div style={styles.workout}>
                <Title1 style={styles.title}>{title}</Title1>
                <Body1 style={styles.description}>{description}</Body1>
                <Button style={styles.button} href={videoLink}>
                  {_t('workout.videoAll')}
                </Button>
                <Button
                  type="ghost"
                  color="black"
                  href={constructUrl({
                    url: `/api/v1/cases/${caseId}/workout/${workoutId}/pdf`,
                  })}
                >
                  {_t('workout.pdf')}
                </Button>
              </div>

              {(exercises || []).map((exercise) => (
                <CardWithBody key={exercise.id} style={styles.exercise}>
                  <SubHead style={styles.title}>{exercise.title}</SubHead>
                  <Body1 style={styles.description}>
                    {exercise.description}
                  </Body1>
                  <Button href={exercise.videoLink}>
                    {_t('workout.video')}
                  </Button>
                </CardWithBody>
              ))}
            </div>
          </div>
        }
        footer={
          <MediaQuery minWidth={801}>
            <TruncatedFooter />
          </MediaQuery>
        }
        showScrollGradient={true}
      />
    );
  }
}

export default connect(
  (state, props) => {
    const { workoutId } = props;

    return {
      workout: getWorkoutDetailsByID(state, workoutId),
      isLoading: isWorkoutDetailsLoading(state),
    };
  },
  {
    // todo: fetch the workout with workout id instead of fetching them all.
    // requires BE update.
    fetchWorkoutDetails,
  }
)(WorkoutScreen);
