import React, { Component } from 'react';

import { Body1, Title1 } from '../../../common/components/typography';
import TruncatedFooter from '../../components/footer/truncated';
import MediaQuery from '../../components/media_query';
import Screen from '../../components/screen';
import MenuBar from '../../menu/bar';

const styles = {
  wrapper: {
    width: '100%',
    maxWidth: 800,
    margin: '0 auto',
    paddingTop: 20,
    paddingBottom: 20,
  },

  body: {
    maxWidth: 600,
    paddingLeft: 20,
    paddingRight: 20,
  },

  title: {
    fontSize: 26,
    lineHeight: '32px',
    marginBottom: 10,
  },
};

export default class ForgetSuccessScreen extends Component {
  render() {
    return (
      <Screen
        header={<MenuBar />}
        body={
          <div style={styles.wrapper}>
            <div style={styles.body}>
              <Title1 style={styles.title}>Rätten att bli glömd</Title1>

              <Body1>
                Du har åberopat rätten att bli glömd. Dina personuppgifter
                kommer att raderas inom 30 dagar. Så snart detta skett kommer en
                bekräftelse att skickas till din folkbokföringsadress. Detta
                innebär att du fram tills dess kan komma att få utskick från
                oss.
              </Body1>
            </div>
          </div>
        }
        footer={
          <MediaQuery minWidth={801}>
            <TruncatedFooter />
          </MediaQuery>
        }
        showScrollGradient={true}
      />
    );
  }
}
