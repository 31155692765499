import React from 'react';
import * as ReactDOM from 'react-dom';

// type Props = { children: React.Node | React.Node[] };

/**
 * A layer creates a portal (https://reactjs.org/docs/portals.html)
 *
 * To make it possible to animate elements outside of its boundaries
 * without making it possible to scroll to them
 * the layer spans over the viewport, but it is not possible to interact
 * with it (css pointer-events: none).
 *
 * To make it possible to interact with it chilren, you therefore
 * have to add a pointerEvents style (probably `all`) on the root child(ren).
 */
export default class Layer extends React.Component {
  el;

  constructor(props) {
    super(props);
    this.el = document.createElement('div');
    const style = this.el.style;
    style.position = 'absolute';
    style.top = '0px';
    style.right = '0px';
    style.bottom = '0px';
    style.left = '0px';
    style.background = 'rgba(0, 250, 0, 0.5);';
    style.pointerEvents = 'none';
    style.overflow = 'hidden';
    style.display = 'flex';
    style.flexDirection = 'column';
    style.alignItems = 'center';
  }

  componentDidMount() {
    document.body && document.body.appendChild(this.el);
  }

  componentWillUnmount() {
    document.body && document.body.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}
