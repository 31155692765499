/**
 * The following browser/os checks is very much dumb WIP and should be replaced
 * with better checks. Most of it come from
 * packages/apps/patient/legacy/src/web/utils/device.js.
 **/

import { platform } from '../utils/compatibility/platform/platform';

const userAgent = (navigator as { userAgent: string }).userAgent;

const isEdge = /Edge\//i.test(userAgent);
const isChrome =
  !isEdge &&
  (/Chrome/i.test(userAgent) || (platform.isIos && /CriOS/i.test(userAgent)));
const isFirefox =
  !isEdge &&
  (/Firefox/i.test(userAgent) || (platform.isIos && /FxiOS/i.test(userAgent)));
const isSafari =
  /Safari/i.test(userAgent) && !isEdge && !isChrome && !isFirefox;

/** Attempts to detect an in-app browser in Instagram or Facebook. */
export const isFacebookOrInstagram =
  /Instagram/i.test(userAgent) || /FBAN|FBAV/i.test(userAgent);

/** Checks if the user is using Safari on iOS */
export const isSafariOnIos = platform.isIos && isSafari;

export const isChromeOnAndroid = platform.isAndroid && isChrome;
