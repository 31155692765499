import './styles.css';

import LokaliseTranslator from '@mindoktor/patient-legacy/i18n/LokaliseTranslator';
import { useLocale } from '@mindoktor/patient-app/localization/components/LocaleProvider';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Router from '@mindoktor/patient-app/routing/components/Router/web';
import { setApiCompany, setApiSite } from '../../state/api/actions';
import { appStart, initApp } from '../../state/app/actions';
import { setFeatureFlags } from '../../state/featureflags/actions';
import Snackbar from '../components/snackbar/snackbar';
import Notifications from '../notifications/notifications';
import ThemeProvider from '../theme/ThemeProvider';
import { getDeviceId, getSessionId } from '@mindoktor/patient-app/api';
import { userAgent } from '../utils/device';
import { getCompanyIdFromHost, getFeatureFlagsFromHost } from '../utils/host';
import { getSite } from '../utils/site';
import { CommonStateType } from '../../state/types';

// only allow for translation ui on test+local env
// const enableTranslationUI = !!config.IsDev;
// disabled for now, due to conflicts with snapshot-tests:
const enableTranslationUI = false;

const App = () => {
  const dispatch = useDispatch();
  const locale = useLocale();

  const currentLocaleLanguage = useSelector(
    (state: CommonStateType) => state.intl.currentLocale
  );

  useEffect(() => {
    // eslint-disable-next-line no-eq-null
    if (locale == null || locale.currentLocale != null) {
      return;
    }

    locale.setDefaultLocaleForLanguage(currentLocaleLanguage);
  }, [currentLocaleLanguage]);

  useEffect(() => {
    dispatch(setFeatureFlags(getFeatureFlagsFromHost() || []));
    dispatch(setApiSite(getSite()));
    dispatch(setApiCompany(getCompanyIdFromHost()));
    dispatch(
      initApp({
        userAgent,
        deviceId: getDeviceId(),
        sessionId: getSessionId(),
      })
    );
    dispatch(appStart('web', '1'));
  }, []);

  return (
    <ThemeProvider>
      <Router />
      <Notifications />
      <Snackbar />
      {!!enableTranslationUI && (
        <LokaliseTranslator locale={currentLocaleLanguage} />
      )}
    </ThemeProvider>
  );
};

export default App;
