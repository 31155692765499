import { getDomainPublicInfo } from '../api/selectors';

export const getInformedConsentsByEntrywayId = (entrywayId, { consents }) =>
  consents.informedConsentsByEntrywayId[entrywayId];

export const getInformedConsentBoolByEntrywayId = (
  entrywayId,
  { consents }
) => {
  const informedConsent = consents.informedConsentsByEntrywayId[entrywayId];

  if (!informedConsent) {
    return false;
  }

  return informedConsent.first || informedConsent.second || false;
};

export const isInformedConsentRequired = (state) => {
  const domainInfo = getDomainPublicInfo(state);

  if (!domainInfo) {
    return false;
  }

  if (domainInfo.requireInformedConsent) {
    return true;
  }

  return false;
};
