import { connect } from 'react-redux';
import { Component } from 'react';

import { track } from './actions';

interface TrackingProps {
  trackingEvent?: string;
  trackingProperties?: unknown;
  trackingReceiver?: string[];
  onPress?: () => void;
}

export const trackOnPress = <ComponentT = Component>(component: ComponentT) =>
  connect(null, (dispatch, props: TrackingProps) => ({
    onPress: () => {
      const { trackingEvent, trackingProperties, trackingReceiver, onPress } =
        props;

      onPress && onPress();

      dispatch(track(trackingEvent, trackingProperties, trackingReceiver));
    },
  }))(component as typeof Component<TrackingProps>) as unknown as ComponentT;
