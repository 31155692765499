import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { _t } from '@mindoktor/patient-legacy/i18n';
import React from 'react';

export default class CancelModal extends React.Component {
  render() {
    const { open, close, cancel } = this.props;

    return (
      <Dialog open={open} onClose={close} maxWidth="sm" fullWidth>
        <DialogTitle disableTypography>
          <Typography variant="h2">
            {_t('formulary.cancelModal.title')}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography>{_t('formulary.cancelModal.body')}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} variant="outlined" color="secondary">
            {_t('formulary.cancelModal.cancel')}
          </Button>
          <Button onClick={cancel} variant="contained" color="secondary">
            {_t('formulary.cancelModal.exit')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
