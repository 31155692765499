export const REFERRAL_LAB_PREVIEW = 'REFERRAL_LAB_PREVIEW';
export const REFERRAL_LAB_PREVIEW_CLEAR = 'REFERRAL_LAB_PREVIEW_CLEAR';
export const REFERRALLABS_REQUEST = 'REFERRALLABS_REQUEST';
export const REFERRALLABS_REQUEST_ERROR = 'REFERRALLABS_REQUEST_ERROR';
export const REFERRALLABS_REQUEST_SUCCESS = 'REFERRALLABS_REQUEST_SUCCESS';

export const REFERRAL_LAB_CHOOSE = 'REFERRAL_LAB_CHOOSE';
export const REFERRAL_LAB_CHOOSE_ERROR = 'REFERRAL_LAB_CHOOSE_ERROR';
export const REFERRAL_LAB_CHOOSE_SUCCESS = 'REFERRAL_LAB_CHOOSE_SUCCESS';

export const REFERRAL_LAB_DONE = 'REFERRAL_LAB_DONE';
export const REFERRAL_LAB_DONE_ERROR = 'REFERRAL_LAB_DONE_ERROR';
export const REFERRAL_LAB_DONE_SUCCESS = 'REFERRAL_LAB_DONE_SUCCESS';

export const REFERRAL_LAB_REQUEST_SUPPORT = 'REFERRAL_LAB_REQUEST_SUPPORT';
export const REFERRAL_LAB_REQUEST_SUPPORT_SUCCESS =
  'REFERRAL_LAB_REQUEST_SUPPORT_SUCCESS';
export const REFERRAL_LAB_REQUEST_SUPPORT_ERROR =
  'REFERRAL_LAB_REQUEST_SUPPORT_ERROR';

export const REFERRAL_SELFTEST_CONFIRM = 'REFERRAL_SELFTEST_CONFIRM';
export const REFERRAL_SELFTEST_CONFIRM_ERROR =
  'REFERRAL_SELFTEST_CONFIRM_ERROR';
export const REFERRAL_SELFTEST_CONFIRM_SUCCESS =
  'REFERRAL_SELFTEST_CONFIRM_SUCCESS';

export const REFERRAL_SELFTEST_SENT = 'REFERRAL_SELFTEST_SENT';
export const REFERRAL_SELFTEST_SENT_ERROR = 'REFERRAL_SELFTEST_SENT_ERROR';
export const REFERRAL_SELFTEST_SENT_SUCCESS = 'REFERRAL_SELFTEST_SENT_SUCCESS';

export const REFERRAL_MAP_SCREEN_VIEW = 'REFERRAL_MAP_SCREEN_VIEW';

export const USER_REGION_SET = 'USER_REGION_SET';
export const USER_REGION_SET_SUCCESS = 'USER_REGION_SET_SUCCESS';
export const USER_REGION_SET_ERROR = 'USER_REGION_SET_ERROR';

export const USER_REGION_CLEAR = 'USER_REGION_CLEAR';

export const REFERRALS_FETCH_SHARED_RESULTS = 'REFERRALS_FETCH_SHARED_RESULTS';
export const REFERRALS_FETCH_SHARED_RESULTS_ERROR =
  'REFERRALS_FETCH_SHARED_RESULTS_ERROR';
export const REFERRALS_FETCH_SHARED_RESULTS_SUCCESS =
  'REFERRALS_FETCH_SHARED_RESULTS_SUCCESS';
export const REFERRALS_SET_IDS = 'REFERRALS_SET_IDS';
export const REFERRALS_NEAREST_PHARMACY_SET = 'REFERRALS_NEAREST_PHARMACY_SET';
