import React, { Component } from 'react';

import { Body3 } from '../../../common/components/typography';

const styles = {
  link: {
    display: 'inline-block',
    textDecoration: 'none',
    paddingTop: 1,
    paddingBottom: 1,
  },

  linkText: {
    display: 'inline-block',
  },
};

export default class Link extends Component {
  state = {
    hover: false,
  };

  onMouseEnter = () => this.setState({ hover: true });

  onMouseLeave = () => this.setState({ hover: false });

  render() {
    const { href, style, children } = this.props;
    const { hover } = this.state;

    return (
      <a
        href={href}
        style={{ ...styles.link, ...style }}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        <Body3
          style={{ ...styles.linkText, color: hover ? 'black' : '#646464' }}
        >
          {children}
        </Body3>
      </a>
    );
  }
}
