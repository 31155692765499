import React from 'react';

import apoteketIcon from '../../../../../images/apoteket/ic_apoteket_mark@4x.png';
import iconBorrelia from '../../../../../images/guides/ill_borrelia.svg';
import iconDefault from '../../../../../images/logo/heart_alone.svg';
import iconVaccination from '../../../../../images/vaccinationbook/vaccination@3x.png';
import colors from '../../../../common/colors';
import { Body1 } from '../../../../common/components/typography';
import constants from '../../../../common/constants';
import spacing from '../../../../common/spacing';
import Link from '../../../components/link';
import Markdown from '../../../components/markdown';
import Message from '../../components/message';
import Img from '@mindoktor/pulse/src/utils/images/Img.web';

/**
 *  @typedef {import('./marketing_link_item_definitions').Variant} Variant
 *  @param {Variant} variant
 */
const icon = (variant) => {
  switch (variant) {
    case 'aph':
      return apoteketIcon;
    case 'tbeVaccination':
      return iconBorrelia;
    case 'seasonalFluVaccination':
      return iconVaccination;
    default:
      return iconDefault;
  }
};

/**  @param {Variant} variant */
const iconStyle = (variant) => {
  switch (variant) {
    case 'aph':
      return {
        display: 'block',
        width: 24,
        height: 24,
      };
    case 'seasonalFluVaccination':
      return {
        display: 'block',
        width: 34,
        height: 34,
      };
    default:
      return {
        display: 'block',
        width: 35,
        height: 27,
      };
  }
};

const defaultIconWrapperStyle = {
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
  width: constants.thumbDoctor,
  height: constants.thumbDoctor,
  marginRight: spacing.xs,
  borderRadius: '50%',
};

/**  @param {Variant} variant */
const iconWrapperStyle = (variant) => {
  switch (variant) {
    case 'aph':
      return {
        ...defaultIconWrapperStyle,
        backgroundColor: colors.white,
      };
    default:
      return defaultIconWrapperStyle;
  }
};

/**  @param {Variant} variant */
const backgroundStyle = (variant) => {
  switch (variant) {
    case 'aph':
      return { backgroundColor: colors.apoteket_hjartat_background };
    default:
      return { backgroundColor: colors.red_100 };
  }
};

/**  @param {Variant} variant */
const linkColor = (variant) => {
  switch (variant) {
    case 'aph':
      return { color: colors.apoteket_hjartat_textcolor };
    default:
      return {};
  }
};

/** @param {import('./marketing_link_item_definitions').Props} props */
export default function MarketingLinkItem(props) {
  return (
    <Message
      style={{
        ...props.style,
        marginBottom: spacing.t,
        backgroundColor: backgroundStyle(props.variant).backgroundColor,
      }}
    >
      <div style={styles.header}>
        <div style={iconWrapperStyle(props.variant)}>
          <Img src={icon(props.variant)} style={iconStyle(props.variant)} />
        </div>
        <div>
          <Markdown source={props.children} textComponent={Body1} />
        </div>
      </div>
      <div style={styles.linkWrapper}>
        <Link style={linkColor(props.variant)} href={props.url}>
          {props.linkTitle} ›
        </Link>
      </div>
    </Message>
  );
}

const styles = {
  header: {
    paddingBottom: spacing.xs,
    display: 'grid',
    gridTemplateColumns: '60px auto',
  },
  linkWrapper: {
    display: 'inline',
    marginLeft: 60,
  },
};
