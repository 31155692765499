import React from 'react';

import { getSpacingStyle } from '../../../spacing';
import { nativeToWeb } from '../../../styleUtils';

const View = ({
  children,
  style,
  className,
  mb,
  mt,
  ml,
  mr,
  onPress,
  onMouseEnter,
  onMouseLeave,
}) => {
  const spacingStyle = getSpacingStyle({
    mb,
    mt,
    ml,
    mr,
  });
  const clickableStyle = onPress ? { cursor: 'pointer' } : {};

  const webStyle = style
    ? nativeToWeb([style, spacingStyle, clickableStyle])
    : nativeToWeb([spacingStyle, clickableStyle]);
  return (
    <div
      style={webStyle}
      className={className}
      onClick={onPress}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </div>
  );
};
export default View;
