import React, { useEffect, useState } from 'react';

import { useExperiment } from '@mindoktor/patient-app/experiments';

import { WEB_MARKETING_INTRO_VS_CHAT_EXPERIMENT } from '../../../constants/web';
import { useShouldShowMarketingConsent } from '../../../hooks/useShouldShowMarketingConsent';
import MarketingConsentBottomSheet from '../../MarketingConsentBottomSheet/web';

const DELAY_MS = 3500 as const;

export const Placings = {
  intro: 'intro',
  chat: 'chat',
} as const;
type Placing = keyof typeof Placings;

interface Props {
  placing: Placing;
  delayed?: boolean;
}

const MarketingConsentHandler: React.FC<Props> = ({
  placing,
  delayed = false,
}) => {
  const { data: shouldShow } = useShouldShowMarketingConsent();
  const [hasDelayPassed, setHasDelayPassed] = useState(!delayed);

  const shouldShowAndReady = shouldShow && hasDelayPassed;

  const {
    isLoading,
    data: { variant },
  } = useExperiment(WEB_MARKETING_INTRO_VS_CHAT_EXPERIMENT, shouldShowAndReady);

  useEffect(() => {
    if (!hasDelayPassed) {
      setTimeout(() => setHasDelayPassed(true), DELAY_MS);
    }
  }, []);

  if (!shouldShowAndReady || isLoading) {
    return null;
  }

  if (
    (variant === 0 && placing === Placings.intro) ||
    (variant === 1 && placing === Placings.chat)
  ) {
    return <MarketingConsentBottomSheet />;
  }

  return null;
};

export default MarketingConsentHandler;
