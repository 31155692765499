/** Get the redirect route from the URL param `state` at the current location. */
export const getRedirectRoute = () => {
  const url = new URL(window.location.origin + window.location.hash.slice(1));
  const redirectRoute = url.searchParams.get('state') ?? null;

  return redirectRoute;
};

/** Gets the full hash route, including search params */
export const getFullHashRoute = () => {
  return window.location.hash.slice(1) ?? '';
};
