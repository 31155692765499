export const VIDEOCHAT_CREDENTIALS_REQUEST = 'VIDEOCHAT_CREDENTIALS_REQUEST';
export const VIDEOCHAT_CREDENTIALS_REQUEST_SUCCESS =
  'VIDEOCHAT_CREDENTIALS_REQUEST_SUCCESS';
export const VIDEOCHAT_CREDENTIALS_REQUEST_ERROR =
  'VIDEOCHAT_CREDENTIALS_REQUEST_ERROR';

export const VIDEOCHAT_PRESENCE_REQUEST = 'VIDEOCHAT_PRESENCE_REQUEST';
export const VIDEOCHAT_PRESENCE_REQUEST_SUCCESS =
  'VIDEOCHAT_PRESENCE_REQUEST_SUCCESS';
export const VIDEOCHAT_PRESENCE_REQUEST_ERROR =
  'VIDEOCHAT_PRESENCE_REQUEST_ERROR';

// export type Session = {
//   caseId: string,
//   sessionId: string,
// };

// export type Credentials = {
//   apiKey?: string,
//   sessionId?: string,
//   token?: string,
//   precallSessionId?: string,
//   precallToken?: string,
//   userId?: number,
//   roomId?: string,
// };

// export type VideochatState = {
//   +credentialsByCaseId: { +[id: number]: Credentials },
//   sessions: Session[],
// };
