/* eslint-env browser */

import { getIn } from 'timm';

export const userAgent = getIn(window, ['navigator', 'userAgent']) || '';

export const isPhone = /Phone|Android(?=.*Mobile)/i.test(userAgent);
export const isTablet = /iPad|Android(?!.*Mobile)/i.test(userAgent);
export const isDesktop = !isPhone && !isTablet;

export const isIos = /iPad|iPhone|iPod/i.test(userAgent) && !window.MSStream;
const versionInfo = /OS (\d+)/.exec(userAgent);
export const iosVersion =
  versionInfo && versionInfo.length >= 2 ? versionInfo[1] : undefined;
export const isAndroid = /Android/i.test(userAgent);

export const isEdge = /Edge\//i.test(userAgent);
export const isIe =
  !isEdge && (/MSIE/i.test(userAgent) || /Trident\/7.0/i.test(userAgent));
export const isChrome =
  !isEdge && (/Chrome/i.test(userAgent) || (isIos && /CriOS/i.test(userAgent)));
export const isFirefox =
  !isEdge &&
  (/Firefox/i.test(userAgent) || (isIos && /FxiOS/i.test(userAgent)));
export const isOpera = !isEdge && /Opera/i.test(userAgent);
export const isSafari =
  /Safari/i.test(userAgent) && !isEdge && !isChrome && !isFirefox;

// Currently only Internet Explorer is not supported.
export const isBrowserSupported = () => !isIe;

export const getPlatform = () => {
  if (isIos) return Platforms.IOS;
  if (isAndroid) return Platforms.ANDROID;
  if (isDesktop) return Platforms.DESKTOP;
  return Platforms.UNKNOWN;
};

export const getBrowser = () => {
  if (isChrome) return Browsers.CHROME;
  if (isFirefox) return Browsers.FIREFOX;
  if (isSafari) return Browsers.SAFARI;
  return Browsers.UNKNOWN;
};

export const Platforms = {
  IOS: 'PLATFORM_IOS',
  ANDROID: 'PLATFORM_ANDROID',
  DESKTOP: 'PLATFORM_DESKTOP',
  UNKNOWN: 'PLATFORM_UNKNOWN',
};

export const Browsers = {
  CHROME: 'BROWSER_CHROME',
  FIREFOX: 'BROWSER_FIREFOX',
  SAFARI: 'BROWSER_SAFARI',
  UNKNOWN: 'BROWSER_UNKNOWN',
};

let iosHeightOffset;

export const getIosHeightOffset = () => {
  if (iosHeightOffset !== undefined) {
    return iosHeightOffset;
  }

  // test with fixed
  const fixedTest = document.createElement('div');
  fixedTest.style.cssText = 'position: fixed; top: 0; bottom: 0;';
  document.documentElement &&
    document.documentElement.insertBefore(
      fixedTest,
      document.documentElement && document.documentElement.firstChild
    );
  // test with vh
  const vhTest = document.createElement('div');
  vhTest.style.cssText = 'position: fixed; top: 0; height: 100vh';
  document.documentElement &&
    document.documentElement.insertBefore(
      vhTest,
      document.documentElement && document.documentElement.firstChild
    );
  // in iOS vh will be bigger
  const topBottom = fixedTest.offsetHeight;
  const vh = vhTest.offsetHeight;
  iosHeightOffset = vh - topBottom;
  // clean
  document.documentElement && document.documentElement.removeChild(fixedTest);
  document.documentElement && document.documentElement.removeChild(vhTest);

  return iosHeightOffset;
};

export const getFadeDuration = () => {
  const md_fade_duration =
    window &&
    window.localStorage &&
    window.localStorage.getItem('md_fade_duration');

  return md_fade_duration === null ? 200 : parseInt(md_fade_duration, 10);
};
