import { _t } from '@mindoktor/patient-legacy/i18n';
import React from 'react';

import arrowIconSource from '../../../../../images/icon/ic_back@3x.png';
import iconDone from '../../../../../images/icon/icon_done@4x.png';
import colors from '../../../../common/colors';
import { Body1, Body3, Intro } from '../../../../common/components/typography';
import spacing from '../../../../common/spacing';
import { formatRoute } from '../../../../state/routing';
import Button from '../../../components/button/button';
import { getSiteMedicalComplaints } from '../../../utils/site';
import Message from '../../components/message';
import Header from '../components/header';

import { routes } from '@mindoktor/patient-app/routing/constants/webRoutes';
import Img from '@mindoktor/pulse/src/utils/images/Img.web';

const styles = {
  body: {
    marginBottom: spacing.t,
  },
  link: {
    marginTop: spacing.t,
    display: 'flex',
    flexDirection: 'row',
    textDecoration: 'none',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  linkText: {
    color: colors.red_500,
  },
  button: {},
  complaintsInfo: {
    marginTop: spacing.m,
    marginBottom: spacing.s,
    color: colors.grey_700,
  },
  arrow: {
    display: 'block',
    height: 18,
    marginTop: 4,
    transform: 'rotate(180deg)',
    opacity: '0.3',
  },
};

const Link = () => (
  <a style={styles.link} href={getSiteMedicalComplaints()}>
    <Intro style={styles.linkText}>
      {_t('closedCase.medicalComplaintsLink')}
    </Intro>
    <Img src={arrowIconSource} style={styles.arrow} />
  </a>
);

const RevisitButton = ({
  openRevisitInvitation: {
    guideParams: { entrywayId, guideType },
    patientUUID,
    id: revisitId,
  },
}) => (
  <Button
    href={`#${formatRoute(routes.GUIDES, {
      entrywayId,
      childUuid: guideType === 'children' ? patientUUID : undefined,
      revisitId,
    })}`}
    style={styles.button}
  >
    {_t('closedCase.doRevisit')}
  </Button>
);

export default class ClosedCase extends React.Component {
  render() {
    const { timestamp, body, openRevisitInvitation = null, style } = this.props;

    return (
      <Message style={style}>
        <Header
          title={_t('closedCase.title')}
          timestamp={timestamp}
          image={iconDone}
        />

        <Body1 style={styles.body}>{body}</Body1>
        {openRevisitInvitation &&
          openRevisitInvitation.isOpen &&
          openRevisitInvitation.caseOwnerCanRepresentPatient && (
            <RevisitButton openRevisitInvitation={openRevisitInvitation} />
          )}
        <Body3 style={styles.complaintsInfo}>
          {_t('closedCase.medicalComplaintsInfo')}
        </Body3>
        <Link />
      </Message>
    );
  }
}
