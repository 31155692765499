import React from 'react';

import Body from './body';
import Card from './card';

export { default as CardBody } from './body';
export { default as Card } from './card';

export const CardWithBody = ({ children, ...cardProps }) => (
  <Card {...cardProps}>
    <Body>{children}</Body>
  </Card>
);
