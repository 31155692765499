import React from 'react';

import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import BackChevronIcon from '@mindoktor/pulse/src/icons/BackChevronIcon/BackChevronIcon';

import { useNavigation } from '@mindoktor/patient-app/routing/web';

const NavigationHeaderMobile: React.FC = () => {
  const navigation = useNavigation();
  return (
    <Stack direction="row">
      <IconButton
        edge="start"
        color="transparent"
        size="large"
        onClick={() => navigation.goBack()}
      >
        <BackChevronIcon />
      </IconButton>
    </Stack>
  );
};

export default NavigationHeaderMobile;
