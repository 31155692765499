import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';

import { useNotifications } from '@mindoktor/patient-app/adapters/hooks/useNotifications';
import { ResponseError } from '@mindoktor/patient-app/api/responseError';
import { useTranslation } from '@mindoktor/patient-app/localization/hooks/useTranslation';

import { authApi } from '../api/auth';

import { authenticationQueryKey } from './useIsAuthenticatedApi';

type LogoutMutation = UseMutationResult<void, ResponseError, void>;

export const useLogoutMutator = (): LogoutMutation => {
  const queryClient = useQueryClient();
  const notifications = useNotifications();
  const t = useTranslation('error');

  return useMutation(
    async () => {
      const response = await authApi.logout();
      if (!response.ok) {
        throw new ResponseError(response);
      }
      return response.data;
    },
    {
      onError: async (_error) => {
        notifications.showWarning(t('unknown'));
      },
      onSuccess: async (_error) => {
        void queryClient.resetQueries([authenticationQueryKey]);
      },
    }
  );
};
