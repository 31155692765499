import { Schema } from 'zod';

import Config from '@mindoktor/env/Config';

import { ApiResponse } from '../../requestHandler';

export const validate = <TData>(
  response: ApiResponse<TData>,
  schema: Schema
): void => {
  if (!response.ok) {
    return;
  }

  if (Config.SentryEnabled === true) {
    // TODO: Here we should safe parse and log error to Sentry.
  } else {
    schema.parse(response.data);
  }
};
