import React from 'react';
import { connect } from 'react-redux';
import { fetchCaseOverviewById } from '../../../../state/cases/actions';
import { getCase } from '../../../../state/cases/selectors';
import { showNotification } from '../../../../state/notifications/actions';
import { track } from '../../../../state/tracking/actions';
import Message from '../../components/message';
import Answers from './answers';

class Intro extends React.Component {
  render() {
    const {
      caseId,
      iconSource,
      style,
      hasTechnicalError,
      hasPayment,
      hasHistory,
      updated,
      firstName,
    } = this.props;

    return (
      <Message style={style}>
        <Answers
          caseId={caseId}
          iconSource={iconSource}
          hasTechnicalError={hasTechnicalError}
          hasPayment={hasPayment}
          hasHistory={hasHistory}
          updated={updated}
          firstName={firstName}
        />
      </Message>
    );
  }
}

export default connect(
  (state, { caseId }) => {
    const caseData = getCase(state, caseId);

    return {
      hasHistory: caseData?.guideHasHistory,
      updated: caseData?.guideUpdated,
    };
  },
  {
    fetchCaseOverviewById,
    showNotification,
    track,
  }
)(Intro);
