export const WORKOUT_SHOW = 'WORKOUT_SHOW';
export const WORKOUT_DISMISS = 'WORKOUT_DISMISS';
export const WORKOUT_DETAILS_FETCH = 'WORKOUT_DETAILS_FETCH';
export const WORKOUT_DETAILS_FETCH_ERROR = 'WORKOUT_DETAILS_FETCH_ERROR';
export const WORKOUT_DETAILS_FETCH_SUCCESS = 'WORKOUT_DETAILS_FETCH_SUCCESS';

// export type WorkoutDetailsFetchAction = {
//   type: typeof WORKOUT_DETAILS_FETCH,
// } & Action;
// export type WorkoutDetailsFetchErrorAction = {
//   type: typeof WORKOUT_DETAILS_FETCH_ERROR,
//   error: Error,
// } & Action;
// export type WorkoutDetailsFetchSuccessAction = {
//   type: typeof WORKOUT_DETAILS_FETCH_SUCCESS,
//   payload: {
//     workoutsById: { [id]: Workout },
//     allWorkoutIds: number[],
//   },
// } & Action;

// export type WorkoutState = {
//   selectedWorkout?: number,
//   workoutsById: { [id]: Workout },
//   allWorkoutIds: number[],
//   isLoading: boolean,
// };

// export type Workout = {
//   id: number,
//   title: string,
//   description: string,
//   exercises: Exercise[],
//   videoLink: string,
// };

// export type Exercise = {
//   id: number,
//   title: string,
//   description: string,
//   videoLink: string,
// };
