import { defineMessages } from 'react-intl';

const msg = defineMessages({
  // LabTest Referrals
  labTestNewHeader: {
    id: 'case.referral.labtest.new.header',
    defaultMessage: 'Referral to sampling',
  },
  labTestNewSubHeader: {
    id: 'case.referral.labtest.new.subheader',
    defaultMessage:
      'In order for your doctor to make an accurate diagnosis, you need to take samples. You choose where you want to go.',
  },
  labTestSentHeader: {
    id: 'case.referral.labtest.sent.header',
    defaultMessage: 'Sampling done',
  },
  labTestSentSubHeader: {
    id: 'case.referral.labtest.sent.subheader',
    defaultMessage:
      'Your doctor will contact you when the test responses assessed.',
  },
  labTestCanceledHeader: {
    id: 'case.referral.labtest.canceled.header',
    defaultMessage: 'Sampling canceled',
  },
  labTestCanceledSubHeader: {
    id: 'case.referral.labtest.canceled.subheader',
    defaultMessage: 'Your sampling has been canceled',
  },
  labTestMarkedDoneHeader: {
    id: 'case.referral.labtest.done.header',
    defaultMessage: 'Sampling done',
  },
  labTestMarkedDoneSubHeader: {
    id: 'case.referral.labtest.done.subheader',
    defaultMessage:
      'Your doctor will contact you when the test responses assessed.',
  },
  labTestApprovedHeader: {
    id: 'case.referral.labtest.approved.header',
    defaultMessage: 'Lab Test',
  },
  labTestApprovedSubHeader: {
    id: 'case.referral.labtest.approved.subheader',
    defaultMessage: 'You need to print your referral and bring it to the lab.',
  },
  labTestApprovedSubHeaderOld: {
    id: 'case.referral.labtest.approved.subheader.old',
    defaultMessage: 'You need to print your referral and bring it to the lab.',
  },
  labTestButtonNew: {
    id: 'case.referral.labtest.button.new',
    defaultMessage: 'Select lab',
  },
  labTestButtonApproved: {
    id: 'case.referral.labtest.button.approved',
    defaultMessage: 'Labtest done',
  },

  // XRay Referrals
  xRayNewHeader: {
    id: 'case.referral.xray.new.header',
    defaultMessage: 'Referral to X-ray',
  },
  xRayNewSubHeader: {
    id: 'case.referral.xray.new.subheader',
    defaultMessage:
      'In order for your doctor to make an accurate diagnosis, you need to be X-rayed. You choose where to send the referral.',
  },
  xRaySentHeader: {
    id: 'case.referral.xray.sent.header',
    defaultMessage: 'XRay complete',
  },
  xRaySentSubHeader: {
    id: 'case.referral.xray.sent.subheader',
    defaultMessage: 'Your doctor will contact you when the images assessed.',
  },
  xRayCanceledHeader: {
    id: 'case.referral.xray.canceled.header',
    defaultMessage: 'XRay cancelled',
  },
  xRayCanceledSubHeader: {
    id: 'case.referral.xray.canceled.subheader',
    defaultMessage: 'Your X-ray has been canceled',
  },
  xRayMarkedDoneHeader: {
    id: 'case.referral.xray.done.header',
    defaultMessage: 'XRay done',
  },
  xRayMarkedDoneSubHeader: {
    id: 'case.referral.xray.done.subheader',
    defaultMessage:
      'Your doctor will contact you when the images have been looked at',
  },
  xRayApprovedHeader: {
    id: 'case.referral.xray.approved.header',
    defaultMessage: 'Mark that you are ready when you rayed',
  },
  xRayApprovedSubHeader: {
    id: 'case.referral.xray.approved.subheader',
    defaultMessage: 'Mark that you are ready when you rayed',
  },
  xRayApprovedSubHeaderOld: {
    id: 'case.referral.xray.approved.subheader.old',
    defaultMessage: 'Mark that you are ready when you rayed',
  },
  xRayButtonNew: {
    id: 'case.referral.xray.button.new',
    defaultMessage: 'Choose Xray location',
  },
  xRayButtonApproved: {
    id: 'case.referral.xray.button.approved',
    defaultMessage: 'Mark as done',
  },

  // Freetext Referrals
  freeTextNewHeader: {
    id: 'case.referral.freetext.new.header',
    defaultMessage: 'Referral to other health care facility',
  },
  freeTextNewSubHeader: {
    id: 'case.referral.freetext.new.subheader',
    defaultMessage:
      'You will be contacted to get an appointment by the medical facility that you are referred to.',
  },
  freeTextSentHeader: {
    id: 'case.referral.freetext.sent.header',
    defaultMessage: 'Other welfare institution',
  },
  freeTextSentSubHeader: {
    id: 'case.referral.freetext.sent.subheader',
    defaultMessage:
      'You will be contacted to get an appointment by the medical facility that you are referred to.',
  },
  freeTextCanceledHeader: {
    id: 'case.referral.freetext.canceled.header',
    defaultMessage: 'Referral cancelled',
  },
  freeTextCanceledSubHeader: {
    id: 'case.referral.freetext.canceled.subheader',
    defaultMessage: 'Your referral has been canceled',
  },
  freeTextMarkedDoneHeader: {
    id: 'case.referral.freetext.done.header',
    defaultMessage: 'Other welfare institution',
  },
  freeTextMarkedDoneSubHeader: {
    id: 'case.referral.freetext.done.subheader',
    defaultMessage:
      'You will be contacted to get an appointment by the medical facility that you are referred to.',
  },
  freeTextApprovedHeader: {
    id: 'case.referral.freetext.approved.header',
    defaultMessage: 'Referral to other health care facility',
  },
  freeTextApprovedSubHeader: {
    id: 'case.referral.freetext.approved.subheader',
    defaultMessage:
      'You will be contacted to get an appointment by the medical facility that you are referred to.',
  },

  // OtherEstablishment
  otherEstablishmentNewHeader: {
    id: 'case.referral.otherestablishment.new.header',
    defaultMessage: 'Referral to other health care facility',
  },
  otherEstablishmentNewSubHeader: {
    id: 'case.referral.otherestablishment.new.subheader',
    defaultMessage:
      'You will be contacted to get an appointment by the medical facility that you are referred to.',
  },
  otherEstablishmentSentHeader: {
    id: 'case.referral.otherestablishment.sent.header',
    defaultMessage: 'Other welfare institution',
  },
  otherEstablishmentSentSubHeader: {
    id: 'case.referral.otherestablishment.sent.subheader',
    defaultMessage:
      'You will be contacted to get an appointment by the medical facility that you are referred to.',
  },
  otherEstablishmentCanceledHeader: {
    id: 'case.referral.otherestablishment.canceled.header',
    defaultMessage: 'Referral cancelled',
  },
  otherEstablishmentCanceledSubHeader: {
    id: 'case.referral.otherestablishment.canceled.subheader',
    defaultMessage: 'Your referral has been canceled',
  },
  otherEstablishmentMarkedDoneHeader: {
    id: 'case.referral.otherestablishment.done.header',
    defaultMessage: 'Other welfare institution',
  },
  otherEstablishmentMarkedDoneSubHeader: {
    id: 'case.referral.otherestablishment.done.subheader',
    defaultMessage:
      'You will be contacted to get an appointment by the medical facility that you are referred to.',
  },
  otherEstablishmentApprovedHeader: {
    id: 'case.referral.otherestablishment.approved.header',
    defaultMessage: 'Referral to other health care facility',
  },
  otherEstablishmentApprovedSubHeader: {
    id: 'case.referral.otherestablishment.approved.subheader',
    defaultMessage:
      'You will be contacted to get an appointment by the medical facility that you are referred to.',
  },

  // ContactTracing Referrals
  tracingNewHeader: {
    id: 'case.referral.tracing.new.header',
    defaultMessage: 'Contact tracing',
  },
  tracingNewSubHeader: {
    id: 'case.referral.tracing.new.subheader',
    defaultMessage: 'Referral for contact tracing',
  },
  tracingSentHeader: {
    id: 'case.referral.tracing.sent.header',
    defaultMessage: 'Contact Tracing',
  },
  tracingSentSubHeader: {
    id: 'case.referral.tracing.sent.subheader',
    defaultMessage:
      'Your doctor has sent a referral for tracing. You will be contacted by the clinic for consultation',
  },
  tracingCanceledHeader: {
    id: 'case.referral.tracing.canceled.header',
    defaultMessage: 'Tracing cancelled',
  },
  tracingCanceledSubHeader: {
    id: 'case.referral.tracing.canceled.subheader',
    defaultMessage: 'Your referral for tracing has been canceled',
  },
  tracingMarkedDoneHeader: {
    id: 'case.referral.tracing.done.header',
    defaultMessage: 'Contact Tracing',
  },
  tracingMarkedDoneSubHeader: {
    id: 'case.referral.tracing.done.subheader',
    defaultMessage:
      'Your doctor has sent a referral for tracing. You will be contacted by the clinic for consultation',
  },
  tracingApprovedHeader: {
    id: 'case.referral.tracing.approved.header',
    defaultMessage: 'Contact Tracing',
  },
  tracingApprovedSubHeader: {
    id: 'case.referral.tracing.approved.subheader',
    defaultMessage:
      'Your doctor has sent a referral for tracing. You will be contacted by the clinic for consultation',
  },

  // Default Referral Strings
  defaultNewHeader: {
    id: 'case.referral.default.new.header',
    defaultMessage: 'Self-test',
  },
  defaultNewHeaderCandida: {
    id: 'case.referral.default.new.header.candida',
    defaultMessage: 'Self-test - candida / bacterial vaginos',
  },
  defaultNewHeaderCoeliacDiseasePredisposition: {
    id: 'case.referral.default.new.header.coeliac.disease.predisposition',
    defaultMessage: 'Self-test - coeliac disease, predisposition',
  },
  defaultNewHeaderCoeliacDiseaseDiagnosisMonitoring: {
    id: 'case.referral.default.new.header.coeliac.disease.diagnosis.monitoring',
    defaultMessage: 'Self-test - coeliac disease, diagnosis/monitoring',
  },
  defaultNewHeaderCovid19: {
    id: 'case.referral.default.new.header.covid19',
    defaultMessage: 'Self-test - covid 19 - ongoing infection',
  },
  defaultNewHeaderFourSTIsFemale: {
    id: 'case.referral.default.new.header.four.stis.female',
    defaultMessage:
      'Self-test - chlamydia, mycoplasma, gonorrhea and trichomonas (FEMALE)',
  },
  defaultNewHeaderFourSTIsMale: {
    id: 'case.referral.default.new.header.four.stis.male',
    defaultMessage:
      'Self-test - chlamydia, mycoplasma, gonorrhea and trichomonas (MALE)',
  },
  defaultNewHeaderLactoseIntolerance: {
    id: 'case.referral.default.new.header.lactose.intolerance',
    defaultMessage: 'Self-test - lactose intolerance',
  },
  defaultNewHeaderNail: {
    id: 'case.referral.default.new.header.nail',
    defaultMessage: 'Self-test - nail fungus',
  },
  defaultNewHeaderSkin: {
    id: 'case.referral.default.new.header.skin',
    defaultMessage: 'Self-test - skin and foot fungus',
  },
  defaultNewHeaderUrine: {
    id: 'case.referral.default.new.header.urine',
    defaultMessage: 'Self-test - urine culture',
  },
  defaultNewSubHeader: {
    id: 'case.referral.default.new.subheader',
    defaultMessage:
      'Your doctor wants you to do a self-test that we send to your home.',
  },
  defaultSentHeader: {
    id: 'case.referral.default.sent.header',
    defaultMessage: 'Your self-test will be analyzed',
  },
  defaultSentSubHeader: {
    id: 'case.referral.default.sent.subheader',
    defaultMessage: 'Your doctor will contact you when its ready.',
  },
  defaultCanceledHeader: {
    id: 'case.referral.default.canceled.header',
    defaultMessage: 'Referral cancelled',
  },
  defaultCanceledSubHeader: {
    id: 'case.referral.default.canceled.subheader',
    defaultMessage: 'Your referral has been canceled',
  },
  defaultMarkedDoneHeader: {
    id: 'case.referral.default.done.header',
    defaultMessage: 'Your self-test will be analyzed',
  },
  defaultMarkedDoneSubHeader: {
    id: 'case.referral.default.done.subheader',
    defaultMessage: 'Your doctor will contact you when its ready.',
  },
  defaultApprovedHeader: {
    id: 'case.referral.default.approved.header',
    defaultMessage:
      'Mark that you are ready when you have submitted your self test',
  },
  defaultButtonNew: {
    id: 'case.referral.default.button.new',
    defaultMessage: 'Approve',
  },
  defaultButtonApproved: {
    id: 'case.referral.default.button.approved',
    defaultMessage: 'Mark as completed',
  },
  buttonShowReferralPdf: {
    id: 'case.referral.button.showPdf',
    defaultMessage: 'Your referral',
  },
  buttonShowReferralPrint: {
    id: 'case.referral.button.showPrint',
    defaultMessage: 'How do I print it?',
  },
  buttonShowReferralPrintDesktop: {
    id: 'case.referral.button.showPrint.old',
    defaultMessage: 'How do I print it?',
  },
  howToButton: {
    id: 'case.referral.howToButton',
    defaultMessage: 'This is how it works',
  },
  UrgentReferralHeader: {
    id: 'case.referral.acute.header',
    defaultMessage: 'Acute Referral',
  },
  UrgentReferralSubHeader: {
    id: 'case.referral.acute.subheader',
    defaultMessage:
      'You need to seek physical care as soon as possible. Your doctor will tell you where to go.',
  },
});

export default msg;
