import { postApi } from '../api/actions';
import { isLoggedIn } from '../api/selectors';
import { localeMap } from './types';

export const SET_CURRENT_LOCALE = 'SET_CURRENT_LOCALE';
export const INTL_SET_USER_LOCALE_REQUEST = 'INTL_SET_USER_LOCALE_REQUEST';
export const INTL_SET_USER_LOCALE_REQUEST_SUCCESS =
  'INTL_SET_USER_LOCALE_REQUEST_SUCCESS';
export const INTL_SET_USER_LOCALE_REQUEST_ERROR =
  'INTL_SET_USER_LOCALE_REQUEST_ERROR';

export const setCurrentLocale = (locale) => ({
  type: SET_CURRENT_LOCALE,
  payload: { locale },
});

export const setUserLocale = (locale) => async (dispatch, getState) => {
  if (!isLoggedIn(getState())) {
    return dispatch(setCurrentLocale(locale));
  }

  dispatch({
    type: INTL_SET_USER_LOCALE_REQUEST,
    payload: {
      locale,
    },
  });
  try {
    const { error } = await dispatch(
      postApi('/api/v1/user/locale', {
        locale: localeMap[locale],
      })
    );

    dispatch(setCurrentLocale(locale));

    if (error) {
      throw new Error(error);
    }

    dispatch({
      type: INTL_SET_USER_LOCALE_REQUEST_SUCCESS,
      payload: {
        locale,
      },
    });
  } catch (err) {
    console.error('Failed to set user locale with argument ', locale);

    dispatch({
      type: INTL_SET_USER_LOCALE_REQUEST_ERROR,
      error: err,
    });
  }

  return null;
};
