import {
  VIDEOCHAT_CREDENTIALS_REQUEST,
  VIDEOCHAT_CREDENTIALS_REQUEST_SUCCESS,
  VIDEOCHAT_PRESENCE_REQUEST_SUCCESS,
} from './types';

export const initialState = {
  credentialsByCaseId: {},
  sessions: [],
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case VIDEOCHAT_CREDENTIALS_REQUEST: {
      if (!payload) return state;

      const { caseId } = payload;

      const credentialsByCaseId = { ...state.credentialsByCaseId };

      // $FlowFixMe I don't know what is happening here. It's a read only and resetting it to undefined.
      credentialsByCaseId[caseId] = undefined;

      return { ...state, credentialsByCaseId };
    }

    case VIDEOCHAT_CREDENTIALS_REQUEST_SUCCESS: {
      if (!payload) return state;

      const { caseId, credentials } = payload;

      const credentialsByCaseId = { ...state.credentialsByCaseId };

      credentialsByCaseId[caseId] = credentials;

      return { ...state, credentialsByCaseId };
    }

    case VIDEOCHAT_PRESENCE_REQUEST_SUCCESS: {
      if (!payload) return state;
      return { ...state, sessions: payload };
    }

    default:
      return state;
  }
};
