/** Extracts page.view tracking data for children screens. */
export const getChildrenTrackingDataFromProps = ({
  entrywayId,
  dynamicCode,
  preferredCaregiverId,
}: Record<string, string | number | undefined>) => ({
  entrywayId,
  dynamicCode,
  // this may be NaN because of some bad parseInt upstream in the app
  preferredCaregiverId:
    preferredCaregiverId?.toString() !== 'NaN'
      ? preferredCaregiverId
      : undefined,
});
