import { _t } from '@mindoktor/patient-legacy/i18n';

const shortDaysTrStrings: string[] = [
  'common.shortDay.mon',
  'common.shortDay.tue',
  'common.shortDay.wed',
  'common.shortDay.tho',
  'common.shortDay.fri',
  'common.shortDay.sat',
  'common.shortDay.sun',
];

/**
 * Provides a short day name for display purposes.
 *
 * @example formatShortDay(0) // 'mon'
 * @example formatShortDay(6) // 'sun'
 */
const formatShortDay = (dayNumber: number): string => {
  if (dayNumber < 0) {
    throw Error(`The dayNumber needs to be at least 0, found ${dayNumber}`);
  }
  if (dayNumber > shortDaysTrStrings.length - 1) {
    throw Error(
      `The dayNumber needs to be at most ${
        shortDaysTrStrings.length - 1
      }, found ${dayNumber}`
    );
  }
  return _t(shortDaysTrStrings[dayNumber]);
};

export default formatShortDay;
