import { _t } from '@mindoktor/patient-legacy/i18n';
import React, { Component } from 'react';

import { Body1, SubHead } from '../../../common/components/typography';
import HeartIcon from '../../components/icon/heart';

const styles = {
  root: {
    marginBottom: 10,
  },

  icon: {
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 10,
  },

  lead: {
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 10,
  },

  body: {
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 10,
  },
};

// type Props = {
//   childUuid?: string,

//   hasAnswers: boolean,
//   hasErrors: boolean,
// };

export class IntroScreen extends Component {
  getTexts = () => {
    return {
      lead: _t('guides.reviewIntro.lead'),
      body: _t('guides.reviewIntro.body'),
    };
  };

  render() {
    const { lead, body } = this.getTexts();

    return (
      <div style={styles.root}>
        <HeartIcon style={styles.icon} />

        <SubHead style={styles.lead}>{lead}</SubHead>

        <Body1 style={styles.body}>{body}</Body1>
      </div>
    );
  }
}

export default IntroScreen;
