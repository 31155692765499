import { _t } from '@mindoktor/patient-legacy/i18n';
import React from 'react';

import closeIcon from '../../../../images/icon/ic_close_medium@3x.png';
import searchIcon from '../../../../images/icon/icon_search.svg';
import colors from '../../../common/colors';
import DrugWarningModal from '../../../common/components/formulary/input/DrugWarningModal';
import SearchModalWrapper from '../../../common/components/SearchModalWrapper';
import {
  Body1,
  Body3,
  fontFamilies,
  Title2,
} from '../../../common/components/typography';
import spacing from '../../../common/spacing';
import Button from '../../components/button/button';
import ContinueButton from '../../components/button/continue';
import TextInput from '../../components/input/text';
import SearchModal from '../../components/search_modal';
import { hideModal, showModal } from '../../routing';
import { withRouting } from '@mindoktor/patient-app/routing/web';
import Img from '@mindoktor/pulse/src/utils/images/Img.web';

const styles = {
  step: {
    padding: '0 ' + spacing.s + 'px',
    display: 'flex',
    flexDirection: 'column',
    padding: spacing.s,
    paddingTop: 0,
  },

  error: {
    color: colors.red_v5000,
  },
  card: {
    padding: spacing.xs,
    paddingRight: spacing.xs + spacing.s + spacing.xt,
    backgroundColor: colors.white,
    shadowColor: colors.off_black,
    boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.3)',
    borderTop: `solid 3px ${colors.grey_500}`,
    marginBottom: spacing.s,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  cardClose: {
    width: spacing.s,
    height: spacing.s,
    position: 'absolute',
    top: spacing.xs,
    right: spacing.xs,
  },
  drugDescription: {
    color: colors.grey_700,
  },
  addButton: {
    marginBottom: spacing.xs,
  },
  nextButton: {
    alignSelf: 'flex-end',
  },
  buttonReset: {
    border: 'none',
    padding: 0,
    background: 'transparent',
    textAlign: 'left',
  },
  searchField: {
    padding: 0,
    display: 'flex',
    alignItems: 'stretch',
    cursor: 'text',
    backgroundColor: colors.white,
    marginBottom: spacing.xs,
  },
  searchFieldIcon: {
    borderBottom: 'solid 1px black',
    paddingRight: spacing.xs,
  },
  resultItem: {
    padding: `${spacing.t}px 0`,
  },
  resultItemLabel: {
    color: colors.red_500,
    fontFamily: fontFamilies.graphikSemibold,
  },
};

const SearchResultItem = ({ drug: { Label, Description } }) => (
  <div style={styles.resultItem}>
    <Body1 style={styles.resultItemLabel}>{Label}</Body1>
    <Body1 style={styles.drugDescription}>{Description}</Body1>
  </div>
);

class MultipleSearch extends React.Component {
  state = {
    step: 'init',
  };

  onClose = () => {
    this.setState({ step: 'stopped-searching' });
  };

  itemKeyExtractor = (drug) => drug.Value;

  FakeTextField = ({ setModalMode }) => {
    const {
      props: { hint },
    } = this;

    return (
      <button
        onMouseDown={() => {
          this.setState({ step: 'searching' });
          setModalMode();
        }}
        style={{
          ...styles.buttonReset,
          ...styles.searchField,
        }}
      >
        <img src={searchIcon} style={styles.searchFieldIcon} />
        <TextInput
          placeholder={hint}
          onFocus={(e) => {
            if (e && e.target) e.target.blur();
          }}
        />
      </button>
    );
  };

  SearchButton = ({ setModalMode }) => (
    <Button
      style={styles.addButton}
      type="ghost"
      color="black"
      onClick={() => {
        this.setState({ step: 'searching' });
        setModalMode();
      }}
    >
      {_t('choose_medication.add_one_more')}
    </Button>
  );

  SearchField = () => {
    const {
      itemKeyExtractor,
      FakeTextField,
      SearchButton,
      props: {
        canAddMore,
        onSelect,
        search,
        routing: {
          params: { entrywayId },
        },
        hint,
        answer,
      },
    } = this;
    return (
      <SearchModalWrapper
        hint={hint}
        itemKeyExtractor={itemKeyExtractor}
        children={(props) => (
          <SearchModal
            renderItem={(drug) => <SearchResultItem drug={drug} />}
            renderLaunchField={(setModalMode) =>
              !answer.length ? (
                <FakeTextField setModalMode={setModalMode} />
              ) : (
                canAddMore && <SearchButton setModalMode={setModalMode} />
              )
            }
            {...props}
          />
        )}
        onSelect={(drug) => {
          if (drug.Forbidden) {
            showModal(DrugWarningModal, {
              onClose: hideModal,
              drug,
              entrywayId: parseInt(entrywayId),
              itemKeyExtractor,
            });
            return;
          }
          onSelect(drug);
        }}
        search={search}
      />
    );
  };

  NextButton = () => {
    const {
      props: { onSubmit, error },
    } = this;
    return (
      <ContinueButton
        style={styles.nextButton}
        disabled={!!error}
        onClick={onSubmit}
      />
    );
  };

  ErrorMessage = () => {
    const {
      props: { errorMessage },
    } = this;

    return (
      <Body3 mb="t" style={styles.error}>
        {errorMessage}
      </Body3>
    );
  };

  // RemoveButton?
  CloseButton = ({ drug }) => {
    const {
      props: { onRemove },
    } = this;

    return (
      // for some reason, onClick did not work
      // on a button on Firefox in this specific case.
      <div
        onClick={() => {
          onRemove(drug);
        }}
        style={styles.buttonReset}
      >
        <Img src={closeIcon} style={styles.cardClose} />
      </div>
    );
  };

  ItemCard = ({ drug }) => {
    const { CloseButton } = this;

    return (
      <div style={styles.card}>
        <Title2>{drug.Label}</Title2>
        <Body1 style={styles.drugDescription}>{drug.Description}</Body1>
        <CloseButton drug={drug} />
      </div>
    );
  };

  render() {
    const {
      SearchField,
      ItemCard,
      ErrorMessage,
      NextButton,
      props: { answer, hasError },
    } = this;

    const hasAnswer = !!answer.length;

    return (
      <div style={styles.step}>
        {hasAnswer && (
          <React.Fragment>
            {answer.map((drug) => {
              return <ItemCard key={drug.Value} drug={drug} />;
            })}
          </React.Fragment>
        )}
        <SearchField />
        {hasAnswer && <NextButton />}
        {hasError && <ErrorMessage />}
      </div>
    );
  }
}

export default withRouting(MultipleSearch);
