import React from 'react';

import { IconButton } from '@mui/material';

import { colors } from '@mindoktor/pulse/src/styles/colors';
import { rem } from '@mindoktor/pulse/src/styles/conversions';

import ArrowIcon from '../../../icons/ArrowIcon/ArrowIcon';

interface Props {
  scrollableRef: React.MutableRefObject<null | HTMLDivElement>;
  scrollOffsetY: number;
  scrollPageSize: number;
  isScrollBottom?: boolean;
  hideAtBottom?: boolean;
  verticalOffset?: number;
}

const ScrollDownButton: React.FC<Props> = ({
  scrollableRef,
  scrollOffsetY,
  scrollPageSize,
  isScrollBottom = false,
  hideAtBottom = false,
  verticalOffset = 0,
}) => {
  const scrollToBottom = () => {
    if (scrollableRef?.current === null) {
      return;
    }
    scrollableRef.current.scrollTo({
      left: 0,
      top: scrollOffsetY + scrollPageSize * 0.9, // scroll almost a full page height
      behavior: 'smooth',
    });
  };

  if (hideAtBottom && isScrollBottom) {
    return null;
  }

  return (
    <IconButton
      color="primary"
      onClick={scrollToBottom}
      sx={{
        '&.MuiIconButton-root': {
          // Center an absolute positioned element
          marginX: 'auto',
          left: 0,
          right: 0,
        },
        position: 'absolute',
        bottom: `${rem(1, null) - verticalOffset}px`,
      }}
    >
      <ArrowIcon direction="down" color={colors.white} />
    </IconButton>
  );
};

export type ScrollDownButtonProps = Props;
export default ScrollDownButton;
