import { _t } from '@mindoktor/patient-legacy/i18n';
import React from 'react';
import { connect } from 'react-redux';

import iconDone from '../../../../../images/icon/icon_done@4x.png';
import { postCaseReopened } from '../../../../state/logs/actions';
import { getInvitations } from '../../../../state/profile/actions';
import { formatRoute } from '../../../../state/routing';
import { GuideStartOrigin } from '../../../../state/tracking/constants';
import Button from '../../../components/button/button';
import MessageCard from '../components/message_card';
import { routes } from '@mindoktor/patient-app/routing/constants/webRoutes';

class ResumeCase extends React.Component {
  componentDidMount() {
    const { getInvitations } = this.props;
    getInvitations();
  }

  resumeCase() {
    const {
      resumeCaseInfo: { entrywayId, patientUUID, guideType, revisitId },
      caseId,
      postCaseReopened,
    } = this.props;
    return (
      <Button
        href={`#${formatRoute(routes.GUIDES_QUESTIONS, {
          entrywayId,
          childUuid: guideType === 'children' ? patientUUID : undefined,
          revisitId,
          origin: GuideStartOrigin.CHAT,
        })}`}
        onClick={async () => {
          await postCaseReopened(caseId);
        }}
      >
        {_t('closedCase.resume')}
      </Button>
    );
  }

  render() {
    const { timestamp, style, resumeCaseInfo } = this.props;
    return (
      <MessageCard
        imageUrl={iconDone}
        title={_t('closedCase.title')}
        description={_t('closedCase.body.resume')}
        timestamp={timestamp}
        actionComponent={resumeCaseInfo ? this.resumeCase() : null}
        style={style}
      />
    );
  }
}

export default connect(null, (dispatch) => ({
  postCaseReopened: (caseId) => dispatch(postCaseReopened(caseId)),
  getInvitations: () => dispatch(getInvitations()),
}))(ResumeCase);
