import React from 'react';

import { createIcon } from '@mindoktor/pulse/src/utils/icons/createIcon';
import { PulseIconProps } from '@mindoktor/pulse/src/utils/icons/types';

import { SvgIconComponent as BankID } from './BankID.svg';

export const BANK_ID_ICON_DEFAULT_COLOR = '#1D1D1B';

const BankIDIcon: React.FC<PulseIconProps> = ({
  color = BANK_ID_ICON_DEFAULT_COLOR,
  ...otherProps
}) => {
  const BaseBankIDIcon = createIcon(BankID);
  return <BaseBankIDIcon color={color} {...otherProps} />;
};

export default BankIDIcon;
