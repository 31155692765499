import React, { Component } from 'react';

import { isQuestion } from '../../state/formulary/formulary';
import { Answer } from './answer';

const styles = {
  item: {
    marginBottom: 30,
  },
};

const excludedNodeTypes = ['info'];

const castNodeToQuestion = (node) => {
  return node;
};

// type ItemProps = {
//   node: Question,
//   answer?: any,
//   contextDescription?: any,
//   error?: string,
//   truncate?: boolean,
//   thumbnails?: boolean,

//   edit?: string => any,

//   style?: any,
// };

class AnswerItem extends Component {
  edit = () => {
    const { node, edit } = this.props;

    edit && edit(node.index);
  };

  render() {
    const {
      caseId,
      node,
      answer,
      contextDescription,
      error,
      truncate,
      thumbnails,
      edit,
      style,
    } = this.props;

    return (
      <Answer
        caseId={caseId}
        node={node}
        answer={answer}
        contextDescription={contextDescription}
        error={error}
        truncate={truncate}
        thumbnails={thumbnails}
        solid={true}
        onClick={edit ? this.edit : undefined}
        style={style}
      />
    );
  }
}

// type Props = {
//   formulary: Node,
//   answers: Object,
//   contextDescriptions?: Object,
//   visibility: { [string]: boolean },
//   errors?: { [string]: string },
//   truncate?: boolean,
//   thumbnails?: boolean,

//   edit?: string => any,

//   style?: any,
// };

export default class Answers extends Component {
  renderNode = (node) => {
    const {
      caseId,
      answers,
      contextDescriptions,
      visibility,
      errors,
      truncate,
      thumbnails,
      edit,
    } = this.props;

    if (visibility[node.index]) {
      if (isQuestion(node) && !excludedNodeTypes.includes(node.type)) {
        const question = castNodeToQuestion(node);

        return (
          <AnswerItem
            key={question.index}
            caseId={caseId}
            node={question}
            answer={answers[question.id]}
            contextDescription={
              contextDescriptions && contextDescriptions[question.id]
            }
            error={errors && errors[question.id]}
            truncate={truncate}
            thumbnails={thumbnails}
            edit={edit}
            style={styles.item}
          />
        );
      } else if (node.children) {
        // $FlowFixMe
        return node.children.map(this.renderNode);
      }
    }

    return null;
  };

  render() {
    const { formulary, style } = this.props;

    return <div style={style}>{this.renderNode(formulary)}</div>;
  }
}
