import type { CommonStateType } from '../types';
import getUserNotificationTitle from '../../api/userNotifications/getNotificationTitle';
import getSupportTicketNotifications from '../../api/userNotifications/getSupportTicketNotifications';
import { getCaseUnread } from '../cases/selectors';
import { isHelpCenterEnabled } from '../helpCenter/selectors';
import { CaseUnreadObject } from '../cases/types';
import { UserNotificationsObject, UserNotificationMessage } from './types';
import { UserNotification } from '../../api/userNotifications/types';

export const getUserNotifications = (
  state: CommonStateType
): UserNotification[] => {
  return state.userNotifications.notifications;
};

export const getSupportTicketsNotifications = (
  state: CommonStateType
): UserNotification[] => {
  return getSupportTicketNotifications(state.userNotifications.notifications);
};

export const getUnreadNotifications = (
  state: CommonStateType
): UserNotificationsObject => {
  const helpCenterEnabled = isHelpCenterEnabled(state);
  // ATTOW we cannot dismiss other types of notifications, so we don't display them yet
  const notifications = helpCenterEnabled
    ? getSupportTicketsNotifications(state)
    : [];
  const notificationMessages: UserNotificationMessage[] = notifications.map(
    (n) => ({ ...n, displayTitle: getUserNotificationTitle(n) })
  );

  // We add case messages, until they move to use the same API
  const unreadCaseMessages = getCaseUnread(state) as CaseUnreadObject;

  const unreadUserNotification = {
    messages: [...notificationMessages, ...unreadCaseMessages.messageCases],
  };
  return unreadUserNotification;
};
