const routes = {
  LOGIN_PRIVACY_SETTINGS: '/login/privacy/settings',

  MDKLINIKEN: '/mdkliniken',
  MDKLINIKEN_LOCATIONS: '/mdkliniken/locations',

  CASE_MESSAGES: '/cases/:id/messages',
  CASE_WORKOUT: '/cases/:id/workout/:workoutId',
  CASE_REFERRAL_RESULTS: '/cases/referrals/results',
  CASE_SCHEDULER: '/cases/:id/scheduler',

  IDENTITY_PROTECTION_SUPPORT: '/support/identity_protection',

  INQUISITION: '/inquisitions/:entrywayId',
  INQUISITION_COMPLETE: '/inquisitions/:entrywayId/complete',
  INQUISITION_ABORT: '/inquisitions/:entrywayId/abort',

  CHILDREN: '/children',
  CHILDREN_ADD: '/children/add',
  CHILDREN_UNDER_SIX_MONTHS: '/children/undersixmonths',

  HEALTH_PROFILE: '/healthprofile',
  HEALTH_PROFILE_EDIT: '/healthprofile/edit',

  VACCINATION_BOOK: '/vaccinationbook',

  PAYMENT_FREE_PASS: '/payment/freepass',

  CONTACT_INFO_VERIFICATION_GUIDE_CONFIRMATION: '/verify/:caseId/confirmation',
  CONTACT_INFO_VERIFICATION_GUIDE_VERIFICATION: '/verify/:caseId',
  CONTACT_INFO_VERIFICATION_GUIDE_COMPLETED_ALREADY_VERIFIED:
    '/verify/:caseId/done',
  CONTACT_INFO_VERIFICATION_BY_LINK: '/confirm/:type/link/:token',

  VIDEOCHAT: '/videochat/:caseId',

  MENU: '/menu',

  USER_SURVEY_INTRO: '/user-survey/:surveyName',
  USER_SURVEY: '/user-survey/:surveyName/survey',
  USER_SURVEY_COMPLETE: '/user-survey/:surveyName/complete',
} as const;

export type UrlRoutes = typeof routes;
export type UrlRouteIdentifiers = keyof typeof routes;

export const DEFAULT_LOCATION_AS_HASH_ROUTE = '#/guides/adult' as const;

export default routes;
