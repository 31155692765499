import MuiAvatar from '@material-ui/core/Avatar';
import { _t } from '@mindoktor/patient-legacy/i18n';
import { MdVideocam } from '../../../components/docly-md-icons';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Body1 } from '../../../../common/components/typography';
import constants from '../../../../common/constants';
import { getCaseData } from '../../../../state/cases/selectors';
import { formatRoute, routes } from '../../../../state/routing';
import Button from '../../../components/button/button';
import Message from '../../components/message';

const styles = {
  row: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 16,
  },

  avatar: {
    position: 'relative',
  },

  doctorImage: {
    display: 'block',
    width: constants.thumbDoctor,
    height: constants.thumbDoctor,
    marginRight: constants.spacing16,
  },

  avatarIcon: {
    position: 'absolute',
    top: 24,
    left: 24,
    width: 14,
    height: 14,
    padding: 5,
    color: 'white',
    borderRadius: '50%',
    backgroundColor: '#1D9E6F',
  },

  middle: {
    flex: 1,
  },

  button: {
    marginTop: 10,
  },
};

// type Props = {
//   caseId: number,
//   doctorName: string,
//   doctorSignature: string,
//   doctorImageUrl: string,
//   style?: number | Object,
// };

export class Videochat extends Component {
  render() {
    const { caseId, doctorName, doctorSignature, doctorImageUrl, style } =
      this.props;

    return (
      <Message style={style}>
        <div style={styles.row}>
          <div style={styles.avatar}>
            <MuiAvatar src={doctorImageUrl} style={styles.doctorImage} />

            <MdVideocam style={styles.avatarIcon} />
          </div>

          <Body1 style={styles.middle}>
            {_t('videochat.welcomeText', { doctorName })}
          </Body1>
        </div>

        <Button
          color="green"
          href={`#${formatRoute(routes.VIDEOCHAT, {
            caseId,
            doctorName,
            doctorSignature,
            doctorImageUrl,
          })}`}
          style={styles.button}
        >
          {_t('videochat.start')}
        </Button>
      </Message>
    );
  }
}

export default connect((state, props) => {
  const { caseId } = props;

  const { doctorName, doctorSignature, doctorImageUrl } =
    getCaseData(state, caseId) || {};

  return {
    doctorName,
    doctorSignature,
    doctorImageUrl,
  };
})(Videochat);
