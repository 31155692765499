import React from 'react';

import { CompatImgSrc } from './types';

type ImgProps = React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>;

interface CompatImgSrcProps {
  src: CompatImgSrc;
}

/**
 * Core `img` component providing a thin abstraction to be framework independent.
 *
 * At the moment, handles discrepancies between Next and CRA.
 */
const Img: React.FC<Omit<ImgProps, 'src'> & CompatImgSrcProps> = ({
  src: importedImage,
  ...otherProps
}) => {
  if (importedImage == null) {
    throw new Error('No image imported');
  }

  if (typeof importedImage === 'string') {
    return <img src={importedImage} {...otherProps} />;
  }

  return <img src={importedImage.src} {...otherProps} />;
};

export default Img;
