import React from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import BaseLayout from '@mindoktor/patient-app/layout/components/BaseLayout/web';
import { useTranslation } from '@mindoktor/patient-app/localization/hooks/useTranslation';
import {
  RESPONSIVE_SCREEN_WIDTH_BREAKPOINT,
  useIsSmallScreen,
} from '@mindoktor/patient-app/utils/device/web';

import DownloadAppWithQR from '../../DownloadAppWithQR/web/DownloadAppWithQR';
import InfoLink from '../../InfoLink/web';
import InjectionIcon from '../../InjectionIcon/web/InjectionIcon';
import NavigationHeaderDesktop from '../../NavigationHeaderDesktop/web/NavigationHeaderDesktop';
import PromotionalCard from '../../PromotionalCard/web';
import QRCodeInstructions from '../../QRCodeInstructions/web/QRCodeInstructions';

const MainColumn: React.FC = () => {
  const t = useTranslation('vaccinationBook', 'downloadPage');
  return (
    <Stack spacing="4rem">
      <Stack spacing="1rem">
        <Typography variant="body1">{t('paragraph1')}</Typography>
        <Typography variant="body1">{t('paragraph2')}</Typography>
        <Typography variant="body1">{t('paragraph3')}</Typography>
      </Stack>
      <DownloadAppWithQR />
      <QRCodeInstructions />
    </Stack>
  );
};

const SideColumn: React.FC = () => {
  return (
    <Stack spacing="4rem">
      <PromotionalCard />
      <InfoLink />
    </Stack>
  );
};

const VaccinationBookDesktop: React.FC = () => {
  const t = useTranslation('vaccinationBook', 'downloadPage');
  const isSmallScreen = useIsSmallScreen();
  return (
    <BaseLayout>
      <Stack
        alignItems="center"
        marginTop="1rem"
        marginBottom="4rem"
        marginX="1.5rem"
      >
        <Stack spacing="2rem" maxWidth={RESPONSIVE_SCREEN_WIDTH_BREAKPOINT}>
          <NavigationHeaderDesktop marginBottom="3rem" />
          <InjectionIcon />
          <Typography variant="h2">{t('title')}</Typography>
          <Stack direction={isSmallScreen ? 'column' : 'row'} spacing="4rem">
            <MainColumn />
            <SideColumn />
          </Stack>
        </Stack>
      </Stack>
    </BaseLayout>
  );
};

export default VaccinationBookDesktop;
