import { SET_DEEP_LINK } from './actions';

type AllOrNone<T> = T | { [K in keyof T]?: never };

const initialState = {};
interface Action {
  type: string;
  payload: { link: AllOrNone<{ name: string; params: any }> };
}

const DeepLinkReducer = (state = initialState, action: Action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_DEEP_LINK:
      return {
        ...payload.link,
      };

    default: {
      return state;
    }
  }
};

export default DeepLinkReducer;
