import { useEffect } from 'react';

import { useContext } from '../components/RealtimeMessagingProvider';

export interface UseSubscriptionArgs {
  channel: string;
  onPublish: (message: {
    data: {
      text: string;
    };
  }) => void;
}

/**
 * Hook to be used for subscribing to channels to retrieve real time messages.
 * See realtime-messaging/README.md for more information on how to use this.
 */
export const useSubscription = ({
  channel,
  onPublish,
}: UseSubscriptionArgs) => {
  const { centrifuge, connected } = useContext();

  useEffect(() => {
    if (centrifuge === null || !connected) {
      return undefined;
    }

    let sub = centrifuge.getSubscription(channel);

    if (sub === null) {
      sub = centrifuge.newSubscription(channel);
      sub
        .on('publication', function (ctx) {
          onPublish(ctx);
        })
        .subscribe();
    }

    return () => {
      sub?.unsubscribe();
      sub?.removeAllListeners();
      centrifuge.removeSubscription(sub);
    };
  }, [centrifuge, connected, channel]);
};
