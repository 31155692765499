import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

import { SvgIconComponent as Visibility } from '../Visibility.svg';
import { SvgIconComponent as VisibilityOff } from '../VisibilityOff.svg';

interface Props extends Omit<SvgIconProps, 'fontSize'> {
  iconColor?: string;
  fontSize?: string | SvgIconProps['fontSize'];
  state?: 'on' | 'off';
}

/**
 * VisibilityIconColor toggling icon e.g. for password nput fields or the like.
 */
const VisibilityIconColor: React.FC<Props> = ({
  iconColor = 'black',
  fontSize,
  state = 'on',
  ...props
}) => (
  <SvgIcon
    inheritViewBox
    component={state === 'on' ? Visibility : VisibilityOff}
    fontSize={fontSize as SvgIconProps['fontSize']}
    {...props}
    sx={{
      ...props.sx,
      '&.MuiSvgIcon-root path': {
        fill: iconColor,
      },
    }}
  />
);

export default VisibilityIconColor;
