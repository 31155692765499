import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import { _t } from '@mindoktor/patient-legacy/i18n';
import React from 'react';
import compose from '@mindoktor/patient-legacy/src/common/compose';

import Body from './components/Body';
import Frame from './components/Frame';
import Header from './components/Header';
import Logo from './components/Logo';
import Markdown from './components/Markdown';
import PreviousLink from './components/PreviousLink';
import ScrollIndicator from './components/ScrollIndicator';
import withLoaderScreen from './withLoaderScreen';

import { withRouting } from '@mindoktor/patient-app/routing/HOC/withRouting.web';
import { routes } from '@mindoktor/patient-app/routing/constants/webRoutes';

class AbortScreen extends React.Component {
  state = {};

  cancel = () => {
    const {
      routing: {
        navigation: { navigate },
      },
    } = this.props;

    navigate(routes.GUIDES, { entrywayId: 'adult' });
  };

  componentDidMount() {
    const { result: { answers, abort } = {}, postCase } = this.props;

    if (abort) {
      this.setState({ abort });

      postCase(answers);
    } else {
      this.cancel();
    }
  }

  render() {
    const { classes } = this.props;
    const { abort } = this.state;

    if (!abort) return null;

    const { label, description } = abort;

    return (
      <Body>
        <Header>
          <PreviousLink label={_t('common.back')} onClick={this.cancel} />
        </Header>

        <Frame>
          <Logo className={classes.logo} />

          {label !== undefined && (
            <Typography variant="h2" className={classes.section}>
              {label}
            </Typography>
          )}

          {description !== undefined && (
            <Markdown className={classes.section}>{description}</Markdown>
          )}

          <ScrollIndicator />
        </Frame>
      </Body>
    );
  }
}

const enhance = compose(
  withRouting,
  withLoaderScreen(),
  withStyles(({ spacing }) => ({
    logo: {
      marginBottom: spacing(3),
    },

    section: {
      marginBottom: spacing(),
      '&:last-child': {
        marginBottom: 0,
      },
    },
  }))
);

export default enhance(AbortScreen);
