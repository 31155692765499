import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from '@mindoktor/patient-legacy/src/common/compose';
import { _t } from '@mindoktor/patient-legacy/i18n';

import {
  nextQuestion,
  previousQuestion,
  abortFormulary,
} from '../../../state/formulary/actions';
import { getFormularyState } from '../../../state/formulary/selectors';

import { stepGuide, cancelGuide } from '../../../state/guides/actions';
import { getGuideByEntrywayId } from '../../../state/guides/selectors';
import { getProgress } from '../../../state/guides/progress';

import { openRoute } from '../../../state/routing';

import { showModal, hideModal } from '../../routing';

import { getExternalIdFromHost } from '../../utils/host';

import FadeTransition from '../../components/transition/fade';

import FormularyAnswer from '../../formulary/answer';
import FormularyQuestion from '../../formulary/question';
import FormularyFooter from '../../formulary/footer';

import HealthProfileIntroContent from '../../healthprofile/intro/content';
import HealthProfileIntroFooter from '../../healthprofile/intro/footer';
import HealthProfileSummaryContent from '../../healthprofile/summary/content';
import HealthProfileSummaryFooter from '../../healthprofile/summary/footer';

import ReviewIntroContent from '../reviewintro/content';
import ReviewIntroFooter from '../reviewintro/footer';

import CartContent from '../../payment/cart/content';

import CartFooter from '../../payment/cart/footer';

import GuideSummaryContent from '../summary/content';
import GuideSummaryFooter from '../summary/footer';

import {
  isInformedConsentRequired,
  getInformedConsentsByEntrywayId,
} from '../../../state/consents/selectors';

import InformedConsentContent from '../../../common/components/InformedConsent/content';
import InformedConsentFooter from '../../../common/components/InformedConsent/footer';

import Bar from '../components/bar';
import QuestionView from '../components/question_view';
import PreviousLink from '../components/previous_link';

import guideLoader from '../utils/loader';
import { getGuideImageByEntrywayId } from '../utils/images';

import CancelModal from './cancel_modal';
import { withTrackPageView } from '@mindoktor/patient-app/tracking/HOC/withTrackPageView';
import { getQuestionsTrackingDataFromProps } from '../getTrackingDataFromProps';

import { withRouting } from '@mindoktor/patient-app/routing/HOC/withRouting.web';
import { routes } from '@mindoktor/patient-app/routing/constants/webRoutes';

// import { type PaymentMethodType } from '../../../state/payment/types';
// import type { FreePassEditData } from '../../../state/freepass/types';

// type Props = {
//   entrywayId: number,
//   childUuid?: string,
//   revisitId?: number,
//   dynamicCode?: string,
//   origin: string,

//   step: GuideStep,

//   formularyKey: string,
//   healthProfileKey: string,

//   showHealthProfile?: boolean,

//   shouldAbort?: boolean,
//   abortReason?: string,

//   hasGuideErrors?: boolean,
//   hasHealthProfileErrors?: boolean,

//   previousGuideNode?: Question,
//   currentGuideNode?: Question,
//   nextGuideNode?: Question,

//   previousHealthProfileNode?: Question,
//   currentHealthProfileNode?: Question,
//   nextHealthProfileNode?: Question,

//   informedConsentRequired: boolean,

//   nextQuestion: typeof nextQuestion,
//   previousQuestion: typeof previousQuestion,

//   abortFormulary: typeof abortFormulary,

//   stepGuide: typeof stepGuide,
//   cancelGuide: typeof cancelGuide,

//   openRoute: typeof openRoute,

//   iconSource?: any,
//   title?: string,

//   getProgress: (
//     formularyKey: string
//   ) => {
//     position?: number,
//     total?: number,
//     progress?: number,
//     savedAt?: number,
//   },

// };

// type State = {
//   paymentMethod?: PaymentMethodType,
//   freePassData?: FreePassEditData,
// };

export class QuestionsScreen extends Component {
  static defaultProps = {
    step: 'guide',
  };

  state = {
    paymentMethod: undefined,
    freePassData: undefined,
  };

  changeStep = (step) => {
    const {
      entrywayId,
      childUuid,
      revisitId,
      dynamicCode,
      formularyKey,
      step: previousStep,
      stepGuide,
      origin,
      preferredCaregiverId,
      routing: {
        navigation: { navigate },
      },
    } = this.props;

    stepGuide({ formularyKey, step, previousStep, origin });

    navigate(
      routes.GUIDES_QUESTIONS,
      {
        entrywayId,
      },
      {
        childUuid,
        revisitId,
        dynamicCode,
        step,
        origin,
        preferredCaregiverId,
      }
    );
  };

  abort = async () => {
    const {
      entrywayId,
      formularyKey,
      abortReason,
      abortFormulary,
      routing: {
        navigation: { navigate },
      },
    } = this.props;

    await abortFormulary({ formularyKey, externalId: getExternalIdFromHost() });

    navigate(routes.GUIDES_ABORT, { entrywayId }, { reason: abortReason });
  };

  cancel = () => {
    const { formularyKey, step, cancelGuide, openRoute } = this.props;

    showModal(CancelModal, {
      confirm: () => {
        cancelGuide({ formularyKey, step });

        hideModal();

        openRoute(routes.CASES);
      },
      cancel: hideModal,
    });
  };

  next = () => {
    const {
      formularyKey,
      healthProfileKey,
      step,
      showHealthProfile,
      shouldAbort,
      hasHealthProfileErrors,
      nextGuideNode,
      nextHealthProfileNode,
      nextQuestion,
      informedConsentRequired,
    } = this.props;

    if (shouldAbort) {
      this.abort();

      return;
    }

    switch (step) {
      case 'guide':
        if (nextGuideNode) {
          nextQuestion({ formularyKey });
          break;
        }

        if (showHealthProfile && hasHealthProfileErrors) {
          this.changeStep('healthProfileIntro');
          break;
        }

        this.changeStep('reviewIntro');

        break;

      case 'healthProfileIntro':
        this.changeStep(
          hasHealthProfileErrors ? 'healthProfile' : 'reviewIntro'
        );

        break;

      case 'reviewIntro':
        this.changeStep('guideSummary');

        break;

      case 'healthProfile':
        if (nextHealthProfileNode) {
          nextQuestion({ formularyKey: healthProfileKey });
          break;
        }
        this.changeStep('reviewIntro');

        break;

      case 'guideSummary':
        if (showHealthProfile) {
          this.changeStep('healthProfileSummary');
          break;
        }

        if (informedConsentRequired) {
          this.changeStep('informedConsent');
          break;
        }

        this.changeStep('cart');

        break;

      case 'healthProfileSummary':
        if (informedConsentRequired) {
          this.changeStep('informedConsent');
        } else {
          this.changeStep('cart');
        }

        break;

      case 'informedConsent':
        this.changeStep('cart');

        break;
    }
  };

  previous = () => {
    const {
      formularyKey,
      healthProfileKey,
      step,
      showHealthProfile,
      previousHealthProfileNode,
      previousQuestion,
      informedConsentRequired,
    } = this.props;

    switch (step) {
      case 'guide':
        previousQuestion({ formularyKey });

        break;

      case 'healthProfileIntro':
      case 'reviewIntro':
      case 'guideSummary':
        this.changeStep('guide');

        break;

      case 'healthProfile':
        if (previousHealthProfileNode) {
          previousQuestion({ formularyKey: healthProfileKey });
          break;
        }
        this.changeStep('guide');
        break;

      case 'healthProfileSummary':
        this.changeStep('guideSummary');
        break;

      case 'informedConsent':
        if (showHealthProfile) {
          this.changeStep('healthProfileSummary');
        } else {
          this.changeStep('guideSummary');
        }
        break;

      case 'cart':
        if (informedConsentRequired) {
          this.changeStep('informedConsent');
        } else if (showHealthProfile) {
          this.changeStep('healthProfileSummary');
        } else {
          this.changeStep('guideSummary');
        }
        break;
    }
  };

  getGuideQuestionPaneProps = (currentNode, previousNode) => {
    const { formularyKey } = this.props;

    return currentNode
      ? {
          key: `${formularyKey}.${currentNode.index}`,

          previous: previousNode ? (
            <PreviousLink
              text={_t('guides.previousQuestion')}
              onClick={this.previous}
            />
          ) : undefined,
          content: currentNode ? (
            <FormularyQuestion
              formularyKey={formularyKey}
              node={currentNode}
              next={this.next}
            />
          ) : undefined,
          footer: currentNode ? (
            <FormularyFooter
              formularyKey={formularyKey}
              node={currentNode}
              next={this.next}
            />
          ) : undefined,
        }
      : undefined;
  };

  getGuideSummaryPaneProps = () => {
    const { formularyKey, entrywayId, childUuid } = this.props;

    return {
      key: 'guideSummary',

      previous: (
        <PreviousLink
          text={_t('guides.previousLink')}
          onClick={this.previous}
        />
      ),
      content: (
        <GuideSummaryContent
          formularyKey={formularyKey}
          entrywayId={entrywayId}
          childUuid={childUuid}
        />
      ),
      footer: (
        <GuideSummaryFooter
          formularyKey={formularyKey}
          entrywayId={entrywayId}
          next={this.next}
        />
      ),
    };
  };

  getHealthProfileIntroPaneProps = () => {
    const { childUuid } = this.props;

    return {
      key: 'healthProfileIntro',

      previous: (
        <PreviousLink
          text={_t('guides.previousLink')}
          onClick={this.previous}
        />
      ),
      content: <HealthProfileIntroContent childUuid={childUuid} />,
      footer: <HealthProfileIntroFooter next={this.next} />,
    };
  };

  getReviewIntroPaneProps = () => {
    return {
      key: 'reviewIntro',

      previous: (
        <PreviousLink
          text={_t('guides.previousLink')}
          onClick={this.previous}
        />
      ),
      content: <ReviewIntroContent />,
      footer: <ReviewIntroFooter next={this.next} />,
    };
  };

  getHealthProfileQuestionPaneProps = (currentNode, previousNode) => {
    const { healthProfileKey } = this.props;

    return currentNode
      ? {
          key: `${healthProfileKey}.${currentNode.index}`,

          previous: previousNode ? (
            <FormularyAnswer
              formularyKey={healthProfileKey}
              node={previousNode}
              dimLabel={true}
              onClick={this.previous}
            />
          ) : (
            <PreviousLink
              text={_t('guides.previousLink')}
              onClick={this.previous}
            />
          ),
          content: currentNode ? (
            <FormularyQuestion
              formularyKey={healthProfileKey}
              node={currentNode}
              next={this.next}
            />
          ) : undefined,
          footer: currentNode ? (
            <FormularyFooter
              formularyKey={healthProfileKey}
              node={currentNode}
              next={this.next}
            />
          ) : undefined,
        }
      : undefined;
  };

  getHealthProfileSummaryPaneProps = () => {
    const { childUuid } = this.props;

    return {
      key: 'healthProfileSummary',

      previous: (
        <PreviousLink
          text={_t('guides.guideSummary')}
          onClick={this.previous}
        />
      ),
      content: <HealthProfileSummaryContent childUuid={childUuid} />,
      footer: (
        <HealthProfileSummaryFooter childUuid={childUuid} next={this.next} />
      ),
    };
  };

  getInformedConsentProps = () => {
    const { showHealthProfile, entrywayId } = this.props;

    return {
      key: `informedConsent`,

      previous: (
        <PreviousLink
          text={
            showHealthProfile
              ? _t('guides.healthProfileSummary')
              : _t('guides.guideSummary')
          }
          onClick={this.previous}
        />
      ),
      content: <InformedConsentContent entrywayId={entrywayId} />,
      footer: (
        <InformedConsentFooter entrywayId={entrywayId} next={this.next} />
      ),
    };
  };

  getCartPaneProps = () => {
    const { formularyKey, showHealthProfile, informedConsentRequired } =
      this.props;

    const { paymentMethod, freePassData } = this.state;

    let previousText = _t('guides.guideSummary');

    if (showHealthProfile) {
      previousText = _t('guides.healthProfileSummary');
    }

    if (informedConsentRequired) {
      previousText = _t('consent.informed.label');
    }

    let cartContent = () => (
      <CartContent
        formularyKey={formularyKey}
        onPaymentMethodChange={this.handlePaymentMethodChange}
        onFreePassChange={this.handleFreePassDataChange}
      />
    );

    return {
      key:
        'cart' +
        '-paymentMethod:' +
        JSON.stringify(paymentMethod ?? null) +
        '-freePassData:' +
        JSON.stringify(freePassData ?? null),
      previous: <PreviousLink text={previousText} onClick={this.previous} />,
      content: cartContent(),
      footer: (
        <CartFooter
          formularyKey={formularyKey}
          paymentMethod={paymentMethod}
          freePassData={freePassData}
        />
      ),
    };
  };

  handlePaymentMethodChange = (type) => {
    this.setState({ paymentMethod: type });
  };

  handleFreePassDataChange = (data) => {
    this.setState({ freePassData: data });
  };

  async componentDidMount() {
    const {
      formularyKey,
      step,
      shouldAbort,
      hasGuideErrors,
      stepGuide,
      openRoute,
      informedConsent,
      informedConsentRequired,
      origin,
      trackPageView,
    } = this.props;
    trackPageView(getQuestionsTrackingDataFromProps(this.props));

    stepGuide({ formularyKey, step, previousStep: step, origin });

    if (shouldAbort) {
      await this.abort();
    }

    // If for some reason the user refreshes the page at the gp consultation, redirect
    // them back to the informed consent screen
    if (informedConsentRequired && step === 'cart') {
      const hasNotConsented = !(informedConsent && informedConsent.second);
      if (hasNotConsented) {
        this.changeStep('informedConsent');
      }
    }

    // If the user for some reasons ends up past the guide step with guide errors we need to
    // redirect back to a more suitable place. If it's the cart step the most likely reason is
    // that the user went back from the payment step and therefore it's best to show the case list.
    if (step !== 'guide' && hasGuideErrors) {
      if (step === 'cart') {
        openRoute(routes.CASES, undefined, { replace: true });
      } else {
        this.changeStep('guide');
      }
    }
  }

  render() {
    const {
      formularyKey,

      step,

      previousGuideNode,
      currentGuideNode,

      previousHealthProfileNode,
      currentHealthProfileNode,

      iconSource,
      title,

      getProgress,
    } = this.props;

    let current;

    switch (step) {
      case 'guide':
        current = this.getGuideQuestionPaneProps(
          currentGuideNode,
          previousGuideNode
        );

        break;

      case 'guideSummary':
        current = this.getGuideSummaryPaneProps();

        break;

      case 'healthProfileIntro':
        current = this.getHealthProfileIntroPaneProps();

        break;

      case 'reviewIntro':
        current = this.getReviewIntroPaneProps();

        break;

      case 'healthProfile':
        current = this.getHealthProfileQuestionPaneProps(
          currentHealthProfileNode,
          previousHealthProfileNode
        );

        break;

      case 'healthProfileSummary':
        current = this.getHealthProfileSummaryPaneProps();

        break;

      case 'informedConsent':
        current = this.getInformedConsentProps();

        break;

      case 'cart':
        current = this.getCartPaneProps();

        break;
    }

    if (!current) return null;

    const { key, previous, content, footer } = current;

    const { progress, savedAt } = formularyKey ? getProgress(formularyKey) : {};

    let staticFooter = true;
    if (step === 'cart') {
      staticFooter = false;
    }

    return (
      <FadeTransition
        transitionKey={key}
        afterTransition={window.scroll}
        skipTransition={step === 'informedConsent' || step === 'cart'}
      >
        <QuestionView
          header={
            <Bar
              title={title}
              iconSource={iconSource}
              progress={progress}
              savedAt={savedAt}
              onCancel={this.cancel}
            />
          }
          previous={previous}
          content={content}
          footer={footer}
          staticFooter={staticFooter}
        />
      </FadeTransition>
    );
  }
}

const enhance = compose(
  withTrackPageView,
  withRouting,
  guideLoader,
  connect(
    (state, props) => {
      const {
        routing: {
          params: { entrywayId },
          queryParams,
        },
        formularyKey,
        healthProfileKey,
      } = props;

      const step = queryParams.get('step') ?? 'guide';
      const _entrywayId = parseInt(entrywayId, 10);

      const {
        abort,
        abortReason,

        errors: guideErrors,

        previous: previousGuideNode,
        current: currentGuideNode,
        next: nextGuideNode,
      } = getFormularyState(state, formularyKey) || {};

      const {
        errors: healthProfileErrors,

        previous: previousHealthProfileNode,
        current: currentHealthProfileNode,
        next: nextHealthProfileNode,
      } = getFormularyState(state, healthProfileKey) || {};

      const { displayTitle: title, integratedHealthProfile } =
        getGuideByEntrywayId(state, entrywayId) || {};

      const informedConsent = getInformedConsentsByEntrywayId(
        _entrywayId,
        state
      );
      const informedConsentRequired = isInformedConsentRequired(state);

      return {
        showHealthProfile: !integratedHealthProfile,

        shouldAbort: abort,
        abortReason,

        hasGuideErrors: !!guideErrors && !!Object.keys(guideErrors).length,
        hasHealthProfileErrors:
          !!healthProfileErrors && !!Object.keys(healthProfileErrors).length,

        previousGuideNode,
        currentGuideNode,
        nextGuideNode,

        previousHealthProfileNode,
        currentHealthProfileNode,
        nextHealthProfileNode,

        informedConsent,
        informedConsentRequired,

        iconSource: getGuideImageByEntrywayId(entrywayId),
        title,

        getProgress: (formularyKey) => getProgress(state, formularyKey),
        entrywayId,
        step,
      };
    },
    {
      nextQuestion,
      previousQuestion,
      abortFormulary,
      stepGuide,
      cancelGuide,
      openRoute,
    }
  )
);

export default enhance(QuestionsScreen);
