import { useEffect, useState } from 'react';
import { _t } from '@mindoktor/patient-legacy/i18n';
import {
  sendContactInfoVerificationCode,
  selectContactInfoVerification,
  setContactInfoBeingVerified,
  verifyContactInfo,
  getLatestContactInfoVerification,
} from '../../state/profile/actions';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { AppDispatch } from '../../state/types';
import {
  contactInformation,
  getContactInfoBeingVerified,
  isContactInfoVerified,
} from '../../state/profile/selectors';
import { showSnackbar } from '../../state/snackbar/actions';
import { SnackbarType } from '../../state/snackbar/types';
import {
  ContactInformation,
  InfoBeingVerified,
} from '../../state/profile/types_definitions';

/** Keys defined according to responses from BE: V1ContactInfoVerificationPost */
const VerificationErrorMessage = {
  LNGInvalidData: 'contactinfo.verification.entercode.wrongcode',
  LNGErrInternalSrv: 'contactinfo.verification.entercode.error',
} as const;

export const useEmailVerification = ({
  onComplete,
}: {
  onComplete: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);
  const [code, setCode] = useState('');
  const { contactInfo, verification } = useAppSelector((state) => ({
    contactInfo: contactInformation(state) as ContactInformation,
    verification: getContactInfoBeingVerified(state) as InfoBeingVerified,
  }));
  const dispatch = useAppDispatch();
  const isVerified = useAppSelector((state) =>
    isContactInfoVerified(state, verification?.info)
  );
  const { email } = contactInfo;

  // Handler for completing verification if user becomes verified
  useEffect(() => {
    if (!isVerified) return;

    onComplete();
  }, [isVerified]);

  // Initialize contact info verification if it doesn't exist
  useEffect(() => {
    const updateVerification = async () => {
      let info;
      if (!!email) {
        // If email is already known, use it
        info = email;
        await dispatch(selectContactInfoVerification(email));
      } else {
        // If email is not known, fetch latest verification information
        const data = await (
          dispatch as AppDispatch<
            ReturnType<typeof getLatestContactInfoVerification>
          >
        )(getLatestContactInfoVerification('email'));
        info = 'info' in data ? data.info : null;
      }

      if (!info) {
        dispatch(
          showSnackbar({
            type: SnackbarType.WARNING,
            text: _t(VerificationErrorMessage.LNGErrInternalSrv),
          })
        );
        setIsLoading(false);
        return;
      }

      await dispatch(
        setContactInfoBeingVerified({
          info,
          type: 'email',
        })
      );

      setIsLoading(false);
    };

    if (!verification) {
      updateVerification();
      return;
    }

    setIsLoading(false);
  }, [verification]);

  // When contact info verification exists, send code to user
  useEffect(() => {
    if (verification) {
      const { info } = verification;
      dispatch(sendContactInfoVerificationCode(info, 'email'));
    }
  }, [verification]);

  const handleContinueButton = async () => {
    const { info } = verification;
    const { error, message } = await (
      dispatch as AppDispatch<ReturnType<typeof verifyContactInfo>>
    )(verifyContactInfo(info, code));

    if (error && message !== 'LNGVerifiedSuccessfully') {
      const errMessage = VerificationErrorMessage[message];

      dispatch(
        showSnackbar({
          type: SnackbarType.WARNING,
          text: _t(errMessage || VerificationErrorMessage.LNGErrInternalSrv),
        })
      );
      return;
    }

    await dispatch(selectContactInfoVerification(info));
  };

  const handleSendAgainButton = async () => {
    const { info } = verification;

    try {
      await dispatch(sendContactInfoVerificationCode(info, 'email'));

      dispatch(
        showSnackbar({
          type: SnackbarType.SUCCESS,
          text: _t('contactinfo.verification.entercode.codesent'),
        })
      );
    } catch (_e) {
      dispatch(
        showSnackbar({
          type: SnackbarType.WARNING,
          text: _t(VerificationErrorMessage.LNGErrInternalSrv),
        })
      );
    }
  };

  const handleChangeTextInput = (val: string) => {
    setCode(val.toUpperCase());
    if (val) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };

  return {
    handleSendAgainButton,
    code,
    setCode,
    handleChangeTextInput,
    handleContinueButton,
    isDisabled,
    verification,
    isLoading,
  };
};
