import { _t } from '@mindoktor/patient-legacy/i18n';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Body1, Title2 } from '../../../common/components/typography';
import { sendLabRequestSupport } from '../../../state/referrals/actions';
import Button from '../../components/button/button';
import TextInput from '../../components/input/text';
import Modal from '../../components/modal';
import { hideModal } from '../../routing';

const styles = {
  title: {
    paddingBottom: 16,
  },

  lead: {
    paddingBottom: 16,
  },

  submit: {
    marginBottom: 8,
  },
};

// type Props = {
//   sendLabRequestSupport: typeof sendLabRequestSupport,
// };

export class ReferralSupportModal extends Component {
  state = {
    text: '',
    submitting: false,
    submitted: false,
  };

  onTextChange = (text) => {
    this.setState({ text });
  };

  submit = async () => {
    const { sendLabRequestSupport } = this.props;
    const { text } = this.state;

    if (!text.trim()) return;

    this.setState({ submitting: true });

    const { error } = await sendLabRequestSupport(text);

    this.setState({ submitting: false, submitted: !error });
  };

  render() {
    const { submitting, submitted } = this.state;

    return (
      <Modal
        body={
          <div>
            <Title2 style={styles.title}>
              {submitted
                ? _t('referralSupportConfirmModal.title')
                : _t('referralSupportModal.title')}
            </Title2>

            <Body1 style={styles.lead}>
              {submitted
                ? _t('referralSupportConfirmModal.body')
                : _t('referralSupportModal.body')}
            </Body1>

            {!submitted && (
              <TextInput
                placeholder={_t('referralSupportModal.placeholder')}
                multiline={true}
                autoFocus={true}
                disabled={submitting}
                onChange={this.onTextChange}
              />
            )}
          </div>
        }
        footer={
          <div>
            {!submitted && (
              <Button
                color="black"
                disabled={submitting}
                style={styles.submit}
                onClick={this.submit}
              >
                {_t('referralSupportModal.submit')}
              </Button>
            )}

            <Button type="flat" color="black" hero={false} onClick={hideModal}>
              {_t('common.close')}
            </Button>
          </div>
        }
      />
    );
  }
}

export default connect(undefined, { sendLabRequestSupport })(
  ReferralSupportModal
);
