import React from 'react';

import LegacyScreen from '@mindoktor/patient-app/adapters/components/LegacyScreen/web';
import { isMobile } from '@mindoktor/patient-app/utils/device/web';

import VaccinationBookDesktop from '../../VaccinationBookDesktop/web/VaccinationBookDesktop';
import VaccinationBookMobile from '../../VaccinationBookMobile/web/VaccinationBookMobile';

const VaccinationBookScreen: React.FC = () => {
  return (
    <LegacyScreen>
      {isMobile ? <VaccinationBookMobile /> : <VaccinationBookDesktop />}
    </LegacyScreen>
  );
};

export default VaccinationBookScreen;
