import throttle from 'lodash-es/throttle';
// Create throttled redux-thunk actions.
// Throttled actions can be dispatched at most once per every <wait> milliseconds.
// defaults to leading: true, trailing: false.
// Perfect for making sure API requests are not triggered multiple times …

// Example:
// export const fetchCases = throttleAction(
//   () => async (dispatch, getState) => { ...get data, dispatch action(s)... },
//   5000
// );
export default function throttleAction(action, wait, options) {
  const throttled = throttle(
    (dispatch, actionArgs) => dispatch(action(...actionArgs)),
    wait,
    {
      leading: true,
      trailing: false,
      ...options,
    }
  );

  // see: https://github.com/gaearon/redux-thunk
  const thunk =
    (...actionArgs) =>
    (dispatch) =>
      throttled(dispatch, actionArgs);

  // provide hook to _.throttle().cancel() to cancel any trailing invocations
  thunk.cancel = throttled.cancel;
  thunk.flush = throttled.flush;

  return thunk;
}
