export const HOSTS_CONFIG_LOAD_SUCCESS = 'HOSTS_CONFIG_LOAD_SUCCESS';

// export type ApiState = {
//   +token: string,
//   +session: Object,
//   +loggedIn: boolean,
//   +site: string,
//   +companyId?: number,
//   signupDomainId?: number,
//   host?: HostConfig,
// };

// export type UploadApi = (uri: string, path: string) => AsyncAction;

export type Domain = {
  id: number;
  slug: string;
  title: string;
  openingHours: string;
  streetAddress: string;
  streetAddress2?: string;
  city: string;
  postalCode: string;
  externalReviewUrl?: string;
  requireInformedConsent?: boolean;
  isOpeningHours?: boolean;
  childrenAllowed?: boolean;
};

// export type HostConfig = {
//   defaultSite: string,
//   companyId: number,
//   featureFlags: Flag[],
//   analyticsEnabled: boolean,
//   session?: {
//     externalId?: string,
//     firstName: string,
//     lastName: string,
//     country: string,
//     identified: boolean,
//   },
//   messagingServerHost?: ?string,
//   locale: string,
//   domainsPublicInfo?: Domain[],

//   // The minimum age to use the service.
//   independentCareAge: number,

//   // The legal age of majority.
//   adultAge: number,
// };
