import { REHYDRATE } from 'redux-persist/es/constants';

import { APP_LAUNCHED_FIRST_TIME } from '../app/actions';
import { REFERRAL_MAP_SCREEN_VIEW } from '../referrals/actionTypes';

const initialState = {
  abTests: {},
  hasLaunchedOnce: false,
  hasLoaded: false,
  referralMapScreenViewed: null,
  version: 1,
  foo: 'bar',
};

export default function persistentReducer(
  state = initialState,
  { payload = {}, type }
) {
  switch (type) {
    case APP_LAUNCHED_FIRST_TIME: {
      return {
        ...state,
        hasLaunchedOnce: true,
      };
    }

    case REFERRAL_MAP_SCREEN_VIEW:
      return {
        ...state,
        referralMapScreenViewed: Date.now(),
      };

    case REHYDRATE:
      const { persistent = {} } = payload;

      return {
        ...state,
        ...persistent,
        hasLoaded: true,
      };

    default:
      return state;
  }
}
