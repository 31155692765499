// export type PaymentState = {
//   +items: PaymentType[],
// };

// export type PaymentType = {
//   caseId: number,
//   created: string,
//   currency: string,
//   hasReceipt: boolean,
//   price: number,
//   provider: string,
//   status: string,
// };

export const PaymentStatusType = {
  PENDING: 'pending',
  INIT: 'init',
  COMPLETE: 'complete',
  ERROR: 'error',
  CANCELLED: 'cancelled',
  CREDITED: 'credited',
  REFUSED: 'refused',
  UNKNOWN: 'unknown',
};

// Introducing Payment method types here as we start using invoicing via billogram and
// the only options will be that or using freepass, if anything as free still remains free.
// TODO: Adjust the above types or remove if not needed.
export type PaymentMethodType = 'invoice' | 'freepass' | 'free' | undefined;

export const paymentMethodTypes = {
  INVOICE: 'invoice',
  FREEPASS: 'freepass',
  FREE: 'free',
} as const;

export interface PaymentOverview {
  type: PaymentMethodType;
  payload?: {
    status: 'waitingForInvoice' | 'invoiceSent';
    URL: string;
  };
}
