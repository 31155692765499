import {
  MESSAGING_CONNECT,
  MESSAGING_SUBSCRIBE,
  MESSAGING_UNSUBSCRIBE,
} from './actionTypes';

export const connect = (host) => ({
  type: MESSAGING_CONNECT,
  payload: { host },
});

export const subscribe = (channel, callbacks) => ({
  type: MESSAGING_SUBSCRIBE,
  payload: {
    channel,
    ...callbacks,
  },
});

export const unsubscribe = (channel) => ({
  type: MESSAGING_UNSUBSCRIBE,
  payload: { channel },
});
