import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';

import Config from '@mindoktor/env/Config';

import { resources } from './resources';

i18n
  .use(initReactI18next)
  .init({
    resources,
    compatibilityJSON: 'v3',
    lng: 'sv',
    debug: Config.IsDev,
  })
  .catch((e: Error) => {
    throw new Error('Failed to load translations: ' + e.toString());
  });

export default i18n;
