import React from 'react';
import Link from '../../components/link';

export interface Props {
  disabled?: boolean;
  onPress: () => void;
  children?: React.ReactNode;
}

const styles = {
  disabled: {
    cursor: 'not-allowed',
    opacity: 0.5,
    backgroundColor: 'white',
    display: 'inline-block',
    pointer: 'none',
  },
};
export const LinkButton: React.FC<Props> = ({
  onPress,
  disabled,
  children,
}) => {
  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (disabled) {
      return;
    }
    onPress();
  };

  return (
    <Link style={disabled && styles.disabled} onClick={onClick}>
      {children}
    </Link>
  );
};
