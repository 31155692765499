import React, { useState } from 'react';

import { CircularProgress } from '@mui/material';
import { Stack } from '@mui/system';

interface Props {
  src: string;
  alt: string;
  width?: string;
  height?: string;
  maxWidth?: string;
  maxHeight?: string;
  onError?: () => void;
  onClick?: () => void;
  onLoad?: () => void;
}

const ImageWithLoader: React.FC<Props> = ({
  src,
  alt,
  width,
  height,
  maxWidth,
  maxHeight,
  onError,
  onClick,
  onLoad,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  const handleOnLoad = () => {
    setIsLoading(false);
    onLoad?.();
  };

  return (
    <Stack alignItems="center" justifyContent="center" height="100%">
      <img
        src={src}
        alt={alt}
        onLoad={handleOnLoad}
        onError={onError}
        onClick={onClick}
        style={{
          display: isLoading ? 'none' : 'block',
          cursor: onClick != null ? 'pointer' : 'default',
          maxWidth,
          maxHeight,
        }}
        width={width}
        height={height}
      />
      {isLoading && <CircularProgress color="primary" />}
    </Stack>
  );
};

export default ImageWithLoader;
