import React from 'react';
import { _t } from '@mindoktor/patient-legacy/i18n';
import * as Boxed from '../components/boxedscreen';
import { openRoute, routes as legacyRoutes } from '../../state/routing';
import { routes } from '@mindoktor/patient-app/routing/constants/webRoutes';
import { getRouteParam } from '../../state/routing/utils';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { showModal } from '../routing';
import VerificationRisksModal from './verification_risks_modal';
import { useIsLongWaitingTimeWarning } from '../../state/contactInfoVerification/useIsLongWaitingTimeWarning';
import { useTrackPageView } from '@mindoktor/patient-app/tracking/hooks/useTrackPageView';
import { useNavigation } from '@mindoktor/patient-app/routing/web';

const ConfirmationScreen: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigation = useNavigation();
  const { caseId } = useAppSelector((state) => ({
    caseId: +getRouteParam(state, 'caseId'),
  }));
  useTrackPageView({ caseId });
  const isLongWaitingTimeWarningActive = useIsLongWaitingTimeWarning();

  const navigateToVerification = async () => {
    return dispatch(
      openRoute(legacyRoutes.CONTACT_INFO_VERIFICATION_GUIDE_VERIFICATION, {
        caseId,
      })
    );
  };

  const openInfoModal = () => {
    showModal(VerificationRisksModal);
  };

  const onCancel: () => void = () => {
    return navigation.navigate(routes.CASES);
  };

  return (
    <Boxed.Screen
      footer={
        <>
          <Boxed.FooterButtons>
            <Boxed.Button onPress={navigateToVerification}>
              {_t('guides.contactinfo.verification.confirm.email')}
            </Boxed.Button>
            <Boxed.Button type="flat" onPress={() => openInfoModal()}>
              {_t('guides.contactinfo.verification.risks.button')}
            </Boxed.Button>
          </Boxed.FooterButtons>
        </>
      }
      onCancel={onCancel}
    >
      <Boxed.Body>
        <Boxed.Heart />
        <Boxed.Title>
          {_t('guides.contactinfo.verification.thanks')}
        </Boxed.Title>
        <Boxed.Lead>
          <p>{_t('guides.done.queue')}</p>
          <p>
            {_t('guides.done.staffanswer') +
              ' ' +
              _t('guides.contactinfo.verification.unverified.info')}
          </p>
          {isLongWaitingTimeWarningActive && (
            <strong>{_t('guides.done.longqueues')}</strong>
          )}
        </Boxed.Lead>
      </Boxed.Body>
    </Boxed.Screen>
  );
};

export default ConfirmationScreen;
