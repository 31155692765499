let token = '';

/**
 * Common (NAPP/WAPP) token store.
 *
 * Each app initializes this to achieve a sort of dependency injection.
 */
let persistentTokenStore = {
  getToken: async (): Promise<string> => {
    throw new Error('Not implemented!');
  },
  setToken: async (token: string): Promise<string> => {
    throw new Error('Not implemented!');
  },
  removeToken: async (): Promise<void> => {
    throw new Error('Not implemented!');
  },
};

export const setPersistentTokenStore = (
  tokenStore: typeof persistentTokenStore
) => {
  persistentTokenStore = tokenStore;
};

export const getToken: typeof persistentTokenStore.getToken = async () => {
  return token !== '' ? token : await persistentTokenStore.getToken();
};

export const setToken: typeof persistentTokenStore.setToken = async (
  newToken
) => {
  if (newToken !== undefined && newToken !== '') {
    token = newToken;
    await persistentTokenStore.setToken(newToken);
    return newToken;
  }
  return '';
};

export const removeToken: typeof persistentTokenStore.removeToken =
  async () => {
    token = '';
    await persistentTokenStore.removeToken();
  };
