import { _t } from '@mindoktor/patient-legacy/i18n';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from '@mindoktor/patient-legacy/src/common/compose';

import { Title1 } from '../../../common/components/typography';
import { getFormularyState } from '../../../state/formulary/selectors';
// import type { Formulary } from '../../../state/formulary/types';
import { openRoute } from '../../../state/routing';
import FormularyAnswers from '../../formulary/answers';
import { withRouting } from '@mindoktor/patient-app/routing/HOC/withRouting.web';
import { routes } from '@mindoktor/patient-app/routing/constants/webRoutes';

const styles = {
  title: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
  },
};

// type Props = {
//   entrywayId: number,
//   formularyKey?: string,
//   showTitle?: boolean,
//   formulary?: Formulary,
//   answers?: Object,
//   visibility?: { [string]: boolean },
//   errors?: { [string]: string },

//   openRoute: typeof openRoute,
// };

export class SummaryContent extends Component {
  static defaultProps = {
    showTitle: true,
  };

  edit = (index) => {
    const {
      formularyKey,
      entrywayId,
      routing: {
        navigation: { navigate },
      },
    } = this.props;

    navigate(
      routes.GUIDES_QUESTIONS_EDIT,
      {
        entrywayId,
      },
      {
        formularyKey,
        index,
      }
    );
  };

  render() {
    const { showTitle, formulary, answers, visibility, errors } = this.props;

    return (
      <div>
        {showTitle && (
          <Title1 style={styles.title}>{_t('guides.guideSummary')}</Title1>
        )}

        {!!formulary && !!answers && !!visibility && !!errors && (
          <FormularyAnswers
            formulary={formulary}
            answers={answers}
            visibility={visibility}
            errors={errors}
            edit={this.edit}
          />
        )}
      </div>
    );
  }
}

const enhance = compose(
  withRouting,
  connect(
    (state, props) => {
      const {
        formularyKey,
        routing: {
          params: { entrywayId },
        },
      } = props;

      const { formulary, errors, visibility, answers } =
        getFormularyState(state, formularyKey) || {};

      return {
        entrywayId,
        formulary,
        errors,
        visibility,
        answers,
      };
    },
    { openRoute }
  )
);

export default enhance(SummaryContent);
