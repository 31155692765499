import { _t } from '@mindoktor/patient-legacy/i18n';
import React from 'react';

import apoteketSource from '../../../../../images/apoteket/ic_apoteket_mark@4x.png';
import iconSource from '../../../../../images/icon/icon_medication@3x.png';
import { Body1 } from '../../../../common/components/typography';
import { APOTEKET_ERECEPT_LINK } from '../../../../state/apoteket/constants';
import { PrescriptionType } from '../../../../state/cases/constants';
import Button from '../../../components/button/button';
import Message from '../../components/message';
import Header from '../components/header';
import Img from '@mindoktor/pulse/src/utils/images/Img.web';

const styles = {
  message: {
    backgroundColor: '#E1E1E1',
  },

  lead: {
    paddingBottom: 16,
  },

  drugs: {
    marginBottom: -8,
    marginLeft: -8,
    marginRight: -8,
  },

  drug: {
    paddingBottom: 0,
  },

  drugHeader: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 16,
  },

  drugDosage: {
    paddingBottom: 16,
  },

  drugTitle: {
    flex: 1,
    fontWeight: 500,
  },

  drugLead: {
    paddingBottom: 16,
  },

  drugLabel: {
    color: '979ea2',
  },

  drugValue: {
    marginLeft: '1ex',
    fontWeight: 500,
  },

  apoteketIcon: {
    display: 'block',
    width: 26 * (88 / 89),
    height: 26,
    marginLeft: 16,
  },

  buttonWrapper: {
    padding: 16,
    backgroundColor: 'white',
  },
};

const findCommaIndex = (product) => product.search(/[^0-9],[^0-9]/);
const getName = (product) => product.substr(0, findCommaIndex(product) + 1);
const getStrength = (product) =>
  product.substr(findCommaIndex(product) + 2).trim();

const ERecept = ({
  product,
  packageSize,
  dosage,
  limit,
  interval,
  intervalUnit,
}) => (
  <Message style={styles.drug}>
    <div style={styles.drugHeader}>
      <Body1 style={styles.drugTitle}>{getName(product) || product}</Body1>

      <div>
        <Img src={apoteketSource} style={styles.apoteketIcon} />
      </div>
    </div>

    <div style={styles.drugLead}>
      {!!getName(product) && <Body1>{getStrength(product)}</Body1>}
      <Body1>{packageSize}</Body1>
    </div>

    <Body1>
      <span style={styles.drugLabel}>{_t('prescriptionItem.dosage')}</span>
      <span style={styles.drugValue}>{dosage}</span>
    </Body1>

    <Body1>
      <span style={styles.drugLabel}>{_t('prescriptionItem.limit')}</span>
      <span style={styles.drugValue}>{limit}</span>
    </Body1>

    {!!interval && !!intervalUnit && (
      <Body1>
        <span style={styles.drugLabel}>
          {_t('prescriptionItem.intervalUnit')}
        </span>
        <span style={styles.drugValue}>{`${interval} ${intervalUnit}`}</span>
      </Body1>
    )}
  </Message>
);

const FreeText = ({ product, dosage }) => (
  <Message style={styles.drug}>
    <div style={styles.drugHeader}>
      <Body1 style={styles.drugTitle}>{getName(product) || product}</Body1>
    </div>

    <div style={styles.drugDosage}>
      <Body1>{dosage}</Body1>
    </div>
  </Message>
);

const Drug = ({
  product,
  packageSize,
  dosage,
  limit,
  interval,
  intervalUnit,
  type,
}) => {
  switch (type) {
    case PrescriptionType.ERECEPT:
      return (
        <ERecept
          product={product}
          packageSize={packageSize}
          dosage={dosage}
          limit={limit}
          interval={interval}
          intervalUnit={intervalUnit}
        />
      );

    case PrescriptionType.FREETEXT:
      return <FreeText product={product} dosage={dosage} />;

    case PrescriptionType.UNKNOWN:
      return null;
  }

  return null;
};

const Prescription = ({ type, drugs, timestamp, style }) => (
  <Message style={{ ...styles.message, ...style }}>
    <Header
      title={_t('prescriptionItem.title')}
      icon={iconSource}
      timestamp={timestamp}
    />

    <Body1 style={styles.lead}>{_t('prescriptionItem.description')}</Body1>

    <div style={styles.drugs}>
      {drugs.map(
        (
          { product, packageSize, dosage, limit, interval, intervalUnit },
          i
        ) => (
          <Drug
            key={'drug-' + i}
            product={product}
            packageSize={packageSize}
            dosage={dosage}
            limit={limit}
            interval={interval}
            intervalUnit={intervalUnit}
            type={type}
          />
        )
      )}

      {type === PrescriptionType.ERECEPT && (
        <div style={styles.buttonWrapper}>
          <Button color="black" href={APOTEKET_ERECEPT_LINK}>
            {_t('prescriptionItem.order')}
          </Button>
        </div>
      )}
    </div>
  </Message>
);

export default Prescription;
