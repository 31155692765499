import { MIN_DOKTOR_COMPANY_ID } from '../api/constants';

export const getIdByMessageType = (state, { message, type }) => {
  const items = state.notifications.items;
  const match = items.find(
    (item) => item.message === message && item.type === type
  );
  if (!match) {
    return undefined;
  }
  if (match.id === undefined) {
    console.warn('Id is missing on item ', match);
    return undefined;
  }
  return match.id;
};

export const getCoachNotifications = (state) => state.notifications.coachItems;

export const isMinDoktorCustomer = (state, caseId) => {
  return (
    state.cases.casesById[caseId] &&
    state.cases.casesById[caseId].companyID === MIN_DOKTOR_COMPANY_ID
  );
};
