export const getWorkoutDetailsByID = (state, workoutID) => {
  return state.workouts.workoutsById[workoutID];
};

export const isWorkoutDetailsLoading = (state) => {
  return !!state.workouts.isLoading;
};

export const hasWorkoutDetailsLoaded = (state) => {
  return !!state.workouts.hasLoaded;
};
