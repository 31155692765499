import { getApi, postApi } from '../api/actions';
import { preprocess } from '../formulary/formulary';
import { flattenAnswers, mapAnswers } from '../formulary/utils';
import { getCurrentLocale } from '../intl/selectors';

export const getHealthProfile = (childUuid) => async (dispatch) => {
  try {
    const api =
      '/api/v1/user/healthprofileformulary' +
      (childUuid ? '/' + childUuid : '');
    const { json, error } = await dispatch(getApi(api));

    if (error) return { error };

    const { questions, answers } = json;

    return {
      formulary: preprocess(questions),
      answers: flattenAnswers(questions, answers),
    };
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const postHealthProfile =
  (formularyId, formularyVersion, answers, childUuid) =>
  async (dispatch, getState) => {
    const locale = getCurrentLocale(getState());

    const apiPath = childUuid
      ? `/api/v1/user/healthprofile/${childUuid}`
      : '/api/v1/user/healthprofile';

    return dispatch(
      postApi(apiPath, {
        id: formularyId,
        version: formularyVersion,
        language: locale,
        answers: mapAnswers(answers),
      })
    );
  };
