import { actionTypes } from './actions';
import type { Action } from './actions';
import type { Reducer } from 'redux';
import { UserNotification } from '../../api/userNotifications/types';

const initialState = {
  notifications: [] as UserNotification[],
};

const reducer: Reducer<typeof initialState, Action> = (state, action) => {
  switch (action.type) {
    case actionTypes.ACTION_SET_USER_NOTIFICATIONS:
      const typedAction = action as Action<UserNotification[]>;
      return {
        ...state,
        notifications: typedAction.payload,
      };
    default:
      return state || initialState;
  }
};

export default reducer;
