import cloneDeep from 'lodash-es/cloneDeep';
import each from 'lodash-es/each';
import filter from 'lodash-es/filter';
import find from 'lodash-es/find';
import isEqual from 'lodash-es/isEqual';
import isString from 'lodash-es/isString';

import Evaluators from './evaluators';
import { findByIndex, nextQuestion, previousQuestion } from './navigation';
import Transforms from './transforms';
import { lastVisibleQuestion } from './utils';
import Validators from './validators';

export const toQuestion = (node) => {
  if (!node) return null;

  const { id, version } = node;

  if (!id || !version) throw new Error('Node is not a valid Question');

  return {
    ...node,
    id,
    version,
  };
};

export const isFormulary = (node) => !!node && node.type === 'formulary';
export const isSection = (node) => !!node && node.type === 'section';
export const isVariable = (node) => !!node && node.type === 'variable';
export const isPredefined = (node) => !!node && node.type === 'predefined';
export const isQuestion = (node) =>
  !!node &&
  !!node.type &&
  !isFormulary(node) &&
  !isSection(node) &&
  !isVariable(node);

const resolveLimit = 100;

export function populateAndResolve(node, answers) {
  populate(node, answers);
  return resolve(node);
}

export function getNode(node, index) {
  let match;

  if (node.index === index) return node;

  if (node.children) {
    for (let i = 0; i < node.children.length; i++) {
      match = getNode(node.children[i], index);
      if (match) return match;
    }
  }

  return undefined;
}

export function getAnswers(node, includeHidden, out = {}) {
  const optionsShown = {};

  if (node.show || includeHidden || isPredefined(node)) {
    if (isQuestion(node) && node.value !== undefined) {
      each(node.options, (o) => {
        optionsShown[o.value] = !!o.show;
      });

      if (node.type === 'choice') {
        if (node.value !== undefined && optionsShown[node.value]) {
          out[node.id] = node.value;
        }
      } else if (node.type === 'multipleChoice') {
        out[node.id] = filter(node.value, (val) => optionsShown[val]);
      } else {
        out[node.id] = node.value;
      }
    }

    each(node.children, (c) => getAnswers(c, includeHidden, out));
  }

  return out;
}

function getOptionVisibility(option, out = {}) {
  out[option.index] = !!option.show;

  return out;
}

export function getVisibility(node, out = {}) {
  out[node.index] = !!node.show;

  Array.isArray(node.children) &&
    node.children.forEach((c) => getVisibility(c, out));

  Array.isArray(node.options) &&
    node.options.forEach((o) => getOptionVisibility(o, out));

  return out;
}

export function getVariables(node) {
  const out = {};

  each(node.variables, (v) => {
    if (v.id !== undefined && v.value !== undefined) out[v.id] = v.value;
  });

  return out;
}

export function getErrors(node, out = {}) {
  if (node.show) {
    if (isQuestion(node) && node.error) {
      out[node.id] = node.error;
    }

    Array.isArray(node.children) &&
      node.children.forEach((c) => getErrors(c, out));
  }

  return out;
}

export function hasAbort(node) {
  if (!node) return false;

  if (node.abort && node.abort.show) {
    return true;
  }

  return !!find(node.variables, function (v) {
    return v.type === 'abort' && !!v.value;
  });
}

export function getAbortReason(node) {
  if (!node) return undefined;

  let variableWithAbort;

  if (node.abort && node.abort.show) {
    return undefined;
  }

  variableWithAbort = find(node.variables, function (v) {
    return v.type === 'abort' && !!v.value;
  });

  if (variableWithAbort) {
    return (
      variableWithAbort.id &&
      variableWithAbort.id.substr(variableWithAbort.id.indexOf('.') + 1)
    );
  }

  return undefined;
}

export function getContextDescriptions(node, out = {}) {
  if (node.show || isPredefined(node)) {
    if (isQuestion(node) && node.contextDescriptionValue) {
      out[node.id] = node.contextDescriptionValue;
    }
    each(node.children, (c) => getContextDescriptions(c, out));
  }
  return out;
}

export function isInvalid(node) {
  return !!(node.show && (node.error || find(node.children, isInvalid)));
}

function populate(node, answers) {
  if (isQuestion(node)) {
    const answer = answers[node.id || ''];
    node.value = Transforms(node)(answer);
  }

  Array.isArray(node.children) &&
    node.children.forEach((c) => populate(c, answers));

  return node;
}

function resolve(node) {
  let i = 0;

  while (
    i++ < resolveLimit &&
    resolveNode(
      node,
      Evaluators({
        answers: getAnswers(node),
        variables: getVariables(node),
      })
    )
  );

  if (i === resolveLimit) {
    console.error('Maximum resolve calls exceeded!');
  }

  return node;
}

function resolveNode(node, evaluator, parent) {
  let changed = false;
  const show = !!node.show;
  let optionsShowCount = 0;

  each(node.options, (o) => {
    const oldShow = o.show;
    o.show =
      o.condition === undefined ||
      o.condition === '' ||
      !!evaluator(o.condition);
    if (o.show) {
      optionsShowCount++;
    }
    changed = changed || oldShow != o.show;
  });

  node.show =
    !isPredefined(node) &&
    (!parent || parent.show) &&
    (!node.condition || !!evaluator(node.condition)) &&
    (!node.options || optionsShowCount > 0);

  if (node.abort) {
    const abortShow = !!evaluator(node.abort.condition);
    if (node.abort) node.abort.show = abortShow;
  }

  each(node.children, (c) => {
    changed = resolveNode(c, evaluator, node) || changed;
  });

  each(node.variables, (v) => {
    const prev = v.value;
    v.value = Transforms(v)(evaluator(v.expression));
    v.show = v.value !== undefined;
    changed = changed || !isEqual(prev, v.value);
  });

  if (node.contextDescription) {
    const prev = node.contextDescriptionValue;

    node.contextDescriptionValue = Transforms({ ...node, type: 'text' })(
      evaluator(node.contextDescription)
    );
    changed = changed || !isEqual(prev, node.contextDescriptionValue);
  }

  node.show = (function fn(n) {
    return !!(
      n &&
      n.show &&
      (isQuestion(n) ||
        find(n.children, (c) => {
          return fn(c);
        }))
    );
  })(node);

  if (isQuestion(node) && node.validations) {
    node.error = node.show ? Validators(node, evaluator)(node.value) : false;
  }

  return changed || show !== node.show;
}

export function preprocess(node) {
  if (node) {
    ['label', 'description', 'unit', 'link', 'content'].forEach((key) => {
      if (isString(node[key])) {
        node[key] = node[key].trim().replace(/\s+/g, ' ');
      }
    });

    [node.modal, node.min, node.max].forEach(preprocess);

    if (node.children) each(node.children, preprocess);

    if (Array.isArray(node.options)) {
      each(node.options, preprocess);
      node.options.forEach((o, i) => (o.index = `${node.index}.${i}`));
    }
  }

  return node;
}

const findQuestion = (...args) => toQuestion(findByIndex(...args)) || undefined;

export const withAnswers = (
  formulary,
  answers = {},
  initialVisibility = {}
) => {
  const root = cloneDeep(formulary);

  populateAndResolve(root, answers);

  const visibility = getVisibility(root);
  const abort = hasAbort(root);

  return {
    root,
    answers: getAnswers(root, true),
    visibility,
    initialVisibility: Object.keys(initialVisibility).length
      ? initialVisibility
      : visibility,
    errors: getErrors(root),
    abort,
    abortReason: abort ? getAbortReason(root) : undefined,
    contextDescriptions: getContextDescriptions(root),
  };
};

export function withAnswersAndPosition(
  formulary,
  node,
  answers = {},
  initialVisibility = {},
  options = {}
) {
  const formularyWithAnswers = withAnswers(
    formulary,
    answers,
    initialVisibility
  );

  const { root, initialVisibility: shownNodes } = formularyWithAnswers;

  let current = node;

  const nextOptions = options.skipAnswered
    ? {
        skipAnswered: options.skipAnswered,
        skipOptionalQuestions: options.skipOptionalQuestions,
      }
    : undefined;

  if (!current) {
    current = nextQuestion(root, undefined, shownNodes, nextOptions);
  }

  if (current && !isQuestion(current)) {
    current = nextQuestion(root, current.index, shownNodes, nextOptions);
  }

  if (current) {
    if (options.previous) {
      const candidate = previousQuestion(root, current.index);
      if (candidate) current = candidate;
    }

    if (options.next) {
      const candidate = nextQuestion(
        root,
        current.index,
        shownNodes,
        nextOptions
      );
      if (candidate) current = candidate;
    }
  }

  const previous = current && previousQuestion(root, current.index);

  const next =
    current && nextQuestion(root, current.index, shownNodes, nextOptions);

  return {
    ...formularyWithAnswers,
    skipOptionalQuestions: !!options.skipOptionalQuestions,
    // $FlowFixMe current isn't optional. Find out why this code below pretends it is.
    current: current ? findQuestion(formulary, current.index) : undefined,
    previous: previous ? findQuestion(formulary, previous.index) : undefined,
    next: next ? findQuestion(formulary, next.index) : undefined,
  };
}

export const hasKey = (key, o) => Object.keys(o).includes(key);

/**
 * Calculate if all the answers have been answered.
 */
export const isCompleted = (data, submittedAnswers) => {
  const question = toQuestion(
    lastVisibleQuestion(
      { submittedAnswers, visibility: data.visibility },
      data.root
    )
  );

  if (!question) return true;

  return question.value !== undefined && hasKey(question.id, submittedAnswers);
};
