import { z } from 'zod';

export const LegacyApiErrorSchema = z.object({
  msg: z.string(),
});

export const ApiErrorSchema = z.object({
  error: z.object({
    code: z.string(),
    message: z.string(),
  }),
});
