import React from 'react';

import Stack from '@mui/material/Stack';

import { colors } from '@mindoktor/pulse/src/styles/colors';

const BaseLayout: React.FC<{
  children: React.ReactNode;
  backgroundColor?: string;
  useFillHeight?: boolean;
}> = ({ children, backgroundColor = colors.white, useFillHeight = false }) => {
  const heightProp = useFillHeight ? { flexGrow: '1' } : { height: '100%' };
  return (
    <Stack
      {...heightProp}
      width="100%"
      sx={{
        backgroundColor,
      }}
    >
      {children}
    </Stack>
  );
};

export default BaseLayout;
