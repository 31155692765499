import React from 'react';

import Question from '../../../questionnaire/question';

const styles = {
  // hackish: the questionnaire item is not build to
  // receive width and padding from container
  rootOverride: {
    width: '100%',
  },
};

export default class Questionnaire extends React.PureComponent {
  static defaultProps = {
    questions: [],
  };

  scrollToDiv;

  componentDidUpdate({ questions: oldQuestions }) {
    if (!this.props.questions || !oldQuestions) return;
    if (
      this.props.questions.length !== oldQuestions.length &&
      this.scrollToDiv
    ) {
      this.scrollToDiv.scrollIntoView();
    }
  }

  render() {
    const { questions, formularyKey, caseId, surveyName, style } = this.props;

    return (
      <div key={formularyKey} style={{ ...style, ...styles.rootOverride }}>
        {questions &&
          questions.map((question) => {
            return (
              <Question
                key={formularyKey + question.id}
                formularyKey={formularyKey}
                node={question}
                answer={question.value}
                error={question.error}
                options={question.options}
                min={question.min}
                max={question.max}
                caseId={caseId}
                surveyName={surveyName}
                submitted={question.submitted}
              />
            );
          })}
        <div
          style={
            // Hackish: this element compensates for the footer
            { position: 'relative', top: '100px' }
          }
          ref={(ref) => {
            this.scrollToDiv = ref;
          }}
        />
      </div>
    );
  }
}
