import dayjs from 'dayjs';
import { normalize } from 'normalizr';

import {
  getBrowser,
  getPlatform,
  iosVersion,
  isBrowserSupported,
} from '../../web/utils/device';
// import { type Case, type Message, type DenormalizedCase } from './types';
import { getApi, postApi } from '../api/actions';
import { getCompanyId, getSite } from '../api/selectors';
import { postFile, postImageFile } from '../files/actions';
import { flattenAnswers, toFormularyKey } from '../formulary/utils';
import Values from '../formulary/values';
import { getCurrentLocale } from '../intl/selectors';
import { BROWSER_VIDEO_WARNING } from '../notifications/types';
import schemas from '../schemas';
// import type {
//   UploadFile,
//   RemoveTemporaryFile,
//   TranscodeVideo,
// } from '../files/types';
import { getSiteOptions } from '../site';
import throttleAction from '../utils/throttleAction';
import { hasActiveVideochatSession } from '../videochat/selectors';
// import type { Dispatch, AsyncAction } from '../types';
import {
  CASE_ANSWERS_REQUEST,
  CASE_ANSWERS_REQUEST_ERROR,
  CASE_ANSWERS_REQUEST_SUCCESS,
  CASE_CANCEL_REQUEST,
  CASE_CANCEL_REQUEST_ERROR,
  CASE_CANCEL_REQUEST_SUCCESS,
  CASE_CREATED,
  CASE_GUIDE_SUMMARY_SHOWN,
  CASE_PAYMENT_DONE,
  CASE_VIDEOCALL_ACCEPT,
  CASE_VIDEOCALL_RESCHEDULE,
  CASE_VIDEOCALL_URL_RECEIVED,
  CASE_VIEW,
  CASES_RECEIVED,
  CASES_REQUEST,
  CASES_REQUEST_ERROR,
  GUIDE_SUMMARY_VIEW_ONLY_ITEM_CLICKED,
  MESSAGE_INCOMING,
  MESSAGE_POST,
  MESSAGE_POST_ERROR,
  MESSAGE_POST_SUCCESS,
  MESSAGES_RECEIVED,
  MESSAGES_REQUEST,
  MESSAGES_REQUEST_ERROR,
  UNREAD_MESSAGES,
} from './actionTypes';
import {
  defaultTimeFormat,
  flattenCaseAnswers,
  flattenGuideData,
  makeFakeId,
  prepareCaseMessages,
} from './caseMessageUtils';
import { decorateCase } from './caseUtils';
import {
  CaseStatuses,
  MessageSendStatus, // FileUploadStatus,
  MessageType,
} from './constants';
import {
  getCase,
  getCaseMessageInvitationsData,
  getCases,
  getMessageById,
  getVideoPendingMessage,
} from './selectors';

import { fetchSupportTickets } from '../helpCenter/actions';
import { fetchUserNotifications } from '../userNotifications/actions';
import { getInvitations } from '../profile/actions';
import { loadDrafts } from '../drafts/actions';

/** @typedef {import('../types').CommonStateType} CommonStateType */
/**
 * @template T
 * @typedef {import('../types').AppDispatch<T>} AppDispatch<T>
 */

// import type { CaseAnswers } from './types';

// This is the format of the data returned from the Normalize case schema
// type NormalizedCases = {
//   entities: {
//     cases: Case[],
//     messages: Message[],
//   },
//   result: number[],
// };

// This is the format of the data we get from api when requesting cases
// type CaseFromApi = {
//   companyID: number,
//   companyName: string,
//   created: string,
//   displayTitle: string,
//   entrywayId: number,
//   id: number,
//   isCompanySameAsProfileCompany: boolean,
//   isPatientSameAsUser: boolean,
//   patientName: string,
//   paymentStatus: number, // what's this enum?
//   status: number, // what's this enum?
//   submitted: {
//     time: string,
//     valid: boolean,
//   },
//   updated: string,
//   doctorID: number,
//   doctorName: string,
//   doctorImageUrl: string,
//   doctorSignature: string,
//   paymentMethod: string,
// };

/**
 * @typedef {Object} CaseViewedActionData
 * @prop {number} caseId
 * @prop {number=} entrywayId
 * @prop {string=} fromComponent
 */

/**
 * @param {CaseViewedActionData} caseViewActionData
 */
export const viewCase =
  (caseViewActionData) =>
  async (
    /** @type {AppDispatch<unknown>} */ dispatch,
    /** @type {() => CommonStateType} */ getState
  ) => {
    const state = getState();

    /** @typedef {AppDispatch<(url: string) => Promise<{error: unknown}>>} PostCasesApiAction */

    const { error } = await /** @type {PostCasesApiAction} */ (dispatch)(
      postApi(`/api/v1/cases/${caseViewActionData.caseId}/view`)
    );
    if (error) {
      return { error };
    }

    dispatch({
      type: CASE_VIEW,
      payload: caseViewActionData,
    });

    const cases = getCases(state);
    getUnreadMessagesCase(dispatch, cases);

    return { error };
  };

/**
 * @param {number} caseId
 * @param {string} originalPath
 * @param {*} origin
 * @param {*} uploadFile
 * @param {{transcodeVideo?: any, removeTemporaryFile?: any}=} options
 * @returns
 */
export const postVideoMessage =
  (
    caseId,
    originalPath,
    origin,
    uploadFile,
    { transcodeVideo, removeTemporaryFile, type } = {}
  ) =>
  async (dispatch) => {
    const ts = dayjs();
    const timestampUnix = ts.format('X');

    // https://github.com/mindoktor/mindoktor/issues/1898 (multiple selected videos get the same timestamp)
    const fakeMsgId = Values.hash(`${originalPath}${timestampUnix}`);

    const message = {
      caseId,
      type: MessageType.VIDEO,
      timestampUnix,
      timestamp: defaultTimeFormat(ts),
      cId: makeFakeId(caseId, MessageType.VIDEO, timestampUnix, fakeMsgId),
      attachmentId: 0,
      attachmentUri: originalPath,
      fromPatient: true,
      origin,
      uploading: true,
    };

    dispatch({
      type: MESSAGE_POST,
      payload: message,
    });

    let path = originalPath;

    if (transcodeVideo) {
      const { path: transcodedPath, error } = await transcodeVideo(path);

      if (!error && transcodedPath) {
        removeTemporaryFile && removeTemporaryFile(path);

        path = message.attachmentUri = transcodedPath;
      }
    }

    dispatch({
      type: MESSAGE_POST,
      payload: message,
    });

    const { json, error } = await dispatch(
      postFile(
        `/api/v1/cases/${caseId}/videomessage?form=true`,
        path,
        uploadFile,
        {
          origin,
          location: 'messages',
          contentType: 'video',
          type,
        }
      )
    );

    if (error) {
      dispatch({
        type: MESSAGE_POST_ERROR,
        payload: { caseId, path },
        error,
        uploading: false,
      });

      return { error };
    }

    const { fileId, referenceNumber } = json;

    dispatch({
      type: MESSAGE_POST_SUCCESS,
      payload: {
        cId: message.cId,
        caseId,
        attachmentId: fileId,
        attachmentFileReferenceNumber: referenceNumber,
        uploading: false,
      },
    });

    return {
      fileId,
      error,
    };
  };

/**
 * @param {number} caseId
 * @param {string} path
 * @param {*} origin
 * @param {*} uploadFile
 * @param {{removeTemporaryFile?: any}=} options
 * @returns
 */
export const postPhotoMessage =
  (caseId, path, origin, uploadFile, { removeTemporaryFile, type } = {}) =>
  async (dispatch) => {
    const ts = dayjs();
    const timestampUnix = ts.format('X');

    // https://github.com/mindoktor/mindoktor/issues/1898 (multiple selected images get the same timestamp)
    const fakeMsgId = Values.hash(`${path}${timestampUnix}`);
    const cId = makeFakeId(caseId, MessageType.IMAGE, timestampUnix, fakeMsgId);

    const msg = {
      caseId,
      type: MessageType.IMAGE,
      fromPatient: true,
      doctorName: null,
      doctorSignature: null,
      timestamp: defaultTimeFormat(ts),
      timestampUnix,
      cId,
      progress: 0,
      imageSource: { uri: `file://${path}` },
      imagePath: path,
      origin,
    };

    dispatch({
      type: MESSAGE_POST,
      payload: msg,
    });

    const { fileId, error: fileError } = await dispatch(
      postImageFile(path, uploadFile, {
        origin,
        location: 'messages',
        type,
      })
    );

    if (fileError) {
      dispatch({
        type: MESSAGE_POST_ERROR,
        payload: { cId },
        error: fileError,
      });

      return { error: fileError };
    }

    const { error, json } = await dispatch(
      postApi(`/api/v1/cases/${caseId}/files`, [fileId])
    );

    if (error) {
      dispatch({
        type: MESSAGE_POST_ERROR,
        payload: { cId },
        error,
      });

      return { error };
    }

    const caseFile = (Array.isArray(json) ? json : [])[0];

    dispatch({
      type: MESSAGE_POST_SUCCESS,
      payload: {
        cId,
        attachmentId: fileId,
        attachmentFileReferenceNumber: caseFile?.referenceNumber,
      },
    });

    removeTemporaryFile && removeTemporaryFile(path);

    return {
      fileId,
      error,
    };
  };

/**
 * @param {number} caseId
 * @param {number} entrywayId
 * @param {*} data
 * @returns
 */
export const postTextMessage =
  (caseId, entrywayId, data) => async (dispatch) => {
    const ts = dayjs();
    const msgDefaults = {
      caseId,
      type: MessageType.TEXT,
      text: '',
      fromDoctor: false,
      doctorName: null,
      doctorSignature: null,
      timestamp: defaultTimeFormat(ts),
      timestampUnix: ts.unix(),
      cId: '',
    };
    const msg = { ...msgDefaults, ...data };
    const cId = makeFakeId(caseId, msg.type, msg.timestampUnix);
    msg.cId = cId;

    dispatch({
      type: MESSAGE_POST,
      payload: { entrywayId, ...msg },
    });

    const { json, error, message } = await dispatch(
      postApi(`/api/v1/cases/${caseId}/messages`, {
        body: msg.text,
      })
    );

    if (error) {
      dispatch({
        type: MESSAGE_POST_ERROR,
        payload: { cId, message },
        error: true,
      });
      return;
    }

    dispatch({
      type: MESSAGE_POST_SUCCESS,
      payload: { ...json, cId },
    });
  };

/**
 * @param {number} caseId
 * @param {number} entrywayId
 * @param {*} cId
 * @returns
 */
export const resendMessage =
  (caseId, entrywayId, cId) => async (dispatch, getState) => {
    const state = getState();
    const msg = getMessageById(state, cId);
    if (
      !msg ||
      [MessageSendStatus.SENDING, MessageSendStatus.DONE].includes(
        msg.sendStatus
      )
    ) {
      console.warn('already resending or sent successfully', msg);
      return false;
    }

    return dispatch(postTextMessage(caseId, entrywayId, msg));
  };

export function newMessage(data) {
  const caseId = data.CaseID;

  return {
    type: MESSAGE_INCOMING,
    payload: {
      caseId,
      data,
    },
  };
}

export const fetchAndDecorateCase =
  (caseData) => async (dispatch, getState) => {
    const state = getState();
    const caseId = caseData.id;

    if (!caseId) {
      throw new Error('caseId is not defined');
    }

    const invitationDataContainer = {
      caseInvitationsData: getCaseMessageInvitationsData(state, caseId),
    };
    const { json, error } = await dispatch(
      getApi(`/api/v1/cases/${caseId}/timeline`)
    );

    if (error) {
      throw new Error(error);
    }

    const caseFromTimeline = {
      canPost: json.textMessages.canPost,
      case: {
        attachments: json.overview.case.attachments,
        created: json.overview.case.created,
        entrywayId: json.overview.overview.entrywayId,
        prescriptions: json.overview.case.prescriptions,
        status: json.overview.case.status,
      },
      showServiceSurvey: json.textMessages.showServiceSurvey,
      certificates: json.certificates.certificates,
      doctorId: json.overview.case.doctorID,
      doctorName: json.overview.overview.doctorName,
      doctorSignature: json.overview.overview.doctorSignature,
      guide: json.overview.guide,
      healthProfile: json.overview.healthProfile,
      messages: json.textMessages.messages,
      inquisitionAnswers: json.overview.inquisitionAnswers,
      referrals: json.referrals.referrals,
      sharedResults: json.sharedResults,
      videoCalls: json.videoCalls.videocalls,
      workouts: json.workouts.workouts,
      ...invitationDataContainer,
      videoPendingMessage: getVideoPendingMessage(state, caseId),
      hasActiveVideochat:
        !!getVideoPendingMessage(state, caseId) ||
        hasActiveVideochatSession(state, caseId),
      survey:
        json.survey && json.survey.formulary
          ? surveyData(caseId, json.survey)
          : null,
      schedulingAllowed: json.schedulingAllowed,
      appointments: json.appointments,
      patientIsListable: json.overview.overview.patientIsListable,
      paymentOverview: json.paymentOverview,
    };
    const preparedCaseMessages = prepareCaseMessages(
      getSite(state),
      caseData.id,
      caseFromTimeline,
      state?.featureFlags
    );

    return {
      ...decorateCase(caseData, state),
      ...flattenGuideData(preparedCaseMessages),
      attachments: caseFromTimeline.case.attachments,
    };
  };

export const fetchCaseAnswers = (caseId) => async (dispatch) => {
  dispatch({ type: CASE_ANSWERS_REQUEST });

  const { json, error } = await dispatch(
    getApi(`/api/v1/cases/${caseId}/answers`)
  );

  if (error) {
    dispatch({ type: CASE_ANSWERS_REQUEST_ERROR, error: error });
    throw new Error(error);
  }

  const caseAnswers = {
    ...flattenCaseAnswers(json),
    title: json.guideDisplayTitle,
  };

  dispatch({
    type: CASE_ANSWERS_REQUEST_SUCCESS,
    payload: {
      caseAnswers: caseAnswers,
    },
  });

  return caseAnswers;
};

export const surveyData = (caseId, survey) => ({
  answers: flattenAnswers(survey.formulary, survey.answers || {}),
  formulary: survey.formulary,
  caseId,
  surveyName: survey.formulary.id,
  formularyKey: toFormularyKey({
    caseId,
    surveyName: survey.formulary.id,
    formularyId: survey.formulary.id,
    formularyVersion: survey.formulary.version,
  }),
});

/**
 * @param {number} id
 * @returns jsonData
 */
export const fetchCaseOverviewById = (id) => async (dispatch) => {
  const { json, error } = await dispatch(
    getApi(`/api/v1/cases/${id}/overview`)
  );
  if (error) throw error;
  return json;
};

export const filterNewMessages = (messages, state) => {
  return messages
    .filter((msg) => {
      // Don't report patients' own messages as unread, only new incoming ones.
      return (
        !getMessageById(state, msg.cId) &&
        msg.fromDoctor !== false &&
        msg.type !== MessageType.IMAGE &&
        msg.type !== MessageType.VIDEO
      );
    })
    .map((msg) => msg.cId);
};

const openCaseStatuses = [
  CaseStatuses.Pending,
  CaseStatuses.InProgress,
  CaseStatuses.AutomationEvaluating,
];
const closedCaseStatuses = [CaseStatuses.Finished, CaseStatuses.ClosedByDoctor];

export const decorateAndMergeCases = (
  cases = [],
  dispatch,
  state
) /* Array<Promise<DenormalizedCase>> */ =>
  cases.map(async (c) => {
    const caseFromState = getCase(state, c.id);

    const caseHasBeenUpdated =
      (caseFromState && caseFromState.updated !== c.updated) || // seems like updated doesn't update even though payment status updates
      (caseFromState && caseFromState.paymentStatus !== c.paymentStatus); // so let's watch paymentStatus as well until above is fixed in BE

    const caseIsOpenAndWasNotInStateBefore =
      !caseFromState && openCaseStatuses.includes(c.status);

    // Fetch case overview only for closed cases
    // that we don't have in state yet.
    const caseIsClosedAndNotInState =
      !caseFromState && closedCaseStatuses.includes(c.status);

    // fetch messages for new, open cases and cases that are updated
    if (caseIsOpenAndWasNotInStateBefore || caseHasBeenUpdated) {
      const decoratedCaseData /* DenormalizedCase */ = await dispatch(
        fetchAndDecorateCase(c)
      );

      // HACK: timeline endpoint doesn't return paymentMethod attribute anymore.
      // That's why we have to set the paymentMethod from .../user/cases endpoint
      // to be able preserve payment method we got.
      return { ...c, ...decoratedCaseData, paymentMethod: c.paymentMethod };
    } else if (caseIsClosedAndNotInState) {
      return decorateCase(c, state);
    }

    return { ...caseFromState, ...c };
  });

export const filterCases = (state, cases) => {
  const siteOptions = getSiteOptions(getSite(state));

  // cases that are not drafts, and has a whitelisted companyId
  return cases.filter((data) => {
    // Omit drafts by duck typing them, they don't have an id
    if (!data.id) {
      return false;
    }

    if (
      getCompanyId(state) === data.companyID ||
      (siteOptions.whitelistedCaseCompanyIds &&
        siteOptions.whitelistedCaseCompanyIds.includes(data.companyID))
    ) {
      return true;
    }

    return false;
  });
};

const getUnreadMessagesCase = (dispatch, cases) => {
  const open = cases
    .filter(
      ({ status }) =>
        status === CaseStatuses.InProgress ||
        status === CaseStatuses.Pending ||
        status === CaseStatuses.AutomationEvaluating
    )
    .map(({ id }) => id);
  let messageCount = 0;
  let messageCases = [];

  if (cases.length) {
    messageCount = cases
      .filter((item) => open.includes(item.id))
      .reduce(
        (sum = 0, value) => sum + value.unreadMessageCountFromCaregiver,
        0
      );

    messageCases = cases
      .filter(
        (item) =>
          open.includes(item.id) && item.unreadMessageCountFromCaregiver > 0
      )
      .map((i) => i);
  }
  dispatch({
    type: UNREAD_MESSAGES,
    payload: { messageCount, messageCases },
  });
};

export const fetchCases = throttleAction(
  () => async (dispatch, getState) => {
    const state = getState();
    const locale = getCurrentLocale(state);

    dispatch({ type: CASES_REQUEST, payload: locale });
    try {
      const { json, error } = await dispatch(
        getApi(`/api/v1/user/cases?locale=${locale}`)
      );

      if (error) throw error;

      getUnreadMessagesCase(dispatch, json);
      const decoratedCases = decorateAndMergeCases(
        filterCases(state, json),
        dispatch,
        state
      );

      const cases = await Promise.all(decoratedCases);
      const normalized = normalize(cases, schemas.cases);
      return dispatch({
        type: CASES_RECEIVED,
        payload: {
          casesById: normalized.entities.cases || {},
          messagesById: normalized.entities.messages || {},
          caseIds: normalized.result || [],
          receivedAt: Date.now(),
        },
      });
    } catch (err) {
      console.error(err);

      return dispatch({
        type: CASES_REQUEST_ERROR,
        error: err,
      });
    }
  },
  5000
);

// fetches the user's cases overview list
// NOTE: This func is very similar to fetchCases func. Difference is that fetchCases func
// makes extra checks and requests with decorateAndMergeCases func.
export const fetchCasesOverviewList = throttleAction(
  () => async (dispatch, getState) => {
    const state = getState();
    const locale = getCurrentLocale(state);

    dispatch({ type: CASES_REQUEST, payload: locale });
    try {
      const { json, error } = await dispatch(
        getApi(`/api/v1/user/cases?locale=${locale}`)
      );

      if (error) throw error;

      getUnreadMessagesCase(dispatch, json);

      const normalized = normalize(filterCases(state, json), schemas.cases);
      return dispatch({
        type: CASES_RECEIVED,
        payload: {
          casesById: normalized.entities.cases || {},
          messagesById: normalized.entities.messages || {},
          caseIds: normalized.result || [],
          receivedAt: Date.now(),
        },
      });
    } catch (err) {
      console.warn(err);

      return dispatch({
        type: CASES_REQUEST_ERROR,
        error: err,
      });
    }
  },
  5000
);

export const fetchCaseMessages = (caseData) => async (dispatch) => {
  const caseId = caseData.id;

  dispatch({
    type: MESSAGES_REQUEST,
    payload: {
      caseId,
    },
  });

  try {
    const { messages, ...newCaseData } = await dispatch(
      fetchAndDecorateCase(caseData)
    );
    const normalized = normalize(messages, schemas.messages);
    return dispatch({
      type: MESSAGES_RECEIVED,
      payload: {
        caseId,
        ...newCaseData,
        receivedAt: Date.now(),
        messageIds: normalized.result,
        messagesById: normalized.entities.messages,
      },
    });
  } catch (e) {
    console.warn(e);

    dispatch({
      type: MESSAGES_REQUEST_ERROR,
      error: e,
    });
  }
  return null;
};

/**
 * Fetches up-to-date data for the cases list.
 */
export const fetchCasesListData = () => async (dispatch, _getState) => {
  dispatch(fetchCasesOverviewList());
  dispatch(fetchSupportTickets());
  dispatch(fetchUserNotifications());
  dispatch(loadDrafts());
  dispatch(getInvitations());
};

// type HasReachedCaseLimit = (
//   entrywayId: string,
//   patientUuid?: string
// ) => (Dispatch) => Promise<boolean>;

export const hasReachedCaseLimit =
  (entrywayId, patientUuid) => async (dispatch) => {
    let url = `/api/v1/user/hasreachedcaselimit/${entrywayId}`;
    if (patientUuid) url += '?uuid=' + patientUuid;

    const { json, error } = await dispatch(getApi(url));

    if (error) return { error };

    return {
      blocked: !!json,
    };
  };
export const paymentDone = (caseId, paymentMethod) => async (dispatch) => {
  let paymentInfo = {};

  if (caseId) {
    try {
      const { json = {}, error } = await dispatch(
        postApi(`/api/v1/cases/${caseId}/processpayment`)
      );
      if (!error) {
        const { patientCaseCount, marketingRevenue, paymentRequired } = json;
        paymentInfo = {
          marketingRevenue,
          paymentRequired,
          patientCaseCount,
        };
      }
    } catch (err) {
      console.error('paymentDone', err);
    }
  }

  return dispatch({
    type: CASE_PAYMENT_DONE,
    payload: {
      caseId,
      paymentMethod,
      ...paymentInfo,
    },
  });
};

export const caseCreated = (
  caseId,
  { entrywayId, paymentMethod, paymentRequired } // { entrywayId?: number, paymentMethod?: string, paymentRequired?: boolean }
) => ({
  type: CASE_CREATED,
  payload: {
    caseId,
    entrywayId,
    paymentMethod,
    paymentRequired,
  },
});

export const getVideoCallUrl = (id) => async (dispatch) => {
  const { json: url, error } = await dispatch(
    getApi(`/api/v1/videocallurl/${id}`)
  );

  if (error) return { error };

  dispatch({
    type: CASE_VIDEOCALL_URL_RECEIVED,
    payload: { url },
  });

  return { url };
};

export const acceptVideoCall = (caseId, id, cId) => async (dispatch) => {
  try {
    const { error } = await dispatch(
      postApi(`/api/v1/cases/${caseId}/videocalls`, {
        answer: 'yes',
        id,
      })
    );
    if (error) {
      throw new Error(error);
    }

    dispatch({
      type: CASE_VIDEOCALL_ACCEPT,
      payload: { caseId, id, cId },
    });
  } catch (err) {
    console.error('error in acceptVideoCall:', err);
  }
};

export const rescheduleVideoCall =
  (caseId, id, message, cId) => async (dispatch) => {
    try {
      const { json, error } = await dispatch(
        postApi(`/api/v1/cases/${caseId}/videocalls`, {
          id,
          answer: 'no',
          message,
        })
      );
      if (error) {
        throw new Error(error);
      }
      dispatch({
        type: CASE_VIDEOCALL_RESCHEDULE,
        payload: { caseId, id, message, cId, response: json },
      });
    } catch (err) {
      console.error('error in rescheduleVideoCall:', err);
    }
  };

export const showVideoWarning = (caseId) => async (dispatch) => {
  dispatch({
    type: BROWSER_VIDEO_WARNING,
    payload: {
      platform: getPlatform(),
      iosVersion: iosVersion,
      browser: getBrowser(),
      isBrowserSupported: isBrowserSupported(),
      caseId: caseId,
    },
  });
};

export function viewGuideAnswers() {
  return {
    type: CASE_GUIDE_SUMMARY_SHOWN,
  };
}

// Triggered when a user tries to edit (by clicking) an item
// in the formulary answers. The section can be
export function clickedViewOnlyItem(section /* 'healthprofile' | 'guide' */) {
  return {
    type: GUIDE_SUMMARY_VIEW_ONLY_ITEM_CLICKED,
    payload: section,
  };
}

/**
 *
 * @param {number} caseId
 * @param {*} reason
 * @returns {*} error
 */
export const cancelCase = (caseId, reason) => async (dispatch) => {
  dispatch({
    type: CASE_CANCEL_REQUEST,
    payload: { caseId, reason },
  });

  const { error } = await dispatch(
    postApi(`/api/v1/cases/${caseId}/cancel`, { reason })
  );

  if (error) {
    dispatch({
      type: CASE_CANCEL_REQUEST_ERROR,
      payload: { error },
    });
  } else {
    dispatch({
      type: CASE_CANCEL_REQUEST_SUCCESS,
      payload: { caseId },
    });
  }

  return error;
};
