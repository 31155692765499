import React, { useEffect, useRef, useState } from 'react';

import { Box, Stack } from '@mui/material';

import { useSafeAreaInsets } from '../hooks/useSafeAreaInsets';

type BGColor = React.CSSProperties['backgroundColor'];

const topInsetColor: BGColor = '#00151e';
const bottomInsetColor: BGColor = '#ffffff';

const heightPercentageThresholdUnder = 0.3; // %
const heightPercentageThresholdOver = 0.25; // %

/**
 * Wraps the web app to fix insets and use capacitor abilities.
 */
const CapacitorWrapper: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [currentLocation, setCurrentLocation] = useState<string | null>(null);
  const locationChangeEventTimeout = useRef<ReturnType<
    typeof setTimeout
  > | null>(null);
  const { insets: originalInsets } = useSafeAreaInsets();
  const insets = {
    ...originalInsets,
    // Using insets.bottom / 2 to avoid cutting fixed bottom controls
    bottom: originalInsets.bottom / 2,
  };

  useEffect(() => {
    adaptFixedElements(insets);
  });

  // TODO: move to own hook
  useEffect(() => {
    const updateLocation = () => {
      locationChangeEventTimeout.current = setTimeout(() => {
        const location = window.location.href;
        if (currentLocation !== location) {
          setCurrentLocation(location);
        }
        updateLocation();
      }, 500);
    };
    updateLocation();
    return () => {
      if (locationChangeEventTimeout.current != null) {
        clearTimeout(locationChangeEventTimeout.current);
      }
    };
  }, []);

  return (
    <>
      {/* Cover the insets top */}
      <Box
        position="fixed"
        // -1 to avoid being picked by moveFixedElements
        top="-1px"
        minHeight={`${insets.top + 1}px`}
        width="100%"
        zIndex={999}
        sx={{ backgroundColor: topInsetColor }}
      />
      {/* Push static elements by the insets top amount */}
      <Stack id="contentBox" width="100%" flexGrow={1}>
        <Box
          width="100%"
          minHeight={`${insets.top}px`}
          sx={{ backgroundColor: topInsetColor }}
        />
        {/* The actual web app */}
        {children}
      </Stack>
      {/* Push static elements by the insets bottom amount */}
      <Box
        width="100%"
        minHeight={`${insets.bottom}px`}
        sx={{ backgroundColor: bottomInsetColor }}
      />
      {/* Cover the insets bottom */}
      <Box
        position="fixed"
        // -1 to avoid being picked by moveFixedElements
        bottom="-1px"
        minHeight={`${insets.bottom + 1}px`}
        width="100%"
        zIndex={999}
        sx={{ backgroundColor: bottomInsetColor }}
      />
    </>
  );
};

const adaptFixedElements = (insets: { top: number; bottom: number }) => {
  const documentElements = document.body.getElementsByTagName('*');
  const len = documentElements.length;

  for (let i = 0; i < len; i++) {
    const element = documentElements[i] as Element & {
      style: React.CSSProperties;
    };
    const elementStyle = window.getComputedStyle(element, null);
    if (elementStyle.getPropertyValue('position') === 'fixed') {
      // Move down all position top elements
      if (elementStyle.getPropertyValue('top') === '0px') {
        if (element?.style?.top != null) {
          element.style.top = `${insets.top}px`;
        }
      }

      // Move up all position bottom elements
      if (elementStyle.getPropertyValue('bottom') === '0px') {
        if (element?.style?.bottom != null) {
          element.style.bottom = `${insets.bottom}px`;
        }
      }
    }

    // If an element has a minHeight of more than the given threshold of the window height
    // we assume the intention was to fill the page, so we resize the element
    // reducing it by the insets bottom amount (the top is pushed down already).
    const checkAndUpdateHeight = (heightProperty: string) => {
      const heightStyle = elementStyle.getPropertyValue(heightProperty);
      if (heightStyle.endsWith('px') && heightStyle !== '0px') {
        const height = parseInt(heightStyle, 10);
        if (
          height > window.innerHeight * (1 - heightPercentageThresholdUnder) &&
          height < window.innerHeight * (1 + heightPercentageThresholdOver)
        ) {
          (element.style as Record<string, string>)[heightProperty] = `${
            height - insets.bottom - insets.top
          }px`;
        }
      }
    };
    checkAndUpdateHeight('min-height');
    checkAndUpdateHeight('height');
  }
};

export default CapacitorWrapper;
