import React from 'react';

import Footer from '@mindoktor/patient-legacy/src/web/components/footer/full';
import MediaQuery from '@mindoktor/patient-legacy/src/web/components/media_query';
import Screen from '@mindoktor/patient-legacy/src/web/components/screen';
import MenuBar from '@mindoktor/patient-legacy/src/web/menu/bar';

import { RESPONSIVE_SCREEN_WIDTH_BREAKPOINT } from '@mindoktor/patient-app/utils/device/web';

interface Props {
  children: React.ReactNode;
  showFooter?: boolean;
  showMenuBarActions?: boolean;
  stickyFooter?: boolean;
}

const MediaQueryFooter: React.FC = () => (
  <MediaQuery minWidth={RESPONSIVE_SCREEN_WIDTH_BREAKPOINT + 1}>
    <Footer />
  </MediaQuery>
);

const LegacyScreen: React.FC<Props> = ({
  children,
  showFooter = true,
  showMenuBarActions = true,
  stickyFooter = false,
}) => {
  return (
    <Screen
      header={<MenuBar showActions={showMenuBarActions} />}
      body={
        <>
          {children}
          {showFooter && !stickyFooter && <MediaQueryFooter />}
        </>
      }
      footer={showFooter && stickyFooter && <MediaQueryFooter />}
      showScrollGradient={true}
    />
  );
};

export default LegacyScreen;
