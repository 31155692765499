import { createSelector } from 'reselect';
import { set } from 'timm';

import { getFormularyDatas } from '../formulary/selectors';
import { questionnaireQuestions } from '../formulary/utils';
import { CommonStateType } from '../types';
import { QuestionnaireData } from '../formulary/types';

export const getQuestionnaireKey = (
  state: CommonStateType,
  caseId: number,
  surveyName: string
) => state.survey2.ongoing[String(caseId)]?.[surveyName];

export const getQuestionnaireData = (
  state: CommonStateType,
  caseId: number,
  surveyName: string
) =>
  state.formulary.byKey[
    getQuestionnaireKey(state, caseId, surveyName) ?? ''
  ] as QuestionnaireData | undefined;

export const getIsSurveyOngoing = (
  state: CommonStateType,
  caseId: number,
  surveyName: string
) => {
  const qData = getQuestionnaireData(state, caseId, surveyName);
  return !!qData && !qData.isFinished;
};

const getOngoingSurveyKeys = (state: CommonStateType) => {
  const ongoing = state.survey2.ongoing;
  return Object.values(ongoing)
    .map(Object.values)
    .reduce((x, y) => x.concat(y), []);
};

export const getHasOngoingSurvey = (state: CommonStateType, caseId: number) => {
  const surveys = state.survey2.ongoing[String(caseId)];
  return !!surveys && !!Object.keys(surveys).length;
};

const getOngoingQuestionsAndAnswersByKey = createSelector(
  [getOngoingSurveyKeys, getFormularyDatas],
  (keys, datas) =>
    (keys || []).reduce(
      (acc, key) => set(acc, key, questionnaireQuestions(datas[key])),
      {}
    )
);

export const getQuestionsAndAnswers = (
  state: CommonStateType,
  caseId: number,
  surveyName: string
) =>
  getOngoingQuestionsAndAnswersByKey(state)[
    getQuestionnaireKey(state, caseId, surveyName) || ''
  ];
