import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import React from 'react';

export default withStyles(({ spacing, breakpoints: { down, up } }) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: `calc(100% - ${spacing(6)}px)`,
    maxWidth: 800,
    margin: '0 auto',
    padding: spacing(3),
    [down('xs')]: {
      justifyContent: 'space-between',
    },
    [up('sm')]: {
      paddingBottom: spacing(6),
    },
  },
}))(({ children, classes, className }) => (
  <div className={classNames(classes.root, className)}>{children}</div>
));
