import dayjs from 'dayjs';
import { _t } from '@mindoktor/patient-legacy/i18n';

export const onlyChild = (children: any) =>
  Array.isArray(children) ? children[0] : children;

export const getDisplayTime = (timestamp: number) => {
  if (dayjs().diff(timestamp, 'day') === 0) {
    return dayjs(timestamp).format('HH:mm');
  }

  if (dayjs().diff(timestamp, 'year') === 0) {
    return dayjs(timestamp).format('HH:mm D/M');
  }

  return dayjs(timestamp).format('D/M YYYY');
};

export const tryAsync = (promise: any) => {
  return promise
    .then((data: any) => [data, null])
    .catch((err: any) => [null, err]);
};

export const getPaymentMethodString = (paymentMethod: string) => {
  switch (paymentMethod) {
    case 'invoice':
    case 'freeOther':
      return _t('payment.paymentSelection.invoice');
    case 'freePass':
      return _t('payment.paymentSelection.freepass');
    case 'eFrikort':
      return _t('payment.paymentSelection.efrikort');
    default:
      return '';
  }
};
