import React, { Component } from 'react';
import { connect } from 'react-redux';

import { dismissNotification } from '../../state/notifications/actions';
import Intl from '../intl/provider';
import Item from './item';

const styles = {
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
  },
};
class Notifications extends Component {
  remove = (id) => {
    const { dismissNotification } = this.props;
    dismissNotification(id);
  };

  render() {
    const { items = [] } = this.props;

    return (
      <Intl>
        <div style={styles.root}>
          {items.map((item) => (
            <Item
              id={item.id}
              message={item.message}
              type={item.type}
              pings={item.pings}
              timeout={item.timeout}
              shouldTimeout={item.shouldTimeout}
              onRemove={this.remove}
              onClose={item.onClose}
              key={item.id}
            />
          ))}
        </div>
      </Intl>
    );
  }
}

export default connect(
  (state) => ({
    items: state.notifications.items,
  }),
  {
    dismissNotification,
  }
)(Notifications);
