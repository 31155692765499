import {
  NavigateOptions,
  useNavigate as useNavigateDOM,
} from 'react-router-dom';

import { Route } from '../../../web';

/**
 * Custom hook providing similar APIs to the React Navigation's
 * `useNavigation` we use on native.
 *
 * Note: this is unrelated to React Router's `useNavigation` on web.
 */
export const useNavigation = () => {
  const navigateDOM = useNavigateDOM();

  const navigate = <UrlParamsT = unknown, QueryParamsT = unknown>(
    route: Route,
    urlParams?: UrlParamsT,
    queryParams?: QueryParamsT,
    options?: NavigateOptions
  ): void => {
    if (urlParams == null) {
      return navigateDOM(route, options);
    }

    let replacedRoute: string = route;
    for (const urlParam of Object.keys(urlParams)) {
      const value = (urlParams as any)[urlParam] as string | number;

      if (value == null) {
        continue;
      }

      if (!route.includes(`:${urlParam}`)) {
        throw new Error(
          `Provided url '${route as string}' has no param called '${urlParam}'.`
        );
      }

      replacedRoute = replacedRoute.replace(`:${urlParam}`, `${value}`);
    }

    // For some of the legacy routes we need to pass query params,
    // like when starting a revisit from a thank-you card.
    if (queryParams != null) {
      replacedRoute +=
        '?' +
        Object.entries<string | number>(queryParams)
          .filter(([_key, value]) => value != null)
          .map(([key, value]) => `${key}=${value}`)
          .join('&');
    }

    return navigateDOM(replacedRoute, options);
  };
  const goBack = (): void => navigateDOM(-1);

  return {
    navigate,
    goBack,
  };
};
