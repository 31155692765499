import { HEALTH_PROFILE_SET_FORMULARY } from './actions';

const initialState = {
  formularyId: null,
  formularyVersion: null,
  children: null,
};

export default function healthProfileReducer(
  state = initialState,
  { payload = {}, type }
) {
  switch (type) {
    case HEALTH_PROFILE_SET_FORMULARY: {
      const { childUuid, formularyId, formularyVersion } = payload;

      if (!childUuid) {
        return {
          ...state,
          formularyId,
          formularyVersion,
        };
      }

      const nextChildren = { ...state.children };

      nextChildren[childUuid] = {
        formularyId,
        formularyVersion,
      };

      return {
        ...state,
        children: nextChildren,
      };
    }

    default:
      return state;
  }
}
