import React from 'react';
import Slider from 'react-slick';

import PictureInstructionCard from './PictureInstructionCard';

export default class extends React.Component {
  render() {
    const { cards } = this.props;
    return (
      <Slider dots={true} infinite={false} arrows={false}>
        {cards.map((card, index) => (
          <PictureInstructionCard {...card} key={index} />
        ))}
      </Slider>
    );
  }
}
