import map from 'lodash-es/map';

const cachedFiles = {};

let i = 0;

export const chooseFiles = (accept, multiple) =>
  new Promise((resolve) => {
    // Some cleanup so we don't end up with multiple inputs (since it's outside react shadow dom)
    const existingElement = document.querySelector(
      '#fileUploadDynamicInputElement'
    );
    if (existingElement) {
      window.document.body.removeChild(existingElement);
    }

    const inputEl = document.createElement('input');

    // Hacky but for some reason 'video/*' doesn't catch mp4 in Safari.
    const processedAccept = accept
      ? accept.replace('video/*', 'video/*,video/mp4')
      : 'image/*';

    inputEl.type = 'file';
    inputEl.accept = processedAccept;
    inputEl.multiple = !!multiple;
    inputEl.style.display = 'none';
    inputEl.id = 'fileUploadDynamicInputElement';

    inputEl.onchange = ({ target: { files } }) =>
      resolve(
        map(files, (file) => {
          const path = 'file-' + ++i + '.' + file.name.split('.').pop();

          cachedFiles[path] = file;

          return path;
        })
      );

    window.document.body.appendChild(inputEl);
    inputEl.click();
  });

export const getFileData = (path) =>
  new Promise((resolve, reject) => {
    const file = cachedFiles[path];

    if (!file) {
      reject(new Error(`File '${path}' is not in the list of cached files.`));
      return;
    }

    const reader = new FileReader();

    // FlowTODO: onload(target) … target is not a FileReader, but an EventTarget,
    // according to dom.js, and as such it doesn't have a `result` part
    // $FlowFixMe
    reader.onload = ({ target: { result } = {} }) => resolve(result);
    reader.onerror = reject;

    reader.readAsDataURL(file);
  });

/**
 * @param url URL string
 * @param _type Note that `type` is unused in the web app, but it's a required
 *              parameter because of shared code with the native app.
 * @param path Path string
 * @param headers {Object}
 * @returns {Promise<{error: boolean}|{data: *, error: boolean, status}>}
 */
export const uploadFile = async (url, _type, path, headers) =>
  /* Promise<{ data?: string, error?: boolean, status?: number }>  */

  {
    let response;

    const file = cachedFiles[path];

    if (!file) {
      return { error: true };
    }

    const formData = new FormData();

    formData.append('file', file);

    try {
      response = await fetch(url, {
        method: 'POST',
        body: formData,
        credentials: 'include',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          ...headers,
        },
      });
    } catch (e) {
      console.warn(e);

      return { error: true };
    }

    if (!response) {
      return { error: true };
    }

    const { status } = response;

    return {
      data: await response.text(),
      status,
      error: status >= 400,
    };
  };
