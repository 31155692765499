import * as React from 'react';
import { createIntl, createIntlCache, RawIntlProvider } from 'react-intl';

import formats from '../formats';
import messages from '../messages'; // Add messages for all languages

// usage:
// import { _t } from '@mindoktor/patient-legacy/i18n';
// allows for using translations without needing `useIntl()` for imperative messages,
// or <FormattedMessage /> et al.
// @mindoktor/patient-legacy/i18n is just a smal wrapper around react-intl, so it's fully compatible.

// This is optional but highly recommended since it prevents memory leak
// (mostly for SSR implementations)
const intlCache = createIntlCache();

// temporary intl placeholder, in case _t gets called before initialisation
// or imported outside of <DoclyIntlProvider> scope
let intlInstance = {
  formatMessage: (msg) => (typeof msg === 'string' ? msg : msg.id),
};

// use instead of <IntlProvider> to set up messages and enable _t()
export const DoclyIntlProvider = ({
  defaultLocale,
  locale,
  textComponent = React.Fragment, // was 'span' for web and Text for native
  children,
}) => {
  intlInstance = createIntl(
    {
      defaultLocale,
      locale,
      messages: messages[locale],
      formats,
      defaultFormats: formats,
      textComponent,
    },
    intlCache
  );

  // debug
  // console.warn(`DoclyIntlProvider:render: ${locale}`);

  // Intl: Need to set key={locale} to allow for hot reloading
  // github.com/yahoo/react-intl/issues/234#issuecomment-163366518
  return (
    <RawIntlProvider key={locale} value={intlInstance}>
      {children}
    </RawIntlProvider>
  );
};

// It's possible to conditionally render messages as translation keys:
// when translationMode is active, messages are rendered like
// `{.common.button.cancel.}`,
// to be picked up by lokalise.co for in-place editing of translations.
// the prefix and suffix are configurable in window.LOKALISE_CONFIG,
// but we're using the default, because why not ¯\_(ツ)_/¯

// regular _t() function:
// export const _t = (msg, value) => {
//   const message = typeof msg === 'string' ? { id: msg } : msg;
//   return intlInstance.formatMessage(message, value);
// };

const translateIt = (msg) => '{.' + msg + '.}';
const renderIt = (msg, value) => {
  const message = typeof msg === 'string' ? { id: msg } : msg;
  return intlInstance.formatMessage(message, value);
};

/**
 * Returns a translation string.
 * @example _t('common.date') // 'Datum'
 * @param {string} translationKey
 * @param {{ [key: string]: number | string | boolean }=} params
 * @returns {string}
 */
export let _t = (translationKey, params) => {
  const translationMode = !!window && window.LOKALISE_CONFIG;
  // assign the appropriate function on first call
  const fn = translationMode ? translateIt : renderIt;
  _t = fn;

  // also, return the output on first call
  return fn(translationKey, params);
};
