import { _t } from '@mindoktor/patient-legacy/i18n';
import React, { useEffect } from 'react';

import { Title1 } from '../../../common/components/typography';

import TruncatedFooter from '../../components/footer/truncated';
import Markdown from '../../components/markdown';
import MediaQuery from '../../components/media_query';
import Screen from '../../components/screen';
import MenuBar from '../../menu/bar';
import Setting from './setting';

import {
  ActionDict,
  ConsentID,
  useMarketingConsentApi,
  useMarketingConsentLogMutator,
  useMarketingConsentMutator,
} from '@mindoktor/patient-app/marketing-consents/web';
import { useHasProtectedIdentity } from '@mindoktor/patient-app/adapters/hooks/useHasProtectedIdentity';

const styles = {
  wrapper: {
    width: '100%',
    maxWidth: 800,
    margin: '0 auto',
    paddingTop: 20,
    paddingBottom: 20,
  },

  body: {
    maxWidth: 600,
    paddingLeft: 20,
    paddingRight: 20,
  },

  settings: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 24,
  },

  title: {
    paddingBottom: 24,
  },
} as const;

export const PrivacyScreen: React.FC = () => {
  const consents = useMarketingConsentApi();
  const consentsMutator = useMarketingConsentMutator();
  const consentLogMutator = useMarketingConsentLogMutator();
  const hasProtectedIdentity = useHasProtectedIdentity();

  const updateConsents = (id: ConsentID, enabled: boolean) => {
    if (!consents.data) {
      return;
    }

    const updatedConsents = consents.data.consents.map((consent) =>
      consent.id === id
        ? {
            ...consent,
            enabled,
          }
        : consent
    );
    consentsMutator.mutate({ consents: updatedConsents });
  };

  const logConsentViewAction = () => {
    if (!consents.data) {
      return;
    }

    consentLogMutator.mutate({
      consents: consents.data.consents.map(({ id }) => ({
        id,
        action: ActionDict.ActionViewed,
      })),
    });
  };

  useEffect(() => {
    if (!consents.isLoading) {
      logConsentViewAction();
    }
  }, [consents.isLoading]);

  return (
    <Screen
      header={<MenuBar />}
      body={
        <div style={styles.wrapper}>
          <div style={styles.body}>
            <Title1 style={styles.title}>
              {_t('integrityPolicyInfoNewUser.inspect')}
            </Title1>
            {hasProtectedIdentity ? (
              <Markdown
                source={_t('identityProtection.integrity.datasaving')}
              />
            ) : (
              <div>
                <Markdown source={_t('integritySettings.intro')} />
                <div style={styles.settings}>
                  {consents.data?.consents.map((consent) => {
                    return (
                      <Setting
                        key={consent.id}
                        name={consent.label}
                        description={consent.description}
                        value={consent.enabled}
                        setValue={(isEnabled) =>
                          updateConsents(consent.id, isEnabled)
                        }
                      />
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      }
      footer={
        <MediaQuery minWidth={801}>
          <TruncatedFooter />
        </MediaQuery>
      }
      showScrollGradient={true}
    />
  );
};

export default PrivacyScreen;
