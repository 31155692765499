import { FREE_PASS_ERROR, RECEIVE_FREE_PASS } from './types';

const initialState = {
  number: undefined,
  issuer: undefined,
  expires: undefined,
  hasError: false,
  errorMessage: undefined,
};

export default function freePassReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case RECEIVE_FREE_PASS: {
      const {
        number,
        issuer,
        expires,
        editable,
        origin,
        status,
        amountToLimit,
      } = payload;

      return {
        number,
        issuer,
        expires,
        editable,
        origin,
        hasError: false,
        errorMessage: '',
        status,
        amountToLimit,
      };
    }

    case FREE_PASS_ERROR: {
      const { hasError, errorMessage } = payload;

      return {
        ...state,
        hasError,
        errorMessage,
      };
    }

    default:
      return state;
  }
}
