export const FILES_POST = 'FILES_POST';
export const FILES_POST_SUCCESS = 'FILES_POST_SUCCESS';
export const FILES_POST_ERROR = 'FILES_POST_ERROR';
export const FILES_CACHE_ADD = 'FILES_CACHE_ADD';

// export type UploadFile = (
//   url: string,
//   path: string,
//   headers?: Object
// ) => Promise<{ data?: string, error?: boolean, status?: number }>;

// export type RemoveTemporaryFile = string => Promise<*>;

// export type TranscodeVideo = string => Promise<{
//   path?: string,
//   error?: boolean,
// }>;

// export type FilesState = {
//   uploading: number,
//   cache: { [number]: string },
// };
