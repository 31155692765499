import React from 'react';

import { Divider, Stack, Typography } from '@mui/material';

import ContainerLink from '@mindoktor/pulse/src/components/ContainerLink/web';
import TextAlignedChevronIcon from '@mindoktor/pulse/src/icons/ChevronIcon/TextAlignedChevronIcon';

import { useTranslation } from '@mindoktor/patient-app/localization/hooks/useTranslation';
import { getStrengthFormPackageSizeText } from '@mindoktor/patient-app/messaging/functions/prescriptionMessage';

import { useBottomSheet } from '../../../hooks/useBottomSheet';
import SystemCardLayout from '../../layout/SystemCardLayout/SystemCardLayout';

import PrescriptionIcon from './PrescriptionIcon/PrescriptionIcon';
import PrescriptionSheetContent from './PrescriptionSheetContent';
import { PrescriptionCardProps } from './types';

const PrescriptionCard: React.FC<PrescriptionCardProps> = ({ message }) => {
  const { drugs, showInfoText } = message.payload;
  const t = useTranslation('messaging', 'prescriptionMessage');
  const sheet = useBottomSheet();

  const handleShowDetails = () => {
    sheet.setContent(
      <PrescriptionSheetContent onClose={sheet.close} drugs={drugs} />
    );
    sheet.open();
    sheet.setOptions({ unmountOnExit: true });
  };

  return (
    <SystemCardLayout
      icon={PrescriptionIcon}
      title={t('title')}
      message={message}
    >
      <>
        {drugs.map((drug, index) => (
          <Stack key={index}>
            <Typography variant="body1" fontWeight={'medium'}>
              {drug.productName}
            </Typography>
            <Typography>{getStrengthFormPackageSizeText(drug)}</Typography>
          </Stack>
        ))}
      </>
      {showInfoText && (
        <>
          <Divider />
          <Typography>{t('infoText.part1')}</Typography>
          <Typography>{t('infoText.part2')}</Typography>
        </>
      )}
      <ContainerLink
        onClick={handleShowDetails}
        endIcon={<TextAlignedChevronIcon variant="body1" />}
      >
        {t('linkText')}
      </ContainerLink>
    </SystemCardLayout>
  );
};

export default PrescriptionCard;
