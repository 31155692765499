import { _t } from '@mindoktor/patient-legacy/i18n';
import React from 'react';
import { connect } from 'react-redux';

import colors from '../../../../common/colors';
import { Intro, Title2 } from '../../../../common/components/typography';
import { deleteDraft } from '../../../../state/drafts/actions';
import { getGuideByEntrywayId } from '../../../../state/guides/selectors';
import { formatRoute } from '../../../../state/routing';
import Button from '../../../components/button/button';
import Card from '../../components/card';
import { routes } from '@mindoktor/patient-app/routing/constants/webRoutes';

const styles = {
  card: {
    borderTop: `2px solid ${colors.red_500}`,
  },

  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: 16,
  },

  icon: {
    display: 'block',
    width: 56,
    height: 56,
    marginRight: 16,
  },

  draft: {
    color: colors.red_500,
  },

  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  removeButton: {
    flex: 0.5,
    marginRight: 8,
  },

  continueButton: {
    flex: 0.5,
    marginLeft: 8,
  },
};

export const DraftCard = ({
  entrywayId,
  childUuid,
  revisitId,
  iconSource,
  title,
  patientName,
  style,
  remove,
}) => (
  <Card style={{ ...styles.card, ...style }}>
    <div style={styles.header}>
      <img src={iconSource} style={styles.icon} />
      <div>
        <Title2>
          <span style={styles.draft}>{_t('draftCard.header')}</span> - {title}
        </Title2>

        <Intro>
          {_t('draftCard.journalizedFor')} {patientName}
        </Intro>
      </div>
    </div>

    <div style={styles.buttons}>
      <Button
        type="ghost"
        color="black"
        onClick={remove}
        style={styles.removeButton}
      >
        {_t('common.delete')}
      </Button>

      <Button
        color="black"
        href={`#${formatRoute(routes.GUIDES_PRESTART, {
          entrywayId,
          childUuid,
          revisitId,
        })}`}
        style={styles.continueButton}
      >
        {_t('draftCard.continue')}
      </Button>
    </div>
  </Card>
);

export default connect(undefined, (dispatch, props) => ({
  remove: () =>
    dispatch((dispatch, getState) => {
      const { entrywayId, childUuid } = props;
      const {
        formularyName: formularyId,
        formularyVersion,
        inquisitionId,
      } = getGuideByEntrywayId(getState(), entrywayId) || {};

      dispatch(
        deleteDraft({
          entrywayId,
          inquisitionId,
          childUuid,
          formularyId,
          formularyVersion,
        })
      );
    }),
}))(DraftCard);
