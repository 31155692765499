import React, { Component, ReactNode } from 'react';

interface Props {
  maxWidth?: number;
  minWidth?: number;
  maxHeight?: number;
  minHeight?: number;
  children: ReactNode;
}

interface State {
  width: number;
  height: number;
}

class MediaQuery extends Component<Props, State> {
  state = {
    width: window.innerWidth,
    height: window.innerHeight,
  };

  onResize = () =>
    this.setState({ width: window.innerWidth, height: window.innerHeight });

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  render() {
    const { maxWidth, minWidth, maxHeight, minHeight, children } = this.props;
    const { width, height } = this.state;

    return (maxWidth !== undefined && maxWidth < width) ||
      (minWidth !== undefined && minWidth > width) ||
      (maxHeight !== undefined && maxHeight < height) ||
      (minHeight !== undefined && minHeight > height) ? null : (
      <>{children}</>
    );
  }
}

export default MediaQuery;
