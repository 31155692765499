import { _t } from '@mindoktor/patient-legacy/i18n';
import React from 'react';

import colors from '../../../common/colors';
import { Body3 } from '../../../common/components/typography';
import ContinueButton from '../../components/button/continue';
import TextInput from '../../components/input/text';

// type Props = {
//   answer?: any,
//   error?: string,
//   errorMessage?: string,
//   hint?: string,
//   maxLength?: number,
//   single?: boolean,
//   numeric?: boolean,
//   update: (value?: any) => any,
//   next?: () => any,
//   disabled?: boolean,
// };

const styles = {
  root: {
    paddingBottom: 20,
    paddingRight: 20,
    paddingLeft: 20,
  },

  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },

  button: {
    marginLeft: 6,
  },

  error: {
    marginTop: 6,
    color: colors.error_text,
  },

  errorInput: {
    paddingBottom: 5,
    backgroundColor: `${colors.yellow_500}50`,
    borderBottomColor: colors.yellow_500,
    borderWidth: 2,
  },
};

export default class Text extends React.Component {
  state = {
    dirty: !!this.props.error && this.props.error !== 'required',
  };

  blur = () => {
    const { answer } = this.props;
    const { dirty } = this.state;

    if (!dirty) {
      this.setState({ dirty: !!answer });
    }
  };

  submit = () => {
    const { error, next } = this.props;

    this.setState({ dirty: true });

    if (!error) {
      next();
    }
  };

  render() {
    const {
      answer,
      errorMessage,
      hint,
      maxLength,
      single,
      numeric,
      update,
      error,
      style,
      next,
      disabled,
    } = this.props;
    const { dirty } = this.state;

    return (
      <div style={{ ...styles.root, ...style }}>
        <div style={styles.wrapper}>
          <TextInput
            type={numeric ? 'number' : 'text'}
            initialValue={answer ? String(answer) : undefined}
            onChange={update}
            placeholder={hint || _t('formulary.question.textHint')}
            maxLength={maxLength}
            autoFocus={true}
            multiline={!single}
            onBlur={this.blur}
            style={dirty && error ? styles.errorInput : undefined}
            disabled={disabled}
          />

          {!!next && (
            <ContinueButton
              disabled={error === 'required' || (!!dirty && !!error)}
              style={styles.button}
              onClick={this.submit}
            />
          )}
        </div>

        {!!dirty && !!errorMessage && (
          <div data-test-id="erromessage">
            <Body3 style={styles.error}>{errorMessage}</Body3>
          </div>
        )}
      </div>
    );
  }
}
