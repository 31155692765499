import config from '@mindoktor/env/Config';
import { _t } from '@mindoktor/patient-legacy/i18n';
import {
  MdArrowDropDown,
  MdArrowDropUp,
  MdBuild,
  MdChildCare,
  MdContactInfo,
  MdCreditCard,
  MdDeleteAccount,
  MdHealthProfile,
  MdPowerSettingsNew,
  MdSecurity,
  MdVaccinationBook,
} from '../components/docly-md-icons';

import React, { useEffect, useState } from 'react';

import colors from '../../common/colors';
import { Intro } from '../../common/components/typography';
import {
  getChildren,
  getPatientNameAndBirthDate,
} from '../../state/profile/selectors';
import { formatRoute, routes as legacyRoutes } from '../../state/routing';
import Avatar from '../components/user/avatar';
import { getSiteOptions } from '../utils/site';
import UserItem from './user_item';
import { Connection } from '../../state/profile/types_definitions';
import { useAppSelector } from '../../state/hooks';
import { useAuthentication } from '@mindoktor/patient-app/auth';
import { useNavigation, routes } from '@mindoktor/patient-app/routing/web';

const styles = {
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },

  icon: {
    width: '24px',
    marginRight: '10px',
  },

  link: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 58,
    cursor: 'pointer',
  },

  arrow: {
    height: 26,
    width: 26,
    marginLeft: -4,
    color: 'white',
  },

  menu: {
    position: 'absolute',
    top: '100%',
    right: 0,
    minWidth: 240,
    backgroundColor: 'white',
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.175)',
    border: '1px solid #d2d2d2',
  },

  menuName: {
    padding: '14px 20px',
    backgroundColor: '#fafafa',
    whiteSpace: 'nowrap',
    fontWeight: 500,
  },

  menuItems: {
    borderTop: '1px solid #d2d2d2',
    paddingTop: 10,
    paddingBottom: 10,
  },

  childItem: {
    paddingLeft: 24,
  },
} as const;

interface Props {
  style?: React.CSSProperties;
}

const DesktopDropdownMenu: React.FC<Props> = ({ style }) => {
  const [show, setShow] = useState(false);
  const [_hover, setHover] = useState(false);
  const { initials, fullName } = useAppSelector((state) =>
    getPatientNameAndBirthDate(state)
  );
  const children = useAppSelector(
    (state) => getChildren(state) as Connection[]
  );

  const authentication = useAuthentication();

  const onClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
    setShow(!show);
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
  };

  const onUserMenuClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
  };

  const onDocumentClick = () => {
    setShow(false);
  };
  const onMouseEnter = () => {
    setHover(true);
  };
  const onMouseLeave = () => {
    setHover(false);
  };

  const logout = async () => {
    await authentication.logout(true);
  };

  useEffect(() => {
    document.addEventListener('click', () => onDocumentClick());
    return () => {
      document.removeEventListener('click', () => onDocumentClick());
    };
  }, []);

  const navigation = useNavigation();

  return (
    <div style={{ ...styles.root, ...style }}>
      <div
        style={styles.link}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <Avatar
          initials={initials}
          size={34}
          backgroundColor={colors.grey_100}
        />

        {show ? (
          <MdArrowDropUp style={styles.arrow} />
        ) : (
          <MdArrowDropDown style={styles.arrow} />
        )}
      </div>

      {show && (
        <div style={styles.menu} onClick={onUserMenuClick}>
          <Intro style={styles.menuName}>{fullName}</Intro>

          <div style={styles.menuItems}>
            <UserItem
              iconComponent={MdHealthProfile}
              text={_t('menu.healthProfile')}
              onClick={() =>
                ((window as Window).location = `#${formatRoute(
                  legacyRoutes.HEALTH_PROFILE
                )}`)
              }
            />
            <UserItem
              iconComponent={MdVaccinationBook}
              text={_t('menu.vaccinationBook')}
              onClick={() =>
                ((window as Window).location = `#${formatRoute(
                  legacyRoutes.VACCINATION_BOOK
                )}`)
              }
            />

            <UserItem
              iconComponent={MdContactInfo}
              text={_t('menu.account')}
              onClick={() => navigation.navigate(routes.ACCOUNT_CONTACT)}
            />

            {config.ShowFreePass && (
              <UserItem
                iconComponent={MdCreditCard}
                text={_t('menu.freePass')}
                onClick={() =>
                  ((window as Window).location = `#${formatRoute(
                    legacyRoutes.PAYMENT_FREE_PASS
                  )}`)
                }
              />
            )}

            {getSiteOptions().showPrivacySettings && (
              <UserItem
                iconComponent={MdSecurity}
                text={_t('menu.integrity')}
                onClick={() =>
                  ((window as Window).location = `#${formatRoute(
                    legacyRoutes.LOGIN_PRIVACY_SETTINGS
                  )}`)
                }
              />
            )}

            <UserItem
              iconComponent={MdDeleteAccount}
              text={_t('menu.deleteMyAccount')}
              onClick={() => navigation.navigate(routes.DELETE_ACCOUNT)}
            />

            {config.IsDev && (
              <UserItem
                iconComponent={MdBuild}
                text="Developer tools"
                onClick={() => navigation.navigate(routes.DEVELOPER_TOOLS)}
              />
            )}

            <UserItem
              iconComponent={MdPowerSettingsNew}
              text={_t('menu.logout')}
              onClick={logout}
            />
          </div>

          {!!children && !!children.length && (
            <div style={styles.menuItems}>
              {children.map((child) => (
                <UserItem
                  key={child.uuid}
                  iconComponent={MdChildCare}
                  text={_t('menu.healthProfileChild', {
                    name: child.firstName,
                  })}
                  small={true}
                  style={styles.childItem}
                  onClick={() =>
                    ((window as Window).location = `#${formatRoute(
                      legacyRoutes.HEALTH_PROFILE,
                      { childUuid: child.uuid }
                    )}`)
                  }
                />
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default DesktopDropdownMenu;
