import { useQuery } from '@tanstack/react-query';

import { useNotifications } from '@mindoktor/patient-app/adapters/hooks/useNotifications';
import { ResponseError } from '@mindoktor/patient-app/api/responseError';
import { useAuthentication } from '@mindoktor/patient-app/auth/hooks/useAuthentication';
import { useTranslation } from '@mindoktor/patient-app/localization/hooks/useTranslation';
import { TIME } from '@mindoktor/patient-app/utils/time/constants';

import { MarketingUser } from '../api/models/marketingUser';
import { trackingApi } from '../api/trackingApi';

export const marketingUserQueryKey = 'marketingUser';

interface Options {
  refetchIntervalFunction: (data: MarketingUser | undefined) => number | false;
}

export const useMarketingUserApi = (options?: Options) => {
  const authentication = useAuthentication();
  const notifications = useNotifications();
  const t = useTranslation('error');

  return useQuery<MarketingUser | undefined, ResponseError>(
    [marketingUserQueryKey],
    async () => {
      const response = await trackingApi.getMarketingUser();
      if (!response?.ok) {
        throw new ResponseError(response);
      }

      return response.data?.data;
    },
    {
      enabled: authentication.isAuthenticated,
      retry: false,
      refetchInterval: options?.refetchIntervalFunction,
      staleTime: 1 * TIME.HOUR,
      onError: (_error) => {
        notifications.showWarning(t('unknown'));
      },
    }
  );
};
