import React from 'react';

import colors from '../../../common/colors';
import { Body1 } from '../../../common/components/typography';

const styles = {
  avatar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
  },
};

interface Props {
  initials?: string;
  size?: number;
  textColor?: string;
  backgroundColor?: string;
  style?: React.CSSProperties;
}

const Avatar: React.FC<Props> = ({
  initials,
  size = 40,
  textColor = colors.off_black,
  backgroundColor = colors.grey_200,
  style,
}) => (
  <div
    style={{
      width: size,
      height: size,
      ...style,
    }}
  >
    <Body1
      style={{
        ...styles.avatar,
        width: size,
        height: size,
        lineHeight: `${size}px`,
        fontSize: size * (2 / 5),
        color: textColor,
        backgroundColor,
      }}
    >
      {initials}
    </Body1>
  </div>
);

export default Avatar;
