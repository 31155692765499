import React, { useEffect, useRef, useState } from 'react';

import { Box } from '@mui/material';

import ImageWithLoader from '@mindoktor/pulse/src/components/ImageWithLoader/ImageWithLoader.web';

import { getFileUri } from '@mindoktor/patient-app/files/api/utils';
import { useTranslation } from '@mindoktor/patient-app/localization/hooks/useTranslation';

import FullscreenImageModal from './FullscreenImageModal/FullscreenImageModal';
import ImageAttachmentOverlay from './ImageAttachmentOverlay/ImageAttachmentOverlay';
import { ImageAttachmentProps } from './types';

const ImageAttachment: React.FC<ImageAttachmentProps> = ({ attachment }) => {
  const t = useTranslation('messaging', 'attachment');

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [cardWidth, setCardWidth] = useState(0);
  const cardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (cardRef.current != null) {
      setCardWidth(cardRef.current.offsetWidth);
    }
  }, [cardRef.current?.offsetWidth]);

  const onLoad = () => {
    setIsLoading(false);
  };

  const onClick = () => {
    setIsModalOpen(true);
  };

  const onClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Box ref={cardRef} height={isLoading ? cardWidth : undefined}>
        <ImageAttachmentOverlay
          fileReferenceNumber={attachment.fileReferenceNumber}
        >
          <ImageWithLoader
            src={getFileUri(attachment.fileId, { thumbnail: true })}
            maxWidth="100%"
            alt={t('altImageText')}
            onLoad={onLoad}
            onClick={onClick}
          />
        </ImageAttachmentOverlay>
      </Box>
      <FullscreenImageModal
        isOpen={isModalOpen}
        onClose={onClose}
        attachment={attachment}
      />
    </>
  );
};

export default ImageAttachment;
