import { createContext } from 'react';

export interface BottomSheetOptions {
  avoidKeyboard?: boolean;
  mountOnEnter?: boolean;
  unmountOnExit?: boolean;
}

interface Context {
  open: () => void;
  close: () => void;
  setContent: (content: React.ReactNode) => void;
  setOptions: (options: BottomSheetOptions) => void;
}

export const BottomSheetContext = createContext<Context | undefined>(undefined);
