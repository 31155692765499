import { _t } from '@mindoktor/patient-legacy/i18n';
import React from 'react';
import ReactMarkdown from 'react-markdown';

import { hideModal, showModal } from '../routing';
import { Body1, Title1 } from '../../common/components/typography';
import Button from './button/button';
import Link from './link';
// react-markdown plugins
import ModalPlugin from './markdown/plugins/block/Modal';
// react-markdown renderers
import GuideInfoIcon from './markdown/renderers/GuideInfoIcon';
import Modal from './modal';

/**
root - Root container element that contains the rendered markdown
break - Hard-break (<br>)
paragraph - Paragraph (<p>)
emphasis - Emphasis (<em>)
strong - Strong/bold (<strong>)
thematicBreak - Horizontal rule / thematic break (<hr>)
blockquote - Block quote (<blockquote>)
delete - Deleted/strike-through (<del>)
link - Link (<a>)
image - Image (<img>)
linkReference - Link (through a reference) (<a>)
imageReference - Image (through a reference) (<img>)
table - Table (<table>)
tableHead - Table head (<thead>)
tableBody - Table body (<tbody>)
tableRow - Table row (<tr>)
tableCell - Table cell (<td>/<th>)
list - List (<ul>/<ol>)
listItem - List item (<li>)
definition - Definition (not rendered by default)
heading - Heading (<h1>-<h6>)
inlineCode - Inline code (<code>)
code - Block of code (<pre><code>)
html - HTML node (Best-effort rendering)
**/

const inlineStyles = `
  .md-markdown-wrapper strong,
  .md-markdown-wrapper b {
    font-weight: 500;
  }

  .md-markdown-paragraph {
    margin: 0;
    padding-bottom: 10px;
  }

  .md-markdown-paragraph:last-child {
    padding-bottom: 0;
  }

  .md-markdown-modal-opener {
    padding-bottom: 10px;
  }
`;

const plugins = [ModalPlugin];

const renderers = (TextComponent, color, style) => ({
  root: TextComponent,

  paragraph: ({ children }) => (
    <TextComponent className="md-markdown-paragraph" color={color}>
      {children}
    </TextComponent>
  ),

  list: ({ ordered, children }) =>
    ordered ? (
      <ol className="md-markdown-paragraph">{children}</ol>
    ) : (
      <ul className="md-markdown-paragraph">{children}</ul>
    ),

  heading: ({ children, color }) => (
    <Title1 className="md-markdown-paragraph" color={color}>
      {children}
    </Title1>
  ),

  link: (props) => (
    <Link
      {...props}
      style={style?.link}
      textComponent={TextComponent}
      variant="markdown"
    />
  ),

  modal: ({ flag, innards }) => (
    <div className="md-markdown-modal-opener">
      <Link
        onClick={() =>
          showModal(Modal, {
            body: (
              <ReactMarkdown
                source={innards.trim()}
                renderers={renderers(TextComponent, color)}
                plugins={plugins}
              />
            ),
            footer: (
              <Button type="solid" color="red" onClick={hideModal}>
                {_t('common.close')}
              </Button>
            ),
          })
        }
      >
        {flag.trim()}
      </Link>
    </div>
  ),

  image: GuideInfoIcon,
});

// type Props = {
//   source?: string,
//   textComponent?: any,
//   style?: any,
//   color?: string,
// };

/**
 * TODO: Type this better
 * @param {
 *  {
 *    source?: string,
 *    textComponent?: any,
 *    style?: any,
 *    color?: string
 *  }
 * }
 */
export default ({
  source,
  textComponent: TextComponent = Body1,
  style,
  color,
}) => (
  <div className="md-markdown-wrapper" style={style}>
    <style>{inlineStyles}</style>

    <ReactMarkdown
      source={source}
      renderers={renderers(TextComponent, color, style)}
      plugins={plugins}
    />
  </div>
);
