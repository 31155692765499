import { useEffect, useState } from 'react';

import { getVisibleHeight } from '../../../functions/getActualHeight/web';

export const useVisibleHeight = () => {
  const [visibleHeight, setVisibleHeight] = useState(`${getVisibleHeight()}px`);

  useEffect(() => {
    setVisibleHeight(`${getVisibleHeight()}px`);
  }, []);

  return visibleHeight;
};
