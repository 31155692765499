import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import compose from '@mindoktor/patient-legacy/src/common/compose';
import { AppDispatch, CommonStateType } from '../../../state/types';

import config from '@mindoktor/env/Config';
import colors from '../../../common/colors';
import {
  fetchCaseMessages,
  postPhotoMessage,
  postVideoMessage,
  viewCase,
} from '../../../state/cases/actions';
import {
  createQuestionnaireItem,
  injectDivider,
} from '../../../state/cases/caseMessageUtils';
import {
  CaseStatuses,
  CaseStatus,
  MessageType,
} from '../../../state/cases/constants';
import {
  getCaseData,
  getCaseMessages,
  getClosedCaseItem,
  getResumeCaseItem,
} from '../../../state/cases/selectors';
import { hasFeatureFlag } from '../../../state/featureflags/selectors';
import {
  hasImageExtension,
  hasVideoExtension,
} from '../../../state/files/utils';
import {
  hasPayment,
  hasTechnicalError,
} from '../../../state/guides/guidesUtils';
import { recordVisit } from '../../../state/messages/actions';
import {
  getActiveMessagesScreenSince,
  getMarkNewForCase,
} from '../../../state/messages/selectors';
import {
  subscribe as channelSubscribe,
  unsubscribe as channelUnsubscribe,
} from '../../../state/messaging/actions';
import { caseChannel } from '../../../state/messaging/channels';
import { getEnabled as getMessagingEnabled } from '../../../state/messaging/selectors';
import { routes } from '@mindoktor/patient-app/routing/constants/webRoutes';
import { surveyNames } from '../../../state/survey2/constants';
import {
  getHasOngoingSurvey,
  getIsSurveyOngoing,
  getQuestionnaireKey,
  getQuestionsAndAnswers,
} from '../../../state/survey2/selectors';
import { fetchVideochatPresence } from '../../../state/videochat/actions';
import { chooseFiles, uploadFile } from '../../utils/files';
import BackBar from '../../components/bar/back';
import TruncatedFooter from '../../components/footer/truncated';
import MediaQuery from '../../components/media_query';
import Screen from '../../components/screen';
import MenuBar from '../../menu/bar';
import Bar from './bar';
import Input from './input';
import List from './list';

import {
  MARKETING_CONSENT_FEATURE_FLAG,
  MarketingConsentHandler,
} from '@mindoktor/patient-app/marketing-consents/web';
import { Placings } from '@mindoktor/patient-app/marketing-consents/components/MarketingConsentHandler/web';
import {
  withTrackPageView,
  WithTrackPageViewProps,
} from '@mindoktor/patient-app/tracking/HOC/withTrackPageView';
import {
  withRouting,
  WithRoutingProps,
} from '@mindoktor/patient-app/routing/HOC/withRouting.web';
import { useCaseById } from '@mindoktor/patient-app/cases/hooks/useCaseById';
import { useParams } from '@mindoktor/patient-app/routing/hooks/useParams/useParams';
import MessagingScreen from '@mindoktor/patient-app/messaging/components/MessagingScreen/MessagingScreen';

const styles = {
  menuBar: {
    boxShadow: 'none',
  },

  backBar: {
    borderBottom: `2px solid ${colors.off_black}`,
  },

  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
    maxWidth: 800,
    margin: '0 auto',
  },

  inner: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
    maxWidth: 600,
  },

  list: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: 16,
    backgroundColor: colors.background,
  },
} as const;

const isPending = (status: CaseStatus) => status === CaseStatuses.Pending;

const Wrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <div style={styles.wrapper}>
    <div style={styles.inner}>{children}</div>
  </div>
);

interface OwnProps {
  caseId: number;
  isNextGenChat: boolean;
}

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = OwnProps &
  PropsFromRedux &
  WithTrackPageViewProps &
  WithRoutingProps;

export class MessagesScreen extends Component<Props> {
  hasUserSeenAllMessages = false;

  fetchMessagesAndVideochatPresence = () => {
    const { fetchMessages, fetchVideochatPresence } = this.props;
    fetchVideochatPresence();
    fetchMessages();
  };

  pollerId: null | ReturnType<typeof setInterval> = null;
  pollMessages = () => {
    this.pollerId = setInterval(() => {
      if (document.hasFocus && document.hasFocus()) {
        this.fetchMessagesAndVideochatPresence();
      }
    }, config.MessagesPollInterval);
  };

  subscribeToMessages = () => {
    const {
      props: { channelSubscribe, caseId },
    } = this;

    channelSubscribe(caseChannel(caseId), {
      onPublish: () => {
        this.fetchMessagesAndVideochatPresence();
        if (this.pollerId) {
          clearInterval(this.pollerId);
        }
        this.pollMessages();
      },
    });
  };

  observeMessages = () => {
    const {
      props: { messagingEnabled },
    } = this;

    this.fetchMessagesAndVideochatPresence();

    document.addEventListener(
      'visibilitychange',
      this.fetchMessagesAndVideochatPresence
    );

    if (messagingEnabled) this.subscribeToMessages();

    this.pollMessages();
  };

  unsubscribeToCaseChannel = () => {
    const { channelUnsubscribe, caseId } = this.props;
    channelUnsubscribe(caseChannel(caseId));
  };

  unobserveMessages = () => {
    const {
      props: { messagingEnabled },
    } = this;

    if (this.pollerId) {
      clearInterval(this.pollerId);
    }

    if (messagingEnabled) this.unsubscribeToCaseChannel();

    document.removeEventListener(
      'visibilitychange',
      this.fetchMessagesAndVideochatPresence
    );
  };

  uploadPhoto = async () => {
    let path;
    try {
      path = await chooseFiles('image/*', false);
    } catch (e) {
      return;
    }

    if (!hasImageExtension(path)) {
      return;
    }

    this.props.postPhotoMessage(path);
  };

  uploadVideo = async () => {
    let path;
    try {
      path = await chooseFiles('video/*', false);
    } catch (e) {
      return;
    }

    if (!hasVideoExtension(path)) {
      return;
    }

    this.props.postVideoMessage(path);
  };

  componentDidUpdate(previousProps: Props) {
    const { loadedCase, messages, fetchMessages, messagingEnabled } =
      this.props;
    const { loadedCase: previouslyLoadedCase, messages: previousMessages } =
      previousProps;

    if (loadedCase !== previouslyLoadedCase) {
      fetchMessages();
    }

    if (messages.length > previousMessages.length) {
      this.hasUserSeenAllMessages = false;
      setTimeout(() =>
        window.scrollTo(0, document.body && document.body.scrollHeight)
      );
    }

    if (!previousProps.messagingEnabled && messagingEnabled) {
      this.subscribeToMessages();
    }
  }

  componentDidMount() {
    const { trackPageView, caseId } = this.props;
    trackPageView({ caseId });
    this.observeMessages();
  }

  viewCase = async () => {
    const { viewCase, caseId, entrywayId } = this.props;

    const { error } = await viewCase({
      caseId,
      entrywayId,
    });
    if (error) {
      // view logging could be rejected due to connection interrupted.
      // In this case, there's no need to error the whole application by throwing,
      // it's fine for us to just try again the next time the user views the case.
      console.error(error);
      return;
    }
  };

  componentWillUnmount() {
    const { recordVisit } = this.props;

    recordVisit();

    this.unobserveMessages();
  }

  newMessagesDivider = {
    cId: 'newMessagesDivider',
    type: MessageType.NEW_MESSAGES_DIVIDER,
  };

  openCases = () => {
    const {
      routing: { navigation },
    } = this.props;

    navigation.navigate(routes.CASES);
  };

  render() {
    const {
      messages,
      caseId,
      entrywayId,
      status,
      title,
      doctorName,
      doctorImageUrl,
      doctorSignature,
      canPost,
      fetchMessages,
      markNewSince,
      activeSince,
      hasTechnicalError,
      hasPayment,
      isNextGenChat,
      isMarketingConsentFlagOn,
    } = this.props;

    const barTitle = isPending(status) ? title : doctorName;
    const barSubtitle = isPending(status) ? undefined : doctorSignature;

    return (
      <Screen
        header={
          <div>
            <MediaQuery maxWidth={600}>
              <BackBar
                title={barTitle}
                subtitle={barSubtitle}
                dark={true}
                onBack={this.openCases}
              />
            </MediaQuery>

            <MediaQuery minWidth={601}>
              <MenuBar style={styles.menuBar} />

              {/* Chat top bar, we don't use it in the new chat */}
              {!isNextGenChat && (
                <Wrapper>
                  <Bar
                    title={barTitle}
                    subtitle={barSubtitle}
                    onBack={this.openCases}
                  />
                </Wrapper>
              )}
            </MediaQuery>
          </div>
        }
        body={
          <>
            {
              // Legacy chat
              <Wrapper>
                <List
                  ref={(el: React.ElementType) => {
                    if (!el) {
                      // component has been rendered, but not mounted
                      return;
                    }

                    // check the status to see if the case has been loaded yet.
                    // The status can also be 0 (CaseStatusGuideAborted),
                    // so it's important to check for it actually being undefined.
                    if (status === undefined) {
                      // case has not been loaded from backend yet.
                      return;
                    }
                    if (this.hasUserSeenAllMessages) {
                      return;
                    }
                    if (!document.hasFocus()) {
                      return;
                    }
                    this.viewCase();
                    this.hasUserSeenAllMessages = true;
                  }}
                  caseId={caseId}
                  entrywayId={entrywayId}
                  messages={injectDivider(
                    messages,
                    markNewSince,
                    activeSince,
                    this.newMessagesDivider
                  )}
                  onUpdate={fetchMessages}
                  style={styles.list}
                  uploadPhoto={this.uploadPhoto}
                  uploadVideo={this.uploadVideo}
                  hasTechnicalError={hasTechnicalError}
                  hasPayment={hasPayment}
                  doctorImageUrl={doctorImageUrl}
                />
              </Wrapper>
            }
            {isMarketingConsentFlagOn && (
              <MarketingConsentHandler placing={Placings.chat} delayed />
            )}
          </>
        }
        footer={
          <div>
            <Wrapper>
              {canPost && <Input caseId={caseId} entrywayId={entrywayId} />}
            </Wrapper>
            <MediaQuery minWidth={601}>
              <TruncatedFooter />
            </MediaQuery>
          </div>
        }
      />
    );
  }
}

const connector = connect(
  (state: CommonStateType, props: OwnProps) => {
    const { caseId } = props;

    const caseData = getCaseData(state, caseId) || {};

    const {
      entrywayId,
      status,
      displayTitle: title,
      doctorName,
      doctorSignature,
      doctorImageUrl,
      caseIsTreatedBy: treater,
      submitted,
      paymentStatus,
      paymentMethod,
    } = caseData;

    // Hide message input if we have an ongoing survey to stop user
    // confusion and unintentional inputs
    const canPost = caseData.canPost && !getHasOngoingSurvey(state, caseId);

    return {
      loadedCase: caseData ? !!Object.keys(caseData).length : false,
      messages: [
        ...createQuestionnaireItem(
          caseId,
          getIsSurveyOngoing(state, caseId, surveyNames.ONBOARDING_EVALUATION),
          getQuestionsAndAnswers(
            state,
            caseId,
            surveyNames.ONBOARDING_EVALUATION
          ),
          getQuestionnaireKey(state, caseId, surveyNames.ONBOARDING_EVALUATION),
          surveyNames.ONBOARDING_EVALUATION
        ),
        ...createQuestionnaireItem(
          caseId,
          getIsSurveyOngoing(
            state,
            caseId,
            surveyNames.CONSULTATION_EVALUATION
          ),
          getQuestionsAndAnswers(
            state,
            caseId,
            surveyNames.CONSULTATION_EVALUATION
          ),
          getQuestionnaireKey(
            state,
            caseId,
            surveyNames.CONSULTATION_EVALUATION
          ),
          surveyNames.CONSULTATION_EVALUATION
        ),
        hasFeatureFlag(state, 'resume-closed-cases')
          ? getResumeCaseItem(state, caseId)
          : getClosedCaseItem(state, caseId),
        ...getCaseMessages(state, caseId),
      ].filter((x) => x),

      entrywayId,
      status,
      title,

      doctorName,
      doctorSignature,
      treater,
      paymentMethod,
      canPost,

      markNewSince: getMarkNewForCase(state, caseId),
      activeSince: getActiveMessagesScreenSince(state),

      messagingEnabled: getMessagingEnabled(state),

      hasTechnicalError: submitted && hasTechnicalError(submitted.time, status),
      hasPayment: hasPayment(paymentStatus, paymentMethod),
      doctorImageUrl,
      isMarketingConsentFlagOn: hasFeatureFlag(
        state,
        MARKETING_CONSENT_FEATURE_FLAG
      ),
    };
  },
  (dispatch, props) => {
    const typedDispatch = dispatch as unknown as AppDispatch;
    return {
      fetchMessages: () =>
        typedDispatch(
          (dispatch: AppDispatch, getState: () => CommonStateType) => {
            const { caseId } = props;
            const caseData = getCaseData(getState(), caseId);

            if (caseData) {
              dispatch(fetchCaseMessages(caseData));
            }
          }
        ),
      fetchVideochatPresence: () => typedDispatch(fetchVideochatPresence()),
      recordVisit: () => typedDispatch(recordVisit(props.caseId)),
      postPhotoMessage: (path: string) =>
        typedDispatch(postPhotoMessage(props.caseId, path, '', uploadFile)),
      postVideoMessage: (path: string) =>
        typedDispatch(postVideoMessage(props.caseId, path, '', uploadFile)),
      channelSubscribe: (...args: unknown[]) =>
        typedDispatch(channelSubscribe(...args)),
      channelUnsubscribe: (...args: unknown[]) =>
        typedDispatch(channelUnsubscribe(...args)),
      viewCase: (data: Parameters<typeof viewCase>[0]) =>
        typedDispatch(viewCase(data)) as ReturnType<
          ReturnType<typeof viewCase>
        >,
    };
  }
);

const enhance = compose(withTrackPageView, withRouting, connector);

const EnhancedMessagingScreen = enhance(MessagesScreen);

const MessagingNavigationWrapper = (props: Props) => {
  const { id, caseId } = useParams();
  const routeId = id ?? caseId;
  const routeCaseId = Number(routeId);
  const _case = useCaseById(routeCaseId);
  const conversationId = _case?.conversationId;
  if (!_case) {
    return null;
  }
  return conversationId ? (
    <MessagingScreen conversationId={conversationId} />
  ) : (
    <EnhancedMessagingScreen {...props} />
  );
};
export default MessagingNavigationWrapper;
