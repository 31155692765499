import React, { Component } from 'react';

// import type { Option } from '../../../state/formulary/types';
import OptionInput from '../../components/input/option';

const styles = {
  root: {
    marginBottom: 20,
  },
};

// type ItemProps = {
//   option: Option,
//   checked: boolean,
//   select: any => any,
//   lastItem: boolean,
//   disabled?: boolean,
// };

class ChoiceItem extends Component {
  select = () => {
    const { option, select } = this.props;

    select(option.value);
  };

  render() {
    const { option, checked, lastItem, disabled } = this.props;

    return (
      <OptionInput
        label={option.label}
        checked={checked}
        lastItem={lastItem}
        onChange={this.select}
        disabled={disabled}
      />
    );
  }
}

// type Props = {
//   options: Option[],
//   answer?: any,
//   error?: string,
//   update: (value?: any) => any,
//   next?: () => any,
//   disabled?: boolean,
// };

export default class Choice extends Component {
  select = async (value) => {
    const { update, next } = this.props;

    await update(value);

    if (next) next();
  };

  render() {
    const { options, answer, disabled } = this.props;

    return (
      <div style={styles.root}>
        {options.map((option, i) => (
          <ChoiceItem
            data-test-id={`choiceitem-${option.index}`}
            key={option.index || i}
            option={option}
            checked={option.value === answer}
            select={this.select}
            lastItem={i === options.length - 1}
            disabled={disabled}
          />
        ))}
      </div>
    );
  }
}
