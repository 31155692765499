import {
  clearDraft as clearInquisitionDraft,
  removeDraft as removeInquisitionDraft,
} from '../inquisitions/actions';
import { clearFormulary } from '../formulary/actions';
import { getDrafts, postDraft, removeDraft } from '../formulary/api';
import {
  getFormularyState,
  getPreferredCaregiverId,
} from '../formulary/selectors';
import { FORMULARY_SAVED } from '../formulary/types';
import { toFormularyKey } from '../formulary/utils';
import { deleteInquisitor } from '../inquisitions/inquisitor';
import {
  DRAFT_DELETE,
  DRAFT_DELETE_ERROR,
  DRAFT_DELETE_SUCCESS,
  DRAFTS_LOAD,
  DRAFTS_LOAD_ERROR,
  DRAFTS_LOAD_SUCCESS,
} from './types';

const DRAFT_DELAY = 5000;
const delayedDrafts = {};

export function loadDrafts() {
  return async (dispatch) => {
    dispatch({
      type: DRAFTS_LOAD,
    });

    const { drafts, error } = await dispatch(getDrafts());
    if (error) {
      dispatch({
        type: DRAFTS_LOAD_ERROR,
      });
      return { error: true };
    }

    dispatch({
      type: DRAFTS_LOAD_SUCCESS,
      payload: { drafts },
    });

    return true;
  };
}

export function deleteDraft({
  formularyId,
  inquisitionId,
  formularyVersion,
  childUuid,
  entrywayId,
}) {
  return async (dispatch, getState) => {
    dispatch({
      type: DRAFT_DELETE,
    });

    // This removes both inquisition and formulary drafts, on the server side only.
    const { error } = await dispatch(
      inquisitionId
        ? removeInquisitionDraft(inquisitionId, childUuid)
        : removeDraft(formularyId, formularyVersion, childUuid)
    );

    await dispatch(loadDrafts());

    if (error) {
      dispatch({
        type: DRAFT_DELETE_ERROR,
      });
      return { error: true };
    }

    dispatch({
      type: DRAFT_DELETE_SUCCESS,
    });

    // also make sure to delete any locally stored formulary draft (if present)
    const formularyKey = toFormularyKey({
      entrywayId,
      formularyId,
      formularyVersion,
      childUuid,
    });

    const formularyState = getFormularyState(getState(), formularyKey);
    formularyState && dispatch(clearFormulary({ formularyKey }));

    // And finally, take care of any locally stored inquisition draft.
    if (inquisitionId) {
      deleteInquisitor(inquisitionId);
      await dispatch(clearInquisitionDraft(inquisitionId));
    }

    return true;
  };
}

export function clearDelayedDraft({ formularyKey }) {
  const timeout = delayedDrafts[formularyKey];
  timeout && clearTimeout(timeout);
  delete delayedDrafts[formularyKey];
}

export function saveDraft({ formularyKey }) {
  return async (dispatch, getState) => {
    delayedDrafts[formularyKey] =
      delayedDrafts[formularyKey] ||
      setTimeout(async () => {
        const state = getState();

        delete delayedDrafts[formularyKey];

        const formularyState = getFormularyState(state, formularyKey);

        if (
          !formularyState ||
          formularyState.posting ||
          formularyState.skipDrafts
        ) {
          return;
        }

        const {
          formulary,
          answers,
          current,
          childUuid,
          revisitId,
          dynamicCode,
        } = formularyState;

        const entrywayId = parseInt(formularyState.entrywayId);

        const { error } = await dispatch(
          postDraft(
            entrywayId,
            formulary.id,
            formulary.version,
            childUuid,
            answers,
            current ? current.index : undefined,
            revisitId,
            dynamicCode,
            getPreferredCaregiverId(state, formularyKey)
          )
        );

        if (error) return;

        dispatch({
          type: FORMULARY_SAVED,
          payload: { formularyKey, savedAt: Date.now() },
        });

        dispatch(loadDrafts());
      }, DRAFT_DELAY);
  };
}
