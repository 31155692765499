import React, { Component } from 'react';

import FadeTransition from '../components/transition/fade';
import Intl from '../intl/provider';
import { context } from './index';

export default class LegacyRouter extends Component {
  state = {
    index: 0,
    Component: undefined,
    props: undefined,
    options: undefined,
  };

  navigate = (Component, props, options) => {
    // Using the callback version of setState instead of the "object" version
    // removes the race condition here.
    // Callback setState has the benefit that two calls to setState that happen
    // very close to each other can't overwrite each others values, as long as
    // you use the prevState parameter.
    // What happened here before is probably that componentDidMount and this one
    // in navigate were called right after each other without this.state being
    // properly updated, and then the index wasn't properly updated even though
    // the other parameters were updated.
    this.setState((prevState) => {
      return { Component, props, options, index: prevState.index + 1 };
    });
  };

  componentDidMount() {
    const { Component, props, options } = context.cachedRoute || {};

    this.setState((prevState) => {
      return { Component, props, options, index: prevState.index + 1 };
    });

    context.navigate = this.navigate;
  }

  afterTransition = () => {
    const { options = {} } = this.state;

    if (options.scrollToBottom) {
      window.scrollTo(0, document.body && document.body.scrollHeight);
    } else {
      window.scroll();
    }
  };

  render() {
    const { Component, props, index } = this.state;

    return (
      <Intl>
        <FadeTransition
          transitionKey={index}
          afterTransition={this.afterTransition}
        >
          {!!Component && <Component {...props} />}
        </FadeTransition>
      </Intl>
    );
  }
}
