import React from 'react';

import ContinueButton from '../../components/button/continue';

// type Props = {
//   next: () => any,
// };

const styles = {
  footer: {
    marginBottom: 20,
    marginLeft: 20,
    marginRight: 20,
  },

  next: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
};

const Info = ({ next }) => (
  <div style={styles.footer}>
    <div style={styles.next}>
      <ContinueButton onClick={next} />
    </div>
  </div>
);

export default Info;
