import dayjs from 'dayjs';

export const getDisplayTime = (timestamp) => {
  // if we don't get a provided timestamp, return empty string
  if (!timestamp) return '';

  if (dayjs().diff(timestamp, 'day') === 0) {
    return dayjs(timestamp).format('HH:mm');
  }

  if (dayjs().diff(timestamp, 'year') === 0) {
    return dayjs(timestamp).format('HH:mm D/M');
  }

  return dayjs(timestamp).format('D/M YYYY');
};
