import React from 'react';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';

import { useTranslation } from '@mindoktor/patient-app/localization/hooks/useTranslation';

// copied from: https://mui.com/material-ui/react-menu/#account-menu
const UserMenu: React.FC = () => {
  const t = useTranslation('menu');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Button
          color="hero"
          // FIXME: Should link to the guides
        >
          {t('seekCare')}
        </Button>
        <Tooltip title="Meny">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'user-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }}>M</Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="user-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem>{t('seekCare')}</MenuItem>
        <MenuItem>{t('myCases')}</MenuItem>
        <MenuItem>{t('support')}</MenuItem>
        <MenuItem>{t('howItWorks')}</MenuItem>
        <Divider />
        <MenuItem>
          <Avatar /> Patient namn
        </MenuItem>
        <MenuItem>{t('healthProfile')}</MenuItem>
        <MenuItem>{t('accountSettings')}</MenuItem>
        <MenuItem>{t('freeCard')}</MenuItem>
        <MenuItem>{t('privacySettings')}</MenuItem>
        <MenuItem>{t('logout')}</MenuItem>
      </Menu>
    </>
  );
};

export default UserMenu;
