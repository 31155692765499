import { ajaxApi, ApiHeaders } from '../api';

const API_ENDPOINT_TICKETS_SUPPORT_CENTER = '/api/v1/support/tickets';
import type { SupportTicket } from './types';

/**
 * Fetches the link to the support center (or help-center) landing page
 */
const fetchHelpCenterSupportTickets = async (
  apiHeaders: ApiHeaders
): Promise<[SupportTicket[], Error | null]> => {
  const [jsonResponse, err] = await ajaxApi(
    apiHeaders,
    {
      method: 'GET',
      url: API_ENDPOINT_TICKETS_SUPPORT_CENTER,
    },
    null
  );
  if (err) {
    return [[], err];
  }
  const tickets = (jsonResponse?.data?.tickets ?? []) as SupportTicket[];
  return [tickets, err];
};

export default fetchHelpCenterSupportTickets;
