import React, { Component } from 'react';

import checkboxChecked from '../../../../images/components/checkbox_checked@3x.png';
import checkboxUnchecked from '../../../../images/components/checkbox_unchecked@3x.png';
import radioChecked from '../../../../images/components/radio_checked@3x.png';
import radioUnchecked from '../../../../images/components/radio_unchecked@3x.png';
import colors from '../../../common/colors';
import { Body1 } from '../../../common/components/typography';
import Markdown from '../markdown';
import Img from '@mindoktor/pulse/src/utils/images/Img.web';

const styles = {
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    minHeight: 60,
    paddingLeft: 20,
    cursor: 'pointer',
  },

  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 60,
    height: 60,
  },

  halo: {
    position: 'absolute',
    backgroundColor: `${colors.red_500}20`,
    borderRadius: 25,
    width: 50,
    height: 50,
  },

  checkbox: {
    width: 30,
    height: 30,
  },

  labelContainer: {
    marginLeft: 10,
    display: 'flex',
    flexDirection: 'row',
    paddingRight: 40,
    paddingTop: 18,
    paddingBottom: 18,
    flex: 1,
  },

  label: {
    flex: 1,
    color: colors.off_black,
  },

  underlayStyle: {
    borderRadius: 25,
    marginLeft: -12,
  },

  disabled: {
    color: colors.grey_500,
  },
};

// type CheckboxProps = {
//   checked?: boolean,
//   multiple?: boolean,
// };

export class Checkbox extends Component {
  state = {
    pressed: false,
  };

  onMouseDown = () => {
    if (!this.props.disabled) this.setState({ pressed: true });
  };

  onMouseUp = () => {
    if (!this.props.disabled) this.setState({ pressed: false });
  };

  onMouseLeave = () => {
    if (!this.props.disabled) this.setState({ pressed: false });
  };

  render() {
    const { checked, multiple } = this.props;
    const { pressed } = this.state;

    let source = multiple ? checkboxUnchecked : radioUnchecked;

    if (checked) {
      source = multiple ? checkboxChecked : radioChecked;
    }

    return (
      <div
        onMouseDown={this.onMouseDown}
        onMouseUp={this.onMouseUp}
        onMouseLeave={this.onMouseLeave}
        style={styles.imageContainer}
      >
        <div style={{ ...styles.halo, opacity: pressed ? 1 : 0 }} />

        <Img style={styles.checkbox} src={source} />
      </div>
    );
  }
}

// type Props = {
//   checked?: boolean,
//   multiple?: boolean,
//   lastItem?: boolean,

//   label: string,
//   labelStyle?: any,

//   onChange?: () => any,

//   disabled?: boolean,
//   withMarkdown?: boolean,
// };

export default class Option extends Component {
  onClick = () => {
    if (!this.props.disabled) this.props.onChange();
  };

  render() {
    const {
      checked,
      multiple,
      lastItem,
      label,
      labelStyle,
      disabled,
      withMarkdown,
    } = this.props;

    return (
      <div
        style={styles.underlayStyle}
        onClick={this.onClick}
        data-test-id="input-option"
        data-test-checked={checked}
      >
        <div style={styles.container}>
          <Checkbox checked={checked} multiple={multiple} />
          <div
            style={{
              ...styles.labelContainer,
              borderBottom: !lastItem
                ? `1px solid ${colors.grey_200}`
                : undefined,
            }}
          >
            <Body1
              style={{
                ...styles.label,
                ...labelStyle,
                ...(!multiple && checked
                  ? { color: colors.red_500 }
                  : undefined),
                ...(disabled && styles.disabled),
              }}
            >
              {withMarkdown && <Markdown source={label} />}
              {!withMarkdown && <>{label}</>}
            </Body1>
          </div>
        </div>
      </div>
    );
  }
}
