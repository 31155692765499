import { _t } from '@mindoktor/patient-legacy/i18n';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import colors from '../../../common/colors';
import { Body3 } from '../../../common/components/typography';
import { saveHealthProfile } from '../../../state/healthprofile/actions';
import { getHealthProfileFormularyState } from '../../../state/healthprofile/selectors';
import ContinueButton from '../../components/button/continue';

const styles = {
  root: {
    marginBottom: 20,
    marginLeft: 20,
    marginRight: 20,
  },

  footer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  error: {
    color: colors.error_text,
  },
};

// type Props = {
//   childUuid?: string,

//   errorCount: number,

//   next: () => any,

//   saveHealthProfile: typeof saveHealthProfile,
// };

export class SummaryFooter extends Component {
  state = {
    submitting: false,
  };

  submit = async () => {
    const { childUuid, next, saveHealthProfile } = this.props;

    this.setState({ submitting: true });

    const { error } = await saveHealthProfile({ childUuid });

    if (!error) {
      next();
    }

    this.setState({ submitting: false });
  };

  render() {
    const { errorCount } = this.props;
    const { submitting } = this.state;

    return (
      <div style={styles.root}>
        <div
          style={{
            ...styles.footer,
            justifyContent: errorCount ? 'space-between' : 'flex-end',
          }}
        >
          {errorCount > 0 && (
            <div>
              <Body3 style={{ ...styles.error, fontWeight: 'bold' }}>
                {errorCount > 1
                  ? _t('healthProfile.errors.countMultiple', {
                      count: errorCount,
                    })
                  : _t('healthProfile.errors.countOne')}
              </Body3>

              <Body3 style={styles.error}>
                {_t('healthProfile.errors.suffix')}
              </Body3>
            </div>
          )}

          <ContinueButton
            disabled={!!errorCount || submitting}
            text={_t('healthProfile.summary.confirm')}
            onClick={this.submit}
          />
        </div>
      </div>
    );
  }
}

export default connect(
  (state, props) => {
    const { childUuid } = props;

    const { errors } = getHealthProfileFormularyState(state, childUuid) || {};

    return {
      childUuid,

      errorCount: errors ? Object.keys(errors).length : 0,
    };
  },
  {
    saveHealthProfile,
  }
)(SummaryFooter);
