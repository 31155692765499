import withStyles from '@material-ui/core/styles/withStyles';
import { _t } from '@mindoktor/patient-legacy/i18n';
import React from 'react';
import { connect } from 'react-redux';
import compose from '@mindoktor/patient-legacy/src/common/compose';
import { withRouting } from '@mindoktor/patient-app/routing/HOC/withRouting.web';
import { routes } from '@mindoktor/patient-app/routing/constants/webRoutes';

import {
  createInquisitor,
  setInquisitor,
} from '../../state/inquisitions/inquisitor';
import { parseInquisition } from '../../state/inquisitions/utils';
import { openRoute, routes as legacyRoutes } from '../../state/routing';
import {
  answerQuestion,
  fetchInquisitionJson,
  goToNextQuestion,
  goToPreviousQuestion,
} from '../../state/usersurveys/actions';
import {
  getDraft,
  getPatient,
  getResult,
  getValue,
  isFirst,
} from '../../state/usersurveys/selectors';
import Body from '../inquisitions/components/Body';
import Close from '../inquisitions/components/Close';
import FadeTransition from '../inquisitions/components/FadeTransition';
import Frame from '../inquisitions/components/Frame';
import Header from '../inquisitions/components/Header';
import Logo from '../inquisitions/components/Logo';
import PreviousLink from '../inquisitions/components/PreviousLink';
import ScrollIndicator from '../inquisitions/components/ScrollIndicator';
import Question from '../inquisitions/question/Question';
import CancelModal from './components/CancelModal';

// type Props = {
//   surveyName?: string,
//   patient?: Patient,
//   inquisitor?: InquisitorType,
//   value?: *,
//   result?: Result,
//   first?: boolean,
//   openRoute: typeof openRoute,
//   answerQuestion: typeof answerQuestion,
//   goToPreviousQuestion: typeof goToPreviousQuestion,
//   goToNextQuestion: typeof goToNextQuestion,
// };

// type State = {
//   loaded: boolean,
//   cancelModal: boolean,
//   inquisitor: InquisitorType | null,
// };

// Fix for React.createRef() misbehaving for flow
// type RefObject = {
//   current: any,
// };

export class UserSurveyScreen extends React.Component {
  state = {
    loaded: false,
    cancelModal: false,
    inquisitor: null,
  };

  transitionRef = React.createRef();
  transition = async () =>
    this.transitionRef.current && this.transitionRef.current.transition();

  openCancelModal = () => this.setState({ cancelModal: true });
  closeCancelModal = () => this.setState({ cancelModal: false });

  cancel = () => {
    const {
      routing: { navigation },
    } = this.props;

    navigation.navigate(routes.CASES);
  };

  async loadSurvey() {
    const { surveyName, patient, draft } = this.props;

    const { json, error } = await fetchInquisitionJson(surveyName);

    if (!json || error) {
      // Inquisition json failed to load
      return { survey: undefined, error: true };
    }

    // This would be replaced with an api call to the json file
    const inquisition = parseInquisition(json);

    if (!patient) {
      // Patient isn't logged in
      return { survey: undefined, error: true };
    }

    const inquisitor = createInquisitor(inquisition, patient);

    const survey = await inquisitor.start(draft || {});

    setInquisitor(surveyName, inquisitor);

    this.setState({ inquisitor, loaded: true });

    return { survey, error: false };
  }

  update = async (value) => {
    const { surveyName, answerQuestion } = this.props;

    answerQuestion(surveyName, value);
  };

  previous = async () => {
    const { first, goToPreviousQuestion, surveyName, openRoute } = this.props;

    if (first) {
      openRoute(legacyRoutes.USER_SURVEY_INTRO, { surveyName });
    } else {
      await this.transition();

      goToPreviousQuestion(surveyName);
    }
  };

  done = async (value) => {
    const { goToNextQuestion, surveyName } = this.props;
    const { inquisitor } = this.state;

    if (value !== undefined) {
      this.update(value);
    }

    await this.transition();

    if (inquisitor) {
      goToNextQuestion(surveyName);
    }
  };

  redirect = ({ abort }) => {
    const { openRoute, surveyName } = this.props;

    openRoute(
      abort
        ? legacyRoutes.USER_SURVEY_INTRO
        : legacyRoutes.USER_SURVEY_COMPLETE,
      {
        surveyName,
      }
    );
  };

  UNSAFE_componentWillReceiveProps({ result }) {
    const { result: prevResult } = this.props;

    if (!prevResult && result) {
      this.redirect(result);
    }
  }

  componentDidMount() {
    const { result } = this.props;

    this.loadSurvey();

    if (result) {
      this.redirect(result);
    }
  }

  render() {
    const { cancelModal, loaded, inquisitor } = this.state;

    const { classes, value } = this.props;

    if (!loaded || !inquisitor) {
      return null;
    }

    const question = inquisitor.getQuestion();

    if (!question) {
      return null;
    }

    const frame = () => (
      <Frame>
        <Logo className={classes.logo} />

        <Question
          key={question.ref}
          value={value}
          question={question}
          update={this.update}
          done={this.done}
        />

        <ScrollIndicator />
      </Frame>
    );

    const previousLink = () => (
      <PreviousLink
        label={_t('guides.previousQuestion')}
        onClick={this.previous}
      />
    );

    return (
      <>
        <Body>
          <Header>
            {previousLink()}

            <Close
              onClick={this.openCancelModal}
              className={classes.cancelFixed}
            />
          </Header>

          <FadeTransition ref={this.transitionRef}>{frame()}</FadeTransition>
        </Body>

        <CancelModal
          open={cancelModal}
          close={this.closeCancelModal}
          cancel={this.cancel}
        />
      </>
    );
  }
}

const enhance = compose(
  withRouting,
  connect(
    (state, props) => {
      const { surveyName } = props;
      const patient = getPatient(state);

      return {
        ...props,
        ...(surveyName && {
          draft: getDraft(state, surveyName),
          value: getValue(state, surveyName),
          first: isFirst(state, surveyName),
          result: getResult(state, surveyName),
        }),
        patient,
      };
    },
    {
      goToPreviousQuestion,
      goToNextQuestion,
      answerQuestion,
      openRoute,
    }
  ),
  withStyles(({ spacing }) => ({
    cancelFixed: {
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
    },

    logo: {
      marginBottom: spacing(3),
    },
  }))
);

export default enhance(UserSurveyScreen);
