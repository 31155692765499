import { _t } from '@mindoktor/patient-legacy/i18n';
import React, { Component } from 'react';

import logoDynamicCode from '../../../../images/dynamiccode/dynamiccode_logo.png';
import { Body1 } from '../../../common/components/typography';
import { CardWithBody } from '../../components/card';
import TextInput from '../../components/input/text';
import Markdown from '../../components/markdown';
import Img from '@mindoktor/pulse/src/utils/images/Img.web';

const styles = {
  logo: { display: 'block', height: 267 / 6, width: 1240 / 6 },
  info: {
    paddingTop: 20,
  },

  section: {
    display: 'block',
    paddingTop: 20,
  },

  label: {
    fontWeight: 500,
    marginBottom: 5,
  },
};

// type Props = {
//   initialCode?: string,

//   onCodeChange?: string => any,

//   style?: any,
// };

export default class DynamicCodeCard extends Component {
  render() {
    const { initialCode, onCodeChange, style } = this.props;

    return (
      <CardWithBody color={'#d63327'} style={style}>
        <Img style={styles.logo} src={logoDynamicCode} />

        <Markdown
          source={
            initialCode
              ? _t('guides.dynamicCode.leadWithCode', { code: initialCode })
              : _t('guides.dynamicCode.lead')
          }
          style={styles.info}
        />

        {!initialCode && (
          <label style={styles.section}>
            <Body1 style={styles.label}>{_t('guides.dynamicCode.label')}</Body1>

            <TextInput
              placeholder={_t('guides.dynamicCode.placeholder')}
              onChange={onCodeChange}
            />
          </label>
        )}
      </CardWithBody>
    );
  }
}
