import React from 'react';
import Switch from 'react-ios-switch';

import colors from '../../../common/colors';
import { Body1, SubHead } from '../../../common/components/typography';

const styles = {
  root: {
    marginBottom: 8,
  },

  row: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

interface Props {
  description: string;
  name: string;
  value: boolean;
  setValue: (value: boolean) => void;
  disabled?: boolean;
}

const Setting: React.FC<Props> = ({
  description,
  name,
  value,
  setValue,
  disabled,
}) => {
  return (
    <div style={styles.root}>
      <div style={styles.row}>
        <SubHead>{name}</SubHead>
        <Switch
          checked={value}
          disabled={disabled}
          onChange={setValue}
          onColor={colors.red_500}
        />
      </div>
      <Body1>{description}</Body1>
    </div>
  );
};

export default Setting;
