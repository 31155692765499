import withStyles from '@material-ui/core/styles/withStyles';
import { _t } from '@mindoktor/patient-legacy/i18n';
import React from 'react';
import { connect } from 'react-redux';
import compose from '@mindoktor/patient-legacy/src/common/compose';
import { withRouting } from '@mindoktor/patient-app/routing/HOC/withRouting.web';
import { routes } from '@mindoktor/patient-app/routing/constants/webRoutes';

import { Body1, Title1 } from '../../common/components/typography';
import { postSurvey } from '../../state/usersurveys/actions';
import { getResult } from '../../state/usersurveys/selectors';
import Button from '../components/button/button';
import Body from '../inquisitions/components/Body';
import FadeTransition from '../inquisitions/components/FadeTransition';
import Frame from '../inquisitions/components/Frame';
import Header from '../inquisitions/components/Header';
import Logo from '../inquisitions/components/Logo';

// type Props = {
//   surveyName: string,
//   result?: Result,
//   getResult: typeof getResult,
//   openRoute: typeof openRoute,
//   postSurvey: typeof postSurvey,
// };
class IntroScreen extends React.Component {
  completeSurvey = async () => {
    const {
      surveyName,
      postSurvey,
      result,
      routing: { navigation },
    } = this.props;

    if (result) {
      await postSurvey(surveyName, result);
    }

    navigation.navigate(routes.CASES);
  };

  render() {
    const { classes } = this.props;

    return (
      <Body>
        <Header />

        <FadeTransition ref={this.transitionRef}>
          <Frame>
            <Logo className={classes.logo} />

            <Title1 className={classes.title}>
              {_t('usersurvey.completeTitle')}
            </Title1>

            <Body1 className={classes.body}>
              {_t('usersurvey.completeText')}
            </Body1>

            <div>
              <Button
                className={classes.completeButton}
                type="solid"
                color="red"
                onClick={this.completeSurvey}
              >
                {_t('common.done')}
              </Button>
            </div>
          </Frame>
        </FadeTransition>
      </Body>
    );
  }
}

const enhance = compose(
  withRouting,
  connect(
    (state, props) => {
      const { surveyName } = props;

      return {
        ...props,
        result: surveyName && getResult(state, surveyName),
      };
    },
    {
      postSurvey,
    }
  ),
  withStyles(({ spacing }) => ({
    title: {
      marginBottom: spacing(2),
    },

    body: {
      marginBottom: spacing(2),
    },

    logo: {
      marginBottom: spacing(3),
    },
  }))
);

export default enhance(IntroScreen);
