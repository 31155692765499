/**
 * Changes the pointer (visited prop) to the previous question for
 * an inquisition
 *
 * Note: To be used in an action primarily
 *
 * @param {Draft} draft The in progress inquisition
 * @param {Inquisitor} inquisitor The instance of the inquisitor used
 *
 * @returns {Draft} The updated in progress inquisition
 */
export const previousQuestion = async (draft, inquisitor) => {
  if (!inquisitor) {
    throw new Error('no inquisitor');
  }

  const { visited = [], values = {} } = draft;

  if (!visited.length) {
    return draft;
  }

  return await inquisitor.start({
    visited: visited.slice(0, visited.length - 1),
    values,
  });
};

/**
 * Changes the pointer (visited prop) to the next question for
 * an inquisition, and appends the value to the values array
 *
 * Note: To be used in an action primarily
 *
 * @param {Draft} draft The in progress inquisition
 * @param {Inquisitor} inquisitor The instance of the inquisitor used
 *
 * @returns {Draft} The updated in progress inquisition
 */
export const nextQuestion = async (draft, inquisitor) => {
  if (!inquisitor) {
    throw new Error('no inquisitor');
  }

  const question = inquisitor.getQuestion();

  if (!question) {
    throw new Error('no question');
  }

  const { visited = [], values = {} } = draft;

  const value = values[question.ref];

  if (value === undefined) {
    throw new Error('no value');
  }

  await inquisitor.push(value);

  return { visited: [...visited, question.ref], values };
};
