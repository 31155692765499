// Exporting actions types separately from actions.js
// The plan is to move these to Flow and add typing to all Actions.

export const CASE_VIEW = 'CASE_VIEW';
export const CASE_PAYMENT_DONE = 'CASE_PAYMENT_DONE';
export const CASE_CREATED = 'CASE_CREATED';
export const CASES_RECEIVED = 'CASES_RECEIVED';
export const CASES_REQUEST = 'CASES_REQUEST';
export const CASES_REQUEST_ERROR = 'CASES_REQUEST_ERROR';
export const MESSAGES_RECEIVED = 'MESSAGES_RECEIVED';
export const MESSAGES_REQUEST = 'MESSAGES_REQUEST';
export const MESSAGES_REQUEST_ERROR = 'MESSAGES_REQUEST_ERROR';
export const MESSAGE_POST = 'MESSAGE_POST';
export const MESSAGE_POST_SUCCESS = 'MESSAGE_POST_SUCCESS';
export const MESSAGE_POST_ERROR = 'MESSAGE_POST_ERROR';
export const MESSAGE_INCOMING = 'MESSAGE_INCOMING';
export const CASE_VIDEOCALL_URL_RECEIVED = 'CASE_VIDEOCALL_URL_RECEIVED';
export const CASE_VIDEOCALL_ACCEPT = 'CASE_VIDEOCALL_ACCEPT';
export const CASE_VIDEOCALL_RESCHEDULE = 'CASE_VIDEOCALL_RESCHEDULE';
export const CASE_GUIDE_SUMMARY_SHOWN = 'CASE_GUIDE_SUMMARY_SHOWN';
export const GUIDE_SUMMARY_VIEW_ONLY_ITEM_CLICKED =
  'GUIDE_SUMMARY_VIEW_ONLY_ITEM_CLICKED';
export const CASE_CANCEL_REQUEST = 'CASE_CANCEL_REQUEST';
export const CASE_CANCEL_REQUEST_SUCCESS = 'CASE_CANCEL_REQUEST_SUCCESS';
export const CASE_CANCEL_REQUEST_ERROR = 'CASE_CANCEL_REQUEST_ERROR';
export const UNREAD_MESSAGES = 'UNREAD_MESSAGES';
export const CASE_ANSWERS_REQUEST = 'CASE_ANSWERS_REQUEST';
export const CASE_ANSWERS_REQUEST_ERROR = 'CASE_ANSWERS_REQUEST_ERROR';
export const CASE_ANSWERS_REQUEST_SUCCESS = 'CASE_ANSWERS_REQUEST_SUCCESS';
