import config from '@mindoktor/env/Config';
import { _t } from '@mindoktor/patient-legacy/i18n';
import dayjs from 'dayjs';
import React from 'react';

import colors from '../../colors';
import { Body3, SubHead } from '../typography';
import spacing from '../../spacing';

const styles = {
  root: {
    borderTop: `1px solid ${colors.grey_300}`,
    paddingTop: spacing.xt,
  },
  header: {
    textAlign: 'center',
    marginBottom: 0,
  },
  subHead: {
    textAlign: 'center',
  },
};

// type Props = {
//   fullName: string,
//   birthDate: string,
// };

export default (props) => {
  const { fullName, birthDate } = props;

  return (
    <div style={{ ...styles.root }}>
      <Body3 mb="t" mt="t" style={styles.header}>
        {_t('userInfo.journalizedFor')}
      </Body3>
      <SubHead mb="s" style={styles.subHead}>
        {fullName}{' '}
        {config.ShowBirthDate && (
          <span style={{ color: colors.grey_500 }}>
            {dayjs(birthDate).format('YYYYMMDD')}
          </span>
        )}
      </SubHead>
    </div>
  );
};
