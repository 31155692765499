export const HOME_UPDATE_MODAL_ACTIVE = 'HOME_UPDATE_MODAL_ACTIVE';

// export type HomeState = {
//   +activeTab: number,
//   +modalActive: boolean,
// };

// export type UpdateModal = {
//   type: typeof HOME_UPDATE_MODAL_ACTIVE,
//   payload: boolean,
// };

// export type HomeAction = UpdateModal;
