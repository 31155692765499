import { _t } from '@mindoktor/patient-legacy/i18n';
import React from 'react';

import colors from '../../../common/colors';
import { Body1, Title1 } from '../../../common/components/typography';
import TruncatedFooter from '../../components/footer/truncated';
import MediaQuery from '../../components/media_query';
import Screen from '../../components/screen';
import MenuBar from '../../menu/bar';

const styles = {
  body: {
    display: 'flex',
    flexDirection: 'column',
    padding: 16,
    minHeight: 'inherit',
    background: colors.grey_100,
  },
  message: {
    marginBottom: 16,
    padding: 16,
    backgroundColor: colors.white,
    maxWidth: 400,
    alignSelf: 'center',
    color: colors.off_black,
  },
  title: {
    marginBottom: 24,
  },
  bodytext: {
    marginBottom: 16,
  },
} as const;

const SurveyCompletedScreen: React.FC = () => (
  <Screen
    header={<MenuBar />}
    body={
      <div style={styles.body}>
        <div style={styles.message}>
          <Title1 style={styles.title}>{_t('followup.thanks')}</Title1>
          <Body1 style={styles.bodytext}>{_t('followup.thanks.body')}</Body1>
          <Body1>{_t('followup.thanks.greets')}</Body1>
          <Body1>{_t('followup.thanks.staff')}</Body1>
        </div>
      </div>
    }
    footer={
      <MediaQuery minWidth={801}>
        <TruncatedFooter />
      </MediaQuery>
    }
    showScrollGradient={true}
  />
);

export default SurveyCompletedScreen;
