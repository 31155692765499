import { _t } from '@mindoktor/patient-legacy/i18n';
import React from 'react';
import { connect } from 'react-redux';

import colors from '../../../colors';
import Image from '../../../components/primitives/Image';
import View from '../../../components/primitives/View';
import { Body1, Body3, Intro } from '../../../components/typography';
import ItemTemplate from '../ItemTemplate';
import { cameraIcon, fontWeightMedium } from './specified';

// type Props = {
//   timestamp: string,
// };

const style = {
  top: {
    display: 'flex',
    flexDirection: 'row',
  },
  icon: {
    width: 44,
    height: 44,
  },
  header: {
    flex: 1,
    paddingTop: 10,
    fontWeight: fontWeightMedium,
  },
  time: {
    marginLeft: 16,
    color: colors.grey_500,
  },
  body: {
    marginTop: 10,
    marginBottom: 4,
  },
};

class WrongAttachment extends React.Component {
  render() {
    const { timestamp } = this.props;

    return (
      <ItemTemplate flipVertically>
        <View style={style.top}>
          <Image source={cameraIcon} style={style.icon} mr="t" />
          <Intro style={style.header} mb="t">
            {_t('case.wrongAttachment.header')}
          </Intro>
          <Body3 style={style.time}>{timestamp}</Body3>
        </View>
        <Body1 style={style.body} mb="s">
          {_t('case.wrongAttachment.body')}
        </Body1>
      </ItemTemplate>
    );
  }
}

export default connect(undefined, {})(WrongAttachment);
