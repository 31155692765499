import { MdClose } from './docly-md-icons';
import React from 'react';

import { hideModal } from '../routing';
import IconButton from './button/icon';
import MediaQuery from './media_query';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
  },

  image: {
    maxWidth: '100%',
    maxHeight: '100%',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.18)',
  },

  imageWide: {
    maxWidth: '90%',
    maxHeight: '90%',
  },

  close: {
    position: 'fixed',
    top: 20,
    right: 20,
  },
};
export default class Modal extends React.Component {
  stopPropagation = (event) => event && event.stopPropagation();

  render() {
    const { src } = this.props;

    return (
      <div style={{ ...styles.root }} onClick={hideModal}>
        <MediaQuery maxWidth={800}>
          <img src={src} style={styles.image} onClick={this.stopPropagation} />

          <IconButton
            color="black"
            size={50}
            iconComponent={MdClose}
            style={styles.close}
            onClick={hideModal}
          />
        </MediaQuery>

        <MediaQuery minWidth={801}>
          <img
            src={src}
            style={{ ...styles.image, ...styles.imageWide }}
            onClick={this.stopPropagation}
          />

          <IconButton
            color="black"
            size={60}
            iconComponent={MdClose}
            style={styles.close}
            onClick={hideModal}
          />
        </MediaQuery>
      </div>
    );
  }
}
