import { _t } from '@mindoktor/patient-legacy/i18n';
import React from 'react';

import Button from '../../../../components/Button';
import Modal from '../../../../components/Modal';
import Image from '../../../../components/primitives/Image';
import View from '../../../../components/primitives/View';
import { Body1, Title2 } from '../../../../components/typography';
import Illustration from './Illustration';

const style = {
  root: {
    display: 'flex',
    width: 'auto',
    flexDirection: 'column',
  },
  image: {
    alignSelf: 'stretch',
    width: 'auto',
  },
};

class MinDoktorKlinikenModal extends React.Component {
  render() {
    return (
      <Modal>
        <View style={style.root}>
          <Image mb="s" source={Illustration} style={style.image} />
          <Title2 mb="s">{_t('referral.mdkModal.title')}</Title2>
          <Body1 mb="s">{_t('referral.mdkModal.body')}</Body1>
          <Button onPress={this.props.hideModal}>
            {_t('referral.mdkModal.CTA')}
          </Button>
        </View>
      </Modal>
    );
  }
}

export default MinDoktorKlinikenModal;
