import { z } from 'zod';

export const ConsentIDDict = {
  V1MarketingTracking: 'v1-marketing-tracking',
  V1MarketingCommunication: 'v1-marketing-communication',
} as const;

export const ConsentIDSchema = z.nativeEnum(ConsentIDDict);

/** Used for action log. */
export const ActionDict = {
  ActionViewed: 'viewed',
} as const;

export const ActionDictSchema = z.nativeEnum(ActionDict);

export const MarketingConsentSchema = z.object({
  id: ConsentIDSchema,
  label: z.string(),
  description: z.string(),
  enabled: z.boolean(),
  shouldAsk: z.boolean(),
});

export const MarketingConsentResponseSchema = z.object({
  data: z.object({
    consents: z.array(MarketingConsentSchema),
  }),
});
