import { USER_SURVEY_UPDATE } from './types';

const initialState = {
  draftById: [],
};

export default (state = initialState, action) => {
  const { type, payload = {} } = action;

  switch (type) {
    case USER_SURVEY_UPDATE: {
      const { id, draft } = payload;
      const { visited, values } = draft;

      // If draft.options is undefined, use the previous options instead
      // TODO: Create separate INIT_DRAFT action to handle this
      const options =
        draft.options || (state.draftById[id] && state.draftById[id].options);

      return {
        ...state,
        draftById: {
          ...state.draftById,
          [id]: {
            visited,
            values,
            options,
          },
        },
      };
    }

    default:
      return state;
  }
};
