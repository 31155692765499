import React, { FC, useEffect, useState } from 'react';
import { Transition } from 'react-transition-group';
import { useAppDispatch, useAppSelector } from '../../../state/hooks';
import { hideSnackbar } from '../../../state/snackbar/actions';
import MediaQuery from '../media_query';

import colors from '../../../common/colors';

import { Body3 } from '../../../common/components/typography';
import {
  SnackbarType,
  SnackbarTypeKeys as SnackbarTypeKey,
} from '../../../state/snackbar/types';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/styles';

type SnackbarPosition = 'top' | 'bottom';

type ThemeWithSnackbarProps = Theme & {
  props: {
    position: SnackbarPosition;
  };
};

const transitions = (position: SnackbarPosition) => ({
  entering: {
    display: 'block',
  },
  entered: {
    [position]: 0,
    display: 'block',
  },
  exiting: {
    [position]: 0,
    display: 'block',
  },
  exited: {
    [position]: -50,
    display: 'block',
  },
  unmounted: {
    [position]: -50,
    display: 'none',
  },
});

const useStyles = makeStyles<ThemeWithSnackbarProps>(
  ({ props: { position } }) => ({
    root: {
      padding: '15px 20px',
      textAlign: 'center',
      transition: 'all 0.3s ease-in-out',
      position: 'fixed',
      left: 0,
      right: 0,
      [position]: 0,
      zIndex: 2,
    },
    [SnackbarType.SUCCESS]: {
      backgroundColor: colors.green_500,
      // For some reason, when using makeStyles, MUI overrides the font color
      color: colors.white,
    },
    [SnackbarType.WARNING]: {
      backgroundColor: colors.yellow_500,
    },
  })
);

const textColors: Record<SnackbarTypeKey, { color: any }> = {
  [SnackbarType.SUCCESS]: {
    color: colors.white,
  },
  [SnackbarType.WARNING]: {
    color: colors.black,
  },
};

interface Props {
  text: string;
  type?: SnackbarTypeKey;
  timeout?: number;
  onComplete?: () => void;
  position?: SnackbarPosition;
}

const Snackbar: FC<Props> = ({
  text = '',
  type = SnackbarType.SUCCESS,
  position = 'bottom',
  timeout = 5000,
  onComplete,
}) => {
  const classes = useStyles(position);
  const transitionStyle = transitions(position);
  const [transition, setTransition] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setTransition(false);
      if (onComplete) {
        setTimeout(() => onComplete(), 800);
      }
    }, timeout);
  }, []);

  return (
    <Transition in={transition} timeout={300}>
      {(state) => {
        return (
          <Body3
            className={`${classes.root} ${classes[type]}`}
            style={{ ...transitionStyle[state], ...textColors[type] }}
          >
            {text}
          </Body3>
        );
      }}
    </Transition>
  );
};

const SnackbarWrapper: FC = () => {
  const snackbar = useAppSelector((state) => state.snackbar);
  const dispatch = useAppDispatch();
  const theme = useTheme<Theme>();

  const onComplete = () => {
    dispatch(hideSnackbar());
  };

  if (!snackbar) return null;

  return (
    <>
      <MediaQuery key="narrow" maxWidth={theme.breakpoints.values.sm}>
        <Snackbar
          text={snackbar.text}
          type={snackbar.type}
          onComplete={onComplete}
          position={'top'}
        />
      </MediaQuery>
      <MediaQuery key="wide" minWidth={theme.breakpoints.values.sm}>
        <Snackbar
          text={snackbar.text}
          type={snackbar.type}
          onComplete={onComplete}
          position={'bottom'}
        />
      </MediaQuery>
    </>
  );
};

export default SnackbarWrapper;
