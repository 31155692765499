import { _t } from '@mindoktor/patient-legacy/i18n';
import React from 'react';

import colors from '../../../common/colors';
import { getSiteOptions } from '../../utils/site';
import Markdown from '../markdown';

const styles = {
  root: {
    padding: 20,
    backgroundColor: colors.grey_100,
    textAlign: 'center',
  },
};

const GuidesPlug = () =>
  getSiteOptions().showGuidesPlug ? (
    <Markdown source={_t('company.guidesPlug')} style={styles.root} />
  ) : null;

export default GuidesPlug;
