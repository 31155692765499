import { z } from 'zod';

const postalCodePattern = /^[1-9][0-9]{4}$/;

// A more thorough validation will be made in BE. This is very crude.
export const phonePattern = /^\+?[0-9\s-]{8,12}$/g;

const UserFinalizeRegistrationBaseSchema = z.object({
  address: z.string().min(1, { message: 'error.required' }),
  city: z.string().min(1, { message: 'error.required' }),
  postalCode: z
    .string()
    .min(1, { message: 'error.required' })
    .regex(postalCodePattern, { message: 'error.postalCode' }),
});

export const UserFinalizeRegistrationSchema =
  UserFinalizeRegistrationBaseSchema.extend({
    email: z
      .string()
      .min(1, { message: 'error.required' })
      .email({ message: 'error.email' }),
    telephone: z
      .string()
      .min(1, { message: 'error.required' })
      .regex(phonePattern, { message: 'error.telephone' }),
  });

export const UserFinalizeRegistrationProtectedSchema =
  UserFinalizeRegistrationBaseSchema.extend({
    // email and phone fields is optional for protected users but validated if provided
    email: z.string().refine(
      (data) => {
        // Check if the data is a valid email or an empty string
        return data === '' || z.string().email().safeParse(data).success;
      },
      { message: 'error.email' }
    ),
    telephone: z.string().refine(
      (data) => {
        // Check if the data is a valid phone number or an empty string
        return (
          data === '' || z.string().regex(phonePattern).safeParse(data).success
        );
      },
      { message: 'error.telephone' }
    ),
  });
