import { getApi, postApi } from '../api/actions';
import { freePassStatuses, FREE_PASS_ERROR, RECEIVE_FREE_PASS } from './types';

export function fetchFreePass() {
  return async (dispatch) => {
    const { json, error } = await dispatch(getApi('/api/v1/user/freepasscard'));

    if (error) {
      console.warn('ERROR.fetchFreePass: ', error);
      return false;
    }

    const { number, issuer, expires, editable, origin } = json;

    dispatch({
      type: RECEIVE_FREE_PASS,
      payload: {
        number,
        issuer,
        expires,
        editable,
        origin,
        // WORKAROUND:
        // We set this value here, as we know from our implementation
        // that if we return something here, the status would be this.
        // We should refactor this at some point in the future.
        status: freePassStatuses.FINAL,
      },
    });

    return true;
  };
}

export function updateFreePass() {
  return async (dispatch) => {
    const { json, error } = await dispatch(
      postApi('/api/v1/user/freepasscards')
    );

    if (error) {
      console.warn('ERROR.updateFreePass: ', error);
      return false;
    }

    const { number, issuer, expires, editable, origin, status, amountToLimit } =
      json;

    // we are only interested in valid freepasses
    dispatch({
      type: RECEIVE_FREE_PASS,
      payload: {
        number,
        issuer,
        expires,
        editable,
        origin,
        status,
        amountToLimit,
      },
    });

    return true;
  };
}

// type Params = {
//   number: string,
//   issuer: string,
//   expires: string,
//   type?: FreePassType,
// };

export function submitFreePass({ number, issuer, expires, type }) {
  return async (dispatch) => {
    const { error, message } = await dispatch(
      postApi('/api/v1/user/freepasscard', {
        number,
        issuer,
        expires,
        freepassType: type,
      })
    );

    if (error) {
      dispatch({
        type: FREE_PASS_ERROR,
        payload: {
          hasError: error,
          errorMessage: message,
        },
      });
      return false;
    }

    dispatch({
      type: RECEIVE_FREE_PASS,
      payload: {
        number,
        issuer,
        expires,
        // The api reported no errors so we can safely assume this is in status
        // final as all valid freepasses are. Plus, we need status to be set
        // after saving a freepass since we look at that to decide screen in
        // payment/cart.
        status: freePassStatuses.FINAL,
      },
    });

    return true;
  };
}
