import { legacyRoutes } from '@mindoktor/patient-app/adapters/routes/legacyRoutes.web';
import { routes } from '@mindoktor/patient-app/routing/web';

export const pageViewRoutesWithExtraData = new Set([
  routes.LOGIN,
  routes.TOS_PRIVACY,
  routes.REGISTER,
  legacyRoutes.GUIDES,
  legacyRoutes.GUIDES_QUESTIONS,
  legacyRoutes.GUIDES_QUESTIONS_EDIT,
  legacyRoutes.GUIDES_PRESTART,
  legacyRoutes.CONTACT_INFO_VERIFICATION_GUIDE_COMPLETED_ALREADY_VERIFIED,
  legacyRoutes.CONTACT_INFO_VERIFICATION_GUIDE_CONFIRMATION,
  legacyRoutes.CASE_MESSAGES,
  legacyRoutes.CHILDREN,
  legacyRoutes.CHILDREN_ADD,
  legacyRoutes.CHILDREN_UNDER_SIX_MONTHS,
]);
