import React from 'react';

import { nativeToWeb } from '../../../styleUtils';

const pText = ({
  style,
  numberOfLines, // eslint-disable-line no-unused-vars
  ellipsizeMode, // eslint-disable-line no-unused-vars
  onPress,
  onClick,
  onMouseEnter,
  onMouseLeave,
  color,
  fontWeight,
  children,
  className,
}) => {
  const propStyle = (color || fontWeight) && {
    color,
    fontWeight,
  };

  const webStyle = style || propStyle ? nativeToWeb([propStyle, style]) : {};

  return (
    <div
      onClick={onPress || onClick}
      style={webStyle}
      children={children}
      className={className}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    />
  );
};

export default pText;
