import React from 'react';

// type Props = { children: ({ width: number, height: number }) => React.Node };

// type State = { width: number, height: number, media: 'mobile' | 'desktop' };

// todo: move to constants file somewhere
// constants {
const breakpoints = {
  mobile: { maxWidth: 800 },
  desktop: { minWidth: 801 },
};
// } constants

export default class ViewportDimensions extends React.Component {
  dimensions = () => ({
    width: window.innerWidth,
    height: window.innerHeight,
    media:
      window.innerWidth > breakpoints.mobile.maxWidth ? 'desktop' : 'mobile',
  });

  state = this.dimensions();

  onResize = () => this.setState(this.dimensions());

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
  }

  render() {
    const {
      props: { children },
      state: { height, width, media },
    } = this;
    return children({ width, height, media });
  }
}
