/**
 * Formats a standard landline number for display purposes.
 *
 * @example formatPhone('0760121440') // // 0760-12 14 40
 */
const formatPhone = (phone: string | number): string => {
  const stringPhone = `${phone}`.replace(/[^\d]/g, ''); // Remove non-digit chars
  let formattedPhone = '';
  for (let i = 0; i < stringPhone.length; i++) {
    if (i < 4) {
      formattedPhone += stringPhone[i];
    } else if (i === 4) {
      formattedPhone += '-' + stringPhone[i];
    } else if (i > 4) {
      formattedPhone += i % 2 != 0 ? stringPhone[i] : ' ' + stringPhone[i];
    }
  }
  return formattedPhone;
};

export default formatPhone;
