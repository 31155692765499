import React from 'react';

import { Stack, Typography } from '@mui/material';

import aphColors from '@mindoktor/patient-app/apotekhjartat/aphColors';
import APHArticleStock from '@mindoktor/patient-app/apotekhjartat/components/APHArticleStock';
import { useTranslation } from '@mindoktor/patient-app/localization/hooks/useTranslation';

import SystemCardLayout from '../../layout/SystemCardLayout/SystemCardLayout';

import APHFullLogoIcon from './APHFullLogoIcon/APHFullLogoIcon';
import APHHeartIcon from './APHHeartIcon/APHHeartIcon';
import { APHCardProps } from './types';

const APHCard: React.FC<APHCardProps> = ({ message }) => {
  const { drugs, showInfoText } = message.payload;
  const t = useTranslation('messaging', 'aph');

  return (
    <SystemCardLayout
      message={message}
      backgroundColor={aphColors.bright}
      avatarIcon={APHHeartIcon}
    >
      <Stack width="100%" paddingBottom={'0.5rem'} alignItems="center">
        <APHFullLogoIcon fontSize={1.5} />
      </Stack>
      {showInfoText && <Typography>{t('infoText')}</Typography>}

      {drugs.map((drug) => (
        <APHArticleStock article={drug} key={drug.nplPackId} />
      ))}
    </SystemCardLayout>
  );
};

export default APHCard;
