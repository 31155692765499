import { getSessionId } from '@mindoktor/patient-app/api';
import { postApi } from '../api/actions';
import { withForwardSlash } from './utils';

/**
 * This function is helping us track the pages viewed to
 * understand better users' journey and behavior.
 * @param isWebApp needed to create the request body differently for wapp and napp
 * @param options optional parts of request body
 */
const trackPageViewed =
  (
    isWebApp: boolean,
    {
      path = '',
      data = {},
    }: { path: string; data: Record<string, unknown> } = {
      path: '',
      data: {},
    }
  ) =>
  async (dispatch: <T = unknown>(f: (...args: unknown[]) => T) => T) => {
    const eventName = 'page.viewed';
    const createdAt = new Date().toISOString();

    const requestBody = isWebApp
      ? {
          eventName,
          sessionId: getSessionId(),
          referrer: document.referrer,
          createdAt,
          data,
          uri: {
            full: window.location.href,
            schema: window.location.protocol.slice(0, -1),
            host: window.location.hostname,
            path: window.location.pathname,
            query: window.location.search.substr(1),
            fragment: window.location.hash.substr(1),
          },
        }
      : {
          eventName,
          sessionId: getSessionId(),
          referrer: 'mindoktor',
          createdAt,
          data,
          uri: {
            schema: 'mindoktor',
            host: 'mindoktor',
            // some of the napp routes doesn't start with forward slash which
            // contradicts with BE expectations.
            path: withForwardSlash(path),
            full: 'mindoktor:/' + withForwardSlash(path),
          },
        };

    const { json: response, error } = await dispatch(
      postApi('/api/v1/track-event', requestBody)
    );
    if (error) {
      console.warn('/api/v1/track-event request failed');
      return { error };
    }

    return { response };
  };

const trackingUserJourney = { trackPageViewed };

export type TrackingUserJourney = typeof trackingUserJourney;

export default trackingUserJourney;
