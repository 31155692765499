import { REHYDRATE } from 'redux-persist/es/constants';

import {
  HIDE_RENEWAL_MODAL,
  OPEN_RENEWAL_GUIDE,
  SHOW_RENEWAL_MODAL,
} from './constants';

const initialState = {
  reminderIsVisible: false,
  prescriptionType: null,
  returnPage: null,
  triggeredAt: 0,
  error: null,
};

export default function apoteketReducer(state = initialState, action) {
  const { type, payload = {} } = action;

  switch (type) {
    case SHOW_RENEWAL_MODAL:
      return {
        ...state,
        reminderIsVisible: false,
      };
    case HIDE_RENEWAL_MODAL:
    case OPEN_RENEWAL_GUIDE:
      return {
        ...state,
        reminderIsVisible: true,
      };

    case REHYDRATE:
      return { ...payload.apoteket };

    default:
      return state;
  }
}
