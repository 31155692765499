import {
  CLEAR_GUIDE_FLAG,
  GUIDES_SEARCH_CLEAR,
  GUIDES_SEARCH_SUBMIT,
  RECEIVE_GUIDES,
  REQUEST_GUIDES,
  START_GUIDE,
} from './actionTypes';

export const initialState = {
  formulary: null,
  formularyIndex: '.0',
  guideEntrywayIds: [],
  guidesByEntrywayId: {},
  guideStarted: false,
  isLoading: false,
  searchTerm: null,
  searchTermHistory: [],
  updatedAt: null,
  guideCategoriesById: {},
};

export default function guideReducer(
  state = initialState,
  { payload = {}, type }
) {
  switch (type) {
    case REQUEST_GUIDES:
      return { ...state, isLoading: true };
    case RECEIVE_GUIDES:
      return {
        ...state,
        isLoading: false,
        guidesByEntrywayId: payload.guidesByEntrywayId,
        guideEntrywayIds: payload.guideEntrywayIds,
        updatedAt: payload.receivedAt,
        guideCategoriesById: payload.guideCategoriesById,
      };

    case START_GUIDE:
      return {
        ...state,
        guideStarted: true,
      };

    case CLEAR_GUIDE_FLAG:
      return {
        ...state,
        guideStarted: false,
      };

    case GUIDES_SEARCH_SUBMIT:
      return {
        ...state,
        searchTerm: payload.searchTerm,
      };

    case GUIDES_SEARCH_CLEAR:
      return {
        ...state,
        searchTerm: '',
      };

    default:
      return state;
  }
}
