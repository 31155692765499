import { getGuideByEntrywayId } from '../guides/selectors';

export function decorateCase(caseData, state) {
  try {
    const guideInfo = getGuideByEntrywayId(state, caseData.entrywayId);
    const guideName = (guideInfo && guideInfo.displayTitle) || '';

    return {
      ...caseData,
      doctorImage: caseData.doctorImageUrl,
      guideName,
    };
  } catch (err) {
    console.error('error mapping case data', err);
    return caseData;
  }
}
