import { useEffect, useRef, useState } from 'react';
import { v4 as uuidV4 } from 'uuid';

import { TIME } from '../../utils/time/constants';

const tabId = uuidV4();

interface UserActivityAcrossTabsState {
  [tabId: string]: boolean;
}
interface TabHeartbeatsState {
  [tabId: string]: Date;
}

/**
 * Notifies its activity/aliveness to the other clinic tabs and monitors their activity/aliveness.
 * NOTE: Intended to be used only from UserActivityProvider, separated
 * into a file to tidy the code.
 */
const useUserActivityAcrossTabs = (isUserActive: boolean) => {
  const [anyOtherTabActive, setAnyOtherTabActive] = useState(false);
  /** userActivities: User activities in other tabs */
  const [userActivities, setUserActivities] =
    useState<UserActivityAcrossTabsState | null>(null);
  const userActivitiesRef = useRef<null | UserActivityAcrossTabsState>(null);

  const tabHeartbeatsRef = useRef<null | TabHeartbeatsState>(null);

  const broadcastChannel = useRef(
    new BroadcastChannel('USER_ACTIVITY_CHANNEL')
  );

  const sendNotifyUserActivityMsg = (isActive: boolean) => {
    broadcastChannel.current.postMessage({
      messageName: userActivityChannelMessages.notifyUserActivity,
      payload: {
        tabId,
        isActive,
      },
    });
  };
  const sendHeartbeatMsg = () => {
    broadcastChannel.current.postMessage({
      messageName: userActivityChannelMessages.heartbeat,
      payload: {
        tabId,
      },
    });
  };

  // Send heartbeat messages to let other tabs know this tab is alive.
  useEffect(() => {
    const sendHeartbeatIntervalDuration = 3 * TIME.SECOND;
    const removeDeadTabsIntervalDuration = 20 * TIME.SECOND;
    // We will assume the tab is dead if it skipped 3 heartbeat.
    const deadTabThreshold = 3 * sendHeartbeatIntervalDuration;

    // Send immediately as the component is rendered for the first time.
    sendHeartbeatMsg();

    const sendHeartbeatInterval = new WorkerInterval(
      () => sendHeartbeatMsg(),
      sendHeartbeatIntervalDuration
    );

    const removeDeadTabsInterval = new WorkerInterval(() => {
      if (tabHeartbeatsRef.current === null) {
        return;
      }

      const now = new Date();
      for (const tabId in tabHeartbeatsRef.current) {
        // If it has been longer than given time offset, consider tab dead and remove it.
        if (
          now.getTime() - tabHeartbeatsRef.current[tabId].getTime() >
          deadTabThreshold
        ) {
          if (userActivitiesRef.current != null) {
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
            delete userActivitiesRef.current[tabId];
          }
          if (tabHeartbeatsRef.current != null) {
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
            delete tabHeartbeatsRef.current[tabId];
          }
        }
      }
      setUserActivities({ ...userActivitiesRef.current });
    }, removeDeadTabsIntervalDuration);

    return () => {
      sendHeartbeatInterval.stop();
      removeDeadTabsInterval.stop();
    };
  }, []);

  // Send updated user activity to other tabs.
  useEffect(() => {
    const sendUserActivityIntervalDuration = 10 * TIME.SECOND;

    sendNotifyUserActivityMsg(isUserActive);

    // Send the same value with intervals to make sure the
    // tabs opened after this knows the activity of the tabs before them.
    const sendActivityInterval = new WorkerInterval(
      () => sendNotifyUserActivityMsg(isUserActive),
      sendUserActivityIntervalDuration
    );

    return () => {
      sendActivityInterval.stop();
    };
  }, [isUserActive]);

  // Set the `onmessage` listener of broadcast channel.
  useEffect(() => {
    const heartbeatMessageHandler = (msgData: HeartBeatMessageData) => {
      if (tabHeartbeatsRef.current === null) {
        tabHeartbeatsRef.current = { [msgData.payload.tabId]: new Date() };
        return;
      }
      tabHeartbeatsRef.current[msgData.payload.tabId] = new Date();
    };
    const notifyUserActivityMessageHandler = (
      msgData: NotifyUserActivityMessageData
    ) => {
      userActivitiesRef.current = {
        ...userActivitiesRef.current,
        [msgData.payload.tabId]: msgData.payload.isActive,
      };
      setUserActivities(userActivitiesRef.current);
    };

    broadcastChannel.current.onmessage = (msg: UserActivityMessage) => {
      const messageName = msg?.data?.messageName;

      switch (messageName) {
        case userActivityChannelMessages.heartbeat:
          heartbeatMessageHandler(msg.data);
          return;
        case userActivityChannelMessages.notifyUserActivity:
          notifyUserActivityMessageHandler(msg.data);
          return;
        default:
          // TODO: Use fabios assert function?
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          throw new Error(`Unknown message type: ${messageName}`);
      }
    };
  }, []);

  useEffect(() => {
    if (userActivities === null) {
      setAnyOtherTabActive(false);
    }

    for (const tabId in userActivities) {
      if (userActivities[tabId]) {
        setAnyOtherTabActive(true);
        return;
      }
    }

    setAnyOtherTabActive(false);
  }, [userActivities]);

  return { anyOtherTabActive };
};

const userActivityChannelMessages = {
  heartbeat: 'heartbeat',
  notifyUserActivity: 'notifyUserActivity',
} as const;
interface HeartBeatMessageData {
  messageName: typeof userActivityChannelMessages.heartbeat;
  payload: {
    tabId: string;
  };
}
interface NotifyUserActivityMessageData {
  messageName: typeof userActivityChannelMessages.notifyUserActivity;
  payload: {
    tabId: string;
    isActive: boolean;
  };
}
type MessageData = NotifyUserActivityMessageData | HeartBeatMessageData;
type UserActivityMessage = MessageEvent<MessageData>;

/**
 * The browsers will "slow down" the execution in tabs that are in "background".
 * This affects the `setInterval` and `setTimeout` the most since they get suspended
 * for a period while the tab is in background, which makes the interval unreliable.
 * Starting a `WebWorker` with the browser API solves the issue since the web workers
 * are not slowed down.
 *
 * Inspired from https://stackoverflow.com/a/75828547
 */
class WorkerInterval {
  worker: Worker | null = null;
  constructor(callback: () => void, interval: number) {
    const blob = new Blob([`setInterval(() => postMessage(0), ${interval});`]);
    const workerScript = URL.createObjectURL(blob);
    this.worker = new Worker(workerScript);
    this.worker.onmessage = callback;
  }

  stop() {
    if (this.worker != null) {
      this.worker.terminate();
    }
  }
}

export { useUserActivityAcrossTabs };
