import React, { Component } from 'react';

import colors from '../../../common/colors';
import { Body2Strong } from '../../../common/components/typography';
import Markdown from '../../components/markdown';

const styles = {
  text: {
    flex: 1,
    paddingRight: 5,
  },
  linkStyle: {
    fontWeight: '600',
    color: colors.white,
    textDecoration: 'underline',
  },
  linkStyleError: {
    fontWeight: '600',
    color: colors.red_500,
    textDecoration: 'underline',
  },
};

export default class Message extends Component {
  getColorByType = () => {
    const { type } = this.props;

    switch (type) {
      case 'error':
        return colors.off_black;
      default:
        return colors.white;
    }
  };

  getLinkStyleByType = () => {
    const { type } = this.props;

    switch (type) {
      case 'error':
        return styles.linkStyleError;
      default:
        return styles.linkStyle;
    }
  };

  render() {
    const { children, style } = this.props;

    return (
      <Body2Strong style={{ ...styles.text, ...style }}>
        <Markdown
          source={children}
          textComponent={Body2Strong}
          color={this.getColorByType()}
          style={{ link: this.getLinkStyleByType() }}
        />
      </Body2Strong>
    );
  }
}
