import { formatRoute } from '../../../state/routing';
import { routes } from '@mindoktor/patient-app/routing/constants/webRoutes';

export const replaceLinks = (text = '') => {
  const links = {
    linkToRenewal: `[Receptförnyelse](#${formatRoute(routes.GUIDES, {
      entrywayId: 7,
    })})`,
    linkToAnticonception: `[Preventivmedel](#${formatRoute(routes.GUIDES, {
      entrywayId: 18,
    })})`,
  };
  return text.replace(/{{(\w*)}}/g, (match, key) => links[key] || '');
};
