import { LOGOUT } from '../api/actions';
import { HOME_UPDATE_MODAL_ACTIVE } from './types';

export const initialState = {
  activeTab: 0,
  modalActive: false,
};

export default function homeReducer(state = initialState, action) {
  switch (action.type) {
    case HOME_UPDATE_MODAL_ACTIVE:
      return { ...state, modalActive: action.payload };

    case LOGOUT:
      return { ...state, activeTab: initialState.activeTab };

    default:
      return state;
  }
}
