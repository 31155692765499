export const SET_CLINIC_FULL_INFO = 'SET_CLINIC_FULL_INFO';

// export type ClinicState = {
//   cards: Array<{}>,
//   clinics: Array<{}>,
// };

// export type ClinicsListState = {
//   clinicByCityName: ClinicByCityName,
//   allCities: [string],
//   pending: boolean,
//   error: boolean,
// };

// export type ClinicAddress = {
//   city: string,
//   locations: Array<Location>,
// };

// type Location = {
//   title: string,
//   storeId: number,
//   id: string,
//   domainSlug: string,
//   address: string,
//   proximity: string,
//   phone: number,
//   email: string,
//   openHours: Array<string>,
//   openHoursText: string,
//   exceptions: Array<Object>,
//   latitude: number,
//   longitude: number,
//   city: string,
// };

// export type ClinicByCityName = {
//   +clinicByCityName: {
//     +[name: string]: ClinicState,
//   },
// };
