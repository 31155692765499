import React from 'react';

import { getSpacingStyle } from '../../spacing';
import Text from '../primitives/Text';

const TextTemplate = ({
  children,
  font,
  style,
  color,
  mb,
  mt,
  mr,
  ml,
  className,
  ...props
}) => {
  if (!children) {
    return null;
  }

  const overideColor = color || font.color;
  const spacingStyle = getSpacingStyle({
    mb,
    mt,
    mr,
    ml,
  });

  const propStyle = {
    ...font,
    color: overideColor,
    ...spacingStyle,
  };

  return (
    <Text
      style={[propStyle, style]}
      className={className}
      selectable={true}
      {...props}
    >
      {children}
    </Text>
  );
};

export default TextTemplate;
