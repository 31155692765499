import { _t } from '@mindoktor/patient-legacy/i18n';
import React, { Component } from 'react';

import ContinueButton from '../../components/button/continue';

// type Props = {
//   answer?: any,
//   error?: string,
//   next: () => any,
// };

const styles = {
  footer: {
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
  },

  next: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
};

export default class MultipleChoice extends Component {
  render() {
    const { answer, error, next } = this.props;

    return (
      <div style={styles.footer}>
        <div style={styles.next}>
          <ContinueButton
            text={
              !answer && !error
                ? _t('formulary.multipleChoice.noneOfAbove')
                : undefined
            }
            disabled={!!error}
            onClick={next}
          />
        </div>
      </div>
    );
  }
}
