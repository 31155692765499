import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import isArray from 'lodash-es/isArray';
import React from 'react';

import { getDisplayValue } from '../../../state/inquisitions/utils';
import UploadFiles from '../../components/upload/files';
import typography from '../../theme/typography';
import Markdown from '../components/Markdown';

export const AnswerMarkdown = withStyles(({ spacing }) => ({
  heading: {
    ...typography.body1,
    marginBottom: 0,
  },
  paragraph: {
    marginBottom: spacing(),
    '&:last-child': {
      marginBottom: 0,
    },
  },
  bold: {
    fontWeight: 400,
  },
}))(Markdown);

class Answer extends React.Component {
  // <{
  //   answer: AnswerType,
  //   classes: { [string]: string },
  //   className?: string,
  // }>

  render = () => {
    const { answer, classes, className } = this.props;

    const { type, label, description, value } = answer;

    const displayValue = getDisplayValue(answer);

    return (
      <div className={classNames(classes.root, className)}>
        {label !== undefined && label !== '' && (
          <Typography>{label}</Typography>
        )}
        {description !== undefined && description !== '' && (
          <AnswerMarkdown>{description.trim()}</AnswerMarkdown>
        )}
        {type === 'upload' && !!(value && value.length) ? (
          <div className={classes.files}>
            <UploadFiles files={value} />
          </div>
        ) : (
          (isArray(displayValue) ? displayValue : [displayValue]).map(
            (a, i) => (
              <Typography key={i} className={classes.bold}>
                {a}
              </Typography>
            )
          )
        )}
      </div>
    );
  };
}

export default withStyles(({ spacing }) => ({
  root: {
    position: 'relative',
  },

  bold: { fontWeight: 600 },

  files: { marginTop: spacing() },
}))(Answer);
