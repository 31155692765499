/**
 * This files handles tracking events.
 *
 * Note that some parts are only related to Web or Native.
 *
 * For native, you might be interested in this:
 * https://github.com/mindoktor/mindoktor-app/blob/develop/docs/howto/tracking-via-redux-actions.md
 */

import { getIn, setIn } from 'timm';

import uuid from '../../common/uuid';
import { isLoggedIn } from '../api/selectors';
import {
  APP_LAUNCHED,
  APP_LAUNCHED_FIRST_TIME,
  APP_REGISTER_ADVERTISING_ID_REQUEST_SUCCESS,
  APP_START,
} from '../app/actions';
import { getAppOsAndVersion } from '../app/selectors';
import { fetchCases } from '../cases/actions';
import { CASE_CREATED, CASE_PAYMENT_DONE } from '../cases/actionTypes';
import { getCase } from '../cases/selectors';
import { FEATUREFLAGS_REQUEST_SUCCESS } from '../featureflags/types';
import { getFormularyState } from '../formulary/selectors';
import { QUESTION_SHOWN } from '../formulary/types';
import {
  CHANGE_GUIDECATEGORY,
  GUIDES_STEP,
  VIEW_GUIDE,
} from '../guides/actionTypes';
import { isChildGuide } from '../guides/guidesUtils';
import { getGuideByEntrywayId } from '../guides/selectors';
import { routes as nativeRoutes } from '../native/nav/routes';
import { PROFILE_RECEIVED } from '../profile/actions';
import { getApiProfile, getTrackerId } from '../profile/selectors';
import { ROUTING_OPENED } from '../routing';
import { VACCINATION_DELETE } from '../vaccinations/types';
import { isValidFreePass, isValidEFrikort } from '../freepass/utils';
import { getPaymentMethod } from '../payment/selectors';
import {
  NATIVE_TRACK_EVENT_NAVIGATE,
  NATIVE_TRACK_EVENT_REPLACE,
} from '../native/nav/constants';
import { getFreePass } from '../freepass/selectors';
import type { CommonStateType } from '../types';
import type { SegmentAPI } from '../../native/tracking/middleware';
import type { TrackingUserJourney } from '../trackingUserJourney/track';
import type { DoclyTracking } from '../../web/tracking/docly';
import type {
  Track2Options,
  LegacyTrackOptions,
} from '../../native/tracking/segment/core';
import { LegacyProfileState } from '@mindoktor/patient-app/adapters/types';

const GUIDE_PICKER_USER_STAYED_TIMEOUT_DURATION = 60000;
const PAYMENT_SCREEN_STEP = 'cart';

interface TrackingActionProps {
  id: string;
  entrywayId: number;
  childUuid: string;
  caseData: { id: number; rawCaseId: string };
  profile: LegacyProfileState;
  [key: string]: unknown;
}

interface TrackingAction {
  payload: {
    routeName: string;
    path: string;
    profile: LegacyProfileState;
    props: TrackingActionProps;
    params: TrackingActionProps;
    step: string;
    previousStep: string;
    [key: string]: unknown;
  };
  meta: LegacyTrackOptions | Track2Options;
  type: string;
  [key: string]: unknown;
}

interface TrackingMiddlewareOptions {
  isWebApp: boolean;
  segment: SegmentAPI;
  docly: DoclyTracking;
  trackingUserJourney: TrackingUserJourney;
}

/** @deprecated Use `@mindoktor/patient-app/tracking` instead. */
const trackingMiddleware = ({
  isWebApp,
  /** @deprecated Use `@mindoktor/patient-app/tracking` instead. */
  segment,
  /** @deprecated Use `@mindoktor/patient-app/tracking` instead. */
  docly,
  /** @deprecated Use `@mindoktor/patient-app/tracking` instead. */
  trackingUserJourney,
}: TrackingMiddlewareOptions) => {
  /**
   * Based on the options it will perform various actions, much like a Redux reducer.
   */
  const trackingEventIDs: string[] = [];

  /**
   * Proxy function for segmentTrack
   *
   * Use this to automatically append trackerId when it is set in the state, and the logged_in
   * bool based on state.
   */
  const segmentTrackProxy =
    (getState: () => CommonStateType) =>
    (event: string, data = {}, trackerId = '') => {
      const state = getState();
      return segment.legacyTrack({
        event,
        trackerId: trackerId || getTrackerId(state),
        properties: {
          logged_in: isLoggedIn(state),
          ...data,
        },
      });
    };

  // https://github.com/mindoktor/mindoktor-app/blob/develop/docs/howto/tracking-via-redux-actions.md
  const trackMeta =
    (getState: () => CommonStateType) =>
    ({ traits, track, userTrackerId }: Track2Options) => {
      const state = getState();

      const options: Partial<Track2Options> = {};

      const userMarketingId = userTrackerId || getTrackerId(state);

      options.userTrackerId = userMarketingId;

      if (track && track.event) {
        const { receiver = ['growth'] } = track;

        for (const receiverName of receiver) {
          switch (receiverName) {
            case 'growth':
              // @ts-expect-error the outcome of this statement is not clear
              options.track = setIn(
                track,
                ['properties', 'logged_in'],
                isLoggedIn(state)
              );

              // NOTE: we provide an empty object instead of consents here as
              // they really shouldn't be necessary for the tracking to work.
              // All in accordance to conversation in JIRA:
              // https://mdinternational.atlassian.net/browse/MDP-4765
              segment.track(options as Track2Options, {});
              break;
          }
        }
      }

      if (traits) {
        if (userMarketingId) {
          segment.identify({
            userId: userMarketingId,
            traits,
          });
        } else {
          console.error('traits but not userTrackerId was set on action.meta');
        }
      }
    };

  /**
   * This is used in order to send a Segment event if the user has been on the guide picker view a certain
   * amount of time.
   */
  let guidePickerUserStayedTimeout: ReturnType<typeof setTimeout> | undefined;

  /**
   * Instead of looking at a feature flag, look at the actual tracking data.
   * If the meta data is compatible with the newer functionality, use it,
   * otherwise use the older functionality
   */
  const isNewTrackMeta = (
    meta: LegacyTrackOptions | Track2Options
  ): meta is Track2Options =>
    Boolean((meta as Track2Options).track || (meta as Track2Options).traits);

  /**
   * TrackingMiddleware is injected into the Redux state machine and every actions passes through
   * it before hitting the reducers.
   *
   * You can listen for specific actions to perform tracking actions based on them.
   *
   * No matter the actions taken, TrackingMiddleware should always return `next(action)`
   *
   */
  return ({
    dispatch,
    getState,
  }: {
    dispatch: (...args: unknown[]) => unknown;
    getState: () => CommonStateType;
  }) => {
    const segmentTrack = segmentTrackProxy(getState);
    return (next: (action: TrackingAction) => void) =>
      async (action: TrackingAction) => {
        const { payload, meta } = action;

        if (meta) {
          if (isNewTrackMeta(meta)) {
            trackMeta(getState)(meta);
          } else {
            const { event, trackerId, ...data } = meta;

            if (event) {
              segmentTrack(event, data, trackerId);
            }
          }
        }

        // this is to add the data needed per page
        const userJourneyTrackingData: Record<string, unknown> = {};

        switch (action.type) {
          /**
           * Tracking on Native.
           * https://github.com/mindoktor/mindoktor-app/blob/develop/docs/howto/tracking-via-redux-actions.md
           *
           * These tracking events are no longer triggered by redux events.
           * Instead they are triggered by NavigationContainer's onStateChanged.
           */
          case NATIVE_TRACK_EVENT_NAVIGATE:
          case NATIVE_TRACK_EVENT_REPLACE: {
            const { routeName, params } = payload;

            // Each time the user navigates, clear the potential timeout.
            if (guidePickerUserStayedTimeout) {
              clearTimeout(guidePickerUserStayedTimeout);
            }

            switch (routeName) {
              case nativeRoutes.GUIDE_LIST: {
                segmentTrack('GuidePicker.Viewed');
                guidePickerUserStayedTimeout = setTimeout(
                  () => segmentTrack('GuidePicker.UserStayed'),
                  GUIDE_PICKER_USER_STAYED_TIMEOUT_DURATION
                );
                break;
              }

              case nativeRoutes.HOME_TAB: {
                segmentTrack('TabBar.Pressed', { tab: nativeRoutes.HOME_TAB });
                break;
              }

              case nativeRoutes.CASES_TAB: {
                segmentTrack('TabBar.Pressed', { tab: nativeRoutes.CASES_TAB });
                break;
              }

              case nativeRoutes.ACCOUNT_TAB: {
                segmentTrack('TabBar.Pressed', {
                  tab: nativeRoutes.ACCOUNT_TAB,
                });
                break;
              }

              case nativeRoutes.GUIDE_INFO: {
                segmentTrack('GuideIntroA.Viewed');

                // userJourneyTracking add entrywayId to tracked data
                userJourneyTrackingData.entrywayId =
                  params?.entrywayId?.toString();
                break;
              }

              case nativeRoutes.GUIDE_PRESTART: {
                const { childUuid, entrywayId } = params || {};
                const state = getState();
                const freePass = getFreePass(state);
                const hasValidFreePass = isValidFreePass(freePass);
                const hasValidEFrikort = isValidEFrikort(freePass);
                const paymentMethod = getPaymentMethod(state, entrywayId);

                segmentTrack('GuideIntroB.Viewed');

                userJourneyTrackingData.entrywayId = entrywayId?.toString();
                userJourneyTrackingData.childUuid = childUuid?.toString();
                userJourneyTrackingData.hasValidFreePass =
                  hasValidFreePass?.toString();
                userJourneyTrackingData.hasValidEFrikort =
                  hasValidEFrikort?.toString();
                userJourneyTrackingData.paymentMethod =
                  paymentMethod?.toString();

                break;
              }

              case nativeRoutes.GUIDE_QUESTIONS:
              case nativeRoutes.GUIDE_QUESTIONS_EDIT: {
                const {
                  entrywayId,
                  revisitId,
                  childUuid,
                  dynamicCode,
                  origin,
                  step,
                  preferredCaregiverId,
                } = params || {};
                // userJourneyTracking add various params.. For example:
                // entrywayId,
                // childUuid,
                // revisitId,
                // dynamicCode,
                // step,
                // origin,
                // preferredCaregiverId,
                userJourneyTrackingData.entrywayId = entrywayId?.toString();
                userJourneyTrackingData.revisitId = revisitId?.toString();
                userJourneyTrackingData.childUuid = childUuid?.toString();
                userJourneyTrackingData.dynamicCode = dynamicCode?.toString();
                userJourneyTrackingData.origin = origin?.toString();
                userJourneyTrackingData.step = step?.toString();
                userJourneyTrackingData.preferredCaregiverId =
                  preferredCaregiverId !== 'NaN'
                    ? preferredCaregiverId?.toString()
                    : undefined;
                break;
              }

              case nativeRoutes.HEALTHPROFILE_EDIT: {
                break;
              }

              case nativeRoutes.GUIDE_FREEPASS: {
                break;
              }

              // @ts-expect-error FIXME this does not exist anymore
              case nativeRoutes.GUIDES_ABORT: {
                segmentTrack('Guide.Aborted');
                break;
              }

              case nativeRoutes.MDKLINIKEN:
                segmentTrack('MDClinic.Viewed');

                break;

              case nativeRoutes.MDKLINIKEN_LOCATIONS:
                segmentTrack('MDClinicLocations.Viewed');

                break;

              case nativeRoutes.MDKLINIKEN_LOCATION_CLINIC:
                segmentTrack('MDClinicLocation.Viewed');

                break;

              case nativeRoutes.GUIDE_CHILD_SELECT:
              case nativeRoutes.GUIDE_CHILD_ADD:
              case nativeRoutes.GUIDE_CHILD_INFANT:
                // userJourneyTracking add various params.. For example:
                // entrywayId,
                userJourneyTrackingData.entrywayId =
                  params?.entrywayId?.toString();
                break;
              case nativeRoutes.MDKLINIKEN_LOCATION_PRESENCE:
                segmentTrack('MDClinicPresence.Viewed');

                break;

              case nativeRoutes.MDKLINIKEN_KIOSK_WEBVIEW:
                segmentTrack('MDClinicKioskWebView.Viewed');

                break;

              case nativeRoutes.VACCINATION_BOOK:
                segmentTrack('VaccinationBook.Viewed');

                break;

              // @ts-expect-error FIXME this does not exist anymore
              case nativeRoutes.CONTACT_INFO_VERIFICATION: {
                segmentTrack('ContactInfoVerification.Viewed');
                break;
              }

              case nativeRoutes.CONTACT_INFO_VERIFICATION_VERIFY: {
                segmentTrack('ContactInfoVerificationVerify.Viewed');
                break;
              }

              case nativeRoutes.CONTACT_INFO_VERIFICATION_SUCCESS: {
                segmentTrack('ContactInfoVerificationSuccess.Viewed');
                break;
              }

              case nativeRoutes.VACCINATION_ADD_PRODUCT:
                segmentTrack('VaccinationAddProductScreen.Viewed');

                break;

              case nativeRoutes.VACCINATION_ADD_TAKENDATE:
                segmentTrack('VaccinationAddTakedDateScreen.Viewed');

                break;

              case nativeRoutes.VACCINATION_ADD_DOSAGE:
                segmentTrack('VaccinationAddDosageScreen.Viewed');

                break;

              case nativeRoutes.VACCINATION_ADD_REMINDER:
                segmentTrack('VaccinationAddReminderScreen.Viewed');

                break;

              case nativeRoutes.CASE_MESSAGES:
                segmentTrack('CaseMessages.Viewed');

                // userJourneyTracking add case id to tracked data
                userJourneyTrackingData.caseId = params?.caseId?.toString();

                break;

              case nativeRoutes.PAYMENT_CONFIRMATION:
              case nativeRoutes.GUIDE_CONFIRMATION_ALREADY_VERIFIED:
              case nativeRoutes.GUIDE_CONFIRMATION_UNVERIFIED: {
                await dispatch(fetchCases());
                const {
                  caseData: { id: caseId },
                  paymentMethod,
                } = (getIn(action, ['payload', 'params']) ||
                  {}) as TrackingActionProps;

                const state = getState();
                const freePass = getFreePass(state);
                const hasValidFreePass = isValidFreePass(freePass);
                const hasValidEFrikort = isValidEFrikort(freePass);

                if (caseId) {
                  segmentTrack('Confirmation.Viewed', {
                    payment_method: paymentMethod,
                  });
                } else {
                  console.warn('caseId missing in URL params');
                }

                // userJourneyTracking add case id and payment method to tracked data
                userJourneyTrackingData.caseId = caseId?.toString();
                userJourneyTrackingData.paymentMethod =
                  paymentMethod?.toString();
                userJourneyTrackingData.hasValidFreePass =
                  hasValidFreePass?.toString();
                userJourneyTrackingData.hasValidEFrikort =
                  hasValidEFrikort?.toString();

                break;
              }
            }

            dispatch(
              trackingUserJourney.trackPageViewed(isWebApp, {
                path: routeName,
                data: userJourneyTrackingData,
              })
            );

            return next(action);
          }

          /**
           * Tracking on the Web.
           */
          case ROUTING_OPENED: {
            return next(action);
          }

          case APP_START: {
            segmentTrack('Application.Launched');

            userJourneyTrackingData.state = 'appStart';

            dispatch(
              trackingUserJourney.trackPageViewed(isWebApp, {
                path: '',
                data: userJourneyTrackingData,
              })
            );

            return next(action);
          }

          case PROFILE_RECEIVED: {
            const { userMarketingId } = getApiProfile(action.payload);

            // Execute all tracking events waiting for userMarketingId
            trackingEventIDs.forEach((name) =>
              segmentTrack(name, {}, userMarketingId)
            );

            // Clear the buffer since they have all been executed now.
            trackingEventIDs.length = 0;
            return next(action);
          }

          case GUIDES_STEP: {
            const state = getState();
            const { formularyKey, step, previousStep } = action.payload || {};
            const { entrywayId, formulary, answers } =
              getFormularyState(state, formularyKey) || {};

            userJourneyTrackingData.entrywayId = entrywayId?.toString();
            userJourneyTrackingData.step = step?.toString();
            userJourneyTrackingData.previousStep = previousStep?.toString();

            // We want more info when we are on the payment screen
            if (step === PAYMENT_SCREEN_STEP) {
              const state = getState();
              const freePass = getFreePass(state);
              const hasValidFreePass = isValidFreePass(freePass);
              const hasValidEFrikort = isValidEFrikort(freePass);
              const paymentMethod = getPaymentMethod(state, entrywayId);
              userJourneyTrackingData.hasValidFreePass =
                hasValidFreePass?.toString();
              userJourneyTrackingData.paymentMethod = paymentMethod?.toString();
              userJourneyTrackingData.hasValidEFrikort =
                hasValidEFrikort?.toString();
            }

            dispatch(
              trackingUserJourney.trackPageViewed(isWebApp, {
                path: '/guides/step',
                data: userJourneyTrackingData,
              })
            );

            if (!entrywayId || !formulary || !answers) return next(action);

            if (step === 'guide' && previousStep === 'guide') {
              segmentTrack('Guide.Started');
              docly.sendFunnelEvent('System', 'Guide.Started', 'Guide');
            } else if (
              step.startsWith('healthProfile') &&
              previousStep === 'guideSummary'
            ) {
              segmentTrack('HealthProfile.Started');
              docly.sendFunnelEvent(
                'System',
                'HealthProfile.Started',
                'HealthProfile'
              );
            } else if (
              step === PAYMENT_SCREEN_STEP &&
              previousStep.startsWith('healthProfile')
            ) {
              segmentTrack('Cart.Viewed');
              docly.sendFunnelEvent('System', 'Cart.Viewed', 'Cart');
            }

            return next(action);
          }

          case QUESTION_SHOWN: {
            const state = getState();
            const { formularyKey } = action.payload || {};

            const {
              childUuid,
              entrywayId,
              current: currentGuideNode,
            } = getFormularyState(state, formularyKey) || {};

            userJourneyTrackingData.entrywayId = entrywayId?.toString();
            userJourneyTrackingData.childUuid = childUuid?.toString();
            userJourneyTrackingData.questionId =
              currentGuideNode?.id?.toString();

            dispatch(
              trackingUserJourney.trackPageViewed(isWebApp, {
                path: '/question/shown',
                data: userJourneyTrackingData,
              })
            );
          }

          // eslint-disable-next-line no-fallthrough
          case VIEW_GUIDE: {
            /**
             * This should only be triggered from the guide picker,
             * therefore added a special meta property.
             * The ability to send to segment via meta tracking is
             * hidden behind a feature flag so far. Therefore special
             * treatment for now.
             */
            if (meta && meta.doTrackPressed) {
              segmentTrack('Guide.Pressed', {
                current_location: getIn(action, [
                  'tracking',
                  'currentLocation',
                ]),
              });
            }

            return next(action);
          }

          case CHANGE_GUIDECATEGORY: {
            segmentTrack('GuidePicker.Pressed', {
              current_location: getIn(action, ['tracking', 'currentLocation']),
            });

            return next(action);
          }

          case CASE_CREATED: {
            docly.sendFunnelEvent(
              'System',
              'PaymentButton.Pressed',
              'PaymentButton'
            );
            segmentTrack('PaymentButton.Pressed');

            return next(action);
          }

          case CASE_PAYMENT_DONE: {
            const {
              patientCaseCount,
              marketingRevenue,
              caseId,
              paymentMethod,
            } = action.payload;
            const state = getState();
            const { entrywayId } = getCase(state, caseId as number) || {};
            const guide = entrywayId && getGuideByEntrywayId(state, entrywayId);
            const guideType = guide && isChildGuide(guide) ? 'child' : 'adult';
            // Pass event to Segment.io, which passes it on to FB + AdWords
            // orderId is required by GA, so just generate a uuid
            const fakeOrderId = uuid.generate();

            const eventData = {
              orderId: fakeOrderId,
              products: [
                {
                  product_id: guideType,
                  name: guideType,
                  quantity: 1,
                  price: marketingRevenue,
                },
              ],
              value: marketingRevenue,
              revenue: marketingRevenue,
              total: marketingRevenue,
              currency: 'SEK',
              quantity: 1,
              payment_method: paymentMethod,
            };

            segmentTrack('Order Completed', eventData);

            docly.sendGenericEvent(
              'Checkout',
              'Order Completed',
              guideType,
              eventData
            );

            // Send Transaction event
            docly.sendFunnelEvent(
              'System',
              'TransactionWeb.Completed',
              guide && isChildGuide(guide) ? 'child' : 'adult',
              eventData
            );
            segmentTrack('TransactionApp.Completed', eventData);

            // Send Acquisition event
            if (patientCaseCount === 1) {
              docly.sendGenericEvent(
                'System',
                'AcquisitionWeb.Completed',
                guideType,
                eventData
              );
              segmentTrack('AcquisitionApp.Completed', eventData);
            }

            return next(action);
          }

          case VACCINATION_DELETE: {
            const { type } = action.payload;

            segmentTrack('DeleteVaccine.Pressed', { type });
            return next(action);
          }

          case FEATUREFLAGS_REQUEST_SUCCESS: {
            const nextAction = next(action);

            const state = getState();

            const { os } = getAppOsAndVersion(state);

            // These events have to wait for super properties to be set
            // but don't necessarily need to be here anymore.
            if (!state.app.hasLaunched && (os === 'ios' || os === 'android')) {
              dispatch({ type: APP_LAUNCHED });
            }

            return nextAction;
          }

          case APP_REGISTER_ADVERTISING_ID_REQUEST_SUCCESS: {
            const { seenBefore } = action.payload;

            if (!seenBefore) {
              segmentTrack('Application.LaunchedFirstTime');
              dispatch({ type: APP_LAUNCHED_FIRST_TIME });
            }

            return next(action);
          }
        }
        // Makes sure we always return next(action)
        return next(action);
      };
  };
};

export default trackingMiddleware;
