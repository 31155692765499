import { deleteApi, getApi, postApi } from '../api/actions';
import { getCurrentLocale } from '../intl/selectors';
import { preprocess } from './formulary';
import { flattenAnswers, mapAnswers } from './utils';

export const getFormulary = (id, version) => async (dispatch, getState) => {
  const locale = getCurrentLocale(getState());

  const { json, error } = await dispatch(
    getApi(
      '/api/v1/formulary/questions/' + id + '/' + (version || 1) + '/' + locale
    )
  );

  if (error) return { error };

  return {
    formulary: preprocess(json),
  };
};

// /api/v1/formulary/drafts/:id/:version/:language/:uuid
export const getDraft =
  (formulary, childUuid) => async (dispatch, getState) => {
    const locale = getCurrentLocale(getState());

    const idAndVersion = `${formulary.id}/${formulary.version}`;
    const basePath = `/api/v1/formulary/drafts/${idAndVersion}/${locale}`;
    const apiPath = childUuid ? `${basePath}/${childUuid}` : basePath;
    const { json, error } = await dispatch(getApi(apiPath));

    if (error) return { error };

    json.answers = flattenAnswers(formulary, json.answers);
    return { draft: json };
  };

/*
  entrywayId: number,
  formularyId: string,
  formularyVersion: string,
  childUuid?: string,
  answers: Object,
  index?: string,
  revisitId?: number,
  dynamicCode?: string
*/

export const postDraft =
  (
    entrywayId,
    formularyId,
    formularyVersion,
    childUuid,
    answers,
    index,
    revisitId,
    dynamicCode,
    preferredCaregiverId
  ) =>
  async (dispatch, getState) => {
    const locale = getCurrentLocale(getState());

    const apiPath = childUuid
      ? `/api/v1/formulary/drafts/${childUuid}`
      : '/api/v1/formulary/drafts';

    return dispatch(
      postApi(apiPath, {
        entrywayId,
        formularyId,
        formularyVersion,
        language: locale,
        answers: mapAnswers(answers),
        index,
        revisitId,
        dynamicCode,
        preferredCaregiverId,
      })
    );
  };

export const removeDraft =
  (id, version, childUuid) => async (dispatch, getState) => {
    const locale = getCurrentLocale(getState());

    const apiPath = childUuid
      ? `/api/v1/formulary/drafts/${id}/${version}/${locale}/${childUuid}`
      : `/api/v1/formulary/drafts/${id}/${version}/${locale}`;

    return dispatch(deleteApi(apiPath));
  };

export const postCase =
  (
    entrywayId,
    formularyId,
    formularyVersion,
    answers,
    childUuid,
    revisitId,
    {
      dynamicCode,
      journalConsent,
      informedConsent,
      externalId,
      inquisitionAnswers,
    } = {}
  ) =>
  async (dispatch, getState) => {
    const locale = getCurrentLocale(getState());

    const query = [
      ['formulary', !inquisitionAnswers],
      ['entrywayID', entrywayId],
      ['revisitID', revisitId],
      ['dynamicCode', dynamicCode],
      ['journalconsent', journalConsent],

      // TODO: Done so we get redirected back to react web after payment. Will be redundant once
      // we've fully migrated the web.
      ['react', true],
    ]
      .map(
        ([name, value]) => `${name}=${encodeURIComponent(String(value || ''))}`
      )
      .join('&');

    const apiPath = childUuid
      ? `/api/v1/guides/0/${childUuid}?${query}`
      : `/api/v1/guides/0?${query}`;

    const { json, error, message } = await dispatch(
      postApi(apiPath, {
        id: formularyId,
        version: formularyVersion,
        language: locale,
        answers: mapAnswers(answers),
        externalID: externalId,
        informedConsent,
        inquisitionAnswers,
      })
    );

    if (error) return { error, message };

    return {
      caseId: json && json.caseID,
      paymentRequired: json && json.paymentRequired,
    };
  };

export const getDrafts = () => async (dispatch) => {
  const apiPath = '/api/v1/formulary/drafts';
  const { json: drafts, error } = await dispatch(getApi(apiPath));
  if (error) return { error };
  return { drafts };
};

export const getAbortText =
  (entrywayId, reason) => async (dispatch, getState) => {
    const locale = getCurrentLocale(getState());

    const { json, error } = await dispatch(
      postApi(`/api/v1/formulary/abort?locale=${locale}`, {
        entrywayId,
        reason,
      })
    );

    if (error) return { error };

    return {
      text: json.aborttext,
    };
  };
