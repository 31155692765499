import { getQueryParam } from '../../state/routing/utils';
import * as site from '../../state/site';
import { getDefaultSiteFromHost } from './host';

export const getSite = () =>
  getQueryParam(window.location.search, 'site') || getDefaultSiteFromHost();

export const getSiteOptions = () => site.getSiteOptions(getSite());
export const getSiteLandingUrl = () => site.getSiteLandingUrl(getSite());
export const getSiteHowItWorksUrl = () => site.getSiteHowItWorksUrl(getSite());
export const getSiteSupportUrl = () => site.getSiteSupportUrl(getSite());
export const getSiteCookiesUrl = () => site.getSiteCookiesUrl(getSite());
export const getSiteTermsUrl = () => site.getSiteTermsUrl(getSite());
export const getSitePrerequisitesUrl = () =>
  site.getSitePrerequisitesUrl(getSite());
export const getSiteMedicalComplaints = () =>
  site.getSiteMedicalComplaints(getSite());
