/**
 * Decides if the current device is a mobile phone.
 *
 * Use this function if you need to decide what to display,
 * based on a different user experience between desktop and mobile phone.
 *
 * **Do not use this function if what you're trying to achieve is just responsiveness.**
 */
const getIsMobile = (): boolean => {
  const navigator = window.navigator;

  if ('maxTouchPoints' in navigator) {
    return navigator.maxTouchPoints > 0;
  }

  if ('msMaxTouchPoints' in navigator) {
    return (navigator as { msMaxTouchPoints: number }).msMaxTouchPoints > 0;
  }

  const mediaQueryResult = window?.matchMedia?.('(pointer:coarse)');
  if (mediaQueryResult?.media === '(pointer:coarse)') {
    return mediaQueryResult.matches;
  }

  if ('orientation' in window) {
    return true; // deprecated, but good fallback
  }

  // Only as a last resort, fall back to user agent sniffing
  const userAgent = (navigator as { userAgent: string }).userAgent;
  if (
    /\b(BlackBerry|webOS|iPhone|IEMobile|Ubuntu Touch)\b/i.test(userAgent) ||
    /\b(Android|Windows Phone|iPad|iPod)\b/i.test(userAgent)
  ) {
    return true;
  }

  return false;
};

export const isMobile = getIsMobile();
