import Fade from '@material-ui/core/Fade';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import React from 'react';

const transitionDuration = 250;

class Transition extends React.Component {
  state = {};

  timer;

  transition = async () =>
    new Promise((resolve) => {
      this.setState({ transition: true });
      this.timer = setTimeout(async () => {
        this.timer = undefined;
        this.setState({ transition: false });
        resolve();
      }, transitionDuration);
    });

  componentWillUnmount() {
    this.timer && clearTimeout(this.timer);
  }

  render() {
    const { children, classes, className } = this.props;
    const { transition } = this.state;

    return (
      <Fade
        in={!transition}
        timeout={{
          enter: transitionDuration,
          exit: transitionDuration,
        }}
      >
        <div
          className={classNames(classes.root, className)}
          style={{
            pointerEvents: transition === true ? 'none' : 'all',
          }}
        >
          {children}
        </div>
      </Fade>
    );
  }
}

export default withStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
}))(Transition);
