import {
  VACCINATION_DOSAGE_SELECTED,
  VACCINATION_FETCH_CHILD_SUCCESS,
  VACCINATION_FETCH_SUCCESS,
  VACCINATION_PRODUCT_SELECTED,
  VACCINATION_REMINDER_SELECTED,
  VACCINATION_RESET_CURRENTANSWERS,
  VACCINATION_TAKEN_SELECTED,
} from './types';

const initialState = {
  vaccinationCards: [],
  products: [],
  vaccinationCardsChild: {},
  query: '',
  answers: {
    takenDate: '',
    dosage: '',
    reminder: '',
    key: '',
    nplId: '',
    atcCode: '',
    label: '',
    type: [],
    productType: '',
    productName: '',
  },
};

export default function vaccinationReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case VACCINATION_FETCH_SUCCESS: {
      return {
        ...state,
        vaccinationCards: payload.vaccinationCards,
      };
    }
    case VACCINATION_FETCH_CHILD_SUCCESS: {
      return {
        ...state,
        vaccinationCardsChild: {
          ...state.vaccinationCardsChild,
          ...payload.vaccinationCards,
        },
      };
    }
    case VACCINATION_PRODUCT_SELECTED: {
      return {
        ...state,
        answers: {
          ...state.answers,
          key: payload.key,
          nplId: payload.nplId,
          atcCode: payload.atcCode,
          label: payload.label,
          type: payload.type,
        },
      };
    }
    case VACCINATION_TAKEN_SELECTED: {
      return {
        ...state,
        answers: {
          ...state.answers,
          takenDate: payload.takenDate,
        },
      };
    }
    case VACCINATION_DOSAGE_SELECTED: {
      return {
        ...state,
        answers: {
          ...state.answers,
          dosage: payload,
        },
      };
    }
    case VACCINATION_REMINDER_SELECTED: {
      return {
        ...state,
        answers: {
          ...state.answers,
          reminder: payload.reminderDate,
        },
      };
    }
    case VACCINATION_RESET_CURRENTANSWERS: {
      return {
        ...state,
        answers: initialState.answers,
      };
    }

    default:
      return state;
  }
}
