import { useEffect, useState } from 'react';
import Base64 from 'base-64';

import { useLegacyAuth } from '@mindoktor/patient-app/adapters/hooks/useLegacyAuth';

export const useRtmToken = (): string | undefined => {
  const [rtmToken, setRtmToken] = useState<string>();
  const { token } = useLegacyAuth();

  useEffect(() => {
    if (token != null) {
      try {
        setRtmToken(extractRtmToken(token));
      } catch (e) {
        // Ignore, probably an old token got stuck from the last session.
      }
    }
  }, [token]);

  return rtmToken;
};

const extractRtmToken = (token: string): string | undefined => {
  const parts = token.split('.');
  if (parts.length !== 3) {
    throw Error('Invalid access token.');
  }
  const data = JSON.parse(Base64.decode(parts[1]));
  return data?.messagingToken;
};
