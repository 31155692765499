import { v4 as uuidv4 } from 'uuid';

import { readCookieValue, setCookie } from '../cookies';

/**
 * This function will try to get the device UUID from cookies.
 * If it is not found it will generate a new uuid and set the cookie.
 */
export const getDeviceId = () => {
  // this cookie name is 'special' since we are using it across
  // different subdomains and products (Ie. dr-static, clinic ect.).
  const cookieName = 'md-device-id';

  const didFromCookie = readCookieValue(cookieName);
  if (didFromCookie != null) {
    return didFromCookie;
  }

  const did = uuidv4();
  setCookie(cookieName, did, getNewDeviceIDExpireDate());
  return did;
};

const getNewDeviceIDExpireDate = (): string => {
  // Save it for one year according to the cookie policy
  // https://www.mindoktor.se/cookies/
  const oneYearAhead = new Date();
  oneYearAhead.setFullYear(oneYearAhead.getFullYear() + 1);

  return oneYearAhead.toUTCString();
};

export const getUniqueId = () => {
  const uniqueId = generateRandomId();

  const tokenKey = 'md_device_token';
  const token = window.localStorage.getItem(tokenKey) ?? uniqueId;

  window.localStorage.setItem(tokenKey, token);

  return token;
};

/**
 * @deprecated
 * @todo Only one usage, consider if it can be safely replaced with `uuid`
 */
const generateRandomId = () => {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return (
    s4() +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    s4() +
    s4()
  );
};

// Not really necessary, just to match native device API
export const USER_AGENT = navigator.userAgent;
