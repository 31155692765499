import React, { Component } from 'react';

import colors from '../../../common/colors';

const styles = {
  inner: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 24,
    paddingRight: 12,
    minHeight: 40,
    maxWidth: 964,
    margin: '0 auto',
  },

  error: { backgroundColor: colors.yellow_500 },
  info: { backgroundColor: colors.grey_900 },
  success: { backgroundColor: colors.green_500 },
  warning: { backgroundColor: colors.blue_500 },
};

export default class Notification extends Component {
  getStyleByType = () => {
    const { type } = this.props;

    switch (type) {
      case 'error':
        return styles.error;
      case 'success':
        return styles.success;
      case 'warning':
        return styles.warning;
      default:
        return styles.info;
    }
  };

  render() {
    const { children, style } = this.props;

    return (
      <div
        style={{
          ...this.getStyleByType(),
          ...style,
        }}
      >
        <div style={styles.inner}>{children}</div>
      </div>
    );
  }
}
