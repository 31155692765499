/** Base type with a string representation. */
type StringAbleBaseType = string | number | bigint | boolean | null | undefined;

/** Converts a record in its string representation. */
type StringifiedObject<T extends object> = {
  [key in keyof T]: Stringified<T[key]>;
};

/** Converts a `StringType` in its string representation. */
export type Stringified<T extends unknown> = T extends StringAbleBaseType
  ? `${T}`
  : T extends object
  ? StringifiedObject<T>
  : never;

/** Returns the same object where each prop value has been replaces by its string representation. */
export const getStringifiedObject = <T extends object>(
  obj: T,
  options: { skipUndefined?: boolean } = { skipUndefined: false }
): StringifiedObject<T> => {
  const objectEntries = Object.entries(obj).filter(
    // Filter out undefined
    ([k, v]) => !(options.skipUndefined === true && v === undefined)
  );
  const stringifiedEntries = objectEntries.map(([key, value]) => {
    if (value != null && typeof value === 'object') {
      return [key, getStringifiedObject(value, options)];
    }

    return [key, String(value)];
  });
  return Object.fromEntries(stringifiedEntries);
};
