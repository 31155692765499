import { combineReducers } from '@reduxjs/toolkit';

import api from './api/reducer';
import app from './app/reducer';
import intl from './intl/reducer';
import guides from './guides/reducer';
import inquisitions from './inquisitions/reducer';
import usersurveys from './usersurveys/reducer';
import cases from './cases/reducer';
import profile from './profile/reducer';
import home from './home/reducer';
import persistent from './persistent/reducer';
import survey2 from './survey2/reducer';
import messages from './messages/reducer';
import messaging from './messaging/reducer';
import medications from './medications/reducer';
import formulary from './formulary/reducer';
import freePass from './freepass/reducer';
import healthProfile from './healthprofile/reducer';
import featureFlags from './featureflags/reducer';
import apoteket from './apoteket/reducer';
import referrals from './referrals/reducer';
import notifications from './notifications/reducer';
import videochat from './videochat/reducer';
import files from './files/reducer';
import drafts from './drafts/reducer';
import experiments from './experiments/reducer';
import push from './push/reducer';
// import type { PushState } from './push/types';
import consents from './consents/reducer';
import faq from './faq/reducer';
import userNotifications from './userNotifications/reducer';
import vaccinations from './vaccinations/reducer';
import clinicsInfo from './mdkliniken/reducer';
import deeplink from './deeplink/reducer';
import workouts from './workout/reducer';
import routing from './routing/reducer';
import helpCenter from './helpCenter/reducer';
import snackbar from './snackbar/reducer';

// Do not use this, use the inferred CommonStateType in types.ts instead
// export interface GlobalState {
//   api: ApiState,
//   apoteket: ApoteketState,
//   app: AppState,
//   cases: CasesState,
//   featureFlags: FeatureFlagsState,
//   formulary: FormularyState,
//   freePass: FreePassState,
//   guides: GuidesState,
//   inquisitions: InquisitionsState,
//   usersurveys: UserSurveyState,
//   healthProfile: HealthProfileState,
//   home: HomeState,
//   intl: IntlState,
//   medications: MedicationsState,
//   messages: MessagesState,
//   messaging: MessagingState,
//   persistent: PersistentState,
//   profile: ProfileState,
//   referrals: ReferralsState,
//   // referralResults: ReferralResultsState,
//   survey: SurveyState,
//   survey2: Survey2State,
//   notifications: NotificationsState,
//   videochat: VideochatState,
//   files: FilesState,
//   drafts: DraftsState,
//   experiments: ExperimentsState,
//   push: PushState,
//   consents: ConsentsState,
//   vaccinations: VaccinationState,
//   faq?: FAQState, // only available for native
//   clinicsInfo: ClinicState,
//   deeplink: DeepLink,
//   workouts: WorkoutState,
// }

// rootReducer should be of type Reducer<GlobalState>, but it won't work for some reason...
export const commonReducers = {
  api,
  app,
  intl,
  profile,
  guides,
  inquisitions,
  usersurveys,
  cases,
  home,
  persistent,
  survey2,
  messages,
  messaging,
  medications,
  formulary,
  freePass,
  healthProfile,
  featureFlags,
  apoteket,
  referrals,
  notifications,
  videochat,
  files,
  drafts,
  experiments,
  push,
  consents,
  faq,
  userNotifications,
  vaccinations,
  clinicsInfo,
  deeplink,
  workouts,
  helpCenter,
  routing,
  snackbar,
};

export default combineReducers(commonReducers);
