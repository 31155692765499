import React from 'react';
import { useLocation } from 'react-router-dom';

import { useNavigation } from '../hooks/useNavigation/web/useNavigation';
import { ParamsDefaultType } from '../hooks/useParams/types';
import { useParams } from '../hooks/useParams/useParams';
import { useQueryParams } from '../hooks/useQueryParams/web/useQueryParams';

export interface RoutingProp {
  navigation: ReturnType<typeof useNavigation>;
  params: ReturnType<typeof useParams<ParamsDefaultType>>;
  queryParams: ReturnType<typeof useQueryParams>;
  location: ReturnType<typeof useLocation>;
}

export interface WithRoutingProps {
  routing: RoutingProp;
}

export const withRouting = <PropsT extends WithRoutingProps = WithRoutingProps>(
  Component: React.ComponentType<PropsT>
) => {
  type PropsWithoutRoutingProps = Omit<PropsT, keyof WithRoutingProps>;
  const ComponentWithRouting: React.FC<PropsWithoutRoutingProps> = (
    baseProps
  ) => {
    const navigation = useNavigation();
    const params = useParams();
    const queryParams = useQueryParams();
    const location = useLocation();
    const props = {
      ...baseProps,
      routing: { navigation, params, queryParams, location },
    };
    // See why coercion is needed: https://github.com/Microsoft/TypeScript/issues/28938#issuecomment-450636046
    return <Component {...(props as PropsT)} />;
  };

  return ComponentWithRouting;
};
