import { INQUISITIONS_DRAFT_REMOVE, INQUISITIONS_DRAFT_UPDATE } from './types';

export default (
  state = {
    draftById: {},
  },
  action
) => {
  const { type, payload = {} } = action;

  switch (type) {
    case INQUISITIONS_DRAFT_UPDATE: {
      const { id, draft } = payload;
      const { visited, values } = draft;

      // If draft.options is undefined, use the previous options instead
      // TODO: Create separate INIT_DRAFT action to handle this
      const options =
        draft.options || (state.draftById[id] && state.draftById[id].options);

      return {
        ...state,
        draftById: {
          ...state.draftById,
          [id]: {
            visited,
            values,
            options,
          },
        },
      };
    }

    case INQUISITIONS_DRAFT_REMOVE: {
      const { id } = payload;
      const newDraftById = {
        ...state.draftById,
      };
      delete newDraftById[id];

      return {
        ...state,
        draftById: newDraftById,
      };
    }

    default:
      return state;
  }
};
