import Base64 from 'base-64';
import throttle from 'lodash-es/throttle';

import { removeToken, setToken } from '@mindoktor/patient-app/api/token';
import { constructUrl, DEFAULT_HEADERS, UNAUTHORIZED } from '../api';
import { fetchHostConfig } from './api';
import { getDeviceId, getSessionId, getUserAgent } from '../app/selectors';
import { getCurrentLocale } from '../intl/selectors';
import { generateRandomId } from '../utils/random';
import { getSite, getToken, isLoggedIn } from './selectors';
import { HOSTS_CONFIG_LOAD_SUCCESS } from './types';
import { platform } from '@mindoktor/patient-app/utils/compatibility/platform/platform';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const UPDATE_TOKEN = 'UPDATE_TOKEN';
export const SERVER_MAINTENANCE_RESPONSE = 'SERVER_MAINTENANCE_RESPONSE';
export const API_SITE = 'API_SITE';
export const API_COMPANY = 'API_COMPANY';
export const API_ERROR = 'API_ERROR';
export const CHOOSE_SIGNUP_DOMAIN = 'CHOOSE_SIGNUP_DOMAIN';
export const DEBUG_MESSAGE = 'DEBUG_MESSAGE';
export const STATUS_NOT_FOUND = 404;
export const STATUS_NO_CONTENT = 204;

export const stripBearerPrefix = (token) => {
  return token.replace(/Bearer\s*/gi, '');
};

const extractSession = (token) => {
  // rudimentary check for JWT validity
  // see: http://git.io/xPBn for JWT format
  const parts = token.split('.');
  if (parts.length !== 3) {
    // throw new Error('Invalid Token (JWT) format');
    return {};
  }
  return JSON.parse(Base64.decode(parts[1]));
};

export const fetchLoginRedirectUrl = (state) => async (dispatch, getState) => {
  const locale = getCurrentLocale(getState());

  const { json: { redirectUrl } = {}, error } = await dispatch(
    getApi(`/api/v1/oauth/login?state=${state || ''}&locale=${locale}`)
  );

  return { redirectUrl, error };
};

export const login = (rawToken) => (dispatch) => {
  const token = rawToken ? stripBearerPrefix(rawToken) : undefined;
  setToken(token);

  dispatch({
    type: LOGIN,
    payload: {
      token,

      // If there's no token we assume the iat timestamp is now, for idle timeout purposes.
      session: token
        ? extractSession(token)
        : { iat: Math.floor(Date.now() / 1000) },
    },
  });
};

export const logout =
  ({ initiatedByUser } = {}) =>
  async (dispatch) => {
    await removeToken(); // Can be removed after transitioning out of legacy auth
    dispatch({ type: LOGOUT, payload: { initiatedByUser } });
  };

export const refreshToken = () => getApi('/api/v1/user/renewtoken');

// Throttle the token update so it only
// runs its update loop every 6 seconds
const throttledUpdateToken = throttle((rawToken, dispatch) => {
  const token = stripBearerPrefix(rawToken);
  setToken(token);
  return dispatch({
    type: UPDATE_TOKEN,
    payload: {
      token,
      session: extractSession(token),
    },
  });
}, 6000);

export const updateToken = (rawToken) => (dispatch) => {
  throttledUpdateToken(rawToken, dispatch);
};

export const setApiSite = (site = '') => ({
  type: API_SITE,
  payload: { site },
});

export const setApiCompany = (companyId) => ({
  type: API_COMPANY,
  payload: { companyId },
});

export const chooseSignupDomain = (domain) => ({
  type: CHOOSE_SIGNUP_DOMAIN,
  payload: domain,
});

export const getApi = (url, options) =>
  ajaxApi({
    method: 'GET',
    url,
    options,
  });

export const postApi = (url, body) =>
  ajaxApi({
    method: 'POST',
    url,
    body,
  });

export const deleteApi = (url) =>
  ajaxApi({
    method: 'DELETE',
    url,
  });

export const debugMessage = (message) => async (dispatch) => {
  dispatch({ type: DEBUG_MESSAGE, payload: { message } });
};

export const ajaxApi = (request) => async (dispatch, getState) => {
  try {
    const state = getState();
    const token = getToken(state);
    const hasToken = getToken(state);
    const site = getSite(state);

    const params = {
      site,
      requestId: encodeURIComponent(generateRandomId()),
    };

    request.url = constructUrl({
      url: request.url,
      params,
    });

    if (hasToken) {
      request.headers = request.headers || {};
      if (!platform.isWeb) {
        // On web we are using `credentials: true` in the requests. In the NAPP this is not implemented yet so it will fail.
        request.headers.Authorization = `Bearer ${token}`;
      }
    }

    const { response, error, status } = await dispatch(ajax(request));

    if (!response) return { error };

    let json;
    if (status !== STATUS_NOT_FOUND && status !== STATUS_NO_CONTENT) {
      try {
        // some replies (like the extras endpoint) return plaintext errors instead of json.
        json = await response.json();
      } catch {
        (e) => {
          return { error: e };
        };
      }
    }

    let message;

    if (json) {
      const { data, msg, token: responseToken } = json;

      json = data;
      message = msg;
      // TODO: use new auth to check if the user is logged in or not
      if (responseToken) {
        // Hack: Since the token doesn't get cleared properly on logout in BE
        // we have to check if the user is logged in before updating token to avoid
        // setting an old token.
        if (!isLoggedIn(state)) {
          // eslint-disable-next-line no-console
          console.log(
            "Received token from BE even though user isn't signed in yet, will skip token update. This could be caused by using the app, and not being logged in yet for this session, but you have been logged in previously."
          );
        } else {
          request.options && request.options.skipTokenUpdate
            ? undefined
            : dispatch(updateToken(responseToken));
        }
      }
    }

    if (error) {
      const { status, message } = response;
      const { url } = request;
      console.warn(status, message, url);

      // don't spam unauthorized errors
      if (status !== 401) {
        dispatch({ type: API_ERROR, payload: { status, message, url } });
      }
    }

    if (status === UNAUTHORIZED) {
      dispatch({ type: LOGOUT });
    }

    return {
      response,
      error,
      status,
      json,
      message,
    };
  } catch (err) {
    console.error(err);
    return { error: true };
  }
};

export const ajax = (request) => async (dispatch, getState) => {
  try {
    let response;

    const state = getState();

    const headers = {
      ...DEFAULT_HEADERS,
      'User-Agent': getUserAgent(state),
      'X-MD-Device-ID': getDeviceId(state),
      'X-MD-Session-ID': getSessionId(state),
      ...request.headers,
    };

    try {
      response = await fetch(request.url, {
        method: request.method || 'GET',
        credentials: request.credentials || 'include',
        headers,
        body: request.body ? JSON.stringify(request.body) : undefined,
      });
    } catch (e) {
      if (response) {
        console.warn(response.status, response.message, request.url);
      } else {
        console.error(
          `Could not fetch ${request.url}. There seems to be something wrong with the network connection ☠`
        );
      }
    }

    if (!response) {
      return { error: true };
    }

    const { status } = response;

    if (status === 503) {
      dispatch({
        type: SERVER_MAINTENANCE_RESPONSE,
      });
    }

    return {
      response,
      error: status >= 400,
      status,
    };
  } catch (err) {
    console.error(err);
    return { error: true };
  }
};

export const loadHostConfig = () => async (dispatch, getState) => {
  const state = getState();
  const { host, error } = await fetchHostConfig(state.api.site);

  if (error) {
    return { error };
  }

  await dispatch({ type: HOSTS_CONFIG_LOAD_SUCCESS, payload: { host } });

  return { host };
};
