import { platform } from '@mindoktor/patient-app/utils/compatibility/platform/platform';

import { isFacebookOrInstagram } from '../utils';

import type { OpenBankIDLinkFunction } from './types';

export const openBankIDLink: OpenBankIDLinkFunction = (autoStartToken) => {
  const redirect = 'null';

  // For desktop and some mobile browsers
  let bankIdAppUrl = `bankid:///?autostarttoken=${autoStartToken}&redirect=${redirect}`;

  // And for Android and iOS, attempting to exclude in-app browsers on Android.
  // https://www.bankid.com/utvecklare/guider/teknisk-integrationsguide/programstart
  if (platform.isIos || (platform.isAndroid && !isFacebookOrInstagram)) {
    bankIdAppUrl = `https://app.bankid.com/?autostarttoken=${autoStartToken}&redirect=${redirect}`;
  }

  window.location.href = bankIdAppUrl;
};
