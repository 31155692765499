import React from 'react';

import Markdown from '../../../components/markdown';
import Message from '../../components/message';
import Header from '../components/header';

const styles = {
  text: {
    whiteSpace: 'pre-wrap',
    overflowX: 'auto',
  },
};

const DoctorText = ({ avatarUrl, name, signature, timestamp, text, style }) => (
  <Message style={style}>
    <Header
      image={avatarUrl}
      title={name}
      signature={signature}
      timestamp={timestamp}
    />

    <Markdown source={text} style={styles.text} />
  </Message>
);

export default DoctorText;
