import { _t } from '@mindoktor/patient-legacy/i18n';
import React from 'react';

import iconSource from '../../../../../images/icon/icon_pay.svg';
import Button from '../../../components/button/button';
import Message from '../../components/message';
import Header from '../components/header';

// type Props = {
//   url: string,
//   style?: any,
// };

const PaymentInfoInvoice = (props) => {
  const { style, url } = props;
  return (
    <Message style={style}>
      <Header
        title={_t('paymentInfoCard.invoice.header')}
        icon={iconSource}
        bold={false}
      />

      <Button color="black" href={url} hrefTarget="_blank">
        {_t('paymentInfoCard.invoice.button')}
      </Button>
    </Message>
  );
};

export default PaymentInfoInvoice;
