import React from 'react';

import Box from '@mui/material/Box/Box';
import Container from '@mui/material/Container/Container';

import { isMobile } from '@mindoktor/patient-app/utils/device/web';

import Footer from '../../Footer/web/Footer';
import FullHeightStack from '../../FullHeightStack/web';
import Header from '../../Header/web/Header';

interface Props {
  children: React.ReactNode;
}

/** Fixed full-page layout containing an element with an inner scrollable component. */
const ScrollableContentLayout: React.FC<Props> = ({ children }) => {
  return (
    <FullHeightStack>
      {!isMobile && (
        <Box>
          <Header />
        </Box>
      )}

      <Container
        maxWidth="sm"
        sx={{
          padding: 0,
          height: '100%',
          overflowY: 'hidden',
        }}
      >
        {children}
      </Container>
      {!isMobile && (
        <Box>
          <Footer />
        </Box>
      )}
    </FullHeightStack>
  );
};

export default ScrollableContentLayout;
