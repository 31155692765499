import { _t } from '@mindoktor/patient-legacy/i18n';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import colors from '../../../common/colors';
import { Body3 } from '../../../common/components/typography';
import { updateFormulary } from '../../../state/formulary/actions';
import { getFormularyState } from '../../../state/formulary/selectors';
import ContinueButton from '../../components/button/continue';

const styles = {
  root: {
    marginBottom: 20,
    marginLeft: 20,
    marginRight: 20,
  },

  footer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  error: {
    color: colors.error_text,
  },
};

// type Props = {
//   formularyKey?: string,
//   answers?: Object,

//   errorCount: number,

//   next: () => any,

//   updateFormulary: typeof updateFormulary,
// };

export class SummaryFooter extends Component {
  state = {
    submitting: false,
  };

  submit = async () => {
    const { next, formularyKey, updateFormulary, answers, errorCount } =
      this.props;

    this.setState({ submitting: true });

    if (errorCount === 0) {
      await updateFormulary({ formularyKey, answers });

      next();
    }

    this.setState({ submitting: false });
  };

  render() {
    const { errorCount } = this.props;
    const { submitting } = this.state;

    return (
      <div style={styles.root}>
        <div
          style={{
            ...styles.footer,
            justifyContent: errorCount ? 'space-between' : 'flex-end',
          }}
        >
          {errorCount > 0 && (
            <div>
              <Body3 style={{ ...styles.error, fontWeight: 'bold' }}>
                {errorCount > 1
                  ? _t('healthProfile.errors.countMultiple', {
                      count: errorCount,
                    })
                  : _t('healthProfile.errors.countOne')}
              </Body3>

              <Body3 style={styles.error}>
                {_t('healthProfile.errors.suffix')}
              </Body3>
            </div>
          )}

          <ContinueButton
            disabled={!!errorCount || submitting}
            text={_t('guides.continue')}
            onClick={this.submit}
          />
        </div>
      </div>
    );
  }
}

export default connect(
  (state, props) => {
    const { formularyKey } = props;

    const { errors, answers } = getFormularyState(state, formularyKey) || {};

    return {
      errorCount: errors ? Object.keys(errors).length : 0,
      answers,
    };
  },
  {
    updateFormulary,
  }
)(SummaryFooter);
