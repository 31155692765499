import React from 'react';

import { Body3 } from '../../../common/components/typography';
import Slider from '../../components/input/slider';

const styles = {
  root: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
  },

  slider: {
    paddingBottom: 5,
  },

  labels: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};

// type Props = {
//   answer?: any,
//   min?: Min,
//   max?: Max,
//   update: (value?: any) => any,
//   disabled?: boolean,
//   step?: number,
// };

const Range = ({ answer, min, max, update, disabled, step }) => (
  <div style={styles.root}>
    <Slider
      value={answer}
      min={min.value}
      max={max.value}
      onChange={update}
      style={styles.slider}
      disabled={disabled}
      step={step}
    />
    <div style={styles.labels}>
      <Body3>{min.label}</Body3>

      <Body3>{max.label}</Body3>
    </div>
  </div>
);

export default Range;
