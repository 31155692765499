import { _t } from '@mindoktor/patient-legacy/i18n';
import React from 'react';
import { connect } from 'react-redux';

import colors from '../../../../common/colors';
import { Body1 } from '../../../../common/components/typography';
import spacing from '../../../../common/spacing';
import { postCaseReopened } from '../../../../state/logs/actions';
import { formatRoute } from '../../../../state/routing';
import { GuideStartOrigin } from '../../../../state/tracking/constants';
import Button from '../../../components/button/button';
import Card from '../../components/card';
import { CaseCardHeader } from './case_card_header';
import { openChatRoute } from '../../../utils/openChatRoute';
import { useNavigation } from '@mindoktor/patient-app/routing/hooks/useNavigation/web';
import { routes } from '@mindoktor/patient-app/routing/constants/webRoutes';
import { useAppDispatch } from '../../../../state/hooks';

const styles = {
  card: {
    borderTop: `2px solid ${colors.grey_900}`,
  },

  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: spacing.t,
  },

  removeButton: {
    flex: 0.5,
    marginRight: spacing.xt,
  },

  continueButton: {
    flex: 0.5,
    marginLeft: spacing.xt,
  },

  caseNumber: {
    fontWeight: 500,
  },
  caseInfo: {
    marginLeft: 72,
  },
};

const getRoute = (resumeCaseInfo) => {
  const { entrywayId, guideType, patientUUID, revisitId } = resumeCaseInfo;
  return formatRoute(routes.GUIDES_QUESTIONS, {
    entrywayId,
    childUuid: guideType === 'children' ? patientUUID : undefined,
    revisitId,
    origin: GuideStartOrigin.CASE_CARD,
  });
};

const CaseClosedCard = ({
  caseId,
  conversationId,
  title,
  iconSource,
  patientName,
  resumeCaseInfo,
  style,
  postCaseReopened,
}) => {
  const navigation = useNavigation();
  const dispatch = useAppDispatch();
  return (
    <Card
      style={{ ...styles.card, ...style }}
      onClick={() =>
        !resumeCaseInfo &&
        openChatRoute(dispatch, navigation, caseId, conversationId)
      }
    >
      <CaseCardHeader
        iconSource={iconSource}
        title={title}
        patientName={patientName}
        caseHasActions={resumeCaseInfo}
      />
      <div style={styles.caseInfo}>
        <Body1>
          {_t('caseCard.caseId')}:{' '}
          <span style={styles.caseNumber}>{caseId}</span>
        </Body1>
      </div>
      {resumeCaseInfo && (
        <div style={styles.buttons}>
          <Button
            type="ghost"
            color="black"
            onClick={() =>
              openChatRoute(dispatch, navigation, caseId, conversationId)
            }
            style={styles.removeButton}
          >
            {_t('caseCard.show')}
          </Button>

          <Button
            color="black"
            href={`#${getRoute(resumeCaseInfo)}`}
            style={styles.continueButton}
            onClick={async () => {
              await postCaseReopened(caseId);
            }}
          >
            {_t('caseCard.resume')}
          </Button>
        </div>
      )}
    </Card>
  );
};

export default connect(null, {
  postCaseReopened,
})(CaseClosedCard);
