import { INFORMED_CONSENT_FIRST, INFORMED_CONSENT_SECOND } from './types';

const initialState = {
  informedConsentsByEntrywayId: {},
};

export default function draftReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case INFORMED_CONSENT_FIRST: {
      const { entrywayId, value } = payload;
      return {
        ...state,
        informedConsentsByEntrywayId: {
          [entrywayId]: {
            ...state.informedConsentsByEntrywayId[entrywayId],
            first: value,
          },
        },
      };
    }

    case INFORMED_CONSENT_SECOND: {
      const { entrywayId, value } = payload;
      return {
        ...state,
        informedConsentsByEntrywayId: {
          [entrywayId]: {
            ...state.informedConsentsByEntrywayId[entrywayId],
            second: value,
          },
        },
      };
    }

    default: {
      return state;
    }
  }
}
