import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { ResponseError } from '@mindoktor/patient-app/api/responseError';
import { useLocale } from '@mindoktor/patient-app/localization/components/LocaleProvider';

import { casesApi } from '../casesApi';
import { Case } from '../models/cases';

type CasesResult = UseQueryResult<Case[], ResponseError>;
const queryKey = 'Cases';

export const useCasesApi = (): CasesResult => {
  const locale = useLocale();
  const currentLocale = locale?.currentLocale;
  return useQuery<Case[], ResponseError>(
    [queryKey, currentLocale],
    async () => {
      const response = await casesApi.getCases(currentLocale);
      if (!response.ok) {
        throw new ResponseError(response);
      }
      return response?.data?.data ?? [];
    }
  );
};
