import { LOGOUT } from '../api/actions';
import {
  // type ClinicState,
  // type ClinicsListState,
  SET_CLINIC_FULL_INFO,
} from './types';

const initialState = {
  clinicByCityName: {},
  allCities: [],
  pending: false,
  error: false,
};
const ClinicInformation = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case SET_CLINIC_FULL_INFO:
      const { clinicByCityName, allCities } = payload || {};

      return {
        ...state,
        pending: false,
        clinicByCityName,
        allCities,
      };
    case LOGOUT:
      return initialState;

    default:
      return state;
  }
};

export default ClinicInformation;
