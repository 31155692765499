import React from 'react';

import Box from '@mui/material/Box/Box';
import Stack from '@mui/material/Stack/Stack';
import Typography from '@mui/material/Typography/Typography';

import MDLogoHeartIcon from '@mindoktor/pulse/src/icons/MDLogoHeartIcon/MDLogoHeartIcon.web';
import { colors } from '@mindoktor/pulse/src/styles/colors';

const Footer: React.FC = () => {
  return (
    <Box sx={{ backgroundColor: colors.gray[100], padding: 2.5 }}>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <MDLogoHeartIcon sx={{ fontSize: '2rem' }} />
        <Typography sx={{ color: colors.gray[600], fontSize: 13 }}>
          Min Doktor erbjuder vård för hela Sverige genom Min Doktor Vårdcentral
          & BVC, som ingår i vårdvalet i Region Sörmland. © 2022 MD
          International AB
        </Typography>
      </Stack>
    </Box>
  );
};

export default Footer;
