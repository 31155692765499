import React, { Component } from 'react';

import FadeTransition from '../components/transition/fade';
import Intl from '../intl/provider';
import { context } from './index';

const styles = {
  root: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1,
  },
};

export default class Router extends Component {
  state = {
    index: 0,
    Component: undefined,
    props: undefined,
    options: undefined,
  };

  show = (Component, props, options) =>
    this.setState({ Component, props, index: this.state.index + 1, options });

  hide = () =>
    this.setState({
      Component: undefined,
      props: undefined,
      index: this.state.index + 1,
    });

  componentDidMount() {
    context.showModal = this.show;
    context.hideModal = this.hide;
  }

  render() {
    const {
      Component,
      props,
      index,
      options: { skipTransition } = {},
    } = this.state;

    return (
      <Intl>
        <FadeTransition transitionKey={index} skipTransition={skipTransition}>
          {!!Component && (
            <div style={styles.root}>
              <Component {...props} />
            </div>
          )}
        </FadeTransition>
      </Intl>
    );
  }
}
