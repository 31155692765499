import { _t } from '@mindoktor/patient-legacy/i18n';
import React from 'react';

import iconSourceTravel from '../../../../../images/icon/icon_travel_certificate.svg';
import { Body1 } from '../../../../common/components/typography';
import { constructUrl } from '../../../../state/api';
import { getCertificateTexts } from '../../../../state/cases/caseMessageUtils';
import {
  CertificateStatus,
  CertificateType,
} from '../../../../state/cases/constants';
import Button from '../../../components/button/button';
import { getSite } from '../../../utils/site';
import Message from '../../components/message';
import Header from '../components/header';

const styles = {
  lead: {
    paddingBottom: 16,
  },
};

const iconSource = require('../../../../../images/icon/icon_medical_certificate@3x.png');

export default ({ id, timestamp, style, status, certificateType, data }) => {
  const isCancelledOrRemoved =
    status === CertificateStatus.Cancelled ||
    status === CertificateStatus.Removed;

  const isTravelCertificate = certificateType === CertificateType.TravelCovid19;

  const { header, body } = getCertificateTexts(certificateType, status, data);

  return (
    <Message style={style}>
      <Header
        title={header}
        icon={isTravelCertificate ? iconSourceTravel : iconSource}
        timestamp={timestamp}
      />

      <Body1 style={styles.lead}>{body}</Body1>

      {!isCancelledOrRemoved && (
        <Button
          color="black"
          href={constructUrl({
            url: `/api/v1/certificates/${id}/download/certificate${id}.pdf`,
            params: { site: getSite() },
          })}
        >
          {_t('certificateItem.button')}
        </Button>
      )}
    </Message>
  );
};
