import { API_URL } from '../api';
// import type { AsyncAction, Dispatch } from '../types';
import { updateToken } from '../api/actions';
import { getSite, getToken } from '../api/selectors';
import {
  FILES_CACHE_ADD,
  FILES_POST,
  FILES_POST_ERROR,
  FILES_POST_SUCCESS,
} from './types';

const FILE_URL = '/api/v1/files/';

// type OptionalParams = {
//   removeTemporaryFile?: RemoveTemporaryFile,
//   transcodeVideo?: TranscodeVideo,
//   origin?: string,
//   location?: string,
//   contentType?: string,
//   entrywayId?: number,
//   childUuid?: string,
// };

export const postFile =
  (url, path, uploadFile, options = {}) =>
  async (dispatch, getState) => {
    const { origin, location, contentType, entrywayId, childUuid, type } =
      options;

    const payload = { origin, location, contentType, entrywayId };

    dispatch({ type: FILES_POST, payload });

    let uploadUrl =
      API_URL +
      url +
      (url.includes('?') ? '&' : '?') +
      'site=' +
      getSite(getState());

    if (childUuid) {
      uploadUrl = uploadUrl + '&childUuid=' + childUuid;
    }

    const {
      data,
      error: postError,
      status,
    } = await uploadFile(uploadUrl, type, path, {
      Authorization: `Bearer ${getToken(getState())}`,
    });

    const hasData = data && data.length > 0;

    let error = postError || !hasData;
    let json;
    let message;

    try {
      json = hasData && JSON.parse(String(data));
    } catch (e) {
      error = true;
    }

    if (json) {
      const { data, msg, token } = json;

      json = data;
      message = msg;

      if (token) {
        dispatch(updateToken(token));
      }
    }

    dispatch({
      type: error ? FILES_POST_ERROR : FILES_POST_SUCCESS,
      payload: {
        ...payload,
        json,
        message,
        status,
        error,
      },
    });

    return {
      json,
      error,
      message,
      status,
    };
  };

export const postImageFile =
  (path, uploadFile, options = {}) =>
  async (dispatch) => {
    const { removeTemporaryFile } = options;

    const { json, error } = await dispatch(
      postFile(FILE_URL, path, uploadFile, { ...options, contentType: 'image' })
    );

    if (!error) {
      removeTemporaryFile && removeTemporaryFile(path);
    }

    return {
      fileId: json,
      error,
    };
  };

export const postVideoFile =
  (originalPath, uploadFile, options = {}) =>
  async (dispatch) => {
    const { removeTemporaryFile, transcodeVideo, type } = options;

    let path = originalPath;

    // If we need to transcode the video, we try to remove the original file
    // when the transcoding is done. The newly transcoded file is used thereafter.
    if (transcodeVideo) {
      const { path: transcodedPath, error } = await transcodeVideo(path);
      if (!error && transcodedPath) {
        removeTemporaryFile && removeTemporaryFile(path);
        path = transcodedPath;
      }
    }

    const { json: fileId, error } = await dispatch(
      postFile(FILE_URL, path, uploadFile, {
        ...options,
        contentType: 'video',
        type,
      })
    );

    if (!error) {
      dispatch({
        type: FILES_CACHE_ADD,
        payload: { fileId, path },
      });
    }

    return {
      fileId,
      error,
    };
  };
