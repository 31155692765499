import { createSelector } from 'reselect';

import {
  getAdultAgeFromHost,
  getIndependentCareAgeFromHost,
} from '../api/selectors';
import { isChildGuide } from './guidesUtils';

// Temporary State type, will refine it in a global types.js file
// type State = Object;

const allGuides = (state) =>
  state.guides.guideEntrywayIds.map(
    (id) => state.guides.guidesByEntrywayId[id]
  );

export const getGuideCategories = (state) => {
  const independentCareAge = getIndependentCareAgeFromHost(state);
  const guideCategories = {
    ...state.guides.guideCategoriesById,
  };

  // Add to categories obj {guides} by existing entrywayIds
  const result = {};
  for (const key in guideCategories) {
    const category = guideCategories[key];
    result[key] = {
      title: category.title,
      id: category.id,
      guides: category.entrywayIds
        .map((id) => state.guides.guidesByEntrywayId[id])
        .filter(
          (g) =>
            g.showInList && !g.forGuardian && g.minAge >= independentCareAge
        ),
    };
  }
  return result;
};

export const getChildGuides = (state) => {
  const adultAge = getAdultAgeFromHost(state);
  return allGuides(state).filter(
    (g) => g.showInList && g.forGuardian && g.minAge < adultAge
  );
};

export const hasGuides = createSelector(allGuides, (guides) => !!guides.length);

export const hasChildGuides = createSelector(
  getChildGuides,
  (childGuides) => !!childGuides.length
);

export const getAdultGuides = (state) => {
  const independentCareAge = getIndependentCareAgeFromHost(state);
  return allGuides(state).filter(
    (g) => g.showInList && !g.forGuardian && g.minAge >= independentCareAge
  );
};

export const getPrescriptionGuides = createSelector(
  allGuides,
  // Pick from state.guides.guidesByEntrywayId directly?
  (guides) => guides.filter((g) => g.entrywayId === 7 || g.entrywayId === 18)
);

export const getGuidesByCategory = (state, category) => {
  switch (category) {
    case 'child':
      return getChildGuides(state);
    case 'prescription':
      return getPrescriptionGuides(state);
    case 'adult':
    default:
      return getAdultGuides(state);
    // we could change the default to return all guides grouped by adult categories
    // return getGuideCategories(state);
  }
};

export function getGuideByEntrywayId(state, entrywayId) {
  return state.guides.guidesByEntrywayId[entrywayId];
}

export const getGuidesByEntrywayId = (state) => state.guides.guidesByEntrywayId;

export function getGuideTitle(state, entrywayId) {
  const guide = getGuideByEntrywayId(state, entrywayId);
  if (guide && guide.displayTitle) {
    return guide.displayTitle;
  }
  return '';
}

export function getGuidePrice(state, entrywayId) {
  const guide = getGuideByEntrywayId(state, entrywayId);
  if (guide && guide.priceMinor) {
    return guide.priceMinor / 100;
  }
  return 0;
}

export function getGenderMessageByEntrywayId(state, entrywayId) {
  const guide = getGuideByEntrywayId(state, entrywayId);
  if (!guide) return '';

  const { gender, genderMessage } = guide;
  const adultGuide = !isChildGuide(guide);

  if (gender && adultGuide) {
    const userGender = state.profile.isFemale ? 'female' : 'male';

    if (userGender !== gender && genderMessage) {
      return genderMessage;
    }
  }
  return '';
}

export const isGuideStarted = (state) => state.guides.guideStarted;
