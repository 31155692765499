import config from '@mindoktor/env/Config';
import React from 'react';
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from 'react-google-maps';
import { getIn } from 'timm';

import { getImageSrc } from '@mindoktor/pulse/src/utils/images/getImageSrc';
import locationPin from '../../../images/referrals/location_pin@3x.png';

const defaultZoom = 8;
const defaultPosition = {
  lat: 55.6048823,
  lng: 12.9951041,
};

const defaultMarkerIcon = {
  url: getImageSrc(locationPin),
  scaledSize: { width: 34, height: 48 },
};

const googleMapUrl = `https://maps.googleapis.com/maps/api/js?key=${config.GoogleMapsApiKey}&v=3.exp&libraries=geometry,drawing,places`;

const MapView = withScriptjs(
  withGoogleMap(({ markers = [], initialZoom, initialPosition }) => (
    <GoogleMap
      defaultZoom={initialZoom || defaultZoom}
      defaultCenter={initialPosition || defaultPosition}
      options={{
        clickableIcons: false,
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        zoomControlOptions: {
          position: getIn(window, [
            'google',
            'maps',
            'ControlPosition',
            'TOP_RIGHT',
          ]),
        },
      }}
    >
      {markers.map(({ icon, position, onClick }, i) => (
        <Marker
          key={i}
          icon={icon || defaultMarkerIcon}
          position={position}
          onClick={onClick}
        />
      ))}
    </GoogleMap>
  ))
);

// type Props = {
//   markers?: {
//     icon?: {
//       url?: string,
//       size?: { width: number, height: number },
//       scaledSize?: { width: number, height: number },
//     },
//     position: { lat: number, lng: number },
//     onClick?: () => any,
//   }[],

//   initialZoom?: number,
//   initialPosition?: { lat: number, lng: number },

//   style?: any,
// };

const MapViewComponent = ({
  markers,

  initialZoom,
  initialPosition,

  style,
}) => (
  <MapView
    markers={markers}
    initialZoom={initialZoom}
    initialPosition={initialPosition}
    googleMapURL={googleMapUrl}
    mapElement={<div style={{ height: '100%', width: '100%' }} />}
    loadingElement={<div />}
    containerElement={<div style={style} />}
  />
);

export default MapViewComponent;
