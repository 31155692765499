import { _t } from '@mindoktor/patient-legacy/i18n';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import logoHjartat from '../../../../images/apoteket/logo_ap_hjartat.png';
import colors from '../../../common/colors';
import { Body1 } from '../../../common/components/typography';
import { sendApoteketVoucherMail } from '../../../state/apoteket/actions';
import { APOTEKET_BLOOD_PRESSURE_LINK } from '../../../state/apoteket/constants';
import { contactInformation } from '../../../state/profile/selectors';
import Button from '../../components/button/button';
import { CardWithBody } from '../../components/card';
import Img from '@mindoktor/pulse/src/utils/images/Img.web';

const styles = {
  logo: { display: 'block', height: 24, width: 128 },

  info: {
    paddingTop: 20,
    paddingBottom: 20,
  },

  footer: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  apoteketLink: {
    fontWeight: 'bold',
    color: colors.apoteket_color,
    cursor: 'pointer',
    textDecoration: 'none',
  },

  emailButton: {
    width: 180,
  },
};

// type Props = {
//   email: string,

//   sendApoteketVoucherMail: typeof sendApoteketVoucherMail,

//   style?: any,
// };

export class ApoteketMailCard extends Component {
  state = {
    pressed: false,
  };

  onFindClick = () => (window.location.href = APOTEKET_BLOOD_PRESSURE_LINK);

  onEmailClick = () => {
    const { sendApoteketVoucherMail } = this.props;
    const { pressed } = this.state;

    if (pressed) return;

    this.setState({ pressed: true });

    sendApoteketVoucherMail();
  };

  render() {
    const { email, style } = this.props;
    const { pressed } = this.state;

    return (
      <CardWithBody color={colors.apoteket_color} style={style}>
        <Img style={styles.logo} src={logoHjartat} />

        <Body1 style={styles.info}>
          {_t('bloodPressureMail.info', { email })}
        </Body1>

        <div style={styles.footer}>
          <div>
            <Body1>{_t('bloodPressureMail.findLink')}</Body1>

            <Body1 style={styles.apoteketLink} onClick={this.onFindClick}>
              {_t('bloodPressureMail.apoteketLink')}
            </Body1>
          </div>
          <Button
            type="ghost"
            color="green"
            disabled={pressed}
            style={styles.emailButton}
            onClick={this.onEmailClick}
          >
            {pressed
              ? _t('bloodPressureMail.hasSentEmail')
              : _t('bloodPressureMail.sendEmail')}
          </Button>
        </div>
      </CardWithBody>
    );
  }
}

export default connect(
  (state) => {
    const { email } = contactInformation(state);

    return { email };
  },
  { sendApoteketVoucherMail }
)(ApoteketMailCard);
