import React, { useEffect, useState } from 'react';

import {
  Button,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
} from '@mui/material';

import { colors } from '@mindoktor/pulse/src/styles/colors';

import { useTranslation } from '@mindoktor/patient-app/localization/hooks/useTranslation';
import { useRedirectionNavigation } from '@mindoktor/patient-app/routing/hooks/useRedirectionNavigation';
import {
  DataTrackingEvents,
  DataTrackingJourneyEvents,
} from '@mindoktor/patient-app/tracking/api/models/dataTracking';
import { MarketingTrackingActions } from '@mindoktor/patient-app/tracking/api/models/marketingTracking';
import { useDataTracking } from '@mindoktor/patient-app/tracking/hooks/useDataTracking';
import { useMarketingTracking } from '@mindoktor/patient-app/tracking/hooks/useMarketingTracking';

import { useAuthentication } from '../../../hooks/useAuthentication';
import { useAuthErrorNotifications } from '../../../hooks/useAuthErrorNotifications';
import { useEmailLoginForm } from '../../../hooks/useEmailLoginForm';
import { useEmailLoginMutator } from '../../../hooks/useEmailLoginMutator';
import VisibilityIconColor from '../../VisibilityIconColor/web/VisibilityIconColor';

const passwordAdornment = (
  currentValue: boolean,
  toggleVisibility: (value: boolean) => void
) => ({
  endAdornment: (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={() => toggleVisibility(!currentValue)}
        edge="end"
      >
        <VisibilityIconColor
          iconColor={colors.gray[700]}
          state={currentValue ? 'off' : 'on'}
        />
      </IconButton>
    </InputAdornment>
  ),
});

const EmailLogin = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  /** We don't want to validate until the user has tried submitting */
  const [hasTriedSubmitting, setHasTriedSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const t = useTranslation('auth');
  const redirectionNavigation = useRedirectionNavigation();
  const marketingTracking = useMarketingTracking();
  const dataTracking = useDataTracking();

  const emailLoginMutator = useEmailLoginMutator();

  const authError = useAuthErrorNotifications();
  const authentication = useAuthentication();

  useEffect(() => {
    if (authentication.isAuthenticated) {
      redirectionNavigation.redirect();
    }
  }, [authentication.isAuthenticated]);

  const onSubmit = async () => {
    if (!isValid) {
      setHasTriedSubmitting(true);
      return;
    }

    setIsSubmitting(true);

    try {
      await emailLoginMutator.mutateAsync(values);
      void marketingTracking.track(MarketingTrackingActions.LoginCompleted);
      void dataTracking.track(DataTrackingEvents.PageViewed, {
        journeyEvent: DataTrackingJourneyEvents.LoginCompleted,
      });
    } catch (error: unknown) {
      authError.show(error);
    }

    setIsSubmitting(false);
    setHasTriedSubmitting(true);
  };

  const { values, errors, isValid, onChange, onEnterKey, inputRefs } =
    useEmailLoginForm(onSubmit);

  const disabled = (hasTriedSubmitting && !isValid) || isSubmitting;

  return (
    <form>
      <Stack spacing="2.5rem">
        <TextField
          id="user-email"
          label={t('email.address')}
          sx={{ marginBottom: 0 }}
          variant="filled"
          type="email"
          value={values.email}
          onChange={(e) => onChange('email', e.target.value)}
          error={hasTriedSubmitting && !(errors.email == null)}
          helperText={
            hasTriedSubmitting && errors?.email?.length != null
              ? t(errors.email[0])
              : ''
          }
          required
          autoComplete="email"
          onKeyDown={(e) => onEnterKey('email', e)}
        />
        <TextField
          id="user-password"
          inputRef={inputRefs.passwordRef}
          label={t('email.password')}
          sx={{ marginBottom: 0 }}
          variant="filled"
          type={showPassword ? 'text' : 'password'}
          value={values.password}
          onChange={(e) => onChange('password', e.target.value)}
          InputProps={passwordAdornment(showPassword, setShowPassword)}
          error={hasTriedSubmitting && !(errors.password == null)}
          helperText={
            hasTriedSubmitting && errors?.password?.length != null
              ? t(errors.password[0])
              : ''
          }
          onKeyDown={(e) => onEnterKey('password', e)}
          required
          autoComplete="current-password"
        />

        <Stack alignItems={'center'}>
          <Button
            color="primary"
            onClick={() => {
              void onSubmit();
            }}
            variant="contained"
            disabled={disabled}
            sx={{ width: '70%' }}
          >
            {t('common.login')}
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default EmailLogin;
