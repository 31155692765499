import { _t } from '@mindoktor/patient-legacy/i18n';
import React from 'react';

import iconSource from '../../../../../images/icon/icon_pay.svg';
import Message from '../../components/message';
import Header from '../components/header';

// type Props = {
//   style?: any,
//   paymentOverview: any,
// };

const PaymentInfoFree = (props) => {
  const { style, paymentOverview } = props;

  const paymentType = paymentOverview?.payload?.kind;

  if (
    paymentType === 'freeYoung' ||
    paymentType === 'freeOld' ||
    paymentType === 'alwaysFree'
  ) {
    return null;
  }
  return (
    <Message style={style}>
      <Header
        title={
          paymentType === 'freePass'
            ? _t('paymentInfoCard.freepass.header')
            : _t('paymentInfoCard.freeother.header')
        }
        icon={iconSource}
        bold={false}
      />
    </Message>
  );
};

export default PaymentInfoFree;
