import { hideModal, showModal } from '../../routing';
import CancelCaseModal from '../modals/cancel_case_modal';
import { _t } from '@mindoktor/patient-legacy/i18n/docly-intl';
import CannotCancelCaseModal from '../modals/cannot_cancel_case_modal';
import React, { useState } from 'react';
import { useAppDispatch } from '../../../state/hooks';
import {
  cancelCase,
  fetchCaseOverviewById,
} from '../../../state/cases/actions';
import { track } from '../../../state/tracking/actions';
import { showNotification } from '../../../state/notifications/actions';
import { LinkButton } from './link_button';
import { routes } from '@mindoktor/patient-app/routing/constants/webRoutes';
import { useNavigation } from '@mindoktor/patient-app/routing/hooks/useNavigation/web';
import { CaseDataOverview } from '../../../api/cases/types';

export interface Props {
  caseId: number;
  entrywayId: number;
}

const CancelCase: React.FC<Props> = (props) => {
  const [disableCancelButton, setCancelButtonDisabled] = useState(false);
  const dispatch = useAppDispatch();
  const navigation = useNavigation();

  const onAbortCancel = () => {
    setCancelButtonDisabled(false);
    hideModal();

    dispatch(
      track('CancelCase.Deny', {
        entrywayId: props.entrywayId,
      })
    );
  };

  const onCancel = async () => {
    const { caseId, entrywayId } = props;
    setCancelButtonDisabled(true);

    track('CaseMessagesScreen.CancelCase', {
      entrywayId,
    });

    let caseData;
    try {
      caseData = (await dispatch(
        fetchCaseOverviewById(caseId)
      )) as CaseDataOverview;
    } catch {
      // TODO: Log to sentry here
      setCancelButtonDisabled(false);
      return;
    }

    const { overview } = caseData;

    if (overview.isCancelable === true) {
      showModal(CancelCaseModal, {
        onConfirm: (reason: string) => {
          confirmCancelCase(caseId, reason);
        },
        onCancel: onAbortCancel,
      });
    } else {
      openCannotCancelModal();
      setCancelButtonDisabled(false);
    }
  };

  const confirmCancelCase = async (caseId: number, reason: string) => {
    const error = await dispatch(cancelCase(caseId, reason));

    dispatch(
      track('CancelCase.Confirm', {
        entrywayId: props.entrywayId,
      })
    );

    if (error) {
      openCannotCancelModal();
    } else {
      hideModal();
      dispatch(
        showNotification({
          message: _t('case.cancel.toast'),
          type: 'success',
          onClose: undefined,
        })
      );
      navigation.navigate(routes.CASES);
    }
  };

  const openCannotCancelModal = () => {
    showModal(CannotCancelCaseModal, {
      onCancel: hideModal,
    });

    dispatch(
      track('CancelCase.CaseAlreadyTaken', {
        entrywayId: props.entrywayId,
      })
    );
  };

  return (
    <LinkButton
      key={'CaseListScreen'}
      onPress={onCancel}
      disabled={disableCancelButton}
    >
      {_t('case.abort.caption') + ' >'}
    </LinkButton>
  );
};

export default CancelCase;
