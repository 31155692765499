import React from 'react';

import UndoLink from '../../formulary/components/undo_link';

const PreviousLink = ({ text, onClick }) => (
  <div>
    <UndoLink
      text={text}
      onClick={onClick}
      style={{
        maxWidth: 'calc(75% - 40px)',
        paddingLeft: 20,
        paddingRight: 20,
      }}
    />
  </div>
);

export default PreviousLink;
