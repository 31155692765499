import React from 'react';

import { checkUnreachable } from '../../utils/typechecking/assertUnreachable';
import { Message, MessageType } from '../api/models/conversation';

import APHCard from './cards/APH/APHCard';
import FreepassCard from './cards/Freepass/FreepassCard';
import GreetingCaseCard from './cards/GreetingCase/GreetingCaseCard';
import InvoiceCard from './cards/Invoice/InvoiceCard';
import PrescriptionCard from './cards/Prescription/PrescriptionCard';
import TextMessageCard from './cards/TextMessage/TextMessageCard';
import ThankYouCard from './cards/ThankYou/ThankYouCard';

interface Props {
  message: Message;
}

const MessageCardFactory: React.FC<Props> = ({ message }) => {
  switch (message.type) {
    case MessageType.TextMessage:
      return <TextMessageCard message={message} />;
    case MessageType.GreetingCase:
      return <GreetingCaseCard message={message} />;
    case MessageType.ThankYou:
      return <ThankYouCard message={message} />;
    case MessageType.Invoice:
      return <InvoiceCard message={message} />;
    case MessageType.Freepass:
      return <FreepassCard message={message} />;
    case MessageType.Prescription:
      return <PrescriptionCard message={message} />;
    case MessageType.APH:
      return <APHCard message={message} />;
    default:
      return checkUnreachable(message, (message: Message) => {
        console.warn('Provided type is not supported:', message.type);
      });
  }
};

export default MessageCardFactory;
