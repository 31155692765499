import { updateIn } from 'timm';

import {
  REFERRAL_LAB_CHOOSE,
  REFERRAL_LAB_CHOOSE_ERROR,
  REFERRAL_LAB_CHOOSE_SUCCESS,
  REFERRAL_LAB_DONE,
  REFERRAL_LAB_DONE_ERROR,
  REFERRAL_LAB_DONE_SUCCESS,
  REFERRAL_LAB_PREVIEW,
  REFERRAL_LAB_PREVIEW_CLEAR,
  REFERRAL_SELFTEST_CONFIRM,
  REFERRAL_SELFTEST_CONFIRM_ERROR,
  REFERRAL_SELFTEST_CONFIRM_SUCCESS,
  REFERRAL_SELFTEST_SENT,
  REFERRAL_SELFTEST_SENT_ERROR,
  REFERRAL_SELFTEST_SENT_SUCCESS,
  REFERRALLABS_REQUEST,
  REFERRALLABS_REQUEST_ERROR,
  REFERRALLABS_REQUEST_SUCCESS,
  REFERRALS_FETCH_SHARED_RESULTS_SUCCESS,
  REFERRALS_NEAREST_PHARMACY_SET,
  REFERRALS_SET_IDS,
  USER_REGION_CLEAR,
  USER_REGION_SET,
  USER_REGION_SET_ERROR,
  USER_REGION_SET_SUCCESS,
} from './actionTypes';

const initialState = {
  labs: undefined,
  nearestMDK: undefined,
  previewedLab: null,
  loading: undefined,
  posting: undefined,
  findingUserPosition: undefined,
  region: undefined,
  results: {
    referralId: null,
    caseId: null,
    shared: null,
  },
};

export default function referralsReducer(state = initialState, action) {
  const { type, payload = {} } = action;

  switch (type) {
    case REFERRAL_LAB_PREVIEW:
      return {
        ...state,
        previewedLab: payload.labId,
      };

    case REFERRAL_LAB_PREVIEW_CLEAR:
      return {
        ...state,
        previewedLab: null,
      };

    case REFERRALLABS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case REFERRALLABS_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
      };

    case REFERRALLABS_REQUEST_SUCCESS:
      return {
        ...state,
        labs: payload.labs,
        loading: false,
      };

    case REFERRAL_LAB_CHOOSE:
    case REFERRAL_LAB_DONE:
    case REFERRAL_SELFTEST_CONFIRM:
    case REFERRAL_SELFTEST_SENT:
      return {
        ...state,
        posting: true,
      };

    case REFERRAL_LAB_CHOOSE_ERROR:
    case REFERRAL_LAB_CHOOSE_SUCCESS:
    case REFERRAL_LAB_DONE_ERROR:
    case REFERRAL_LAB_DONE_SUCCESS:
    case REFERRAL_SELFTEST_CONFIRM_ERROR:
    case REFERRAL_SELFTEST_CONFIRM_SUCCESS:
    case REFERRAL_SELFTEST_SENT_ERROR:
    case REFERRAL_SELFTEST_SENT_SUCCESS:
      return {
        ...state,
        posting: false,
      };

    case USER_REGION_SET:
      return {
        ...state,
        findingUserPosition: true,
      };

    case USER_REGION_SET_ERROR:
      return {
        ...state,
        findingUserPosition: false,
      };

    case USER_REGION_SET_SUCCESS:
      return {
        ...state,
        region: payload.region,
        findingUserPosition: false,
      };

    case USER_REGION_CLEAR:
      return {
        ...state,
        region: undefined,
      };

    case REFERRALS_FETCH_SHARED_RESULTS_SUCCESS:
      return updateIn(state, ['results'], (results) => ({
        ...results,
        shared: action.payload && action.payload.data,
      }));

    case REFERRALS_SET_IDS:
      const { referralId, caseId } = action.payload || {};

      return updateIn(state, ['results'], (results) => ({
        ...results,
        referralId,
        caseId,
      }));

    case REFERRALS_NEAREST_PHARMACY_SET:
      const { nearestMDK } = payload;
      return {
        ...state,
        nearestMDK,
      };

    default:
      return state;
  }
}
