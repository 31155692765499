import { _t } from '@mindoktor/patient-legacy/i18n';
import React from 'react';

import arrowIcon from '../../../../../images/generated/arrow_listitem@4x.png';
import { Intro, Title2 } from '../../../../common/components/typography';
import spacing from '../../../../common/spacing';
import Img from '@mindoktor/pulse/src/utils/images/Img.web';

const styles = {
  icon: {
    display: 'block',
    width: 56,
    height: 56,
    marginRight: spacing.xs,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: spacing.xs,
  },
  labels: {
    flex: 1,
  },
  arrow: {
    height: spacing.xs,
    marginTop: spacing.xxt,
    marginLeft: spacing.xs,
    opacity: 0.33,
  },
};

export const CaseCardHeader = ({
  iconSource,
  title,
  patientName,
  caseHasActions,
}) => (
  <div style={styles.header}>
    <img src={iconSource} style={styles.icon} />
    <div style={styles.labels}>
      <Title2>{title}</Title2>
      <Intro>
        {_t('caseCard.journalizedFor')} {patientName}
      </Intro>
    </div>
    {!caseHasActions && <Img src={arrowIcon} style={styles.arrow} />}
  </div>
);

CaseCardHeader.defaultProps = {
  caseHasActions: false,
};
