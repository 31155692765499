import React from 'react';

import { useAsyncTrackingHandler } from '../hooks/useAsyncTrackingHandler';
import { useMarketingTrackingApi } from '../hooks/useMarketingTrackingApi';

import { MarketingTrackingContext } from './MarketingTrackingContext';

/** Handles marketing tracking initialization, identification and sending events requiring identification. */
const MarketingTrackingProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const marketingTrackingApi = useMarketingTrackingApi();
  const { asyncTrack } = useAsyncTrackingHandler();

  return (
    <MarketingTrackingContext.Provider
      value={{ ...marketingTrackingApi, track: asyncTrack }}
    >
      {children}
    </MarketingTrackingContext.Provider>
  );
};

export default MarketingTrackingProvider;
