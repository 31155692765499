import { _t } from '@mindoktor/patient-legacy/i18n';

import { getApi, postApi } from '../api/actions';
import { showNotification } from '../notifications/actions';
import {
  getApiProfile,
  getOpenRevisitInvitationByInvitationId,
} from './selectors';

export const PROFILE_REQUEST = 'PROFILE_REQUEST';
export const PROFILE_RECEIVED = 'PROFILE_RECEIVED';
export const PROFILE_USERINFO_RECEIVED = 'PROFILE_USERINFO_RECEIVED ';

export const PROFILE_CONNECTIONS_RECEIVED = 'PROFILE_CONNECTIONS_RECEIVED';
export const PROFILE_CONNECTIONS_ADD = 'PROFILE_CONNECTIONS_ADD';
export const PROFILE_CONNECTIONS_ERROR = 'PROFILE_CONNECTIONS_ERROR';

export const PROFILE_CONNECTION_LEGALGUARDIANSTATUS =
  'PROFILE_CONNECTION_LEGALGUARDIANSTATUS';
export const PROFILE_CONNECTION_LEGALGUARDIANSTATUS_ERROR =
  'PROFILE_CONNECTION_LEGALGUARDIANSTATUS_ERROR';

export const PROFILE_SAVE = 'PROFILE_SAVE ';
export const PROFILE_SAVE_SUCCESS = 'PROFILE_SAVE_SUCCESS';
export const PROFILE_SAVE_ERROR = 'PROFILE_SAVE_ERROR';

export const PROFILE_UPDATE_FROM_SPAR = 'PROFILE_UPDATE_FROM_SPAR';
export const PROFILE_UPDATE_FROM_SPAR_SUCCESS =
  'PROFILE_UPDATE_FROM_SPAR_SUCCESS';
export const PROFILE_UPDATE_FROM_SPAR_ERROR = 'PROFILE_UPDATE_FROM_SPAR_ERROR';
export const PROFILE_UPDATE_FROM_SPAR_RATE_LIMITED =
  'PROFILE_UPDATE_FROM_SPAR_RATE_LIMITED';

export const PROFILE_VALIDATE = 'PROFILE_VALIDATE';
export const PROFILE_VALIDATE_SUCCESS = 'PROFILE_VALIDATE_SUCCESS';
export const PROFILE_VALIDATE_ERROR = 'PROFILE_VALIDATE_ERROR';

export const PROFILE_INVITATION_REQUEST = 'PROFILE_INVITATION_REQUEST';
export const PROFILE_INVITATION_REQUEST_SUCCESS =
  'PROFILE_INVITATION_REQUEST_SUCCESS';
export const PROFILE_INVITATION_REQUEST_ERROR =
  'PROFILE_INVITATION_REQUEST_ERROR';

export const PROFILE_FORGET_REQUEST = 'PROFILE_FORGET_REQUEST';
export const PROFILE_FORGET_REQUEST_SUCCESS = 'PROFILE_FORGET_REQUEST_SUCCESS';
export const PROFILE_FORGET_REQUEST_ERROR = 'PROFILE_FORGET_REQUEST_ERROR';

export const TICKETS_REQUEST = 'TICKETS_REQUEST';
export const TICKETS_REQUEST_SUCCESS = 'TICKETS_REQUEST_SUCCESS';
export const TICKETS_REQUEST_ERROR = 'TICKETS_REQUEST_ERROR';

export const SEND_CONTACT_INFO_VERIFICATION_REQUEST =
  'SEND_CONTACT_INFO_VERIFICATION_REQUEST';
export const SEND_CONTACT_INFO_VERIFICATION_SUCCESS =
  'SEND_CONTACT_INFO_VERIFICATION_SUCCESS';
export const SEND_CONTACT_INFO_VERIFICATION_ERROR =
  'SEND_CONTACT_INFO_VERIFICATION_ERROR';

export const VERIFY_CONTACT_INFO_REQUEST = 'VERIFY_CONTACT_INFO_REQUEST';
export const VERIFY_CONTACT_INFO_SUCCESS = 'VERIFY_CONTACT_INFO_SUCCESS';
export const VERIFY_CONTACT_INFO_ERROR = 'VERIFY_CONTACT_INFO_ERROR';

export const FETCH_CONTACT_INFO_VERIFICATION_REQUEST =
  'FETCH_CONTACT_INFO_VERIFICATION_REQUEST';
export const FETCH_CONTACT_INFO_VERIFICATION_SUCCESS =
  'FETCH_CONTACT_INFO_VERIFICATION_SUCCESS';
export const FETCH_CONTACT_INFO_VERIFICATION_ERROR =
  'FETCH_CONTACT_INFO_VERIFICATION_ERROR';

export const SET_CONTACT_INFO_VERIFICATION_BEING_VERIFIED =
  'SET_CONTACT_INFO_VERIFICATION_BEING_VERIFIED';
export const SET_CONTACT_INFO_VERIFICATION_REDIRECT_FUNC =
  'SET_CONTACT_INFO_VERIFICATION_REDIRECT_FUNC';

export const STATUS_TOO_MANY_REQUESTS = 429;

/**
 * TODO: Improve dispatch typing
 * @typedef {import('redux').Dispatch<any>} Dispatch
 */

/**
 * Helper function that accepts two strings (names) and returns a string with the
 * first letter of each string uppercased.
 *
 * @param {*} first
 * @param {*} last
 * @returns {string}
 */
export const createInitials = (first, last) => {
  return (
    ((typeof first === 'string' && first[0]) || '') +
    ((typeof last === 'string' && last[0]) || '')
  ).toUpperCase();
};

/**
 * This is typically called before starting a new verification flow.
 * Because we will be getting the info (ie. email, phone) from global store.
 * @param {{ info: string, type: string}} infoObject
 */
export const setContactInfoBeingVerified = (infoObject) => {
  return async (dispatch) => {
    dispatch({
      type: SET_CONTACT_INFO_VERIFICATION_BEING_VERIFIED,
      payload: infoObject,
    });
  };
};

/**
 * Just sets the redirectFunc. Please see the `./types.js` file for more info.
 * @param {*} f
 * @returns
 */
export const setContactInfoVerificationRedirectFunc = (f) => {
  return async (dispatch) => {
    dispatch({
      type: SET_CONTACT_INFO_VERIFICATION_REDIRECT_FUNC,
      payload: f,
    });
  };
};

/**
 * @param {*} info
 * @param {*} verificationType
 */
export const sendContactInfoVerificationCode = (info, verificationType) => {
  return async (dispatch) => {
    const url = 'api/v1/user/contactinfo/sendverification';

    dispatch({
      type: SEND_CONTACT_INFO_VERIFICATION_REQUEST,
    });

    // TODO: check, await might need to be inside

    const { error } = await dispatch(
      postApi(url, { info, verificationType, verificationMethod: 'code' })
    );
    if (error) {
      dispatch({
        type: SEND_CONTACT_INFO_VERIFICATION_ERROR,
        error,
      });

      return { error };
    }

    dispatch({
      type: SEND_CONTACT_INFO_VERIFICATION_SUCCESS,
    });

    return {};
  };
};

/**
 * @param {string} info
 * @param {string} secret
 * @returns {(dispatch: any) => Promise<{error: boolean, response: Response , message: 'LNGErrInternalSrv' | 'LNGInvalidData' | 'LNGVerifiedSuccessfully'}>}
 */
export const verifyContactInfo = (info, secret) => {
  return async (dispatch) => {
    const url = 'api/v1/user/contactinfo/verify';

    dispatch({
      type: VERIFY_CONTACT_INFO_REQUEST,
    });

    const { error, response, message } = await dispatch(
      postApi(url, { info, secret })
    );

    if (error) {
      dispatch({
        type: VERIFY_CONTACT_INFO_ERROR,
        error,
      });

      return { error, response, message };
    }

    dispatch({
      type: VERIFY_CONTACT_INFO_SUCCESS,
    });

    return {};
  };
};

export const verifyContactInfoByLink = (token) => {
  return async (dispatch) => {
    const url = 'api/v1/user/contactinfo/verify/link';

    return await dispatch(await postApi(url, { token }));
  };
};

export const getLatestContactInfoVerification = (type) => {
  return async (dispatch) => {
    const url = `api/v1/user/contactinfo/latestverification?type=${type}`;

    dispatch({
      type: FETCH_CONTACT_INFO_VERIFICATION_REQUEST,
    });

    const { error, json } = await dispatch(getApi(url));
    if (error) {
      dispatch({
        type: FETCH_CONTACT_INFO_VERIFICATION_ERROR,
        error,
      });

      return { error, isVerified: false };
    }

    const data = {
      info: json.info,
      isVerified: json.verified === true,
      type: json.type,
    };

    dispatch({
      type: FETCH_CONTACT_INFO_VERIFICATION_SUCCESS,
      payload: data,
    });

    return { error, ...data };
  };
};

export const selectContactInfoVerification = (info) => {
  return async (dispatch) => {
    // we use post method to be able send the sensitive info (not to send it in URL)
    const url = 'api/v1/user/contactinfo/getverification';

    dispatch({
      type: FETCH_CONTACT_INFO_VERIFICATION_REQUEST,
    });

    const { error, json } = await dispatch(postApi(url, { info }));
    if (error) {
      dispatch({
        type: FETCH_CONTACT_INFO_VERIFICATION_ERROR,
        error,
      });

      return { error, isVerified: false };
    }

    dispatch({
      type: FETCH_CONTACT_INFO_VERIFICATION_SUCCESS,
      payload: { info, isVerified: json.isVerified },
    });

    return { error, isVerified: json.isVerified === true };
  };
};

export function updateFromSpar() {
  return async (dispatch) => {
    dispatch({
      type: PROFILE_UPDATE_FROM_SPAR,
    });

    const response = await dispatch(
      postApi('/api/v1/user/profile/updatefromspar')
    );

    const { json: profile, error, status } = response;

    if (error) {
      switch (status) {
        case STATUS_TOO_MANY_REQUESTS: {
          dispatch({
            type: PROFILE_UPDATE_FROM_SPAR_RATE_LIMITED,
          });
          break;
        }
        default: {
          dispatch({
            type: PROFILE_UPDATE_FROM_SPAR_ERROR,
          });
          break;
        }
      }

      return { error };
    }

    dispatch({
      type: PROFILE_UPDATE_FROM_SPAR_SUCCESS,
    });

    dispatch({
      type: PROFILE_RECEIVED,
      payload: {
        profile,
      },
    });

    return {
      profile,
    };
  };
}

/**
 *
 * @returns
 */
export function fetchUserProfile() {
  return async (dispatch) => {
    dispatch({
      type: PROFILE_REQUEST,
    });

    const { json: profile, error } = await dispatch(
      getApi('/api/v1/user/profile')
    );

    if (error) return { error };

    profile.initials = createInitials(profile.firstName, profile.lastName);

    dispatch({
      type: PROFILE_RECEIVED,
      payload: {
        profile,
      },
    });

    return { profile, error: false };
  };
}

/**
 * Save user profile to backend.
 * @async
 *
 * @return {Object} error { error: false | ErrorReason }
 */
export const saveUserProfile =
  ({ contactInformation, poster = postApi }) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: PROFILE_SAVE,
      });

      /**
       * The API performs a POST like operation, so we need to pass along all the data that
       * should go in the users profile.
       */
      const profile = {
        ...getApiProfile(getState()),
        ...contactInformation,
      };

      const { message, error } = await dispatch(
        poster('/api/v1/user/profile', profile)
      );

      if (error) {
        dispatch({
          type: PROFILE_SAVE_ERROR,
          payload: {
            error,
            message,
          },
        });
      } else {
        dispatch({
          type: PROFILE_SAVE_SUCCESS,
          payload: {
            profile,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: PROFILE_SAVE_ERROR,
        payload: {
          error,
          message: '',
        },
      });
    }
  };

export function fetchUserInfo() {
  return async (dispatch) => {
    const { json: userInfo, error } = await dispatch(
      getApi('/api/v1/user/info')
    );

    if (error) return { error };

    dispatch({
      type: PROFILE_USERINFO_RECEIVED,
      payload: { userInfo },
    });

    return { error: false };
  };
}

export function fetchUserConnections() {
  return async (dispatch) => {
    const { json: connections, error } = await dispatch(
      getApi('/api/v1/user/children')
    );

    if (error) return { error };

    dispatch({
      type: PROFILE_CONNECTIONS_RECEIVED,
      payload: { connections },
    });

    return { connections, error: false };
  };
}

export function checkLegalGuardianStatus(childUuid) {
  return async (dispatch) => {
    const { json, error, message } = await dispatch(
      getApi(`/api/v1/user/children/${childUuid}/validateguardian`)
    );

    if (error) {
      dispatch({
        type: PROFILE_CONNECTIONS_ERROR,
        payload: {
          message,
        },
      });
      return false;
    }

    dispatch({
      type: PROFILE_CONNECTION_LEGALGUARDIANSTATUS,
      payload: { json },
    });

    // Both statuses "yes" (proper legal guardian) and "unknown" (e.g. identity
    // protection) is considered ok, but a "no" is always a no.
    if (json.legalGuardianStatus === 'no') {
      dispatch({
        type: PROFILE_CONNECTIONS_ERROR,
        payload: {
          message: 'LNGErrNotLegalGuardianOfChild',
        },
      });
      return false;
    }

    return true;
  };
}

/**
 * This function determines what endpoint should be used and posts the new child there.
 * The reason we currently have two end points is that the old API was heavily tied to
 * Swedish personnummer, and as we move to other contries, we need a different solution.
 *
 * As a first step, keep the old for Sweden since it works. The intention is to completely
 * do away with /connections/add, and only keep /children/add down the line.
 *
 */
export const addChild =
  ({ ssn }) =>
  async (dispatch) => {
    const connection = {
      ssn: ssn.replace('-', ''),
    };

    dispatch({
      type: PROFILE_CONNECTIONS_ADD,
      payload: {
        connection,
      },
    });

    const { json, error, message } = await dispatch(
      postApi('/api/v1/user/children/add', connection)
    );

    if (error) {
      dispatch({
        type: PROFILE_CONNECTIONS_ERROR,
        payload: {
          connection,
          message: json || message,
        },
      });
    } else {
      await dispatch(fetchUserConnections());
    }

    return { child: json, error };
  };

/**
 * TODO: Check why does this function return two different types
 */
export const getInvitations = () => async (dispatch) => {
  dispatch({
    type: PROFILE_INVITATION_REQUEST,
  });

  const { json, error } = await dispatch(getApi('/api/v1/invitations/all'));

  if (error) {
    dispatch({
      type: PROFILE_INVITATION_REQUEST_ERROR,
      error,
    });

    return error;
  }

  dispatch({
    type: PROFILE_INVITATION_REQUEST_SUCCESS,
    payload: json,
  });

  return { error: false };
};

export const requestToBeForgotten = () => async (dispatch) => {
  dispatch({
    type: PROFILE_FORGET_REQUEST,
  });

  const { json, error } = await dispatch(postApi('/api/v1/user/forget'));

  if (error) {
    dispatch({
      type: PROFILE_FORGET_REQUEST_ERROR,
      error,
    });

    return error;
  }

  dispatch({
    type: PROFILE_FORGET_REQUEST_SUCCESS,
    payload: json,
  });

  return { error: false };
};

export const validateRevisitId = (revisitId) => async (dispatch, getState) => {
  await dispatch(getInvitations());

  const valid = !!getOpenRevisitInvitationByInvitationId(getState(), revisitId);

  if (!valid) {
    dispatch(
      showNotification({
        type: 'error',
        message: _t('revisit.errors.invalid'),
      })
    );
  }

  return {
    valid,
  };
};

export const removeChild = (childUuid) => async (dispatch) => {
  const child = {
    childUuid,
  };

  const { error, message, json } = await dispatch(
    postApi('/api/v1/user/children/remove', child)
  );

  if (error) {
    dispatch(
      showNotification({
        message: message || json,
        type: 'error',
      })
    );
  } else {
    await dispatch(fetchUserConnections());
  }
};
