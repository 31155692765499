import { _t } from '@mindoktor/patient-legacy/i18n';
import React, { Component } from 'react';

import ContinueButton from '../../components/button/continue';

const styles = {
  root: {
    marginBottom: 20,
    marginLeft: 20,
    marginRight: 20,
  },

  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
};

export default class IntroScreen extends Component {
  render() {
    const { next } = this.props;

    return (
      <div style={styles.root}>
        <div style={styles.footer}>
          <ContinueButton onClick={next} text={_t('guides.reviewIntro.next')} />
        </div>
      </div>
    );
  }
}
