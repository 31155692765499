import Config from '@mindoktor/env/Config';

export const mdVaccinationInfoURL = `${
  Config.Urls?.web?.domain ?? ''
}/vaccination/`;
export const downloadAppLink = 'https://mdlink.se/vaccinationbook-web' as const;
export const storeURLIOS =
  'https://apps.apple.com/se/app/min-doktor-v%C3%A5rd-vaccin/id1104213750' as const;
export const storeURLAndroid =
  'https://play.google.com/store/apps/details?id=se.mindoktor.android' as const;
