import React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import ReviewStarColorIcon from '../../ReviewStarColorIcon/web';

const minMax = (min: number, value: number, max: number) =>
  Math.min(Math.max(min, value), max);

const iconColor = 'red';

interface Props {
  rating?: number;
  height?: string;
}

const ReviewStars: React.FC<Props> = ({ rating = 5.0, height = '1rem' }) => {
  const shownPercentage = (minMax(0, rating, 5) / 5) * 100;
  return (
    <Box width="max-content" height={height}>
      <Stack
        direction="row"
        spacing="0"
        sx={{
          overflowX: 'hidden',
        }}
        width={`${shownPercentage}%`}
      >
        <ReviewStarColorIcon fontSize={height} iconColor={iconColor} />
        <ReviewStarColorIcon fontSize={height} iconColor={iconColor} />
        <ReviewStarColorIcon fontSize={height} iconColor={iconColor} />
        <ReviewStarColorIcon fontSize={height} iconColor={iconColor} />
        <ReviewStarColorIcon fontSize={height} iconColor={iconColor} />
      </Stack>
    </Box>
  );
};

export default ReviewStars;
