import { _t } from '@mindoktor/patient-legacy/i18n';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Body1, SubHead } from '../../../common/components/typography';
import { getHealthProfileFormularyState } from '../../../state/healthprofile/selectors';
import HeartIcon from '../../components/icon/heart';

const styles = {
  root: {
    marginBottom: 10,
  },

  icon: {
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 10,
  },

  lead: {
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 10,
  },

  body: {
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 10,
  },
};

// type Props = {
//   childUuid?: string,

//   hasAnswers: boolean,
//   hasErrors: boolean,
// };

export class IntroScreen extends Component {
  getTexts = () => {
    const { childUuid, hasAnswers, hasErrors } = this.props;

    if (!hasErrors) {
      return childUuid
        ? {
            lead: _t('healthProfile.intro.completeLeadChild'),
            body: _t('healthProfile.intro.completeBodyChild'),
          }
        : {
            lead: _t('healthProfile.intro.completeLead'),
            body: _t('healthProfile.intro.completeBody'),
          };
    }

    if (!hasAnswers) {
      return childUuid
        ? {
            lead: _t('healthProfile.intro.emptyLeadChild'),
            body: _t('healthProfile.intro.emptyBodyChild'),
          }
        : {
            lead: _t('healthProfile.intro.emptyLead'),
            body: _t('healthProfile.intro.emptyBody'),
          };
    }

    return childUuid
      ? {
          lead: _t('healthProfile.intro.incompleteLeadChild'),
          body: _t('healthProfile.intro.incompleteBodyChild'),
        }
      : {
          lead: _t('healthProfile.intro.incompleteLead'),
          body: _t('healthProfile.intro.incompleteBody'),
        };
  };

  render() {
    const { lead, body } = this.getTexts();

    return (
      <div style={styles.root}>
        <HeartIcon style={styles.icon} />

        <SubHead style={styles.lead}>{lead}</SubHead>

        <Body1 style={styles.body}>{body}</Body1>
      </div>
    );
  }
}

export default connect((state, props) => {
  const { childUuid } = props;

  const { answers, errors } =
    getHealthProfileFormularyState(state, childUuid) || {};

  return {
    childUuid,

    hasAnswers:
      !!answers &&
      !!Object.keys(answers).find((a) => !a.startsWith('predefined.')),
    hasErrors: !!errors && !!Object.keys(errors).length,
  };
})(IntroScreen);
