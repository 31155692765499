import React, { Component } from 'react';

import spacing from '../../../common/spacing';
import Item from './item';

const styles = {
  item: {
    marginBottom: spacing.t,
  },

  lastItem: {
    marginBottom: 0,
  },
};

export default class List extends Component {
  render() {
    const { children, selectedChildUuid, onChildSelect, style } = this.props;
    return (
      <div style={style}>
        {children.map((child, i) => (
          <Item
            key={child.uuid}
            firstName={child.firstName}
            lastName={child.lastName}
            ssn={child.ssn}
            birthDate={child.birthDate}
            selected={child.uuid === selectedChildUuid}
            onClick={() => onChildSelect(child.uuid)}
            style={{
              ...styles.item,
              ...(i === children.length - 1 ? styles.lastItem : undefined),
            }}
          />
        ))}
      </div>
    );
  }
}
