import React from 'react';
import { connect } from 'react-redux';

import { postImageFile, postVideoFile } from '../../../state/files/actions';
import { hasImageExtension } from '../../../state/files/utils';
import { chooseFiles, uploadFile } from '../../utils/files';
import Button from '../button/button';

// type Props = {
//   max?: number,
//   types?: string,
//   location: string,

//   spinner?: React.Node,

//   onUpload: (response: Object) => any,
//   onError?: (response: Object) => any,

//   postImageFile?: (
//     path: string,
//     fn: () => any,
//     options?: { location?: string }
//   ) => any,
//   postVideoFile?: (
//     path: string,
//     fn: () => any,
//     options?: { location?: string }
//   ) => any,

//   track?: () => {},
// } & ButtonProps;

export class UploadButton extends React.Component {
  state = {
    submitting: false,
  };

  upload = async () => {
    const {
      types,
      max,
      onUpload,
      onError,
      postImageFile,
      postVideoFile,
      location,
      track,
    } = this.props;

    let paths;

    track && track();

    try {
      paths = await chooseFiles(types, max ? max > 1 : true);
    } catch (e) {
      return;
    }

    let posted = 0;

    if (paths.length) {
      this.setState({ submitting: true });
    }

    paths.slice(0, max || paths.length).forEach(async (path) => {
      const postFn = hasImageExtension(path) ? postImageFile : postVideoFile;

      const response = await postFn(path, uploadFile, { location });
      posted = posted + 1;
      if (posted === max || posted === paths.length) {
        this.setState({ submitting: false });
      }

      if (!response.error) {
        return onUpload && onUpload(response);
      }

      return onError && onError(response);
    });
  };

  render() {
    const { spinner } = this.props;
    const { submitting } = this.state;

    return submitting && spinner ? (
      spinner
    ) : (
      <Button
        {...this.props}
        onClick={this.upload}
        disabled={this.props.disabled || submitting}
      />
    );
  }
}

export default connect(undefined, { postImageFile, postVideoFile })(
  UploadButton
);
