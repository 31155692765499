import { _t } from '@mindoktor/patient-legacy/i18n';
import React from 'react';

import Button from '../../components/button/button';
import Markdown from '../../components/markdown';
import Modal from '../../components/modal';
import { hideModal } from '../../routing';
import { getSiteOptions } from '../../utils/site';

const bodies = {
  ea: 'För att Euro Accident Health & Care Insurance AB, organisationsnummer 556551-4766 ("Euro Accident") ska kunna bedöma och reglera det aktuella försäkringsfallet samt planera ett eventuell fortsatt vårdbehov kan Euro Accident behöva ta del av medicinsk information om dig. I syfte att MD International AB, organisationsnummer 556934-0580 ("Min Doktor") ska kunna lämna Euro Accident nödvändig information behöver vi ditt samtycke.\n\nSamtycket som du lämnar innebär att Min Doktor får lämna Euro Accident eller de företag Euro Accident eventuellt anlitar för skadereglering medicinsk information om dig. Samtycket upphör att gälla när försäkringsärendet hos Euro Accident är avslutat, det innebär att Euro Accident då inte längre får ta del av medicinsk information om dig från Min Doktor. Om du inte lämnar ditt samtycke till att Min Doktor får delge Euro Accident medicinsk information om dig, kan det innebära att Euro Accident inte har möjlighet att ersätta vidare behandling.\n\nGenom signering av denna handling lämnar jag mitt samtycke till att Min Doktor får lämna Euro Accident, eller de företag Euro Accident eventuellt anlitar för skadereglering de hälsouppgifter som Euro Accident eller de företag som Euro Accident anlitar för skadereglering bedömt är nödvändiga för att reglera den aktuella skadan.\n\nJag samtycker även till att Min Doktor får lämna ut mina patientjournaler till Euro Accident, eller de företag Euro Accident eventuellt anlitar för skadereglering, om Euro Accident, eller de företag Euro Accident eventuellt anlitar för skadereglering, efter att ha gått igenom det enskilda ärendet, bedömt tillgång patientjournaler som nödvändigt för att kunna reglera den aktuella skadan.',
  lf: 'För att Länsförsäkringar Sak Försäkringsaktiebolag (publ), organisationsnummer 502010-9681 ("Länsförsäkringar") ska kunna bedöma och reglera det aktuella försäkringsfallet samt planera ett eventuell fortsatt vårdbehov kan Länsförsäkringar behöva ta del av medicinsk information om dig. I syfte att MD International AB, organisationsnummer 556934-0580 ("Min Doktor") ska kunna lämna Länsförsäkringar nödvändig information behöver vi ditt samtycke.\n\nSamtycket som du lämnar innebär att Min Doktor får lämna Länsförsäkringar eller de företag Länsförsäkringar eventuellt anlitar för skadereglering medicinsk information om dig. Samtycket upphör att gälla när försäkringsärendet hos Länsförsäkringar är avslutat, det innebär att Länsförsäkringar då inte längre får ta del av medicinsk information om dig från Min Doktor. Om du inte lämnar ditt samtycke till att Min Doktor får delge Länsförsäkringar medicinsk information om dig, kan det innebära att Länsförsäkringar inte har möjlighet att ersätta vidare behandling.\n\nGenom signering av denna handling lämnar jag mitt samtycke till att Min Doktor får lämna Länsförsäkringar, eller de företag Länsförsäkringar eventuellt anlitar för skadereglering de hälsouppgifter som Länsförsäkringar eller de företag som Länsförsäkringar anlitar för skadereglering bedömt är nödvändiga för att reglera den aktuella skadan.\n\nJag samtycker även till att Min Doktor får lämna ut mina patientjournaler till Länsförsäkringar, eller de företag Länsförsäkringar eventuellt anlitar för skadereglering, om Länsförsäkringar, eller de företag Länsförsäkringar eventuellt anlitar för skadereglering, efter att ha gått igenom det enskilda ärendet, bedömt tillgång patientjournaler som nödvändigt för att kunna reglera den aktuella skadan.',
  skandia:
    'För att Försäkringsaktiebolaget Skandia (publ), organisationsnummer 502017-3083 ("Skandia") ska kunna bedöma och reglera det aktuella försäkringsfallet samt planera ett eventuell fortsatt vårdbehov kan Skandia behöva ta del av medicinsk information om dig. I syfte att MD International AB, organisationsnummer 556934-0580 ("Min Doktor") ska kunna lämna Skandia nödvändig information behöver vi ditt samtycke.\n\nSamtycket som du lämnar innebär att Min Doktor får lämna Skandia eller de företag Skandia eventuellt anlitar för skadereglering medicinsk information om dig. Samtycket upphör att gälla när försäkringsärendet hos Skandia är avslutat, det innebär att Skandia då inte längre får ta del av medicinsk information om dig från Min Doktor. Om du inte lämnar ditt samtycke till att Min Doktor får delge Skandia medicinsk information om dig, kan det innebära att Skandia inte har möjlighet att ersätta vidare behandling.\n\nGenom signering av denna handling lämnar jag mitt samtycke till att Min Doktor får lämna Skandia, eller de företag Skandia eventuellt anlitar för skadereglering de hälsouppgifter som Skandia eller de företag som Skandia anlitar för skadereglering bedömt är nödvändiga för att reglera den aktuella skadan.\n\nJag samtycker även till att Min Doktor får lämna ut mina patientjournaler till Skandia, eller de företag Skandia eventuellt anlitar för skadereglering, om Skandia, eller de företag Skandia eventuellt anlitar för skadereglering, efter att ha gått igenom det enskilda ärendet, bedömt tillgång patientjournaler som nödvändigt för att kunna reglera den aktuella skadan.',
  th: 'För att Trygg-Hansa Försäkring filial (publ), organisationsnummer 516404-4405 ("Trygg-Hansa") ska kunna bedöma och reglera det aktuella försäkringsfallet samt planera ett eventuell fortsatt vårdbehov kan Trygg-Hansa behöva ta del av medicinsk information om dig. I syfte att MD International AB, organisationsnummer 556934-0580 ("Min Doktor") ska kunna lämna Trygg-Hansa nödvändig information behöver vi ditt samtycke.\n\nSamtycket som du lämnar innebär att Min Doktor får lämna Trygg-Hansa eller de företag Trygg-Hansa eventuellt anlitar för skadereglering medicinsk information om dig. Samtycket upphör att gälla när försäkringsärendet hos Trygg-Hansa är avslutat, det innebär att Trygg-Hansa då inte längre får ta del av medicinsk information om dig från Min Doktor. Om du inte lämnar ditt samtycke till att Min Doktor får delge Trygg-Hansa medicinsk information om dig, kan det innebära att Trygg-Hansa inte har möjlighet att ersätta vidare behandling.\n\nGenom signering av denna handling lämnar jag mitt samtycke till att Min Doktor får lämna Trygg-Hansa, eller de företag Trygg-Hansa eventuellt anlitar för skadereglering de hälsouppgifter som Trygg-Hansa eller de företag som Trygg-Hansa anlitar för skadereglering bedömt är nödvändiga för att reglera den aktuella skadan.\n\nJag samtycker även till att Min Doktor får lämna ut mina patientjournaler till Trygg-Hansa, eller de företag Trygg-Hansa eventuellt anlitar för skadereglering, om Trygg-Hansa, eller de företag Trygg-Hansa eventuellt anlitar för skadereglering, efter att ha gått igenom det enskilda ärendet, bedömt tillgång patientjournaler som nödvändigt för att kunna reglera den aktuella skadan.',
  certico:
    'För att NLA (Nordic Loss Adjusting är ett fristående skaderegleringsföretag som på uppdrag av er försäkringsgivare via Nordeuropa Försäkring/ Säkra Direkt / Söderberg & Partners) ska kunna bedöma och reglera det aktuella försäkringsfallet samt planera ett eventuell fortsatt vårdbehov kan NLA behöva ta del av medicinsk information om dig. I syfte att MD International AB, organisationsnummer 556934-0580 ("Min Doktor") ska kunna lämna NLA nödvändig information behöver vi ditt samtycke.\n\nSamtycket som du lämnar innebär att Min Doktor får lämna NLA eller de företag NLA eventuellt anlitar för skadereglering medicinsk information om dig. Samtycket upphör att gälla när försäkringsärendet hos NLA är avslutat, det innebär att NLA då inte längre får ta del av medicinsk information om dig från Min Doktor. Om du inte lämnar ditt samtycke till att Min Doktor får delge NLA medicinsk information om dig, kan det innebära att NLA inte har möjlighet att ersätta vidare behandling.\n\nGenom signering av denna handling lämnar jag mitt samtycke till att Min Doktor får lämna NLA eller de företag NLA eventuellt anlitar för skadereglering de hälsouppgifter som NLA eller de företag som NLA anlitar för skadereglering bedömt är nödvändiga för att reglera den aktuella skadan.\n\nJag samtycker även till att Min Doktor får lämna ut mina patientjournaler till NLA, eller de företag NLA eventuellt anlitar för skadereglering, om NLA, eller de företag NLA eventuellt anlitar för skadereglering, efter att ha gått igenom det enskilda ärendet, bedömt tillgång patientjournaler som nödvändigt för att kunna reglera den aktuella skadan.',

  '': '',
};

const JournalConsentModal = () => (
  <Modal
    body={<Markdown source={bodies[getSiteOptions().key]} />}
    footer={
      <Button type="ghost" color="black" onClick={hideModal}>
        {_t('common.close')}
      </Button>
    }
  />
);

export default JournalConsentModal;
