import {
  ApiResponse,
  requestHandler,
} from '@mindoktor/patient-app/api/requestHandler';

import {
  ExperimentsPostFetchVariantRequest,
  ExperimentsPostFetchVariantResponse,
} from './models/experiments';
import { ExperimentsSchema } from './schema/experiment';

const BASE_URL = '/api/v1/ab/fetch-variant' as const;

const postFetchVariant = async (
  payload: ExperimentsPostFetchVariantRequest
): Promise<ApiResponse<ExperimentsPostFetchVariantResponse>> => {
  return await requestHandler.post(`${BASE_URL}`, payload, {
    validationSchema: ExperimentsSchema,
  });
};

export const experimentsApi = {
  postFetchVariant,
};
