import React, { FC } from 'react';

import ButtonBase from '../button/button';
import { Title1, Body1 } from '../../../common/components/typography';
import BoxedScreen from '../boxed_screen';
import TextInput from '../input/text';

import GuideBar from '../../guides/components/bar';
import styles from './styles';

import logo from '../../../../images/logo/heart_alone@2x.png';
import Img from '@mindoktor/pulse/src/utils/images/Img.web';

const Heart = () => <Img src={logo} style={styles.heart} />;

export enum ScreenVariant {
  GuideFlow = 'guideflow',
  Screen = 'screen',
}

const DEFAULT_SCREEN_VARIANT = ScreenVariant.GuideFlow;

interface ScreenProps {
  headerTitle?: string;
  headerIconSource?: string;
  children: React.ReactNode;
  onCancel?: () => void;
  footer?: React.ReactNode;
  warning?: React.ReactNode;
}

const Screen: FC<ScreenProps> = ({
  headerTitle,
  headerIconSource,
  onCancel,
  children,
  footer,
  warning,
}) => {
  return (
    <BoxedScreen
      header={
        <GuideBar
          title={headerTitle}
          iconSource={headerIconSource}
          onCancel={onCancel}
        />
      }
      body={children}
      footer={
        <>
          <div>
            <div style={styles.footer}>{footer}</div>
          </div>
          {warning}
        </>
      }
      showScrollGradient={true}
    />
  );
};

interface BodyProps {
  children: React.ReactNode;
  variant?: ScreenVariant;
}

const Body: FC<BodyProps> = ({
  children,
  variant = DEFAULT_SCREEN_VARIANT,
}) => {
  return (
    <div style={{ ...styles.wrapper.style, ...styles.wrapper[variant] }}>
      <div
        style={{
          ...styles.body.style,
          ...styles.body[variant],
        }}
      >
        {children}
      </div>
    </div>
  );
};

interface TitleProps {
  children: React.ReactNode;
}

const Title: FC<TitleProps> = ({ children }) => {
  return <Title1 style={styles.title}>{children}</Title1>;
};

interface SectionProps {
  children: React.ReactNode;
}

const Section: FC<SectionProps> = ({ children }) => {
  return <label style={styles.section}>{children}</label>;
};

interface LeadProps {
  children: React.ReactNode;
}

const Lead: FC<LeadProps> = ({ children }) => {
  return <Body1 style={styles.lead}>{children}</Body1>;
};

interface FooterButtonsProps {
  children?: React.ReactNode;
}

const FooterButtons: FC<FooterButtonsProps> = ({ children }) => {
  return <div style={styles.buttons}>{children}</div>;
};

interface ButtonProps {
  secondary?: boolean;
  children?: React.ReactNode;
  type?: 'solid' | 'flat' | 'outline';
  disabled?: boolean;
  onPress: () => void;
}

const Button: FC<ButtonProps> = ({
  secondary,
  children,
  type,
  onPress,
  disabled,
}) => {
  return (
    <ButtonBase
      hero={false}
      disabled={disabled}
      onClick={onPress}
      color={secondary ? 'secondary' : 'red'}
      style={styles.footerButton}
      type={type}
    >
      {children}
    </ButtonBase>
  );
};

interface InputProps {
  placeholder?: string;
  onChange?: (value: string) => void;
  value?: string;
  style?: any;
}

const Input: FC<InputProps> = ({ placeholder, onChange, value, style }) => {
  return (
    <TextInput
      value={value}
      placeholder={placeholder}
      autoFocus={true}
      onChange={onChange}
      onBlur={() => null}
      error={false}
      style={{ ...styles.input, ...style }}
    />
  );
};

export {
  Screen,
  Body,
  Section,
  Lead,
  Title,
  FooterButtons,
  Button,
  Input,
  Heart,
  Body1,
};
