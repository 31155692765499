import React, { useEffect } from 'react';

import { DataTrackingEvents } from '../api/models/dataTracking';
import { useDataTracking } from '../hooks/useDataTracking';

interface Props {
  path: string;
  enabled: boolean;
  children: React.ReactNode;
}

/** Wraps a component to provide automatic page-view tracking. */
const TrackPlainPageView: React.FC<Props> = ({ path, children, enabled }) => {
  const dataTracking = useDataTracking();
  useEffect(() => {
    if (enabled) {
      dataTracking.track(DataTrackingEvents.PageViewed, {});
    }
  }, [path, enabled]);
  return <>{children}</>;
};

export default TrackPlainPageView;
