import React, { useEffect, useRef, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { useLegacyAuth } from '@mindoktor/patient-app/adapters/hooks/useLegacyAuth';
import { getLogoutPageUrl } from '@mindoktor/patient-app/auth';
import { useMarketingTrackingApi } from '@mindoktor/patient-app/tracking/hooks/useMarketingTrackingApi';
import { useUserActivity } from '@mindoktor/patient-app/user/hooks/useUserActivity';

import { platform } from '../../utils/compatibility/platform/platform';
import { useIsAuthenticatedApi } from '../hooks/useIsAuthenticatedApi';
import { useLogoutMutator } from '../hooks/useLogoutMutator';

import { AuthenticationContext } from './AuthenticationContext';

interface Props {
  children: React.ReactNode;
}

const AuthenticationProvider: React.FC<Props> = ({ children }) => {
  const [isReady, setIsReady] = useState(platform.isWeb);
  const legacyAuth = useLegacyAuth();
  const isAuthenticated = useIsAuthenticatedApi(isReady);
  const logoutMutator = useLogoutMutator();
  const activityContext = useUserActivity();
  const queryClient = useQueryClient();
  const marketingTrackingApi = useMarketingTrackingApi();

  const logoutStartedRef = useRef(false);

  /** Logs the user out by sending a logout request and clearing authorization and user status */
  const logout = async (initiatedByUser = false) => {
    if (logoutStartedRef.current) {
      return;
    }

    marketingTrackingApi.cleanupSession();

    // Make sure we logout only once.
    logoutStartedRef.current = true;

    if (platform.isWeb) {
      // Redirect to special BE page that logs the user out
      // We need this to fix issues with ongoing requests that
      // might renew the token after logout, basically logging in again.
      (window as Window).location = getLogoutPageUrl();
      return;
    }

    try {
      // Dispatch and await legacy logout before fully logging out
      await legacyAuth.dispatchLogout(initiatedByUser);
      await logoutMutator.mutateAsync();
      await queryClient.cancelQueries();
      queryClient.clear();
    } catch (error) {
      console.error('LOGOUT went wrong', error);
    } finally {
      logoutStartedRef.current = false;
    }
  };

  useEffect(() => {
    // Auto logout mechanism based on the user activity.
    // NOTE: activity timeout value is set in config (UserActivityTimeout)
    if (
      !activityContext.isUserActive &&
      !activityContext.anyOtherTabActive &&
      isAuthenticated.isAuthenticated
    ) {
      void logout();
    }
  }, [activityContext, isAuthenticated]);

  useEffect(() => {
    const f = async () => {
      if (isReady) {
        return;
      }
      await logout();
      setIsReady(true);
    };
    void f();
  }, []);

  const value = {
    logout,
    isAuthenticated: isAuthenticated.isAuthenticated,
    isLoading: !isAuthenticated.isFetched,
  };

  return (
    <AuthenticationContext.Provider value={value}>
      {children}
    </AuthenticationContext.Provider>
  );
};

export default AuthenticationProvider;
