import { _t } from '@mindoktor/patient-legacy/i18n';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import Checkbox from '../../../common/components/input/Checkbox';
import { Body1, Title2 } from '../../../common/components/typography';
import { contactInformation } from '../../../state/profile/selectors';
import Button from '../../components/button/button';
// import type { ContactInformation } from '../../../state/profile/types';
import TextInput from '../../components/input/text';
import Modal from '../../components/modal';
import { hideModal } from '../../routing';

const postalCodeRegexp = /^(s-|S-)?[0-9]{3}\s?[0-9]{2}$/;

const styles = {
  title: {
    paddingBottom: 16,
  },

  lead: {
    paddingBottom: 16,
  },

  contact: {
    paddingBottom: 16,
  },

  label: {
    fontWeight: 500,
  },

  confirm: {
    marginBottom: 8,
  },

  deliveryAddressRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

// type Props = {
//   confirm: Function,
//   contactInformation: ContactInformation,
// };

// type State = {
//   deliveryAddress: {
//     enabled: boolean,
//     address?: string,
//     postalCode?: string,
//     city?: string,
//   },
// };

class SelfTestAddressModal extends Component {
  state = {
    deliveryAddress: {
      enabled: false,
    },
  };

  render() {
    const {
      confirm,
      contactInformation: {
        firstName,
        lastName,
        address,
        postalcode,
        city,
        identityProtection,
      },
    } = this.props;

    const { deliveryAddress } = this.state;

    const isDeliveryAddressValid = !deliveryAddress.enabled
      ? true
      : !!deliveryAddress.address &&
        postalCodeRegexp.test(deliveryAddress?.postalCode) &&
        !!deliveryAddress.city;

    const name = `${firstName} ${lastName} `;
    const postalAndCity = `${postalcode} ${city} `;

    const handleToggleCustomAddress = () => {
      this.setState({
        deliveryAddress: {
          enabled: !deliveryAddress.enabled,
          address: address,
          postalCode: postalcode,
          city: city,
        },
      });
    };

    const updateCustomAddressField = (field) => (value) => {
      const { deliveryAddress } = this.state;

      this.setState({
        deliveryAddress: {
          ...deliveryAddress,
          [field]: value,
        },
      });
    };

    const onConfirm = () => {
      const { deliveryAddress } = this.state;

      hideModal();

      confirm(deliveryAddress.enabled ? deliveryAddress : null);
    };

    return (
      <Modal
        body={
          <div>
            <Title2 style={styles.title}>
              {_t('referral.selfTestAddress.header')}
            </Title2>
            <Body1 style={styles.lead}>
              {_t('referral.selfTestAddress.body')}
            </Body1>

            <div style={styles.contact}>
              <Body1>{name}</Body1>
              <Body1>{address}</Body1>
              <Body1>{postalAndCity}</Body1>
            </div>

            {!identityProtection && (
              <div>
                <div>
                  <Checkbox
                    label={_t('referral.selfTestAddress.custom')}
                    checked={deliveryAddress.enabled}
                    onChange={() => handleToggleCustomAddress()}
                  />
                </div>

                {deliveryAddress.enabled && (
                  <div>
                    <div>
                      <label>
                        <Body1>{_t('common.address')}</Body1>
                        <TextInput
                          initialValue={deliveryAddress?.address}
                          placeholder={_t('common.address')}
                          onChange={updateCustomAddressField('address')}
                        />
                      </label>
                    </div>
                    <div style={styles.deliveryAddressRow}>
                      <label>
                        <Body1>{_t('common.postalcode')}</Body1>
                        <TextInput
                          initialValue={deliveryAddress?.postalCode}
                          placeholder={_t('common.postalcode')}
                          onChange={updateCustomAddressField('postalCode')}
                        />
                      </label>
                      <label>
                        <Body1>{_t('common.city')}</Body1>
                        <TextInput
                          initialValue={deliveryAddress?.city}
                          placeholder={_t('common.city')}
                          onChange={updateCustomAddressField('city')}
                        />
                      </label>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        }
        footer={
          <div>
            <Button
              style={styles.confirm}
              disabled={!isDeliveryAddressValid}
              onClick={onConfirm}
            >
              {_t('referral.selfTestAddress.confirm')}
            </Button>

            <Button type="flat" color="black" onClick={hideModal}>
              {_t('common.close')}
            </Button>
          </div>
        }
      />
    );
  }
}

export default connect((state) => ({
  contactInformation: contactInformation(state),
}))(SelfTestAddressModal);
