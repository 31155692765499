import React, { PropsWithChildren } from 'react';

import { Stack } from '@mui/material';

import { SnackbarListProps } from './types';

const SnackbarList: React.FC<PropsWithChildren<SnackbarListProps>> = ({
  children,
}) => {
  return (
    <Stack
      position="fixed"
      bottom={{ md: 0 }}
      width="100%"
      zIndex={1000}
      gap={0.5}
    >
      {children}
    </Stack>
  );
};

export default SnackbarList;
