import { getIn } from 'timm';

export const getUserAgent = (state) => getIn(state, ['app', 'userAgent']);
export const getDeviceId = (state) => getIn(state, ['app', 'deviceId']);
export const getSessionId = (state) => getIn(state, ['app', 'sessionId']);

export const getAppState = (state) => getIn(state, ['app', 'state']);

export const isAppActive = (state) => getAppState(state) === 'active';

export const isKeyboardVisible = (state) => !!state.app.keyboardVisible;

export const getAppOsAndVersion = (state) => ({
  os: state.app.os,
  version: state.app.version,
});

export const getAdvertisingId = (state) => state.app.advertisingId;
