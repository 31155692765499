import { TRACKING_TRACK, TRACKING_TRACK_ONCE } from './constants';

/** @deprecated Use `@mindoktor/patient-app/tracking` instead. */
export const track = (event, properties, receiver = ['product']) => ({
  type: TRACKING_TRACK,
  meta: {
    track: {
      event,
      properties,
      receiver,
    },
  },
});

/** @deprecated Use `@mindoktor/patient-app/tracking` instead. */
export const trackOnce = (event, properties, receiver = ['product']) => ({
  type: TRACKING_TRACK_ONCE,
  meta: {
    track: {
      event,
      properties,
      receiver,
      once: true,
    },
  },
});
