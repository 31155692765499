import { getApi } from '../api/actions';
import {
  WORKOUT_DETAILS_FETCH,
  WORKOUT_DETAILS_FETCH_ERROR,
  WORKOUT_DETAILS_FETCH_SUCCESS,
  WORKOUT_DISMISS,
  WORKOUT_SHOW,
} from './types';

export function showWorkout(cId) {
  return {
    type: WORKOUT_SHOW,
    payload: {
      cId,
    },
  };
}

export function dismissWorkout(cId) {
  return {
    type: WORKOUT_DISMISS,
    payload: {
      cId,
    },
  };
}

// Fetch workout details of a case
export function fetchWorkoutDetails(caseID) {
  return async (dispatch) => {
    dispatch({
      type: WORKOUT_DETAILS_FETCH,
    });

    const api = `/api/v1/cases/${caseID}/workout`;
    const { json, error } = await dispatch(getApi(api));
    if (error) {
      dispatch({
        type: WORKOUT_DETAILS_FETCH_ERROR,
      });

      return { error };
    }

    const preppedWorkouts = denormalizeWorkouts(mapWorkouts(json.workouts));

    dispatch({
      type: WORKOUT_DETAILS_FETCH_SUCCESS,
      payload: {
        workoutsById: preppedWorkouts,
        allWorkoutIds: Object.keys(preppedWorkouts),
      },
    });

    return { workouts: preppedWorkouts };
  };
}

function mapWorkouts(workouts = []) {
  if (!workouts) return [];

  return workouts.map((workout) => {
    return {
      id: workout.id,
      title: workout.title,
      description: workout.description,
      exercises: workout.exercises,
      videoLink: workout.videoLink,
    };
  });
}

function denormalizeWorkouts(workouts = []) {
  const data = {};

  workouts.forEach((w) => {
    data[w.id] = w;
  });

  return data;
}
