import { decode as atob } from 'base-64';

import { LOGOUT, UPDATE_TOKEN } from '../api/actions';
import { MESSAGING_ENABLED, MESSAGING_STATUS_CHANGE } from './actionTypes';

const claims = (token) => {
  try {
    if (!token) return {};
    return JSON.parse(atob(token.split('.')[1]));
  } catch (err) {
    console.error(err);
    return {};
  }
};

const messagingToken = (token) => {
  const { messagingToken } = claims(token) || {};
  return messagingToken;
};

const reducer = (
  state = {
    isConnected: false,
    enabled: false,
    host: null,
    prefix: null,
    token: null,
  },
  action
) => {
  switch (action.type) {
    case MESSAGING_ENABLED: {
      return {
        ...state,
        enabled: true,
      };
    }
    case MESSAGING_STATUS_CHANGE: {
      switch (action?.payload?.change) {
        case 'connected':
          return {
            ...state,
            isConnected: true,
          };
        case 'disconnected':
          return {
            ...state,
            isConnected: false,
          };
        default:
          return state;
      }
    }
    case UPDATE_TOKEN:
      const { payload: { token: accessToken } = {} } = action;
      const token = messagingToken(accessToken);
      if (!token) return state;
      const { rtmHost } = claims(token) || {};
      return { ...state, token, rtmHost: 'wss://' + rtmHost };
    case LOGOUT:
      return {
        ...state,
        token: null,
      };
    default:
      return state;
  }
};

export default reducer;
