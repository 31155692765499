/** Size of the checkbox path */
export const checkboxVisualSize = 28;

/** Size of the SVG containing the checkbox path */
export const checkboxWrapperSize = (checkboxVisualSize / 3) * 4;

export const borderWidth = 2; // px

/** Size of the inner element without the border */
export const checkboxInnerSize = checkboxVisualSize - borderWidth * 2;

export const margin = (checkboxWrapperSize - checkboxVisualSize) / 2;
