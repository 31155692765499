import { ThemeOptions } from '@mui/material/styles';

import { colors } from '@mindoktor/pulse/src/styles/colors';

const overrides: ThemeOptions['components'] = {
  MuiFilledInput: {
    defaultProps: {},
    styleOverrides: {
      root: {
        '&.Mui-error': {
          backgroundColor: colors.yellow.L500,
        },
      },
      underline: {
        '&:after': {
          borderBottomColor: colors.red[400],
        },
        '&.Mui-error:after': {
          borderBottomColor: colors.yellow[500],
        },
      },
    },
  },
};

const muiFilledInputOverrides = overrides.MuiFilledInput;
export default muiFilledInputOverrides;
