import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { UseBreakpointStyleFunction } from './types';

/**
 * Provides breakpoint matches, similarly to how they are handled in MUI sx prop.
 *
 * See more: https://mui.com/system/getting-started/usage/#responsive-values
 * @example const { matches } useBreakpointStyle
 * return <MDLogoFullIcon fontSize={matches.md ? 4 : matches.sm ? 3.15 : 2.5} />
 */
export const useBreakpointStyle = (): UseBreakpointStyleFunction => {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.up('xs'));
  const sm = useMediaQuery(theme.breakpoints.up('sm'));
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const lg = useMediaQuery(theme.breakpoints.up('lg'));
  const xl = useMediaQuery(theme.breakpoints.up('xl'));

  const matches = {
    xs,
    sm,
    md,
    lg,
    xl,
  };

  return {
    matches,
  };
};
