import React from 'react';

import colors from '../../../common/colors';
import MediaQuery from '../media_query';

const styles = {
  root: {
    borderTopWidth: 2,
    borderTopStyle: 'solid',
  },

  wide: {
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.18)',
  },
};

// export type Props = {
//   color?: string,
//   style?: any,
//   children?: any,
// };

const Card = ({ color = colors.red_500, style, children }) => [
  <MediaQuery key="narrow" maxWidth={600}>
    <div
      style={{
        ...styles.root,
        ...style,
        borderTopColor: color,
      }}
    >
      {children}
    </div>
  </MediaQuery>,

  <MediaQuery key="wide" minWidth={601}>
    <div
      style={{
        ...styles.root,
        ...styles.wide,
        ...style,
        borderTopColor: color,
      }}
    >
      {children}
    </div>
  </MediaQuery>,
];

export default Card;
