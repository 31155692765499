import { REHYDRATE } from 'redux-persist/es/constants';
import { getIn } from 'timm';

import {
  FEATUREFLAGS_REQUEST,
  FEATUREFLAGS_REQUEST_ERROR,
  FEATUREFLAGS_REQUEST_SUCCESS,
  FEATUREFLAGS_UPDATE_FLAG_ENABLED,
} from './types';

export const initialState = {
  flags: /** @type {{enabled: boolean, key: string}[]} */ ([]),
  persistedFlags: {},
  fetched: false,
  pending: false,
};

export default function featureFlagsReducer(state = initialState, action) {
  switch (action.type) {
    case FEATUREFLAGS_REQUEST:
      return {
        ...state,
        pending: true,
      };

    case FEATUREFLAGS_REQUEST_SUCCESS:
      const persistedFlags = state.persistedFlags || {};
      return {
        ...state,
        fetched: true,
        pending: false,
        flags: action.payload.flags
          .map((flag) => ({
            ...flag,
            enabledInApi: flag.enabled,
          }))
          .map((flag) => {
            return {
              ...flag,
              enabled:
                persistedFlags[flag.key] !== undefined
                  ? persistedFlags[flag.key]
                  : flag.enabled,
            };
          }),
      };

    case FEATUREFLAGS_REQUEST_ERROR:
      return {
        ...state,
        pending: false,
      };

    case FEATUREFLAGS_UPDATE_FLAG_ENABLED:
      const { payload = {} } = action;
      return {
        ...state,
        flags: state.flags.map((flag) => {
          if (flag.key === payload.key) {
            return {
              ...flag,
              enabled: payload.value,
            };
          }
          return flag;
        }),
      };

    case REHYDRATE:
      const flags = getIn(action, ['payload', 'featureFlags', 'flags']) || {};
      return {
        ...state,
        persistedFlags: flags,
      };

    default:
      return state;
  }
}
