import { useFeatureFlag } from '@mindoktor/patient-app/utils/featureFlags';

export const LONG_WAITING_TIME_WARNING_FEATURE_FLAG =
  'long-waiting-time-warning';

export const useIsLongWaitingTimeWarning = (): boolean => {
  const isLongWaitingTimeWarning = useFeatureFlag(
    LONG_WAITING_TIME_WARNING_FEATURE_FLAG
  ).isEnabled;
  return isLongWaitingTimeWarning;
};
