import React from 'react';

import Colors from '../../colors';
import View from '../../components/primitives/View';

const styles = {
  message: {
    padding: 16,
    backgroundColor: Colors.white,
  },
};

export default ({ children, flipVertically }) => {
  const style = {
    ...styles.message,
    ...(flipVertically ? { transform: [{ scaleY: -1 }] } : {}),
  };
  return (
    <View style={style} mb="xs">
      {children}
    </View>
  );
};
