import { requestHandler } from '../../api/requestHandler';

import { BASE_URL_FILES } from './constants';

interface Options {
  thumbnail: boolean;
}

export const getFileUri = (fileId: number, options?: Options): string => {
  const { baseUrl } = requestHandler.getApiConfig();
  return `${baseUrl}${BASE_URL_FILES}${fileId}${
    options?.thumbnail === true ? '?thumb=true' : ''
  }`;
};
