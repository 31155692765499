import 'rc-slider/assets/index.css';

import RcSlider from 'rc-slider';
import React, { Component } from 'react';

import colors from '../../../common/colors';

const styles = {
  wrapper: {
    marginTop: 11,
    marginBottom: 11,
  },

  handle: {
    height: 28,
    width: 28,
    marginLeft: -14,
    marginTop: -11,
    backgroundColor: colors.red_500,
    borderColor: colors.red_500,
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.35)',
  },

  track: {
    backgroundColor: '#bbb',
    height: 6,
  },

  rail: {
    backgroundColor: '#bbb',
    height: 6,
  },
};

// type Props = {
//   value?: number,
//   min?: number,
//   max?: number,

//   onChange?: (value?: any) => any,

//   style?: any,
//   disabled?: boolean,
//   step?: number,
// };

export default class Slider extends Component {
  onChange = (value) => {
    const { onChange } = this.props;

    onChange && onChange(value);
  };

  static defaultProps = {
    step: 0.1,
  };

  render() {
    const { value, min, max, style, disabled, step } = this.props;

    return (
      <div style={style}>
        <div style={styles.wrapper}>
          <RcSlider
            defaultValue={value || (max - min) / 2}
            min={min}
            max={max}
            step={step}
            trackStyle={styles.track}
            handleStyle={styles.handle}
            railStyle={styles.rail}
            onChange={this.onChange}
            disabled={disabled}
          />
        </div>
      </div>
    );
  }
}
