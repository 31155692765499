import React, { FC } from 'react';
import { _t } from '@mindoktor/patient-legacy/i18n';

import Modal from '../components/modal';

import { Title1 } from '../../common/components/typography';
import Button from '../components/button/button';
import { openRoute, routes as legacyRoutes } from '../../state/routing';
import withStyles, { ClassNameMap } from '@material-ui/core/styles/withStyles';
import Markdown from '../components/markdown';
import { getRouteParam } from '../../state/routing/utils';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { Theme } from '@material-ui/core';
import { getConversationId } from '../../state/cases/selectors';
import { openChatRoute } from '../utils/openChatRoute';
import { useNavigation } from '@mindoktor/patient-app/routing/web';
import { hideModal } from '../routing';

const styles = (theme: Theme) => ({
  submit: {
    marginBottom: theme.spacing(1),
  },
});

interface Props {
  classes: ClassNameMap<keyof ReturnType<typeof styles>>;
}

const VerificationRisksModal: FC<Props> = ({ classes }) => {
  const dispatch = useAppDispatch();
  const navigation = useNavigation();
  const { caseId } = useAppSelector((state) => ({
    caseId: getRouteParam(state, 'caseId'),
  }));

  const conversationId = useAppSelector((state) => {
    return getConversationId(state, caseId);
  });

  const goToVerifyContactInfo = () => {
    return dispatch(
      openRoute(legacyRoutes.CONTACT_INFO_VERIFICATION_GUIDE_VERIFICATION, {
        caseId,
      })
    );
  };

  const lineBreak = '\n\n';
  return (
    <Modal
      onBackgroundClick={null}
      body={
        <>
          <Title1 mb="xt">
            {_t('guides.contactinfo.verification.risks.title')}
          </Title1>
          <Markdown
            source={
              _t('guides.contactinfo.verification.risks.body1') +
              lineBreak +
              _t('guides.contactinfo.verification.risks.body2')
            }
          />
        </>
      }
      footer={
        <>
          <Button
            color="red"
            className={classes.submit}
            onClick={goToVerifyContactInfo}
          >
            {_t('guides.contactinfo.verification.confirm.email')}
          </Button>

          <Button
            type="outline"
            color="black"
            onClick={() => {
              hideModal();
              openChatRoute(dispatch, navigation, caseId, conversationId);
            }}
          >
            {_t('guides.contactinfo.verification.risks.decline')}
          </Button>
        </>
      }
    />
  );
};

export default withStyles(styles)(VerificationRisksModal);
