import React from 'react';
import Img from '@mindoktor/pulse/src/utils/images/Img.web';

import { getSpacingStyle } from '../../../spacing';
import { nativeToWeb } from '../../../styleUtils';
import { PrimitiveImageProps } from './types';
import type { CompatImgSrc } from '@mindoktor/pulse/src/utils/images/types';

const Image: React.FC<PrimitiveImageProps> = ({
  source,
  style,
  className,
  mb,
  mt,
  ml,
  mr,
}) => {
  const spacingStyle = getSpacingStyle({
    mb,
    mt,
    ml,
    mr,
  });
  const webStyle = style
    ? nativeToWeb([style, spacingStyle])
    : nativeToWeb([spacingStyle]);
  return (
    <Img src={source as CompatImgSrc} className={className} style={webStyle} />
  );
};
export default Image;
