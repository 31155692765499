import { Keys, Values } from '@mindoktor/patient-app/utils/types/objects';

interface DataTrackingEventURI {
  full: string;
  /** E.g. `https` */
  schema: string;
  host: string;
  path: string;
  query?: string;
  /** E.g. `'/guides/15/questions?origin=guidePicker'` */
  fragment?: string;
}

export const DataTrackingEvents = {
  PageViewed: 'page.viewed',
} as const;

export type DataTrackingEventKey = Keys<typeof DataTrackingEvents>;
export type DataTrackingEvent = Values<typeof DataTrackingEvents>;

export const DataTrackingAppStates = {
  appStart: 'appStart',
  login: 'login',
} as const;
export type DataTrackingAppState = Values<typeof DataTrackingAppStates>;

export const DataTrackingJourneyEvents = {
  LoginStarted: 'Login.Started',
  LoginCompleted: 'Login.Completed',
  RegistrationStarted: 'Registration.Started',
  RegistrationCompleted: 'Registration.Completed',
  TOSPrivacyUpdated: 'TOSPrivacy.Updated',
  UserDetailsUpdated: 'UserDetails.Updated',
} as const;
export type DataTrackingJourneyEventKey = Keys<
  typeof DataTrackingJourneyEvents
>;
export type DataTrackingJourneyEvent = Values<typeof DataTrackingJourneyEvents>;

export const DataTrackingSteps = {
  guide: 'guide',
  healthProfileIntro: 'healthProfileIntro',
  healthProfile: 'healthProfile',
  reviewIntro: 'reviewIntro',
  guideSummary: 'guideSummary',
  healthProfileSummary: 'healthProfileSummary',
  cart: 'cart',
} as const;
export type DataTrackingStep = Values<typeof DataTrackingJourneyEvents>;

type GuidesListCategory = 'adult' | 'child';

export interface DataTrackingEventData {
  journeyEvent?: DataTrackingJourneyEvent;
  entrywayId?: number | GuidesListCategory;
  /** E.g. `'guidePicker'` */
  origin?: string;
  step?: DataTrackingStep;
  previousStep?: DataTrackingStep;
  caseId?: number;
  childUuid?: string;
  currentGuideNode?: string;
  paymentMethod?: string;
  hasValidFreePass?: boolean;
  hasValidEFrikort?: boolean;
  dynamicCode?: string;
  preferredCaregiverId?: number;
  state?: DataTrackingAppState;
}

export interface DataTrackingEventBaseRequest {
  sessionId: string;
  /** URL */
  referrer: string;
  /** Timestamp */
  createdAt: string;
  uri: DataTrackingEventURI;
}

export interface DataTrackingEventRequest {
  eventName: DataTrackingEvent;
  data: DataTrackingEventData;
}

export interface DataTrackingEventResponse {
  msg: '';
  data: null;
}
