import React, { Component } from 'react';

import colors from '../../common/colors';
import { fonts } from '../../common/components/typography';

const styles = {
  root: {
    display: 'block',
    fontFamily: fonts.body,
    fontWeight: 500,
    fontSize: 18,
    lineHeight: '40px',
    cursor: 'pointer',
    textDecoration: 'none',
    color: colors.off_black,
  },
};

interface Props {
  color?: string;
  style?: React.CSSProperties;
  highlightStyle?: React.CSSProperties;
  children?: React.ReactNode;
  href?: string;
  target?: React.HTMLAttributeAnchorTarget;
  onClick?: () => void;
}

export default class Item extends Component<Props> {
  state = {
    hover: false,
  };

  onMouseEnter = () => this.setState({ hover: true });

  onMouseLeave = () => this.setState({ hover: false });

  render() {
    const {
      style,
      highlightStyle = { color: colors.red_500 },
      children,
      href,
      target,
      onClick,
    } = this.props;
    const { hover } = this.state;

    return (
      <a
        style={{
          ...styles.root,
          ...style,
          ...(hover ? highlightStyle : undefined),
        }}
        href={href}
        target={target}
        onClick={onClick}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        {children}
      </a>
    );
  }
}
