import { getFormularyState } from '../formulary/selectors';
import {
  FORMULARY_LOAD,
  FORMULARY_LOAD_SUCCESS,
  FORMULARY_POST,
  FORMULARY_POST_ERROR,
  FORMULARY_POST_SUCCESS,
} from '../formulary/types';
import { toFormularyKey } from '../formulary/utils';
import { getHealthProfile, postHealthProfile } from './api';
import { getHealthProfileFormularyKey } from './selectors';

export const HEALTH_PROFILE_SET_FORMULARY = 'HEALTH_PROFILE_SET_FORMULARY';

/**
 * @typedef LoadHealthProfileOptions
 * @prop {string} childUuid,
 * @prop {number=} initialIndex,
 * @prop {boolean=} includeState,
 * @prop {boolean=} skipOptionalQuestions,
 * @prop {boolean=} skipReload,
 */

/**
 * @typedef LoadHealthProfileReturn
 * @prop {string} childUuid,
 * @prop {number=} initialIndex,
 * @prop {boolean=} includeState,
 * @prop {boolean=} skipOptionalQuestions,
 * @prop {boolean=} skipReload,
 */
export function loadHealthProfile(
  /** @type {LoadHealthProfileOptions} */ {
    childUuid,
    initialIndex,
    includeState,
    skipOptionalQuestions,
    skipReload,
  } = {}
) {
  return async (dispatch, getState) => {
    dispatch({ type: FORMULARY_LOAD });

    let formularyKey;
    let formulary;
    let answers;

    if (skipReload) {
      formularyKey = getHealthProfileFormularyKey(getState(), childUuid);

      const formularyState = getFormularyState(getState(), formularyKey);

      if (!formularyState) return { error: true };

      formulary = formularyState.formulary;
      answers = formularyState.answers;
    } else {
      const healthProfile = await dispatch(getHealthProfile(childUuid));

      if (healthProfile.error) return { error: true };

      formulary = healthProfile.formulary;
      answers = healthProfile.answers;

      formularyKey = toFormularyKey({
        childUuid,
        formularyId: formulary.id,
        formularyVersion: formulary.version,
      });
    }

    dispatch({
      type: FORMULARY_LOAD_SUCCESS,
      payload: {
        childUuid,
        formularyKey,
        formulary,
        answers,
        index: initialIndex || formulary.index,
        skipDrafts: true,
        skipAnswered: true,
        skipOptionalQuestions,
      },
    });

    dispatch({
      type: HEALTH_PROFILE_SET_FORMULARY,
      payload: {
        childUuid,
        formularyId: formulary.id,
        formularyVersion: formulary.version,
      },
    });

    if (includeState) {
      return {
        formularyKey,
        formularyState: getFormularyState(getState(), formularyKey),
      };
    }

    return { formularyKey };
  };
}

export function saveHealthProfile({ childUuid } = {}) {
  return async (dispatch, getState) => {
    const state = getState();

    const formularyKey = getHealthProfileFormularyKey(state, childUuid);

    const formularyState = getFormularyState(state, formularyKey);

    if (!formularyState) return { error: true };

    const { formulary, answers, posting } = formularyState;

    if (posting) return { posting: true };

    dispatch({ type: FORMULARY_POST, payload: { formularyKey } });

    const { error } = await dispatch(
      postHealthProfile(formulary.id, formulary.version, answers, childUuid)
    );

    if (error) {
      dispatch({
        type: FORMULARY_POST_ERROR,
        payload: {
          formularyKey,
          formularyId: formulary.id,
          formularyVersion: formulary.version,
          error,
        },
      });
    } else {
      dispatch({
        type: FORMULARY_POST_SUCCESS,
        payload: { formularyKey },
      });
    }

    return { error };
  };
}
