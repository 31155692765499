const formats = {
  number: {
    SEK: {
      style: 'currency',
      currency: 'SEK',
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    },
    EUR: {
      style: 'currency',
      currency: 'EUR',
      maximumFractionDigits: 2,
    },
    GBP: {
      style: 'currency',
      currency: 'GBP',
      maximumFractionDigits: 2,
    },
  },
};

export default formats;
