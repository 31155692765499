import { _t } from '@mindoktor/patient-legacy/i18n';
import React from 'react';

import Text from './Text';

export default class Number extends React.Component {
  update = (value) => {
    const { update } = this.props;

    update(isNaN(parseInt(value, 10)) ? undefined : parseInt(value, 10));
  };

  render() {
    const { value, error, unit, done } = this.props;

    return (
      <Text
        {...{
          type: 'number',
          placeholder: unit && _t('formulary.question.unitsIn', { unit }),
          multiline: false,
          value,
          error,
          update: this.update,
          done,
        }}
      />
    );
  }
}
