import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { useNotifications } from '@mindoktor/patient-app/adapters/hooks/useNotifications';
import { ResponseError } from '@mindoktor/patient-app/api/responseError';
import { useTranslation } from '@mindoktor/patient-app/localization/hooks/useTranslation';
import { TIME } from '@mindoktor/patient-app/utils/time/constants';

import { authApi } from '../api/auth';
import { IsAuthenticated } from '../api/models/isAuthenticated';

type IsAuthenticatedResult = UseQueryResult<
  IsAuthenticated | undefined,
  ResponseError
>;

export const authenticationQueryKey = 'authentication';

export const useIsAuthenticatedApi = (
  isEnabled: boolean
): IsAuthenticatedResult & {
  isAuthenticated: boolean;
} => {
  const notifications = useNotifications();
  const t = useTranslation('error');

  const queryResult = useQuery<IsAuthenticated | undefined, ResponseError>(
    [authenticationQueryKey],
    async () => {
      const response = await authApi.getIsAuthenticated();

      if (!response?.ok) {
        throw new ResponseError(response);
      }
      return response?.data;
    },
    {
      enabled: isEnabled,
      retry: false,
      staleTime: 30 * TIME.SECOND,
      onError: (_error) => {
        notifications.showWarning(t('unknown'));
      },
    }
  );

  return {
    isAuthenticated: queryResult.data?.isAuthenticated ?? false,
    ...queryResult,
  };
};
