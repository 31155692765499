import React, { ComponentProps } from 'react';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';

import { FontVariant } from '../../../styles/fonts';

type LinkProps = ComponentProps<typeof Link>;
type StackProps = ComponentProps<typeof Stack>;

interface Props {
  variant?: FontVariant;
  color?: LinkProps['color'];
  noUnderline?: boolean;
  spacing?: StackProps['spacing'];
  onClick?: LinkProps['onClick'];
  children?: LinkProps['children'];
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
}

const defaults: Omit<Omit<Required<Props>, 'onClick'>, 'color'> = {
  variant: 'body1',
  noUnderline: true,
  spacing: 0,
  children: null,
  startIcon: null,
  endIcon: null,
};

/**
 * Enriched version of  the `TextLink` component with start/end icons and similar.
 */
const ContainerLink: React.FC<Props> = ({
  variant,
  color,
  noUnderline,
  spacing,
  onClick,
  children,
  startIcon,
  endIcon,
}) => {
  return (
    <Link
      variant={variant ?? defaults.variant}
      color={color}
      underline={noUnderline ?? defaults.noUnderline ? 'none' : 'always'}
      onClick={onClick}
      sx={{
        '&:hover .MuiSvgIcon-root': {
          filter: 'brightness(115%)',
        },
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        spacing={spacing ?? defaults.spacing}
      >
        {startIcon}
        <Box>{children}</Box>
        {endIcon}
      </Stack>
    </Link>
  );
};

export type ContainerLinkProps = Props;
export default ContainerLink;
