import { _t } from '@mindoktor/patient-legacy/i18n';
import React, { Component, Fragment } from 'react';

import { SubHead, Title1 } from '../../../common/components/typography';
import Button from '../../components/button/button';
import TextInput from '../../components/input/text';
import Modal from '../../components/modal';

const styles = {
  title: {
    marginTop: 20,
  },

  lead: {
    marginTop: 24,
    marginBottom: 24,
  },

  submit: {
    marginBottom: 8,
  },

  image: {
    display: 'block',
    width: 222,
    height: 92,
  },
};

// type Props = {
//   onConfirm: () => Promise<*>,
//   onCancel: () => void,
// };

// type State = {
//   disableButtons: false,
//   reason: string,
// };

export default class CancelCaseModal extends Component {
  state = {
    disableButtons: false,
    reason: '',
  };

  confirm = () => {
    this.setState((state) => ({ ...state, disableButtons: true }));
    this.props.onConfirm(this.state.reason);
  };

  onReasonTextHasChanged = (text) => {
    this.setState({
      reason: text,
    });
  };

  render() {
    return (
      <Modal
        body={
          <Fragment>
            <Title1 mb="xt">{_t('case.abort.question')}</Title1>
            <SubHead mb="xt">{_t('case.abort.reason')}</SubHead>
            <TextInput
              initialValue=""
              placeholder={_t('case.abort.placeholder')}
              onChange={this.onReasonTextHasChanged}
            />
          </Fragment>
        }
        footer={
          <Fragment>
            <Button
              color="red"
              style={styles.submit}
              onClick={this.confirm}
              disabled={this.state.disableButtons}
            >
              {_t('case.abort.caption')}
            </Button>

            <Button
              type="ghost"
              color="black"
              disable={this.state.disableButtons}
              onClick={this.props.onCancel}
            >
              {_t('case.abort.reject')}
            </Button>
          </Fragment>
        }
      />
    );
  }
}
