import React, { Component } from 'react';

import ContinueButton from '../../components/button/continue';

// type Props = {
//   error?: string,
//   next: () => any,
// };

const styles = {
  footer: {
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
  },

  next: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
};

export default class Choice extends Component {
  render() {
    const { error, next } = this.props;

    return (
      <div style={styles.footer}>
        <div style={styles.next}>
          <ContinueButton disabled={!!error} onClick={next} />
        </div>
      </div>
    );
  }
}
