import { z } from 'zod';

export const BankIDStartSchema = z.object({
  orderRef: z.string(),
  autoStartToken: z.string(),
  qrData: z.string(),
});

export const BankIDStatuses = {
  PENDING: 'pending',
  COMPLETE: 'complete',
  FAILED: 'failed',
} as const;

const BankIDStatus = z.nativeEnum(BankIDStatuses);

export const BankIDCollectSchema = z.object({
  bankIdStatus: BankIDStatus,
  bankIdHintCode: z.string(),
  qrData: z.string(),
});
