import { useAppDispatch } from '@mindoktor/patient-legacy/src/state/hooks';
import { openRoute } from '@mindoktor/patient-legacy/src/state/routing';

type openRouteProps = Parameters<typeof openRoute>;

export const useLegacyNavigation = () => {
  const dispatch = useAppDispatch();
  const navigate = (...props: openRouteProps) => dispatch(openRoute(...props));
  return { navigate };
};
