import debounce from 'lodash-es/debounce';
import React from 'react';

// export type ChildProps<Item> = {
//   hint?: string,
//   isSearching: boolean,
//   results: Item[],
//   onQueryChange: string => Promise<void>,
//   onSelect: Item => void,
//   itemKeyExtractor?: Item => string,
//   defaultValue?: string,
// };

// type Props<Item> = {
//   hint?: string,
//   search: string => Promise<Item[]>,
//   onSelect: Item => any,
//   onForbidden: Item => any,
//   children: (props: ChildProps<Item>) => React.Component<ChildProps<Item>>,
//   itemKeyExtractor?: Item => string,
// };

// type State<Item> = {
//   isSearching: boolean,
//   results: Item[],
// };

export default class SearchModalWrapper extends React.Component {
  state = { results: [], isSearching: false };

  _onQueryChange = async (query) => {
    this.setState({ isSearching: true });

    const {
      props: { search },
    } = this;
    this.setState({ results: await search(query), isSearching: false });
  };

  // This behaves irratic. I don't know if there is a bug in the lodash implementation
  // Sometimes it doesn't respect the waiting time, and often it executes on leading
  onQueryChange = debounce(this._onQueryChange, 200, {
    leading: false,
    trailing: true,
  });

  render() {
    const {
      onQueryChange,
      props: { children, onSelect, hint, itemKeyExtractor },
      state: { results, isSearching },
    } = this;

    const childProps = {
      onQueryChange,
      results,
      isSearching,
      onSelect,
      hint,
      itemKeyExtractor,
    };

    return children(childProps);
  }
}
