import debounce from 'lodash-es/debounce';

import config from '@mindoktor/env/Config';

import { deleteApi, getApi, postApi } from '../api/actions';
import { loadDrafts } from '../drafts/actions';
import { postCase } from '../formulary/api';
import { getCurrentLocale } from '../intl/selectors';
import { createInquisitor, getInquisitor, setInquisitor } from './inquisitor';
import {
  getChildPatient,
  getDraft,
  getPatient,
  getQuestion,
} from './selectors';
import { INQUISITIONS_DRAFT_REMOVE, INQUISITIONS_DRAFT_UPDATE } from './types';
import { parseInquisition } from './utils';

const updateDraft = (id, draft) => (dispatch, getState) => {
  dispatch({
    type: INQUISITIONS_DRAFT_UPDATE,
    payload: { id, draft },
  });

  postDraft(id, dispatch, getState);
};

export const setDraftValue = (id, value, options) => (dispatch, getState) => {
  const question = getQuestion(getState(), id);

  if (!question) throw new Error('no question');

  const { visited = [], values = {} } = getDraft(getState(), id) || {};

  dispatch(
    updateDraft(id, {
      visited,
      values: { ...values, [question.ref]: value },
      options,
    })
  );
};

export const popDraft = (id, options) => async (dispatch, getState) => {
  const inquisitor = getInquisitor(id);

  if (!inquisitor) throw new Error('no inquisitor');

  const { visited = [], values = {} } = getDraft(getState(), id) || {};

  if (!visited.length) throw new Error('no visited');

  dispatch(
    updateDraft(id, {
      ...(await inquisitor.start({
        visited: visited.slice(0, visited.length - 1),
        values,
      })),
      options,
    })
  );
};

export const pushDraft = (id, options) => async (dispatch, getState) => {
  const inquisitor = getInquisitor(id);

  if (!inquisitor) throw new Error('no inquisitor');

  const question = getQuestion(getState(), id);

  if (!question) throw new Error('no question');

  const { visited = [], values = {} } = getDraft(getState(), id) || {};

  const value = values[question.ref];

  if (value === undefined) throw new Error('no value');

  await inquisitor.push(value);

  dispatch(
    updateDraft(id, { visited: [...visited, question.ref], values, options })
  );
};

export const clearDraft = (id) => async (dispatch) => {
  dispatch({
    type: INQUISITIONS_DRAFT_REMOVE,
    payload: { id },
  });
};

export const fetchDraft = (id, childUuid) => async (dispatch) => {
  const { json, error } = await dispatch(
    getApi(
      `/api/v1/inquisitions/drafts/${id}${
        childUuid ? `?child_uuid=${childUuid}` : ''
      }`
    )
  );

  if (error) {
    return { error };
  }

  return { draft: json };
};

export const fetchInquisitionJson = async (id) => {
  let response;
  let json;
  try {
    response = await fetch(
      `${config.Urls.inquisitor.domain}/static/guides/${id}.json`,
      {
        mode: 'cors',
        credentials: 'omit',
      }
    );
    json = await response.json();
  } catch (e) {
    return { json: undefined, error: true };
  }

  return { json, error: response.status >= 400 };
};

export const fetchInquisition =
  (id, childUuid) => async (dispatch, getState) => {
    let inquisitor = getInquisitor(id);

    if (inquisitor) {
      return {};
    }

    const [{ json, error }, { draft }] = await Promise.all([
      fetchInquisitionJson(id),
      dispatch(fetchDraft(id, childUuid)),
    ]);

    if (error) {
      return { error };
    }

    const patient = childUuid
      ? getChildPatient(getState(), childUuid)
      : getPatient(getState());

    if (!patient || !json) {
      return { error: true };
    }

    const inquisition = parseInquisition(json);

    inquisitor = createInquisitor(inquisition, patient);

    setInquisitor(id, inquisitor);

    const startedDraft = await inquisitor.start(draft || {});

    if (startedDraft?.entrywayId) {
      // if there is a draft, with an entryway ID, save in the backend
      // if there is no entryway ID, do not save, since we will not know which guide to resume.

      // draft can be null, when going from the intro screen to the prestart screen,
      // and from a fresh guide, meaning:
      // - not a resumed draft
      // - you don't have a draft for this guide already
      // so nothing is fetched from the state
      await dispatch(updateDraft(id, startedDraft));
    }

    return {};
  };

export const postInquisitionCase =
  (
    id,
    {
      entrywayId,
      childUuid,
      revisitId,
      dynamicCode,
      answers,
      // auth params
      externalId,
    }
  ) =>
  async (dispatch) => {
    postDraft.cancel();

    const { caseId, error, paymentRequired } = await dispatch(
      postCase(entrywayId, '', '', {}, childUuid, revisitId, {
        dynamicCode,
        inquisitionAnswers: answers,
        informedConsent: true,
        // auth params
        externalId,
      })
    );

    if (!error) {
      postDraft.cancel();

      dispatch(clearDraft(id));

      dispatch(loadDrafts());

      setTimeout(() => setInquisitor(id, undefined), 3000);
    }

    return {
      caseId,
      paymentRequired,
      error,
    };
  };

const postDraft = debounce(
  async (id, dispatch, getState) => {
    const draft = getDraft(getState(), id);
    const locale = getCurrentLocale(getState());
    if (draft && draft.visited && draft.visited.length > 0) {
      dispatch(
        postApi('/api/v1/inquisitions/drafts', {
          id,
          draft: {
            ...draft,
            ...draft.options,
            created: new Date(),
            language: locale,
          },
        })
      );

      dispatch(loadDrafts());
    }
  },
  5000,
  { leading: true, trailing: true, maxWait: 5000 }
);

export const removeDraft = (id, childUuid) => async (dispatch) => {
  const { error } = await dispatch(
    deleteApi(
      `/api/v1/inquisitions/drafts/${id}${
        childUuid ? `?child_uuid=${childUuid}` : ''
      }`
    )
  );

  if (error) {
    return { error };
  }

  dispatch(clearDraft(id));

  return {};
};
