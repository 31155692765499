import React from 'react';

import { CircularProgress, Stack, Typography } from '@mui/material';

import { circularProgressLarge } from '@mindoktor/pulse/src/styles/sizes';

import { useTranslation } from '@mindoktor/patient-app/localization/hooks/useTranslation';
import { isMobile } from '@mindoktor/patient-app/utils/device/web';

import { useUserDetailsApi } from '../../../hooks/useUserDetailsApi';
import RegistrationForm from '../../RegistrationForm/web';

const RegistrationContent: React.FC = () => {
  const t = useTranslation('user', 'registration');
  const { data: userDetails, isFetched } = useUserDetailsApi();

  return (
    <Stack padding="2rem" paddingBottom={0} spacing="1.5rem" flexGrow={1}>
      {!isFetched ? (
        <CircularProgress
          color="primary"
          size={circularProgressLarge}
          sx={{ alignSelf: 'center' }}
        />
      ) : (
        <>
          <Typography variant={isMobile ? 'h2' : 'h1'}>
            {userDetails?.identityProtection === true
              ? t('protectedIdentity.header')
              : t('header')}
          </Typography>
          <Stack spacing="1rem" paddingBottom={'2rem'} flex={1} width="100%">
            <RegistrationForm />
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default RegistrationContent;
