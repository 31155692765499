// import type { Action } from '../types';

export const FORMULARY_LOAD = 'FORMULARY_LOAD';
export const FORMULARY_LOAD_SUCCESS = 'FORMULARY_LOAD_SUCCESS';
export const FORMULARY_UPDATE = 'FORMULARY_UPDATE';
export const FORMULARY_PREVIOUS = 'FORMULARY_PREVIOUS';
export const FORMULARY_NEXT = 'FORMULARY_NEXT';
export const FORMULARY_CLEAR = 'FORMULARY_CLEAR';
export const FORMULARY_POST = 'FORMULARY_POST';
export const FORMULARY_POST_SUCCESS = 'FORMULARY_POST_SUCCESS';
export const FORMULARY_POST_ERROR = 'FORMULARY_POST_ERROR';
export const FORMULARY_SAVED = 'FORMULARY_SAVED';
export const FORMULARY_ABORT = 'FORMULARY_ABORT';
export const FORMULARY_ADD = 'FORMULARY_ADD';
export const QUESTIONNAIRE_UPDATE = 'QUESTIONNAIRE_UPDATE';
export const FORMULARY_SUBMIT = 'FORMULARY_SUBMIT';
export const QUESTION_SHOWN = 'QUESTION_SHOWN';

export type FormularyKey = string;

export interface Option {
  index: string;
  label?: string;
  value: unknown;
  condition?: string;
  show?: boolean;
}

export interface Abort {
  description?: string;
  condition: string;
  show?: boolean;
}

export interface Min {
  label?: string;
  value: unknown;
}

export interface Max {
  label?: string;
  value: unknown;
}

export interface Modal {
  link?: string;
  content?: string;
}

export interface Node {
  type: string;
  index: string;

  id?: string;
  version?: string;

  children?: Node[];
  variables?: Node[];

  condition?: string;
  expression?: string;
  abort?: Abort;

  show?: boolean;
  value?: unknown;
  error?: string | false;

  label?: string;
  prologue?: string;
  description?: string;
  hint?: string;
  modal?: Modal;

  filter?: string;
  extras?: { instructions?: string[] };
  min?: Min;
  max?: Max;
  options?: Option[];
  other?: boolean;
  single?: boolean;
  source?: string;
  types?: string;
  unit?: string;

  // Possible validations are 'required', 'min', 'max', 'condition', 'pattern'.
  validations?: { [k: string]: unknown };
  messages?: { [k: string]: string };

  contextDescription?: string;
  contextDescriptionValue?: string;
}

export interface Formulary extends Node {
  type: 'formulary';
  id: string;
  version: string;
}

export interface Question extends Node {
  id: string;
  version: string;
}

// export type QuestionnaireQuestion = Question & {
//   submitted: boolean,
// };

// export type Answers = { [string]: unknown };

// export type Evaluator = (code?: string) => unknown;

// export type Transform = (value: unknown) => unknown;

export interface QuestionnaireData {
  submittedAnswers: Record<string, Question>;
  isFinished: boolean;
}

export interface FormularyState {
  byKey: Record<FormularyKey, FormularyData | QuestionnaireData | null>;
  isLoading?: boolean;
}

// type FormularyAddPayload =
//   | {
//       type: 'questionnaire',
//       formulary: Formulary,
//       answers: Answers,
//       key: string,
//     }
//   | {
//       type: 'formulary',
//       /**
//        * Todo: Add those props needed if we implement
//        * FORMULARY_ADD for "classic" formularies
//        */
//     };

// export type FormularyAddAction = {
//   type: typeof FORMULARY_ADD,
//   payload: FormularyAddPayload,
// } & Action;

// export type FormularyLoadAction = {
//   type: 'FORMULARY_LOAD',
//   payload?: {
//     revisitId?: number,
//     formulary?: Formulary,
//     formularyId?: string,
//     formularyVersion?: string,
//     answers?: Object,
//     index?: string,
//     dynamicCode?: string,
//   },
// } & Action;

// export type FormularyLoadSuccessAction = {
//   type: 'FORMULARY_LOAD_SUCCESS',
//   payload: {
//     formularyKey: string,
//     entrywayId?: number,
//     revisitId?: number,
//     childUuid?: string,
//     formulary: Formulary,
//     answers?: Object,
//     index?: string,
//     dynamicCode?: string,
//     skipDrafts?: boolean,
//     skipAnswered?: boolean,
//     skipOptionalQuestions?: boolean,
//   },
// } & Action;

// export type FormularyUpdateAction = {
//   type: 'FORMULARY_UPDATE',
//   payload: {
//     formularyKey: string,
//     answers: Object,
//     index?: string,
//     type: 'formulary',
//   },
// } & Action;

// export type QuestionnaireUpdateAction = {
//   type: 'QUESTIONNAIRE_UPDATE',
//   payload: {
//     formularyKey: string,
//     answers: Object,
//     index?: string,
//     type: 'questionnaire',
//   },
// } & Action;

// export type FormularyPreviousAction = {
//   type: 'FORMULARY_PREVIOUS',
//   payload: {
//     formularyKey: string,
//   },
// } & Action;

// export type FormularyNextAction = {
//   type: 'FORMULARY_NEXT',
//   payload: {
//     formularyKey: string,
//   },
// } & Action;

// export type FormularyClearAction = {
//   type: 'FORMULARY_CLEAR',
//   payload: {
//     formularyKey: string,
//   },
// } & Action;

// export type FormularySubmitAction = {
//   type: typeof FORMULARY_SUBMIT,
//   payload: {
//     formularyKey: string,
//   },
// };

// export type FormularyPostAction = {
//   type: 'FORMULARY_POST',
//   payload: {
//     formularyKey: string,
//   },
// } & Action;

// export type FormularyPostSuccessAction = {
//   type: 'FORMULARY_POST_SUCCESS',
//   payload: {
//     formularyKey: string,
//     revisitId?: number,
//   },
// } & Action;

// export type FormularyPostErrorAction = {
//   type: 'FORMULARY_POST_ERROR',
//   payload: {
//     formularyKey: string,
//     revisitId?: number,
//     message?: string,
//   },
// } & Action;

// export type FormularySavedAction = {
//   type: 'FORMULARY_SAVED',
//   payload: {
//     formularyKey: string,
//     savedAt: number,
//   },
// } & Action;

// export type FormularyAbortAction = {
//   type: 'FORMULARY_ABORT',
//   payload: {
//     entrywayId: number,
//     formularyId: string,
//     currentQuestion: string,
//   },
// } & Action;

export interface FormularyWithAnswers {
  root: Node;
  answers: Record<string, unknown>;
  visibility: Record<string, unknown>;
  initialVisibility: Record<string, boolean>;
  errors: Record<string, string>;
  abort: boolean;
  abortReason?: string;
  contextDescriptions?: Record<string, string>;
}

export interface FormularyPosition {
  current: Question;
  next?: Question;
  previous?: Question;
  skipOptionalQuestions: boolean;
}

export interface FormularyOptions {
  skipOptionalQuestions: boolean;
  skipDrafts: boolean;
  skipAnswered: boolean;
}

export interface FormularyMetadata {
  entrywayId: number;
  revisitId?: number;
  childUuid?: string;
  dynamicCode?: string;
  savedAt?: number;
}

export interface FormularyAbstractData extends FormularyWithAnswers {
  formulary: Formulary;
}

// export type QuestionnaireData = {|
//   ...FormularyAbstractData,
//   type: 'questionnaire',
//   submittedAnswers: Answers,
//   hasUnsubmittedAnswers: boolean,
//   isFinished: boolean,
// |};

export interface FormularyData
  extends FormularyAbstractData,
    FormularyPosition,
    FormularyOptions,
    FormularyMetadata {
  type: 'formulary';
  posting?: boolean;
  postingError?: boolean;
  initialIndex: string;
}
