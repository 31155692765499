import isArray from 'lodash-es/isArray';
import isFunction from 'lodash-es/isFunction';
import isObject from 'lodash-es/isObject';

import { APP_START } from '../../state/app/actions';
import {
  formatRoute,
  openedRoute,
  parseRoute,
  ROUTING_OPEN,
} from '../../state/routing';
import { hideModal } from './index';

export const mapHandlers = (candidate) => {
  if (isArray(candidate)) {
    return candidate.reduce(
      (previous, current) => ({ ...previous, ...mapHandlers(current) }),
      {}
    );
  }

  return isObject(candidate) ? candidate : {};
};

const middleware =
  (...handlers) =>
  ({ dispatch, getState }) => {
    const handlerMap = mapHandlers(handlers);
    const onHashChange = () => {
      hideModal();

      const urlHash = window.location.hash.slice(1);
      const { path: route, params: props } = parseRoute(urlHash);

      const handler = handlerMap[route];
      if (handler === undefined) {
        console.warn('No handler for route:', route);
      }

      if (isFunction(handler)) {
        handler({
          dispatch,
          getState,
          props,
        });

        dispatch(
          openedRoute({
            route: urlHash,
            path: route,
            props,
          })
        );
      }
    };

    window.addEventListener('hashchange', onHashChange);

    return (next) => (action) => {
      const { type, payload = {} } = action;

      if (type === APP_START) {
        onHashChange();
      } else if (type === ROUTING_OPEN) {
        const { path, props = {}, options: { replace } = {} } = payload;

        const route = formatRoute(path, props);

        const url =
          window.location.pathname + window.location.search + '#' + route;

        if (replace) {
          window.location.replace(url);
        } else {
          window.location.href = url;
        }
      }

      return next(action);
    };
  };

export default middleware;
