import React from 'react';
import { connect } from 'react-redux';

import { fetchCases } from '../../state/cases/actions';
import { getGuideByEntrywayId } from '../../state/guides/selectors';
import { fetchSupportTickets } from '../../state/helpCenter/actions';
// import { type Guide as Entryway } from '../../state/guides/types';
import {
  clearDraft,
  fetchInquisition,
  popDraft,
  postInquisitionCase,
  pushDraft,
  setDraftValue,
} from '../../state/inquisitions/actions';
import {
  getChildPatient,
  getPatient,
  getQuestion,
  getResult,
  getValue,
  isFirst,
} from '../../state/inquisitions/selectors';
// import {
//   type Patient,
//   type Question,
//   type Result,
// } from '../../state/inquisitions/types';
import { fetchUserConnections } from '../../state/profile/actions';
import { openRoute } from '../../state/routing';
import { fetchUserNotifications } from '../../state/userNotifications/actions';

// export type LoaderProps = {
//   entrywayId?: number,
//   childUuid?: string,
//   revisitId?: number,
//   dynamicCode?: string,
// };

// type Props = LoaderProps & {
//   inquisitionId?: string,

//   entryway?: Entryway,
//   patient?: Patient,
//   question?: Question,
//   result?: Result,
//   value?: *,
//   first?: boolean,

//   LoadedComponent: React.Node,
//   LoadingComponent: React.Node,

//   fetchCases: typeof fetchCases,
//   fetchInquisition: typeof fetchInquisition,
//   postInquisitionCase: typeof postInquisitionCase,
//   setDraftValue: typeof setDraftValue,
//   popDraft: typeof popDraft,
//   pushDraft: typeof pushDraft,
//   clearDraft: typeof clearDraft,
//   openRoute: typeof openRoute,
// };

// export type LoadedProps = {
//   entryway: Entryway,
//   patient: Patient,
//   question?: Question,
//   result?: Result,
//   value?: *,
//   first?: boolean,
//   fetchCases: typeof fetchCases,
//   postInquisitionCase: (answers: *) => Promise<{
//     caseId?: number,
//     error?: boolean,
//     paymentRequired?: boolean,
//   }>,
//   updateDraftValue: (ref: string, value: *) => *,
//   popDraft: () => *,
//   pushDraft: (ref: string) => *,
//   clearDraft: () => *,
//   fetchUserConnections: typeof fetchUserConnections,
//   needsToFetchChildConnections: boolean,
//   openRoute: typeof openRoute,
// };

export default connect(
  (state, { entrywayId, childUuid }) => {
    const entryway = getGuideByEntrywayId(state, entrywayId);

    const inquisitionId = entryway?.inquisitionId;

    const patientIsAChild = !!childUuid;
    const childConnectionsLoaded = !!state.profile.connections;
    const needsToFetchChildConnections =
      patientIsAChild && !childConnectionsLoaded;

    return {
      inquisitionId,

      needsToFetchChildConnections,

      entryway,
      patient: childUuid
        ? getChildPatient(state, childUuid)
        : getPatient(state),
      question: inquisitionId && getQuestion(state, inquisitionId),
      result: inquisitionId && getResult(state, inquisitionId),
      value: inquisitionId && getValue(state, inquisitionId),
      first: inquisitionId && isFirst(state, inquisitionId),
    };
  },
  {
    fetchCases,
    fetchSupportTickets,
    fetchUserNotifications,
    fetchInquisition,
    postInquisitionCase,
    setDraftValue,
    popDraft,
    pushDraft,
    clearDraft,
    fetchUserConnections,
    openRoute,
  }
)(
  class Loader extends React.Component {
    state = {};

    componentDidMount() {
      const {
        inquisitionId,
        fetchInquisition,
        childUuid,
        needsToFetchChildConnections,
        fetchUserConnections,
      } = this.props;

      if (inquisitionId) {
        fetchInquisition(inquisitionId, childUuid);
      }

      if (needsToFetchChildConnections) {
        fetchUserConnections();
      }
    }

    componentDidUpdate(prevProps) {
      const { inquisitionId, fetchInquisition, childUuid } = this.props;
      const { inquisitionId: prevInquisitionId } = prevProps;

      if (inquisitionId && !prevInquisitionId) {
        fetchInquisition(inquisitionId, childUuid);
      }
    }

    setDraftValue = async (value) => {
      const {
        inquisitionId,
        setDraftValue,
        entrywayId,
        childUuid,
        revisitId,
        dynamicCode,
      } = this.props;

      return setDraftValue(inquisitionId, value, {
        entrywayId,
        childUuid,
        revisitId,
        dynamicCode,
      });
    };

    popDraft = async () => {
      const {
        inquisitionId,
        entrywayId,
        childUuid,
        revisitId,
        dynamicCode,
        popDraft,
      } = this.props;

      await popDraft(inquisitionId, {
        entrywayId,
        childUuid,
        revisitId,
        dynamicCode,
      });
    };

    pushDraft = async () => {
      const {
        inquisitionId,
        entrywayId,
        childUuid,
        revisitId,
        dynamicCode,
        pushDraft,
      } = this.props;

      await pushDraft(inquisitionId, {
        entrywayId,
        childUuid,
        revisitId,
        dynamicCode,
      });
    };

    clearDraft = async () => {
      const {
        inquisitionId,
        entrywayId,
        childUuid,
        revisitId,
        dynamicCode,
        clearDraft,
      } = this.props;

      await clearDraft(inquisitionId, {
        entrywayId,
        childUuid,
        revisitId,
        dynamicCode,
      });
    };

    postCase = (answers, marketingParams) => {
      const {
        inquisitionId,
        entrywayId,
        childUuid,
        revisitId,
        dynamicCode,
        postInquisitionCase,
      } = this.props;

      return postInquisitionCase(inquisitionId, {
        entrywayId,
        childUuid,
        revisitId,
        dynamicCode,
        answers,
        ...marketingParams,
      });
    };

    render() {
      const {
        entrywayId,
        childUuid,
        revisitId,
        dynamicCode,
        entryway,
        patient,
        question,
        result,
        LoadedComponent,
        LoadingComponent,
      } = this.props;

      return entryway && patient && (question || result) ? (
        <LoadedComponent
          {...this.props}
          query={{ entrywayId, childUuid, revisitId, dynamicCode }}
          setDraftValue={this.setDraftValue}
          popDraft={this.popDraft}
          pushDraft={this.pushDraft}
          clearDraft={this.clearDraft}
          postCase={this.postCase}
        />
      ) : (
        <LoadingComponent />
      );
    }
  }
);
