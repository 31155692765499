import config from '@mindoktor/env/Config';
import React, { Component } from 'react';

import MultipleSearchWrapper from '../../common/components/formulary/input/MultipleSearchWrapper';
import TimeSlider from '../../common/components/input/TimeSlider';
import Binary from './input/binary';
import Choice from './input/choice';
import Medication from './input/medication';
import MultipleChoice from './input/multiple_choice';
import MultipleSearch from './input/multiple_search';
import Number from './input/number';
import Range from './input/range';
import Rating from './input/rating';
import Tertiary from './input/tertiary';
import Text from './input/text';
import Upload from './input/upload';
import { getDisplayError } from './utils';

// type Props = {
//   node: Question,
//   answer?: any,
//   error?: string,
//   options?: Option[],
//   update: any => any,
//   next: () => any,
// };

export default class Input extends Component {
  render() {
    const { node, answer, error, options, update, next, entrywayId } =
      this.props;

    const {
      type,
      filter,
      hint,
      min,
      max,
      other,
      single,
      unit,
      validations,
      extras,
    } = node;

    const source = node.source && JSON.parse(node.source);
    const errorMessage = error && getDisplayError(node, error);
    const quickChoice = config.FormularyQuickChoice;

    switch (type) {
      case 'binary':
        return (
          <Binary
            answer={answer}
            error={error}
            update={update}
            next={quickChoice ? next : undefined}
          />
        );

      case 'choice':
        return (
          <Choice
            options={options}
            answer={answer}
            error={error}
            update={update}
            next={quickChoice ? next : undefined}
          />
        );

      case 'medication':
        return (
          <Medication
            answer={answer}
            error={error}
            filter={filter}
            other={other}
            update={update}
            next={next} // Hotfix since no footer for medication
          />
        );

      case 'multipleChoice':
        return (
          <MultipleChoice
            options={options}
            answer={answer}
            error={error}
            update={update}
          />
        );

      case 'number':
        return (
          <Number
            answer={answer}
            error={error}
            errorMessage={errorMessage}
            hint={hint}
            unit={unit}
            update={update}
            next={next}
          />
        );

      case 'range':
        return <Range answer={answer} min={min} max={max} update={update} />;

      case 'duration':
        return <TimeSlider answer={answer} options={options} update={update} />;

      case 'rating':
        return (
          <Rating answer={answer} error={error} update={update} next={next} />
        );

      case 'tertiary':
        return (
          <Tertiary
            answer={answer}
            error={error}
            update={update}
            next={quickChoice ? next : undefined}
          />
        );

      case 'text':
        return (
          <Text
            answer={answer}
            error={error}
            errorMessage={errorMessage}
            hint={hint}
            maxLength={validations && validations.max}
            single={single}
            update={update}
            next={next}
          />
        );

      case 'upload':
        return (
          <Upload answer={answer} update={update} next={next} extras={extras} />
        );

      case 'multipleSearch':
        // Note, even though it is named multipleSearch it is not generic.
        // It allows you to search for drugs, nothing else.
        return (
          <MultipleSearchWrapper
            answer={answer}
            error={error}
            errorMessage={errorMessage}
            update={update}
            next={next}
            source={source}
            entrywayId={entrywayId}
            max={validations && validations.max}
          >
            {(props) => <MultipleSearch {...props} />}
          </MultipleSearchWrapper>
        );
    }

    return null;
  }
}
