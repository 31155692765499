import React from 'react';

import { SxProps, Theme } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Slide from '@mui/material/Slide';

import { backdropColor } from './constants';
import { BottomSheetProps } from './types';

const BottomSheet: React.FC<BottomSheetProps> = ({
  children,
  isOpen,
  onClose,
  mountOnEnter = false,
  unmountOnExit = false,
}) => {
  return (
    <Modal
      sx={classes.root}
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          sx: { backgroundColor: backdropColor },
          timeout: 500,
        },
      }}
    >
      <Slide
        direction="up"
        in={isOpen}
        mountOnEnter={mountOnEnter}
        unmountOnExit={unmountOnExit}
      >
        <Paper sx={classes.sheet}>{children}</Paper>
      </Slide>
    </Modal>
  );
};

const classes: Record<string, SxProps<Theme>> = {
  root: (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',

    // Desktop
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'center',
    },
  }),

  sheet: (theme) => ({
    zIndex: 0,

    display: 'flex',
    flexDirection: 'column',

    boxSizing: 'border-box',
    maxHeight: '90vh',
    width: '100vw',
    maxWidth: theme.breakpoints.values.sm,

    outline: 'none', // Hide outline when focused

    '&.MuiPaper-rounded': {
      borderRadius: '12px 12px 0 0',
      padding: '0 0 1rem 0',

      // Desktop
      [theme.breakpoints.up('sm')]: {
        borderRadius: '12px',
      },
    },
  }),
};

export default BottomSheet;
