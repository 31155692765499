import React from 'react';

import { platform } from '../../compatibility/platform/platform';

import { FormKeyboardItems } from './types';

export const useFormKeyboardActions = (
  form: FormKeyboardItems,
  onSubmit: () => Promise<void>
) => {
  const handleEnterKey = (field: string, ev?: React.KeyboardEvent) => {
    // Check if it's an enter key press on web. Native handles this using the
    // inputs onSubmitEditing prop.
    if (ev?.key === 'Enter' || platform.isNative) {
      const foundAt = form.findIndex((item) => item.name === field);
      if (foundAt !== -1) {
        switch (form[foundAt].action) {
          case 'next':
            form[foundAt + 1]?.ref?.current?.focus();
            break;
          case 'go':
          case 'send':
          case 'search':
            void onSubmit();
            form[foundAt]?.ref?.current?.blur();
            break;
          case 'done':
            form[foundAt]?.ref?.current?.blur();
            break;
          default:
            console.info('Non implemented action', form[foundAt].action);
        }
      }
    }
  };

  return {
    onEnterKey: handleEnterKey,
  };
};
