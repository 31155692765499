import { useLocale } from '@mindoktor/patient-app/localization/components/LocaleProvider';
import React from 'react';
import { connect } from 'react-redux';

import { setUserLocale } from '../../../state/intl/actions';
import { getCurrentLocale } from '../../../state/intl/selectors';
import { localeMap } from '../../../state/intl/types';
import DropdownInput from '../../components/input/dropdown';

const labels = {
  sv: 'Swedish',
  en: 'English',
};

const options = Object.keys(localeMap).map((key) => ({
  label: labels[key],
  value: key,
}));

const LocalePicker = ({ style, currentLocale, setUserLocale }) => {
  const locale = useLocale();

  const setLanguage = (language) => {
    locale.setDefaultLocaleForLanguage(language);
    setUserLocale(language);
  };
  return (
    <DropdownInput
      value={currentLocale}
      options={options}
      multiple={false}
      style={style}
      onChange={(value) => setLanguage(value)}
    />
  );
};

export default connect(
  (state) => ({ currentLocale: getCurrentLocale(state, true) }),
  {
    setUserLocale,
  }
)(LocalePicker);
