import { routes as legacyRoutes, openRoute } from '../../state/routing';
import { routes } from '@mindoktor/patient-app/routing/web';
import { AppDispatch } from '../../state/types';
import { RoutingNavigationProp } from '../../api/navigation/types';

/**
 * Navigates to the route of either the new or the old chat
 **/
export const openChatRoute = (
  dispatch: AppDispatch,
  navigation: RoutingNavigationProp,
  caseId?: number,
  conversationId?: number
) => {
  if (!conversationId) {
    return dispatch(
      openRoute(legacyRoutes.CASE_MESSAGES, {
        id: caseId,
      })
    );
  }
  return navigation.navigate(routes.CONVERSATION, {
    caseId,
    conversationId,
  });
};
