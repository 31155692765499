import reduce from 'lodash-es/reduce';

function set(namespace, map, value) {
  let id;
  if (!namespace.length) return;
  id = namespace[0];
  if (namespace.length === 1) {
    map[id] = value;
  } else {
    if (!map[id]) map[id] = {};
    set(namespace.slice(1), map[id], value);
  }
}

function get(namespace, map) {
  if (!namespace.length || !map) return undefined;
  if (namespace.length === 1) return map[namespace[0]];
  return get(namespace.slice(1), map[namespace[0]]);
}

export default {
  set: (id, map, value) => {
    set(id.split('.'), map, value);
  },

  get: (id, map) => {
    return get(id.split('.'), map);
  },

  hash: (o) => {
    return String(
      reduce(
        JSON.stringify(o).split(''),
        (result, c) => {
          return ((result << 5) - result + c.charCodeAt(0)) | 0;
        },
        0
      )
    );
  },
};
