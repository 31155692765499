import { ajaxApi, ApiHeaders } from '../api';

const API_ENDPOINT_URL_SUPPORT_CENTER = '/api/v1/support/helpcenterurl';

export type allowedPage = '' | 'request' | 'requests' | 'new_request';

/**
 * Fetches the link to the support center (or help-center) landing page
 */
const fetchHelpCenterSupportUrl = async (
  apiHeaders: ApiHeaders,
  page: allowedPage = '',
  ticketId = 0
): Promise<[string, Error | null]> => {
  const [jsonResponse, err] = await ajaxApi(
    apiHeaders,
    {
      method: 'GET',
      url:
        `${API_ENDPOINT_URL_SUPPORT_CENTER}?page=${page}` +
        (ticketId > 0 ? `&ticketId=${ticketId}` : ''),
    },
    null
  );
  if (err) {
    return ['', err];
  }
  const url = (jsonResponse?.data?.url ?? '') as string;
  return [url, err];
};

export default fetchHelpCenterSupportUrl;
