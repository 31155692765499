export interface FontStyleType {
  size: number;
}

export const weights = {
  light: 300,
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
} as const;

export const fonts = {
  h1: {
    size: 36,
    weight: weights.bold,
    lineHeight: 1.1,
  },
  h2: {
    size: 26,
    weight: weights.semibold,
    lineHeight: 1.2,
  },
  h3: {
    size: 18,
    weight: weights.medium,
    lineHeight: 1.2,
  },
  body1: {
    size: 16,
    weight: weights.regular,
    lineHeight: 1.5,
    /** rem */
    gutter: 0.75,
  },
  body2: {
    size: 13,
    weight: weights.regular,
    lineHeight: 1.5,
    /** rem */
    gutter: 0.5,
  },
} as const;

export const desktopFonts = {
  h1: {
    size: 46,
    weight: weights.bold,
    lineHeight: 1.1,
  },
  h2: {
    size: 29,
    weight: weights.semibold,
    lineHeight: 1.2,
  },
} as const;

export type FontVariant = keyof typeof fonts;
