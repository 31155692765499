import dayjs from 'dayjs';
import _get from 'lodash-es/get';
import { createSelector } from 'reselect';

import {
  getLatestTimestampFromSupportTicketList,
  isTicketClosed,
} from '../../api/helpCenter/utils';
import { hasFeatureFlag } from '../featureflags/selectors';
import { getOpenCases, hasActiveCases } from '../cases/selectors';
import { getDrafts } from '../drafts/selectors';
import { getGuideTitle } from '../guides/selectors';
import { isPushEnabled } from '../push/selectors';
import { isValidChildUser } from '../utils/validation';
import { getInitials } from './profileUtils';
import { InvitationStatus, InvitationType } from './types';

// type Props = { consultationIds: Array<number> };

/** @typedef {import('../types').CommonStateType} CommonStateType */
/** @typedef {import('./types_definitions').Invitation} Invitation */

export const getContactInfoBeingVerified = ({ profile }) => {
  return profile.contactInfoVerification.infoBeingVerified;
};

export const isContactInfoVerified = (
  { profile },

  info
) => {
  if (!profile.contactInfoVerification.verifications) {
    return false;
  }

  return profile.contactInfoVerification.verifications[info]?.isVerified;
};

export const getContactInfoVerificationRedirectFunc = ({ profile }) => {
  return profile.contactInfoVerification.redirectFunc;
};

/**
 * Return the contact information part of the profile state
 */
export function contactInformation({ profile }) {
  return {
    address: profile.address,
    city: profile.city,
    email: profile.email,
    firstName: profile.firstName,
    lastName: profile.lastName,
    phone: profile.phone,
    postalcode: profile.postalcode,

    isFemale: profile.isFemale,

    birthDate: profile.birthDate,
    identityProtection: profile.identityProtection,

    addressEditAllowed: profile.addressEditAllowed,
  };
}

/**
 * Return the parts of the profile that is used by the API.
 */

export function getApiProfile({ profile }) {
  return {
    ...contactInformation({ profile }),
    lat: profile.lat,

    lng: profile.lng,
    prefLang: profile.prefLang,
    status: profile.status,
    timeZone: profile.timeZone,
    userMarketingId: profile.userMarketingId,
  };
}

export function profileValidationErrors({ profile }) {
  return profile.validation.errors;
}

export function profileValidationPending({ profile }) {
  return profile.validation.pending;
}

/**
 * If profile.firstName is set, we can assume that the profile has been fetched
 */
export function isProfileFetched({ profile }) {
  return !!profile.firstName;
}

export function getChildren({ profile }) {
  if (!profile.connections) {
    return [];
  }

  return profile.connections.filter((connection) => {
    // If the child is not a valid child
    if (
      typeof connection.birthDate === 'string' &&
      !isValidChildUser(connection.birthDate)
    ) {
      return false;
    }

    return true;
  });
}

export function getChild({ profile }, childUuid) {
  return (
    profile.connections &&
    profile.connections.find((connection) => connection.uuid === childUuid)
  );
}

export function getTrackerId({ profile }) {
  return profile.userMarketingId;
}

export function getAllInvitations(state) {
  const allInvitations = _get(state, 'profile.invitationsById');

  if (allInvitations) {
    return allInvitations;
  }

  return {};
}

/**
 * Returns all open invitations (invitations that are within the time span of when they can be created)
 *
 * @param {CommonStateType} state
 * @returns {Invitation[]}
 */
export const getOpenInvitations = (state) => getCaseInvitations(state, false);

/**
 * Returns all open and future invitations
 *
 * @param {CommonStateType} state
 * @returns {Invitation[]}
 */
export const getOpenAndFutureInvitations = (state) =>
  getCaseInvitations(state, true);

/**
 * @param {CommonStateType} state
 * @param {boolean=} includeFutureInvitations
 * @returns {Invitation[]}
 */
export const getCaseInvitations = (state, includeFutureInvitations = false) => {
  const invitations = state.profile.invitationsById;
  const casesById = state.cases.casesById;

  // invitations needs some cases!
  if (Object.keys(casesById).length < 1) return [];

  // filter out open invitations that doesn't have open/active cases in their chain.
  const openInvitations = Object.keys(invitations)
    .filter(
      (invitationId) =>
        (invitations[parseInt(invitationId, 10)].isOpen === true ||
          (includeFutureInvitations &&
            invitations[parseInt(invitationId, 10)].status ===
              InvitationStatus.RevisitStatusActive)) &&
        invitations[parseInt(invitationId, 10)].revisitChainHasOpenCase ===
          false
    )

    .map((id) => invitations[parseInt(id, 10)]);

  // remove invitations who's revisitParentCase is missing (ie non-payed, expired)
  const invitationsWithoutExpiredCases = openInvitations.filter(
    (invitation) =>
      !invitation.hasOwnProperty('revisitParentCaseId') ||
      casesById[invitation.revisitParentCaseId]
  );

  // if there's both patient and doctor invitations, only show doctor one
  // have to be done after active filtering.
  const nonDuplicateInvitations = invitationsWithoutExpiredCases.filter(
    (invitation) => {
      const hasABetterHalf =
        invitation.typeName === InvitationType.patientRevisit &&
        openInvitations.find(
          (compareInvitation) =>
            compareInvitation.revisitParentCaseId ===
              invitation.revisitParentCaseId &&
            compareInvitation.typeName === InvitationType.caregiverRevisit
        );
      return !hasABetterHalf;
    }
  );

  // case specific mapping.
  return nonDuplicateInvitations.map((invitation) => {
    // if this invitation is an internal referral, check guideParams for id and name.
    if (invitation.typeName === InvitationType.internalReferral) {
      return {
        ...invitation,
        targetEntrywayId: invitation.guideParams.entrywayId,
        targetEntrywayName: getGuideTitle(
          state,
          invitation.guideParams.entrywayId
        ),
      };
    }

    // otherwise use revisitParent entrywayId and name.
    const revisitParentCase =
      invitation.revisitParentCaseId &&
      casesById[invitation.revisitParentCaseId];
    if (revisitParentCase) {
      return {
        ...invitation,
        targetEntrywayId: revisitParentCase.entrywayId,
        targetEntrywayName: getGuideTitle(state, revisitParentCase.entrywayId),
      };
    }

    // this shouldn't happen since an invitation must be either internal or revisit.
    console.warn('Invitation type unknown');
    return invitation;
  });
};

const getOpenSupportTickets = (state) => {
  if (!hasFeatureFlag(state, 'zendesk-help-center')) {
    return [];
  }
  return (
    state?.helpCenter?.tickets?.filter((t) => !isTicketClosed(t.status)) || []
  );
};

// combines invitations, open cases and drafts into one list, sorted in time.
export const getOpenCaseListItems = createSelector(
  getOpenCases,
  getOpenAndFutureInvitations,
  getDrafts,
  getOpenSupportTickets,
  (openCases, activeInvitations, drafts, openSupportTickets) => {
    const caseListItemCases = openCases.map((openCase) => ({
      type: 'case',
      timestamp: openCase.updated,
      payload: openCase,
    }));
    const caseListItemInvitations = activeInvitations
      // in caseList no patient initiated revisits should be visible.
      .filter(
        (invitation) => invitation.typeName !== InvitationType.patientRevisit
      )
      .map((invitation) => ({
        type: 'invitation',
        timestamp: invitation.opens,
        payload: invitation,
      }));

    const caseListItemDraft = drafts.map((draft) => ({
      type: 'draft',
      timestamp: draft.created,
      payload: draft,
    }));
    // We keep this list empty if there's no open support ticket,
    // otherwise it will contain one single card to link to the help center
    const supportMessageCardList =
      openSupportTickets.length > 0
        ? [
            {
              type: 'support',
              timestamp:
                getLatestTimestampFromSupportTicketList(openSupportTickets),
              payload: openSupportTickets,
            },
          ]
        : [];

    const openCaseListItems = [
      ...caseListItemCases,
      ...caseListItemInvitations,
      ...caseListItemDraft,
      ...supportMessageCardList,
    ].sort((a, b) => dayjs(b.timestamp).utc().diff(dayjs(a.timestamp).utc()));

    return openCaseListItems;
  }
);

const getFilteredOpenCases = (state, props) => {
  return getOpenCaseListItems(state).filter((caseItem) =>
    props.consultationIds.some(
      (consultationId) => consultationId === caseItem.payload.id
    )
  );
};

export const filterOpenCaseListItems = createSelector(
  getFilteredOpenCases,
  (filteredCases) => {
    return filteredCases;
  }
);

// This function fetches revisit invitations based on the origin caseId
export const invitationsByParentCaseId = ({ profile }) =>
  profile.invitationsByParentCaseId;

/**
 * @param {CommonStateType} state
 * @param {number} entrywayId
 * @returns {Invitation[]}
 */
export const getOpenInvitationsByEntrywayId = (state, entrywayId) =>
  getOpenInvitations(state).filter(
    (invitation) => invitation.revisitParentEntrywayId === entrywayId
  );

// Returns all open invitations for a case. See the InvitationType object for possible types.
// The getOpenInvitations selector is filtering out and only sends one revisit type invitation where
// caregiverRevisit > patientRevisit. If there are more invitation of non revisit type, these will also be sent.
export const getOpenInvitationsByCaseId = (
  state,

  caseId
) =>
  getOpenInvitations(state).filter(
    (invitation) => invitation.sourceCaseId === caseId
  );

// Returns future caregiverRevisit invitations, if there are any
export const getFutureRevisitInvitationByCaseId = (
  state,

  caseId
) => {
  const invitations = getAllInvitations(state);

  const result = Object.keys(invitations)
    .filter((invitationId) => {
      const { sourceCaseId, status, isOpen, typeName } =
        invitations[parseInt(invitationId, 10)];

      return (
        sourceCaseId === caseId &&
        status === InvitationStatus.RevisitStatusActive &&
        isOpen === false &&
        typeName === InvitationType.caregiverRevisit
      );
    })
    .map((id) => invitations[parseInt(id, 10)]);

  return result.length ? result[0] : undefined;
};

/**
 * Revisits can be made when having caregiverRevisit or patientRevisit invitations
 * internalReferral starts new cases.
 *
 * @param {CommonStateType} state
 * @param {number} entrywayId
 * @param {string=} patientUUID
 * @returns {null | Invitation}
 */
export const getValidGuideRevisitInvitation = (
  state,

  entrywayId,

  patientUUID
) => {
  const openInvitations = getOpenInvitationsByEntrywayId(state, entrywayId);

  if (!Array.isArray(openInvitations)) return null;

  let validInvitations = openInvitations.filter(
    (invitation) =>
      invitation.typeName === InvitationType.caregiverRevisit ||
      invitation.typeName === InvitationType.patientRevisit
  );

  if (patientUUID) {
    validInvitations = openInvitations.filter(
      (invitation) => invitation.patientUUID === patientUUID
    );
  }

  if (validInvitations.length === 1) {
    return validInvitations[0];
  }

  if (validInvitations.length > 1) {
    // pick the latest created invitation
    return validInvitations.reduce((acc, cur) => {
      const curCreatedMs = new Date(cur.created).getTime();
      const accCreatedMs = new Date(acc.created).getTime();

      if (curCreatedMs > accCreatedMs) {
        return cur;
      }

      return acc;
    }, validInvitations[0]);
  }

  return null;
};

export const showInactiveNotificationsReminder = createSelector(
  isPushEnabled,
  hasActiveCases,
  (notificationsAreEnabled, hasActiveCases) => {
    return !notificationsAreEnabled && hasActiveCases;
  }
);

// Returns an open revisit invitation for the current case if there are any.
// The getOpenInvitations is filtering out and only sends one open revisit invitation where caregiverRevisit > patientRevisit
export const getOpenRevisitInvitationByCaseId = (
  state,

  caseId
) => {
  const openInvitations = getOpenInvitationsByCaseId(state, caseId);

  if (!Array.isArray(openInvitations)) return undefined;

  const openDoctorRevisitInvitation = openInvitations.find(
    (invitation) => invitation.typeName === InvitationType.caregiverRevisit
  );

  if (openDoctorRevisitInvitation) {
    return openDoctorRevisitInvitation;
  }

  return undefined;
};

export const getOpenRevisitInvitationByInvitationId = (
  state,

  invitationId
) => {
  const openInvitations = getOpenInvitations(state);

  if (!Array.isArray(openInvitations)) return undefined;

  const openRevisitInvitation = openInvitations.find(
    (invitation) => invitation.id === invitationId
  );

  if (openRevisitInvitation) {
    return openRevisitInvitation;
  }

  return undefined;
};

export const getPatientNameAndBirthDate = (
  { profile },

  childUuid
) => {
  if (childUuid) {
    const child =
      profile.connections &&
      profile.connections.find((item) => item.uuid === childUuid);

    if (!child) return {};

    return {
      initials: getInitials(child.firstName, child.lastName),
      fullName: `${child.firstName} ${child.lastName}`,
      birthDate: child.birthDate,
    };
  }

  return {
    initials: getInitials(profile.firstName, profile.lastName),
    fullName: `${profile.firstName} ${profile.lastName}`,

    birthDate: profile.birthDate,
  };
};

// profileIdentityProtection returns true if a user has identity protection.
export function profileIdentityProtection(state) {
  return !!state.profile.identityProtection;
}

export function getTickets({ profile }) {
  return profile.tickets;
}
