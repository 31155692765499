import React from 'react';

const styles = {
  root: {
    padding: 16,
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.18)',
  },
};

/**
 * Wrapper for a case card. You can provide header and content as children.
 */
const Card: React.FC<{
  style?: React.CSSProperties;
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  isOpen?: boolean;
}> = ({ style, children, onClick }) => (
  <div
    style={{
      ...styles.root,
      cursor: !!onClick ? 'pointer' : 'normal',
      ...style,
    }}
    onClick={onClick}
    data-test-id="card"
  >
    {children}
  </div>
);

export default Card;
