import { _t } from '@mindoktor/patient-legacy/i18n';
import React, { Component } from 'react';

import { Body1, Title2 } from '../../../common/components/typography';
import { caseReferralType } from '../../../state/cases/constants';
import { labLevels } from '../../../state/referrals/constants';
import Button from '../../components/button/button';
import Link from '../../components/link';
import Modal from '../../components/modal';
import { hideModal } from '../../routing';
import { getSiteOptions } from '../../utils/site';

const styles = {
  section: {
    paddingBottom: 10,
  },

  button: {
    marginBottom: 10,
  },
};

// type Props = {
//   name: string,
//   eReferrals: boolean,
//   url?: string,
//   address1: string,
//   postalCode1: string,
//   city1: string,
//   notes?: string,
//   telephone?: string,
//   bookingNotes?: string,
//   level: number,
//   referralType: string,
//   submit: () => any,
// };

export default class ReferralLabModal extends Component {
  state = {
    submitting: false,
  };

  submit = async () => {
    const { submit } = this.props;

    this.setState({ submitting: true });

    await submit();

    this.setState({ submitting: false });
  };

  render() {
    const {
      name,
      eReferrals,
      url,
      address1,
      postalCode1,
      city1,
      notes,
      telephone,
      bookingNotes,
      level,
      referralType,
    } = this.props;
    const { submitting } = this.state;

    return (
      <Modal
        body={
          <div>
            <Title2 style={styles.section}>{name}</Title2>

            {!!url && (
              <div style={styles.section}>
                <Link href={url}>{_t('referral.website')}</Link>{' '}
              </div>
            )}

            {!!telephone && (
              <div style={styles.section}>
                <Link href={`tel:${telephone}`}>{telephone}</Link>{' '}
              </div>
            )}

            {!!notes && <Body1 style={styles.section}>{notes}</Body1>}

            {!!bookingNotes && (
              <Body1 style={styles.section}>{bookingNotes}</Body1>
            )}

            {eReferrals && (
              <Body1 style={styles.section}>
                {_t('referral.acceptsEreferral')}
              </Body1>
            )}

            {getSiteOptions().showLabGuaranteeLevel &&
              referralType === caseReferralType.LabTest &&
              level < labLevels.GUARANTEED && (
                <Body1 style={styles.section}>
                  {_t('referral.notCoveredByTimeGuarantee')}
                </Body1>
              )}

            <div>
              <Body1>{address1}</Body1>

              <Body1>{`${postalCode1} ${city1.toUpperCase()}`}</Body1>
            </div>
          </div>
        }
        footer={
          <div>
            <Button
              disabled={submitting}
              onClick={this.submit}
              style={styles.button}
            >
              {_t('referral.selectLab')}
            </Button>

            <Button type="flat" color="black" onClick={hideModal}>
              {_t('common.close')}
            </Button>
          </div>
        }
      />
    );
  }
}
