import React from 'react';

import BoxedScreen from '../boxed_screen';
import Spinner from './item';

const styles = {
  wrapper: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const BoxedScreenComponent = () => (
  <BoxedScreen
    body={
      <div style={styles.wrapper}>
        <Spinner />
      </div>
    }
  />
);

export default BoxedScreenComponent;
