import { _t } from '@mindoktor/patient-legacy/i18n';
import React, { Component } from 'react';

import colors from '../../../common/colors';
import { Intro, Title1 } from '../../../common/components/typography';
import MediaQuery from '../../components/media_query';

const styles = {
  title: {
    paddingTop: 48,
    paddingBottom: 26,
    textAlign: 'center',
  },

  titleLarge: {
    paddingTop: 88,
    paddingBottom: 56,
    fontSize: 40,
    lineHeight: '47px',
  },

  tabs: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },

  tab: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 12,
    paddingBottom: 12,
    marginLeft: 6,
    marginRight: 6,
    borderBottom: '3px solid transparent',
    fontWeight: 500,
    cursor: 'pointer',
  },

  tabSelected: {
    borderBottomColor: colors.red_500,
    color: colors.red_500,
  },

  tabHover: {
    color: colors.red_500,
  },
};

// type TabProps = {
//   text: string,
//   selected?: boolean,
//   onClick: () => any,
// };

class Tab extends Component {
  state = {
    hover: false,
  };

  onMouseEnter = () => this.setState({ hover: true });

  onMouseLeave = () => this.setState({ hover: false });

  render() {
    const { text, selected, onClick } = this.props;
    const { hover } = this.state;

    return (
      <Intro
        style={{
          ...styles.tab,
          ...(selected && styles.tabSelected),
          ...(hover && styles.tabHover),
        }}
        onClick={onClick}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        {text}
      </Intro>
    );
  }
}

// type Props = {
//   category: string,
//   showChildGuides: boolean,
//   onTabClick: string => any,
//   style?: any,
// };

export default class Tabs extends Component {
  render() {
    const { category, showChildGuides, onTabClick, style } = this.props;

    const title = showChildGuides
      ? _t('guides.categoryTitle')
      : _t('guides.categoryTitleWithoutChild');

    return (
      <div
        style={{
          backgroundColor: colors.grey_100,
          ...style,
          paddingBottom: showChildGuides ? 0 : 22,
        }}
      >
        <MediaQuery maxWidth={800}>
          <Title1 style={styles.title}>{title}</Title1>
        </MediaQuery>

        <MediaQuery minWidth={801}>
          <Title1
            style={{
              ...styles.title,
              ...styles.titleLarge,
            }}
          >
            {title}
          </Title1>
        </MediaQuery>

        {showChildGuides && (
          <div style={styles.tabs}>
            <Tab
              text={_t('guides.list.tabs.adult')}
              selected={category === 'adult'}
              onClick={() => onTabClick('adult')}
            />

            <Tab
              text={_t('guides.list.tabs.child')}
              selected={category === 'child'}
              onClick={() => onTabClick('child')}
            />
          </div>
        )}
      </div>
    );
  }
}
