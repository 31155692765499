import { _t } from '@mindoktor/patient-legacy/i18n';
import React, { Component } from 'react';

import { Body1, Title2 } from '../../../common/components/typography';
import Button from '../../components/button/button';
import TextInput from '../../components/input/text';
import Modal from '../../components/modal';
import { hideModal } from '../../routing';

const styles = {
  title: {
    paddingBottom: 16,
  },

  lead: {
    paddingBottom: 16,
  },

  submit: {
    marginBottom: 8,
  },
};
export default class VideocallRescheduleModal extends Component {
  state = {
    text: '',
  };

  onTextChange = (text) => {
    this.setState({ text });
  };

  submit = () => {
    const { submit } = this.props;
    const { text } = this.state;

    if (!text.trim()) return;

    submit(text);

    hideModal();
  };

  render() {
    return (
      <Modal
        body={
          <div>
            <Title2 style={styles.title}>
              {_t('videoMeeting.reschedule.title')}
            </Title2>

            <Body1 style={styles.lead}>
              {_t('videoMeeting.reschedule.description')}
            </Body1>

            <TextInput
              placeholder={_t('videoMeeting.reschedule.placeholder')}
              multiline={true}
              autoFocus={true}
              onChange={this.onTextChange}
            />
          </div>
        }
        footer={
          <div>
            <Button color="black" style={styles.submit} onClick={this.submit}>
              {_t('videoMeeting.reschedule.submit')}
            </Button>

            <Button type="flat" color="black" onClick={hideModal}>
              {_t('videoMeeting.reschedule.cancel')}
            </Button>
          </div>
        }
      />
    );
  }
}
