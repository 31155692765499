import {
  FILES_CACHE_ADD,
  FILES_POST,
  FILES_POST_ERROR,
  FILES_POST_SUCCESS,
} from './types';

const initialState = {
  uploading: 0,
  cache: {},
};

export default function authReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case FILES_POST:
      return {
        ...state,
        uploading: state.uploading + 1,
      };

    case FILES_POST_SUCCESS:
    case FILES_POST_ERROR:
      return {
        ...state,
        uploading: state.uploading - 1,
      };

    case FILES_CACHE_ADD: {
      const { fileId, path } = payload || {};

      return {
        ...state,
        cache: {
          ...state.cache,
          [fileId]: path,
        },
      };
    }

    default:
      return state;
  }
}
