import { useQuery } from '@tanstack/react-query';

import { ResponseError } from '@mindoktor/patient-app/api/responseError';

import { hostApi } from '../api/host';
import { HostData } from '../api/models/host';

const queryKey = 'host';

export const useHostApi = () => {
  return useQuery<HostData | undefined, ResponseError>(
    [queryKey],
    async () => {
      const response = await hostApi.get();
      if (!response?.ok) {
        throw new ResponseError(response);
      }

      return response?.data?.data;
    },
    {
      staleTime: Infinity, // never consider this data stale
    }
  );
};
