import React from 'react';

import colors from '../../../common/colors';

const Item = ({ size = 160, thickness = 6, style }) => (
  <div style={style} className="md-spinner">
    <style>{`
      .md-spinner {
        position: relative;
        font-size: 10px;
        text-indent: -1000px;
        border-top: ${thickness}px solid #e6e6e6;
        border-right: ${thickness}px solid #e6e6e6;
        border-bottom: ${thickness}px solid ${colors.red_500};
        border-left: ${thickness}px solid ${colors.red_500};
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-animation: md-spinner-animation 1.5s infinite linear;
        animation: md-spinner-animation 1.5s infinite linear;
      }
      .md-spinner,
      .md-spinner:after {
        border-radius: 50%;
        width: ${size}px;
        height: ${size}px;
        min-width: ${size}px;
        min-height: ${size}px;
      }
      @-webkit-keyframes md-spinner-animation {
        0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
        }
        100% {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }
      @keyframes md-spinner-animation {
        0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
        }
        100% {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }
    `}</style>
  </div>
);

export default Item;
