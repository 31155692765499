import React, { Component } from 'react';

import Markdown from '../../../components/markdown';
import Message from '../../components/message';
import Header from './header';
import ActionArea from './action_area';

const styles = {
  text: {
    whiteSpace: 'pre-wrap',
  },
};

/**
 * Generic message card that renders an image, title, timestamp and a
 * description on a layout that is common for most of our message types.
 *
 * Takes an optional action component that will be layed out at the bottom
 * of the card if present.
 */
export class MessageCard extends Component {
  static defaultProps = {
    titleBold: true,
  };

  render() {
    const {
      imageUrl,
      title,
      titleBold,
      timestamp,
      description,
      style,
      actionComponent,
    } = this.props;

    return (
      <Message style={style}>
        <Header
          image={imageUrl}
          timestamp={timestamp}
          title={title}
          bold={titleBold}
        />

        {description && <Markdown source={description} style={styles.text} />}
        {actionComponent && <ActionArea>{actionComponent}</ActionArea>}
      </Message>
    );
  }
}

export default MessageCard;
