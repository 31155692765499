import { getGuideByEntrywayId, getGuidePrice } from '../guides/selectors';
import { paymentMethodTypes, PaymentMethodType } from './types';
import { isValidFreePass } from '../freepass/utils';
import { CommonStateType } from '../types';

/**
 * Provides the payment method for a guide based on price and free pass.
 */
export const getPaymentMethod = (
  state: CommonStateType,
  entrywayId: number
): NonNullable<PaymentMethodType> => {
  if (getGuidePrice(state, entrywayId) === 0) {
    return paymentMethodTypes.FREE;
  }

  if (
    isValidFreePass(state?.freePass) &&
    getGuideByEntrywayId(state, entrywayId)?.allowFreePass
  ) {
    return paymentMethodTypes.FREEPASS;
  }

  return paymentMethodTypes.INVOICE;
};
