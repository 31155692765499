import {
  ScrollPositionOption,
  ScrollPositionOptionDict,
} from '../components/ListView/constants';
import { ElementRect } from '../types';

type ComputeScrollPositionFunction = (
  elementRect: ElementRect,
  containerRect: ElementRect,
  positionOffset: number
) => number;

/**
 * Computes the value for scrolling to show a target element at the bottom.
 */
const computeScrollBottomPosition: ComputeScrollPositionFunction = (
  elementRect,
  containerRect,
  positionOffset
) => elementRect.y - containerRect.height + elementRect.height + positionOffset;

/**
 * Computes the value for scrolling to show a target element at the top.
 */
const computeScrollTopPosition: ComputeScrollPositionFunction = (
  elementRect,
  _containerRect,
  positionOffset
) => elementRect.y - positionOffset;

export const isScrollAtBottom = (
  scrollOffsetY: number,
  contentHeight: number,
  scrollElementHeight: number,
  paddingBottom: number = 0
): boolean => {
  return contentHeight <= scrollOffsetY + scrollElementHeight + paddingBottom;
};

export const getScrollPositionFunc = (
  option: ScrollPositionOption | null | undefined
): ComputeScrollPositionFunction => {
  switch (option) {
    case ScrollPositionOptionDict.viewableAreaTop:
      return computeScrollTopPosition;

    case ScrollPositionOptionDict.viewableAreaBottom:
      return computeScrollBottomPosition;

    default:
      return computeScrollBottomPosition;
  }
};
