import { SURVEY2_SHOW_MEDICAL_COMPLAINTS_INFO } from '../../state/survey2/actionTypes';
import { showModal } from '../routing';
import MedicalComplaintsModal from './medical_complaints_modal';

const middleware =
  ({}) =>
  (next) =>
  (action) => {
    switch (action.type) {
      case SURVEY2_SHOW_MEDICAL_COMPLAINTS_INFO:
        showModal(MedicalComplaintsModal);
        break;
    }
    next(action);
  };

export default middleware;
