import { _t } from '@mindoktor/patient-legacy/i18n';
import React from 'react';
import { connect } from 'react-redux';

import { setInformedConsent } from '../../../state/consents/actions';
import { getInformedConsentsByEntrywayId } from '../../../state/consents/selectors';
import constants from '../../constants';
import Checkbox from '../input/Checkbox';
import View from '../primitives/View';
import { Title1 } from '../typography';
import ConsentMarkdown from './markdown';

// type Props = {
//   informedConsent: InformedConsent,
//   setInformedConsent: Function,
// };
// type State = {};

const styles = {
  root: {
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 30,
  },

  title: {
    marginBottom: 20,
    fontSize: 36,
    lineHeight: 44,
  },
};

class InformedConsentContent extends React.Component {
  updateInformedConsent = (checked) => {
    const { setInformedConsent } = this.props;
    setInformedConsent(this.props.entrywayId, 'second', !checked);
  };

  render() {
    const { informedConsent } = this.props;
    const checked = !!informedConsent && informedConsent.second;

    return (
      <View style={styles.root}>
        <Title1 style={styles.title}>{_t('consent.informed.title')}</Title1>
        <ConsentMarkdown style={{ marginBottom: constants.spacingSmall }} />

        <Checkbox
          onChange={() => this.updateInformedConsent(checked)}
          checked={checked}
          label={_t('consent.informed.confirmAbove')}
        />
      </View>
    );
  }
}

export default connect(
  (state, props) => {
    const informedConsent = getInformedConsentsByEntrywayId(
      props.entrywayId,
      state
    );
    return {
      informedConsent,
    };
  },
  {
    setInformedConsent,
  }
)(InformedConsentContent);
