const maxPushQuestions = 3;

export const shouldShowPushPrimer = ({ push }) =>
  !push.pushEnabled &&
  push.shouldRequestPushPermissions &&
  maxPushQuestions > push.numberOfTimesPushPrimerHasBeenShown;

export const didDelayPushAcceptance = ({ push }) =>
  !push.pushEnabled && push.shouldRequestPushPermissions;

export const isPushEnabled = ({ push }) => push.pushEnabled;

export const hasRequestedPermissions = ({ push }) =>
  push.hasRequestedPermissions;
