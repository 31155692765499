import React from 'react';

import Spinner from '../../components/spinner/item';

const Loading = () => (
  <div
    style={{
      width: '100%',
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Spinner />
  </div>
);

export default Loading;
