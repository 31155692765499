import Badge from '@material-ui/core/Badge';
import React, { Component } from 'react';

import colors from '../../common/colors';
import { Body1, Body3 } from '../../common/components/typography';

const styles = {
  item: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
    cursor: 'pointer',
    fontWeight: 500,
  },

  itemHover: {
    backgroundColor: colors.red_500,
    color: 'white',
  },

  icon: {
    display: 'block',
    width: 24,
    height: 24,
    marginRight: 10,
    color: 'grey',
  },

  iconSmall: {
    width: 20,
    height: 20,
  },

  iconHover: {
    color: 'white',
  },
};

interface Props {
  iconComponent: React.FC;
  text: string;
  small?: boolean;
  style?: React.CSSProperties;
  onClick: () => void;
  badgeContent?: React.ReactNode;
  badgeMax?: number;
}

export default class UserItem extends Component<Props> {
  state = {
    hover: false,
  };

  onMouseEnter = () => this.setState({ hover: true });

  onMouseLeave = () => this.setState({ hover: false });

  render() {
    const {
      iconComponent,
      text,
      small,
      style,
      onClick,
      badgeContent,
      badgeMax = 9,
    } = this.props;
    const { hover } = this.state;

    const icon =
      iconComponent &&
      iconComponent({
        style: {
          ...styles.icon,
          ...(small && styles.iconSmall),
          ...(hover && styles.iconHover),
        },
      });

    const TextComponent = small ? Body3 : Body1;

    return (
      <TextComponent
        style={{
          ...styles.item,
          ...(hover ? styles.itemHover : undefined),
          ...style,
        }}
        onClick={onClick}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        {badgeContent ? (
          <Badge
            color="primary"
            badgeContent={badgeContent}
            max={badgeMax}
            style={styles.icon}
          >
            {icon}
          </Badge>
        ) : (
          icon
        )}

        {text}
      </TextComponent>
    );
  }
}
