import { getApi, LOGIN } from '../../state/api/actions';
import { isLoggedIn } from '../../state/api/selectors';
import { APP_START } from '../../state/app/actions';
import { HINT_TYPES, HINT_URL } from '../../state/contextualHints/constants';
import { showModal } from '../routing';
import { getPlatform, Platforms } from '../utils/device';
import PrintModal from './components/print_modal';

const handleHints = async (dispatch) => {
  const { error, json: hints } = await dispatch(getApi(HINT_URL));

  if (error) {
    // No notification, just fail silently
    // This should not affect the service in other ways
    if (typeof error === 'string') {
      console.warn(error);
      return;
    }

    console.warn('Something went wrong handling contextual hints');
    return;
  }
  if (!hints || !hints.length) return;

  const hint = hints[0];

  switch (hint.typeName) {
    case HINT_TYPES.PRINT_REFERRAL: {
      if (getPlatform() !== Platforms.DESKTOP) break;
      showModal(PrintModal, { hint });
      break;
    }
    default:
      // No notification, just fail silently
      // This should not affect the service in other ways
      console.error(hint.typeName + ' is not a valid contextual hint type');
      break;
  }
};

const middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    switch (action.type) {
      case APP_START:
        if (isLoggedIn(getState())) handleHints(dispatch);
        break;
      case LOGIN: {
        handleHints(dispatch);
        break;
      }
    }

    return next(action);
  };

export default middleware;
