import { SxProps, Theme } from '@mui/material';

import { colors } from '@mindoktor/pulse/src/styles/colors';

const classes: Record<string, SxProps<Theme>> = {
  root: {
    width: '100%',
    flex: 1,
    flexWrap: 'wrap',
    alignContent: 'center',
    marginBottom: '0.5rem',
  },
  imageWrapper: ({ spacing, breakpoints: { down } }) => ({
    backgroundColor: colors.white,
    borderRadius: 2,
    height: 156,
    width: 156,
    padding: '1rem',
    alignSelf: 'center',
    justifyContent: 'center',
    marginBottom: '1rem',
    [down('sm')]: {
      marginBottom: 0,
    },
  }),
  text: ({ spacing, breakpoints: { down, up } }) => ({
    paddingX: '2rem',
    paddingBottom: '1rem',
    textAlign: 'center',
    margin: 0,
  }),
  image: {
    height: 'auto',
    maxWidth: '100%',
    width: '100%',
  },
};

export default classes;
