import { notificationTypeStrings, UserNotification } from './types';

const getSupportTicketNotifications = (notifications: UserNotification[]) =>
  notifications.filter(({ type }) =>
    (
      [
        notificationTypeStrings.typeSupportTicketCreated,
        notificationTypeStrings.typeSupportTicketUpdated,
      ] as (typeof type)[]
    ).includes(type)
  );

export default getSupportTicketNotifications;
