import { _t } from '@mindoktor/patient-legacy/i18n';
import React from 'react';

import { Body1, Title2 } from '../../../common/components/typography';
import Button from '../../components/button/button';
import Modal from '../../components/modal';
import { hideModal } from '../../routing';

const styles = {
  title: {
    marginBottom: 10,
  },

  confirm: {
    marginBottom: 10,
  },
};

const CancelModal = ({ confirm }) => (
  <Modal
    body={
      <div>
        <Title2 style={styles.title}>
          {_t('formulary.cancelModal.title')}
        </Title2>

        <Body1>{_t('formulary.cancelModal.body')}</Body1>
      </div>
    }
    footer={
      <div>
        <Button color="red" style={styles.confirm} onClick={confirm}>
          {_t('formulary.cancelModal.exit')}
        </Button>

        <Button type="flat" color="black" onClick={hideModal}>
          {_t('formulary.cancelModal.cancel')}
        </Button>
      </div>
    }
  />
);

export default CancelModal;
