import { _t } from '@mindoktor/patient-legacy/i18n';
import dayjs from 'dayjs';
import {
  FreePassState,
  FREEPASS_EFRIKORT_SERVICENAME,
  freePassStatuses,
} from './types';

/**
 * @description simple translate mechanism for freepass error messages
 * @param {string} msg e.g. `LNGFreePassInvalidIssuer`
 * @returns the corresponding i18n string for the error message or empty string
 */
export const translateErrorMessage = (msg: string) => {
  switch (msg) {
    case 'LNGFreePassInvalidIssuer':
      return _t('freePass.invalidIssuer');
    case 'LNGFreePassInvalidNumber':
      return _t('freePass.invalidNumber');
    case 'LNGFreePassInvalidExpires':
      return _t('freePass.invalidExpires');
    case 'LNGFreePassExpired':
      return _t('freePass.expired');
    default:
      return '';
  }
};

/**
 * @description simple check if this is one of the regular validation error
 *   messages (e.g. `LNGFreePassInvalidIssuer`) returned from api.
 */
export const isFreePassValidationError = (msg: string) => {
  return translateErrorMessage(msg) !== '';
};

/**
 * @description checks for a character string of 4 to 8 characters.
 */
export const validateFreePassNumber = (value: string) => {
  const regExp = /^[\w]{4,9}$/g;
  return regExp.test(value) ? '' : 'LNGFreePassInvalidNumber';
};

/**
 * @description checks if the provided date is on the form YYMMDD and that the
 * date hasn't expired (is today or within the span of one year ahead).
 */
export const validateFreePassExpireDate = (value: string) => {
  if (value.length !== 6) return 'LNGFreePassInvalidExpires';
  const now = dayjs().startOf('day');
  const date = dayjs(value, 'YYMMDD');
  if (!date.isValid()) return 'LNGFreePassInvalidExpires';
  if (!date.isSameOrAfter(now)) return 'LNGFreePassExpired';
  if (!date.isSameOrBefore(now.add(1, 'y'))) return 'LNGFreePassExpired';
  return '';
};

/**
 * @description checks that there is a valid region selected
 */
export const validateFreePassIssuer = (value: string) => {
  if (!value || value === '-') return 'LNGFreePassInvalidIssuer';
  return '';
};

/**
 * isValidFreePass performs a simple check if a freepass is valid.
 * Note: Validation of each field isn't performed here.
 */
export const isValidFreePass = (freepass?: FreePassState) => {
  return (
    !!freepass?.expires &&
    !!freepass.issuer &&
    !!freepass.number &&
    !freepass.hasError &&
    freepass?.status === freePassStatuses.FINAL
  );
};

/**
 * isValidEFrikort checks if a freepass is of the type eFrikort and if it's valid.
 */
export const isValidEFrikort = (freepass: FreePassState) => {
  return (
    isValidFreePass(freepass) &&
    freepass?.origin === FREEPASS_EFRIKORT_SERVICENAME
  );
};
