import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { _t } from '@mindoktor/patient-legacy/i18n';

import colors from '../../common/colors';
import { Title1, Body1 } from '../../common/components/typography';
import formatOpeningHours from '../../api/format/formatOpeningHours';
import getOpeningHours from '../../api/helpCenter/getOpeningHours';
import getSupportPhoneNumber from '../../api/helpCenter/getSupportPhoneNumber';
import formatPhone from '../../api/format/formatPhone';
import Markdown from '../components/markdown';
import RedArrowIcon from '../../../images/icon/red_arrow@3x.png';
import IconSupportColor from '../../../images/icon/icon_support_color@4x.png';
import Img from '@mindoktor/pulse/src/utils/images/Img.web';

const useStyles = makeStyles({
  wrapper: {
    width: 'calc(100% - 40px)',
    maxWidth: 760,
    margin: '0 auto',
  },
  phoneText: {
    display: 'inline-block',
    fontWeight: 500,
    color: colors.red_500 + ' !important',
  },
  arrowIcon: {
    paddingLeft: '0.5em',
    height: '0.7em',
    display: 'inline-block',
    margin: 0,
  },
  marginBefore: {
    marginTop: '1.5rem',
  },
  marginAfter: {
    marginBottom: '1.5rem',
  },
  smallHeading: {
    fontWeight: 500,
    marginBottom: '0.2em',
  },
  title: {
    maxWidth: 800,
    fontSize: 32,
    lineHeight: '38px',
    fontWeight: 900,
  },

  supportIcon: {
    height: '2rem',
  },
});

const TextVariantMap = {
  FEEDBACK: 'modals.hiddenidentity.body.feedback',
  LAB_REFERRAL: 'modals.hiddenidentity.body.labreferrals',
} as const;

export const HiddenIdentityTextVariant = {
  FEEDBACK: 'FEEDBACK',
  LAB_REFERRAL: 'LAB_REFERRAL',
} as { [key in keyof typeof TextVariantMap]: key };

interface Props {
  className?: string;
  textVariant?: keyof typeof TextVariantMap;
}

const HiddenIdentityComponent: React.FC<Props> = ({
  className,
  textVariant,
}) => {
  const classes = useStyles();
  return (
    <div className={classNames(className)}>
      <Img className={classNames(classes.supportIcon)} src={IconSupportColor} />
      <Title1
        className={classNames(
          classes.title,
          classes.marginBefore,
          classes.marginAfter
        )}
      >
        {_t('modals.hiddenidentity.title')}
      </Title1>
      <Markdown
        source={
          _t('modals.hiddenidentity.body.intro') +
          ' ' +
          _t(TextVariantMap[textVariant || HiddenIdentityTextVariant.FEEDBACK])
        }
      />
      <Body1 className={classNames(classes.smallHeading, classes.marginBefore)}>
        {_t('common.helpCentre.title')}
      </Body1>
      <a href={`tel:${getSupportPhoneNumber()}`}>
        <div>
          <Body1 className={classes.phoneText}>
            {formatPhone(getSupportPhoneNumber())}
          </Body1>
          <Img className={classes.arrowIcon} src={RedArrowIcon} />
        </div>
      </a>

      <Body1 className={classNames(classes.smallHeading, classes.marginBefore)}>
        {_t('common.opening.hours')}
      </Body1>
      {getOpeningHours().map((openingsObj, i) => (
        <Body1 key={i}>{formatOpeningHours(openingsObj)}</Body1>
      ))}
    </div>
  );
};

export default HiddenIdentityComponent;
