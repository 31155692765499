import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import compose from '@mindoktor/patient-legacy/src/common/compose';

import { getAbortText } from '../../../state/formulary/api';
import { getFormularyState } from '../../../state/formulary/selectors';
import { isChildGuide } from '../../../state/guides/guidesUtils';
import { getGuideByEntrywayId } from '../../../state/guides/selectors';
import BoxedScreen from '../../components/boxed_screen';
import LimitedHtml from '../../components/html/limited';
import Markdown from '../../components/markdown';
import { goToAdultGuides, goToChildGuides } from '../../routing';
import GuideBar from '../components/bar';
import { getGuideImageByEntrywayId } from '../utils/images';
import guideLoader from '../utils/loader';
import {
  WithRoutingProps,
  withRouting,
} from '@mindoktor/patient-app/routing/HOC/withRouting.web';
import { CommonStateType } from '../../../state/types';
import { MarketingTrackingActions } from '@mindoktor/patient-app/tracking/api/models/marketingTracking';
import { useMarketingTracking } from '@mindoktor/patient-app/tracking/hooks/useMarketingTracking';

const styles = {
  description: {
    maxWidth: 760,
    padding: 20,
  },
};

interface Props {
  entrywayId: string;
  formularyKey: string;
  isChild: boolean;
  reason?: string;
  iconSource?: any;
  title?: string;
  description?: string;
  getAbortText: ReturnType<typeof getAbortText>;
}

const AbortScreen: React.FC<Props> = (props) => {
  const [text, setText] = useState();
  const marketingTracking = useMarketingTracking();

  useEffect(() => {
    fetchAbortText();
  }, []);

  useEffect(() => {
    marketingTracking.track(MarketingTrackingActions.GuideAborted, {
      label: 'Guide',
    });
  }, []);

  const fetchAbortText = async () => {
    const { entrywayId, reason, getAbortText } = props;

    if (reason) {
      const { text, error } = await getAbortText(parseInt(entrywayId), reason);

      if (!error) {
        setText(text);
      }
    }
  };

  const onCancel = () => {
    if (props.isChild) {
      goToChildGuides();
    } else {
      goToAdultGuides();
    }
  };

  const { reason, iconSource, title, description } = props;

  return (
    <BoxedScreen
      header={
        <GuideBar iconSource={iconSource} title={title} onCancel={onCancel} />
      }
      body={
        reason ? (
          <Markdown source={text} style={styles.description} />
        ) : (
          <LimitedHtml html={description} style={styles.description} />
        )
      }
      showScrollGradient={true}
    />
  );
};

export const AbortScreenForTest = AbortScreen;

const enhance = compose(
  withRouting,
  guideLoader,
  connect(
    (state: CommonStateType, props: Props & WithRoutingProps) => {
      const {
        formularyKey,
        routing: {
          params: { entrywayId },
          queryParams,
        },
      } = props;

      const reason = queryParams.get('reason');
      const guide = getGuideByEntrywayId(state, entrywayId) || {};
      const isChild = guide ? isChildGuide(guide) : false;

      const { formulary } = getFormularyState(state, formularyKey || '') || {};

      return {
        iconSource: getGuideImageByEntrywayId(entrywayId),
        title: guide.displayTitle,
        description:
          formulary && formulary.abort && formulary.abort.description,
        isChild: isChild,
        entrywayId,
        reason,
      };
    },
    { getAbortText }
  )
);

const EnhancedAbortScreen = enhance(AbortScreen);

export default EnhancedAbortScreen;
