import { schema } from 'normalizr';

// use entrywayId as primary id for guides
const guideSchema = new schema.Entity(
  'guides',
  {},
  { idAttribute: 'entrywayId' }
);

const messageSchema = new schema.Entity(
  'messages',
  {},
  {
    // cId = temporary "fake" id, since only text messages have an id attribute
    idAttribute: 'cId',
  }
);
const caseSchema = new schema.Entity('cases', { messages: [messageSchema] });

export default {
  cases: [caseSchema],
  guides: [guideSchema],
  messages: [messageSchema],
};
