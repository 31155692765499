import { _t } from '@mindoktor/patient-legacy/i18n';
import React from 'react';
import { connect } from 'react-redux';

import Button from '../../../common/components/Button';
import spacing from '../../../common/spacing';
import { API_URL } from '../../../state/api';
import { postApi } from '../../../state/api/actions';
import { HINT_URL } from '../../../state/contextualHints/constants';
import { formatRoute } from '../../../state/routing/utils';
import Markdown from '../../components/markdown';
import Modal from '../../components/modal';
import { hideModal } from '../../routing';

const referralDocumentUrl = ({ caseId, referralId }) =>
  API_URL +
  formatRoute(`/api/v1/cases/${caseId}/referrals`, {
    id: referralId,
    request: 'file',
  });

// type Props = {
//   hint: ContextualHint,
//   postApi: typeof postApi,
// };

class PrintModal extends React.Component {
  changeStatus = async (statusName) => {
    const {
      postApi,
      hint: { id },
    } = this.props;

    const res = await postApi(`${HINT_URL}/${id}/status`, {
      statusName,
    });

    const { error, status } = res;
    // No notification, just fail silently
    // This should not affect the service in other ways
    if (error) console.error(status);
  };

  render() {
    return (
      <Modal
        contentStyle={{ padding: spacing.t }}
        onBackgroundClick={() => {
          hideModal();
          this.changeStatus('rejected');
        }}
        body={
          <Markdown
            style={{ marginBottom: spacing.xs }}
            source={_t('printModal.body')}
          />
        }
        footer={
          <div>
            <Button
              type="solid"
              style={{ marginBottom: spacing.xt }}
              onPress={() => {
                hideModal();
                this.changeStatus('consumed').then(
                  () =>
                    (window.location.href = referralDocumentUrl(
                      this.props.hint
                    ))
                );
              }}
            >
              {_t('printModal.print')}
            </Button>
            <Button
              type="flat"
              onPress={() => {
                hideModal();
                this.changeStatus('rejected');
              }}
            >
              {_t('printModal.cancel')}
            </Button>
          </div>
        }
      />
    );
  }
}

export default connect((state) => state, {
  postApi,
})(PrintModal);
