import { getIn } from 'timm';

import { MessageType } from '../cases/constants';
import { getCaseMessages } from '../cases/selectors';
import { getQuestionnaireData } from './selectors';

const hasMessages = (state, caseId) =>
  !!(getCaseMessages(state, Number(caseId)) || []).filter(
    ({ type }) => type !== MessageType.FORMULARY_ANSWERS
  ).length;

const isFinished = (state, caseId, surveyName) =>
  getIn(getQuestionnaireData(state, caseId, surveyName), ['isFinished']);

const hasUnsubmittedAnswers = (state, caseId, surveyName) =>
  getIn(getQuestionnaireData(state, caseId, surveyName), [
    'hasUnsubmittedAnswers',
  ]);

const checker = ({ state, caseId, surveyName }) =>
  (hasMessages(state, caseId) &&
    !hasUnsubmittedAnswers(state, caseId, surveyName)) ||
  isFinished(state, caseId, surveyName);

export default checker;
