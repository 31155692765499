import React, { useState } from 'react';

import { Container } from '@mui/material';

import { getImageSrc } from '@mindoktor/pulse/src/utils/images/getImageSrc';

import BubbleScreenLayout from '@mindoktor/patient-app/layout/components/BubbleScreenLayout/web';
import { FixedContentLayout } from '@mindoktor/patient-app/layout/web';
import { routes } from '@mindoktor/patient-app/routing/constants';
import { useNavigation } from '@mindoktor/patient-app/routing/web';
import { isMobile } from '@mindoktor/patient-app/utils/device/web';

import { UserStatusStatuses } from '../../../api/models/userStatus';
import _bubbleImageSource from '../../../images/registration_desktop_step_2.webp';
import _bubbleImageSourceMobile from '../../../images/registration_mobile_step_2.webp';
import { useUserStatus } from '../../../web';
import LogoutSheet from '../../LogoutSheet/web';
import RegistrationContent from '../../RegistrationContent/web';

const bubbleImageSource = getImageSrc(_bubbleImageSource);
const bubbleImageSourceMobile = getImageSrc(_bubbleImageSourceMobile);

const RegistrationScreen: React.FC = () => {
  const [showLogoutSheet, setShowLogoutSheet] = useState(false);

  const { data: userStatus } = useUserStatus(true);
  const navigation = useNavigation();

  const backButtonAction = () => {
    // For new users we navigate back to the tos page, otherwise ask if the user
    // wants to log out.
    if (userStatus.status === UserStatusStatuses.UserStatusPending) {
      navigation.navigate(routes.TOS_PRIVACY);
    } else {
      setShowLogoutSheet(true);
    }
  };

  return (
    <FixedContentLayout
      showFooter={false}
      showMenuBarActions={false}
      onBack={backButtonAction}
    >
      <BubbleScreenLayout
        imgSrcDesktop={bubbleImageSource}
        imgSrcMobile={bubbleImageSourceMobile}
        justifyContent={'center'}
      >
        <Container
          maxWidth="sm"
          sx={{
            padding: 0,
            marginTop: '29vh',
            display: 'flex',
            flex: 1,
            overflowX: 'hidden',
            overflowY: isMobile ? 'hidden' : 'unset',
          }}
        >
          <RegistrationContent />

          <LogoutSheet
            isOpen={showLogoutSheet}
            onClose={() => setShowLogoutSheet(false)}
          />
        </Container>
      </BubbleScreenLayout>
    </FixedContentLayout>
  );
};

export default RegistrationScreen;
