import React, { CSSProperties, Component, MouseEvent } from 'react';

import colors from '../../../common/colors';
import { Intro } from '../../../common/components/typography';

export const colorOptions = {
  transparent: {
    backgroundColor: 'transparent',
    highlightColor: colors.off_black,
    contentColor: colors.grey_900,
  },

  secondary: {
    backgroundColor: colors.grey_900,
    highlightColor: colors.grey_900,
    contentColor: 'white',
  },

  white: {
    backgroundColor: 'white',
    highlightColor: colors.grey_100,
    contentColor: colors.grey_900,
  },

  black: {
    backgroundColor: colors.grey_900,
    highlightColor: colors.off_black,
    contentColor: 'white',
  },

  blue: {
    backgroundColor: colors.blue_500,
    highlightColor: colors.blue_500,
    contentColor: 'white',
  },

  red: {
    backgroundColor: colors.red_500,
    highlightColor: colors.red_400,
    contentColor: 'white',
  },

  yellow: {
    backgroundColor: colors.yellow_500,
    highlightColor: colors.yellow_500,
    contentColor: 'black',
  },

  green: {
    backgroundColor: colors.green_500,
    highlightColor: colors.green_500,
    contentColor: 'white',
  },

  green_strong: {
    backgroundColor: colors.green_800,
    highlightColor: colors.green_800,
    contentColor: 'white',
  },
};

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
};

/**
 * @typedef {import('./button').Props} Props
 * @typedef {import('./button').State} State
 * @extends Component<Props, State>
 */

interface Props {
  type?: 'solid' | 'flat' | 'outline';
  color?:
    | 'transparent'
    | 'white'
    | 'black'
    | 'blue'
    | 'red'
    | 'yellow'
    | 'green'
    | 'green_strong'
    | 'secondary';
  disabled?: boolean;
  hero?: boolean;
  style?: CSSProperties;
  children: React.ReactNode;
  hrefTarget?: string;
  className?: string;
  href?: string;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
}

export default class Button extends Component<Props> {
  state = {
    hover: false,
  };

  onMouseEnter = () => this.setState({ hover: true });

  onMouseLeave = () => this.setState({ hover: false });

  onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { href, hrefTarget, onClick } = this.props;

    if (href && hrefTarget) {
      window.open(href, hrefTarget);
    } else if (href) {
      window.location.href = href;
    }

    onClick && onClick(event);
  };

  render() {
    const {
      type = 'solid',
      color = 'red',
      disabled,
      hero,
      style,
      children,
      className,
    } = this.props;

    const { hover } = this.state;

    const { backgroundColor, highlightColor, contentColor } =
      colorOptions[color] || colorOptions.blue;

    return (
      <Intro
        onClick={disabled ? undefined : this.onClick}
        onMouseEnter={disabled ? undefined : this.onMouseEnter}
        onMouseLeave={disabled ? undefined : this.onMouseLeave}
        className={className}
        style={{
          fontWeight: 500,
          backgroundColor:
            type === 'solid'
              ? hover
                ? highlightColor
                : backgroundColor
              : hover
              ? colors.grey_300
              : 'transparent',
          color:
            type === 'solid'
              ? contentColor
              : hover
              ? highlightColor
              : backgroundColor,
          border:
            type !== 'flat'
              ? `2px solid ${hover ? highlightColor : backgroundColor}`
              : '2px solid transparent',
          borderRadius: hero ? 25 : 20,
          opacity: disabled ? 0.33 : 1,
          height: hero ? 46 : 36,
          ...styles.root,
          ...style,
        }}
      >
        {children}
      </Intro>
    );
  }
}
