import { _t } from '@mindoktor/patient-legacy/i18n';
import React, { Component } from 'react';

import { formatRoute, routes } from '../../../../state/routing';
import Button from '../../../components/button/button';
import MessageCard from '../components/message_card';

// type Props = {
//   caseId: number,
//   caregiverId: Number,
//   doctorName: string,
//   doctorImageUrl: string,
// };

const ScheduleAppointmentButton = (caseId, caregiverId) => (
  <Button
    color="black"
    href={`#${formatRoute(routes.CASE_SCHEDULER, {
      id: caseId,
      caregiverId,
    })}`}
  >
    {_t('scheduler.chooseTime')}
  </Button>
);

class VideocallInvitation extends Component {
  render() {
    const { caseId, caregiverId, doctorImageUrl, doctorName, style } =
      this.props;
    return (
      <MessageCard
        imageUrl={doctorImageUrl}
        title={_t('videoCallItem.suggestedTime', { doctorName })}
        titleBold={false}
        actionComponent={ScheduleAppointmentButton(caseId, caregiverId)}
        style={style}
      />
    );
  }
}

export default VideocallInvitation;
