import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { _t } from '@mindoktor/patient-legacy/i18n';

import { MdMenu } from '../components/docly-md-icons';
import { isLoggedIn } from '../../state/api/selectors';
import { hasFeatureFlag } from '../../state/featureflags/selectors';
import { profileIdentityProtection } from '../../state/profile/selectors';
import { BaseStateType } from '../../state/types';

import {
  formatRoute,
  openRoute,
  routes as legacyRoutes,
} from '../../state/routing';
import { routes } from '@mindoktor/patient-app/routing/constants/webRoutes';

import { RESPONSIVE_SCREEN_WIDTH_BREAKPOINT } from '@mindoktor/patient-app/utils/device/web';

import colors from '../../common/colors';

import MediaQuery from '../components/media_query';
import Logo from '../components/logo';

import Item from './item';
import DesktopDropdownMenu from './desktop_dropdown_menu';
import {
  getSiteSupportUrl,
  getSiteLandingUrl,
  getSiteHowItWorksUrl,
} from '../utils/site';

import fetchHelpCenterSupportUrl from '../../api/helpCenter/fetchHelpCenterSupportUrl';
import { apiHeadersSelector } from '../../api/api';

// Workaround for typescript location issue: https://github.com/microsoft/TypeScript/issues/48949#issuecomment-1117142997
const win = window as Window;

const styles = {
  bar: {
    backgroundColor: colors.off_black,
    boxShadow: '0 0 14px -2px rgba(0,0,0,.5)',
  },

  inner: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 60,
    margin: '0 auto',
    maxWidth: 1200,
  } as React.CSSProperties,

  innerLarge: {
    height: 80,
  },

  logo: {
    paddingLeft: 10,
    paddingRight: 10,
  },

  logoLarge: {
    paddingLeft: 20,
    paddingRight: 20,
  },

  items: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  } as React.CSSProperties,

  item: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 10,
    paddingRight: 10,
    color: colors.white,
  },

  itemHighlight: {
    color: colors.red_v500,
  },

  itemLarge: {
    marginLeft: 10,
    marginRight: 10,
  },

  itemButton: {
    backgroundColor: colors.red_500,
    borderRadius: 20,
    paddingLeft: 20,
    paddingRight: 20,
  },

  itemButtonHighlight: {
    backgroundColor: colors.red_400,
  },

  menuIcon: {
    fontSize: 30,
    marginLeft: 6,
  },

  userMenu: {
    marginLeft: 20,
    marginRight: 20,
  },
} as const;

const connector = connect(
  (state: BaseStateType) => ({
    loggedIn: isLoggedIn(state),
    hasIdentityProtection: profileIdentityProtection(state),
    isHelpCenterEnabled: hasFeatureFlag(state, 'zendesk-help-center'),
    apiHeaders: apiHeadersSelector(state),
  }),
  { openRoute }
);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  style?: React.CSSProperties;
  showActions?: boolean;
}

class MenuBar extends Component<Props> {
  openSupportLink = async () => {
    const { isHelpCenterEnabled, loggedIn, apiHeaders } = this.props;

    if (isHelpCenterEnabled && loggedIn) {
      const [helpCenterLink, _err] = await fetchHelpCenterSupportUrl(
        apiHeaders,
        'new_request'
      );
      // Send to the old contact link if we can't get the HelpCenter link
      window.open(helpCenterLink || getSiteSupportUrl(), '_self');
      return;
    }
    window.open(getSiteSupportUrl());
  };

  showHiddenIdentityPage = () => {
    this.props?.openRoute(legacyRoutes.IDENTITY_PROTECTION_SUPPORT);
  };

  handleSupportCenterClick = async () => {
    if (this.props?.hasIdentityProtection) {
      this.showHiddenIdentityPage();
      return;
    }
    await this.openSupportLink();
  };

  render() {
    const { loggedIn, style, showActions = true } = this.props;

    const SmallScreenBar = () => (
      <div style={styles.inner}>
        <MediaQuery maxWidth={349}>
          <Logo
            hasText={false}
            height={30}
            style={{ marginLeft: 16 }}
            onClick={() => (win.location = getSiteLandingUrl())}
          />
        </MediaQuery>
        <MediaQuery minWidth={350}>
          <Logo
            type="white"
            height={30}
            style={styles.logo}
            onClick={() => (win.location = getSiteLandingUrl())}
          />
        </MediaQuery>

        {showActions && (
          <div style={styles.items}>
            <Item
              style={{ ...styles.item, ...styles.itemButton }}
              highlightStyle={styles.itemButtonHighlight}
              href={`#${formatRoute(routes.GUIDES, {
                entrywayId: 'adult',
              })}`}
            >
              {_t('menu.newCase')}
            </Item>

            <Item
              style={styles.item}
              highlightStyle={styles.itemHighlight}
              href={`#${formatRoute(legacyRoutes.MENU)}`}
            >
              <MdMenu style={styles.menuIcon} />
            </Item>
          </div>
        )}
      </div>
    );

    const LargeScreenBar = () => (
      <div
        style={{
          ...styles.inner,
          ...styles.innerLarge,
        }}
      >
        <Logo
          type="white"
          height={36}
          style={{
            ...styles.logo,
            ...styles.logoLarge,
          }}
          onClick={() => (win.location = getSiteLandingUrl())}
        />

        {showActions && (
          <div style={styles.items}>
            <Item
              style={{
                ...styles.item,
                ...styles.itemLarge,
                ...styles.itemButton,
              }}
              highlightStyle={styles.itemButtonHighlight}
              href={`#${formatRoute(routes.GUIDES, {
                entrywayId: 'adult',
              })}`}
            >
              {_t('menu.newCase')}
            </Item>

            {!loggedIn && (
              <Item
                style={{
                  ...styles.item,
                  ...styles.itemLarge,
                }}
                highlightStyle={styles.itemHighlight}
                href={getSiteHowItWorksUrl()}
              >
                {_t('menu.howItWorks')}
              </Item>
            )}

            <Item
              style={{
                ...styles.item,
                ...styles.itemLarge,
              }}
              highlightStyle={styles.itemHighlight}
              onClick={this.handleSupportCenterClick}
            >
              {_t('menu.support')}
            </Item>

            {loggedIn ? (
              <Item
                style={{
                  ...styles.item,
                  ...styles.itemLarge,
                }}
                highlightStyle={styles.itemHighlight}
                href={`#${formatRoute(routes.CASES)}`}
              >
                {_t('menu.myCases')}
              </Item>
            ) : (
              <Item
                style={{
                  ...styles.item,
                  ...styles.itemLarge,
                }}
                highlightStyle={styles.itemHighlight}
                href={`#${formatRoute(routes.LOGIN, {
                  state: formatRoute(routes.CASES),
                })}`}
              >
                {_t('common.login')}
              </Item>
            )}

            {loggedIn && <DesktopDropdownMenu style={styles.userMenu} />}
          </div>
        )}
      </div>
    );

    return (
      <div style={{ ...styles.bar, ...style }}>
        <MediaQuery maxWidth={RESPONSIVE_SCREEN_WIDTH_BREAKPOINT}>
          <SmallScreenBar />
        </MediaQuery>
        <MediaQuery minWidth={RESPONSIVE_SCREEN_WIDTH_BREAKPOINT + 1}>
          <LargeScreenBar />
        </MediaQuery>
      </div>
    );
  }
}

export default connector(MenuBar);
