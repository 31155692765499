import type { GrowthAnalyticsApi } from './models/growthAnalytics';

const GROWTH_ANALYTICS_SCRIPT_URL =
  'https://static.mindoktor.se/growth-analytics/dist/growth-analytics.js';

/** Name of the object attached to the window */
export const growthAnalyticsName = 'growthAnalytics' as const;

type GrowthAnalyticsWindow = Window & {
  [growthAnalyticsName]: GrowthAnalyticsApi;
};

export const isGrowthAnalyticsWindow = (
  window: Window
): window is GrowthAnalyticsWindow => {
  return Object.hasOwn(window, growthAnalyticsName);
};

const onInitialize = (isEnabled: boolean, onLoad?: () => void) => {
  if (isGrowthAnalyticsWindow(window)) {
    window[growthAnalyticsName].init?.(isEnabled);
    onLoad?.();
  }
};

/** Loads Growth Analytics on demand, in compliance with EU law. */
export const loadGrowthAnalyticsScript = (
  isEnabled: boolean,
  onLoad?: (event: Event) => void
) => {
  if (isGrowthAnalyticsWindow(window)) {
    console.warn(
      `Attempted loading window.${growthAnalyticsName} more than once.`
    );
    return;
  }

  // Create a new script tag to hold growth-analytics
  const tagName = 'script';
  const growthAnalyticsScriptElement = document.createElement(tagName);
  growthAnalyticsScriptElement.src = GROWTH_ANALYTICS_SCRIPT_URL;

  // Possibly run a function when the script is loaded
  growthAnalyticsScriptElement.addEventListener(
    'load',
    (event) => {
      console.info(`Loaded ${growthAnalyticsName}, initializing...`);
      onInitialize(isEnabled, () => onLoad?.(event));
    },
    false
  );

  // Append the newly created script element to the current script
  const currentScriptTagElement = document.getElementsByTagName(tagName)[0];
  currentScriptTagElement.parentNode?.insertBefore(
    growthAnalyticsScriptElement,
    currentScriptTagElement
  );
};
