import { _t } from '@mindoktor/patient-legacy/i18n';
import dayjs from 'dayjs';
import get from 'lodash-es/get';
import { createSelector } from 'reselect';

import {
  getLatestTimestampFromSupportTicketList,
  isTicketClosed,
} from '../../api/helpCenter/utils';
import { hasFeatureFlag } from '../featureflags/selectors';
import {
  InvitationStatus,
  // type Invitation,
  InvitationType,
} from '../profile/types';
import { CaseStatuses, MessageType } from './constants';

import { getIndependentCareAgeFromHost } from '../api/selectors';
import { isIndependentCareSeeker } from './utils';
import { SKANDIA_COMPANY_ID } from '../api/constants';

/** @typedef {import('../types').CommonStateType} CommonStateType */
/** @typedef {import('./types').Case} Case */
/** @typedef {import('./types').CaseId} CaseId */
/** @typedef {import('./types').CasesState} CasesState */
/** @typedef {import('./types').Message} Message */
/** @typedef {import('./types').ResumeCaseInfo} ResumeCaseInfo */
/** @typedef {import('../profile/types_definitions').Invitation} Invitation */

export const isCaseAnswersLoading = (/** @type {CommonStateType} */ state) => {
  return !!state.cases.answers.isLoading;
};
export const getCaseAnswers = (/** @type {CommonStateType} */ state) => {
  if (state.cases.answers.caseAnswers) {
    return state.cases.answers.caseAnswers;
  }
  return {};
};

export const getIsLoading = (/** @type {CommonStateType} */ state) => {
  return !!state.cases.isLoading;
};
export const getCurrentCaseId = (/** @type {CommonStateType} */ state) =>
  get(state, 'cases.currentCase');

export const hasCases = (/** @type {CommonStateType} */ state) => {
  return !!state.cases.caseIds.length;
};

export const hasActiveCases = (/** @type {CommonStateType} */ state) => {
  const casesIds = state.cases.caseIds;
  const casesById = state.cases.casesById;

  const firstActiveCase =
    casesIds &&
    casesIds.find(
      (id) =>
        casesById[id].status === CaseStatuses.Pending ||
        casesById[id].status === CaseStatuses.InProgress
    );
  return !!firstActiveCase;
};

export const getCases = (/** @type {CommonStateType} */ state) => {
  if (!state.cases || !state.cases.caseIds || !state.cases.casesById) {
    return [];
  }

  return state.cases.caseIds.map((id) => state.cases.casesById[id]);
};

export function getCase(
  /** @type {CommonStateType} */ state,
  /** @type {CaseId} */ id
) {
  return state.cases.casesById[id];
}

export function getConversationId(
  /** @type {CommonStateType} */ state,
  /** @type {CaseId} */ caseId
) {
  return getCase(state, caseId)?.conversationId;
}

/**
 * Get the reference number of a file on a specific case.
 */
export function getCaseFileReferenceNumber(
  /** @type {CommonStateType} */ state,
  /** @type {CaseId} */ caseId,
  /** @type {number} */ fileId
) {
  const caseData = getCase(state, caseId);
  if (!caseData) return null;

  const caseFile = caseData.attachments.find(
    (caseFile) => caseFile.fileId === fileId
  );

  return caseFile?.fileReferenceNumber;
}

export const getCaseUnread = (/** @type {CommonStateType} */ state) =>
  state.cases.unread;

export const getCasesById = (/** @type {CommonStateType} */ { cases }) =>
  cases.casesById;

const casesByIdSelector = (/** @type {CommonStateType} */ { cases }) =>
  cases.casesById;
const openCasesSelector = (/** @type {CommonStateType} */ { cases }) =>
  cases.openCaseIds;
const closedCasesSelector = (/** @type {CommonStateType} */ { cases }) =>
  cases.closedCaseIds;
const getInvitationsByParentCaseId = (
  /** @type {CommonStateType} */ { profile }
) => profile.invitationsByParentCaseId;
const getInvitationsById = (/** @type {CommonStateType} */ { profile }) =>
  profile.invitationsById;
const getAllEntrywayIds = (/** @type {CommonStateType} */ { guides }) =>
  guides.guideEntrywayIds;

/**
 * Returns a one item list representing the closed support tickets, to conform with the closed cases.
 *
 * The object is as such:
 * type: always equal to 'support',
 * updated: the most recent update in the closed ticket list,
 * payload: the list of the closed tickets.
 *
 */
const getClosedSupportMessageCardList = (
  /** @type {CommonStateType} */ state
) => {
  const supportTickets =
    (hasFeatureFlag(state, 'zendesk-help-center') &&
      state?.helpCenter?.tickets) ||
    [];
  const openSupportTickets = supportTickets.filter(
    (t) => !isTicketClosed(t.status)
  );

  if (openSupportTickets.length == 0 && supportTickets.length > 0) {
    return [
      {
        type: 'support',
        updated: getLatestTimestampFromSupportTicketList(supportTickets),
        payload: supportTickets,
      },
    ];
  }

  return [];
};

export const getOpenCases = createSelector(
  openCasesSelector,
  casesByIdSelector,
  (openCaseIds, casesById) =>
    openCaseIds
      .map((caseId) => {
        // if there's a parent case, append it to the caseData
        const revisitParentCaseId = casesById[caseId].revisitParentCaseId;

        if (revisitParentCaseId) {
          return {
            // For some reason Flow doesn't understand that this is a Case unless explicitly typed
            ...casesById[caseId],
            revisitParentCase: casesById[revisitParentCaseId],
          };
        }
        return casesById[caseId];
      })
      .reduce((openCases, caseData) => {
        if (openCases.some(({ id }) => id === caseData.id)) {
          return openCases;
        }

        return [...openCases, caseData];
      }, [])
      // Open cases are sorted by updated desc, so the case with the most recent activity is on top.
      .sort((a, b) => dayjs.utc(b.updated).diff(dayjs.utc(a.updated)))
);

export const getClosedCasesAndTickets = createSelector(
  closedCasesSelector,
  casesByIdSelector,
  getInvitationsByParentCaseId,
  getInvitationsById,
  getAllEntrywayIds,
  getClosedSupportMessageCardList,
  getIndependentCareAgeFromHost,
  (
    closedCaseIds,
    casesById,
    invitationsByParentCaseId,
    invitationsById,
    validEntrywayIds,
    closedSupportTickets,
    independentCareAge
  ) => {
    const closedCasesList = closedCaseIds.map((caseId) => {
      const resumeCaseInfo = getResumeCaseInfo(
        invitationsByParentCaseId,
        invitationsById,
        casesById[caseId],
        validEntrywayIds,
        independentCareAge
      );
      return {
        ...casesById[caseId],
        resumeCaseInfo,
      };
    });
    // Join cases and support tickets in a single list and sort them. For closed cases we sort by
    // "submitted" so that they do not shift around in the list if there is activity in them.
    return [...closedCasesList, ...closedSupportTickets].sort((a, b) => {
      {
        // A case will have a "submitted" timestamp, but a support ticket will not.
        const ts1 = a.submitted?.valid ? a.submitted.time : a.updated;
        const ts2 = b.submitted?.valid ? b.submitted.time : b.updated;

        return dayjs(ts2).utc().diff(dayjs(ts1).utc());
      }
    });
  }
);

const getFilteredClosedCases = (
  /** @type {CommonStateType} */ state,
  props
) => {
  return getClosedCasesAndTickets(state).filter((caseItem) =>
    props.consultationIds.some(
      (consultationId) => consultationId === caseItem.id
    )
  );
};

export const filterClosedCaseListItems = createSelector(
  getFilteredClosedCases,
  (filteredCases) => {
    return filteredCases;
  }
);

// type CasesByYear = { year, cases: Case[] };

export const getClosedCasesAndTicketsByYear = (
  /** @type {CommonStateType} */ state
) => {
  const closedCasesAndTickets = getClosedCasesAndTickets(state);
  // allYears ensures sort order is persisted from closedCases.
  const allYears = [];
  const caseGroup = {};

  closedCasesAndTickets.forEach((caseOrTicket) => {
    // A case will have a "submitted" timestamp, but a support ticket will not.
    const ts = caseOrTicket.submitted?.valid
      ? caseOrTicket.submitted.time
      : caseOrTicket.updated;

    const year = dayjs(ts).year();

    if (!caseGroup[year]) {
      // happy new year!
      allYears.push(year);
      caseGroup[year] = [];
    }

    caseGroup[year].push(caseOrTicket);
  });

  return allYears.map((year) => ({ year: year, cases: caseGroup[year] }));
};

/**
 * Returns information on how to resume a case, or null if the case can't be resumed.
 * A case can't be resumed if the patient is a child that has reached the independent
 * care age, or if there is a (revisit) case later on in the revisit chain - in which
 * scenario that case should be used to resume the case chain.
 * Skandia cases are also excluded from resume feature altogether.
 *
 * NOTE: The resume functionality is hidden behind the feature flag
 * "resume-closed-cases".
 *
 * @param {Record<number, number[]>} invitationsByParentCaseId - a map of parent caseIds and their invitations
 * @param {Record<number, Invitation>} invitationsById - a list of all outstanding invitations
 * @param {Case} case_ - the case that should potentially be resumed
 * @param {number[]} validEntrywayIds - a list of entryways for which it is possible to resume a case
 * @param {number} independentCareAge - the age from which it is possible to seek care independently
 * @returns {{entrywayId: number, guideType: string, patientUUID: string, revisitId?: number } | null}
 */
export const getResumeCaseInfo = (
  invitationsByParentCaseId,
  invitationsById,
  case_,
  validEntrywayIds,
  independentCareAge
) => {
  if (
    !case_.isPatientSameAsUser &&
    isIndependentCareSeeker(case_.patientBirthDate, independentCareAge)
  ) {
    return null;
  }

  if (case_.companyID === SKANDIA_COMPANY_ID) {
    return null;
  }

  const invitations = getInvitations(
    invitationsByParentCaseId,
    invitationsById,
    case_
  );
  // the case has no invitations, return regular guide entryway id
  if (!invitations) {
    return getFallbackResumeCaseInfo(case_, validEntrywayIds);
  }
  // If there is at least one consumed invitation within the case chain, not
  // originating from an internal referral, we do not show the "resume case" option.
  const consumedInvitations = invitations.filter(
    (invitation) =>
      invitation.typeName !== InvitationType.internalReferral &&
      invitation.status === InvitationStatus.RevisitStatusConsumed
  );
  if (consumedInvitations && consumedInvitations.length > 0) {
    return null;
  }

  // check if there are any open caregiver or patient invitations. Caregiver-initiated invitations
  // always come before patient-initiated invitations. Should there be more than one we use the one
  // that opens first.
  const activeInvitation = invitations
    .filter(
      (invitation) =>
        invitation.typeName !== InvitationType.internalReferral &&
        invitation.isOpen
    )
    .sort((invitation, otherInvitation) => {
      // When the types are different, a caregiverRevisit always takes priority.
      if (invitation.typeName !== otherInvitation.typeName) {
        if (invitation.typeName === InvitationType.caregiverRevisit) {
          return -1;
        }
        if (otherInvitation.typeName === InvitationType.caregiverRevisit) {
          return 1;
        }
      }

      // For the same type, sort by "opens".
      return (
        new Date(invitation.opens).getTime() -
        new Date(otherInvitation.opens).getTime()
      );
    })[0];

  if (activeInvitation) {
    return {
      entrywayId: activeInvitation.guideParams.entrywayId,
      guideType: activeInvitation.guideParams.guideType,
      patientUUID: activeInvitation.patientUUID,
      revisitId: activeInvitation.id,
    };
  } else if (case_.firstVisitCaseId) {
    // if this case is an invitation case and there is no invitation, return null since we don't have an entrywayId that makes sense for these cases to fallback on.
    return null;
  }
  return getFallbackResumeCaseInfo(case_, validEntrywayIds);
};

/**
 * Get a ResumeCaseInfo that can be use to resume cases that don't have any invitations, or null
 * if we don't have an entryway to resume with.
 *
 * @param {Case} caseData - The case that should be resumed.
 * @param {number[]} validEntrywayIds
 */
function getFallbackResumeCaseInfo(caseData, validEntrywayIds) {
  const resumeCaseInfo = {
    entrywayId: caseData.revisitParentEntrywayId
      ? caseData.revisitParentEntrywayId
      : caseData.entrywayId,

    guideType: caseData.isPatientSameAsUser ? 'adult' : 'children',
    patientUUID: caseData.patientUuid,
  };
  return validEntrywayIds.includes(resumeCaseInfo.entrywayId)
    ? resumeCaseInfo
    : null;
}

/**
 * Get all invitations placed on the supplied case, or null if there are none.
 */
function getInvitations(
  invitationsByParentCaseId,
  invitationsById,
  { revisitParentCaseId, id }
) {
  // get all invitation ids in the revisit chain
  const allInvitationIds = invitationsByParentCaseId[revisitParentCaseId || id];

  // get all invitation ids for this specific case
  const invitationIds =
    allInvitationIds &&
    allInvitationIds.filter(
      (invitationsId) => invitationsById[invitationsId].sourceCaseId === id
    );
  if (invitationIds && invitationIds.length > 0) {
    return invitationIds.map((id) => invitationsById[id]);
  }
  return null;
}

export function getCaseData(
  /** @type {CommonStateType} */ state,
  /** @type {CaseId} */ caseId
) {
  const caseData = state.cases.casesById[caseId];

  return caseData;
}

export function getCaseMessages(
  /** @type {CommonStateType} */ state,
  /** @type {CaseId} */ caseId
) {
  const { casesById, messagesById } = state.cases;
  const caseData = casesById[caseId];
  if (caseData && caseData.messages) {
    return caseData.messages.map((id) => messagesById[id]);
  }

  return [];
}

export function getMessageById(
  /** @type {CommonStateType} */ state,
  /** @type {number} */ id
) {
  return state.cases.messagesById[id];
}

export const getVideoPendingMessage = (
  /** @type {CommonStateType} */ state,
  /** @type {CaseId} */ caseId
) =>
  getCaseMessages(state, caseId).find(
    (message) =>
      message && message.type === MessageType.VIDEOCALL && message.isPending
  );

export function hasDoctorMessages(
  /** @type {CommonStateType} */ state,
  /** @type {CaseId} */ caseId
) {
  return !!getCaseMessages(state, caseId).find(
    (msg) => msg && msg.doctorID > 0
  );
}

export function hasNoDoctorAssigned(
  /** @type {CommonStateType} */ state,
  /** @type {CaseId} */ caseId
) {
  return (
    (state.cases.casesById[caseId] &&
      state.cases.casesById[caseId].status === CaseStatuses.Pending) ||
    !hasDoctorMessages(state, caseId)
  );
}

export function getIsInvitationCase(
  /** @type {CommonStateType} */ state,
  /** @type {CaseId} */ caseId
) {
  const data = getCaseData(state, caseId);
  return (
    !!data &&
    data.revisitParentCaseId !== null &&
    data.revisitParentCaseId !== undefined
  );
}

// Should really be in profile/selectors but if added there I run into this issue
// https://github.com/reactjs/reselect/issues/169
export const getScheduledInvitations = (
  /** @type {CommonStateType} */ state
) => {
  const invitations = get(state, 'profile.invitationsById');
  // $FlowFixMe
  return Object.keys(invitations)
    .filter((key) => {
      const item = invitations[key];
      return (
        item.typeName === InvitationType.internalReferral &&
        (item.status === InvitationStatus.RevisitStatusConsumed ||
          item.status === InvitationStatus.RevisitStatusExpired ||
          item.status === InvitationStatus.RevisitStatusActive)
      );
    })
    .map((id) => invitations[id]);
};

// Get all invitations for a case message
export function getCaseMessageInvitationsData(
  /** @type {CommonStateType} */ state,
  /** @type {CaseId} */ caseId
) {
  const invitations = getScheduledInvitations(state);
  return invitations.filter((item) => item.sourceCaseId === caseId);
}

const getInvitationIds = (
  invitationsByParentCaseId,
  { id, revisitParentCaseId }
) => {
  return invitationsByParentCaseId[revisitParentCaseId || id];
};

const isCaseOpen = (_case) =>
  ![
    CaseStatuses.Finished,
    CaseStatuses.CanceledByPatient,
    CaseStatuses.ClosedByDoctor,
  ].includes(_case.status);

export const getResumeCaseItem = (
  /** @type {CommonStateType} */ state,
  caseId
) => {
  const _case = getCase(state, caseId);
  if (!_case || isCaseOpen(_case)) return null;
  const independentCareAge = getIndependentCareAgeFromHost(state);
  const resumeCaseInfo = getResumeCaseInfo(
    state.profile.invitationsByParentCaseId,
    state.profile.invitationsById,
    _case,
    state.guides.guideEntrywayIds,
    independentCareAge
  );

  // fallback to the normal closed case item
  if (!resumeCaseInfo) {
    return getClosedCaseItem(state, caseId);
  }

  return {
    cId: 'closed-case',
    type: MessageType.RESUME_CLOSED_CASE,
    resumeCaseInfo: resumeCaseInfo,
    timestamp: dayjs(_case.updated).valueOf(),
  };
};

export const getClosedCaseItem = (
  /** @type {CommonStateType} */ state,
  caseId
) => {
  const _case = getCase(state, caseId);
  if (!_case || isCaseOpen(_case)) return null;
  if (!_case) return null;

  let invitation, invitationId, body;

  const invitationIds = getInvitationIds(
    state.profile.invitationsByParentCaseId,
    _case
  );

  // a case can have multiple invitation, but we're only interested in the first doctor-initiated non-canceled one
  if (invitationIds && invitationIds.length > 0) {
    invitationId = invitationIds
      .filter((invitationsId) => {
        const inv = state.profile.invitationsById[invitationsId];
        return (
          (inv.typeName === InvitationType.caregiverRevisit ||
            inv.typeName === InvitationType.internalReferral) &&
          inv.status === InvitationStatus.RevisitStatusActive
        );
      })
      .sort(
        (a, b) =>
          new Date(state.profile.invitationsById[a].opens).getTime() -
          new Date(state.profile.invitationsById[b].opens).getTime()
      )[0];
  }

  if (invitationId) {
    invitation = state.profile.invitationsById[invitationId];

    const options = {
      opens: dayjs(invitation.opens),
      closes: dayjs(invitation.closes),
    };
    body = invitation.isOpen
      ? _t('closedCase.body.openInvitation', options)
      : _t('closedCase.body.closedInvitation', options);

    if (!invitation.caseOwnerCanRepresentPatient) {
      const isInternalReferral =
        invitation.typeName === InvitationType.internalReferral;

      body += '\n\n';
      body += isInternalReferral
        ? _t(
            'guides.invitationCard.internalReferral.caseOwnerCanNotRepresentPatient'
          )
        : _t('guides.revisitCard.caseOwnerCanNotRepresentPatient');
    }
  } else {
    body = _t('closedCase.body.noInvitation');
  }

  return {
    cId: 'closed-case',
    type: MessageType.CLOSED_CASE,
    body,
    openRevisitInvitation: invitation,
    timestamp: dayjs(_case.updated).valueOf(),
  };
};
