export const TOAST_NOTIFICATION_DISMISS = 'TOAST_NOTIFICATION_DISMISS';
export const TOAST_NOTIFICATION_SHOW = 'TOAST_NOTIFICATION_SHOW';

export type LegacyNotificationType = 'info' | 'error' | 'success' | 'coach';

export interface LegacyNotification {
  id: number;
  type: LegacyNotificationType;
  /**
   * Actually a message code. E.g. `'errorMessage.maintenance'`.
   * Check available code in `packages/legacy/src/native/notifications/Notification.js`.
   */
  message: string;
  timeout?: number;
  shouldTimeout?: boolean;
  pings?: number;

  onRemove?: (id: number) => void;
  onClose?: () => void;
}

export const REGISTER_COACH_NOTIFICATIONS = 'REGISTER_COACH_NOTIFICATIONS';
export const UNREGISTER_COACH_NOTIFICATIONS = 'UNREGISTER_COACH_NOTIFICATIONS';
export const SET_COACH_NOTIFICATION_POSITION =
  'SET_COACH_NOTIFICATION_POSITION';
export const HIDE_COACH_NOTIFICATION = 'HIDE_COACH_NOTIFICATION';
export const SHOW_COACH_NOTIFICATION = 'SHOW_COACH_NOTIFICATION';
export const BROWSER_VIDEO_WARNING = 'BROWSER_VIDEO_WARNING';
export const BROWSER_VIDEO_WARNING_DISMISSED =
  'BROWSER_VIDEO_WARNING_DISMISSED';

// Todo: rename CoachNotificationItem
// export type CoachNotificationData = {
//   message: string,
//   top?: number,
//   left?: number,
//   name: string,
//   title?: string,
//   visible?: boolean,
// };

// export type CoachNotificationItems = Array<CoachNotificationData>;

// export type NotificationsState = {
//   +items: Notification[],
//   +counter: number,
//   +coachItems: {
//     +[contextName: string]: {
//       +[itemName: string]: CoachNotificationData,
//     },
//   },
//   videoWarningDismissed: boolean,
// };
