import { getIn } from 'timm';

import { CaseStatuses } from '../cases/constants';
import { getCaseData } from '../cases/selectors';
import { getQuestionnaireData } from './selectors';

const isCaseOpen = (state, caseId) => {
  const caseData = getCaseData(state, Number(caseId));
  return !!caseData && caseData.status < CaseStatuses.Finished;
};

const isFinished = (state, caseId, surveyName) =>
  getIn(getQuestionnaireData(state, caseId, surveyName), ['isFinished']);

const checker = ({ state, caseId, surveyName }) =>
  isCaseOpen(state, caseId) || isFinished(state, caseId, surveyName);

export default checker;
