import { _t } from '@mindoktor/patient-legacy/i18n';
import React, { Component, useEffect } from 'react';

import { Title1 } from '../../../common/components/typography';
import spacing from '../../../common/spacing';
import { isLoggedIn } from '../../../state/api/selectors';
import { isChildGuide } from '../../../state/guides/guidesUtils';
import {
  getGuideByEntrywayId,
  getGuidePrice,
} from '../../../state/guides/selectors';
import {
  formatRoute,
  openRoute,
  routes as legacyRoutes,
} from '../../../state/routing';
import { track } from '../../../state/tracking/actions';
import Button from '../../components/button/button';
import TruncatedFooter from '../../components/footer/truncated';
import Markdown from '../../components/markdown';
import MediaQuery from '../../components/media_query';
import Screen from '../../components/screen';
import MenuBar from '../../menu/bar';
import { navigateToLogin, showModal } from '../../routing';
import doclyTracking from '../../tracking/docly';
import { getGuideImageByEntrywayId } from '../utils/images';
import { replaceLinks } from '../utils/links';
import ExplanationModal from './explanation_modal';

import { getInvitations } from '../../../state/profile/actions';
import { useAppDispatch, useAppSelector } from '../../../state/hooks';
import { useParams } from '@mindoktor/patient-app/routing/hooks/useParams/useParams';
import { useNavigation } from '@mindoktor/patient-app/routing/hooks/useNavigation/web';
import { routes } from '@mindoktor/patient-app/routing/constants/webRoutes';
import { useTrackPageView } from '@mindoktor/patient-app/tracking/hooks/useTrackPageView';
import { useQueryParams } from '@mindoktor/patient-app/routing/hooks/useQueryParams/web';
import { useMarketingTracking } from '@mindoktor/patient-app/tracking/hooks/useMarketingTracking';
import { MarketingTrackingActions } from '@mindoktor/patient-app/tracking/api/models/marketingTracking';

const styles = {
  intro: {
    width: 'calc(100% - 40px)',
    maxWidth: 760,
    margin: '0 auto',
    padding: 20,
  },

  icon: {
    display: 'block',
    width: 96,
    height: 96,
    marginBottom: 20,
    marginRight: 20,
  },

  title: {
    maxWidth: 800,
    fontSize: 32,
    lineHeight: '38px',
    marginBottom: 20,
  },

  body: {
    maxWidth: 600,
  },

  footer: {
    maxWidth: 800,
    margin: '0px auto',
  },

  buttons: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    maxWidth: 620,
    marginLeft: 10,
    marginRight: 10,
  },

  buttonsWide: {
    flexWrap: 'nowrap',
    marginLeft: 'auto',
  },

  button: {
    width: 'calc(100% - 20px)',
    marginBottom: spacing.t,
    marginLeft: 10,
    marginRight: 10,
  },
};

// type Props = {
//   entrywayId: number,
//   revisitId?: number,

//   iconSource: any,
//   title?: string,
//   body?: string,
//   treater?: AllowedTreater,
//   price?: number,
//   currency: Currency,
//   maxAge?: number,
//   guide: Guide,
//   forChildren?: boolean,
//   dynamicCode?: string,

//   loggedIn: boolean,

//   openRoute: typeof openRoute,
// };

export class IntroScreen extends Component {
  componentDidMount() {
    const { getInvitations } = this.props;
    getInvitations();
  }
  showExplanationModal = () => {
    const { entrywayId } = this.props.guide;
    const { treater, price, currency, maxAge, track } = this.props;

    track('ContextualHelp.Pressed', { entrywayId, context: 'howItWorks' });

    showModal(ExplanationModal, {
      treater,
      price,
      currency,
      maxAge,
    });
  };

  openGuides = () => {
    const {
      forChildren,
      navigation: { navigate },
    } = this.props;

    navigate(routes.GUIDES, { entrywayId: forChildren ? 'child' : 'adult' });
  };

  openNext = () => {
    const {
      entrywayId,
      revisitId,
      forChildren,
      loggedIn,
      track,
      childUuid,
      dynamicCode,
      preferredCaregiverId,
      navigation: { navigate },
      category,
      openRoute,
    } = this.props;

    let path = routes.GUIDES_PRESTART;
    let props = {
      entrywayId,
      category,
    };

    const params = {
      revisitId,
      childUuid,
      dynamicCode,
      preferredCaregiverId,
    };

    if (forChildren) {
      path = legacyRoutes.CHILDREN;
      // Fallback to legacy navigation.
      // Route not migrated to react-router yet.
      openRoute(path, { ...props, ...params });
      return;
    }

    if (loggedIn) {
      navigate(path, props, params);
    } else {
      track('LoginToGetStarted.Pressed', { entrywayId });

      navigateToLogin(formatRoute(path, props));
    }

    doclyTracking.sendGenericEvent(
      'User Interaction',
      'Guide.Pressed',
      forChildren ? 'child' : 'adult'
    );
  };

  render() {
    const { title, body, loggedIn, stopAtIntroA } = this.props;

    const bodyWithLinks = replaceLinks(body);

    const buttons = stopAtIntroA
      ? []
      : [
          <Button
            key="howItWorks"
            type="ghost"
            color="black"
            hero={true}
            onClick={this.showExplanationModal}
            style={{ ...styles.button, marginBottom: spacing.t }}
          >
            {_t('guides.intro.howItWorks')}
          </Button>,

          <Button
            key="continue"
            hero={true}
            onClick={this.openNext}
            style={{ ...styles.button, marginBottom: 20 }}
          >
            {loggedIn ? _t('guides.continue') : _t('guides.intro.login')}
          </Button>,
        ];

    return (
      <Screen
        header={<MenuBar />}
        body={
          <div style={styles.intro}>
            <Title1 style={styles.title}>{title}</Title1>

            <Markdown style={styles.body} source={bodyWithLinks} />
          </div>
        }
        footer={
          <div>
            <div style={styles.footer}>
              <MediaQuery maxWidth={600}>
                <div style={styles.buttons}>{buttons}</div>
              </MediaQuery>

              <MediaQuery minWidth={601}>
                <div style={{ ...styles.buttons, ...styles.buttonsWide }}>
                  {buttons}
                </div>
              </MediaQuery>
            </div>

            <MediaQuery minWidth={801}>
              <TruncatedFooter />
            </MediaQuery>
          </div>
        }
        showScrollGradient={true}
      />
    );
  }
}

const IntroScreenWrapper = () => {
  const { entrywayId } = useParams();
  const navigation = useNavigation();
  const dispatch = useAppDispatch();
  const queryParams = useQueryParams();
  const revisitId = Number(queryParams.get('revisitId'));
  const childUuid = queryParams.get('childUuid');
  const dynamicCode = queryParams.get('dynamicCode');
  const preferredCaregiverId = queryParams.get('preferredCaregiverId');
  const marketingTracking = useMarketingTracking();

  useTrackPageView({ entrywayId: entrywayId.toString() });

  const props = {
    ...useAppSelector((state) => {
      const guide = getGuideByEntrywayId(state, entrywayId);

      const {
        displayTitle: title,
        introTextShort: body,
        treater,
        maxAge,
        currency,
        stopAtIntroA,
      } = guide || {};

      const price = getGuidePrice(state, entrywayId);

      return {
        iconSource: getGuideImageByEntrywayId(entrywayId),
        title,
        body,
        treater,
        price,
        maxAge,
        currency,
        stopAtIntroA,
        guide,
        forChildren: !!guide && isChildGuide(guide),
        loggedIn: isLoggedIn(state),
      };
    }),
    navigation,
    entrywayId,
    revisitId,
    childUuid,
    dynamicCode,
    preferredCaregiverId,
    getInvitations: () => dispatch(getInvitations()),
    track: (...args) => dispatch(track(...args)),
    openRoute: (...args) => dispatch(openRoute(...args)),
  };

  useEffect(() => {
    marketingTracking.track(MarketingTrackingActions.GuideIntroAViewed, {
      label: props.forChildren ? 'child' : 'adult',
    });
  }, [entrywayId]);

  return <IntroScreen {...props} />;
};

export default IntroScreenWrapper;
