import React, { memo, RefObject, useEffect, useRef } from 'react';

import Box from '@mui/material/Box';

import { useIsVisible } from '../../../hooks/useIsVisible';
import { getScrollPositionFunc } from '../../../utils/scrollPositioning';
import { ScrollPositionOption } from '../constants';

interface Props {
  children?: React.ReactNode;
  scrollOffsetY: number;
  parentElementRef: RefObject<HTMLDivElement>;
  onVisibilityChange: (isVisible: boolean) => void;
  isScrollTarget: boolean;
  scrollPositionOption?: ScrollPositionOption | null;
  positionOffsetOnScroll: number;
}

const ListViewItem: React.FC<Props> = ({
  children,
  scrollOffsetY,
  parentElementRef,
  onVisibilityChange,
  isScrollTarget,
  scrollPositionOption,
  positionOffsetOnScroll,
}) => {
  const elementRef = useRef<HTMLDivElement | null>(null);
  const { isVisible, setScrollOffsetY } = useIsVisible<HTMLDivElement | null>(
    elementRef,
    parentElementRef
  );

  // Keeps track of the element's position to check if it's visible.
  useEffect(() => {
    setScrollOffsetY(scrollOffsetY);
  }, [scrollOffsetY]);

  // Keeps track of the element's visibility to trigger a visibility change.
  useEffect(() => {
    onVisibilityChange(isVisible);
  }, [isVisible]);

  // If it's the scroll target item, scroll this into view
  useEffect(() => {
    if (
      parentElementRef.current != null &&
      elementRef?.current != null &&
      !isVisible &&
      isScrollTarget
    ) {
      const elementRect = elementRef.current.getBoundingClientRect();
      const containerRect = parentElementRef.current.getBoundingClientRect();

      // scroll independent y position w.r.t. its container
      const relativeY = elementRect.y - containerRect.y + scrollOffsetY;
      const scrollTargetPositionY = getScrollPositionFunc(scrollPositionOption)(
        { x: 0, y: relativeY, width: 0, height: elementRect.height },
        { x: 0, y: containerRect.y, width: 0, height: containerRect.height },
        positionOffsetOnScroll
      );
      parentElementRef.current.scrollTo({
        top: scrollTargetPositionY,
      });
    }
  }, [isScrollTarget]);

  return <Box ref={elementRef}>{children}</Box>;
};

export type ListViewItemProps = Props;
export default memo(ListViewItem);
