import { _t } from '@mindoktor/patient-legacy/i18n';
import dayjs from 'dayjs';

import { WEB_URL } from '../../state/api';
import { LOGIN } from '../../state/api/actions';
import { APP_START, fetchSystemMessage } from '../../state/app/actions';
import { fetchCases } from '../../state/cases/actions';
import { loadDrafts } from '../../state/drafts/actions';
import { FORMULARY_ABORT } from '../../state/formulary/types';
import { fetchFreePass } from '../../state/freepass/actions';
import { fetchGuides } from '../../state/guides/actions';
import { fetchSupportTickets } from '../../state/helpCenter/actions';
import { SET_CURRENT_LOCALE } from '../../state/intl/actions';
import { getCurrentLocale } from '../../state/intl/selectors';
import { showNotification } from '../../state/notifications/actions';
import {
  fetchUserConnections,
  fetchUserInfo,
  fetchUserProfile,
  getInvitations,
  PROFILE_RECEIVED,
} from '../../state/profile/actions';
import { ROUTING_OPENED } from '../../state/routing';
import { isBrowserSupported } from '../utils/device';

export default function middleware({ dispatch, getState }) {
  const dispatchAll = (...actions) => actions.forEach((a) => dispatch(a));

  return (next) => (action) => {
    const { type, payload = {} } = action;
    const state = getState();

    const nextAction = next(action);
    switch (type) {
      case APP_START:
        dayjs.locale(getCurrentLocale(state, true));

        dispatch(fetchSystemMessage());

        dispatch(fetchGuides());

        if (!isBrowserSupported()) {
          dispatch(
            showNotification({
              type: 'error',
              message: _t('device.browserNotSupported', {
                hrefToBrowserSupportPage: `${WEB_URL}/browser-support`,
              }),
              shouldTimeout: false,
            })
          );
        }

        break;

      case ROUTING_OPENED:
      case PROFILE_RECEIVED:
        // TODO: Add logic for new app auth login?
        break;

      case LOGIN: {
        dispatchAll(
          fetchGuides(),
          fetchUserProfile(),
          fetchUserInfo(),
          fetchUserConnections(),
          fetchCases(),
          fetchSupportTickets(),
          fetchFreePass(),
          loadDrafts(),
          getInvitations()
        );

        break;
      }

      case SET_CURRENT_LOCALE: {
        const { locale } = payload;

        dayjs.locale(locale);

        dispatchAll(fetchGuides(), fetchSystemMessage());

        break;
      }

      case FORMULARY_ABORT: {
        dispatch(getInvitations());
        break;
      }
    }

    return nextAction;
  };
}
