import { Treater } from '../cases/constants';

const EntrywayIdsThatRequiresMidwife = {
  18: 'Anticonception',
  72: 'ContactTracing',
  103: 'Pregnancy',
  104: 'MidwifeRevisit',
  188: 'MidwifeAdvice',
};

const EntrywayIdsThatRequiresPhysiotherapist = {
  28: 'Fysioterapeut',
  66: 'Fysioterapeut',
  67: 'Fysioterapeut',
  69: 'Fysioterapeut',
  70: 'Fysioterapeut',
  71: 'Fysioterapeut',
};

const EntrywayIdsThatRequiresPsychologist = {
  85: 'InvitationDoctorToPsychologist',
  87: 'PsychologyRevisit',
  102: 'SleepPsychologist',
  133: 'PsychologyUnder18',
  134: 'PsychologyRevisitUnder18',
  136: 'Psychology',
};

/**
 * Get type of treater, "doctor", "generic", "midwife", "physiotherapist" or "psychologist" based on entrywayId
 *
 * @description There is currently no reliable way of telling if a case should be treated by
 * a doctor or a midwife. This function makes use of the entrywayId and the fact that (currently)
 * only a small set of guides are treated by midwives.
 *
 * @param {number} entrywayId of the current guide
 *
 * @returns {string} Type of treater. 'doctor' || 'generic' || 'midwife' || 'physiotherapist' || 'psychologist'
 */
export function getTreaterByEntrywayId(entrywayId) {
  if (EntrywayIdsThatRequiresMidwife[entrywayId]) {
    return Treater.midwife;
  }
  if (EntrywayIdsThatRequiresPhysiotherapist[entrywayId]) {
    return Treater.physiotherapist;
  }
  if (EntrywayIdsThatRequiresPsychologist[entrywayId]) {
    return Treater.psychologist;
  }
  return Treater.generic;
}

/**
 * Convenience function for switching text strings based on treater
 */
export function treaterSwitch(
  // typeof Treater sometimes gets interpreted as Object, not string
  treater,
  doctorTranslation,
  genericTranslation,
  midwifeTranslation,
  physiotherapistTranslation,
  psychologistTranslation
) {
  switch (treater) {
    case Treater.doctor:
      return doctorTranslation;
    case Treater.midwife:
      return midwifeTranslation;
    case Treater.physiotherapist:
      return physiotherapistTranslation;
    case Treater.psychologist:
      return psychologistTranslation;
    default:
      return genericTranslation;
  }
}

export function isChildGuide(guide) {
  return guide.minAge < 17 && guide.maxAge < 20;
}

export const isAnticonceptionGuide = (entrywayId) =>
  entrywayId === 18 || entrywayId === 49;
export const isRenewalGuide = (entrywayId) =>
  entrywayId === 48 || entrywayId === 7;
export const isContactTracingGuide = (entrywayId) => entrywayId === 72;

export const hasTechnicalError = (time, status) =>
  time.indexOf('0001-01-01') !== -1 && status === 1;

export const hasPayment = (paymentStatus, paymentMethod) =>
  !!paymentStatus &&
  paymentMethod !== 'free' &&
  paymentMethod !== 'frikort' &&
  paymentMethod !== 'insurance';
