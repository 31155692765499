import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import React from 'react';

import logoSource from '../images/logo/logo.svg';

class Logo extends React.Component {
  render() {
    const { classes, className } = this.props;

    return (
      <img
        alt="logo"
        src={logoSource}
        className={classNames(classes.root, className)}
      />
    );
  }
}

export default withStyles(() => ({
  root: {
    height: 30,
  },
}))(Logo);
