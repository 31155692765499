import config from '@mindoktor/env/Config';

import colors from '../../common/colors';
import { WEB_URL } from '../api';
import { MIN_DOKTOR_COMPANY_ID } from '../api/constants';

const synonyms = {
  md: ['app', 'mindoktor'],
  ea: ['testea', 'devea', 'euroaccident'],
  skandia: ['skandiasvc', 'testskandia', 'devskandia', 'ext-skandia'],
  lf: ['testlf', 'devlf', 'ext-lf'],
  wh: ['testwh', 'devwh', 'wetterhalsan'],
  th: ['testth', 'devth', 'trygghansa'],
  nova: ['novakliniken'],
};

// type SiteOptions = {
//   // Unique key for the site.
//   key: string,

//   // Primary color to use for the site.
//   color?: string,

//   // Company IDs, other than the default company ID for the site, for which cases should be shown.
//   whitelistedCaseCompanyIds?: number[],

//   // Whether to show the company attribution bar.
//   showCompanyAttribution?: boolean,
//   // Whether to shown the abbreviated simple footer instead of the bigger default one.
//   showSimpleFooter?: boolean,

//   // Whether to show a link to privacy settings.
//   showPrivacySettings?: boolean,

//   // Whether an external ID is needed before a user can go through a guide.
//   requireExternalId?: boolean,

// Whether to show a plug for Min Doktor guides on other sites.
// showGuidesPlug?: boolean,

//   // Whether to show different guarantee levels when browsing lab locations.
//   showLabGuaranteeLevel?: boolean,

//   companyId?: number,
// };

const optionsBySite = {
  // Min Doktor
  md: {
    companyId: MIN_DOKTOR_COMPANY_ID,
    whitelistedCaseCompanyIds: [
      6, // Min Doktor
      10, // Wetterhälsan
      11, // Region Halland
      12, // Min Doktor Trial
      16, // Momondo
      23, // Min Doktor Kliniken
    ],
    showPrivacySettings: true,
  },
  // Euroaccident
  ea: {
    color: colors.ea_color,
    showCompanyAttribution: true,
    showSimpleFooter: true,
    showGuidesPlug: true,
    showLabGuaranteeLevel: true,
  },
  // Skandia
  skandia: {
    color: colors.skandia_color,
    showCompanyAttribution: true,
    showSimpleFooter: true,
    requireExternalId: true,
  },
  // Länsförsäkringar
  lf: {
    color: colors.lf_color,
    showCompanyAttribution: true,
    showSimpleFooter: true,
    requireExternalId: true,
  },
  // Certico
  certico: {
    color: colors.certico_color,
    showCompanyAttribution: true,
    showSimpleFooter: true,
    requireExternalId: true,
  },
  // Wetterhälsan
  wh: {
    color: colors.wh_color,
    showCompanyAttribution: true,
    showSimpleFooter: true,
  },
  // Trygg Hansa
  th: {
    color: colors.th_color,
    showCompanyAttribution: true,
    showSimpleFooter: true,
    requireExternalId: true,
  },
  // Novakliniken
  nova: {
    showSimpleFooter: true,
  },
  'uk-willowbrook': {
    companyId: 20,
  },
};

export const sites = Object.keys(optionsBySite);

const subdomainBySite = {
  ea: 'euroaccident',
  wh: 'wetterhalsan',
  th: 'trygghansa',
  nova: 'novakliniken',
};

export const getSiteOptions = (site) => {
  const key =
    Object.keys(synonyms).find((key) => synonyms[key].includes(site)) ||
    site ||
    'md';

  const siteOptions = optionsBySite[key];

  return {
    ...siteOptions,
    key,
  };
};

const getSiteUrl = (site) => {
  const key =
    Object.keys(synonyms).find((key) => synonyms[key].includes(site)) ||
    site ||
    'md';

  if (['md', 'fr-beauvisage', 'uk-willowbrook', 'nl-eemland'].includes(key)) {
    return WEB_URL;
  }

  if (config.IsDev) {
    if (['fr', 'uk', 'nl'].includes(key)) {
      return `https://${key}.doclytest.io`;
    }

    return `https://${key}.se.doclytest.io`;
  }

  const subdomain = subdomainBySite[key] || key;

  // Hack-on-a-hack™: to fix erroneous links on partner webapps,
  // include potential 'www.' in the subdomain replacer:
  return WEB_URL.replace(/(:\/\/(www\.)?)/gi, `://${subdomain}.`);
};

export const getSiteLandingUrl = (site) => {
  return getSiteUrl(site);
};

export const getSiteHowItWorksUrl = (site) => {
  return getSiteUrl(site) + `/${config.HowItWorksPath || ''}?hideNavigation`;
};

export const getSiteSupportUrl = (site) => {
  return getSiteUrl(site) + '/support?hideNavigation';
};

export const getSiteCookiesUrl = (site) => {
  return getSiteUrl(site) + '/cookies?hideNavigation';
};

export const getSiteTermsUrl = (site) => {
  return getSiteUrl(site) + '/tos?hideNavigation';
};

export const getSitePrerequisitesUrl = (site) => {
  return getSiteUrl(site) + '/prereq';
};

export const getSiteMedicalComplaints = (site) => {
  return getSiteUrl(site) + `/${config.MedicalComplaintsPath || ''}`;
};

export const getSiteContactUsUrl = (site) => {
  return getSiteUrl(site) + `/${config.ContactUsPath || ''}?hideNavigation`;
};

export const getSiteCompanyId = (site) => {
  return getSiteOptions(site).companyId || MIN_DOKTOR_COMPANY_ID;
};
