import { _t } from '@mindoktor/patient-legacy/i18n';
import React, { Component } from 'react';

import arrowIcon from '../../../../images/generated/arrow_listitem@4x.png';
import colors from '../../../common/colors';
import { Body1, SubHead } from '../../../common/components/typography';
import spacing from '../../../common/spacing';
import { getAge } from '../../../state/profile/profileUtils';
import ChildIcon from '../../components/icon/child';
import Img from '@mindoktor/pulse/src/utils/images/Img.web';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    boxShadow:
      '0px 2px 12px rgba(0, 0, 0, 0.12), 0px 1px 4px rgba(0, 0, 0, 0.06)',
    borderRadius: 6,
    padding: `${spacing.xt}px ${spacing.t}px`,
  },

  enabled: {
    cursor: 'pointer',
  },

  middle: {
    flex: 1,
  },

  name: {
    whiteSpace: 'nowrap',
    overflowY: 'hidden',
  },

  text: {
    fontSize: 13,
    lineHeight: 20,
  },

  icon: {
    marginRight: spacing.t,
  },

  arrow: {
    height: 18,
    marginLeft: 10,
    opacity: 0.33,
  },

  badge: {
    borderRadius: 6,
    overflow: 'hidden',
    padding: `${spacing.xt}px ${spacing.t}px`,
    marginLeft: spacing.t,
    textAlign: 'center',
  },

  required: {
    background: colors.yellow_500,
  },

  get white() {
    return {
      ...this.text,
      color: colors.white,
    };
  },

  get black() {
    return {
      ...this.text,
      color: colors.black,
    };
  },
};

// type Props = {
//   firstName: string,
//   birthDate: string,
//   selected?: boolean,
//   onClick?: () => any,
//   style?: any,
// };

export default class Item extends Component {
  render() {
    const { firstName, lastName, birthDate, onClick, style } = this.props;
    const { months, years } = getAge(birthDate);

    return (
      <div
        onClick={() => {
          onClick();
        }}
        style={{ ...styles.root, ...style, ...styles.enabled }}
      >
        <ChildIcon size={50} style={styles.icon} />

        <div style={styles.middle}>
          <SubHead
            style={{ ...styles.name, ...styles.text }}
          >{`${firstName} ${lastName}`}</SubHead>

          <Body1 style={styles.text}>
            {years > 0
              ? _t('childItem.years', {
                  childYears: years,
                  childMonths: months,
                })
              : _t('childItem.months', { childMonths: months })}
          </Body1>
        </div>

        <Img src={arrowIcon} style={styles.arrow} />
      </div>
    );
  }
}
