import { _t } from '@mindoktor/patient-legacy/i18n';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Title1 } from '../../../common/components/typography';
import { getHealthProfileFormularyState } from '../../../state/healthprofile/selectors';
import { openRoute, routes } from '../../../state/routing';
import FormularyAnswers from '../../formulary/answers';

const styles = {
  title: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
  },
};

// type Props = {
//   childUuid?: string,

//   showTitle?: boolean,

//   formulary?: Formulary,
//   answers?: Object,
//   visibility?: { [string]: boolean },
//   errors?: { [string]: string },

//   openRoute: typeof openRoute,
// };

export class SummaryContent extends Component {
  static defaultProps = {
    showTitle: true,
  };

  edit = (index) => {
    const { childUuid, openRoute } = this.props;

    openRoute(routes.HEALTH_PROFILE_EDIT, { index, childUuid });
  };

  render() {
    const { showTitle, formulary, answers, visibility, errors } = this.props;

    return (
      <div>
        {showTitle && (
          <Title1 style={styles.title}>
            {_t('guides.healthProfileSummary')}
          </Title1>
        )}

        {!!formulary && !!answers && !!visibility && !!errors && (
          <FormularyAnswers
            formulary={formulary}
            answers={answers}
            visibility={visibility}
            errors={errors}
            edit={this.edit}
          />
        )}
      </div>
    );
  }
}

export default connect(
  (state, props) => {
    const { childUuid } = props;

    const { formulary, errors, visibility, answers } =
      getHealthProfileFormularyState(state, childUuid) || {};

    return {
      childUuid,

      formulary,
      errors,
      visibility,
      answers,
    };
  },
  { openRoute }
)(SummaryContent);
