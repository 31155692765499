import { _t } from '@mindoktor/patient-legacy/i18n';
import dayjs from 'dayjs';
import React, { Component } from 'react';

import { Treater } from '../../../../state/cases/constants';

import {
  getTreaterByEntrywayId,
  isContactTracingGuide,
} from '../../../../state/guides/guidesUtils';
import {
  InvitationStatus,
  InvitationType,
} from '../../../../state/profile/types';

import { formatRoute } from '../../../../state/routing';

import { showModal } from '../../../routing/index';

import { Body1 } from '../../../../common/components/typography';
import Button from '../../../components/button/button';
import Markdown from '../../../components/markdown';

import Message from '../../components/message';

import InternalReferralModal from '../../modals/internal_referral_modal';
import RevisitInformationModal from '../../modals/revisit_information_modal';

import { routes } from '@mindoktor/patient-app/routing/constants/webRoutes';
import Img from '@mindoktor/pulse/src/utils/images/Img.web';

const styles = {
  header: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 16,
  },

  icon: {
    display: 'block',
    width: 44,
    height: 44,
    marginRight: 16,
    borderRadius: '50%',
  },

  title: {
    flex: 1,
    paddingTop: 10,
    fontWeight: 500,
  },

  buttons: {
    paddingTop: 16,
  },

  continueButton: {
    marginTop: 8,
  },

  topMargin: {
    marginTop: 16,
  },
};

import icon from '../../../../../images/icon/icon_complaints@3x.png';

export default class Invitation extends Component {
  getBodyText = () => {
    const {
      revisitStatus,
      revisitOpens,
      revisitCloses,
      typeName,
      targetEntrywayId,
    } = this.props;

    const period = {
      opens: `${dayjs(revisitOpens).format('DD MMM')}`,
      closes: `${dayjs(revisitCloses).format('DD MMM')}`,
    };

    if (typeName === InvitationType.internalReferral) {
      if (revisitStatus === InvitationStatus.RevisitStatusActive) {
        return isContactTracingGuide(targetEntrywayId)
          ? _t('guides.invitationCard.internalReferral.contactTracing')
          : _t('case.revisit.internalReferralItem.active', period);
      }
      if (revisitStatus === InvitationStatus.RevisitStatusExpired) {
        return _t('case.revisit.internalReferralItem.expired', period);
      }
      return _t('case.revisit.internalReferralItem.consumed');
    }
    if (typeName === InvitationType.patientRevisit) {
      if (revisitStatus === InvitationStatus.RevisitStatusActive) {
        return _t('case.revisit.patientRevisitItem.active', period);
      }
      if (revisitStatus === InvitationStatus.RevisitStatusExpired) {
        return _t('case.revisit.patientRevisitItem.expired', period);
      }
      return _t('case.revisit.patientRevisitItem.consumed');
    }

    if (revisitStatus === InvitationStatus.RevisitStatusActive) {
      return _t('case.revisit.caregiverRevisitItem.active', period);
    }
    if (revisitStatus === InvitationStatus.RevisitStatusExpired) {
      return _t('case.revisit.caregiverRevisitItem.expired', period);
    }
    return _t('case.revisit.caregiverRevisitItem.consumed', {
      opens: `${dayjs(revisitOpens).format('DD MMM')}`,
    });
  };

  getTitleText = () => {
    const { targetEntrywayId, typeName } = this.props;
    switch (typeName) {
      case InvitationType.internalReferral: {
        if (isContactTracingGuide(targetEntrywayId)) {
          return _t('case.revisit.internalReferralItem.header.contactTracing');
        }
        switch (getTreaterByEntrywayId(targetEntrywayId)) {
          case Treater.physiotherapist:
            return _t(
              'case.revisit.internalReferralItem.header.physiotherapist'
            );
          case Treater.psychologist:
            return _t('case.revisit.internalReferralItem.header.psychologist');
          default:
            return _t('case.revisit.internalReferralItem.header.general');
        }
      }
      case InvitationType.caregiverRevisit: {
        return _t('case.revisit.caregiverRevisitItem.header');
      }
      default: {
        return _t('case.revisit.patientRevisitItem.header');
      }
    }
  };

  getButtonText = () => {
    const { targetEntrywayId, typeName } = this.props;
    const isInternalReferral = typeName === InvitationType.internalReferral;

    if (isInternalReferral) {
      return isContactTracingGuide(targetEntrywayId)
        ? _t('guides.revisitCard.howItWorks.contactTracing')
        : _t('case.revisit.howItWorks');
    }
    return _t('case.revisit.howItWorks');
  };

  openInfo = () => {
    const { typeName, targetEntrywayId } = this.props;

    showModal(
      typeName === InvitationType.internalReferral
        ? InternalReferralModal
        : RevisitInformationModal,
      { entrywayId: targetEntrywayId }
    );
  };

  render() {
    const {
      childUuid,
      typeName,
      revisitId,
      revisitStatus,
      caseOwnerCanRepresentPatient,
      targetEntrywayId,
      isOpen,
      style,
    } = this.props;

    const isActive = revisitStatus === InvitationStatus.RevisitStatusActive;
    const isInternalReferral = typeName === InvitationType.internalReferral;

    return (
      <Message style={style}>
        <div style={styles.header}>
          <div>
            <Img src={icon} style={styles.icon} />
          </div>

          <Body1 style={styles.title}>{this.getTitleText()}</Body1>
        </div>

        <Body1>{this.getBodyText()}</Body1>

        {!caseOwnerCanRepresentPatient && (
          <Markdown
            style={styles.topMargin}
            source={
              isInternalReferral
                ? _t(
                    'guides.invitationCard.internalReferral.caseOwnerCanNotRepresentPatient'
                  )
                : _t('guides.revisitCard.caseOwnerCanNotRepresentPatient')
            }
          ></Markdown>
        )}

        {isActive && (
          <div>
            <Button color="black" type="flat" onClick={this.openInfo}>
              {this.getButtonText()}
            </Button>

            {isInternalReferral && isOpen && caseOwnerCanRepresentPatient && (
              <Button
                href={`#${formatRoute(routes.GUIDES_INTRO, {
                  entrywayId: targetEntrywayId,
                  childUuid,
                  revisitId,
                })}`}
                style={styles.continueButton}
              >
                {isContactTracingGuide(targetEntrywayId)
                  ? _t('guides.revisitCard.howItWorks.toContactTracing')
                  : _t('guides.revisitCard.continue')}
              </Button>
            )}
          </div>
        )}
      </Message>
    );
  }
}
