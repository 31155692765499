import React from 'react';

import MediaQuery from '../media_query';

const styles = {
  narrow: {
    paddingTop: 20,
  },

  wide: {
    padding: 30,
  },
};

const Body = ({ style, children }) => [
  <MediaQuery key="narrow" maxWidth={600}>
    <div
      style={{
        ...styles.narrow,
        ...style,
      }}
    >
      {children}
    </div>
  </MediaQuery>,

  <MediaQuery key="wide" minWidth={601}>
    <div
      style={{
        ...styles.wide,
        ...style,
      }}
    >
      {children}
    </div>
  </MediaQuery>,
];

export default Body;
