/*******************************************************************************
 * Mindoktor Palette
 ******************************************************************************/

export default {
  red_100: '#FFF2F2',
  red_400: '#BF0B0E',
  red_500: '#E52427',
  red_v500: '#FF383B',

  black: '#000000',
  off_black: '#00151E',
  white: '#ffffff',

  background: '#F0F0F2',
  white_semi_transparent: 'rgba(255, 255, 255, 0.7)',

  grey_100: '#F2F2F3',
  grey_200: '#E1E1E2',
  grey_300: '#D0D0D2',
  grey_400: '#BDBDBF',
  grey_500: '#A1A1A6',
  grey_700: '#7D7D82',
  grey_900: '#283D46',

  blue_200: '#7AD5FF',
  blue_300: '#3CC1FF',
  blue_500: '#0E73C0',

  green_500: '#1D9E6F',
  green_800: '#52AF52',

  yellow_500: '#FFD700',

  error_text: '#897306',

  orange_500: '#F77700',

  /*****************************************
   * Other colors
   *****************************************/
  apoteket_background: '#bae898',
  apoteket_color: '#2d9739',
  apoteket_hjartat_background: '#d7ead5',
  apoteket_hjartat_textcolor: '#175d30',
  ea_color: '#c6007e',
  lf_color: '#014586',
  skandia_color: '#1a8166',
  wh_color: '#0065d0',
  th_color: '#f4322d',
  certico_color: '#528bc0',
} as const;
