import React from 'react';

import { CircularProgress, Stack, Typography } from '@mui/material';

import TextLink from '@mindoktor/pulse/src/components/TextLink/web';
import { colors } from '@mindoktor/pulse/src/styles/colors';
import { circularProgressLarge } from '@mindoktor/pulse/src/styles/sizes';

import { useTranslation } from '@mindoktor/patient-app/localization/hooks/useTranslation';
import { isMobile } from '@mindoktor/patient-app/utils/device/web';

import BankIDSignIcon from '../../BankIDSignIcon/web';
import classes from '../../LoginMode.styles';

interface Props {
  complete: boolean;
  signing: boolean;
  onClose: () => void;
}

const LoginModeDevice: React.FC<Props> = ({ complete, signing, onClose }) => {
  const t = useTranslation('auth');

  return (
    <Stack sx={classes.root} spacing={'1rem'}>
      <Stack sx={classes.imageWrapper}>
        {signing ? (
          <BankIDSignIcon sx={classes.image} />
        ) : (
          <CircularProgress
            color="primary"
            size={circularProgressLarge}
            sx={{ alignSelf: 'center' }}
          />
        )}
      </Stack>

      {!(complete || signing) && (
        <>
          {isMobile ? (
            <Stack sx={classes.text}>
              <Typography
                variant="body2"
                color={colors.gray[900]}
                fontSize={14}
              >
                {t('bankid.startingApp')}
              </Typography>
            </Stack>
          ) : (
            <Stack sx={classes.text}>
              <Typography
                variant="body2"
                color={colors.gray[900]}
                fontSize={14}
              >
                {t('bankid.autoStartInfo')}
              </Typography>
            </Stack>
          )}
        </>
      )}

      {signing && (
        <Stack sx={classes.text}>
          <Typography variant="body2" color={colors.gray[900]} fontSize={14}>
            {t('bankid.signingInfo')}
          </Typography>
        </Stack>
      )}

      {complete && (
        <Stack sx={classes.text}>
          <Typography variant="body2" color={colors.gray[900]} fontSize={14}>
            {t('bankid.loggingIn')}
          </Typography>
        </Stack>
      )}

      <Stack flex={1} justifyContent={'flex-end'}>
        <TextLink
          noUnderline
          onClick={() => onClose()}
          sx={{ alignSelf: 'center' }}
        >
          {!(complete || signing) ? t('common.back') : t('common.cancel')}
        </TextLink>
      </Stack>
    </Stack>
  );
};

export default LoginModeDevice;
