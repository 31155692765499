import { _t } from '@mindoktor/patient-legacy/i18n';
import React, { PureComponent } from 'react';

import colors from '../../../common/colors';
import { Body3 } from '../../../common/components/typography';
import { translateErrorMessage } from '../../../state/freepass/utils';

// type Props = {
//   message?: string,
//   trigger?: string,
//   includeUnknowns?: boolean,
//   style?: any,
// };

export default class ErrorMessage extends PureComponent {
  render() {
    const { message, trigger, includeUnknowns, style } = this.props;
    let msg = false;

    if (message === trigger) {
      msg =
        (!!message && translateErrorMessage(message)) ||
        _t('freePass.unknownError');
    } else if (includeUnknowns) {
      msg =
        !!message &&
        !translateErrorMessage(message) &&
        _t('freePass.unknownError');
    }

    if (msg) {
      return (
        <Body3 style={{ color: colors.error_text, ...style }}>{msg}</Body3>
      );
    }

    return null;
  }
}
