import { _t } from '@mindoktor/patient-legacy/i18n';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from '@mindoktor/patient-legacy/src/common/compose';

import { isChildGuide } from '../../../state/guides/guidesUtils';
import { getGuideByEntrywayId } from '../../../state/guides/selectors';
import { openRoute, routes as legacyRoutes } from '../../../state/routing';
import { GuideStartOrigin } from '../../../state/tracking/constants';
import BoxedScreen from '../../components/boxed_screen';
import ContinueButton from '../../components/button/continue';
import { goHome } from '../../routing';
import GuideBar from '../components/bar';
import { getGuideImageByEntrywayId } from '../utils/images';
import DynamicCodeCard from './card';

import { withRouting } from '@mindoktor/patient-app/routing/HOC/withRouting.web';
import { routes } from '@mindoktor/patient-app/routing/constants/webRoutes';

const styles = {
  wrapper: {
    padding: 20,
  },

  body: {
    maxWidth: 600,
  },

  footer: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
  },

  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
};

// type Props = {
//   entrywayId: number,
//   childUuid?: string,
//   revisitId?: number,
//   dynamicCode?: string,
//   guide: Object,

//   iconSource: any,
//   title?: string,

//   openRoute: typeof openRoute,
// };

export class DynamicCodeScreen extends Component {
  openQuestions = () => {
    const {
      entrywayId,
      childUuid,
      revisitId,
      inquisitionId,
      openRoute,
      guide,
      dynamicCode,
      routing: {
        navigation: { navigate },
      },
    } = this.props;

    // child guides needs to be redirected to prestart to get child selector
    const guideRoute = isChildGuide(guide)
      ? routes.GUIDES_PRESTART
      : routes.GUIDES_QUESTIONS;

    if (inquisitionId) {
      openRoute(legacyRoutes.INQUISITION, {
        entrywayId,
        childUuid,
        revisitId,
        dynamicCode,
        origin: GuideStartOrigin.GUIDE_PICKER,
      });
      return;
    }

    navigate(
      guideRoute,
      {
        entrywayId,
      },
      {
        childUuid,
        revisitId,
        dynamicCode,
        origin: GuideStartOrigin.GUIDE_PICKER,
      }
    );
  };

  onCodeChange = (code) => this.setState({ dynamicCode: code });

  render() {
    const { dynamicCode, iconSource, title } = this.props;

    return (
      <BoxedScreen
        header={
          <GuideBar
            title={title}
            iconSource={iconSource}
            maxWidth={800}
            onCancel={goHome}
          />
        }
        body={
          <div style={styles.wrapper}>
            <div style={styles.body}>
              <DynamicCodeCard
                initialCode={dynamicCode}
                onCodeChange={this.onCodeChange}
              />
            </div>
          </div>
        }
        footer={
          <div style={styles.footer}>
            <div style={styles.buttons}>
              <ContinueButton
                text={_t('guides.continue')}
                onClick={this.openQuestions}
              />
            </div>
          </div>
        }
        showScrollGradient={true}
      />
    );
  }
}

const enhance = compose(
  withRouting,
  connect(
    (state, props) => {
      const {
        routing: {
          params: { entrywayId },
          queryParams,
        },
      } = props;

      const guide = getGuideByEntrywayId(state, entrywayId);
      const dynamicCode = queryParams.get('dynamicCode');

      const { displayTitle: title, inquisitionId } = guide || {};

      return {
        iconSource: getGuideImageByEntrywayId(entrywayId),
        title,
        inquisitionId,
        guide,
        entrywayId,
        dynamicCode,
      };
    },
    {
      openRoute,
    }
  )
);

export default enhance(DynamicCodeScreen);
