import React, { Component } from 'react';

import spacing from '../../../spacing';
import View from '../../primitives/View';
import { Body3, fonts } from '../../typography';
import Slider from './Slider';

const styles = {
  label: {
    paddingLeft: spacing.s,
    marginBottom: spacing.s,
    fontSize: 16,
    fontWeight: 'bold',
  },

  wrapper: {
    marginTop: spacing.t,
    marginBottom: spacing.m,
    paddingLeft: spacing.s + spacing.t,
    paddingRight: spacing.s + spacing.t,
  },
};

// type Props = {
//   value?: number,
//   options?: Array<mixed>,
//   answer?: any,
//   style?: any,
// };

export default class TimeSlider extends Component {
  constructor(props) {
    super(props);

    const defaultValue =
      props.answer && props.options
        ? props.options.findIndex((option) => option.value === props.answer)
        : 0;

    this.state = {
      value: defaultValue,
      initialValue: defaultValue,
    };
  }

  getValue = () => Math.round(this.state.value) || 0;

  getLabel = () => {
    const { options } = this.props;

    let option = options ? options[this.getValue()] : false;

    return option ? option.label || option.value : 'No options found';
  };

  onChangingValue = (value) => {
    this.setState({ value });
  };

  onSelectedValue = () => {
    const { options, update } = this.props;

    update(options[this.getValue()].value);

    this.setState({ value: this.getValue() });
  };

  render() {
    const { options, answer } = this.props;

    const labelStyles = {
      ...fonts.textLink,
      ...styles.label,
    };

    return (
      <View>
        <View>
          <Body3 style={labelStyles}>{this.getLabel()}</Body3>
        </View>
        <View style={styles.wrapper}>
          <Slider
            options={options}
            answer={answer}
            initialValue={this.state.initialValue}
            onChangingValue={this.onChangingValue}
            onSelectedValue={this.onSelectedValue}
          />
        </View>
      </View>
    );
  }
}
