import config from '@mindoktor/env/Config';
import { _t } from '@mindoktor/patient-legacy/i18n';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import pick from 'lodash-es/pick';

import { getCurrentLocale } from '../../../state/intl/selectors';

import {
  selectContactInfoVerification,
  saveUserProfile,
  setContactInfoBeingVerified,
  setContactInfoVerificationRedirectFunc,
  updateFromSpar,
} from '../../../state/profile/actions';

import {
  contactInformation,
  isProfileFetched,
  getContactInfoBeingVerified,
} from '../../../state/profile/selectors';

import validate from '../../../state/utils/validation';

import colors from '../../../common/colors';

import Button from '../../components/button/button';
import {
  Title1,
  Title2,
  Body1,
  Body3,
  fonts,
} from '../../../common/components/typography';
import Screen from '../../components/screen';
import TruncatedFooter from '../../components/footer/truncated';
import TextInput from '../../components/input/text';
import MediaQuery from '../../components/media_query';

import MenuBar from '../../menu/bar';
import { showSnackbar } from '../../../state/snackbar/actions';
import { SnackbarType } from '../../../state/snackbar/types';

import { withRouting, routes } from '@mindoktor/patient-app/routing/web';

const styles = {
  wrapper: {
    width: '100%',
    maxWidth: 800,
    margin: '0 auto',
    paddingTop: 20,
    paddingBottom: 20,
  },

  body: {
    maxWidth: 600,
    paddingLeft: 20,
    paddingRight: 20,
  },

  title: {
    fontSize: 26,
    lineHeight: '32px',
    marginBottom: 10,
  },

  section: {
    display: 'block',
    marginBottom: 20,
  },

  label: {
    fontWeight: 500,
    marginBottom: 5,
  },

  name: {
    marginBottom: 15,
  },

  message: {
    marginTop: 4,
    fontWeight: 500,
    marginBottom: 20,
  },

  error: {
    color: colors.error_text,
  },

  footer: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
    maxWidth: 760,
    margin: '0 auto',
  },

  buttons: {
    maxWidth: 600,
  },

  address: {
    marginBottom: 25,
  },

  updateFromSparButton: {
    backgroundColor: 'none',
    border: '0',
    display: 'inline-block',
    fontSize: fonts.body3.fontSize,
    marginBottom: 10,
  },
};

const keys = [
  'email',
  'phone',
  config.ShowContactAddressField ? 'address' : '',
  'postalcode',
  config.ShowContactCityField ? 'city' : '',
].filter((key) => !!key);

// addressKeys is what constitutes an address. We use this remove them from
// keys if editing isn't allowed.
const addressKeys = ['address', 'postalcode', 'city'];

const getLabel = (key) =>
  ({
    email: _t('common.email'),
    phone: _t('common.phone'),
    firstName: _t('common.firstName'),
    lastName: _t('common.lastName'),
    address: _t('common.address'),
    postalcode: _t('common.postalcode'),
    city: _t('common.city'),
  }[key]);

const validations = {
  email: ['required', 'email'],
  phone: ['required', 'phone'],
  address: ['required', 'text'],
  postalcode: ['required', 'zip'],
  city: ['required', 'text'],
};

const getErrorMessage = (error) =>
  ({
    required: _t('contactinformation.validation.required'),
    email: _t('contactinformation.validation.email'),
    phone: _t('contactinformation.validation.phone'),
    text: _t('contactinformation.validation.text'),
    zip: _t('contactinformation.validation.zip'),
  }[error]);

export class ContactScreen extends Component {
  state = {
    submitting: false,
    errors: {},
    loaded: this.props.loaded,
    ...pick(this.props, keys),
  };

  static getDerivedStateFromProps = (props, state) => {
    // sometimes the screen can be rendered before the user profile is fetched,
    // which sets the initial state user details to empty strings.
    // this check only runs if the profile is fetched after the component construction,
    // and means that we take the newly-fetched props user details instead of the empty state user details
    if (props.loaded && !state.loaded) {
      return {
        ...state,
        ...pick(props, keys),
        loaded: true,
      };
    }

    return null;
  };

  componentDidMount = () => {
    const { infoBeingVerified, routing } = this.props;
    if (
      routing.location.pathname === routes.ACCOUNT_CONTACT_VERIFY_EMAIL_SUCCESS
    ) {
      if (infoBeingVerified) {
        this.saveEmailVerification();
        return;
      }
      routing.navigation.navigate(routes.ACCOUNT_CONTACT);
    }
    return;
  };

  saveEmailVerification = async () => {
    const {
      infoBeingVerified: { info },
      setContactInfoBeingVerified,
      showSnackbar,
    } = this.props;

    this.setState({ email: info }, async () => {
      await this.submit();
      setContactInfoBeingVerified(null);
      showSnackbar({
        type: SnackbarType.SUCCESS,
        text: _t('contactinfo.verification.emailupdate.saved'),
      });
    });
  };

  onChange = async (key, value) => {
    const { locale } = this.props;
    const errors = (validations[key] || []).filter(
      (validation) => !validate(value, validation, { locale })
    );

    this.setState({
      [key]: value,
      errors: {
        ...this.state.errors,
        [key]: errors.length ? errors[0] : undefined,
      },
    });
  };

  renderUpdateName() {
    return (
      <Body3 style={styles.message}>
        {_t('contactinformation.updateFromSpar')}
        &nbsp;
        <Button
          style={styles.updateFromSparButton}
          type="flat"
          onClick={this.updateFromSpar}
        >
          {_t('contactinformation.updateFromSpar.callToAction')}
        </Button>
      </Body3>
    );
  }

  submit = async () => {
    const { saveUserProfile } = this.props;

    this.setState({ submitting: true });

    await saveUserProfile({
      contactInformation: this.state,
    });

    this.setState({
      submitting: false,
    });
  };

  createKey = (fieldName) => {
    // force a re-render of the relevant fields when their values are changed through redux, for
    // example when updating via SPAR.
    const updatable = [
      'firstName',
      'lastName',
      'address',
      'postalcode',
      'city',
      'email',
    ];
    if (updatable.includes(fieldName)) {
      return `${fieldName}_${this.props.profile[fieldName]}`;
    }

    return fieldName;
  };

  updateFromSpar = async () => {
    // No need to take care of the response or set state. The method itself dispatches the response,
    // which eventually puts it on the state.
    this.props.updateFromSpar();
  };

  // redirects to verification if contact info (ie. email, phone)
  // not verified, and submits if they are verified
  checkContactInfoVerificationAndSubmit = async () => {
    const {
      profile,

      selectContactInfoVerification,
    } = this.props;
    const { email: emailFromProps, identityProtection } = profile;
    const { email: emailFormState } = this.state;

    // if user has identity protection then just skip verification.
    if (identityProtection) {
      return this.submit();
    }

    let email = emailFromProps;

    let isEmailVerified = true;

    // if email is changed this field won't be undefined
    if (emailFormState && email != emailFormState) {
      email = emailFormState;

      const respEmail = await selectContactInfoVerification(email);
      isEmailVerified = respEmail.isVerified;
    }

    // both of them are already verified just continue with normal (previous) flow
    if (isEmailVerified) {
      return this.submit();
    }
    // here we know either one of the email or phone is changed and not verified
    // NOTE: The phone number verification has been hold back, so that's why we pass true
    // always to make sure we do not force user to verify phone.
    return this.verifyContactInfo(isEmailVerified, true, email, '');
  };

  verifyContactInfo = async (
    isEmailVerified,
    isPhoneVerified,
    email,
    phone
  ) => {
    const {
      setContactInfoVerificationRedirectFunc,
      setContactInfoBeingVerified,
      routing,
    } = this.props;
    const navigation = routing.navigation;
    let redirect = async () => {
      await this.submit();
      navigation.navigate(routes.ACCOUNT_CONTACT);
    };

    // If both email and phone is not verified we have to chain them.
    // Basically, set the redirect func to start over the
    // verification for phone after email.
    // (currently with redirect func)
    if (!isEmailVerified && !isPhoneVerified) {
      redirect = async () => {
        await setContactInfoBeingVerified({
          info: phone,
          type: 'phone',
        });

        await setContactInfoVerificationRedirectFunc(async () => {
          await this.submit();
          await navigation.navigate(routes.ACCOUNT_CONTACT);
        });

        navigation.navigate(routes.ACCOUNT_CONTACT_VERIFY_EMAIL);
      };
    }

    let info;
    let verificationType;

    // here we know for sure (because of the first if statement)
    // just one of them is not verified
    if (!isEmailVerified) {
      info = email;
      verificationType = 'email';
    } else if (!isPhoneVerified) {
      info = phone;
      verificationType = 'phone';
    }

    await setContactInfoBeingVerified({
      info: info,
      type: verificationType,
    });
    await setContactInfoVerificationRedirectFunc(redirect);

    navigation.navigate(routes.ACCOUNT_CONTACT_VERIFY_EMAIL);
  };

  render() {
    const { loaded, profile } = this.props;
    const {
      identityProtection,
      addressEditAllowed,
      firstName,
      lastName,
      address,
      postalcode,
      city,
    } = profile;
    const { submitting, errors } = this.state;

    if (!loaded) return null;

    const usedKeys = keys.filter(
      (key) => addressEditAllowed || !addressKeys.includes(key)
    );

    return (
      <Screen
        header={<MenuBar />}
        body={
          <div style={styles.wrapper}>
            <div style={styles.body}>
              <Title1 style={styles.title}>
                {_t('contactinformation.title')}
              </Title1>

              <Title2 style={styles.name}>
                {firstName} {lastName}
              </Title2>
              {addressEditAllowed && this.renderUpdateName()}

              {!identityProtection &&
                usedKeys.map((key) => (
                  <label key={this.createKey(key)} style={styles.section}>
                    <Body1 style={styles.label}>{getLabel(key)}</Body1>

                    <TextInput
                      initialValue={profile[key]}
                      error={!!errors[key]}
                      onChange={(value) => this.onChange(key, value)}
                    />

                    {!!errors[key] && (
                      <Body3 style={{ ...styles.message, ...styles.error }}>
                        {getErrorMessage(errors[key])}
                      </Body3>
                    )}
                  </label>
                ))}

              {!addressEditAllowed && (
                <div>
                  <div style={styles.address}>
                    <Body1 style={styles.label}>{_t('common.address')}</Body1>
                    <Body1>{address}</Body1>
                    <Body1>
                      {postalcode} {city}
                    </Body1>
                  </div>
                  {this.renderUpdateName()}
                </div>
              )}
            </div>
          </div>
        }
        footer={
          <div>
            <div style={styles.footer}>
              <div style={styles.buttons}>
                <Button
                  disabled={
                    submitting || !!Object.values(errors).find((m) => !!m)
                  }
                  hero={true}
                  onClick={this.checkContactInfoVerificationAndSubmit}
                >
                  {_t('common.save')}
                </Button>
              </div>
            </div>

            <MediaQuery minWidth={801}>
              <TruncatedFooter />
            </MediaQuery>
          </div>
        }
        showScrollGradient={true}
      />
    );
  }
}

export default withRouting(
  connect(
    (state) => {
      const profile = contactInformation(state);

      return {
        loaded: isProfileFetched(state),
        locale: getCurrentLocale(state),
        infoBeingVerified: getContactInfoBeingVerified(state),
        profile,
      };
    },
    {
      saveUserProfile,
      updateFromSpar,
      setContactInfoVerificationRedirectFunc,
      setContactInfoBeingVerified,
      getContactInfoBeingVerified,
      selectContactInfoVerification,
      showSnackbar,
    }
  )(ContactScreen)
);
