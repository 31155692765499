import React, { Component } from 'react';
import { connect } from 'react-redux';

import { updateFormulary } from '../../state/formulary/actions';
import { getFormularyState } from '../../state/formulary/selectors';
import Choice from './footer/choice';
import Confirm from './footer/confirm';
import Info from './footer/info';
import MultipleChoice from './footer/multiple_choice';
import Range from './footer/range';
import Upload from './footer/upload';

// type Props = {
//   formularyKey?: string,

//   node: Question,

//   answer?: any,
//   error?: string,

//   next: () => any,

//   updateFormulary: typeof updateFormulary,
// };

export class Footer extends Component {
  static defaultProps = {
    next: () => {},
  };

  update = async (value) => {
    const {
      formularyKey,
      node: { id },
      updateFormulary,
    } = this.props;

    await updateFormulary({ formularyKey, answers: { [id]: value } });
  };

  render() {
    const { node, answer, error, next } = this.props;

    const { label, type, types, validations } = node;

    switch (type) {
      case 'binary':
        return <Choice error={error} next={next} />;

      case 'choice':
        return <Choice error={error} next={next} />;

      case 'confirm':
        return <Confirm label={label} update={this.update} next={next} />;

      case 'info':
        return <Info next={next} />;

      case 'multipleChoice':
        return <MultipleChoice answer={answer} error={error} next={next} />;

      case 'range':
        return <Range error={error} next={next} />;

      case 'duration':
        return <Range error={error} next={next} />;

      case 'tertiary':
        return <Choice error={error} next={next} />;

      case 'upload':
        return (
          <Upload
            answer={answer}
            error={error}
            types={types}
            max={validations && validations.max}
            update={this.update}
            next={next}
          />
        );
    }

    return null;
  }
}

export default connect(
  (state, props) => {
    const { formularyKey, node } = props;

    if (!formularyKey) return {};

    const { answers, errors } = getFormularyState(state, formularyKey) || {};

    return {
      answer: answers && answers[node.id],
      error: errors && errors[node.id],
    };
  },
  { updateFormulary }
)(Footer);
