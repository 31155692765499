import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { PulseThemeProvider } from '@mindoktor/pulse/src/styles/web';

import LegacyApp from '@mindoktor/patient-legacy/src/web/index';
import ReduxProvider from '@mindoktor/patient-legacy/src/web/ReduxProvider';

import RealtimeMessagingProvider from '@mindoktor/realtime-messaging/components/RealtimeMessagingProvider';

import { AuthenticationProvider } from '@mindoktor/patient-app/auth';
import LocaleProvider from '@mindoktor/patient-app/localization/components/LocaleProvider';
import { NotificationProvider } from '@mindoktor/patient-app/notifications';
import { setPersistentStore } from '@mindoktor/patient-app/store/store';
import getWebStore from '@mindoktor/patient-app/store/web';
import MarketingTrackingProvider from '@mindoktor/patient-app/tracking/context/MarketingTrackingProvider';
import { UserActivityProvider } from '@mindoktor/patient-app/user';

import { useRtmToken } from './hooks/useRtmToken';

import '@mindoktor/patient-app/localization/i18n';

// Setup react query
const queryClient = new QueryClient();

setPersistentStore(getWebStore());

const RealtimeMessagingProviderAdapter: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const rtmToken = useRtmToken();
  return (
    <RealtimeMessagingProvider token={rtmToken}>
      {children}
    </RealtimeMessagingProvider>
  );
};

const App = () => {
  return (
    <PulseThemeProvider>
      <NotificationProvider>
        <QueryClientProvider client={queryClient}>
          <UserActivityProvider>
            <ReduxProvider>
              <RealtimeMessagingProviderAdapter>
                <AuthenticationProvider>
                  <MarketingTrackingProvider>
                    <LocaleProvider>
                      <LegacyApp />
                    </LocaleProvider>
                  </MarketingTrackingProvider>
                  <ReactQueryDevtools initialIsOpen={false} />
                </AuthenticationProvider>
              </RealtimeMessagingProviderAdapter>
            </ReduxProvider>
          </UserActivityProvider>
        </QueryClientProvider>
      </NotificationProvider>
    </PulseThemeProvider>
  );
};

export default App;
