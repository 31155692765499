import React, { useEffect, useState } from 'react';

import Stack, { StackProps } from '@mui/material/Stack/Stack';

import { getVisibleHeight } from '../../../functions/getActualHeight/web';
import { useResizeEffect } from '../../../hooks/useResizeEffect/web/useResizeEffect';
import { useScrollEffect } from '../../../hooks/useScrollEffect/web/useScrollEffect';

interface Props extends Omit<StackProps, 'height'> {
  children: React.ReactNode;
}

/** Special Stack that stays as high as the viewport */
const FullHeightStack: React.FC<Props> = ({ children, ...stackProps }) => {
  const [visibleHeight, setVisibleHeight] = useState(getVisibleHeight());
  useResizeEffect(() => {
    setVisibleHeight(getVisibleHeight());
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useScrollEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <Stack height={visibleHeight} {...stackProps}>
      {children}
    </Stack>
  );
};

export default FullHeightStack;
