import { useQuery } from '@tanstack/react-query';

import { ResponseError } from '@mindoktor/patient-app/api/responseError';
import { api } from '@mindoktor/patient-app/utils/featureFlags/api/featureFlags';
import { FeatureFlags } from '@mindoktor/patient-app/utils/featureFlags/api/models/featureFlags';

const queryKey = 'featureFlags';

/**
 * useFeatureFlag fetches feature flags and provides two booleans; isEnabled and isLoading.
 *
 * @example
 * const newAuthFlag = useFeatureFlag(PATIENT_APP_AUTH_FEATURE_FLAG);
 * if (newAuthFlag.isEnabled && !newAuthFlag.isLoading) return doSomethingNew;
 */
export const useFeatureFlag = (key: string) => {
  const { data: flags, isLoading } = useQuery<
    FeatureFlags | undefined,
    ResponseError
  >(
    [queryKey],
    async () => {
      const response = await api.getFeatureFlags();
      if (!response?.ok) {
        throw new ResponseError(response);
      }
      return response?.data?.data;
    },
    {
      retry: false,
      staleTime: Infinity, // never consider this data stale
    }
  );

  const isEnabled = flags?.find((flag) => flag.key === key)?.enabled ?? false;

  return {
    isLoading,
    isEnabled,
  };
};
