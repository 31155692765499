import {
  useAppDispatch,
  useAppSelector,
} from '@mindoktor/patient-legacy/src/state/hooks';

import { LegacyState } from '../types';

type LegacySelector<TSelectedValue = unknown> = (
  legacyState: LegacyState
) => TSelectedValue;

export const useLegacySelector = <TSelectedValue = unknown>(
  selector: LegacySelector<TSelectedValue>
): TSelectedValue => useAppSelector(selector as any);

export const useLegacyDispatch = useAppDispatch;
