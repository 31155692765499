import { _t } from '@mindoktor/patient-legacy/i18n';
import React from 'react';
import { connect } from 'react-redux';

import constants from '../../constants';
import { getInformedConsentsByEntrywayId } from '../../../state/consents/selectors';
import Button from '../Button';
import View from '../primitives/View';

// type Props = {
//   next: () => any,
//   entrywayId: number,
// };

const styles = {
  root: {
    marginBottom: constants.footerMargin,
    marginLeft: constants.screenPaddingHorizontal,
    marginRight: constants.screenPaddingHorizontal,
  },
};

class InformedConsentFooter extends React.Component {
  render() {
    const { informedConsent, next } = this.props;
    const hasNotConsented = !(informedConsent && informedConsent.second);
    return (
      <View style={styles.root}>
        <Button
          hero={true}
          disabled={hasNotConsented}
          onPress={() => {
            next();
          }}
        >
          {_t('common.continue')}
        </Button>
      </View>
    );
  }
}

export default connect(
  (state, props) => ({
    informedConsent: getInformedConsentsByEntrywayId(props.entrywayId, state),
  }),
  {}
)(InformedConsentFooter);
