export const AuthorizationStatuses = {
  /** Can only visit public pages. */
  NONE: 'NONE',
  /** Can visit pages that only require authentication but not registration. */
  AUTHENTICATED: 'AUTHENTICATED',
  /** Can visit pages that require accepting TOS/privacy, but is missing contact details. */
  ACCEPTED_TOS_PRIVACY: 'ACCEPTED_TOS_PRIVACY',
  /** Has access to all pages, is fully registered and logged in. */
  COMPLETE: 'COMPLETE',
} as const;

export type AuthorizationStatus = keyof typeof AuthorizationStatuses;
