import { normalize } from 'normalizr';

import { getApi } from '../api/actions';
import { getCompanyId } from '../api/selectors';
import { openUrl } from '../app/actions';
import { loadFormulary } from '../formulary/actions';
import { updateFreePass } from '../freepass/actions';
import { loadHealthProfile } from '../healthprofile/actions';
import { getCurrentLocale } from '../intl/selectors';
import schemas from '../schemas';
import {
  CHANGE_GUIDECATEGORY,
  CLEAR_GUIDE_FLAG,
  GUIDE_NATIVE_START_ERROR,
  GUIDES_CANCEL,
  GUIDES_LOAD_ERROR,
  GUIDES_SEARCH_CANCEL,
  GUIDES_SEARCH_CLEAR,
  GUIDES_SEARCH_DONE,
  GUIDES_SEARCH_SUBMIT,
  GUIDES_STEP,
  RECEIVE_GUIDES,
  REQUEST_GUIDES,
  START_GUIDE,
  VIEW_GUIDE,
} from './actionTypes';
import { getTreaterByEntrywayId, isChildGuide } from './guidesUtils';
// temporarily use mock data due to cors issues on website endpoint
import mockCategoryResponse from './mockCategoryResponse.json';
import { getGuideByEntrywayId } from './selectors';

const CACHE_DURATION_MS = 3600000; // 1 hour

export const receiveGuides = (_guides = [], locale, categories = {}) => {
  const guides = _guides
    .filter((guide) => !!guide.displayTitle)
    .map((guide) => {
      return {
        ...guide,
        treater: getTreaterByEntrywayId(guide.entrywayId),
      };
    });

  const normalizedData = normalize(guides, schemas.guides);
  const guidesByEntrywayId = normalizedData.entities.guides;

  for (const key in guidesByEntrywayId) {
    if (guidesByEntrywayId.hasOwnProperty(key)) {
      const element = guidesByEntrywayId[key];
      const title = element.displayTitle;
      guidesByEntrywayId[key] = {
        ...guidesByEntrywayId[key],
        displayTitle: title.replace('§', ''),
      };
    }
  }

  const allEntrywayIds = normalizedData.result.filter(
    (id) => guidesByEntrywayId[id]
  );
  const guideCategoriesById = {};

  // Make obj with displayTitle by key
  for (const key in categories) {
    if (categories.hasOwnProperty(key)) {
      const category = categories[key];
      guideCategoriesById[category.id] = {
        ...category,
      };
    }
  }
  // remove entrywayIds from manually maintained category lists when not in API response
  for (const key in guideCategoriesById) {
    const catgr = guideCategoriesById[key];
    catgr.entrywayIds = catgr.entrywayIds.filter(
      (id) => guidesByEntrywayId[id]
    );
  }
  // create a unique Set of entrywayIds to compare with
  // ie. each guide can be part of several categories
  const categorySet = new Set(
    Object.keys(guideCategoriesById).reduce((acc, cat) => {
      acc.push(...guideCategoriesById[cat].entrywayIds);
      return acc;
    }, [])
  );

  const sortByTitle = (ids = [], items = {}, key = 'displayTitle') => {
    const byTitle = (first, second) => {
      const a = items[first] && items[first][key];
      const b = items[second] && items[second][key];
      // skip sorting if sort key is not found
      if (!a || !b) {
        return 0;
      }
      // localeCompare somehow doesn't work as expected on Node anymore,
      // sort order becomes wrong in the static html, causing all sorts of mayhem
      // in the guide picker list after rehydration.
      // return a.localeCompare(b, 'sv');

      // resort back to regular sorting
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    };

    return ids
      .filter((id) => items[id] && items[id].displayTitle)
      .sort(byTitle);
  };

  const guideEntrywayIds = sortByTitle(
    allEntrywayIds,
    guidesByEntrywayId,
    'displayTitle'
  );

  guideEntrywayIds.forEach((id) => {
    const guide = guidesByEntrywayId[id];
    if (
      !categorySet.has(id) &&
      !isChildGuide(guide) &&
      guideCategoriesById.other
    ) {
      // add uncategorized guide to "other" category
      guideCategoriesById.other.entrywayIds.push(id);
    }
  });

  // Sort after we added new to the other
  if (guideCategoriesById.other) {
    guideCategoriesById.other.entrywayIds = sortByTitle(
      guideCategoriesById.other.entrywayIds,
      guidesByEntrywayId,
      'displayTitle'
    );
  }

  return {
    type: RECEIVE_GUIDES,
    payload: {
      guideEntrywayIds,
      guidesByEntrywayId,
      guideCategoriesById,
      receivedAt: Date.now(),
    },
  };
};

export function changeGuideCategory(
  category, // : 'Adult' | 'Child',
  currentLocation = 'System'
) {
  return {
    type: CHANGE_GUIDECATEGORY,
    payload: {
      category,
    },
    tracking: {
      currentLocation,
    },
    meta: {},
  };
}

// memoize fetch criteria
const cacheParams = {
  loggedIn: null,
  id: null,
  locale: null,
};

function cacheIsValid(state) {
  return (
    state.guides.guideEntrywayIds.length > 0 &&
    cacheParams.loggedIn === state.api.loggedIn &&
    cacheParams.id === state.api.session.id &&
    Date.now() - state.guides.updatedAt < CACHE_DURATION_MS &&
    cacheParams.locale === state.intl.currentLocale
  );
}

const fetchGuideCategoriesFromWebsite = async () => {
  // allow for fallback to cached data in case website server fails
  const json = mockCategoryResponse;
  // try {
  //   const response = await fetch(
  //     `${config.Urls.web.domain}/data/guide-categories/`,
  //     {
  //       method: 'GET',
  //       mode: 'no-cors',
  //       headers: { 'content-type': 'application/json' },
  //     }
  //   );
  //   if (!response.ok) {
  //     json = await response.json();
  //   } else {
  //     json = mockCategoryResponse;
  //   }
  // } catch (error) {
  //   throw new Error(error);
  // }
  return json.data;
};

export const fetchGuides = () => async (dispatch, getState) => {
  const state = getState();

  // check if we really need to fetch guide data from server
  if (cacheIsValid(state)) {
    return;
  }

  // if we only want child guides: ?maxage=18&minage=0
  const locale = getCurrentLocale(state);
  const params = `?maxage=999&minage=0&locale=${locale}`;
  const uri = `/api/v1/guides${params}`;

  dispatch({
    type: REQUEST_GUIDES,
    payload: {
      locale,
      params,
    },
  });

  // start with fetching guide categories, located on website
  const guideCategories = await fetchGuideCategoriesFromWebsite();

  // make sure we request guides without auth
  const { json } = await dispatch(getApi(uri));

  if (json && json.guides) {
    dispatch(
      receiveGuides(json.guides, getCurrentLocale(state), guideCategories)
    );
  }

  // update memoized cache params for next comparison if fetch was successful
  cacheParams.loggedIn = state.api.loggedIn;
  cacheParams.id = state.api.session.id;
  cacheParams.locale = state.intl.currentLocale;
};

// make it an alias to fetchGuides for now …
export const getAllCategories = fetchGuides;

export function viewGuide(
  entrywayId,
  { currentLocation } = {},
  doTrackPressed = false
) {
  return async function (dispatch, getState) {
    const state = getState();

    const guide = getGuideByEntrywayId(state, entrywayId);
    if (!guide) {
      return false;
    }

    dispatch({
      type: VIEW_GUIDE,
      payload: { guide },
      meta: {
        doTrackPressed,
        track: {
          event: 'GuidePicker.ViewGuide',
          properties: {
            entrywayId,
            formularyName: guide.formularyName,
            partner: getCompanyId(state),
            site: 'webapp',
          },
          receiver: ['product'],
        },
      },
      tracking: {
        currentLocation,
      },
    });

    return guide;
  };
}

export const conductSearch = (searchTerm) => ({
  type: GUIDES_SEARCH_SUBMIT,
  payload: { searchTerm },
});

export const searchDone = (searchTerm, pickedSuggestion, numberOfHits) => ({
  type: GUIDES_SEARCH_DONE,
  payload: {
    searchTerm,
    pickedSuggestion,
    numberOfHits,
  },
});

export const clearSearch = () => ({
  type: GUIDES_SEARCH_CLEAR,
});

export const cancelSearch = () => ({
  type: GUIDES_SEARCH_CANCEL,
});

// This action is for web guides only.
export function startGuide(entrywayId) {
  return function (dispatch, getState) {
    const state = getState();
    const guide = getGuideByEntrywayId(state, entrywayId);
    const url = `https://guides.mindoktor.se/#/guides/${entrywayId}`;

    dispatch({
      type: START_GUIDE,
      payload: guide,
      url,
    });

    // open url in browser
    dispatch(openUrl(url));
  };
}

export function clearGuideStartedFlag() {
  return {
    type: CLEAR_GUIDE_FLAG,
  };
}

export const triggerChildGuideAgeError = () => {
  return {
    type: GUIDE_NATIVE_START_ERROR,
    payload: { error: 'childGuideAge' },
  };
};

export const stepGuide = ({ formularyKey, step, previousStep, origin }) => ({
  type: GUIDES_STEP,
  payload: { formularyKey, step, previousStep, origin },
});

export const cancelGuide = ({ formularyKey, step }) => ({
  type: GUIDES_CANCEL,
  payload: { formularyKey, step },
});

export const loadGuideQuestionsAndFreePass =
  ({ entrywayId, childUuid, revisitId, dynamicCode }) =>
  async (dispatch, getState) => {
    const { formularyName: formularyId, formularyVersion } =
      getGuideByEntrywayId(getState(), entrywayId);

    const asyncRequests = [
      formularyId
        ? dispatch(
            loadFormulary({
              entrywayId,
              childUuid,
              revisitId,
              formularyId,
              formularyVersion,
              dynamicCode,
            })
          )
        : undefined,
      dispatch(
        loadHealthProfile({
          childUuid,
        })
      ),
      dispatch(updateFreePass()),
    ];

    const [
      { formularyKey, error: formularyError } = {},
      { formularyKey: healthProfileKey, error: healthProfileError },
      freePassSuccess,
    ] = await Promise.all(asyncRequests);

    if (formularyError || healthProfileError || !freePassSuccess) {
      dispatch({ type: GUIDES_LOAD_ERROR });

      return { error: true };
    }

    return {
      formularyKey,
      healthProfileKey,
    };
  };
