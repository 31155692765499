import {
  ApiResponse,
  requestHandler,
} from '@mindoktor/patient-app/api/requestHandler';

import { MarketingUserResponse } from './models/marketingUser';
import { MarketingUserResponseSchema } from './schema/marketingUser';

const BASE_URL = '/api/v1/tracking';

const getMarketingUser = async (): Promise<
  ApiResponse<MarketingUserResponse>
> => {
  return await requestHandler.get(`${BASE_URL}/marketing-user`, {
    validationSchema: MarketingUserResponseSchema,
  });
};

export const trackingApi = {
  getMarketingUser,
};
