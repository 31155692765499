import { useNotifications } from '@mindoktor/patient-app/adapters/hooks/useNotifications';
import { ResponseError } from '@mindoktor/patient-app/api/responseError';
import { useTranslation } from '@mindoktor/patient-app/localization/hooks/useTranslation';

import { getErrorMsgKey } from '../../api/error';
import { UserErrorCodes } from '../api/models/error';

export const useUserErrorNotifications = () => {
  const notifications = useNotifications();
  const t = useTranslation('user', 'error');

  const show = (error: ResponseError | unknown) => {
    if (error instanceof ResponseError) {
      const errorKey = getErrorMsgKey(error, (code) =>
        getUserErrorMsgKey(code)
      );
      notifications.showWarning(t(errorKey));
    } else {
      notifications.showWarning(t('unknown'));
    }
  };

  return {
    show,
  };
};

export const getUserErrorMsgKey = (errorCode: string) => {
  switch (errorCode) {
    case UserErrorCodes.UserAccessDenied:
      return 'accessDenied';
    case UserErrorCodes.UserEmailCollision:
      return 'emailCollision';
    case UserErrorCodes.UserInvalidEmail:
      return 'invalidEmail';
    case UserErrorCodes.UserInvalidPhone:
      return 'invalidPhone';
    default:
      return 'unknown';
  }
};
