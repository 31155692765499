import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';

import { ResponseError } from '@mindoktor/patient-app/api/responseError';

import { messagingApi } from '../api/messaging';
import { MessageRequestPayload, MessageResponse } from '../api/models/message';

import { CONVERSATION_QUERY_KEY } from './useConversationApi';

export type CreateMessageMutation = UseMutationResult<
  MessageResponse | undefined,
  ResponseError,
  MessageRequestPayload
>;

export const useMessageMutator = (
  conversationId: number | null
): CreateMessageMutation => {
  const queryClient = useQueryClient();

  return useMutation(
    async (message: MessageRequestPayload) => {
      if (conversationId == null) {
        return undefined;
      }

      const response = await messagingApi.postMessage(conversationId, {
        payload: message,
      });
      if (!response.ok) {
        throw new ResponseError(response);
      }
      return response.data;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          conversationId,
          CONVERSATION_QUERY_KEY,
        ]);
      },
    }
  );
};
