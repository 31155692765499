import { _t } from '@mindoktor/patient-legacy/i18n';
import React, { Component } from 'react';

import { Title2 } from '../../../common/components/typography';
import spacing from '../../../common/spacing';
import Button from '../../components/button/button';

const TIMESLOT_SPACING = 16;
const BUTTON_SIZE = 36;
const BUTTON_BORDER_SIZE = 2;

const styles = {
  dayWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '95%',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  date: {
    marginTop: spacing.xxt,
    marginLeft: spacing.xxt,
  },
  timeSlots: {
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
  },
  time: {
    marginRight: spacing.xxt,
    marginLeft: spacing.xxt,
    marginBottom: TIMESLOT_SPACING,
  },
  confirmBooking: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
};

// type Props = {
//   day: string,
//   timeSlots: Timeslot[],
//   disabled: boolean,
//   index: number, // this items index in the list
//   clickedTimeSlotIndex: number,
//   handleTimeSlotSelected: () => any,
//   handleTimeSlotBooked?: () => any, // add date/time as parameter
// };

export default class AvailableDay extends Component {
  handleBookClicked(time, event) {
    const { handleTimeSlotBooked } = this.props;
    event.stopPropagation();
    handleTimeSlotBooked(time.start.utc().format(), time.end.utc().format());
  }

  handleTimeSlotClicked(clickedTimeSlotIndex, event) {
    const { handleTimeSlotSelected, index } = this.props;
    event.stopPropagation();
    handleTimeSlotSelected(index, clickedTimeSlotIndex);
  }

  render() {
    const { day, timeSlots, disabled, clickedTimeSlotIndex } = this.props;
    return (
      <div style={styles.dayWrapper}>
        {
          <Title2
            style={{
              ...styles.date,
              opacity: disabled && clickedTimeSlotIndex === -1 ? 0.33 : 1,
              paddingTop:
                clickedTimeSlotIndex === -1
                  ? 0
                  : clickedTimeSlotIndex *
                    (BUTTON_SIZE + 2 * BUTTON_BORDER_SIZE + TIMESLOT_SPACING),
              position: clickedTimeSlotIndex === -1 ? 'sticky' : 'relative',
              top: clickedTimeSlotIndex === -1 ? 80 : 0,
            }}
          >
            {day.format('ddd DD MMM')}
          </Title2>
        }
        <div style={styles.timeSlots}>
          {timeSlots.map((time, i) => {
            if (clickedTimeSlotIndex === i) {
              return (
                <div key={i} style={styles.confirmBooking}>
                  <Button
                    style={{ ...styles.time, width: '50%' }}
                    color="black"
                  >
                    {time.start.format('LT')}
                  </Button>
                  <Button
                    style={{ ...styles.time, width: '50%' }}
                    color="red"
                    onClick={(event) => this.handleBookClicked(time, event)}
                  >
                    {_t('scheduler.book')}
                  </Button>
                </div>
              );
            }
            return (
              <Button
                key={i}
                style={styles.time}
                type="ghost"
                color="black"
                disabled={disabled}
                onClick={(event) => this.handleTimeSlotClicked(i, event)}
              >
                {time.start.format('LT')}
              </Button>
            );
          })}
        </div>
      </div>
    );
  }
}
