import Config from '@mindoktor/env/Config';
import { REHYDRATE } from 'redux-persist/es/constants';
import { getIn } from 'timm';

import {
  API_COMPANY,
  API_SITE,
  CHOOSE_SIGNUP_DOMAIN,
  LOGIN,
  LOGOUT,
  UPDATE_TOKEN,
} from './actions';
import { HOSTS_CONFIG_LOAD_SUCCESS } from './types';

const initialState = {
  token: '',
  session: {},
  loggedIn: false,
  site: Config.DefaultSite || '',
  host: undefined,
  signupDomainId: undefined,
};

export default function apiReducer(state = initialState, action) {
  const { type, payload = {} } = action;

  switch (type) {
    case LOGIN:
      return {
        ...initialState,
        loggedIn: true,
        token: payload.token,
        session: payload.session,
        site: state.site,
        companyId: state.companyId,
        host: state.host,
      };

    case LOGOUT:
      // Ideally signupDomainId should be cleared on logout but this is a safeguard against the following scenario:
      // 1. User selects domain, gets saved here
      // 2. While user continues an unautorized api call (ie push push registration) triggers LOGOUT that would clear selectedDomainID
      //    Note: LOGOUT will be triggered regardless if user is signed-in or not since Dynamic Code functionality is dependent on it https://github.com/mindoktor/mindoktor-app/pull/2986)
      // 3. The user tries to posts signup but domainID is missing.

      return {
        ...initialState,
        site: state.site,
        companyId: state.companyId,
        host: state.host,
        signupDomainId: state.signupDomainId,
      };

    case UPDATE_TOKEN:
      return {
        ...state,
        token: payload.token,
        session: {
          ...(payload.session || state.session),
        },
      };

    case API_SITE:
      return {
        ...state,
        site: payload.site || '',
      };

    case API_COMPANY:
      return {
        ...state,
        companyId: payload.companyId,
      };

    case REHYDRATE: {
      if (Config.StayLoggedIn && getIn(payload, ['api', 'loggedIn'])) {
        return { ...payload.api };
      }
      return state;
    }

    case CHOOSE_SIGNUP_DOMAIN:
      const { id } = payload;
      return {
        ...state,
        signupDomainId: id,
      };

    case HOSTS_CONFIG_LOAD_SUCCESS: {
      const { host } = payload;
      return { ...state, host };
    }

    default:
      return state;
  }
}
