import { _t } from '@mindoktor/patient-legacy/i18n';
import React from 'react';
import { connect } from 'react-redux';

import logoIcon from '../../../images/logo/heart_alone.svg';
import constants from '../../common/constants';
import { updateQuestionnaire } from '../../state/formulary/actions';
import { answerSurvey, cancelSurvey } from '../../state/survey2/actions';
import Message from '../cases/components/message';
import Button from '../components/button/button';
import QuestionnaireHeader from './header';
import Input from './input';

const styles = {
  outer: {
    marginBottom: constants.spacing16,
  },
  input: {
    marginBottom: 0,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    marginTop: constants.spacing24,
  },
  button: {
    flex: 1,
  },
  firstButton: {
    marginRight: 12,
  },
};

// type Props = {
//   formularyKey: string,
//   node: QuestionType,
//   answer?: any,
//   error?: string,
//   options?: Option[],
//   min?: Min,
//   max?: Max,
//   submitted?: boolean,
//   style?: any,
//   caseId: number,
//   surveyName: string,
//   updateQuestionnaire: typeof updateQuestionnaire,
//   answerSurvey: typeof answerSurvey,
//   cancelSurvey: typeof cancelSurvey,
// };

export class Question extends React.PureComponent {
  static defaultProps = {
    next: () => {},
  };

  update = async (value) => {
    const {
      formularyKey,
      node: { id },
      updateQuestionnaire,
    } = this.props;
    await updateQuestionnaire({ formularyKey, answers: { [id]: value } });
  };

  sendAnswer = async () => {
    const { caseId, surveyName, answerSurvey } = this.props;
    await answerSurvey(caseId, surveyName);
  };

  cancel = async () => {
    const { caseId, surveyName, cancelSurvey } = this.props;
    await cancelSurvey(caseId, surveyName);
  };

  render() {
    const { node, answer, error, options, min, max, style, submitted } =
      this.props;

    const displaySubmit = node.type !== 'info';

    return (
      <Message style={{ ...styles.outer, ...style }}>
        <QuestionnaireHeader
          icon={node.type === 'rating' ? logoIcon : ''}
          prologue={node.prologue}
          label={node.label}
          description={node.description}
        />

        <Input
          node={node}
          answer={answer}
          error={error}
          options={options}
          min={min}
          max={max}
          update={this.update}
          style={styles.input}
          submitted={submitted}
        />

        {!submitted && displaySubmit && (
          <div style={styles.buttons}>
            <Button
              color="black"
              type="ghost"
              hero={false}
              onClick={this.cancel}
              style={{ ...styles.button, ...styles.firstButton }}
            >
              {_t('ratings.request.answer.no')}
            </Button>
            <Button
              color="red"
              hero={false}
              style={styles.button}
              onClick={this.sendAnswer}
              disabled={answer === undefined}
            >
              {_t('common.send')}
            </Button>
          </div>
        )}
      </Message>
    );
  }
}
export default connect((state) => state, {
  updateQuestionnaire,
  answerSurvey,
  cancelSurvey,
})(Question);
