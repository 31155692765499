import { API_URL } from '../api';

export const getFileUri = (id, thumbnail, token, site = '') =>
  `${API_URL}/api/v1/files/${id}?${
    thumbnail ? 'thumb=true&' : ''
  }&access_token=${token}&site=${site}`;

const imageRegex = /.(jpeg|jpg|png|gif)$/i;
const videoRegex = /.(webm|mp4|quicktime|mov)$/i;

export const hasImageExtension = (uri) => !!imageRegex.exec(uri);
export const hasVideoExtension = (uri) => !!videoRegex.exec(uri);

// we are not able to know if old files are sent from the patient or not
// we will return "undefined" in that case and show them as a message from the patient
export const isFromPatient = (fromPatient) =>
  fromPatient || fromPatient === undefined;

/**
 * Returns whether the mime type is a video.
 *
 * @param {string} type
 * @returns {boolean} true if the type is video
 */
export const isMimeTypeVideo = (type) => validateMime(type, 'video/');

/**
 * Returns whether the mime type is an image.
 *
 * @param {string} type
 * @returns {boolean} true if the provided type is an image
 */
export const isMimeTypeImage = (type) => validateMime(type, 'image/');

const validateMime = (type, expression) => {
  return !!type?.startsWith(expression);
};

/**
 * Returns a file path that is prefixed with a file:// schema.
 *
 * @param {string} filePath
 * @returns {string} a file:// prefixed file path
 */
export const prefixWithFileScheme = (filePath) => {
  if (!filePath.startsWith('file://')) {
    return 'file://' + filePath;
  }
  return filePath;
};

/**
 * Returns whether provided path is an Android content Uri.
 *
 * @param {string} path
 * @returns {boolean} true if the provided path is an Android Content Uri
 */
export const isContentUri = (path) => {
  return path.startsWith('content://');
};
