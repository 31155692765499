import dayjs from 'dayjs';

import { getApi, postApi } from '../api/actions';
// import type { TimeSlot, AvailableDay } from './types';

export const SCHEDULING_BOOK_APPOINTMENT_REQUEST =
  'SCHEDULING_BOOK_APPOINTMENT_REQUEST';

export const bookAppointment =
  (caseId, caregiverId, start, end) => async (dispatch) => {
    dispatch({ type: SCHEDULING_BOOK_APPOINTMENT_REQUEST });

    const endpoint = '/api/v1/scheduling/appointments';
    const payload = {
      caregiver: caregiverId,
      case: caseId,
      start,
      end,
    };
    return await dispatch(postApi(endpoint, payload));
  };

export const SCHEDULING_CANCEL_APPOINTMENT = 'SCHEDULING_CANCEL_APPOINTMENT';

export const cancelAppointment = (appointmentId) => async (dispatch) => {
  const { error, status } = await dispatch(
    postApi('/v1/scheduling/appointments/' + String(appointmentId) + '/cancel')
  );

  if (error || status > 400) {
    throw new Error(SCHEDULING_CANCEL_APPOINTMENT + 'failed');
  }

  await dispatch({
    type: SCHEDULING_CANCEL_APPOINTMENT,
    payload: { appointmentId, timestamp: dayjs().toISOString() },
  });
};

export const SCHEDULING_UNDO_CANCEL_APPOINTMENT =
  'SCHEDULING_UNDO_CANCEL_APPOINTMENT';

export const undoCancelAppointment =
  (caseId, appointmentId) => async (dispatch) => {
    const { error, status } = await dispatch(
      postApi(
        '/v1/scheduling/appointments/' + String(appointmentId) + '/undocancel'
      )
    );

    if (error || status > 400) {
      throw new Error(SCHEDULING_UNDO_CANCEL_APPOINTMENT + ' failed');
    }

    await dispatch({
      type: SCHEDULING_UNDO_CANCEL_APPOINTMENT,
      payload: { caseId, appointmentId },
    });
  };

export const SCHEDULING_AVAILABLE_TIMESLOTS_REQUEST =
  'SCHEDULING_AVAILABLE_TIMESLOTS_REQUEST';

export const fetchAvailableTimeslots =
  (caregiverId, from, to) => async (dispatch) => {
    dispatch({ type: SCHEDULING_AVAILABLE_TIMESLOTS_REQUEST });

    const endpoint = `/api/v1/scheduling/caregiver/${caregiverId}/slots?from=${from}&to=${to}`;

    try {
      const { json, error } = await dispatch(getApi(endpoint));
      if (error) throw error;
      return { availableDays: processData(json) };
    } catch (err) {
      console.warn('Failed to fetch available time slots', err);
      return { error: true };
    }
  };

const processData = (timeslots) => {
  timeslots.sort(compareTimeslot);

  const availableDays = [];
  if (timeslots.length === 0) {
    return availableDays;
  }
  let date = dayjs(timeslots[0].Start);
  // TODO: Fix the bug in the backend that treat timeslots as available even if they've already started.
  if (date.diff(dayjs(), 'minute') < 5) {
    timeslots.shift();
  }
  let timeslotsPerDay = [];
  timeslots.forEach((timeslot) => {
    const nextDate = dayjs(timeslot.Start);
    if (
      nextDate.get('year') > date.get('year') ||
      nextDate.dayOfYear() > date.dayOfYear()
    ) {
      availableDays.push({
        date,
        timeslots: timeslotsPerDay,
      });
      date = nextDate;
      timeslotsPerDay = [];
    }
    timeslotsPerDay.push({ start: nextDate, end: dayjs(timeslot.End) });
  });

  availableDays.push({
    date,
    timeslots: timeslotsPerDay,
  });

  return availableDays;
};

const compareTimeslot = (timeslot, otherTimeslot) => {
  const time = dayjs(timeslot.Start);
  const otherTime = dayjs(otherTimeslot.Start);
  if (time.isSame(otherTime)) {
    return 0;
  }
  const timeIsBeforeOtherTime = time.isBefore(otherTime);
  return timeIsBeforeOtherTime ? -1 : 1;
};
