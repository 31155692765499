import { ThemeOptions } from '@mui/material/styles';

const overrides: ThemeOptions['components'] = {
  MuiSkeleton: {
    styleOverrides: {
      root: ({ ownerState: { elevation }, theme }) => {
        return {
          '&.MuiSkeleton-rounded': {
            borderRadius: '0.5rem',
          },
        };
      },
    },
  },
};

const skeletonOverrides = overrides?.MuiSkeleton;
export default skeletonOverrides;
