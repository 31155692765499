// initialize dayJS
import '../common/dateConfig';

import { setPersistentTokenStore } from '@mindoktor/patient-app/api/token';
import React, { useEffect, useState } from 'react';
import { Provider as _ReduxProvider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import { autoRehydrate, createTransform, persistStore } from 'redux-persist';
import reduxThunk from 'redux-thunk';

import apiMiddleware from '../state/api/middleware';
import { APP_TYPING } from '../state/app/actions';
import rootReducer from '../state/configureReducer';
import { FORMULARY_UPDATE } from '../state/formulary/types';
import messagingMiddleware from '../state/messaging/middleware';
import notificationsMiddleware from '../state/notifications/middleware';
import { ROUTING_OPENED } from '../state/routing/actions';
import survey2Middleware from '../state/survey2/middleware';
import appMiddleware from './app/middleware';
import authMiddleware from './auth/middleware';
import getFakeWebTokenStore from './auth/webTokenStore';
import casesNavigation from './cases/navigation';
import childrenNavigation from './children/navigation';
import contactInfoVerificationNavigation from './contact_info_verification/navigation';
import contextualHintsMiddleware from './contextual_hints/middleware';
import healthProfileNavigation from './healthprofile/navigation';
import helpCenterIdentityProtection from './helpcenter/navigation';
import inquisitionsNavigation from './inquisitions/navigation';
import loginNavigation from './login/navigation';
import menuNavigation from './menu/navigation';
import paymentNavigation from './payment/navigation';
import questionnaireMiddleware from './questionnaire/middleware';
import routingMiddleware from './routing/middleware';
import trackingMiddleware from './tracking/middleware';
import userSurveyNavigation from './user_survey/navigation';
import vaccinationBookNavigation from './vaccination_book/navigation';
import videochatNavigation from './videochat/navigation';

import { loadHostConfig } from './utils/host';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const middlewares = [
  reduxThunk,
  trackingMiddleware,
  notificationsMiddleware,
  apiMiddleware({
    refreshTokenOn: [APP_TYPING, FORMULARY_UPDATE, ROUTING_OPENED],
  }),
  appMiddleware,
  authMiddleware,
  routingMiddleware(
    casesNavigation,
    childrenNavigation,
    inquisitionsNavigation,
    helpCenterIdentityProtection,
    healthProfileNavigation,
    loginNavigation,
    menuNavigation,
    paymentNavigation,
    contactInfoVerificationNavigation,
    userSurveyNavigation,
    vaccinationBookNavigation,
    videochatNavigation
  ),
  survey2Middleware,
  contextualHintsMiddleware,
  messagingMiddleware,
  questionnaireMiddleware,
];

setPersistentTokenStore(getFakeWebTokenStore());

// if REDUX_DEVTOOLS is running, feed it with info.
const composeEnhancers =
  (window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const enhancers = composeEnhancers(applyMiddleware(...middlewares));
const store = createStore(rootReducer, undefined, enhancers);

const persists = [
  {
    name: 'intl',
    keys: ['currentLocale'],
  },
  {
    name: 'notifications',
    keys: ['videoWarningDismissed'],
  },
  {
    name: 'identification',
    keys: ['failed'],
  },
];

const persistor = persistStore(
  store,
  {
    whitelist: persists.map(({ name }) => name),
    transforms: persists.map(({ name, keys }) =>
      createTransform(
        (inboundState) => {
          const data: Record<string, unknown> = {};

          keys.forEach((key) => (data[key] = inboundState[key]));

          return data;
        },
        (outboundState) => {
          const data: Record<string, unknown> = {};

          keys.forEach((key) => (data[key] = outboundState[key]));

          return data;
        },
        { whitelist: [name] }
      )
    ),
  },
  autoRehydrate()
);

const ReduxProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (loaded) {
      return;
    }

    loadHostConfig().then(({ error }) => {
      if (error) {
        console.error('Failed to load host config.');
        setLoaded(false);
        return;
      }
      setLoaded(true);
    });
  }, []);

  if (!loaded) return null;

  return (
    <_ReduxProvider
      store={store}
      // @ts-ignore Likely bad typing from redux-persist https://github.com/rt2zz/redux-persist/issues/1140
      persistor={persistor}
    >
      {children}
    </_ReduxProvider>
  );
};

export default ReduxProvider;
