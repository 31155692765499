export const readCookieValue = (cookieName: string) => {
  if (
    typeof document === 'undefined' ||
    document.cookie == null ||
    typeof decodeURIComponent === 'undefined'
  ) {
    return undefined;
  }

  const cookies = decodeURIComponent(document.cookie).split(';');

  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];

    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }

    const name = cookieName + '=';
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }

  return undefined;
};

export const setCookie = (name: string, value: string, expires?: string) => {
  const hostname = window.location.hostname;

  // cookieValueDomain is the computed domain of the cookies for this app.
  // Simply the last two part of the domain.
  let cookieValueDomain = hostname;
  // cookieValueExpires set if the expires param exists.
  let cookieValueExpires = '';

  const slicedOriginDomain = hostname.split('.');
  if (slicedOriginDomain.length >= 2) {
    cookieValueDomain =
      slicedOriginDomain[slicedOriginDomain.length - 2] +
      '.' +
      slicedOriginDomain[slicedOriginDomain.length - 1];
  }

  if (expires != null) {
    cookieValueExpires = `expires=${expires};`;
  }

  document.cookie = `${name}=${value}; domain=${cookieValueDomain}; ${cookieValueExpires} secure; path=/`;
};
