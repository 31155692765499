import commonTrackingMiddleware from '../../state/tracking/middleware';
import trackingUserJourney from '../../state/trackingUserJourney/track';

import docly from './docly';

const middleware = ({ dispatch, getState }) => {
  const nextMiddleware = commonTrackingMiddleware({
    isWebApp: true,
    docly,

    // Not implemented for web.
    segment: {
      legacyTrack: async () => {},
      track: async () => {},
      identify: async () => {},
    },
    facebook: {
      logEvent: async () => {},
    },
    trackingUserJourney,
  })({
    dispatch,
    getState,
  });

  return (next) => (action) => {
    const nextAction = nextMiddleware(next)(action);

    return nextAction;
  };
};

export default middleware;
