import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

import { SvgIconComponent as Icon } from '@mindoktor/patient-legacy/images/company/logo_skandia.svg';

const SkandiaIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon inheritViewBox component={Icon} {...props} />
);

export default SkandiaIcon;
