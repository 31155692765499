import { MEDICATIONS_FETCHED } from './actions';
import type { Medication, MedicationsState } from './types';

const initialState: MedicationsState = {
  query: '',
  searchResult: [],
} as unknown as MedicationsState;

export default function medicationReducer(
  state = initialState,
  {
    payload,
    type,
  }: { payload: { filter: string; data: Medication[] }; type: string }
): MedicationsState {
  switch (type) {
    case MEDICATIONS_FETCHED: {
      const newState = { ...state };

      if (payload) {
        // Not sure what this code is supposed to do, what happens if payload.filter isn't set? Where should the data be stored?
        newState[payload.filter || ''] = payload.data;
      }

      return newState as MedicationsState;
    }

    default:
      return state as MedicationsState;
  }
}
