import { getApi, postApi } from '../api/actions';
import { getApiProfile } from '../profile/selectors';
import {
  REFERRAL_LAB_CHOOSE,
  REFERRAL_LAB_CHOOSE_ERROR,
  REFERRAL_LAB_CHOOSE_SUCCESS,
  REFERRAL_LAB_DONE,
  REFERRAL_LAB_DONE_ERROR,
  REFERRAL_LAB_DONE_SUCCESS,
  REFERRAL_LAB_PREVIEW,
  REFERRAL_LAB_PREVIEW_CLEAR,
  REFERRAL_LAB_REQUEST_SUPPORT,
  REFERRAL_LAB_REQUEST_SUPPORT_ERROR,
  REFERRAL_LAB_REQUEST_SUPPORT_SUCCESS,
  REFERRAL_MAP_SCREEN_VIEW,
  REFERRAL_SELFTEST_CONFIRM,
  REFERRAL_SELFTEST_CONFIRM_ERROR,
  REFERRAL_SELFTEST_CONFIRM_SUCCESS,
  REFERRAL_SELFTEST_SENT,
  REFERRAL_SELFTEST_SENT_ERROR,
  REFERRAL_SELFTEST_SENT_SUCCESS,
  REFERRALLABS_REQUEST,
  REFERRALLABS_REQUEST_ERROR,
  REFERRALLABS_REQUEST_SUCCESS,
  REFERRALS_FETCH_SHARED_RESULTS,
  REFERRALS_FETCH_SHARED_RESULTS_ERROR,
  REFERRALS_FETCH_SHARED_RESULTS_SUCCESS,
  REFERRALS_SET_IDS,
  USER_REGION_CLEAR,
  USER_REGION_SET,
  USER_REGION_SET_ERROR,
  USER_REGION_SET_SUCCESS,
} from './actionTypes';
import { getPreviewedLab, getReferralId } from './selectors';
import { decorateLabs, getRegionWithLabs } from './utils';

export const sendLabRequestSupport = (message) => async (dispatch) => {
  dispatch({
    type: REFERRAL_LAB_REQUEST_SUPPORT,
  });

  const { error } = await dispatch(
    postApi('/api/v1/user/requestlablocation', { message })
  );

  dispatch({
    type: error
      ? REFERRAL_LAB_REQUEST_SUPPORT_ERROR
      : REFERRAL_LAB_REQUEST_SUPPORT_SUCCESS,
  });

  return { error };
};

/**
 * This action could potentially be replaced once we have redux actions
 * for navigational stuff.
 */
export const signalViewReferralMapScreen = () => ({
  type: REFERRAL_MAP_SCREEN_VIEW,
});

export function loadReferralLabs({ caseId, messageId }) {
  return async (dispatch) => {
    dispatch({
      type: REFERRALLABS_REQUEST,
    });

    const { json, error } = await dispatch(
      getApi(
        `/api/v1/cases/${caseId}/referrals?id=${messageId}&request=location`
      )
    );
    if (error) {
      dispatch({
        type: REFERRALLABS_REQUEST_ERROR,
        payload: { error },
      });
      return { error };
    }

    const labs = decorateLabs(json);

    dispatch({
      type: REFERRALLABS_REQUEST_SUCCESS,
      payload: { labs },
    });

    return { error };
  };
}

export function centerMapOnPreviewedLab(options) {
  return (dispatch, getState) => {
    dispatch({
      type: USER_REGION_SET,
    });

    const state = getState();
    const lab = getPreviewedLab(state);
    const delta = options && options.zoomIn && 0.05;

    if (lab && lab.lng && lab.lat) {
      const region = {
        longitude: lab.lng,
        latitude: lab.lat,
        longitudeDelta: delta,
        latitudeDelta: delta,
      };
      dispatch({
        type: USER_REGION_SET_SUCCESS,
        payload: { region },
      });
    } else {
      dispatch({
        type: USER_REGION_SET_ERROR,
      });
    }
  };
}

export function previewReferralLab({ labId }) {
  return {
    type: REFERRAL_LAB_PREVIEW,
    payload: { labId },
  };
}

export function clearPreviewReferralLab() {
  return {
    type: REFERRAL_LAB_PREVIEW_CLEAR,
  };
}

export function chooseReferralLab({ caseId, messageId, labId }) {
  return async (dispatch) => {
    dispatch({
      type: REFERRAL_LAB_CHOOSE,
      payload: { caseId, messageId, labId },
    });

    const payload = {
      answer: 'location',
      id: messageId,
      labId: parseInt(labId, 10),
    };

    const { error } = await dispatch(
      postApi(`/api/v1/cases/${caseId}/referrals`, payload)
    );

    if (error) {
      dispatch({
        type: REFERRAL_LAB_CHOOSE_ERROR,
        payload: { error },
      });
      return false;
    }

    dispatch({
      type: REFERRAL_LAB_CHOOSE_SUCCESS,
      payload: { caseId, messageId, labId },
    });
    return true;
  };
}

export function referralLabDone({ caseId, messageId, labId }) {
  return async (dispatch) => {
    dispatch({
      type: REFERRAL_LAB_DONE,
      payload: { caseId, messageId, labId },
    });

    const payload = {
      answer: 'senttolab',
      id: messageId,
    };

    const { error } = await dispatch(
      postApi(`/api/v1/cases/${caseId}/referrals`, payload)
    );

    if (error) {
      dispatch({
        type: REFERRAL_LAB_DONE_ERROR,
        payload: { error },
      });
      return false;
    }

    dispatch({
      type: REFERRAL_LAB_DONE_SUCCESS,
      payload: { caseId, messageId, labId },
    });
    return true;
  };
}

export function selfTestConfirm({
  caseId,
  messageId,
  cId,
  deliveryAddress = null,
}) {
  return async (dispatch) => {
    dispatch({
      type: REFERRAL_SELFTEST_CONFIRM,
      payload: { caseId, messageId, cId, deliveryAddress },
    });

    const payload = {
      answer: 'orderconfirmed',
      id: messageId,
      deliveryAddress,
    };

    const { error } = await dispatch(
      postApi(`/api/v1/cases/${caseId}/referrals`, payload)
    );

    if (error) {
      dispatch({
        type: REFERRAL_SELFTEST_CONFIRM_ERROR,
        payload: { error },
      });
      return false;
    }

    dispatch({
      type: REFERRAL_SELFTEST_CONFIRM_SUCCESS,
      payload: { caseId, messageId, cId, deliveryAddress },
    });
    return true;
  };
}

export function selfTestSend({ caseId, messageId, cId }) {
  return async (dispatch) => {
    const payload = {
      answer: 'senttolab',
      id: messageId,
    };

    dispatch({
      type: REFERRAL_SELFTEST_SENT,
      payload: { caseId, messageId, cId },
    });

    const { error } = await dispatch(
      postApi(`/api/v1/cases/${caseId}/referrals`, payload)
    );

    if (error) {
      dispatch({
        type: REFERRAL_SELFTEST_SENT_ERROR,
        payload: { error },
      });
      return false;
    }

    dispatch({
      type: REFERRAL_SELFTEST_SENT_SUCCESS,
      payload: { caseId, messageId, cId },
    });
    return true;
  };
}

export function centerMapOnUser() {
  return async (dispatch, getState) => {
    dispatch({
      type: USER_REGION_SET,
      payload: { enableHighAccuracy: true },
    });

    let position;

    if (
      typeof navigator !== 'undefined' &&
      navigator.product === 'ReactNative'
    ) {
      const Geolocation =
        require('@react-native-community/geolocation').default;
      Geolocation.setRNConfiguration({
        skipPermissionRequests: false,
        authorizationLevel: 'whenInUse',
      });

      function getCurrentPosition({ enableHighAccuracy }) {
        return new Promise((resolve, reject) => {
          Geolocation.getCurrentPosition(
            (position) => resolve(position),
            (error) => reject(error),
            {
              enableHighAccuracy,
              timeout: 30000,
              maximumAge: 10000,
            }
          );
        });
      }
      try {
        // I'm in react-native
        position = await getCurrentPosition({
          enableHighAccuracy: true,
        });
      } catch (_error) {
        // try again with low accuracy (typically indoor gps)
        dispatch({
          type: USER_REGION_SET,
          payload: { enableHighAccuracy: false },
        });

        try {
          position = await getCurrentPosition({
            enableHighAccuracy: false,
          });
        } catch (error) {
          // failed even in low accuracy, return error.
          dispatch({
            type: USER_REGION_SET_ERROR,
            payload: { error },
          });
          return false;
        }
      }
    }

    // find a region that contains user position and labs
    const { region, error: regionError } = getRegionWithLabs(
      position,
      getState()
    );

    if (regionError) {
      dispatch({
        type: USER_REGION_SET_ERROR,
        payload: { error: regionError },
      });
      return false;
    }

    dispatch({
      type: USER_REGION_SET_SUCCESS,
      payload: { region },
    });

    return true;
  };
}

export function centerMapOnUserHome() {
  return async (dispatch, getState) => {
    dispatch({
      type: USER_REGION_SET,
    });

    const state = getState();
    const { lat: latitude, lng: longitude } = getApiProfile(state);
    const position = {};
    position.coords = { latitude, longitude };

    // find a region that contains user position and labs
    const { region, error: regionError } = getRegionWithLabs(position, state);

    if (regionError) {
      dispatch({
        type: USER_REGION_SET_ERROR,
        payload: { error: regionError },
      });
      return false;
    }

    dispatch({
      type: USER_REGION_SET_SUCCESS,
      payload: { region },
    });

    return true;
  };
}

export function clearRegion() {
  return {
    type: USER_REGION_CLEAR,
  };
}

export const setIds = (caseId, referralId) => ({
  type: REFERRALS_SET_IDS,
  payload: { caseId, referralId },
});

export const fetchSharedResults = () => async (dispatch, getState) => {
  const referralId = getReferralId(getState());

  if (!referralId) throw new Error('No referral id given');

  dispatch({
    type: REFERRALS_FETCH_SHARED_RESULTS,
    payload: { referralId },
  });

  const { json, error } = await dispatch(
    getApi(`/api/v1/referrals/${referralId}/sharedresults`)
  );

  if (error) {
    dispatch({
      type: REFERRALS_FETCH_SHARED_RESULTS_ERROR,
      payload: { error },
    });
    return;
  }
  dispatch({
    type: REFERRALS_FETCH_SHARED_RESULTS_SUCCESS,
    payload: { referralId, data: json },
  });
};
