import React, { useRef } from 'react';

import { CircularProgress, Stack } from '@mui/material';

import BottomSheet from '@mindoktor/pulse/src/components/BottomSheet/BottomSheet';
import BottomSheetContent from '@mindoktor/pulse/src/components/BottomSheetContent/web';
import BottomSheetHeader from '@mindoktor/pulse/src/components/BottomSheetHeader/web';
import ScrollViewWithScrollDownButton from '@mindoktor/pulse/src/components/ScrollViewWithScrollDownButton/web';
import { circularProgressLarge } from '@mindoktor/pulse/src/styles/sizes';

import Markdown from '@mindoktor/patient-legacy/src/web/components/markdown';

import { useTranslation } from '@mindoktor/patient-app/localization/hooks/useTranslation';

import { isMobile } from '../../../../utils/device/web';
import { usePrivacyDocumentApi } from '../../../hooks/usePrivacyDocumentApi';

interface PrivacyProps {
  isOpen: boolean;
  onClose: () => void;
}

const PrivacySheet: React.FC<PrivacyProps> = ({ isOpen, onClose }) => {
  const t = useTranslation('common');
  const { data: privacy, isLoading } = usePrivacyDocumentApi(isOpen);
  const contentRef = useRef(null);

  return (
    <BottomSheet isOpen={isOpen} onClose={onClose}>
      <BottomSheetHeader ariaLabelForClose={t('close')} onClose={onClose} />
      <BottomSheetContent>
        <Stack height={'100%'} position={'relative'}>
          <ScrollViewWithScrollDownButton
            contentRef={contentRef}
            flexGrow={1}
            buttonOffset={isMobile ? 50 : 0}
          >
            <Stack id="privacy-content" ref={contentRef} flexGrow={1}>
              {isLoading ? (
                <Stack>
                  <CircularProgress
                    color="primary"
                    size={circularProgressLarge}
                    sx={{ alignSelf: 'center' }}
                  />
                </Stack>
              ) : (
                <Markdown source={privacy?.content} />
              )}
            </Stack>
          </ScrollViewWithScrollDownButton>
        </Stack>
      </BottomSheetContent>
    </BottomSheet>
  );
};

export default PrivacySheet;
