// initialize dayJS
import '../common/dateConfig';

import React from 'react';
import App from './app/app';

import { unregisterServiceWorker } from './utils/worker';

const Main = () => <App />;

// Load the cookie consent manager except on the confirm pages
if (!/confirm/.test(window.location.href)) {
  (() => {
    const script = document.createElement('script');
    script.async = true;
    script.src =
      'https://static.mindoktor.se/cookie-consent-manager/dist/cookie-consent-manager.js';
    document.head.appendChild(script);
  })();
}

unregisterServiceWorker();

export default Main;
