import { routes } from '../../state/routing';

import { navigate } from '../routing';

import SupportScreen from './HiddenIdentitySupportScreen';

const navigation = {
  [routes.IDENTITY_PROTECTION_SUPPORT]: () => navigate(SupportScreen),
};

export default navigation;
