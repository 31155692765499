// import type { Action } from '../types';

export const DRAFTS_LOAD = 'DRAFTS_LOAD';
export const DRAFTS_LOAD_SUCCESS = 'DRAFTS_LOAD_SUCCESS';
export const DRAFTS_LOAD_ERROR = 'DRAFTS_LOAD_ERROR';
export const DRAFT_DELETE = 'DRAFT_DELETE';
export const DRAFT_DELETE_SUCCESS = 'DRAFT_DELETE_SUCCESS';
export const DRAFT_DELETE_ERROR = 'DRAFT_DELETE_ERROR';

// export type Draft = {
//   patientName: string,
//   language: string,
//   created: string,
//   answers: any,
//   formularyId: string,
//   formularyVersion: number,
//   entrywayId: number,
//   revisitId: number,
//   dynamicCode?: string,
//   index: number,
//   isPatientSameAsUser: boolean,
//   isCompanySameAsProfileCompany: boolean,
//   companyName: string,
//   patientUUID: string,
//   childUuid: string,
//   displayTitle: string,
//   inquisitionId?: string,
// };

// export type DraftsState = {
//   draftsByEntrywayId: { [number]: string[] },
//   draftCount: number,
//   draftById: { [string]: Draft },
//   draftIds: Array<string>,
//   hasLoaded: boolean,
// };

// export type DraftsLoadAction = {
//   type: 'DRAFTS_LOAD',
// } & Action;

// export type DraftsLoadSuccessAction = {
//   type: 'DRAFTS_LOAD_SUCCESS',
//   payload: {
//     drafts: any[],
//   },
// } & Action;

// export type DraftsLoadErrorAction = {
//   type: 'DRAFTS_LOAD_ERROR',
// } & Action;

// export type DraftDeleteAction = {
//   type: 'DRAFT_DELETE',
// } & Action;

// export type DraftDeleteSuccessAction = {
//   type: 'DRAFT_DELETE_SUCCESS',
// } & Action;

// export type DraftDeleteErrorAction = {
//   type: 'DRAFT_DELETE_ERROR',
// } & Action;
