import { caseCreated } from '../cases/actions';
import { getInformedConsentBoolByEntrywayId } from '../consents/selectors';
import {
  clearDelayedDraft,
  deleteDraft,
  loadDrafts,
  saveDraft,
} from '../drafts/actions';
import { getGuideByEntrywayId } from '../guides/selectors';
import { track } from '../tracking/actions';
import { getDraft, getFormulary, postCase } from './api';
import { getFormularyState, getQuestionnaireData } from './selectors';
import {
  FORMULARY_ABORT,
  FORMULARY_ADD,
  FORMULARY_CLEAR,
  FORMULARY_LOAD,
  FORMULARY_LOAD_SUCCESS,
  FORMULARY_NEXT,
  FORMULARY_POST,
  FORMULARY_POST_ERROR,
  FORMULARY_POST_SUCCESS,
  FORMULARY_PREVIOUS,
  FORMULARY_UPDATE,
  QUESTION_SHOWN,
  QUESTIONNAIRE_UPDATE,
} from './types';
import { toFormularyKey } from './utils';

/*
: {
  entrywayId: number,
  childUuid?: string,
  revisitId?: number,

  formulary?: Formulary,
  formularyId?: string,
  formularyVersion?: string,
  answers?: Object,
  index?: string,

  dynamicCode?: string,

  skipDrafts?: boolean,
  skipAnswered?: boolean,
}
*/

// Only used by tracking middleware, attow.
// If some other functionality is required to build around this
// action consider a bigger refactor.
export const questionShown = ({ formularyKey }) => ({
  type: QUESTION_SHOWN,
  payload: { formularyKey },
});

export function loadFormulary({
  entrywayId,
  childUuid,
  skipDrafts,
  skipAnswered,
  ...rest
}) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: FORMULARY_LOAD, payload: rest });

      let {
        formulary,
        formularyId,
        formularyVersion,
        answers,
        index,
        dynamicCode,
        revisitId,
      } = rest;

      if (formulary) {
        formularyId = formulary.id;
        formularyVersion = formulary.version;
      }

      const formularyKey = toFormularyKey({
        entrywayId,
        formularyId,
        formularyVersion,
        childUuid,
      });

      const state = getState();
      const formularyState = getFormularyState(state, formularyKey);

      // Use previous formulary state unless overridden.
      if (formularyState) {
        formulary = formulary || formularyState.formulary;
        answers = answers || formularyState.answers;
        index =
          index ||
          (formularyState.current ? formularyState.current.index : undefined);
      }

      // If a formulary object wasn't in state, fetch from backend along with a draft.
      if (!formulary) {
        const formularyResponse = await dispatch(
          getFormulary(String(formularyId), String(formularyVersion))
        );

        if (formularyResponse.error) return { error: true };

        formulary = formularyResponse.formulary;

        const draftResponse = await dispatch(getDraft(formulary, childUuid));

        if (draftResponse.error) return { error: true };

        const draft = draftResponse.draft;

        answers = { ...draft.answers, ...(answers || {}) };
        index = index || draft.index;

        dynamicCode = dynamicCode || draft.dynamicCode;
      }

      dispatch({
        type: FORMULARY_LOAD_SUCCESS,
        payload: {
          formularyKey,
          entrywayId,
          formulary,
          answers,
          index,
          skipDrafts,
          skipAnswered,
          childUuid,
          revisitId,
          dynamicCode,
        },
      });

      return {
        formularyKey,
        formularyState: getFormularyState(getState(), formularyKey),
      };
    } catch (err) {
      console.error(err);
      return { error: true };
    }
  };
}

export function loadFormularyByKey({
  formularyKey,
  index,
  childUuid,
  skipOptionalQuestions,
}) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: FORMULARY_LOAD, payload: { index } });

      let formulary, answers, currentIndex;

      const state = getState();
      const formularyState = getFormularyState(state, formularyKey);

      // Use previous formulary state unless overridden.
      if (formularyState) {
        formulary = formulary || formularyState.formulary;
        answers = answers || formularyState.answers;
        currentIndex =
          index ||
          (formularyState.current ? formularyState.current.index : undefined);
      } else {
        console.error('formulary state not found for key ', formularyKey);
        return { error: true };
      }

      dispatch({
        type: FORMULARY_LOAD_SUCCESS,
        payload: {
          formularyKey,
          entrywayId: formularyState.entrywayId,
          formulary,
          answers,
          index: currentIndex,
          skipDrafts: true,
          skipAnswered: true,
          childUuid,
          skipOptionalQuestions,
        },
      });

      return {
        formularyKey,
        formularyState: getFormularyState(getState(), formularyKey),
      };
    } catch (err) {
      console.error(err);
      return { error: true };
    }
  };
}

/**
 * @typedef UpdateFormularyOptions
 * @prop {string} formularyKey
 * @prop {Record<string, unknown>} answers
 * @prop {number=} index
 */

export function updateFormulary(
  /** @type UpdateFormularyOptions **/ { formularyKey, answers, index }
) {
  return async (dispatch, getState) => {
    if (!answers || !Object.keys(answers).length) return;

    const formularyState = getFormularyState(getState(), formularyKey);

    if (!formularyState) return;

    dispatch({
      type: FORMULARY_UPDATE,
      payload: { formularyKey, answers, index, type: 'formulary' },
    });

    dispatch(saveDraft({ formularyKey }));
  };
}

export function updateQuestionnaire({ formularyKey, answers }) {
  return async (dispatch, getState) => {
    // Nothing to update.
    if (!answers || !Object.keys(answers).length) return;

    const formularyState = getQuestionnaireData(getState(), formularyKey);

    if (!formularyState) return;

    dispatch({
      type: QUESTIONNAIRE_UPDATE,
      payload: {
        formularyKey,
        answers,
        type: 'questionnaire',
      },
    });
  };
}

export function previousQuestion({ formularyKey }) {
  return async (dispatch, getState) => {
    const formularyState = getFormularyState(getState(), formularyKey);

    if (!formularyState) return;

    const { entrywayId, current } = formularyState;

    dispatch({
      type: FORMULARY_PREVIOUS,
      payload: { formularyKey },
      meta: {
        track: {
          event: 'GuideFormulary.PreviousQuestion',
          receiver: ['product'],
          properties: { entrywayId, currentQuestion: current.id },
        },
      },
    });

    dispatch(saveDraft({ formularyKey }));
  };
}

export function nextQuestion({ formularyKey }) {
  return async (dispatch, getState) => {
    const formularyState = getFormularyState(getState(), formularyKey);

    if (!formularyState) return;

    const { current, errors } = formularyState;

    if (errors[current.id]) return;

    dispatch({ type: FORMULARY_NEXT, payload: { formularyKey } });

    dispatch(saveDraft({ formularyKey }));
  };
}

export function clearFormulary({ formularyKey }) {
  return {
    type: FORMULARY_CLEAR,
    payload: { formularyKey },
  };
}

export function abortFormulary({ formularyKey, externalId }) {
  return async (dispatch, getState) => {
    const state = getState();
    const formularyState = getFormularyState(getState(), formularyKey);

    if (!formularyState) return;

    const { entrywayId, childUuid, formulary, answers, current, revisitId } =
      formularyState;

    dispatch(
      track('GuideFormulary.Abort', {
        entrywayId,
        currentQuestion: current.id,
      })
    );

    clearDelayedDraft({ formularyKey });
    dispatch(clearFormulary({ formularyKey }));

    await dispatch(
      deleteDraft({
        entrywayId,
        childUuid: childUuid || '',
        formularyId: formulary.id || '',
        formularyVersion: formulary.version || '',
      })
    );

    const informedConsent = getInformedConsentBoolByEntrywayId(
      entrywayId,
      state
    );

    await dispatch(
      postCase(
        entrywayId,
        formulary.id,
        formulary.version,
        answers,
        childUuid,
        revisitId,
        {
          externalId,
          informedConsent,
        }
      )
    );

    dispatch({
      type: FORMULARY_ABORT,
      payload: {
        entrywayId,
        formularyId: formulary.id,
        currentQuestion: current.id,
      },
    });
  };
}

/*
: {
  formularyKey: string,
  journalConsent?: boolean,
  externalId?: string,
  paymentMethod?: string,
}
*/
export function createCase({ formularyKey, journalConsent, externalId }) {
  return async (dispatch, getState) => {
    const state = getState();

    const formularyState = getFormularyState(state, formularyKey);

    if (!formularyState) return { error: true };

    if (formularyState.posting) return { posting: true };

    dispatch({ type: FORMULARY_POST, payload: { formularyKey } });

    const {
      entrywayId,
      formulary,
      answers,
      childUuid,
      revisitId,
      dynamicCode,
    } = formularyState;

    const informedConsent = getInformedConsentBoolByEntrywayId(
      entrywayId,
      state
    );

    const { caseId, paymentRequired, error, message } = await dispatch(
      postCase(
        entrywayId,
        formulary.id,
        formulary.version,
        answers,
        childUuid,
        revisitId,
        {
          dynamicCode,
          journalConsent,
          informedConsent,
          externalId,
        }
      )
    );

    if (error) {
      dispatch({
        type: FORMULARY_POST_ERROR,
        payload: { formularyKey, revisitId, message },
      });
      return { error, message };
    }

    dispatch({
      type: FORMULARY_POST_SUCCESS,
      payload: { formularyKey, revisitId },
    });

    const { allowFreePass } = getGuideByEntrywayId(state, entrywayId) || {};
    const freePassNumber = state.freePass.number;

    let paymentMethod;
    if (allowFreePass && freePassNumber) {
      paymentMethod = 'frikort';
    } else if (!paymentRequired) {
      paymentMethod = 'free';
    } else {
      paymentMethod = 'invoice';
    }

    dispatch(
      caseCreated(caseId, { entrywayId, paymentMethod, paymentRequired })
    );

    dispatch(clearFormulary({ formularyKey }));

    dispatch(loadDrafts());

    return { caseId, paymentMethod, paymentRequired };
  };
}

/*
: {
  type: 'questionnaire' | 'formulary',
  formulary: Formulary,
  answers: Answers,
  key: string,
}
*/
export const addFormulary = ({ type, formulary, answers, key }) => {
  if (type === 'formulary') {
    throw new Error(
      FORMULARY_ADD + ' is not implemented for formularies of type "formulary"'
    );
  }

  return {
    type: FORMULARY_ADD,
    payload: {
      type: 'questionnaire',
      formulary,
      answers,
      key,
    },
  };
};
