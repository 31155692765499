import React, { useState, useEffect } from 'react';
import { _t } from '@mindoktor/patient-legacy/i18n';
import { makeStyles } from '@material-ui/styles';

import regions from '../../../state/freepass/regions';
import {
  validateFreePassNumber,
  validateFreePassExpireDate,
  validateFreePassIssuer,
  translateErrorMessage,
} from '../../../state/freepass/utils';
import { freePassStatuses } from '../../../state/freepass/types';
// import type {
//   FreePassState,
//   FreePassEditData,
// } from '../../../state/freepass/types';

import FreePassInfoModal from './freepass_info_modal';
import { showModal } from '../../routing';
import Link from '../../components/link';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HelpIcon from '@material-ui/icons/Help';

const regionOptions = regions.map((region) => ({
  label: region.short,
  value: region.region,
}));

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: 24,
  },

  formControl: {
    width: 'inherit',
    marginBottom: 24,
  },

  infoLink: {
    marginBottom: 48,
  },
  infoLinkIcon: {
    marginLeft: 10,
    marginBottom: -8,
    width: 32,
    height: 32,
  },

  select: {
    height: 32,
    marginTop: -16,
    marginBottom: -16,
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },

  selectInputLabel: {
    transform: 'translate(0px, -24px) scale(0.75)!important',
  },

  input: {
    height: 36,
    marginTop: 30,
  },
});

// type Props = {
//   freePass?: FreePassState,
//   onChange: (data: FreePassEditData) => void,
//   classes?: any,
// };

const FreePassForm = (props) => {
  const { freePass, onChange } = props;
  const [freePassIssuer, setFreePassIssuer] = useState('');
  const [freePassNumber, setFreePassNumber] = useState('');
  const [freePassExpires, setFreePassExpires] = useState('');

  const [expiresError, setExpiresError] = useState('');
  const [issuerError, setIssuerError] = useState('');
  const [numberError, setNumberError] = useState('');

  const [isChanged, setIsChanged] = useState(false);

  const classes = useStyles(props);

  const showFreePassInfoModal = () => showModal(FreePassInfoModal);

  const handleChange = (type, value) => {
    switch (type) {
      case 'freePassIssuer':
        setFreePassIssuer(value);
        setIssuerError(translateErrorMessage(validateFreePassIssuer(value)));
        break;
      case 'freePassNumber':
        setFreePassNumber(value);
        setNumberError(translateErrorMessage(validateFreePassNumber(value)));
        break;
      case 'freePassExpires':
        setFreePassExpires(value);
        setExpiresError(
          translateErrorMessage(validateFreePassExpireDate(value))
        );
        break;
      default:
        console.error('Non existing freepass change type');
    }
    setIsChanged(true);
  };

  useEffect(() => {
    if (!!freePass) {
      // if there are error in the freepass sent from the server
      // we update the corresponding error
      if (freePass.hasError) {
        switch (freePass.errorMessage) {
          case 'LNGFreePassInvalidIssuer':
            setIssuerError(_t('freePass.invalidIssuer'));
            break;
          case 'LNGFreePassInvalidNumber':
            setNumberError(_t('freePass.invalidNumber'));
            break;
          case 'LNGFreePassInvalidExpires':
            setExpiresError(_t('freePass.invalidExpires'));
            break;
          case 'LNGFreePassExpired':
            setExpiresError(_t('freePass.expired'));
            break;
          default:
            break;
        }
      } else if (freePass.status === freePassStatuses.FINAL) {
        // ...otherwise we populate the form with the values, but ONLY for cards
        // with status final.
        setFreePassIssuer(freePass.issuer || '');
        setFreePassNumber(freePass.number || '');
        setFreePassExpires(freePass.expires || '');
      }
    }
  }, [freePass]);

  const isFreePassValid = () => {
    // no errors and no empty fields
    return (
      !expiresError &&
      !issuerError &&
      !numberError &&
      !!freePassIssuer &&
      !!freePassNumber &&
      !!freePassExpires
    );
  };

  useEffect(() => {
    onChange({
      issuer: freePassIssuer,
      number: freePassNumber,
      expires: freePassExpires,
      isValid: isFreePassValid(),
      isChanged: isChanged
        ? freePassIssuer !== freePass?.issuer ||
          freePassNumber !== freePass?.number ||
          freePassExpires !== freePass?.expires
        : false,
    });
  }, [freePassIssuer, freePassNumber, freePassExpires]);

  return (
    <div className={classes.root}>
      <div className={classes.infoLink}>
        <Link onClick={showFreePassInfoModal}>
          {_t('faq.freePass.id.title')}
          <HelpIcon className={classes.infoLinkIcon} />
        </Link>
      </div>

      {/* Region selector */}
      <FormControl className={classes.formControl}>
        <InputLabel
          htmlFor="freePassIssuer"
          variant="outlined"
          classes={{ shrink: classes.selectInputLabel }}
          shrink={true}
        >
          {_t('freePass.issuedByCounty')}
        </InputLabel>
        <Select
          native
          variant="outlined"
          classes={{ select: classes.select }}
          value={freePassIssuer}
          onChange={(e) => handleChange('freePassIssuer', e.target.value)}
          IconComponent={ExpandMoreIcon}
          inputProps={{
            name: _t('freePass.issuedByCounty'),
            id: 'freePassIssuer',
          }}
          disabled={!freePass.editable}
        >
          <option aria-label={_t('common.notSelected')} value="">
            {_t('common.notSelected')}
          </option>
          {regionOptions.map((option, i) => (
            <option key={i} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>

        <FormHelperText className={classes.error}>
          {!!isChanged && issuerError}
        </FormHelperText>
      </FormControl>

      {/* Freepass number */}
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="freePassNumber" variant="standard" shrink={true}>
          {_t('freePass.freePassId')}
        </InputLabel>
        <TextField
          id="freePassNumber"
          placeholder={_t('freePass.freePassIdPlaceholder')}
          InputProps={{
            className: classes.input,
          }}
          value={freePassNumber}
          onChange={(e) => handleChange('freePassNumber', e.target.value)}
          disabled={!freePass.editable}
        />
        <FormHelperText className={classes.error}>
          {!!isChanged && numberError}
        </FormHelperText>
      </FormControl>

      {/* Freepass exipry date */}
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="freePassExpires" variant="standard" shrink={true}>
          {_t('freePass.validity')}
        </InputLabel>
        <TextField
          id="freePassExpires"
          placeholder={_t('freePass.validityPlaceholder')}
          InputProps={{
            className: classes.input,
          }}
          value={freePassExpires}
          onChange={(e) => handleChange('freePassExpires', e.target.value)}
          disabled={!freePass.editable}
        />
        <FormHelperText className={classes.error}>
          {!!isChanged && expiresError}
        </FormHelperText>
      </FormControl>
    </div>
  );
};

export default FreePassForm;
