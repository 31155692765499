import { useQueryClient } from '@tanstack/react-query';

import { useSubscription } from '@mindoktor/realtime-messaging/hooks/useSubscription';

import { CONVERSATION_QUERY_KEY } from './useConversationApi';

export const useConversationSubscriber = (conversationId: number | null) => {
  const queryClient = useQueryClient();
  useSubscription({
    channel: `conversation-messages-${conversationId ?? 'undefined'}`,
    onPublish: () => {
      if (conversationId == null) {
        return;
      }
      queryClient
        .invalidateQueries([conversationId, CONVERSATION_QUERY_KEY])
        .catch((error) => {
          console.error('Could not invalidate data', error);
        });
    },
  });
};
