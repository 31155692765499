import React from 'react';

import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';

import { colors } from '@mindoktor/pulse/src/styles/colors';
import { rem } from '@mindoktor/pulse/src/styles/conversions';

import { CARD_SPACING } from '../../../constants';
import UserCardLayout from '../../layout/UserCardLayout/UserCardLayout';

import ImageAttachment from './ImageAttachment/ImageAttachment';
import { TextMessageCardProps } from './types';

const TextMessageCard: React.FC<TextMessageCardProps> = ({ message }) => {
  return (
    <Stack spacing={rem(CARD_SPACING)}>
      {message.payload.attachments?.map((attachment) => (
        <UserCardLayout
          key={attachment.fileId}
          message={message}
          padding={0}
          backgroundColor={colors.gray[200]}
          fullWidth
        >
          <ImageAttachment key={attachment.fileId} attachment={attachment} />
        </UserCardLayout>
      ))}
      {message.payload.content != null && message.payload.content !== '' && (
        <UserCardLayout message={message}>
          <Typography variant="body1" whiteSpace="pre-wrap">
            {message.payload.content}
          </Typography>
        </UserCardLayout>
      )}
    </Stack>
  );
};

export default TextMessageCard;
