import React, {
  ComponentProps,
  memo,
  useEffect,
  useRef,
  useState,
} from 'react';

import Stack from '@mui/material/Stack';

import { isScrollAtBottom } from '../../../utils/scrollPositioning';
import { ScrollPositionOption } from '../constants';

import ListViewItem from './ListViewItem';

type StackType = typeof Stack;
type StackProps = Omit<ComponentProps<typeof Stack>, 'onScroll'>;
type KeyType = number;

const noop = () => {};

interface OwnProps<ItemType> {
  items: readonly ItemType[] | null | undefined;
  renderItem: (item: ItemType) => ReturnType<React.FC>;
  keyExtractor: (item: ItemType) => KeyType;
  scrollToItemTarget: KeyType | undefined;
  onVisibilityChange?: (item: ItemType, isVisible: boolean) => void;
  onScroll?: (scrollEvent: { isBottom: boolean }) => void;
  onLand?: (landProps: { isBottom: boolean }) => void;
  scrollPositionOption?: ScrollPositionOption;
  positionOffsetOnScroll?: number;
  bottomDetectionPadding?: number;
}

type Props<ItemType> = OwnProps<ItemType> & StackProps;

const ListView = <ItemType,>({
  items,
  renderItem,
  keyExtractor,
  scrollToItemTarget,
  onVisibilityChange,
  scrollPositionOption,
  positionOffsetOnScroll,
  bottomDetectionPadding,
  onScroll,
  onLand,
  ...stackProps
}: Props<ItemType>): ReturnType<React.FC<Props<ItemType>>> => {
  const [scrollOffsetY, setScrollOffsetY] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  // Run on land so we initialize layout properties.
  useEffect(() => {
    if (containerRef?.current == null) {
      return;
    }

    const { scrollTop, scrollHeight, clientHeight } =
      containerRef.current as HTMLElement;

    setScrollOffsetY(scrollTop);
    if (onLand != null) {
      onLand({
        isBottom: isScrollAtBottom(
          scrollTop,
          scrollHeight,
          clientHeight,
          bottomDetectionPadding
        ),
      });
    }
  }, [containerRef]);

  const handleScroll: ComponentProps<StackType>['onScroll'] = ({
    currentTarget,
  }) => {
    const { scrollTop, scrollHeight, clientHeight } = currentTarget;

    setScrollOffsetY(scrollTop);
    if (onScroll != null) {
      onScroll({
        isBottom: isScrollAtBottom(
          scrollTop,
          scrollHeight,
          clientHeight,
          bottomDetectionPadding
        ),
      });
    }
  };

  return (
    <Stack ref={containerRef} onScroll={handleScroll} {...stackProps}>
      {items?.map((item) => (
        <ListViewItem
          key={keyExtractor(item)}
          isScrollTarget={keyExtractor(item) === scrollToItemTarget}
          scrollOffsetY={scrollOffsetY}
          parentElementRef={containerRef}
          onVisibilityChange={
            onVisibilityChange != null
              ? (isVisible) => onVisibilityChange(item, isVisible)
              : noop
          }
          scrollPositionOption={scrollPositionOption}
          positionOffsetOnScroll={positionOffsetOnScroll ?? 0}
        >
          {renderItem(item)}
        </ListViewItem>
      ))}
    </Stack>
  );
};

const MemoisedListView = memo(ListView) as typeof ListView & {
  displayName: string;
};

export default MemoisedListView;
