import { PhotoQuality } from 'react-native-image-picker';

export interface MediaAsset {
  name: string;
  uri: string;
  type: string;
  file?: File;
}

export enum MediaType {
  Photo = 'photo',
  Video = 'video',
}

export interface CameraOptions {
  mediaType: MediaType;

  /**
   * A number between 0 and 1, where 1 is the highest quality.
   * Works only on native.
   */
  quality?: PhotoQuality;

  /**
   * Maximum number of assets that can be selected.
   * On native, this doesn't limit the selection but the number of assets that can be returned.
   */
  limit?: number;
}

export interface MediaPickerOptions {
  library: boolean;
  cameraOptions: CameraOptions;
}
