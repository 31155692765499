import { useEffect } from 'react';

export const useScrollEffect = (handleScroll: () => void) => {
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    // Cleanup effect upon unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
};
