import React from 'react';

import IntroScreen from '@mindoktor/patient-legacy/src/web/guides/intro/screen';
import ListScreen from '@mindoktor/patient-legacy/src/web/guides/list/screen';

import { useParams } from '@mindoktor/patient-app/routing/hooks/useParams/useParams';

const GuidesScreen: React.FC = () => {
  const { entrywayId } = useParams();

  // Render ListScreen when "entrywayId" is not actually an entrywayId but adult/child category.
  // Otherwise render IntroScreen with entrywayId.
  const isCategoryRoute = entrywayId == null || isNaN(parseInt(entrywayId));

  if (isCategoryRoute) {
    return <ListScreen />;
  }
  return <IntroScreen />;
};

export default GuidesScreen;
