import { _t } from '@mindoktor/patient-legacy/i18n';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Body1, Body3, Title2 } from '../../../common/components/typography';
import { postApi } from '../../../state/api/actions';
import { contactInformation } from '../../../state/profile/selectors';
import Button from '../../components/button/button';
import { CardWithBody } from '../../components/card';
import TextInput from '../../components/input/text';
import Markdown from '../../components/markdown';

const styles = {
  lead: {
    paddingTop: 10,
  },

  submitted: {
    paddingTop: 10,
  },

  input: {
    display: 'block',
    marginTop: 20,
  },

  disclaimer: {
    marginTop: 6,
    opacity: 0.66,
  },

  button: {
    marginTop: 20,
  },
};

// type Props = {
//   email?: string,

//   style?: any,

//   postApi: typeof postApi,
// };

export class NewsletterCard extends Component {
  state = {
    email: this.props.email,
    submitting: false,
    submitted: false,
  };

  submit = async () => {
    const { email: emailFromProps, postApi } = this.props;
    const { email: emailFromState } = this.state;

    const email = (emailFromState || emailFromProps || '').trim();

    if (!email) return;

    this.setState({ submitting: true });

    const { error } = await postApi('/api/v1/newsletter/signup', {
      email,
      source: 'child',
    });

    this.setState({ submitting: false });

    if (!error) {
      this.setState({ submitted: true });
    }
  };

  onEmailChange = (email) => this.setState({ email });

  render() {
    const { email, style } = this.props;
    const { submitting, submitted } = this.state;

    return (
      <CardWithBody style={style}>
        {submitted ? (
          <Title2>{_t('children.newsletter.title.submitted')}</Title2>
        ) : (
          <Title2>{_t('children.newsletter.title')}</Title2>
        )}
        {submitted ? (
          <Body1 style={styles.submitted}>
            <Markdown source={_t('children.newsletter.submitted')} />
          </Body1>
        ) : (
          <div>
            <Body1 style={styles.lead}>{_t('children.newsletter.lead')}</Body1>

            <TextInput
              initialValue={email}
              placeholder={_t('children.newsletter.emailPlaceholder')}
              style={styles.input}
              onChange={this.onEmailChange}
            />

            <Body3 style={styles.disclaimer}>
              {_t('children.newsletter.disclaimer')}
            </Body3>

            <Button
              disabled={submitting}
              color="black"
              style={styles.button}
              onClick={this.submit}
            >
              {_t('children.newsletter.submit')}
            </Button>
          </div>
        )}
      </CardWithBody>
    );
  }
}

export default connect(
  (state) => {
    const { email } = contactInformation(state);

    return { email };
  },
  { postApi }
)(NewsletterCard);
