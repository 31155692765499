import { _t } from '@mindoktor/patient-legacy/i18n';

export const getErrorMessage = (code) => {
  switch (code) {
    case 1006:
      return _t('videochat.error.connection');
    // return 'Något gick fel med anslutningen. Kontrollera att du är uppkopplad mot internet.';
    case 1500:
      return _t('videochat.error.publisher');
    // return 'Något gick fel. Pröva att starta om din browser.';
    case 1600:
      return _t('videochat.error.subscriber');
    // return 'Något gick fel med anslutningen till mottagaren. Pröva att starta om samtalet.';
    default:
      return _t('videochat.error.default');
    // return 'Något gick fel. Pröva att starta om samtalet.';
  }
};
