import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/styles/withStyles';
import { _t } from '@mindoktor/patient-legacy/i18n';
import classNames from 'classnames';
import isEqual from 'lodash-es/isEqual';
import React from 'react';
import { connect } from 'react-redux';

import { getSite, getToken } from '../../../state/api/selectors';
import { postImageFile, postVideoFile } from '../../../state/files/actions';
import { getCachedFileUri } from '../../../state/files/selectors';
import { getFileUri, hasVideoExtension } from '../../../state/files/utils';
import Spinner from '../../components/spinner/item';
import { chooseFiles, getFileData, uploadFile } from '../../utils/files';
import Next from '../components/Next';
import VideoPreview from '../components/VideoPreview';
import closeIconSource from '../images/icons/ic_close.svg';
import photoIconSource from '../images/icons/ic_photo.svg';

class Upload extends React.Component {
  // <
  //   {
  //     value?: *,
  //     error?: string,
  //     optional?: boolean,
  //     mimeTypes?: string,
  //     max?: { value: number },
  //     min?: { value: number },
  //     update: (?*) => *,
  //     done: (?*) => *,
  //     postImageFile: typeof postImageFile,
  //     postVideoFile: typeof postVideoFile,
  //     getLocalFileUri: (id: number) => string,
  //     getImageUri: (id: number) => string,
  //     classes: { [string]: string },
  //   },
  //   {
  //     submitting: boolean,
  //     files: { [string]: string },
  //   }
  // >
  state = {
    submitting: false,
    files: {},
  };

  choose = async () => {
    const { postImageFile, postVideoFile, mimeTypes } = this.props;

    let paths;

    try {
      paths = await chooseFiles(mimeTypes, true);
    } catch (e) {
      return;
    }

    let posted = 0;

    if (paths.length) {
      this.setState({ submitting: true });
    }

    paths.forEach(async (path) => {
      const isVideo = hasVideoExtension(path);
      let postResponse;
      if (isVideo) {
        postResponse = await postVideoFile(path, uploadFile);
      } else {
        postResponse = await postImageFile(path, uploadFile);
      }

      const { fileId, error } = postResponse;
      if (!error) {
        const { value: values = [], update } = this.props;

        update([...values, fileId]);
      }

      if (++posted === paths.length) {
        this.setState({ submitting: false });
      }
    });
  };

  remove = (value) => () => {
    const { value: values = [], update } = this.props;

    const newValues = values.filter((v) => v !== value);

    update(newValues.length ? newValues : undefined);
  };

  done = () => {
    const { value: values, done } = this.props;

    done(values === undefined ? [] : undefined);
  };

  isSubscribed = false;
  async updateState() {
    const { value: values, getLocalFileUri } = this.props;
    const { files } = this.state;
    if (!Array.isArray(values) || values.length === 0) {
      return;
    }
    // Prevent memory leak for promises
    this.isSubscribed = true;

    const paths = values.map((val) => getLocalFileUri(val));
    const updates = await Promise.all(
      paths.map((path) => {
        if (!this.isSubscribed) {
          return { path, value: undefined };
        }
        if (files[path]) {
          return { path, value: files[path] };
        }
        return getFileData(path)
          .then((data) => ({ path, value: { data } }))
          .catch((error) => ({ path, value: { error: error.message } }));
      })
    );
    this.setState({
      files: updates.reduce(
        (map, { path, value }) => ({
          ...map,
          [path]: value,
        }),
        { ...files }
      ),
    });
  }

  componentDidMount() {
    this.updateState();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.value, prevProps.value)) {
      this.updateState();
    }
  }
  componentWillUnmount() {
    this.isSubscribed = false;
  }

  render() {
    const {
      value: values = [],
      min: { value: min } = {},
      max: { value: max } = {},
      error,
      getLocalFileUri,
      getImageUri,
      classes,
    } = this.props;
    const { submitting, files } = this.state;

    const nextDisabled = !!error || submitting || (min && min > values.length);
    return (
      <div className={classes.root}>
        {values.map((value, i) => {
          let path = getLocalFileUri(value);
          let localFile = true;
          if (!path) {
            path = getImageUri(value);
            localFile = false;
          }
          const isVideo = hasVideoExtension(path);
          const file = files[path];
          if (
            (localFile && file === undefined) ||
            (file && file.error !== undefined) ||
            path === undefined
          ) {
            return null;
          }

          const fileSrc = localFile ? file.data : path;
          return (
            <div
              key={path}
              className={classNames(
                classes.upload,
                i === values.length - 1 && classes.uploadLast
              )}
            >
              {fileSrc ? (
                isVideo ? (
                  <VideoPreview alt={value} src={fileSrc} />
                ) : (
                  <img alt={path} src={fileSrc} className={classes.image} />
                )
              ) : (
                <>
                  <img
                    alt=""
                    role="presentation"
                    src={photoIconSource}
                    className={classes.placeholderIcon}
                  />
                  <Typography variant="body2">{path}</Typography>
                </>
              )}
              <Fab
                size="small"
                onClick={this.remove(value)}
                className={classes.remove}
              >
                <img
                  alt="close"
                  src={closeIconSource}
                  className={classes.removeIcon}
                />
              </Fab>
            </div>
          );
        })}

        {submitting && (
          <Spinner
            size={75}
            style={{
              marginBottom: '20px',
            }}
          />
        )}

        {(!max || values.length < max) && (
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            disabled={submitting}
            onClick={this.choose}
            className={classes.button}
          >
            {_t('fileUpload.upload')}
          </Button>
        )}

        <div className={classes.buttons}>
          <Next
            disabled={nextDisabled}
            onClick={this.done}
            label={
              !values.length && !nextDisabled
                ? _t('fileUpload.skip')
                : undefined
            }
          />
        </div>
      </div>
    );
  }
}

export default withStyles(({ spacing, breakpoints: { down } }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: `calc(100% - ${spacing(10)}px)`,
    marginLeft: spacing(10),
    [down('xs')]: {
      width: `calc(100% - ${spacing(5)}px)`,
      marginLeft: spacing(5),
    },
  },

  upload: {
    position: 'relative',
    marginBottom: spacing(1.5),
  },

  uploadLast: {
    marginBottom: spacing(3),
  },

  image: {
    maxWidth: '100%',
    maxHeight: 200,
    borderRadius: 4,
  },

  remove: {
    position: 'absolute',
    top: spacing(),
    right: spacing(),
  },

  removeIcon: {
    height: 14,
  },

  placeholderIcon: {
    height: 150,
    width: 150,
    marginBottom: spacing(),
  },

  button: {
    marginBottom: spacing(3),
  },

  buttons: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}))(
  connect(
    (state) => {
      return {
        getLocalFileUri: (id) => getCachedFileUri(state, id),
        getImageUri: (id) =>
          getFileUri(id, true, getToken(state), getSite(state)),
      };
    },
    { postImageFile, postVideoFile }
  )(Upload)
);
