import { ThemeOptions } from '@mui/material/styles';

const overrides: ThemeOptions['components'] = {
  MuiContainer: {
    styleOverrides: {
      root: { padding: 0 },
    },
  },
};

const muiContainerOverrides = overrides?.MuiContainer;
export default muiContainerOverrides;
