import React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import type { Question as QuestionType } from '../../../state/formulary/types';

import { Body1, Title2 } from '../../../common/components/typography';
import { updateQuestionnaire } from '../../../state/formulary/actions';
import { getQuestionnaireData } from '../../../state/formulary/selectors';
import { answerFollowupSurvey } from '../../../state/survey2/actions';
import LimitedHtml from '../../components/html/limited';
import Link from '../../components/link';
import HtmlModal from '../../formulary/components/html_modal';
import Input from '../../formulary/input';
import { showModal } from '../../routing';
import { CommonStateType } from '../../../state/types';

const styles = {
  inner: {
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },

  label: {
    paddingBottom: 10,
  },

  prologue: {
    paddingBottom: 10,
  },

  description: {
    paddingBottom: 10,
  },

  modal: {
    paddingBottom: 10,
  },
};

interface OwnProps {
  formularyKey: string;
  node: QuestionType;
  uuid: string;
  next?: () => void;
  style?: React.CSSProperties;
}

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = OwnProps & PropsFromRedux;

const Question: React.FC<Props> = (props) => {
  const showQuestionModal = () => {
    const {
      node: { modal },
    } = props;

    showModal(HtmlModal, {
      html: modal && modal.content,
    });
  };

  const update = async (value: unknown) => {
    const {
      formularyKey,
      node: { id },
      updateQuestionnaire,
    } = props;

    await updateQuestionnaire({ formularyKey, answers: { [id]: value } });
  };

  const { node, answer, error, options, style, answerFollowupSurvey, uuid } =
    props;

  return (
    <div style={style}>
      <div style={styles.inner}>
        {!!node.prologue && (
          <LimitedHtml html={node.prologue} style={styles.prologue} />
        )}

        {!!node.label && (
          <Title2
            style={{
              ...styles.label,
            }}
          >
            {node.label}
          </Title2>
        )}

        {!!node.description && (
          <LimitedHtml html={node.description} style={styles.description} />
        )}

        {!!node.modal && (
          <div style={styles.modal}>
            <Link textComponent={Body1} onClick={showQuestionModal}>
              {node.modal.link}
            </Link>
          </div>
        )}
      </div>

      <Input
        node={node}
        answer={answer}
        error={error}
        options={options}
        update={update}
        next={() => answerFollowupSurvey(uuid)}
      />
    </div>
  );
};

const connector = connect(
  (state: CommonStateType, props: OwnProps) => {
    const { formularyKey, node } = props;

    if (!formularyKey) return {};

    const { answers, errors, visibility } =
      getQuestionnaireData(state, formularyKey) || {};

    return {
      answer: answers && answers[node.id],
      error: errors && errors[node.id],
      options:
        node.options && !!node.options.find((o) => !!o.condition)
          ? node.options.filter((o) => visibility[o.index])
          : node.options,
    };
  },
  { updateQuestionnaire, answerFollowupSurvey }
);

const ConnectedQuestion = connector(Question);

export default ConnectedQuestion;
