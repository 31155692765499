import // type Question, // needs "submitted" prop
// type QuestionnaireQuestion,
'../formulary/types';

import config from '@mindoktor/env/Config';
import { _t } from '@mindoktor/patient-legacy/i18n';
import dayjs /* , { type Dayjs } */ from 'dayjs';
import memoize from 'lodash-es/memoize';
import { omit } from 'timm';

import { getTreaterByEntrywayId } from '../guides/guidesUtils';
import {
  hasImageExtension,
  hasVideoExtension,
  isFromPatient,
} from '../files/utils';
import { withAnswers } from '../formulary/formulary';
import { flattenAnswers } from '../formulary/utils';
import Values from '../formulary/values';
import {
  CaseStatuses,
  CertificateStatus,
  CertificateType,
  DeclineType,
  MessageSendStatus,
  MessageType,
} from './constants';
import { hasFeatureFlag } from '../featureflags/selectors';

const SEASONAL_FLU_PROMO_FEATURE_FLAG = 'seasonal-flu-promo';
const TBE_PROMO_FEATURE_FLAG = 'tbe-promo';

/**
 * @param {{ timestampUnix?: number }} a
 * @param {{ timestampUnix?: number }} b
 * @returns { -1 | 0 | 1 }
 */
function sortByUnixTimestamp(a, b) {
  if (a.timestampUnix > b.timestampUnix) {
    return 1;
  }
  if (a.timestampUnix < b.timestampUnix) {
    return -1;
  }
  return 0;
}

export const defaultTimeFormat = (timestamp /* Dayjs */) =>
  timestamp.format('DD MMM HH:mm');

export function makeFakeId(caseId, msgType, timestamp, msgId) {
  return `${caseId}_${msgType}_${msgId || timestamp}`;
}

// Needed temporarily for state normalization as there is no unique id-attribute for all messageTypes.
// `id` only exists for MESSAGE_TYPE_TEXT. Hence `cId` aka clientId
// Multiple selected images/videos get the same timestamp so we need to include attachmentName as well (https://github.com/mindoktor/mindoktor/issues/1898)

function addFakeIdToMessages(messages, caseId) {
  return messages.map((msg) => {
    const messageId = msg.attachmentName
      ? Values.hash(`${msg.attachmentName}${msg.timestampUnix}`)
      : msg.id;

    const cId = makeFakeId(caseId, msg.type, msg.timestampUnix, messageId);

    return {
      ...msg,
      cId,
    };
  });
}

const prepMessages = (rawMessages = []) => {
  const messages = [];

  rawMessages?.forEach((msg) => {
    const timestamp = dayjs(msg.updated);
    const currentYear = dayjs().year();
    const fromDoctor = msg.doctorID > 0;
    const formattedTimestamp =
      timestamp.year() === currentYear
        ? defaultTimeFormat(timestamp)
        : timestamp.format('YYYY DD MMM HH:mm');
    const data = {
      type: MessageType.TEXT,
      id: msg.id,

      text: msg.body,
      doctorID: msg.doctorID,
      fromDoctor,
      senderName: msg.senderName,
      name: msg.doctorFullName,
      signature: msg.doctorSignature,
      avatar: fromDoctor ? msg.doctorImageUrl : undefined,
      updated: msg.updated,
      timestamp: formattedTimestamp,
      timestampUnix: timestamp.unix(),
      sendStatus: MessageSendStatus.DONE,
      isFinalAnswer: msg.isFinalAnswer,
    };

    messages.push(data);
  });

  return messages;
};

function prepVideocalls(videocalls) {
  const messages = videocalls.map((call) => {
    const timestamp = dayjs(call.created);
    const startTime = dayjs(call.startTime);

    return {
      type: MessageType.VIDEOCALL,
      id: call.id,
      doctorID: call.doctorid,
      timestamp: defaultTimeFormat(timestamp),
      timestampUnix: timestamp.unix(),
      startTime: defaultTimeFormat(startTime),
      videoDate: startTime.format('ll'),
      doctorName: call.doctorName,
      doctorImageUrl: call.doctorImageUrl,
      created: call.created,
      updated: call.updated,
      status: call.status,
      isPending: call.isPending,
      isInPast: call.isInPast,
      isOutdatedAndUnconfirmed: call.isOutdatedAndUnconfirmed,
    };
  });

  return messages;
}

function prepCertificates(certificates = []) {
  const messages = [];

  certificates.forEach((certificate) => {
    const timestamp = dayjs(certificate.updated);
    const data = {
      type: MessageType.CERTIFICATE,
      id: certificate.id,
      timestamp: defaultTimeFormat(timestamp),
      timestampUnix: timestamp.unix(),
      created: certificate.created,
      updated: certificate.updated,
      certificateType: certificate.type,
      status: certificate.status,
      data: certificate.certificateData,
    };

    messages.push(data);
  });

  return messages;
}

function prepReferrals(referrals = []) {
  const messages = [];

  referrals.forEach((referral) => {
    const timestamp = dayjs(referral.updated);
    const data = {
      type: MessageType.REFERRAL,
      // This id is used as a message id, for the sake of identifying the message
      // referralId further down, looks like a duplicate, but is added to make
      // things clearer.
      id: referral.id,
      timestamp: defaultTimeFormat(timestamp),
      timestampUnix: timestamp.unix(),
      created: referral.created,
      updated: referral.updated,
      status: referral.status,
      referralId: referral.id,
      referralType: referral.type,
      summary: referral.summary,
      testTags: referral.testTags,
      labInfo: referral.labInfo,
      eReferral: referral.eReferral,
    };

    messages.push(data);
  });

  return messages;
}

const prepSharedReferralResults = (referralResults) =>
  (referralResults || []).map(({ referralId, shared }) => {
    const timestamp = dayjs(shared);
    return {
      type: MessageType.SHARED_REFERRAL_RESULTS,
      timestamp: defaultTimeFormat(timestamp),
      timestampUnix: timestamp.unix(),
      shared,
      referralId,
      cid: '' + referralId + timestamp.toString(),
    };
  });

function prepAttachments(attachments = [], caseCreated) {
  const messages = [];
  const guideAttachments = [];

  attachments.forEach((attachment) => {
    const {
      fileId: attachmentId,
      fileName: attachmentName,
      originalFileName: attachmentOriginalName,
      fileContext: attachmentContext,
      fileReferenceNumber: attachmentFileReferenceNumber,
      fileDecline,
      fromPatient,
    } = attachment;

    // Check file extension. Apparently you can attach PDFs as well.
    const isImage = hasImageExtension(attachment.fileName);
    const isVideo = hasVideoExtension(attachment.fileName);
    const caseCreatedAt = dayjs(caseCreated);
    const timestamp = dayjs(attachment.created);
    const isFromGuide = dayjs(timestamp).isBefore(caseCreatedAt);

    // If picture or video is declined by the doctor, spawn a new message item.
    if (fileDecline) {
      const { declinedAt, reasonDescription, freetext } = fileDecline;
      const declinedTimestamp = dayjs(declinedAt);

      let type;
      if (fileDecline.type === DeclineType.retake) {
        type = isImage ? MessageType.RETAKE_IMAGE : MessageType.RETAKE_VIDEO;
      } else if (fileDecline.type === DeclineType.invalidate) {
        type = MessageType.WRONG_ATTACHMENT;
      }

      if (isImage || isVideo) {
        const data = {
          text: attachmentName,
          timestamp: defaultTimeFormat(declinedTimestamp),
          timestampUnix: declinedTimestamp.unix(),
          attachmentId,
          attachmentName,
          fromPatient: isFromPatient(fromPatient),
          reasonDescription,
          freetext,
          sendStatus: MessageSendStatus.DONE,
          type,
        };
        messages.push(data);
        // don't exit.
        // The extra message is a message about the image/video being declined.
        // The file should still be shown as a separate message.
      }

      // Break code and do not add original message for the wrong attachments,
      // it shouldn't be shown in the user app anymore.
      if (type === MessageType.WRONG_ATTACHMENT) {
        return;
      }
    }

    // the ordinary message item.
    const data = {
      text: attachmentName,
      timestamp: defaultTimeFormat(timestamp),
      timestampUnix: timestamp.unix(),
      attachmentContext,
      attachmentId,
      attachmentName,
      attachmentOriginalName,
      attachmentFileReferenceNumber,
      fromPatient: isFromPatient(fromPatient),
    };

    if (isFromGuide) {
      guideAttachments.push(data);
    } else {
      if (isVideo) {
        data.type = MessageType.VIDEO;
      } else if (isImage) {
        // videoItems uses uploading:bool and error:bool instead

        data.sendStatus = MessageSendStatus.DONE;

        data.type = MessageType.IMAGE;
      } else {
        data.sendStatus = MessageSendStatus.DONE;

        data.type = MessageType.FILE;
      }

      messages.push(data);
    }
  });

  return messages;
}

function prepPrescriptions(prescriptions = []) {
  const messages = [];

  prescriptions.forEach((prescription) => {
    if (!prescription.drugs) {
      return;
    }

    const timestamp = dayjs(prescription.created);

    messages.push({
      type: MessageType.PRESCRIPTION,
      id: prescription.id,
      created: prescription.created,
      timestamp: defaultTimeFormat(timestamp),
      timestampUnix: timestamp.unix(),
      drugs: prescription.drugs,
      prescriptionType: prescription.type,
    });
  });

  return messages;
}

const prepCaseFinishedMarketingMessage = (
  prescriptions = [],
  status,
  rawMessages = [],
  patientIsListable,
  featureFlags
) => {
  const messages = [];
  const isSeasonalFluPromoEnabled = hasFeatureFlag(
    { featureFlags },
    SEASONAL_FLU_PROMO_FEATURE_FLAG
  );
  const isTBEPromoEnabled = hasFeatureFlag(
    { featureFlags },
    TBE_PROMO_FEATURE_FLAG
  );

  const isFinished =
    status === CaseStatuses.Finished || status === CaseStatuses.ClosedByDoctor;

  if (isFinished) {
    const finalAnswerMsg = rawMessages.find((msg) => msg.isFinalAnswer);
    const timestamp = dayjs(finalAnswerMsg?.updated);
    const hasPrescriptions = prescriptions.length > 0;

    // Only one marketing link message should be displayed at a time.
    if (isSeasonalFluPromoEnabled) {
      messages.push({
        type: MessageType.FLU_SEASON_LINK,
        timestamp: defaultTimeFormat(timestamp),
        timestampUnix: timestamp.unix(),
      });
    } else if (isTBEPromoEnabled) {
      messages.push({
        type: MessageType.VACCINATION_TBE,
        timestamp: defaultTimeFormat(timestamp),
        timestampUnix: timestamp.unix(),
      });
    } else if (!hasPrescriptions) {
      // We can only promote Apotek Hjärtat if there is no prescription.
      messages.push({
        type: MessageType.APH_LINK,
        timestamp: defaultTimeFormat(timestamp),
        timestampUnix: timestamp.unix(),
      });
    }

    if (patientIsListable) {
      messages.push({
        type: MessageType.LISTING_LINK,
        timestamp: defaultTimeFormat(timestamp),
        timestampUnix: timestamp.unix(),
      });
    }
  }

  return messages;
};

function prepCaseInvitationData(caseInvitationsData = []) {
  const messages = [];

  caseInvitationsData.forEach((invitation) => {
    const timestamp = dayjs(invitation.created);
    const targetEntrywayId = invitation.guideParams.entrywayId;
    const childGuide = invitation.guideParams.guideType !== 'adult';
    const childUuid = childGuide && invitation.patientUUID;

    const data = {
      type: MessageType.INVITATION_SCHEDULED,
      id: invitation.id,
      timestamp: defaultTimeFormat(timestamp),
      timestampUnix: timestamp.unix(),
      created: invitation.created,
      updated: invitation.updated,
      opens: invitation.opens,
      closes: invitation.closes,
      revisitParentCaseId: invitation.revisitParentCaseId,
      status: invitation.status,
      typeName: invitation.typeName,
      targetEntrywayId,
      childUuid,
      isOpen: invitation.isOpen,
      caseOwnerCanRepresentPatient: invitation.caseOwnerCanRepresentPatient,
    };

    messages.push(data);
  });

  return messages;
}

function prepWorkouts(workouts = {}) {
  if (!workouts) return [];

  const messages = [];
  Object.keys(workouts).forEach((key) => {
    const workout = workouts[key];
    const timestamp = dayjs(workout.created);
    const data = {
      type: MessageType.WORKOUT,
      id: workout.id,
      timestamp: defaultTimeFormat(timestamp),
      timestampUnix: timestamp.unix(),
      created: workout.created,
      allTags: workout.allTags,
      description: workout.description,
      exercises: workout.exercises,
      isLocked: workout.isLocked,
      tags: workout.tags,
      title: workout.title,
      videoLink: workout.videoLink,
    };
    messages.push(data);
  });

  return messages;
}

const prepSchedulingInvitation = (doctorId, doctorName, schedulingAllowed) => {
  if (!schedulingAllowed) return [];
  return [
    {
      type: MessageType.VIDEOCALL_INVITATION,
      doctorName,
      doctorId,
      timestampUnix: dayjs().unix(),
    },
  ];
};

/**
 * Given a scheduled video appointment, checks if it is about to start.
 *
 * @param {Appointment} param0 -  - A scheduled appointment.
 * @return {boolean} isAppointmentPending - If the video appointment is pending or not
 */
const isVideoAppointmentPending = ({ start }) =>
  dayjs().isBetween(
    dayjs(start).add(-5, 'minute'),
    dayjs(start).add(15, 'minute')
  );

/**
 * Checks if there is any scheduled video appointment that is about to start now.
 *
 * @param {Array.<Appointment>} appointments - A list of all scheduled appointments for the case.
 * @return {boolean} isAppointmentPending - If there is a video appointment is pending or not
 */
const isAnyVideoAppointmentPending = (appointments) => {
  if (!appointments) return false;
  return appointments.reduce((isPending, appointment) => {
    return isPending || isVideoAppointmentPending(appointment);
  }, false);
};

/**
 * Prepares a message item for a scheduled video appointment, if there is any.
 *
 * @param {Array.<Appointment>} appointments - A list of all scheduled appointments for the case.
 */
const prepAppointments = (appointments) => {
  if (!appointments) return [];

  return appointments
    .filter((appointment) => !appointment.cancelled)
    .map((appointment) => ({
      type: MessageType.SCHEDULED_APPOINTMENT,
      appointment,
      timestampUnix: dayjs(appointment.start).unix(),
      isPending: isVideoAppointmentPending(appointment),
    }));
};

function prepPaymentCard(paymentOverview = {}, status) {
  const messages = [];

  const invoiceSent =
    paymentOverview?.type === 'invoice' &&
    paymentOverview.payload.status === 'invoiceSent';

  const isFinished =
    status === CaseStatuses.Finished || status === CaseStatuses.ClosedByDoctor;

  if (isFinished) {
    if (invoiceSent) {
      messages.push({
        type: MessageType.PAYMENTINFO_INVOICE,
        paymentOverview,
      });
    } else if (paymentOverview?.type === 'free') {
      messages.push({
        type: MessageType.PAYMENTINFO_FREE,
        paymentOverview,
      });
    }
  }

  return messages;
}

export function prepareCaseMessages(site, caseId, data, featureFlags) {
  const {
    canPost,
    caseInvitationsData,
    certificates,
    doctorId,
    doctorName,
    doctorSignature,
    paymentMethod,
    guide,
    healthProfile,
    messages,
    referrals,
    sharedResults,
    showServiceSurvey,
    videoCalls,
    workouts,
    videoPendingMessage,
    hasActiveVideochat,
    survey,
    appointments,
    schedulingAllowed,
    inquisitionAnswers,
    patientIsListable,
    paymentOverview,
  } = data;
  const { created, prescriptions, attachments, entrywayId, status } = data.case;

  const preppedAttachments = prepAttachments(attachments, created);

  // Grab all messages and sort them
  const allMessages = [
    ...prepAppointments(appointments),
    ...prepSchedulingInvitation(doctorId, doctorName, schedulingAllowed),
    ...prepWorkouts(workouts),
    ...prepCaseInvitationData(caseInvitationsData),
    ...prepCertificates(certificates),
    ...prepMessages(messages),
    ...prepCaseFinishedMarketingMessage(
      prescriptions,
      status,
      messages,
      patientIsListable,
      featureFlags
    ),
    ...(config.ShowCasePrescriptionMessage
      ? prepPrescriptions(prescriptions)
      : []),
    ...preppedAttachments,
    ...prepSharedReferralResults(sharedResults),
    ...prepReferrals(referrals),
    ...prepVideocalls(videoCalls),
    ...prepPaymentCard(paymentOverview, status),
  ]
    .sort(sortByUnixTimestamp)
    .reverse();

  /**
   * An item with a button that takes you to a view with all your answers
   */
  allMessages.push({
    type: MessageType.FORMULARY_ANSWERS,
    timestampUnix: '1337',
  });

  /**
   * Add the video active message item, if the caregiver has initiated a
   * video call on their side so there is an active video call ongoing. If
   * a video call is scheduled to start 'now', we have already added a
   * similar message item above, in which case we shouldn't add this one.
   */
  if (!isAnyVideoAppointmentPending(appointments) && hasActiveVideochat) {
    allMessages.unshift({
      type: MessageType.VIDEOCHAT_ACTIVE,
      id: videoPendingMessage && videoPendingMessage.id,
    });
  }

  return {
    canPost,
    caseStatus: status,
    status,
    doctorName,
    doctorSignature,
    paymentMethod,
    showServiceSurvey,
    caseIsTreatedBy: getTreaterByEntrywayId(entrywayId),
    guide,
    healthProfile,
    inquisitionAnswers,
    messages: addFakeIdToMessages(allMessages, caseId),
    survey,
  };
}

export const flattenGuideData = (caseData) => {
  if (caseData.guide.formulary === null) {
    // Some cases without formulary answers still exist in production. These are remains
    // from the old Euro Accident physio hack. We must make sure we do not break them.
    return {
      ...omit(caseData, 'guide'),
    };
  }

  const formularyWithAnswers = withAnswers(
    caseData.guide.formulary,
    flattenAnswers(caseData.guide.formulary, caseData.guide.answers)
  );

  return {
    ...omit(caseData, 'guide'),
    guideAnswers: caseData.guide.answers,
    guideFormulary: caseData.guide.formulary,
    guideContextDescriptions: formularyWithAnswers.contextDescriptions,
    healthProfileFormulary:
      caseData.healthProfile && caseData.healthProfile.formulary,
    healthProfileAnswers:
      caseData.healthProfile && caseData.healthProfile.answers,
    guideHasHistory: caseData.guide.hasHistory,
    guideUpdated: caseData.guide.updated,
  };
};

export const flattenCaseAnswers = (caseAnswersFromApi) => {
  if (caseAnswersFromApi.guide.formulary === null) {
    // Some cases without formulary answers still exist in production. These are remains
    // from the old Euro Accident physio hack. We must make sure we do not break them.
    return {
      ...omit(caseAnswersFromApi, 'guide'),
    };
  }

  const formularyWithAnswers = withAnswers(
    caseAnswersFromApi.guide.formulary,
    flattenAnswers(
      caseAnswersFromApi.guide.formulary,
      caseAnswersFromApi.guide.answers
    )
  );

  return {
    ...omit(caseAnswersFromApi, 'guide'),
    guideAnswers: caseAnswersFromApi.guide.answers,
    guideFormulary: caseAnswersFromApi.guide.formulary,
    guideContextDescriptions: formularyWithAnswers.contextDescriptions,
    healthProfileFormulary: caseAnswersFromApi.healthProfile
      ? caseAnswersFromApi.healthProfile.formulary
      : false,
    healthProfileAnswers: caseAnswersFromApi.healthProfile
      ? caseAnswersFromApi.healthProfile.answers
      : false,
    inquisitionAnswers: caseAnswersFromApi.inquisitionAnswers,
  };
};

export const injectDivider = (
  messages,

  timestampFromJs,

  timestampToJs,

  item
) => {
  const inject = (array, index, item) => [
    ...array.slice(0, index),
    item,
    ...array.slice(index),
  ];

  const from = Number.isInteger(timestampFromJs)
    ? Number(timestampFromJs) / 1000
    : 0;
  const to = Number.isInteger(timestampToJs) ? Number(timestampToJs) / 1000 : 0;

  // No timestamp set
  if (!from) return messages;

  let latestHit = null;

  for (let i = 0, max = messages.length; i < max; i++) {
    const { timestampUnix: ts } = messages[i];

    if (ts > from && ts < to) {
      latestHit = i;
      continue;
    }

    if (latestHit !== null) {
      return inject(messages, i, item);
    }
  }

  // All or none a new
  return messages;
};

const question = memoize(
  (q) => q,
  ({ id, value, submitted }) => '' + id + String(value) + String(submitted)
);

const questionsKey = (questions) =>
  questions
    ? questions.reduce(
        (key, question) =>
          `${key}[${'' + question.id + question.value + question.submitted}]`,
        ''
      )
    : 'none';

export const createQuestionnaireItem = memoize(
  (caseId, isVisible, questions, formularyKey, surveyName) => {
    if (!isVisible) return [];

    const messages = [];
    const timestamp = dayjs();

    const item = {
      type: MessageType.QUESTIONNAIRE,
      caseId,
      surveyName,
      questions: (questions || []).map(question),
      formularyKey,
      key: formularyKey,
      cId: formularyKey, // used by messageList as key
      timestamp: defaultTimeFormat(timestamp),
      timestampUnix: timestamp.unix(),
    };
    messages.push(item);
    return messages;
  },
  (_, __, questions, formularyKey) => formularyKey + questionsKey(questions)
);

export const getCertificateTexts = (certificateType, status, data) => {
  const isCancelled = status === CertificateStatus.Cancelled;
  const isRemoved = status === CertificateStatus.Removed;
  const isTravelCertificate = certificateType === CertificateType.TravelCovid19;

  // default texts
  const texts = {
    header: isTravelCertificate
      ? _t('certificateItem.travel.covid19.header')
      : _t('certificateItem.header'),
    body: !isCancelled
      ? _t('certificateItem.message.issued')
      : _t('certificateItem.message.cancelled'),
  };

  if (
    isTravelCertificate &&
    !isCancelled &&
    !!data.ExpirationDate &&
    !!data.SampleCollectionDate
  ) {
    const created = dayjs(data.SampleCollectionDate);
    const expires = dayjs(data.ExpirationDate);

    // We need to round the diff to adjust for cases when expiration
    // times are created slightly before the update time (timestamp)
    // in which case we "lose" one hour.
    const diffRounded = Math.round(expires.diff(created, 'hours', true));

    texts.body = !isRemoved
      ? _t('certificateItem.travel.covid19.message.issued', {
          created: created.format('YYYY-MM-DD HH:mm'),
          hours: diffRounded,
        })
      : _t('certificateItem.travel.covid19.message.removed');
  }
  return texts;
};
