import React, { createContext, useContext, useEffect, useState } from 'react';

import { getPersistentStore } from '@mindoktor/patient-app/store/store';

import { setLanguage } from '../language';

const LOCALE_STORE_KEY = 'locale' as const;

const languageLocaleMap: Record<string, string> = {
  sv: 'sv-SE',
  en: 'en-GB',
} as const;

interface LocaleContextType {
  currentLocale: string | null;
  currentLanguage: string | null;
  setCurrentLocale: (currentLocale: string) => void;
  setDefaultLocaleForLanguage: (language: string) => void;
}

interface Props {
  children: React.ReactNode;
}

const getLanguageFromLocale = (locale: string) => locale.split('-')[0];

const LocaleContext = createContext<LocaleContextType | null>(null);

const LocaleProvider: React.FC<Props> = ({ children }) => {
  const store = getPersistentStore();
  const [currentLocale, setCurrentLocale] = useState<null | string>(null);
  const [currentLanguage, setCurrentLanguage] = useState(
    currentLocale == null ? null : getLanguageFromLocale(currentLocale)
  );

  useEffect(() => {
    const f = async () => {
      const persistedLocale = await store.get(LOCALE_STORE_KEY);
      setCurrentLocale(persistedLocale);
    };
    void f();
  }, []);

  useEffect(() => {
    if (currentLocale == null) {
      return;
    }

    const f = async () => {
      const language = getLanguageFromLocale(currentLocale);
      setCurrentLanguage(language);
      void setLanguage(language);
      await store.set(LOCALE_STORE_KEY, currentLocale);
    };
    void f();
  }, [currentLocale]);

  useEffect(() => {
    if (currentLanguage == null) {
      return;
    }

    const locale = languageLocaleMap[currentLanguage];
    setCurrentLocale(locale);
  }, [currentLanguage]);

  const setDefaultLocaleForLanguage = (language: string) =>
    setCurrentLanguage(language);

  const value = {
    currentLocale,
    currentLanguage,
    setCurrentLocale,
    setDefaultLocaleForLanguage,
  };

  return (
    <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>
  );
};

export const useLocale = () => {
  return useContext(LocaleContext);
};

export default LocaleProvider;
