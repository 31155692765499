import { createContext } from 'react';

export interface AuthenticationContextType {
  isAuthenticated: boolean;
  logout: (initiatedByUser?: boolean) => Promise<void>;
  isLoading: boolean;
}

export const AuthenticationContext =
  createContext<AuthenticationContextType | null>(null);
