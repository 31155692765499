import { z } from 'zod';

export const MarketingUserSchema = z
  .object({
    id: z.string(),
  })
  .nullable();

export const MarketingUserResponseSchema = z.object({
  data: MarketingUserSchema,
});
