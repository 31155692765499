import { _t } from '@mindoktor/patient-legacy/i18n';
import config from '@mindoktor/env/Config';

import dayjs from 'dayjs';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  Body1,
  Body2,
  Body1Strong,
  Header1,
} from '../../../common/components/typography';
import { getFormularyState } from '../../../state/formulary/selectors';

import {
  getGuideByEntrywayId,
  getGuidePrice,
} from '../../../state/guides/selectors';

import { getPatientNameAndBirthDate } from '../../../state/profile/selectors';

import colors from '../../../common/colors';

import { getFreePass } from '../../../state/freepass/selectors';
import { paymentMethodTypes } from '../../../state/payment/types';
import Link from '../../components/link';
import constants from '../../../common/constants';
import PaymentSelector from '../components/payment_selector';
import EditFreePassModal from '../freepass/edit_modal';

const styles = {
  root: {
    marginLeft: 24,
    marginRight: 24,
    marginBottom: 30,
  },
  titleAndLeadContainer: {
    marginBottom: 56,
  },

  title: {
    marginBottom: constants.spacingNormal,
    lineHeight: 43,
    fontWeight: '700',
  },

  price: {
    color: colors.red_500,
    fontWeight: '700',
    marginBottom: 32,
  },

  freePassPriceWithout: {
    color: colors.grey_700,
    marginTop: -28,
    fontSize: 13, // pulsefonts Body2
    fontWeight: '400',
  },

  subheader: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: constants.spacingSmall / 2,
  },

  name: {
    flex: 1,
    marginBottom: -constants.spacingTiny / 2,
    fontWeight: '500',
  },

  divider: {
    height: 1,
    backgroundColor: colors.grey_300,
    marginBottom: 10,
  },

  lead: {
    marginBottom: constants.spacingSmall,
  },
  leadBody: {
    marginTop: constants.spacingSmall / 4,
    marginBottom: constants.spacingAlmostTiny,
  },
  addFreePassLink: {
    color: colors.red_500,
    fontWeight: '500',
  },

  confirm: {
    color: colors.red_500,
    marginBottom: 10,
  },
};

// type Props = {
//   formularyKey?: string,

//   childUuid?: string,

//   treater: AllowedTreater,
//   price?: number,

// allowFreePass?: boolean,
// freePass?: FreePassState,

// onPaymentMethodChange: Function,
// onFreePassChange: Function,

//   fullName: string,
//   birthDate: string,

//   style?: any,
// };

// type State = {
//   paymentMethod: PaymentMethodType,
// };

export class CartContent extends Component {
  state = {
    paymentMethod: undefined,
    editFreePassModalOpen: false,
  };

  initPaymentMethod = () => {
    const { price } = this.props;
    this.setState({
      paymentMethod: price === 0 ? paymentMethodTypes.FREE : undefined,
    });
  };

  handlePaymentMethodChange = (type) => {
    const { onPaymentMethodChange } = this.props;
    onPaymentMethodChange(type);
    this.setState({ paymentMethod: type });
  };

  async componentDidMount() {
    await this.initPaymentMethod();
    const { paymentMethod } = this.state;
    if (!!paymentMethod) {
      this.handlePaymentMethodChange(paymentMethod);
    }
  }

  onEditFreePass = () => {
    this.setState({ editFreePassModalOpen: true });
  };

  formattedPrice = (value, currency) => {
    const price = new Intl.NumberFormat('sv-SE', {
      style: 'currency',
      currency,
      maximumFractionDigits: 0,
    }).format(value);

    return price;
  };

  getPriceInfo = () => {
    const { price, allowFreePass, currency } = this.props;

    const { paymentMethod } = this.state;

    const getFreePassText = (
      <span>
        <Header1 mb="s" style={styles.price}>
          {`0 kr`}
        </Header1>
        <Body2 mb="s" style={styles.freePassPriceWithout}>
          {_t('previewPayment.priceWithoutFreePass') + ': '}
          {this.formattedPrice(price, currency)}
        </Body2>
      </span>
    );

    const getPriceText = (
      <span>
        <Header1 mb="s" style={styles.price}>
          {this.formattedPrice(price, currency)}
        </Header1>
      </span>
    );

    return (
      <>
        {allowFreePass &&
        config.ShowFreePass &&
        paymentMethod === paymentMethodTypes.FREEPASS
          ? getFreePassText
          : getPriceText}
      </>
    );
  };

  getAddFreePassLink = () => (
    <Link onClick={this.onEditFreePass}>
      <Body1Strong style={styles.addFreePassLink}>
        {_t('payment.cart.leadGenericAddFreePassLink')}
      </Body1Strong>
    </Link>
  );

  render() {
    const {
      price,
      allowFreePass,
      freePass,
      fullName,
      birthDate,
      onFreePassChange,
    } = this.props;
    const { paymentMethod, editFreePassModalOpen } = this.state;

    const titleString = _t('previewPayment.title');

    return (
      <div style={styles.root}>
        <div style={styles.titleAndLeadContainer}>
          <Header1 style={styles.title}>
            {titleString}
            <br />
            {this.getPriceInfo()}
          </Header1>

          <div style={styles.subheader}>
            <Body1Strong mb="s" style={styles.name}>
              {config.ShowBirthDate
                ? `${fullName}, ${dayjs(birthDate).format('YYYYMMDD')}`
                : fullName}
            </Body1Strong>
          </div>

          <div style={styles.divider} />

          {paymentMethod === paymentMethodTypes.FREE && (
            <div style={styles.lead}>
              <Body1 mb="t" style={styles.leadBody}>
                {_t('payment.cart.body')}
              </Body1>
            </div>
          )}

          {paymentMethod === paymentMethodTypes.INVOICE && (
            <div style={styles.lead}>
              <Body1 style={styles.leadBody}>
                {_t('payment.cart.leadGenericIfYouHaveFreePass')}
              </Body1>
              {this.getAddFreePassLink()}
            </div>
          )}

          {paymentMethod === paymentMethodTypes.FREEPASS && (
            <div style={styles.lead}>
              <Body1>{_t('payment.cart.leadWithFreePass')}</Body1>
            </div>
          )}
        </div>
        {!!price && (
          <PaymentSelector
            style={styles.lead}
            onPaymentMethodChange={this.handlePaymentMethodChange}
            onFreePassChange={onFreePassChange}
            freePass={freePass}
            allowFreePass={allowFreePass}
          />
        )}

        {config.ShowPaymentConsultationWarning && (
          <Body1 style={styles.confirm}>
            {_t('payment.cart.confirmation')}
          </Body1>
        )}

        <EditFreePassModal
          freePass={freePass}
          isOpen={editFreePassModalOpen}
          onClose={() => this.setState({ editFreePassModalOpen: false })}
          submitCallback={onFreePassChange}
          submitText={_t('freePass.submit')}
        />
      </div>
    );
  }
}

export default connect((state, props) => {
  const { formularyKey } = props;

  const { entrywayId, childUuid } =
    getFormularyState(state, formularyKey) || {};

  const { treater, currency, allowFreePass } =
    getGuideByEntrywayId(state, entrywayId) || {};

  const price = getGuidePrice(state, entrywayId);

  const { fullName, birthDate } = getPatientNameAndBirthDate(state, childUuid);

  const freePass = getFreePass(state);

  return {
    childUuid,

    fullName,
    birthDate,

    treater,
    price,
    currency,

    allowFreePass,
    freePass,
  };
}, {})(CartContent);
