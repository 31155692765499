import { fetchHostConfig } from '../../state/api/api';
import { getQueryParam } from '../../state/routing/utils';
import { getUniqueId } from '@mindoktor/patient-app/api';

export const loadHostConfig = async () => {
  const site = getQueryParam(window.location.search, 'site') || '';

  const { error, host } = await fetchHostConfig(site, getUniqueId());
  if (error) {
    return {
      error,
    };
  }

  window.mdHost = host;

  return { host, error: false };
};

export const getDefaultSiteFromHost = () => {
  const mdHost = window.mdHost;

  return mdHost ? mdHost.defaultSite : '';
};

export const getCompanyIdFromHost = () => {
  const mdHost = window.mdHost;

  return mdHost ? mdHost.companyId : 0;
};

export const getFeatureFlagsFromHost = () => {
  const mdHost = window.mdHost;

  return mdHost ? mdHost.featureFlags : [];
};

export const isLoggedInFromHost = () => {
  const mdHost = window.mdHost;

  return !!(mdHost && mdHost.session);
};

export const isAnalyticsEnabledFromHost = () => {
  const mdHost = window.mdHost;

  return !!(mdHost && mdHost.analyticsEnabled);
};

export const getExternalIdFromHost = () => {
  const mdHost = window.mdHost;

  return mdHost && mdHost.session ? mdHost.session.externalId : undefined;
};
