import {
  ApiResponse,
  requestHandler,
} from '@mindoktor/patient-app/api/requestHandler';

import { PostFileRequest, PostFileResponse } from './models/files';
import { BASE_URL_FILES } from './constants';
import { FilesApi } from './types';

const createFile = async (fileRequest: PostFileRequest): Promise<File> => {
  const data = await fetch(fileRequest.uri);
  const blob = await data.blob();
  return new File([blob], fileRequest.name, { type: fileRequest.type });
};

const postFile = async (
  fileRequest: PostFileRequest
): Promise<ApiResponse<PostFileResponse>> => {
  const fileData = fileRequest.file ?? (await createFile(fileRequest));

  const formData = new FormData();
  formData.append('file', fileData, fileRequest.name);

  return await requestHandler.post(BASE_URL_FILES, formData);
};

export const filesApi: FilesApi = {
  postFile,
};
