export default [
  { region: 'Landstinget Blekinge', short: 'Blekinge', eFreePass: false },
  { region: 'Landstinget Dalarna', short: 'Dalarna', eFreePass: false },
  { region: 'Region Gotland', short: 'Gotland', eFreePass: true },
  { region: 'Region Gävleborg', short: 'Gävleborg', eFreePass: false },
  { region: 'Region Halland', short: 'Halland', eFreePass: false },
  {
    region: 'Region Jämtland Härjedalen',
    short: 'Jämtland Härjedalen',
    eFreePass: false,
  },
  {
    region: 'Region Jönköpings län',
    short: 'Jönköpings län',
    eFreePass: false,
  },
  { region: 'Landstinget i Kalmar län', short: 'Kalmar län', eFreePass: false },
  { region: 'Region Kronoberg', short: 'Kronoberg', eFreePass: false },
  { region: 'Region Norrbotten', short: 'Norrbotten', eFreePass: false },
  { region: 'Region Skåne', short: 'Skåne', eFreePass: false },
  {
    region: 'Stockholms läns landsting',
    short: 'Stockholms län',
    eFreePass: true,
  },
  { region: 'Landstinget Sörmland', short: 'Sörmland', eFreePass: false },
  { region: 'Region Uppsala', short: 'Uppsala län', eFreePass: false },
  { region: 'Landstinget i Värmland', short: 'Värmland', eFreePass: false },
  {
    region: 'Västerbottens läns landsting',
    short: 'Västerbotten',
    eFreePass: false,
  },
  {
    region: 'Landstinget Västernorrland',
    short: 'Västernorrland',
    eFreePass: false,
  },
  { region: 'Region Västmanland', short: 'Västmanland', eFreePass: false },
  {
    region: 'Västra Götalandsregionen',
    short: 'Västra Götaland',
    eFreePass: false,
  },
  { region: 'Region Örebro län', short: 'Örebro län', eFreePass: false },
  { region: 'Region Östergötland', short: 'Östergötland', eFreePass: false },
];
