import BlockPlugin from './BlockPlugin';

export default function () {
  // Get the parser
  const parser = this.Parser.prototype;

  // Generate the plugin
  // Adjusted regex to be as similar to code fence as possible
  // https://github.com/regexhq/gfm-code-block-regex/blob/master/index.js
  const plugin = new BlockPlugin({
    type: 'modal',
    regex: new RegExp(/^(([ \t]*:{3,4})([^\n]*)([\s\S]+?)(^[ \t]*\2))/, 'm'),
    flagGroup: 3,
    innardsGroup: 4,
    start: ':::',
    end: ':::',
    defaultFlag: 'Open Modal',
    before: 'paragraph',
  });

  // Attach the plugin to the parser
  plugin.attachTo(parser);
}
