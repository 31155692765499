import { z } from 'zod';

export const EmailLoginSchema = z.object({
  marketingUserId: z.string(),
});

export const EmailLoginFormSchema = z.object({
  email: z.string().min(1, {
    message: 'error.required',
  }),
  password: z.string().min(1, {
    message: 'error.required',
  }),
});
