/**
 * Type strings for user notifications.
 *
 * To check the full list, see 'typeStrings' in pkg/eventnotification/const.go of mindoktor.
 */
export const notificationTypeStrings = {
  typeSupportTicketCreated: 'supportTicketCreated',
  typeSupportTicketUpdated: 'supportTicketUpdated',
} as const;

export interface UserNotification {
  type:
    | ''
    | (typeof notificationTypeStrings)[keyof typeof notificationTypeStrings];
  typeId: number;
  caseId: number;
  objectId: number;
  secondaryObjectId: number;
}
