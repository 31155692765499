import '@formatjs/intl-getcanonicallocales/polyfill';
import '@formatjs/intl-locale/polyfill';
// dayjs locales are initalised/changed when locale is set
import 'dayjs/locale/en-gb';
import 'dayjs/locale/sv';

import Config from '@mindoktor/env/Config';
import areIntlLocalesSupported from 'intl-locales-supported';

// get supported locales from config:
const defaultLocales = Config.Locales || ['sv'];

function polyfillLocales(global, locales = defaultLocales) {
  let usingPolyfill = false;

  if (global.Intl) {
    // Determine if the built-in `Intl` has the locale data we need.
    if (!areIntlLocalesSupported(locales)) {
      // `Intl` exists, but it doesn't have the data we need, so load the
      // polyfill and replace the constructors with need with the polyfill's.
      const IntlPolyfill = require('intl');
      usingPolyfill = true;
      Intl.NumberFormat = IntlPolyfill.NumberFormat;
      Intl.DateTimeFormat = IntlPolyfill.DateTimeFormat;
    }
  } else {
    // No `Intl`, so use and load the polyfill.
    global.Intl = require('intl');
    usingPolyfill = true;
  }

  // If we are using the intl polyfill we need to load its locale too,
  // else NumberFormat and DateTimeFormat will break.
  if (usingPolyfill) {
    require('intl/locale-data/jsonp/en.js');
    require('intl/locale-data/jsonp/sv.js');
  }

  if (!Intl.PluralRules) {
    require('@formatjs/intl-pluralrules/polyfill');
    require('@formatjs/intl-pluralrules/locale-data/sv');
  }

  if (!Intl.RelativeTimeFormat) {
    require('@formatjs/intl-relativetimeformat/polyfill');
    require('@formatjs/intl-relativetimeformat/locale-data/sv');
  }
}

export default polyfillLocales;
