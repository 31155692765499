import { _t } from '@mindoktor/patient-legacy/i18n';
import React from 'react';

import Choice from './Choice';

const options = () => [
  {
    label: _t('formulary.question.tertiary.yes'),
    value: 'yes',
  },
  {
    label: _t('formulary.question.tertiary.no'),
    value: 'no',
  },
  {
    label: _t('formulary.question.tertiary.unknown'),
    value: 'unknown',
  },
];

class Tertiary extends React.Component {
  render() {
    const { value, update, done } = this.props;

    return (
      <Choice value={value} options={options()} update={update} done={done} />
    );
  }
}

export default Tertiary;
