import 'rc-slider/assets/index.css';

import RcSlider from 'rc-slider';
import React from 'react';

import { getImageSrc } from '@mindoktor/pulse/src/utils/images/getImageSrc';
import handleIcon from '../../../../../images/components/dragmarker@3x.png';
const handleIconSrc = getImageSrc(handleIcon);
import colors from '../../../colors';
import spacing from '../../../spacing';

const styles = {
  slider: {
    boxSizing: 'border-box',
    padding: '14px 0',
    borderRadius: 0,
    cursor: 'pointer',
  },

  handle: {
    height: spacing.m,
    width: spacing.m,
    marginTop: -spacing.xs,
    marginLeft: -spacing.xs,
    backgroundColor: colors.red_500,
    backgroundImage: `url(${handleIconSrc})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    border: 'none',
    transition: 'left 0.15s',
  },

  track: {
    backgroundColor: '#bbb',
    height: 2,
    borderRadius: 0,
    marginTop: -1,
  },

  rail: {
    backgroundColor: '#bbb',
    height: 2,
    borderRadius: 0,
    marginTop: -1,
  },
};

// type Attributes = {
//   defaultValue: number,
//   min: number,
//   max: number,
//   trackStyle: Object,
//   handleStyle: Object,
//   railStyle: Object,
//   style: Object,
//   onChange: Function,
//   onAfterChange: Function,
// };

export default class Slider extends React.Component {
  get attributes() {
    const { options, initialValue, onChangingValue, onSelectedValue } =
      this.props;

    const min = 0;
    const max = options.length - 1;

    return {
      defaultValue: initialValue,
      min,
      max,
      trackStyle: styles.track,
      handleStyle: styles.handle,
      railStyle: styles.rail,
      style: styles.slider,
      onChange: onChangingValue,
      onAfterChange: onSelectedValue,
    };
  }

  render() {
    return <RcSlider {...this.attributes} />;
  }
}
