import dayjs /* , { type Dayjs } */ from 'dayjs';

/**
 * Eval a valid Personnummer and return the gender and age.
 * Obs! No validation made in this function. It expects a valid input.
 */
export function evalSsn(ssn, options = { now: dayjs() }) {
  const strippedSsn = ssn.replace(/[-+]/g, '');
  const birthDate = dayjs(strippedSsn.slice(0, 8));
  const genderDigit = Number(strippedSsn[10]);
  const months = options.now.diff(birthDate, 'month');

  return {
    gender: genderDigit % 2 ? 'male' : 'female',
    years: Math.floor(months / 12),
    months: months % 12,
  };
}

export function evalBirthDate(birthDate, options = { now: dayjs() }) {
  const date = dayjs(birthDate);
  const months = options.now.diff(date, 'month');

  return {
    years: Math.floor(months / 12),
    months: months % 12,
  };
}
