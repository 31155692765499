import React, { useState } from 'react';

import { Container } from '@mui/material';

import { getImageSrc } from '@mindoktor/pulse/src/utils/images/getImageSrc';

import BubbleScreenLayout from '@mindoktor/patient-app/layout/components/BubbleScreenLayout/web';
import { FixedContentLayout } from '@mindoktor/patient-app/layout/web';
import { isMobile } from '@mindoktor/patient-app/utils/device/web';

import bubbleImage from '../../../images/registration_desktop_step_1.webp';
import bubbleImageMobile from '../../../images/registration_mobile_step_1.webp';
import LogoutSheet from '../../LogoutSheet/web';
import TosAndPrivacyContent from '../../TosAndPrivacyContent/web';

const bubbleImageSrc = getImageSrc(bubbleImage);
const bubbleImageMobileSrc = getImageSrc(bubbleImageMobile);

const TosAndPrivacyScreen: React.FC = () => {
  const [showLogoutSheet, setShowLogoutSheet] = useState(false);
  const backButtonAction = () => {
    setShowLogoutSheet(true);
  };

  return (
    <FixedContentLayout
      showFooter={false}
      showMenuBarActions={false}
      onBack={backButtonAction}
    >
      <BubbleScreenLayout
        imgSrcDesktop={bubbleImageSrc}
        imgSrcMobile={bubbleImageMobileSrc}
      >
        {/*
          The style height numbers here is just plain layouting making sure
          the content will be below the top image bubble and will force scrolling (if needed)
        */}
        <Container
          maxWidth="sm"
          sx={{
            padding: 0,
            marginTop: '29vh',
            display: 'flex',
            flex: 1,
            overflowX: 'hidden',
            overflowY: isMobile ? 'hidden' : 'unset',
          }}
        >
          <TosAndPrivacyContent />
          <LogoutSheet
            isOpen={showLogoutSheet}
            onClose={() => setShowLogoutSheet(false)}
          />
        </Container>
      </BubbleScreenLayout>
    </FixedContentLayout>
  );
};

export default TosAndPrivacyScreen;
