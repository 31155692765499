import { postApi } from '../api/actions';
import { viewGuide } from '../guides/actions';
import {
  HIDE_RENEWAL_MODAL,
  OPEN_RENEWAL_GUIDE,
  SHOW_RENEWAL_MODAL,
} from './constants';

export function showRenewalModal() {
  return {
    type: SHOW_RENEWAL_MODAL,
  };
}

export function hideRenewalModal() {
  return {
    type: HIDE_RENEWAL_MODAL,
  };
}

export function openRenewalGuide(entrywayId) {
  return (dispatch) => {
    // Hack: This should happen when the transition
    // to the next page is finished
    setTimeout(() => {
      dispatch({ type: OPEN_RENEWAL_GUIDE });
      dispatch({ type: HIDE_RENEWAL_MODAL });
    }, 500);
    return dispatch(viewGuide(entrywayId));
  };
}

export function sendApoteketVoucherMail() {
  return postApi('/api/v1/apoteketvoucheremail');
}
