export const SnackbarType = {
  WARNING: 'WARNING',
  SUCCESS: 'SUCCESS',
} as const;
export type SnackbarTypeKeys = keyof typeof SnackbarType;

export interface SnackbarValue {
  type: SnackbarTypeKeys;
  text: string;
}

export type SnackbarState = SnackbarValue | null;

export enum ActionTypes {
  SHOW_SNACKBAR = 'SHOW_SNACKBAR',
  HIDE_SNACKBAR = 'HIDE_SNACKBAR',
}

export interface SnackbarShowAction {
  type: ActionTypes.SHOW_SNACKBAR;
  payload: SnackbarValue;
}

export interface SnackbarHideAction {
  type: ActionTypes.HIDE_SNACKBAR;
}

export type SnackbarAction = SnackbarShowAction | SnackbarHideAction;
