import { useNotifications } from '@mindoktor/patient-app/adapters/hooks/useNotifications';
import { ResponseError } from '@mindoktor/patient-app/api/responseError';
import { useTranslation } from '@mindoktor/patient-app/localization/hooks/useTranslation';

import { getErrorMsgKey } from '../../api/error';
import { BankIDLoginMode, BankIDLoginModes } from '../api/models/bankid';
import { AuthErrorCodes } from '../api/models/error';

/**
 * useAuthErrorNotifications takes an error response from BE and tries to find
 * the appropriate message to show for the user.
 */
export const useAuthErrorNotifications = (loginMode?: BankIDLoginMode) => {
  const notifications = useNotifications();
  const t = useTranslation('auth', 'error');

  const show = (error: ResponseError | unknown) => {
    if (error instanceof ResponseError) {
      const errorMsgKey = getErrorMsgKey(error, (code) =>
        getAuthErrorMsgKey(code, loginMode)
      );
      notifications.showWarning(t(errorMsgKey));
    } else {
      notifications.showWarning(t('unknown'));
    }
  };

  return {
    show,
  };
};

// getAuthErrorMsgKey returns error key based on a provided error code.
// If the error code doesn't exist, it returns a default error key.
export const getAuthErrorMsgKey = (
  errorCode: string,
  loginMode?: BankIDLoginMode
) => {
  switch (errorCode) {
    case AuthErrorCodes.ACCESS_DENIED:
      return 'accessDenied';
    case AuthErrorCodes.BANKID_START_FAILED:
      // Special: The same code renders different messages based on login mode.
      if (loginMode === BankIDLoginModes.DEVICE) {
        return 'bankidStartFailedDevice';
      }
      return 'bankidStartFailedQRCode';
    case AuthErrorCodes.BANKID_CANCELLED:
      return 'bankidCancelled';
    case AuthErrorCodes.BANKID_CERTIFICATE_ERROR:
      return 'bankidCertificateError';
    case AuthErrorCodes.BANKID_EXPIRED_TRANSACTION:
      return 'bankidExpiredTransaction';
    case AuthErrorCodes.BANKID_FAILURE_UNKNOWN:
      return 'bankidFailureUnknown';
    case AuthErrorCodes.BANKID_INTERNAL_ERROR:
      return 'bankidInternalError';
    case AuthErrorCodes.BANKID_SESSION_NOT_FOUND:
      return 'bankidSessionNotFound';
    case AuthErrorCodes.BANKID_USER_CANCEL:
      return 'bankidUserCancel';
    case AuthErrorCodes.BANKID_USER_DECLINED_CALL:
      return 'bankidUserDeclinedCall';
    case AuthErrorCodes.EMAIL_LOGIN_FAILED:
      return 'emailLoginFailed';
    case AuthErrorCodes.USER_AGE_LIMIT:
      return 'userAgeLimit';
    case AuthErrorCodes.RESOURCE_NOT_FOUND: // Intentional fallthrough
    case AuthErrorCodes.INVALID_REQUEST:
      return 'internalError';
    default:
      return 'unknown';
  }
};
