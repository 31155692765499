/* eslint-disable no-console */
import React from 'react';
// In order for Lokalise to be initialized you need to:
// - load the web app from test environment, eg. se.doclytest.io
// - set a `lokalise` flag in localStorage, where the value is the projectId
// - for the lokalise project.
// You  can set the flag via the following command:
// lokalise.enable('323736145c445381d04e29.59011692');
// there's also lokalise.disable() that removes the flag from localStorage
//
// lokalise.update() updates the translations on the page.
// You can achieve the same by clicking on the lokalise logo in the bar.
// lokalise.setLokale('fr') changes locale and refreshes translations.
// you can also use the built-in locale switcher in the web app itself;
// that one updates and refreshes translation keys too.

class LokaliseTranslator extends React.Component {
  componentDidMount() {
    const { locale = 'en' } = this.props;

    const projectId = window.localStorage.getItem('lokalise');

    window.lokalise = {
      enable: (id) => {
        if (!id) {
          console.error('you need to provide a projectId');
        }
        console.log('Lokalise is enabled. Please reload the page!');
        window.localStorage.setItem('lokalise', id);
      },
      disable: () => {
        console.log('Lokalise editing is disabled. Please reload the page.');
        window.localStorage.removeItem('lokalise');
      },
      update: () => {
        console.log('Lokalise: update elements');
        document.dispatchEvent(new Event('lokalise-update-elements'));
      },
      setLocale: (locale) => {
        console.log(`Lokalise: update locale (${locale})`);
        document.dispatchEvent(
          new CustomEvent('lokalise-update-locale', { detail: locale })
        );
      },
    };

    if (!projectId) {
      return;
    }
    window.LOKALISE_CONFIG = {
      projectId,
      usePanelOffset: true,
      locale,
      plainKey: true,
      plainKeyLeftWrapper: '{.',
      plainKeyRightWrapper: '.}',
    };

    const lokaliseJs = document.createElement('script');
    lokaliseJs.type = 'text/javascript';
    lokaliseJs.id = 'lokalise-js-script';
    lokaliseJs.src = `https://lokalise.co/live-js/script.min.js?${Date.now()}`;
    lokaliseJs.async = true;

    document.addEventListener('lokalise-initialized', function () {
      console.log('Lokalise: initialized');

      // clicking on the lokalise logo refreshes translation tags
      const logo = document.querySelector(
        '#lokalise-live-js-panel .lokalise-live-js-logo'
      );
      if (logo) {
        logo.setAttribute('title', 'click to refresh translation tags');
        logo.addEventListener('click', window.lokalise.update);
      }
    });

    document.addEventListener('lokalise-keys-parsed', function () {
      console.log('Lokalise: Plain keys were parsed');
    });

    document.body && document.body.appendChild(lokaliseJs);
  }

  componentDidUpdate(prevProps) {
    // hook into the locale switcher of the app and change accordingly
    if (this.props.locale && this.props.locale !== prevProps.locale) {
      window.lokalise.setLocale(this.props.locale);
      window.lokalise.update();
    }
  }

  render() {
    return null;
  }
}

export default LokaliseTranslator;
