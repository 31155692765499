import { _t } from '@mindoktor/patient-legacy/i18n';
import React, { Component } from 'react';

import Choice from './choice';

// type Props = {
//   answer?: any,
//   error?: string,
//   update: (value?: any) => any,
//   next?: () => any,
//   disabled?: boolean,
// };

export default class Tertiary extends Component {
  options = [
    {
      index: '0',
      label: _t('formulary.question.tertiary.yes'),
      value: 'yes',
    },
    { index: '1', label: _t('formulary.question.tertiary.no'), value: 'no' },
    {
      index: '2',
      label: _t('formulary.question.tertiary.unknown'),
      value: 'unknown',
    },
  ];

  render() {
    const { answer, error, update, next, disabled } = this.props;

    return (
      <Choice
        options={this.options}
        answer={answer}
        error={error}
        update={update}
        next={next}
        disabled={disabled}
      />
    );
  }
}
