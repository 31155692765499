import _get from 'lodash-es/get';

export const isLoggedIn = (state) => state.api.loggedIn;

export const getToken = (state) => state.api.token;

export const getTokenIssuedAt = (state) => _get(state, 'api.session.iat') || 0;

// For use in connect, where it's desired to pass getToken()
// to unconnected components:
// connect ... return { getToken: getTokenThunk(state),
export const getTokenThunk = (state) => () => getToken(state);

/**
 * Return the site based on the state
 * @param {any} state
 * @returns {string}
 */
export const getSite = (state) => (state.api ? state.api.site : '');

export const getCompanyId = (state) =>
  _get(state, 'api.companyId') || undefined;

// getHostConfig retrieves the host config from the appropriate location depending on the execution
// context. For web (where the host config is loaded outside of react entirely) it is retrieved from
// `window.mdHost`. For native, it is retrieved from the passed state.
export const getHostConfig = ({ api }) => api.host || window?.mdHost;

export const getDomains = ({ api }) => {
  const host = getHostConfig({ api });
  return host ? host.domainsPublicInfo : undefined;
};

export const getDomainsWithChildSupport = ({ api }) => {
  const host = getHostConfig({ api });

  return host && host.domainsPublicInfo
    ? host.domainsPublicInfo.filter((d) => d.childrenAllowed)
    : undefined;
};

export const getSignupDomainId = ({ api }) => api.signupDomainId;

export const getDomainPublicInfo = ({ api }) => {
  const { session, host } = api;
  let mdHost = host;

  if (!mdHost) {
    // Web loads the host config outside of react entirely
    // and loads it into `window.mdHost`
    if (typeof window !== 'undefined' && window.mdHost) {
      mdHost = window.mdHost;
    } else {
      return undefined;
    }
  }

  if (!mdHost.domainsPublicInfo) {
    return undefined;
  }
  if (!session || !session.domainID) {
    console.warn('No session or domainId found, user not logged in?');
    return undefined;
  }

  return mdHost.domainsPublicInfo.find((info) => info.id === session.domainID);
};

export const getIndependentCareAgeFromHost = ({ api }) => {
  const hostConfig = getHostConfig({ api });

  if (!hostConfig) {
    throw Error('hostConfig is undefined');
  }

  if (!hostConfig.independentCareAge) {
    // This setting has to exist for our application to function.
    throw Error('independentCareAge is undefined');
  }

  return hostConfig.independentCareAge;
};

export const getAdultAgeFromHost = ({ api }) => {
  const hostConfig = getHostConfig({ api });

  if (!hostConfig) {
    throw Error('hostConfig is undefined');
  }

  if (!hostConfig.adultAge) {
    // This setting has to exist for our application to function.
    throw Error('adultAge is undefined');
  }

  return hostConfig.adultAge;
};
