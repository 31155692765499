import React from 'react';

import constants from '../../common/constants';
import Binary from '../formulary/input/binary';
import Choice from '../formulary/input/choice';
import Range from '../formulary/input/range';
import Tertiary from '../formulary/input/tertiary';
import Text from '../formulary/input/text';
import { getDisplayError } from '../formulary/utils';
import Rating from './input/rating';

// styles to override normal formulary input nodes (if needed)
const styles = {
  text: {
    marginBottom: constants.spacing16,
    paddingBottom: 0,
    paddingRight: 0,
    paddingLeft: 0,
  },
};

// type Props = {
//   node: Question,
//   answer?: any,
//   error?: string,
//   options?: Option[],
//   min?: Min,
//   max?: Max,
//   update: any => any,
//   style?: any,
//   submitted?: boolean,
// };

export default class Input extends React.Component {
  render() {
    const { node, answer, error, options, min, max, update, style, submitted } =
      this.props;

    const { type, hint, single, validations } = node;

    const errorMessage = error && getDisplayError(node, error);

    // A subset of formulary questions needed for questionnaires.
    // Bring in more (or add new ones) if needed.
    switch (type) {
      case 'binary':
        return (
          <Binary
            answer={answer}
            error={error}
            update={update}
            disabled={submitted}
          />
        );

      case 'choice':
        return (
          <Choice
            options={options}
            answer={answer}
            error={error}
            update={update}
            style={style}
            disabled={submitted}
          />
        );

      case 'range':
        return (
          <Range
            min={min}
            max={max}
            answer={answer}
            error={error}
            update={update}
            style={style}
            disabled={submitted}
            step={1}
          />
        );

      case 'rating':
        return (
          <Rating
            answer={answer}
            error={error}
            update={update}
            style={style}
            disabled={submitted}
          />
        );

      case 'tertiary':
        return (
          <Tertiary
            answer={answer}
            error={error}
            update={update}
            style={style}
            disabled={submitted}
          />
        );

      case 'text':
        return (
          <Text
            answer={answer}
            error={error}
            errorMessage={errorMessage}
            hint={hint}
            maxLength={validations && validations.max}
            single={single}
            update={update}
            style={{ ...styles.text, ...style }}
            disabled={submitted}
          />
        );
    }

    return null;
  }
}
