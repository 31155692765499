import React, { Component } from 'react';

import { getGuideImageByEntrywayId } from '../utils/images';
import Item from './item';

const styles = {
  list: {
    margin: '0 auto',
    width: '100%',
    maxWidth: 800,
  },

  listWide: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'stretch',
    justifyContent: 'space-between',
  },

  item: {
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: '#e6e6e6',
  },

  itemWide: {
    width: 'calc(50% - 60px)',
  },

  lastItem: {
    borderBottomWidth: 0,
  },
};

// type Props = {
//   guides: any[],
//   onGuideClick: number => any,
//   style?: any,
// };

export default class List extends Component {
  state = {
    wide: window.innerWidth > 800,
  };

  onResize = () => this.setState({ wide: window.innerWidth > 800 });

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  render() {
    const { guides, onGuideClick, style } = this.props;
    const { wide } = this.state;

    const itemStyleToKeepFlowHappy = {
      ...styles.item,
      ...(!!wide && styles.itemWide),
    };

    return (
      <div
        style={{
          ...styles.list,
          ...(wide && styles.listWide),
          ...style,
        }}
      >
        {guides.map(({ entrywayId, displayTitle, description }, i) => (
          <Item
            key={entrywayId}
            iconSource={getGuideImageByEntrywayId(entrywayId)}
            title={displayTitle}
            lead={description}
            onClick={() => onGuideClick(entrywayId)}
            style={{
              // Hack: convert guide list to material-ui components instead of spending time to fix the Flow error
              ...itemStyleToKeepFlowHappy,
              ...(i >= guides.length - (wide ? 2 : 1) && styles.lastItem),
            }}
          />
        ))}
      </div>
    );
  }
}
