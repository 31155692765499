import React, { Component } from 'react';
import { connect } from 'react-redux';

import colors from '../../common/colors';
import { externalLinkPressed } from '../../state/app/actions';
import { Body1 } from '../../common/components/typography';

const styles = {
  link: {
    textDecoration: 'none',
  },
  variant: {
    markdown: {
      textDecoration: 'underline',
      textDecorationColor: colors.red_500,
      textDecorationThickness: 2,
      textUnderlineOffset: 2,
    },
  },
  text: {
    display: 'inline',
    color: colors.red_500,
    fontWeight: 500,
  },
  hover: {
    color: colors.red_400,
  },
};

const isExternalLink = (href) => {
  if (!href) {
    return false;
  }

  if (!href.startsWith('#/')) {
    return true;
  }

  return false;
};

class Link extends Component {
  static defaultProps = {
    textComponent: Body1,
  };

  state = {
    hover: false,
  };

  onMouseEnter = () => this.setState({ hover: true });

  onMouseLeave = () => this.setState({ hover: false });

  onClick = (e) => {
    const { href, trackData } = this.props;
    if (isExternalLink(href)) {
      this.props.externalLinkPressed({ href, trackData });
    }
    this.props.onClick && this.props.onClick(e);
  };

  render() {
    const {
      textComponent: TextComponent,
      href,
      style,
      children,
      target,
      variant,
    } = this.props;

    const { hover } = this.state;
    const variantStyle = variant ? styles.variant[variant] : {};

    if (!href && !this.props.onClick) {
      console.error(
        'Href and onClick cannot both be empty. Provide one or the other.'
      );
    }

    return (
      <a
        href={href}
        target={target ? target : isExternalLink(href) ? '_blank' : '_self'}
        onClick={this.onClick}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        style={{ ...styles.link, ...variantStyle }}
      >
        <TextComponent
          style={{
            ...styles.text,
            ...(hover ? styles.hover : undefined),
            ...style,
          }}
        >
          {children}
        </TextComponent>
      </a>
    );
  }
}

export default connect(undefined, {
  externalLinkPressed,
})(Link);
