import { growthAnalyticsName } from '@mindoktor/patient-app/tracking/api/growthAnalyticsScript.web';
import { createQueue } from './queue';

const queue = createQueue(growthAnalyticsName);

/** @deprecated Use `@mindoktor/patient-app/tracking` instead. */
const sendFunnelEvent = (
  category: string,
  action: string,
  label: string,
  additionalProperties?: Record<string, unknown>
) => {
  queue.push('sendFunnelEvent', category, action, label, additionalProperties);
};

/** @deprecated Use `@mindoktor/patient-app/tracking` instead. */
const sendGenericEvent = (
  category: string,
  action: string,
  label: string,
  additionalProperties?: Record<string, unknown>
) => {
  queue.push('sendGenericEvent', category, action, label, additionalProperties);
};

/** @deprecated Use `@mindoktor/patient-app/tracking` instead. */
const doclyTracking = {
  sendFunnelEvent,
  sendGenericEvent,
};

export type DoclyTracking = typeof doclyTracking;

export default doclyTracking;
