import { parseRoute } from './utils';

export const ROUTING_OPEN = 'ROUTING_OPEN';
export const ROUTING_OPENED = 'ROUTING_OPENED';

export const openRoute = (path, props, options, meta) => ({
  type: ROUTING_OPEN,
  payload: {
    path,
    props: { ...props },
    options,
    timestamp: Date.now(),
  },
  meta,
});

export const openRouteFromUri = (uri, options) => {
  const { path, params } = parseRoute(uri);

  if (!path) {
    return undefined;
  }

  return openRoute(path, params, options);
};

export const openedRoute = ({ uri, path, props, options }) => ({
  type: ROUTING_OPENED,
  payload: { uri, path, props, options },
});
