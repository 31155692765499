import { login, LOGOUT, UPDATE_TOKEN } from '../../state/api/actions';
import { isLoggedIn } from '../../state/api/selectors';
import { parseRoute, routes } from '../../state/routing';
import { navigateToLogin } from '../routing';
import { routes as newRoutes } from '@mindoktor/patient-app/routing/web';

export default function middleware({ dispatch, getState }) {
  return (next) => (action) => {
    const { type, payload = {} } = action;
    const state = getState();

    switch (type) {
      case LOGOUT:
        const { initiatedByUser } = payload;
        const { path } = parseRoute(window.location.hash.slice(1));

        // TODO: Properly use doclyAnalytics through the tracking package.
        if (window.doclyAnalytics && window.doclyAnalytics.userLogout) {
          window.doclyAnalytics.userLogout();
        }

        // Avoid duplicate routing to login, typically due to simultaneous API calls responding
        // with unauthorized.
        if (
          !initiatedByUser &&
          path !== routes.LOGIN &&
          path !== newRoutes.LOGIN
        ) {
          navigateToLogin('');
        }

        break;

      case UPDATE_TOKEN: {
        const { token } = payload;

        if (!isLoggedIn(state)) {
          dispatch(login(token));
        }

        break;
      }
    }

    return next(action);
  };
}
