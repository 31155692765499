import React from 'react';

import {
  Stack,
  StackProps,
  SxProps,
  Theme,
  useMediaQuery,
  useTheme,
} from '@mui/material';

interface Props {
  direction?: StackProps['direction'];
  children: React.ReactNode;
  sx?: SxProps<Theme>;
}

const BottomSheetActions: React.FC<Props> = ({ children, sx, direction }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Stack
      direction={direction ?? (isSmallScreen ? 'column' : 'row')}
      padding={'2rem'}
      paddingTop={'1.5rem'}
      gap={'1rem'}
      flexWrap={'wrap'}
      justifyContent={'space-between'}
      sx={[...(Array.isArray(sx) ? sx : [sx])]}
    >
      {children}
    </Stack>
  );
};

export default BottomSheetActions;
