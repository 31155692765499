import { ajaxApi, ApiHeaders } from '../api';

const API_ENDPOINT_USER_NOTIFICATIONS_GET = '/api/v1/usernotifications/done';
import type { UserNotification } from './types';

/**
 * Post user notifications to be marked as done.
 */
const postMarkUserNotificationsAsDone = async (
  apiHeaders: ApiHeaders,
  readNotifications: UserNotification[]
): Promise<Error | null> => {
  if (readNotifications.length === 0) {
    return null;
  }
  const [_, err] = await ajaxApi(
    apiHeaders,
    {
      method: 'POST',
      url: API_ENDPOINT_USER_NOTIFICATIONS_GET,
    },
    { notifications: readNotifications }
  );
  return err;
};

export default postMarkUserNotificationsAsDone;
