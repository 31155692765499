import { _t } from '@mindoktor/patient-legacy/i18n';
import React, { Component } from 'react';

import { Body1, Title2 } from '../../../common/components/typography';
import { isContactTracingGuide } from '../../../state/guides/guidesUtils';
import Button from '../../components/button/button';
import Modal from '../../components/modal';
import { hideModal } from '../../routing';

const styles = {
  title: {
    paddingBottom: 10,
  },
};

const getTitleByEntrywayId = (entrywayId) => {
  return isContactTracingGuide(entrywayId)
    ? _t('case.invitation.contactTracing.about')
    : _t('case.invitation.about');
};

const getBodyByEntrywayId = (entrywayId) => {
  return isContactTracingGuide(entrywayId)
    ? _t('guides.internalReferral.contactTracing.information')
    : _t('guides.internalReferral.information');
};

export default class InternalReferralModal extends Component {
  render() {
    const { entrywayId } = this.props;

    return (
      <Modal
        body={
          <div>
            <Title2 style={styles.title}>
              {getTitleByEntrywayId(entrywayId)}
            </Title2>

            <Body1>{getBodyByEntrywayId(entrywayId)}</Body1>
          </div>
        }
        footer={
          <Button type="ghost" color="black" onClick={hideModal}>
            {_t('common.close')}
          </Button>
        }
      />
    );
  }
}
