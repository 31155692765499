import { _t } from '@mindoktor/patient-legacy/i18n';
import React from 'react';
import { connect } from 'react-redux';
import timm from 'timm';

import { formularySearch } from '../../../../state/app/actions';
import { getAbortText } from '../../../../state/formulary/api';

// export type ChildProps = {
//   onSelect: Drug => void,
//   onRemove: Drug => void,
//   search: string => Promise<Drug[]>,
//   entrywayId: number,
//   answer: Drug[],
//   max: number,
//   error?: string,
//   onSubmit: () => void,
//   hint?: string,
//   canAddMore?: boolean,
// };

// type Props = {
//   children: ChildProps => React.Component<ChildProps, any>,
//   formularySearch: typeof formularySearch,
//   hint?: string,
//   answer?: Drug[],
//   error?: string,
//   errorMessage?: string,
//   update: () => any,
//   next?: () => any,
//   source: { type: string, limit: number },
//   getAbortText: () => void,
//   entrywayId: number,
//   max: number,
// };

class MultipleSearchWrapper extends React.Component {
  search = async (query) => {
    if (!query.length) return [];
    const { formularySearch, source } = this.props;
    const { error, json } = await formularySearch(query, source);

    if (error) throw new Error('Error handling not implemented');

    return json;
  };

  onSelect = (drug) => {
    const { update, answer = [] } = this.props;
    if (drug.Forbidden) {
      console.error('Forbidden drug was allowed to be chosen');
      return;
    }
    if (answer.find((d) => d.Value === drug.Value)) return;
    update(timm.addLast(answer, drug));
  };

  onRemove = (drug) => {
    const { update, answer } = this.props;
    if (!answer) return;
    update(
      timm.removeAt(
        answer,
        this.props.answer.findIndex((d) => drug.Value === d.Value)
      )
    );
  };

  onSubmit = () => {
    const { error, next } = this.props;

    if (!error) {
      next();
    }
  };

  canAddMore = () => {
    const {
      props: { max, answer = [] },
    } = this;

    return !max || answer.length < max;
  };

  hasError = () => {
    const {
      props: { error, errorMessage },
    } = this;
    !!errorMessage && error !== 'required';
  };

  render() {
    const {
      onSelect,
      onRemove,
      onSubmit,
      props: {
        children,
        answer = [],
        entrywayId,
        max,
        hint = _t('choose_medication.hint'),
      },
    } = this;

    const renderProps = {
      onSelect,
      onRemove,
      onSubmit,
      answer,
      entrywayId,
      search: this.search,
      max,
      hint,
      canAddMore: !!this.canAddMore(),
      hasError: !!this.hasError(),
    };

    return children(renderProps);
  }
}

export default connect((s) => s, {
  formularySearch,
  getAbortText,
})(MultipleSearchWrapper);
