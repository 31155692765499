import { _t } from '@mindoktor/patient-legacy/i18n';
// This is needed to make this file work: https://stackoverflow.com/a/70933339
import regeneratorRuntime from 'regenerator-runtime';
// eslint-disable-next-line no-unused-vars
const _ = regeneratorRuntime; // Do not remove this import

export const getDisplayValue = (answer) => {
  const { type, options, max, unit, value } = answer;

  if (type === 'info') {
    return undefined;
  }

  if (
    value === undefined ||
    value === '' ||
    (Array.isArray(value) && !value.length)
  ) {
    return _t('formulary.displayValue.unanswered');
  }

  switch (type) {
    case 'binary':
      return {
        yes: _t('formulary.displayValue.yes'),
        no: _t('formulary.displayValue.no'),
      }[value];

    case 'choice': {
      const option =
        options && options.find((option) => option.value === value);
      return option ? option.label : value;
    }

    case 'multipleChoice':
      return value.map((v) => {
        const option = options && options.find((option) => option.value === v);
        return option ? option.label : v;
      });

    case 'number':
      return `${value} ${unit || ''}`;

    case 'range':
      return `${Math.round(value * 10) / 10}/${
        max !== undefined ? max.value : 10
      }`;

    case 'tertiary':
      return {
        yes: _t('formulary.displayValue.yes'),
        no: _t('formulary.displayValue.no'),
        unknown: _t('formulary.displayValue.unknown'),
      }[value];

    default:
      return String(value);
  }
};

export const getDisplayError = (question, value) => {
  const { optional, min, max } = question;

  if (
    !optional &&
    (value === undefined ||
      (typeof value === 'string' && value.trim() === '') ||
      (Array.isArray(value) && !value.length))
  ) {
    return _t('formulary.error.required');
  }

  if (typeof value === 'number') {
    if (min !== undefined && value < min.value) {
      return _t('formulary.error.min');
    }

    if (max !== undefined && value > max.value) {
      return _t('formulary.error.max');
    }
  }

  return undefined;
};

export const parseInquisition = ({ name, script }) => {
  const collector = {};
  const fnString = `
      var window = this.window || window;
      ${script};
  `;
  new Function(fnString).call({ window: collector }); // eslint-disable-line no-new-func

  const fn = collector[name];

  return fn;
};
