// Todo: rename
export const getFormularyState = ({ formulary }, formularyKey) => {
  const data = formulary.byKey[formularyKey];
  if (!data) return null;
  if (data.type === 'formulary') return data;
  throw new Error('Expected formulary, not ' + data.type);
};

export const getQuestionnaireData = ({ formulary }, formularyKey) => {
  const data = formulary.byKey[formularyKey];
  if (!data) return null;
  if (data.type === 'questionnaire') return data;
  throw new Error('Expected questionnaire, not ' + data.type);
};

export const getFormularyDatas = ({ formulary: { byKey } }) => byKey;

// getPreferredCaregiverId returns already set caregiver id for draft or value from URL.
export const getPreferredCaregiverId = (state, draftId) => {
  const {
    drafts,
    routing: { props },
  } = state;
  const existingDraft = drafts.draftById[draftId] || {};
  return (
    existingDraft.preferredCaregiverId ||
    parseInt(props.preferredCaregiverId, 10)
  );
};
