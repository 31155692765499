import { routes } from '../../state/routing';

import { navigate } from '../routing';

import ConfirmationScreen from './confirmation';
import VerifyEmailScreen from './verify';
import VerifyByLinkScreen from './verify_by_link';
import DoneAlreadyVerifiedScreen from './done_already_verified';

interface ContactVerificationRouteOptions {
  props: {
    caseId: string;
  };
}

interface ContactVerificationByLinkOptions {
  props: {
    type: string;
    token: string;
  };
}

const navigation = {
  [routes.CONTACT_INFO_VERIFICATION_GUIDE_CONFIRMATION]: ({
    props: { caseId },
  }: ContactVerificationRouteOptions) => {
    navigate(ConfirmationScreen, {
      caseId: parseInt(caseId, 10),
    });
  },

  [routes.CONTACT_INFO_VERIFICATION_GUIDE_VERIFICATION]: ({
    props: { caseId },
  }: ContactVerificationRouteOptions) => {
    navigate(VerifyEmailScreen, {
      caseId: parseInt(caseId, 10),
    });
  },

  [routes.CONTACT_INFO_VERIFICATION_GUIDE_COMPLETED_ALREADY_VERIFIED]: ({
    props: { caseId },
  }: ContactVerificationRouteOptions) => {
    navigate(DoneAlreadyVerifiedScreen, {
      caseId: parseInt(caseId, 10),
    });
  },

  // Link confirmation used in the Kiosk email verification flow
  [routes.CONTACT_INFO_VERIFICATION_BY_LINK]: ({
    props: { type, token },
  }: ContactVerificationByLinkOptions) => {
    navigate(VerifyByLinkScreen, { type, token });
  },
};

export default navigation;
