import Config from '@mindoktor/env/Config';

import faqInitialCategoriesSE from './faq-se-initial-categories.json';
import faqInitialCategoriesUK from './faq-uk-initial-categories.json';
import { FAQ_FETCH_SUCCESS } from './types';
function getInitialCategories() {
  let categories;

  switch (Config.FaqFile) {
    case 'se': {
      categories = faqInitialCategoriesSE;
      break;
    }
    case 'uk': {
      categories = faqInitialCategoriesUK;
      break;
    }
  }
  return categories;
}
const initialCategories = getInitialCategories();

const initialState = {
  categories: [...(initialCategories ?? [])],
  lastUpdated: 0,
};

export default function faqReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case FAQ_FETCH_SUCCESS: {
      return {
        ...state,
        categories: payload.data,
        lastUpdated: Date.now(),
      };
    }

    default:
      return state;
  }
}
