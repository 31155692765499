import { v4 as uuidv4 } from 'uuid';

import { readCookieValue, setCookie } from '../cookies';

/**
 * This function will try to get the sessionId from cookies.
 * If it is not found it will generate a new uuid and set the cookie.
 */
export const getSessionId = () => {
  // this cookie name is 'special' since we are using it across
  // different subdomains and products (Ie. dr-static, clinic ect.).
  const cookieName = 'md-session-id';

  // Query string precedes cookie.
  const urlParams = new URLSearchParams(window.location.search);
  const sessionIDFromQueryString = urlParams.get(cookieName);
  if (sessionIDFromQueryString !== null && sessionIDFromQueryString !== '') {
    setCookie(cookieName, sessionIDFromQueryString, undefined);
    return sessionIDFromQueryString;
  }

  const sidFromCookie = readCookieValue(cookieName);
  if (sidFromCookie != null) {
    return sidFromCookie;
  }

  const sid = uuidv4();
  setCookie(cookieName, sid, undefined);
  return sid;
};
