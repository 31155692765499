import dayjs from 'dayjs';

import { getAge } from '../profile/profileUtils';

/**
 * @description
 * The patient should get an answer in four hours from the first
 * whole hour after the case was submitted.
 * For example, if a case was submitted 23:01,
 * an answer is expected before 04:00.
 * @returns dayjs timestamp
 */

export function matchingDeadline(
  submittedTimeString = dayjs(),
  respondWithinHours = 4
) {
  // expect UTC timestamp
  const timestamp = dayjs.utc(submittedTimeString);
  const roundUp = timestamp.minute() > 0 ? 1 : 0;

  return timestamp.startOf('hour').add(respondWithinHours + roundUp, 'hours');
}

/**
 * @description
 * NOTE 2017-05-22 it will always remove false, temporary until doctors
 * have better control in waiting room
 * The patient should get a more exact time for answer between 05-21
 * Otherwise a more vague time will be sent
 * NOTE: Should really be time in Sweden and not local but we don't have that
 * extension for dayjs. And this is not a forever solution so cheating that
 * Swedentime = utc - two hours
 * For example, if a case was submitted 23:00, it will be false
 * For example, if a case was submitted 14:00, it will be true
 * @returns true or false
 */
export function shouldShowTimeHeader(/* submittedTimeString */) {
  // Temporary 2017-05-22 just return false
  return false;
  /*
  // Expect UTC timestamp
  const createdUTC = dayjs.utc(submittedTimeString);
  const hour = createdUTC.hours();
  let showTime = true;
  if (hour <= 3 || hour >= 19) {
    showTime = false;
  }
  return showTime;
  */
}

export const isUpperAdolescent = (birthDate, independentCareAge, adultAge) => {
  const age = getAge(birthDate).years;

  return age >= independentCareAge && age < adultAge;
};

/**
 * Checks if the age of the patient is above the threshold for seeking care independently
 * of a guardian.
 * @param {} birthDate - the birth date of the patient
 * @param {*} independentCareAge - the age when you are allowed to seek care without a guardian
 * @returns true if the patient can seek care on their own, false if they need a guardian.
 */
export const isIndependentCareSeeker = (birthDate, independentCareAge) => {
  const age = getAge(birthDate).years;

  return age >= independentCareAge;
};
