import withStyles from '@material-ui/core/styles/withStyles';
import { _t } from '@mindoktor/patient-legacy/i18n';
import React from 'react';
import { connect } from 'react-redux';

import colors from '../../common/colors';
import { Body1, Title1 } from '../../common/components/typography';
import { openRoute, routes as legacyRoutes } from '../../state/routing';
import compose from '@mindoktor/patient-legacy/src/common/compose';
import { withRouting } from '@mindoktor/patient-app/routing/HOC/withRouting.web';
import { routes } from '@mindoktor/patient-app/routing/constants/webRoutes';
import Button from '../components/button/button';
import Body from '../inquisitions/components/Body';
import FadeTransition from '../inquisitions/components/FadeTransition';
import Frame from '../inquisitions/components/Frame';
import Header from '../inquisitions/components/Header';
import Logo from '../inquisitions/components/Logo';
import CancelModal from './components/CancelModal';

// type Props = {
//   surveyName: string,
//   openRoute: typeof openRoute,
// };

// type State = {
//   cancelModal: boolean,
// };

// Fix for React.createRef() misbehaving for flow
// type RefObject = {
//   current: any,
// };

class IntroScreen extends React.Component {
  state = {
    cancelModal: false,
  };

  transitionRef = React.createRef();
  transition = async () =>
    this.transitionRef.current && this.transitionRef.current.transition();

  openCancelModal = () => this.setState({ cancelModal: true });
  closeCancelModal = () => this.setState({ cancelModal: false });

  cancel = () => {
    const {
      routing: { navigation },
    } = this.props;

    navigation.navigate(routes.CASES);
  };

  continue = () => {
    const { openRoute, surveyName } = this.props;

    openRoute(legacyRoutes.USER_SURVEY, { surveyName });
  };

  render() {
    const { classes } = this.props;
    const { cancelModal } = this.state;

    return (
      <>
        <Body>
          <Header />

          <FadeTransition ref={this.transitionRef}>
            <Frame>
              <Logo className={classes.logo} />

              <Title1 className={classes.title}>
                {_t('usersurvey.introTitle')}
              </Title1>

              <Body1 className={classes.body}>
                {_t('usersurvey.introText')}
              </Body1>

              <div>
                <Button
                  className={classes.cancelButton}
                  type="solid"
                  color="white"
                  onClick={this.openCancelModal}
                >
                  {_t('common.cancel')}
                </Button>

                <Button type="solid" color="black" onClick={this.continue}>
                  {_t('common.continue')}
                </Button>
              </div>
            </Frame>
          </FadeTransition>
        </Body>

        <CancelModal
          open={cancelModal}
          close={this.closeCancelModal}
          cancel={this.cancel}
        />
      </>
    );
  }
}

const enhance = compose(
  withRouting,
  connect(
    (state, props) => {
      return {
        ...props,
      };
    },
    {
      openRoute,
    }
  ),
  withStyles(({ spacing }) => ({
    title: {
      marginBottom: spacing(2),
    },

    body: {
      marginBottom: spacing(4),
    },

    cancelButton: {
      borderWidth: '1px !important',
      borderColor: `${colors.off_black} !important`,
      marginBottom: spacing(2),
    },

    logo: {
      marginBottom: spacing(3),
    },
  }))
);

export default enhance(IntroScreen);
