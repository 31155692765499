import { actionTypes } from './actions';
import type { Action } from './actions';
import type { Reducer } from 'redux';
import { SupportTicket } from '../../api/helpCenter/types';

const initialState = {
  tickets: [] as SupportTicket[],
};

const reducer: Reducer<typeof initialState, Action> = (state, action) => {
  switch (action.type) {
    case actionTypes.ACTION_SET_TICKETS:
      const typedAction = action as Action<SupportTicket[]>;
      return {
        ...state,
        tickets: typedAction.payload,
      };
    default:
      return state || initialState;
  }
};

export default reducer;
