import React from 'react';

import colors from '../../common/colors';
import MediaQuery from './media_query';
import Screen from './screen';

const styles = {
  outer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
    backgroundColor: colors.grey_100,
  },

  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
    maxWidth: 1000,
    margin: '0 auto',
    backgroundColor: colors.white,
  },

  inner: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },

  bodyWide: {
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
  },

  footerWide: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
  },

  verticalPadder: {
    // Vertical padding should be 5 % of window height, but min 40 px and max 80 px.
    height: Math.max(40, Math.min(80, 0.05 * window.innerHeight)),
    backgroundColor: colors.grey_100,
  },

  scrollOverlay: {
    maxWidth: 1000,
    margin: '0 auto',
  },
};

const Wrapper = ({ children, innerStyle }) => (
  <div style={styles.outer}>
    <div style={styles.wrapper}>
      <div style={{ ...styles.inner, ...innerStyle }}>{children}</div>
    </div>
  </div>
);

const VerticalPadder = () => <div style={styles.verticalPadder} />;

// type Props = {
//   header?: any,
//   body?: any,
//   footer?: any,
//   showScrollGradient?: boolean,
//   showScrollIndicator?: boolean,
//   staticFooter?: Boolean,
// };

export default class BoxedScreen extends React.Component {
  render() {
    const {
      header,
      body,
      footer,
      staticFooter,
      showScrollGradient,
      showScrollIndicator,
    } = this.props;

    return (
      <Screen
        header={
          <div>
            <MediaQuery minWidth={801} minHeight={801}>
              <VerticalPadder />
            </MediaQuery>

            <Wrapper>{header}</Wrapper>
          </div>
        }
        body={[
          <MediaQuery key="narrow" maxWidth={800}>
            <Wrapper>{body}</Wrapper>
          </MediaQuery>,

          <MediaQuery key="wide" minWidth={801}>
            <Wrapper innerStyle={styles.bodyWide}>{body}</Wrapper>
          </MediaQuery>,
        ]}
        footer={
          <div>
            <MediaQuery maxWidth={800}>
              <Wrapper>{footer}</Wrapper>
            </MediaQuery>

            <MediaQuery minWidth={801}>
              <Wrapper innerStyle={styles.footerWide}>{footer}</Wrapper>
            </MediaQuery>

            <MediaQuery minWidth={801} minHeight={801}>
              <VerticalPadder />
            </MediaQuery>
          </div>
        }
        staticFooter={staticFooter}
        showScrollIndicator={showScrollIndicator}
        showScrollGradient={showScrollGradient}
        scrollOverlayStyle={styles.scrollOverlay}
      />
    );
  }
}
