export const WeekDays = {
  MONDAY: 0,
  TUESDAY: 1,
  WEDNESDAY: 2,
  THURSDAY: 3,
  FRIDAY: 4,
  SATURDAY: 5,
  SUNDAY: 6,
} as const;

export type WeekDayType = (typeof WeekDays)[keyof typeof WeekDays];

type zeroToNine = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9';
export type HH = `${'0' | '1'}${zeroToNine}` | '20' | '21' | '22' | '23';

type md = '0' | '1' | '2' | '3' | '4' | '5';
type mu = zeroToNine;
export type mm = `${md}${mu}`;
export type ss = mm;
export type TimeString = `${HH}:${mm}`;

// 2022-07-06T08:20:44.653447Z This format is too complex for ts to validate
export type StandardTimeStamp = string;

type TimeOpening = {
  from: TimeString;
  to: TimeString;
};

export interface Openings {
  days: {
    from: WeekDayType;
    to: WeekDayType;
  };
  times: TimeOpening[];
}
