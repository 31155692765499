import { _t } from '@mindoktor/patient-legacy/i18n';
import isEqual from 'lodash-es/isEqual';
import React from 'react';
import { connect } from 'react-redux';

import RetakeItem from '../../../common/cases/messages/RetakeItem';
import WrongAttachment from '../../../common/cases/messages/WrongAttachment';
import { constructUrl, WEB_URL } from '../../../state/api';
import { APOTEKET_EGENVARD_LINK } from '../../../state/apoteket/constants';
import { MD_LISTING_LINK, MessageType } from '../../../state/cases/constants';
import { fetchUserProfile } from '../../../state/profile/actions';
import { getGuideImageByEntrywayId } from '../../guides/utils/images';
import { hideModal, showModal } from '../../routing';
import { getSite } from '../../utils/site';
import MarketingLinkItem from './components/marketing_link_item';
import CertificateItem from './items/certificate';
import ClosedCase from './items/closed_case';
import ResumeCase from './items/closed_resume_case';
import DoctorFileItem from './items/doctor_file';
import DoctorTextItem from './items/doctor_text';
import ImageItem from './items/image';
import IntroItem from './items/intro';
import InvitationItem from './items/invitation';
import NewMessagesDivider from './items/new_messages_divider';
import PatientFileItem from './items/patient_file';
import PatientTextItem from './items/patient_text';
import PaymentInfoFree from './items/paymentinfo_free';
import PaymentInfoInvoice from './items/paymentinfo_invoice';
import PrescriptionItem from './items/prescription';
import Questionnaire from './items/questionnaire';
import ReferralItem from './items/referral';
import SharedReferralResultsItem from './items/shared_referral_results';
import VideoItem from './items/video';
import VideoAppointmentScheduled from './items/video_appointment_scheduled';
import VideocallItem from './items/videocall';
import VideocallInvitation from './items/videocall_invitation';
import VideochatItem from './items/videochat';
import WorkoutItem from './items/workout';

const isFromPatient = ({ type, fromDoctor }) =>
  (type === MessageType.TEXT && !fromDoctor) ||
  type === MessageType.IMAGE ||
  type === MessageType.VIDEO ||
  type === MessageType.FILE;

class List extends React.Component {
  componentDidMount() {
    this.props.fetchUserProfile();
  }

  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.messages, nextProps.messages);
  }

  render() {
    const {
      caseId,
      entrywayId,
      messages,
      onUpdate,
      profile,
      style,
      uploadPhoto,
      uploadVideo,
      hasTechnicalError,
      hasPayment,
      doctorImageUrl,
    } = this.props;
    return (
      <div style={style}>
        {messages.reverse().map((message, i) => {
          const { type, cId, timestampUnix } = message;

          const fromPatient = isFromPatient(message);

          const style = {
            width: 'calc(100% - 32px)',
            marginLeft: fromPatient ? 'auto' : 0,
            marginBottom: i < messages.length - 1 ? 16 : 0,
          };

          const timestamp = parseInt(timestampUnix || 0, 10) * 1000;

          switch (type) {
            case MessageType.TEXT: {
              const {
                id,
                avatar: avatarUrl,
                name,
                signature,
                sendStatus: status,
                text,
              } = message;

              return fromPatient ? (
                <PatientTextItem
                  key={`${type}.${id}`}
                  caseId={caseId}
                  entrywayId={entrywayId}
                  cId={cId}
                  timestamp={timestamp}
                  status={status}
                  text={text}
                  style={style}
                />
              ) : (
                <DoctorTextItem
                  key={`${type}.${id}`}
                  avatarUrl={avatarUrl}
                  name={name}
                  signature={signature}
                  timestamp={timestamp}
                  text={text}
                  style={style}
                />
              );
            }

            case MessageType.IMAGE: {
              const {
                attachmentId: attachmentId,
                fromPatient,
                imagePath: path,
                sendStatus: status,
                attachmentFileReferenceNumber: attachmentReferenceNumber,
              } = message;

              return (
                <ImageItem
                  key={`${type}.${attachmentId || path}-${i}`}
                  caseId={caseId}
                  timestamp={timestamp}
                  attachmentId={attachmentId}
                  attachmentReferenceNumber={attachmentReferenceNumber}
                  fromPatient={fromPatient}
                  path={path}
                  status={status}
                  style={style}
                />
              );
            }

            case MessageType.RETAKE_IMAGE: {
              return (
                <RetakeItem
                  path={message.imagePath}
                  key={`${type}.${
                    message.attachmentId || message.imagePath
                  }-${i}`}
                  timestamp={timestamp}
                  attachmentId={message.attachmentId}
                  reasonDescription={message.reasonDescription}
                  freetext={message.freetext}
                  caseId={caseId}
                  uploadPhoto={uploadPhoto}
                  showModal={showModal}
                  hideModal={hideModal}
                  imageSource={constructUrl({
                    url: `/api/v1/files/${message.attachmentId}`,
                    params: { thumb: 'true', site: getSite() },
                  })}
                />
              );
            }

            case MessageType.RETAKE_VIDEO: {
              return (
                <RetakeItem
                  path={message.imagePath}
                  key={`${type}.${
                    message.attachmentId || message.imagePath
                  }-${i}`}
                  timestamp={timestamp}
                  attachmentId={message.attachmentId}
                  reasonDescription={message.reasonDescription}
                  freetext={message.freetext}
                  caseId={caseId}
                  uploadVideo={uploadVideo}
                  showModal={showModal}
                  hideModal={hideModal}
                  imageSource={constructUrl({
                    url: `/api/v1/files/${message.attachmentId}`,
                    params: { thumb: 'true', site: getSite() },
                  })}
                />
              );
            }

            case MessageType.WRONG_ATTACHMENT: {
              return <WrongAttachment timestamp={message.timestamp} />;
            }

            case MessageType.CERTIFICATE: {
              const { id, status, data, certificateType } = message;

              return (
                <CertificateItem
                  key={`${type}.${id}`}
                  id={id}
                  timestamp={timestamp}
                  style={style}
                  status={status}
                  certificateType={certificateType}
                  data={data}
                />
              );
            }

            case MessageType.VIDEO: {
              const {
                attachmentId: attachmentId,
                attachmentFileReferenceNumber: attachmentReferenceNumber,
                attachmentUri: path,
                fromPatient,
                sendStatus: status,
              } = message;

              return (
                <VideoItem
                  key={`${type}.${attachmentId || path}`}
                  timestamp={timestamp}
                  attachmentId={attachmentId}
                  attachmentReferenceNumber={attachmentReferenceNumber}
                  caseId={caseId}
                  fromPatient={fromPatient}
                  path={path}
                  status={status}
                  style={style}
                />
              );
            }

            case MessageType.FILE: {
              const {
                attachmentContext,
                attachmentId: attachmentId,
                attachmentName,
                attachmentOriginalName,
                fromPatient,
              } = message;

              return fromPatient ? (
                <PatientFileItem
                  key={`${type}.${attachmentId}`}
                  timestamp={timestamp}
                  attachmentId={attachmentId}
                  attachmentName={attachmentName}
                  attachmentOriginalName={attachmentOriginalName}
                  style={style}
                />
              ) : (
                <DoctorFileItem
                  key={`${type}.${attachmentId}`}
                  caseId={caseId}
                  timestamp={timestamp}
                  attachmentContext={attachmentContext}
                  attachmentId={attachmentId}
                  attachmentName={attachmentName}
                  attachmentOriginalName={attachmentOriginalName}
                  style={style}
                />
              );
            }

            case MessageType.VIDEOCALL: {
              const {
                id,
                created,
                updated,
                doctorName,
                doctorImageUrl,
                startTime,
                videoDate,
                isInPast,
                isOutdatedAndUnconfirmed,
                isPending,
                status,
              } = message;
              return (
                <VideocallItem
                  key={`${type}.${id}`}
                  id={id}
                  cId={cId}
                  caseId={caseId}
                  created={created}
                  updated={updated}
                  doctorName={doctorName}
                  doctorImageUrl={doctorImageUrl}
                  startTime={startTime}
                  videoDate={videoDate}
                  isInPast={isInPast}
                  isOutdatedAndUnconfirmed={isOutdatedAndUnconfirmed}
                  isPending={isPending}
                  status={status}
                  style={style}
                />
              );
            }

            case MessageType.PRESCRIPTION: {
              const { id, drugs, prescriptionType } = message;

              return (
                <PrescriptionItem
                  key={`${type}.${id}`}
                  type={prescriptionType}
                  drugs={drugs}
                  timestamp={timestamp}
                  style={style}
                />
              );
            }

            case MessageType.APH_LINK: {
              const { type, cId } = message;
              return (
                <MarketingLinkItem
                  key={`${type}.${cId}`}
                  timestamp={timestamp}
                  url={APOTEKET_EGENVARD_LINK}
                  linkTitle={_t('aphLink.linkText')}
                  variant="aph"
                >
                  {_t('aphLink.title')}
                </MarketingLinkItem>
              );
            }

            case MessageType.LISTING_LINK: {
              const { cId, type } = message;

              return (
                <MarketingLinkItem
                  key={`${type}.${cId}`}
                  url={MD_LISTING_LINK}
                  linkTitle={_t('mdListingLinkNykoping.linkText')}
                >
                  {_t('mdListingLinkNykoping.text')}
                </MarketingLinkItem>
              );
            }

            case MessageType.VACCINATION_TBE: {
              const { type, cId } = message;
              const findClinicUrl = `${WEB_URL}/kliniken/mottagningar/`;

              return (
                <MarketingLinkItem
                  key={`${type}.${cId}`}
                  url={findClinicUrl}
                  linkTitle={_t('vaccination.marketingcard.button')}
                  variant="tbeVaccination"
                >
                  {_t('vaccination.marketingcard.tbe.label')}
                </MarketingLinkItem>
              );
            }

            case MessageType.FLU_SEASON_LINK: {
              const { type, cId } = message;
              const findClinicUrl = `${WEB_URL}/influensa/`;

              return (
                <MarketingLinkItem
                  key={`${type}.${cId}`}
                  timestamp={timestamp}
                  url={findClinicUrl}
                  linkTitle={_t('vaccination.marketingcard.seasonalFlu.button')}
                  variant="seasonalFluVaccination"
                >
                  {_t('vaccination.marketingcard.seasonalFlu.label')}
                </MarketingLinkItem>
              );
            }

            case MessageType.FORMULARY_ANSWERS: {
              const iconSource = getGuideImageByEntrywayId(entrywayId);
              const { firstName } = profile;
              return (
                <IntroItem
                  key={`${type}.answers`}
                  caseId={caseId}
                  iconSource={iconSource}
                  style={style}
                  hasTechnicalError={hasTechnicalError}
                  hasPayment={hasPayment}
                  firstName={firstName}
                />
              );
            }

            case MessageType.REFERRAL: {
              const {
                id: messageId,
                cId,
                referralId,
                status,
                eReferral,
                referralType,
                labInfo,
                testTags,
              } = message;

              return (
                <ReferralItem
                  key={`${type}.${messageId}`}
                  caseId={caseId}
                  messageId={messageId}
                  cId={cId}
                  referralId={referralId}
                  status={status}
                  eReferral={eReferral}
                  referralType={referralType}
                  labInfo={labInfo}
                  testTags={testTags}
                  timestamp={timestamp}
                  onUpdate={onUpdate}
                  style={style}
                />
              );
            }

            case MessageType.WORKOUT: {
              const { id, title, exercises } = message;

              return (
                <WorkoutItem
                  key={`${type}.${id}`}
                  caseId={caseId}
                  workoutId={id}
                  title={title}
                  timestamp={timestamp}
                  numberOfExercises={exercises.length}
                  style={style}
                />
              );
            }

            case MessageType.INVITATION_SCHEDULED: {
              const {
                opens,
                closes,
                status,
                typeName,
                targetEntrywayId,
                id,
                childUuid,
                isOpen,
                caseOwnerCanRepresentPatient,
              } = message;

              return (
                <InvitationItem
                  key={`${type}.${id}`}
                  childUuid={childUuid || undefined}
                  typeName={typeName}
                  revisitId={id}
                  isOpen={isOpen}
                  revisitOpens={opens}
                  revisitCloses={closes}
                  revisitStatus={status}
                  caseOwnerCanRepresentPatient={caseOwnerCanRepresentPatient}
                  targetEntrywayId={targetEntrywayId}
                  style={style}
                />
              );
            }

            case MessageType.VIDEOCHAT_ACTIVE: {
              return (
                <VideochatItem
                  key={`${type}.videochat`}
                  caseId={caseId}
                  style={style}
                />
              );
            }

            case MessageType.SHARED_REFERRAL_RESULTS: {
              return (
                <SharedReferralResultsItem
                  caseId={caseId}
                  referralId={message.referralId}
                  style={style}
                  timestamp={timestamp}
                />
              );
            }

            case MessageType.NEW_MESSAGES_DIVIDER: {
              const { type, cId } = message;

              return <NewMessagesDivider key={`${type}.${cId}`} />;
            }

            case MessageType.QUESTIONNAIRE: {
              const { formularyKey, questions, caseId, surveyName } = message;

              return formularyKey ? (
                <Questionnaire
                  style={style}
                  key={formularyKey}
                  formularyKey={formularyKey}
                  questions={questions}
                  timestamp={timestamp}
                  caseId={caseId}
                  surveyName={surveyName}
                />
              ) : null;
            }

            case MessageType.CLOSED_CASE: {
              return (
                <ClosedCase
                  key={message.cId}
                  style={style}
                  body={message.body}
                  timestamp={message.timestamp}
                  openRevisitInvitation={message.openRevisitInvitation}
                />
              );
            }

            case MessageType.RESUME_CLOSED_CASE: {
              return (
                <ResumeCase
                  key={message.cId}
                  style={style}
                  body={message.body}
                  timestamp={message.timestamp}
                  resumeCaseInfo={message.resumeCaseInfo}
                  caseId={caseId}
                />
              );
            }

            case MessageType.VIDEOCALL_INVITATION: {
              const { id, doctorName, doctorId } = message;
              return (
                <VideocallInvitation
                  key={`${type}.${id}`}
                  caseId={caseId}
                  caregiverId={doctorId}
                  doctorName={doctorName}
                  doctorImageUrl={doctorImageUrl}
                  style={style}
                />
              );
            }
            case MessageType.SCHEDULED_APPOINTMENT: {
              const { id, appointment, isPending } = message;

              return (
                <VideoAppointmentScheduled
                  key={`${type}.${id}`}
                  appointment={appointment}
                  style={style}
                  showCancelButton={true}
                  showStartButton={isPending}
                  caseId={caseId}
                />
              );
            }

            case MessageType.PAYMENTINFO_INVOICE: {
              const { paymentOverview } = message;
              return (
                <PaymentInfoInvoice
                  key={`${type}.${cId}`}
                  url={paymentOverview?.payload.URL}
                  style={style}
                />
              );
            }
            case MessageType.PAYMENTINFO_FREE: {
              const { paymentOverview } = message;
              return (
                <PaymentInfoFree
                  paymentOverview={paymentOverview}
                  key={`${type}.${cId}`}
                  style={style}
                />
              );
            }
          }

          return null;
        })}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    profile: state?.profile,
  }),
  {
    fetchUserProfile,
  },
  null,
  { forwardRef: true }
)(List);
