import casesNavigation from '@mindoktor/patient-legacy/src/web/cases/navigation';
import childrenNavigation from '@mindoktor/patient-legacy/src/web/children/navigation';
import contactInfoVerificationNavigation from '@mindoktor/patient-legacy/src/web/contact_info_verification/navigation';
import healthProfileNavigation from '@mindoktor/patient-legacy/src/web/healthprofile/navigation';
import helpCenterIdentityProtection from '@mindoktor/patient-legacy/src/web/helpcenter/navigation';
import inquisitionsNavigation from '@mindoktor/patient-legacy/src/web/inquisitions/navigation';
import loginNavigation from '@mindoktor/patient-legacy/src/web/login/navigation';
import menuNavigation from '@mindoktor/patient-legacy/src/web/menu/navigation';
import paymentNavigation from '@mindoktor/patient-legacy/src/web/payment/navigation';
import userSurveyNavigation from '@mindoktor/patient-legacy/src/web/user_survey/navigation';
import vaccinationBookNavigation from '@mindoktor/patient-legacy/src/web/vaccination_book/navigation';
import videochatNavigation from '@mindoktor/patient-legacy/src/web/videochat/navigation';

export const legacyNavigationObject = {
  ...casesNavigation,
  ...childrenNavigation,
  ...contactInfoVerificationNavigation,
  ...helpCenterIdentityProtection,
  ...healthProfileNavigation,
  ...inquisitionsNavigation,
  ...loginNavigation,
  ...menuNavigation,
  ...paymentNavigation,
  ...userSurveyNavigation,
  ...vaccinationBookNavigation,
  ...videochatNavigation,
};
