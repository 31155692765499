import isEqual from 'lodash-es/isEqual';
import keys from 'lodash-es/keys';

let fnString;

function compare(values, defaultResult, fn) {
  let i, result;

  if (values.length < 2) return false;

  for (i = 1; i < values.length; i++) {
    try {
      result = fn(values[0], values[i]);
      if (result !== undefined) return result;
    } catch (e) {
      return false;
    }
  }

  return defaultResult;
}

function contains() {
  return compare(arguments, true, (a, b) => {
    if (a.indexOf(b) < 0) return false;
    return undefined;
  });
}

function any() {
  return compare(arguments, false, (a, b) => {
    if (isEqual(a, b)) return true;
    return undefined;
  });
}

function eq() {
  return compare(arguments, true, (a, b) => {
    if (!isEqual(a, b)) return false;
    return undefined;
  });
}

function lt() {
  return compare(arguments, true, (a, b) => {
    if (a >= b) return false;
    return undefined;
  });
}

function gt() {
  return compare(arguments, true, (a, b) => {
    if (a <= b) return false;
    return undefined;
  });
}

function length(value) {
  return value && value.length ? value.length : 0;
}

function filter(map) {
  return keys(map).filter((k) => {
    return !!map[k];
  });
}

fnString =
  '"use strict";' +
  'var contains = this.contains;' +
  'var any = this.any;' +
  'var eq = this.eq;' +
  'var lt = this.lt;' +
  'var gt = this.gt;' +
  'var get = this.get;' +
  'var length = this.length;' +
  'var filter = this.filter;' +
  'return $1;';

function evaluate(code, context) {
  if (!code) return undefined;
  try {
    // Adding 'eslint-disable-next-line' since refactor and testing would be really tedious
    // -- Christoffer, July 25, 2016
    // eslint-disable-next-line no-new-func
    return new Function(fnString.replace('$1', code)).call(context);
  } catch (e) {
    console.error(e, code);
  }
  return undefined;
}

export default function Evaluators(data) {
  const context = {
    contains,
    any,
    eq,
    lt,
    gt,
    length,
    filter,
    get: (name) => {
      const matches = /^([^.]*).([^$]*)/.exec(name);

      if (matches && matches.length > 2) {
        return data && data[matches[1]] && data[matches[1]][matches[2]];
      }

      return undefined;
    },
  };

  return (code) => {
    return evaluate(code, context);
  };
}
