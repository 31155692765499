import React, { forwardRef } from 'react';

import Stack, { StackProps } from '@mui/material/Stack';

import {
  OnLayoutFunction,
  useOnLayout,
} from '@mindoktor/patient-app/utils/layout/web';

export const scrollViewStyle = (scrollBar: boolean = false) => ({
  overflowY: 'auto',

  ...(!scrollBar && {
    // Hide scrollbars
    scrollbarWidth: 'none', // Standard, Firefox
    '&::-webkit-scrollbar': {
      // Webkit, Blink
      width: 0,
    },
  }),
});

interface OwnProps {
  scrollBar?: boolean;
  onLayout?: OnLayoutFunction;
}

type Props = OwnProps & Omit<StackProps, 'ref'>;

const ScrollView = forwardRef<HTMLDivElement | null, Props>(
  (
    { scrollBar = false, onLayout, sx, children, ...stackProps },
    ref = null
  ) => {
    const _ref = (ref ??
      null) as React.MutableRefObject<HTMLElement | null> | null;

    useOnLayout(_ref, onLayout);

    return (
      <Stack
        ref={ref}
        sx={[
          scrollViewStyle(scrollBar),
          // You cannot spread `sx` directly because `SxProps` (typeof sx) can be an array.
          // See: https://mui.com/system/getting-started/the-sx-prop/#passing-the-sx-prop
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        {...stackProps}
      >
        {children}
      </Stack>
    );
  }
);

ScrollView.displayName = 'ScrollView';

export type ScrollViewProps = Props;
export default ScrollView;
