import { _t } from '@mindoktor/patient-legacy/i18n';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Textarea from 'react-textarea-autosize';

import colors from '../../../common/colors';
import { fonts } from '../../../common/components/typography';
import {
  postPhotoMessage,
  postTextMessage,
  postVideoMessage,
} from '../../../state/cases/actions';
import { hasImageExtension } from '../../../state/files/utils';
import { chooseFiles, uploadFile } from '../../utils/files';

const styles = {
  root: {
    backgroundColor: 'white',
    borderTop: `2px solid ${colors.off_black}`,
  },

  inner: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    width: '100%',
    maxWidth: 800,
    margin: '0 auto',
  },

  upload: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 32,
    height: 50,
    paddingLeft: 16,
    paddingRight: 16,
    cursor: 'pointer',
  },

  uploadIcon: {
    display: 'block',
    width: 32,
    height: 32,
  },

  textarea: {
    display: 'block',
    width: '100%',
    fontFamily: fonts.body,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '24px',
    padding: 0,
    paddingTop: 13,
    paddingBottom: 13,
    margin: 0,
    border: 'none',
    background: 'none',
    outline: 'none',
    resize: 'none',
  },

  send: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 32,
    height: 50,
    paddingLeft: 16,
    paddingRight: 16,
    cursor: 'pointer',
  },

  sendIcon: {
    display: 'block',
    width: 32,
    height: 32,
  },
};

import cameraSource from '../../../../images/icon/message_camera@4x.png';
import sendSource from '../../../../images/icon/message_send@4x.png';
import Img from '@mindoktor/pulse/src/utils/images/Img.web';

export class MessagesInput extends Component {
  state = {
    text: '',
  };

  send = () => {
    const { caseId, entrywayId, postTextMessage } = this.props;
    const { text } = this.state;

    const trimmedText = text.trim();

    if (trimmedText) postTextMessage(caseId, entrywayId, { text: trimmedText });

    this.setState({ text: '' });
  };

  upload = async () => {
    const { caseId, postPhotoMessage, postVideoMessage } = this.props;

    let paths;

    try {
      paths = await chooseFiles('image/*,video/*', true);
    } catch (e) {
      return;
    }

    paths.forEach(async (path) => {
      const postFn = hasImageExtension(path)
        ? postPhotoMessage
        : postVideoMessage;

      await postFn(caseId, path, '', uploadFile);
    });
  };

  onTextChange = ({ target: { value } }) => this.setState({ text: value });

  render() {
    const { text } = this.state;

    const canSend = !!text.trim();

    return (
      <div style={styles.root}>
        <div style={styles.inner}>
          <div
            style={styles.upload}
            onClick={this.upload}
            data-test-id="camerabutton"
          >
            <Img src={cameraSource} style={styles.uploadIcon} />
          </div>

          <Textarea
            value={text}
            placeholder={_t('case.messagecomposer.placeholder')}
            autoFocus={true}
            style={styles.textarea}
            onChange={this.onTextChange}
          />

          <div
            style={styles.send}
            onClick={canSend ? this.send : undefined}
            data-test-id="sendmessagebutton"
          >
            <Img
              src={sendSource}
              style={{ ...styles.sendIcon, opacity: canSend ? 1 : 0.15 }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(undefined, {
  postPhotoMessage,
  postTextMessage,
  postVideoMessage,
})(MessagesInput);
