import { REHYDRATE } from 'redux-persist/es/constants';
import { getIn, setIn } from 'timm';

import { APP_STATE_CHANGE } from '../app/actions';
import { ROUTING_OPEN } from '../routing/actions';
import routes from '../routing/routes';
import {
  MESSAGES_RECORD_VISIT,
  START_WRITING_MESSAGE,
  STOP_WRITING_MESSAGE,
} from './actions';

const initialState = {
  showTextInput: false,
  markNewSince: {},
  activeCaseId: null,
};

export default function messagesReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case START_WRITING_MESSAGE:
      return { ...state, showTextInput: true };

    case STOP_WRITING_MESSAGE:
      return { ...state, showTextInput: false };

    case MESSAGES_RECORD_VISIT:
      return setIn(
        state,
        ['markNewSince', String(payload.caseId)],
        payload.timestamp
      );

    case ROUTING_OPEN:
      return {
        ...state,
        ...(routes.CASE_MESSAGES === payload.path
          ? {
              activeCaseId: payload.props.id,
              activeCaseIdSince: payload.timestamp,
            }
          : {}),
      };

    case APP_STATE_CHANGE:
      if (payload.state === 'background' && state.activeCaseId) {
        return setIn(
          state,
          ['markNewSince', state.activeCaseId],
          payload.timestamp
        );
      }
      return state;

    case REHYDRATE:
      const markNewSince = getIn('payload', ['messages', 'markNewSince']) || {};
      return { ...state, markNewSince };

    default:
      return state;
  }
}
