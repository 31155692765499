import { routes } from '../../state/routing';
import { navigate } from '../routing';
import MessagesScreen from './messages/screen';
import ReferralResultsScreen from './referrals/results/screen';
import Scheduler from './scheduler/screen';
import WorkoutScreen from './workout/screen';

const navigation = {
  [routes.CASE_MESSAGES]: ({ props: { id } = {} }) =>
    navigate(
      MessagesScreen,
      {
        caseId: parseInt(id, 10),
      },
      { scrollToBottom: true }
    ),

  [routes.CASE_WORKOUT]: ({ props: { id, workoutId } = {} }) =>
    navigate(WorkoutScreen, {
      caseId: parseInt(id, 10),
      workoutId: parseInt(workoutId, 10),
    }),

  [routes.CASE_REFERRAL_RESULTS]: ({ props }) =>
    navigate(ReferralResultsScreen, props),

  [routes.CASE_SCHEDULER]: ({ props: { id, caregiverId } = {} }) =>
    navigate(Scheduler, {
      caseId: parseInt(id, 10),
      caregiverId: Number(caregiverId),
    }),
};

export default navigation;
