import { _t } from '@mindoktor/patient-legacy/i18n';
import React from 'react';
import { connect } from 'react-redux';

import warningIcon from '../../../../../images/icon/ic_warning.png';
import { getAbortText } from '../../../../state/formulary/api';
import Button from '../../Button';
import Modal from '../../Modal';
import Image from '../../primitives/Image';
import View from '../../primitives/View';
import { Body1, Body3, Title1 } from '../../typography';

// type Props = {
//   getAbortText: typeof getAbortText,
//   entrywayId: number,
//   drug: Drug,
//   onClose: () => void,
// };

// type State = {
//   text?: string,
// };

class DrugWarningModal extends React.Component {
  state = {};

  async componentDidMount() {
    const {
      props: { getAbortText, drug, entrywayId },
    } = this;
    const { error, text } = await getAbortText(entrywayId, drug.Forbidden);
    if (error) console.warn('Error when fetching abort text');
    this.setState({ text });
  }

  render() {
    const {
      props: { drug, onClose },
      state: { text },
    } = this;

    if (!text) return null;

    return (
      <Modal>
        <View mb="s">
          <Title1>{drug.Label}</Title1>
          <Body3 mb="s">{drug.Description}</Body3>
          <Image source={warningIcon} />
          <Body1>{text}</Body1>
        </View>
        <Button onPress={onClose}>{_t('pictureInstructions.close')}</Button>
      </Modal>
    );
  }
}

export default connect((s) => s, { getAbortText })(DrugWarningModal);
