import { createContext } from 'react';

interface Context {
  pushError: (text: string) => void;
  pushWarning: (text: string) => void;
  pushInfo: (text: string) => void;
  pushSuccess: (text: string) => void;
}

export const NotificationContext = createContext<Context | undefined>(
  undefined
);
