import { routes } from '../../state/routing';
import { navigate } from '../routing';
import AbortScreen from './AbortScreen';
import CompleteScreen from './CompleteScreen';
import InquisitionScreen from './InquisitionScreen';

const mapProps = ({ entrywayId, childUuid, revisitId, dynamicCode } = {}) => ({
  entrywayId: parseInt(entrywayId, 10),
  childUuid,
  revisitId: revisitId ? parseInt(revisitId, 10) : undefined,
  dynamicCode,
});

const navigation = {
  [routes.INQUISITION]: ({ props }) =>
    navigate(InquisitionScreen, mapProps(props)),
  [routes.INQUISITION_ABORT]: ({ props }) =>
    navigate(AbortScreen, mapProps(props)),
  [routes.INQUISITION_COMPLETE]: ({ props }) =>
    navigate(CompleteScreen, mapProps(props)),
};

export default navigation;
