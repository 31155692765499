import MuiAvatar from '@material-ui/core/Avatar';
import { _t } from '@mindoktor/patient-legacy/i18n';
import React from 'react';

import colors from '../../../../common/colors';
import { Body1, Body4 } from '../../../../common/components/typography';
import { getPaymentMethodString } from '../../../../common/utils';
import { CaseStatuses } from '../../../../state/cases/constants';
import { treaterSwitch } from '../../../../state/guides/guidesUtils';
import Card from '../../components/card';
import { CaseCardHeader } from './case_card_header';
import CancelCase from '../../components/cancel_case';
import { useNavigation } from '@mindoktor/patient-app/routing/web';
import { openChatRoute } from '../../../utils/openChatRoute';
import { useAppDispatch } from '../../../../state/hooks';

const styles = {
  active: {
    borderTop: `2px solid ${colors.grey_900}`,
  },

  finished: {
    backgroundColor: '#e0e0e0',
    opacity: 0.5,
  },

  meta: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },

  metaLeft: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    width: 56,
    marginRight: 16,
  },

  avatar: {
    width: 50,
    height: 50,
  },

  count: {
    position: 'absolute',
    top: -4,
    right: -4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 20,
    width: 20,
    borderRadius: '50%',
    backgroundColor: colors.red_500,
    color: colors.white,
    fontWeight: 500,
  },

  metaRight: {
    flex: 1,
  },

  error: {
    color: colors.red_500,
    fontWeight: 500,
  },

  caseNumber: {
    fontWeight: 500,
  },
};

const isPending = (status) => status === CaseStatuses.Pending;

const isFinished = (status) =>
  status === CaseStatuses.Finished ||
  status === CaseStatuses.CanceledByPatient ||
  status === CaseStatuses.ClosedByDoctor;

// type Props = {
//   caseId: number,

//   status: number,

//   iconSource: any,
//   title: string,
//   patientName: string,

//   doctorImageUrl?: string,
//   doctorName?: string,
//   doctorSignature?: string,

//   domainTitle: string,

//   unread: number,

//   style?: any,

//   caseIsTreatedBy: AllowedTreater,
//   hasTechnicalError: boolean,
//   hasPayment: boolean,
// };

export const CaseCard = ({
  caseId,
  conversationId,
  status,
  iconSource,
  title,
  patientName,
  doctorImageUrl,
  doctorName,
  doctorSignature,
  domainTitle,
  unread,
  style,
  caseIsTreatedBy,
  hasTechnicalError,
  hasPayment,
  paymentMethod,
  isCancelable,
  entrywayId,
}) => {
  const matchWithString = treaterSwitch(
    caseIsTreatedBy,
    _t('caseCard.matchWithDoctor'),
    _t('caseCard.matchWithGeneric'),
    _t('caseCard.matchWithMidwife'),
    _t('caseCard.matchWithPhysiotherapist'),
    _t('caseCard.matchWithPsychologist')
  );

  const paymentMethodString = getPaymentMethodString(paymentMethod);
  const navigation = useNavigation();
  const dispatch = useAppDispatch();
  return (
    <Card
      style={{
        ...(isFinished(status) ? styles.finished : styles.active),
        ...style,
      }}
      onClick={() =>
        openChatRoute(dispatch, navigation, caseId, conversationId)
      }
      data-test-id="casecard"
    >
      <CaseCardHeader
        iconSource={iconSource}
        title={title}
        patientName={patientName}
      />

      <div style={styles.meta}>
        <div style={styles.metaLeft}>
          {!isPending(status) && (
            <MuiAvatar src={doctorImageUrl} style={styles.avatar} />
          )}

          {!!unread && (
            <Body4 style={styles.count}>{unread > 9 ? '+' : unread}</Body4>
          )}
        </div>

        <div style={styles.metaRight}>
          {isFinished(status) && <Body1>{_t('caseCard.cancel')}</Body1>}
          {!!unread && (
            <Body1>
              {_t('caseCard.notifications')}:{' '}
              <span style={styles.error}>
                {_t('caseCard.messages', { unreadMessages: unread })}
              </span>
            </Body1>
          )}
          <Body1>
            {!hasPayment && hasTechnicalError ? (
              <Body1 style={styles.error}>
                {_t('errorMessage.technicalError.contactSupport')}
              </Body1>
            ) : isPending(status) ? (
              matchWithString
            ) : (
              `${doctorName || ''}, ${doctorSignature || ''}`
            )}
          </Body1>
          <Body1>
            {_t('caseCard.caseId')}:{' '}
            <span style={styles.caseNumber}>{caseId}</span>
          </Body1>
          {!!domainTitle && <Body1>{domainTitle}</Body1>}
          {!!paymentMethodString && (
            <Body1>
              {`${_t('payment.paymentMethod')}: ${paymentMethodString}`}
            </Body1>
          )}
          {!!isCancelable && (
            <div style={{ marginTop: '0.75rem' }}>
              <CancelCase caseId={caseId} entrywayId={entrywayId} />
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};

export default CaseCard;
