import commonConstants from './commonConstants';

export default {
  ...commonConstants,

  // TODO: Temporary Flow workaround for https://github.com/facebook/flow/issues/945
  isIos: undefined,
  isAndroid: undefined,
  /** @deprecated Handle like every other device */
  isIphoneX: undefined,
  footerMargin: 0,
  tabHeaderMargin: 0,
  /** @deprecated use ReactNative Dimensions instead */
  screenHeight: 568,
  /** @deprecated use ReactNative Dimensions instead */
  screenWidth: 320,
  /** @deprecated use ReactNative Dimensions instead */
  screenWidthSmall: 360,
  tabbarHeight: 0,
  titlebarHeight: 0,
  statusbarHeight: 0,
  extraTopPadding: 0,
  extraBottomPadding: 0,
  categorySelectorTop: 0,
  onePixel: 0,
  bodyFontFamily: undefined,
  mediumBodyFontFamily: undefined,
  semiBoldBodyFontFamily: undefined,
  normalFontFamily: undefined,
  titleFontFamily: undefined,
  boldTitleFontFamily: undefined,
  navigationEasing: undefined,
  coinSpinEaseIn: undefined,
  coinSpinEaseOut: undefined,
};
