import React, { FC, useCallback } from 'react';

import Button from '@mui/material/Button/Button';
import Typography from '@mui/material/Typography';

import { useTranslation } from '@mindoktor/patient-app/localization/hooks/useTranslation';

import SystemCardLayout from '../../layout/SystemCardLayout/SystemCardLayout';

import BillogramPurpleIcon from './BillogramPurpleIcon/BillogramPurpleIcon';
import { InvoiceCardProps } from './types';

const InvoiceCard: FC<InvoiceCardProps> = ({ message }) => {
  const t = useTranslation('messaging', 'invoiceMessage');
  const { url } = message.payload;

  const openPaymentUrl = useCallback(() => {
    // Secure way to open a new window with JS/TS.
    // https://stackoverflow.com/a/63627688
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow != null) {
      newWindow.opener = null;
    }
  }, [url]);

  return (
    <SystemCardLayout
      icon={BillogramPurpleIcon}
      title={t('title')}
      message={message}
    >
      <Typography>{t('body')}</Typography>
      <Button variant="contained" color="primary" onClick={openPaymentUrl}>
        {t('paymentButton')}
      </Button>
    </SystemCardLayout>
  );
};

export default InvoiceCard;
