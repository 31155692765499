export const START_WRITING_MESSAGE = 'START_WRITING_MESSAGE';
export const STOP_WRITING_MESSAGE = 'STOP_WRITING_MESSAGE';
export const MESSAGES_RECORD_VISIT = 'MESSAGES_RECORD_VISIT';

export function startWritingMessage() {
  return {
    type: START_WRITING_MESSAGE,
  };
}

export function stopWritingMessage() {
  return {
    type: STOP_WRITING_MESSAGE,
  };
}

export const recordVisit = (caseId) => ({
  type: MESSAGES_RECORD_VISIT,
  payload: {
    caseId,
    timestamp: Date.now(),
  },
});
