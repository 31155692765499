import { _t } from '@mindoktor/patient-legacy/i18n';
import React, { Component } from 'react';

import Close from './components/close';
import Message from './components/message';
import Notification from './components/notification';

export default class Item extends Component {
  state = {
    dismissNotification: false,
  };

  timer;
  shouldTimeout;

  constructor(props) {
    super(props);

    if (props.shouldTimeout) {
      this.timer = setTimeout(this.remove, props.timeout);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pings !== this.props.pings) {
      this.timer && clearTimeout(this.timer);

      if (this.props.shouldTimeout) {
        this.timer = setTimeout(this.remove, this.props.timeout);
      }
    }
  }

  remove = () => {
    const { onClose, onRemove, id } = this.props;
    onClose && onClose();
    onRemove && onRemove(id);
  };

  componentWillUnmount() {
    this.timer && clearTimeout(this.timer);
  }

  translations = {
    ['errorMessage.offline']: _t('errorMessage.offline'),
    ['errorMessage.childGuideAge']: _t('errorMessage.childGuideAge'),
    ['errorMessage.maintenance']: _t('errorMessage.maintenance'),
    ['errorMessage.offline']: _t('errorMessage.offline'),
    ['errorMessage.technicalError']: _t('errorMessage.technicalError'),

    ['errorMessage.addChildAlreadyRegisteredToYou']: _t(
      'errorMessage.addChildAlreadyRegisteredToYou'
    ),
    ['errorMessage.addChildAlreadyRegisteredToOther']: _t(
      'errorMessage.addChildAlreadyRegisteredToOther'
    ),
    ['errorMessage.addChildSparNameMismatch']: _t(
      'errorMessage.addChildSparNameMismatch'
    ),
    ['errorMessage.addChildSparIDPMismatch']: _t(
      'errorMessage.addChildSparIDPMismatch'
    ),
    ['errorMessage.ssnNotFoundInOfficialRecords']: _t(
      'errorMessage.ssnNotFoundInOfficialRecords'
    ),
    ['errorMessage.notLegalGuardianOfChild']: _t(
      'errorMessage.notLegalGuardianOfChild'
    ),
    ['errorMessage.cannotAddChildContactSupport']: _t(
      'errorMessage.cannotAddChildContactSupport'
    ),
    ['errorMessage.addChildDefault']: _t('errorMessage.addChildDefault'),
    ['errorMessage.formularyError']: _t('errorMessage.formularyError'),
    ['errorMessage.guideLoadError']: _t('errorMessage.guideLoadError'),
    ['errorMessage.postFile']: _t('errorMessage.postFile'),
    ['errorMessage.parallelCases']: _t('errorMessage.parallelCases'),
    ['errorMessage.notOfficeHours']: _t('errorMessage.notOfficeHours'),
    ['errorMessage.caseClosed']: _t('errorMessage.caseClosed'),
    ['sparNameUpdate.error.rateLimited']: _t(
      'sparNameUpdate.error.rateLimited'
    ),
    ['sparNameUpdate.error.generic']: _t('sparNameUpdate.error.generic'),
    ['sparNameUpdate.success']: _t('sparNameUpdate.success'),

    ['errorMessage.invalidSSN']: _t('errorMessage.invalidSSN'),
    ['errorMessage.invalidPostalCode']: _t('errorMessage.invalidPostalCode'),
    ['errorMessage.invalidPostalCodeMaxLength']: _t(
      'errorMessage.invalidPostalCodeMaxLength'
    ),
    ['errorMessage.invalidPostalCodeRegion']: _t(
      'errorMessage.invalidPostalCodeRegion'
    ),
    ['errorMessage.invalidPhoneFormat']: _t('errorMessage.invalidPhoneFormat'),
    ['errorMessage.phoneCollision']: _t('errorMessage.phoneCollision'),

    ['errorMessage.collisionSSN']: _t('errorMessage.collisionSSN'),
    ['errorMessage.invalidDateOfBirth']: _t('errorMessage.invalidDateOfBirth'),
    ['errorMessage.tooYoung']: _t('errorMessage.tooYoung'),
  };

  render() {
    const {
      message = 'errorMessage.technicalError',
      type,
      timeout,
      pings,
      id,
    } = this.props;
    const translatedMessage = this.translations[message] || message;

    return (
      <Notification id={id} type={type} timeout={timeout} pings={pings}>
        <Message type={type}>{translatedMessage}</Message>

        <Close type={type} onClick={this.remove} />
      </Notification>
    );
  }
}
