import { z } from 'zod';

export const UserDetailsSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  address: z.string(),
  city: z.string(),
  postalCode: z.string(),
  email: z.string(),
  telephone: z.string(),
  identityProtection: z.boolean(),
});
