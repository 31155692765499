import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles({
  videoWrapper: {
    maxWidth: 400,
  },

  video: {
    display: 'block',
    maxWidth: '100%',
    maxHeight: 200,
    backgroundColor: '#eee',
  },
});

function getVideoType(src) {
  let blob = /^data:(video\/.*);(base64)/.exec(src);
  if (blob) {
    if (blob[1] === 'video/quicktime' || blob[1] === 'video/mov') {
      // quicktime is not supported in the browser they are usually mp4 so try it....
      return 'video/mp4';
    }
    return blob[1];
  }

  blob = /(webm|mp4|quicktime|mov)$/i.exec(src);
  if (blob) {
    switch (blob[0]) {
      case 'quicktime':
      case 'mov':
        // quicktime is not supported in the browser they are usually mp4 so try it....
        return 'video/mp4';
      case 'webm':
        return 'video/webm';
      default:
        return 'video/mp4';
    }
  }
  return null;
}

export default function (props) {
  const { src, type = getVideoType(src) } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.videoWrapper}>
      <video className={classes.video} controls>
        <source src={src} type={type} />
      </video>
    </div>
  );
}
