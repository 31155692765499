import React from 'react';
import * as Boxed from '../components/boxedscreen';
import { openRoute } from '../../state/routing';
import {
  contactInformation,
  getContactInfoBeingVerified,
} from '../../state/profile/selectors';
import { goHome } from '../routing';
import { getRouteParam } from '../../state/routing/utils';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import VerifyEmail, { Verification } from './components/VerifyEmail';
import { showSnackbar } from '../../state/snackbar/actions';
import { SnackbarType } from '../../state/snackbar/types';
import { _t } from '@mindoktor/patient-legacy/i18n';
import { getConversationId } from '../../state/cases/selectors';
import { openChatRoute } from '../utils/openChatRoute';
import { useNavigation } from '@mindoktor/patient-app/routing/web';
import { useTrackPageView } from '@mindoktor/patient-app/tracking/hooks/useTrackPageView';

import { routes } from '@mindoktor/patient-app/routing/constants/webRoutes';

const VerifyScreen: React.FC = () => {
  const { verification, contactInfo, caseId } = useAppSelector((state) => {
    const verification: Verification = {
      info: '',
      type: 'email',
      ...getContactInfoBeingVerified(state),
    };

    return {
      contactInfo: contactInformation(state),
      verification,
      caseId: +getRouteParam(state, 'caseId'),
    };
  });
  useTrackPageView({ caseId });

  const conversationId = useAppSelector((state) => {
    return getConversationId(state, caseId);
  });

  const dispatch = useAppDispatch();
  const navigation = useNavigation();

  if (!verification || !contactInfo) {
    goHome();
    return null;
  }

  const onCancel = () => {
    return dispatch(openRoute(routes.CASES));
  };

  const showEmailVerifiedToastNotification = () => {
    return dispatch(
      showSnackbar({
        type: SnackbarType.SUCCESS,
        text: _t('contactinfo.verification.email.done.text'),
      })
    );
  };

  const onComplete = () => {
    showEmailVerifiedToastNotification();
    openChatRoute(dispatch, navigation, caseId, conversationId);
  };

  return (
    <Boxed.Screen onCancel={onCancel}>
      <VerifyEmail
        onComplete={onComplete}
        variant={Boxed.ScreenVariant.GuideFlow}
      />
    </Boxed.Screen>
  );
};

export default VerifyScreen;
